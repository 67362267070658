import React, { useState, useEffect } from 'react'
import Graph from "./Graph";
import Graphs from "./Graphs";
import InspectDetails from '../inspect/InspectDetails';
import moment from 'moment';
import { useCaptureSlice } from '../../capture/hooks/useCaptureSlice';
import SelectWrapper from '../../core/components/SelectWrapper';
import {decisionsFilteredByDuration, listEntryFormat} from '../../dashboard/components/DashboardLeaderShipSurvey';
import { Panel } from '../../core/components/BootstrapWrapper';
import DurationFilterComponent from "../../dashboard/components/DashboardFilters/DurationFilterComponent";
import {DURATION_OPTIONS} from "../../dashboard/components/Dashboard";
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';
import { TeamSelector } from '../../dashboard/components/TeamSelector';
import { Col, Row } from 'react-bootstrap';
import {RESTRICTIONS} from "../../core/const";

const Inspect = () => {
    const { Actions, leaderShipAssessmentSurvey, isFetching } = useCaptureSlice();
    const [currentSurveyValue, setCurrentSurveyValueValue] = useState("All");
    const [duration, setDuration] = useState( DURATION_OPTIONS.ALL );
    const user = useUserSlice();
    const [selectedTeam, setSelectedTeam] =
        useState( localStorage.getItem('leadershipAssessmentSelectedTeam')
            && ! RESTRICTIONS.Leadership.RULES.CAN_SEE_ALL_TEAMS.some(role => user[role] )
                ? parseInt(localStorage.getItem('leadershipAssessmentSelectedTeam'))
                : "");

    useEffect(() => {
        Actions.getCaptures();
    }, []);


    const changeTimeRange = e => {
        const statsQuery = { 
            created: moment().subtract( e.duration, "days" ).format("YYYY-MM-DD")
        }
        if(parseInt(localStorage.getItem('leadershipAssessmentSelectedTeam'))) {
            statsQuery.selected_team = parseInt(localStorage.getItem('leadershipAssessmentSelectedTeam'))
        }
        Actions.getCaptureStats( statsQuery );
        setDuration( e.duration );
    }

    const SurveyTimeRageFilter = decisionsFilteredByDuration( leaderShipAssessmentSurvey.filter( a => selectedTeam === "" || a.selected_team === selectedTeam), duration );

    const leaderShipAssessmentSurveyFiltered = SurveyTimeRageFilter
        .filter( a => ! a.parent_capture)
        .filter( a => a.data.title )
        .map( a => ({label: listEntryFormat(a, leaderShipAssessmentSurvey, user), value: a.key, date: a.created}))
        .sort( (a,b) => moment(b.date).diff(a.date) );

    const surveysOptions = [{value: 'All', label: listEntryFormat(), date: new Date()}, ...leaderShipAssessmentSurveyFiltered];

    const surveys =
        [...SurveyTimeRageFilter]
        .filter( a =>
            currentSurveyValue === "All"
            || a.key === currentSurveyValue
            || a.parent_capture === currentSurveyValue )
        .flatMap(a => ({...a.data, pk: a.key, parent_capture: a.parent_capture, created: a.created}));

    return (<>
        <Row>
            <Col md={4} sm={4} lg={4}>
                <DurationFilterComponent
                    appliedFilters={{duration: duration}}
                    handleFiltersChange={changeTimeRange}
                />
            </Col>
            <Col md={4} sm={4} lg={4}>
                <SelectWrapper
                    minimalistView
                    onChange={(s) => setCurrentSurveyValueValue(s.value)}
                    defaultValue={ surveysOptions.length > 0 ? surveysOptions[0] : "" }
                    options={surveysOptions}
                />
            </Col>
            <Col md={4} sm={4} lg={4}>
                <TeamSelector onChange={(t) => setSelectedTeam(t.value)} />
            </Col>
        </Row>
        <Row>
            <Col md={8} sm={8} lg={8}>
                {(!! surveys.length && !isFetching) && <>
                    <Panel>
                        <Graph surveys={surveys} />
                    </Panel>
                    <Graphs surveys={surveys} showAutonomyVsContolChart={currentSurveyValue === "All"} />
                </>}
            </Col>
            <Col md={4} sm={4} lg={4}>
                <InspectDetails />
            </Col>
        </Row>
    </>)
}

export default Inspect;