import styled from 'styled-components'

export const PrioritiesWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
`

export const Input = styled.input`
    min-height: 36px;
    width: 100%;
    text-align: center;
`

export const Head = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
`

export const HeadItem = styled.div`
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    cursor: pointer;
    :hover {
        background: rgba(185, 185, 185, 0.1);
    }
`

export const HeadItemCover = styled.div`
    height: 36px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
`

export const BaseItem = styled.div`
    justify-content: center;
    display: flex;
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
`
