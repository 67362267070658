import {useEffect, useRef} from 'react'

export const useClickOutside = (hide) => {
    const ref = useRef()
    const handleClickOutside = (e) => {
        e.stopPropagation()
        if (!ref.current.contains(e.target)) {
            hide()
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return ref
}
