import {all, call, put, takeEvery, takeLeading} from 'redux-saga/effects'
import Api from '../api'
import {dashboardSlice} from './slices'
import {consoleError} from '../../core/utils/error'

function* fetchDecisionsSaga() {
    try {
        let page = 1
        let nextPageUrl = true

        while (nextPageUrl !== null) {
            const response = yield call(Api.fetchDecisions, page)
            yield put(dashboardSlice.actions.fetchDecisionsSuccess(response.data.results))
            yield put(dashboardSlice.actions.setDecisionsCount(response.data.count))
            nextPageUrl = response.data.next
            page++
        }
    } catch (error) {
        consoleError(error)
        yield put(dashboardSlice.actions.requestFailure(error))
    }
}

function* logPageView(action) {
    try {
        yield call(Api.logPageView, action.payload)
    } catch (error) {
        consoleError(error)
        yield put(dashboardSlice.actions.requestFailure(error))
    }
}

function* resetPointsSaga() {
    yield put(dashboardSlice.actions.setSelectedPoints({}))
}

export default function* flow() {
    yield all([
        takeLeading(dashboardSlice.actions.fetchDecisions, fetchDecisionsSaga),
        takeLeading(dashboardSlice.actions.logPageView, logPageView),
        takeEvery(dashboardSlice.actions.setFilters, resetPointsSaga),
    ])
}
