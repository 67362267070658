import React, {useCallback, useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import {BarLoader} from 'react-spinners'
import styled from 'styled-components'
import Avatar from '../../../core/components/Avatar'
import {BLACK_GREY, GREY, MID_GREY} from '../../../core/ui/_colors'
import {useActions} from '../../../core/hooks/useActions'
import {strategyMapSlice} from '../../redux/slices'
import {descend, differenceWith, prop, sortWith} from 'ramda'
import {sortUsersByUserFullname} from '../SquadOverlayTrigger'
import NoUsers from '../NoUsers'
import {isExperiment, removeSelfFromSquad} from '../../utils'
import Squad from './Squad'
import {Flex} from '../../../core/ui/Flex'
import {useUserSlice} from '../../hooks/useUserSlice'
import {Modal, SearchIcon, SearchInput, SearchContainer} from '../../../core/ui/modal/Modal'
import {IconButton} from '../../../core/components/Buttons'
import {showPopupMessage} from '../../../core/utils/notifications'
import A42Tabs from '../../../core/components/Tabs'
import { useStrategyMapSlice } from '../../hooks/useStrategyMapSlice'
import {RESTRICTIONS} from "../../../core/const";

const USER_LIST_HEIGHT = 220
const SEARCH_BOX_HEIGHT = 58

export const LoaderContainer = styled.div`
    position: relative;
    margin: 0 auto;
    top: ${(props) => props.top || '0'};
    width: 100px;
`

const UserList = styled.div`
    width: 100%;
    height: ${(props) =>
        props.withSearchBox ? USER_LIST_HEIGHT : USER_LIST_HEIGHT + SEARCH_BOX_HEIGHT}px;
    overflow-y: auto;
`

export const UserItem = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.greyedOut ? GREY : BLACK_GREY)};
    margin-bottom: 4px;
    padding: 5px 2px;
`

const ListDivider = styled(UserItem)`
    margin-top: 20px;
`

const InviteStatusBadge = styled.span`
    opacity: 0.6;
    font-size: 14px;
    position: relative;
    right: 10px;
    font-weight: 500;
    letter-spacing: 0.56px;
    color: ${MID_GREY};
`

export const SearchBox = (props) => {
    return (
        <SearchContainer>
            <SearchIcon />
            <SearchInput
                placeholder={I18n.t('dashboard.search')}
                onChange={(value) => props.onSetFilter(value.target.value)}
            />
        </SearchContainer>
    )
}

const SquadModal = (props) => {
    const Actions = useActions(strategyMapSlice.actions)
    const { 
        isSaving, 
        users, 
        isFetchingUsers, 
        isFetchingSquadMembers, 
        isFetchingNode, 
        nodeMap
    } = useStrategyMapSlice()
    const [filteredUsers, setFilteredUsers] = useState(users)
    const currentActiveUser = useUserSlice()
    const isSameUser = (user, squadMember) => squadMember.user && user.pk === squadMember.user.pk
    const usersNotInSquad = (users) =>
        [...differenceWith(isSameUser, users, props.squadMembers)].sort(sortUsersByUserFullname)
    const currentSquadMember =
        props.squadMembers.find((squadMember) => squadMember.user.pk === currentActiveUser.pk) || {}
    
    const handleSetFilteredUsers = useCallback(() => {
        setFilteredUsers(usersNotInSquad(users))
    }, [])

    useEffect(() => {
        handleSetFilteredUsers()
    }, [users, props.squadMembers])

    useEffect(() => {
        handleSetFilter('')
    }, [props.isOpen])

    const handleSetFilter = (searchString) => {
        searchString = searchString.toLowerCase()
        const filteredUsersList = usersNotInSquad(users).filter(
            (user) =>
                user.email.toLowerCase().includes(searchString) ||
                user.first_name.toLowerCase().includes(searchString) ||
                user.last_name.toLowerCase().includes(searchString) ||
                (user.initials && user.initials.toLowerCase().includes(searchString)),
        )

        setFilteredUsers(filteredUsersList)
    }

    const checkIfUserIsVolunteering = (userId) => {
        const userIsInExperimentTeam = (member, userId) =>
            member.user.pk === userId && (member.pending || member.approved)
        const userIsInAnyExperimentTeam = (userId) => (node) =>
            node &&
            isExperiment(node) &&
            node.experiment_team_members.some((member) => userIsInExperimentTeam(member, userId))
        const childrenObjects =
            props.node.children && props.node.children.map((childId) => nodeMap[childId])
        const experimentsWithUser =
            childrenObjects && childrenObjects.find(userIsInAnyExperimentTeam(userId))

        return !!experimentsWithUser
    }

    const inviteUser = (user) => {
        const adminEmails =
            users
                .filter( (u) => RESTRICTIONS.StrategyMap.RULES.CAN_ADMINISTRATE_STRATEGY_MAP.some(rule => u[rule] ) )
                .map( (a) => a.email );

        if( RESTRICTIONS.StrategyMap.RULES.CAN_INVITE_TO_SQUAD.some( role => user[role] ) )
            Actions.inviteUserToSquad({userId: user.pk, nodeId: props.node.id});
        else {
            props.onRequestClose();
            const adminsMessage = adminEmails.join('\n');
            const upgradeUserMessage = RESTRICTIONS.StrategyMap.RULES.CAN_INVITE_TO_SQUAD_FORCED.some( role => currentActiveUser[role] )
                ? I18n.t('strategyMap.messages.asAdminYouWillUpgradeUser')
                : `${I18n.t(
                      'strategyMap.messages.pleaseContactAdminToUpgradeUser',
                  )} ${adminsMessage}`;

            const message = `${I18n.t(
                'strategyMap.messages.cantInviteNonStrategistToImprovementSquad',
            )} ${upgradeUserMessage}`;

            showPopupMessage({
                title: I18n.t('strategyMap.messages.warning'),
                message: message,
                confirmLabel: I18n.t('strategyMap.messages.ok'),
                cancelLabel: I18n.t('strategyMap.messages.cancel'),
                onConfirmCallback: () => {
                    if( RESTRICTIONS.StrategyMap.RULES.CAN_INVITE_TO_SQUAD_FORCED.some( role => currentActiveUser[role] ) ){
                        Actions.inviteUserToSquad({userId: user.pk, nodeId: props.node.id})
                    }
                },
                onCancelCallback: () => {},
            })
        }
    }

    const getUsersListToRender = () => {
        const sortedUsers = sortWith([descend(prop('is_strategist'))], filteredUsers)
        const [available, unavailable] = sortedUsers.reduce(
            (result, user) => {
                result[!checkIfUserIsVolunteering(user.pk) ? 0 : 1].push(user)
                return result
            },
            [[], []],
        )
        let titleUnavailable
        let unavailableRenderList = []

        const availableRenderList = available.map((user) => {
            return (
                <UserItem data-testid={'active-users-item-modal-' + user.pk} key={user.pk}>
                    <Flex justifyContent="space-between">
                        <Flex alignItems={'center'}>
                            <Avatar colorCode={user.color_code}
                                    text={user.initials}
                                    profilePicture={user.profile_picture} 
                                />
                            {user.full_name}
                        </Flex>
                        <Flex alignItems={'center'}>
                            { RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role] ) && (
                                <InviteStatusBadge>{'Strategist'}</InviteStatusBadge>
                            )}
                            <IconButton
                                withBackground
                                testid={'invite-squad-member-btn-' + user.pk}
                                onClick={() => inviteUser( user ) }
                                icon="add"
                            />
                        </Flex>
                    </Flex>
                </UserItem>
            )
        })

        if (unavailable.length > 0) {
            titleUnavailable = [
                <ListDivider key={'divider'}>
                    {I18n.t('strategyMap.messages.notAvailableInvolvedInEXP')}
                </ListDivider>,
            ]
            unavailableRenderList = unavailable.map((user) => {
                return (
                    <UserItem
                        data-testid={'unactive-users-item-modal-' + user.pk}
                        key={user.pk}
                        greyedOut
                    >
                        <Avatar colorCode={user.color_code}
                                text={user.initials}
                                profilePicture={user.profile_picture} 
                            />
                        {user.full_name}
                    </UserItem>
                )
            })
        }

        return filteredUsers.length > 0 ? (
            availableRenderList.concat(titleUnavailable, unavailableRenderList)
        ) : (
            <NoUsers text={I18n.t('strategyMap.nodes.noUsersToDisplay')} />
        )
    }

    const tabs = [
        {
            key: 'squad-tab-members',
            title: I18n.t('sidebar.squad.dialog.members'),
            content: (<UserList>
                        {isFetchingSquadMembers || isSaving || isFetchingNode ? (
                            <LoaderContainer top={'50%'}>
                                <BarLoader />
                            </LoaderContainer>
                        ) : (
                            <Squad
                                members={props.squadMembers}
                                isUserChampion={props.isUserChampion}
                                isUserSponsor={props.isUserSponsor}
                                totalChampions={props.totalChampions}
                                onRequestClose={props.onRequestClose}
                            />
                        )}
                    </UserList>),
        }
    ]

    if( props.isUserChampion || props.isUserSponsor ){
        tabs.push({
            key: 'squad-tab-invite',
            title: I18n.t('sidebar.squad.dialog.invite'),
            content: (<>
                <SearchBox onSetFilter={handleSetFilter} />
                <UserList withSearchBox>
                    {isFetchingUsers || isSaving || isFetchingNode ? (
                        <LoaderContainer top={'50%'}>
                            <BarLoader />
                        </LoaderContainer>
                    ) : (
                        getUsersListToRender()
                    )}
                </UserList>
            </>)
        })
    }

    const _removeMyselfFromSquad = () => removeSelfFromSquad(
        currentActiveUser.pk,
        currentSquadMember,
        props.isUserChampion,
        props.totalChampions,
        props.onRequestClose,
        Actions,
    )

    return (
        <Modal 
            isOpen={props.isOpen}
            title={I18n.t('strategyMap.nodes.improvementSquad')}
            footer={{
                confirmText: currentSquadMember.id && I18n.t('sidebar.squad.dialog.leaveSquad'),
                onConfirm: currentSquadMember.id && _removeMyselfFromSquad,
                cancelText: I18n.t('sidebar.squad.dialog.done'),
                onCancel: props.onRequestClose
            }}
        >
            <A42Tabs tabs={tabs} />
        </Modal>
    )
}

export default SquadModal
