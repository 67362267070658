import Raven from 'raven-js'
import _ from 'lodash'

export const consoleError = (err) => {
    if (process.env.NODE_ENV === 'development') {
        console.error(err)
    }
}

export const captureRavenException = (actionName) => (errorMessage) => {
    Raven.captureException(actionName, {
        extra: {'error message': errorMessage},
    })
}

export function getApiErrors(errorObj) {
    return _.get(errorObj, 'response.data', {})
}

export function getApiErrorCode(errorObj) {
    return _.get(errorObj, 'response.status', 0)
}

export function formatApiErrorMessages(errors, messages = [], prefix = '') {
    _.forOwn(errors, (v, k) => {
        const newPrefix = prefix ? `${prefix}[${formatFieldName(k)}]` : formatFieldName(k)
        if (typeof v === 'string') {
            messages.push(`${newPrefix}: ${v}`)
        }
        if (Array.isArray(v) && v.length) {
            if (typeof v[0] === 'string') {
                messages.push(`${newPrefix}: ` + v.join('; '))
            } else {
                v.forEach((errors, i) => {
                    messages = formatApiErrorMessages(errors, messages, `${newPrefix}[${i + 1}]`)
                })
            }
        } else if (typeof v === 'object') {
            messages = formatApiErrorMessages(v, messages, `${newPrefix}`)
        }
    })
    return messages
}

function formatFieldName(name) {
    let result = `${name.replace(/_/g, ' ')}`
    result = result.substr(0, 1).toUpperCase() + result.substr(1)
    return result
}

export function getErrorMessages(error) {
    return formatApiErrorMessages(getApiErrors(error))
}
