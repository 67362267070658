import React, {useState} from 'react'
import Avatar from '../../core/components/Avatar'
import styled from 'styled-components'
import {BLACK_GREY, LIGHT_GREY, MID_GREY, PALE_GREY, SHADOW_COLOUR} from '../../core/ui/_colors'
import {useActions} from '../../core/hooks/useActions'
import {commentSlice} from '../redux/comment.slices'
import {useNode} from '../hooks/useNode'
import {useUserSlice} from '../hooks/useUserSlice'
import {H5, I, Span} from '../../core/ui/generic'
import {Div} from '../../core/ui/Div'
import Moment from 'react-moment';
import 'moment-timezone';
import {I18n} from "react-i18nify";


const Container = styled.div`
    .mention {
        background: ${PALE_GREY};
        padding: 2px 5px;
        border-radius: 30px;
    }

    overflow-wrap: break-word;
    margin-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid ${LIGHT_GREY};
    -webkit-box-shadow: 0px 2px 6px 0px ${SHADOW_COLOUR};
    -moz-box-shadow: 0px 2px 6px 0px ${SHADOW_COLOUR};
    box-shadow: 0px 2px 6px 0px ${SHADOW_COLOUR};
`

const Icon = styled(I)`
    :hover {
        color: #000 !important;
    }

    color: ${MID_GREY};
`

const H5_ = styled(H5)`
    color: ${BLACK_GREY};
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 4px;
    line-height: 30px;
`

const Span_ = styled(Span)`
    font-size: 14px;
    color: ${MID_GREY};
    margin-top: 12px;
    margin-bottom: 12px;
`

const Comment = (props) => {
    const {currentOpenNodeId, isUserAbleToEdit, isUserAbleToDeleteComment} = useNode()
    const user = useUserSlice()
    const [disableVoting, setDisableVoting] = useState(!isUserAbleToEdit)
    const isAbleToDelete = isUserAbleToDeleteComment || props.comment.user.pk === user.pk

    const Actions = useActions(commentSlice.actions)

    const vote = (canThumbUp, canThumbDown, userUpvote, userDownvote, voteType) => {
        if (disableVoting) {
            return
        }
        setDisableVoting(true)
        const votation = {}
        if (voteType === 'UP' && userUpvote) {
            votation.up = false
        }
        if (voteType === 'DOWN' && userDownvote) {
            votation.down = false
        }
        if (voteType === 'UP' && canThumbUp) {
            if (userDownvote) {
                votation.down = false
            }
            votation.up = true
        }
        if (voteType === 'DOWN' && canThumbDown) {
            if (userUpvote) {
                votation.up = false
            }
            votation.down = true
        }
        props.vote(props.comment.id, votation)
        setDisableVoting(false)
    }

    let upvotesNumber = 0
    let downVotesNumber = 0
    let canThumbUp = true
    let canThumbDown = true
    let userUpvote = false
    let userDownvote = false

    for (const i in props.comment.votes) {
        if (props.comment.votes[i].up) {
            upvotesNumber++
            if (props.comment.votes[i].user === user.pk) {
                userUpvote = true
                canThumbUp = false
            }
        }
        if (props.comment.votes[i].down) {
            downVotesNumber++
            if (props.comment.votes[i].user === user.pk) {
                userDownvote = true
                canThumbDown = false
            }
        }
    }

    return (
        <Container className="clearfix">
            <H5_>
                {props.comment.user.full_name}{' '}
                <Avatar pull="right" 
                        colorCode={props.comment.user.color_code}
                        text={props.comment.user.initials}
                        profilePicture={props.comment.user.profile_picture} 
                    />
            </H5_>
            <Div fontSize={'16px'} dangerouslySetInnerHTML={{__html: props.comment.text}} />
            <Span_ className="pull-left">
                <Moment locale={I18n._localeKey} fromNow>{ props.comment.timestamp }</Moment>
            </Span_>
            <Span_ className="pull-right">
                <Icon
                    onClick={() => vote(canThumbUp, canThumbDown, userUpvote, userDownvote, 'DOWN')}
                    className="material-icons"
                    aria-hidden="true"
                    role="button"
                >
                    thumb_down
                </Icon>{' '}
                {downVotesNumber}
                <Icon
                    onClick={() => vote(canThumbUp, canThumbDown, userUpvote, userDownvote, 'UP')}
                    marginLeft={'8px'}
                    className="material-icons"
                    aria-hidden="true"
                    role="button"
                >
                    thumb_up
                </Icon>{' '}
                {upvotesNumber}
                {isAbleToDelete && (
                    <Icon
                        marginLeft={'30px'}
                        onClick={() =>
                            Actions.resolve({
                                commentId: props.comment.id,
                                nodeId: currentOpenNodeId,
                            })
                        }
                        data-testid={'comment-delete-button'}
                        className={'material-icons pull-right'}
                        aria-hidden="true"
                        role="button"
                    >
                        delete
                    </Icon>
                )}
            </Span_>
        </Container>
    )
}

export default Comment
