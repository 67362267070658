import React from 'react'
import PropTypes from 'prop-types'
import {I18n} from 'react-i18nify'
import HeadingWithAction from '../../Components/HeadingWithAction'
import Avatar from '../../../core/components/Avatar'
import AcceptRejectButtons from '../../../strategyMap/components/AcceptRejectButtons'
import {ExperimentTeamContainer, VolunteerLabel} from './Experiment.styles'

const VolunteerList = (props) => {
    const getFullName = (user) => {
        return user.first_name !== '' || user.last_name !== ''
            ? `${user.first_name} ${user.last_name}`
            : user.email
    }

    return (
        <div className="volunteer-requests" data-testid={'volunteer-requests'}>
            <HeadingWithAction title={I18n.t('strategyMap.experiment.volunteerRequests')} />
            {props.volunteerList && props.volunteerList.length ? (
                props.volunteerList.map((volunteer) => {
                    return (
                        <ExperimentTeamContainer key={volunteer.id} data-testid={`volunteer-item`}>
                            <Avatar pull="left" 
                                    colorCode={volunteer.user.color_code}
                                    text={volunteer.user.initials}
                                    profilePicture={volunteer.user.profile_picture} 
                                />
                            <VolunteerLabel>{getFullName(volunteer.user)}</VolunteerLabel>
                            <AcceptRejectButtons
                                acceptRequest={() => props.approveVolunteerRequest(volunteer.id)}
                                rejectRequest={() => props.declineVolunteerRequest(volunteer.id)}
                            />
                        </ExperimentTeamContainer>
                    )
                })
            ) : (
                <span className="no-request" data-testid={'volunteer-no-request'}>
                    No new request.
                </span>
            )}
        </div>
    )
}

VolunteerList.propTypes = {
    volunteerList: PropTypes.array,
    declineVolunteerRequest: PropTypes.func,
    approveVolunteerRequest: PropTypes.func,
}

VolunteerList.defaultProps = {}

export default VolunteerList
