import React from 'react'
import {I18n} from 'react-i18nify'

import PropTypes from 'prop-types'

import AnimationCount from 'react-count-animation'
import {Flex} from '../../core/ui/Flex'
import {BLACK_GREY} from '../../core/ui/_colors'
import styled from 'styled-components'
import {usePrevious} from '../../core/hooks/usePrevious'

export const FlexStyled = styled(Flex)`
    font-size: 16px;
    line-height: 2em;
    color: ${BLACK_GREY};
    @media only screen and (max-width: 992px) {
        padding: 0;
        align-items: baseline;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 14px;
    }
    @media only screen and (max-width: 414px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 375px) {
        font-size: 10px;
    }
`

const StoryCounter = (props) => {
    const previousFilteredCount = usePrevious(props.filteredCount) || 0
    const previousTotalCount = usePrevious(props.count) || 0

    return (
        <FlexStyled className={"dashboard-decision-count"}>
            {I18n.t('dashboard.stories')}&nbsp;
            <AnimationCount
                start={previousFilteredCount}
                count={props.filteredCount}
                duration={1000}
                decimals={0}
                useGroup={true}
                animation={'up'}
            />
            &nbsp;/&nbsp;
            <AnimationCount
                start={previousTotalCount}
                count={props.count}
                duration={1000}
                decimals={0}
                useGroup={true}
                animation={'up'}
            />
            &nbsp;{I18n.t('dashboard.total')}
        </FlexStyled>
    )
}

StoryCounter.propTypes = {
    filteredCount: PropTypes.number,
    count: PropTypes.number,
    extraClass: PropTypes.string,
}
StoryCounter.defaultProps = {
    extraClass: '',
}

export default StoryCounter
