import React, {useState} from 'react'
import 'react-rangeslider/lib/index.css'
import {I18n} from 'react-i18nify'
import _ from 'lodash'

import {
    FilterRow,
    FilterWrapper
} from './Filters.styles'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {EXPERIMENT_SHOWN_AFTER_ENDING_IN_DAYS, NODE_STATES} from '../../strategyMap/const'
import moment from 'moment'
import FilterCloseButton from "./Buttons/FilterCloseButton";
import FilterButtonClear, {FilterButtonClearAll} from "./Buttons/FilterClearbutton";
import FilterButton from "./Buttons/FilterButton";
import { useSelector } from 'react-redux'

const getExperimentlabel = (exp) => {
    const experimentFullTitle =
                        I18n.t('dashboard.Filter.experiment') +
                        '#' +
                        exp.id +
                        (exp.title !== ''  ? ' - ' + exp.title : '')
    return experimentFullTitle
}

export const getComparableExperiments = () => {
    const {experiments} = useStrategyMapSlice()
    return experiments.filter((exp) => {
        const isStarted = exp.status === NODE_STATES.E.started
        const isFinishedAndNumDaysAfterEnding =
            moment(exp.end_date).add(EXPERIMENT_SHOWN_AFTER_ENDING_IN_DAYS, 'day') > moment()
        return exp.has_related_decision && (isStarted || isFinishedAndNumDaysAfterEnding)
    })
}

const SelectedFilters = () => {
    const {filters, Actions} = useDashboardSlice();

    const checkIfFilterIsEmpty = () => _.isEmpty(filters) || Object.keys(filters).every( key => filters[key].length === 0 );
    const showPeriodClearButton = () => filters['periods'] && ( filters['periods'].from || filters['periods'].to );

    const periodLabel = () => {
    	let label = ``
		if (filters['periods'].from) {
			label += `${I18n.t('dashboard.Filters.from')}: ${filters['periods'].from} `
		}
		if (filters['periods'].to) {
			label += `${I18n.t('dashboard.Filters.from')}: ${filters['periods'].to}`
		}

		return label
	}
    const getSelectedFilterLabel = (filterType, item) => {
        switch( filterType ){
            case 'experiment':
                return getExperimentlabel(item);
            case 'type':
            case 'decision_owner':
                return I18n.t( "charts.Answers." + item );
            default:
                return item.name;
        }
    }
    const filterItems = (i, item, filterType) => 
                            filterType === 'experiment' ? item.id !== i.id : item.pk !== i.pk;

    return (
        <>
            { ! checkIfFilterIsEmpty() && <FilterButtonClearAll /> }
            {
                Object.keys(filters)
                    .filter( e => e !== 'periods')
                    .map( key => filters[key].map( item =>
                            <FilterButtonClear
                                key={key}
                                label={ getSelectedFilterLabel( key, item ) }
                                testid={ `filter-label-${key}` }
                                handleClick={() =>
                                    Actions.setFilters({
                                        filterType: key,
                                        data: filters[key].filter(i => filterItems(i, item, key))
                                    })
                                }
                            />
                    )
                )
            }
            {
                showPeriodClearButton() && (
                    <FilterButtonClear
                        label={ periodLabel() }
                        handleClick={ () => Actions.clearFilters({filterType: 'periods'}) }
                    />
                )
            }
        </>
    )
}


const FilterItems = ({label, showPeriods}) => {
    const demographics = useSelector((state) => state.demographics);
    const [toggle, setToggle] = useState(false);

    const demographicButtons = []

    if (demographics["country"])
        demographicButtons.push({type: "country", label: 'dashboard.Filters.country'})
    if (demographics["city"])
        demographicButtons.push({type: "city", label: 'dashboard.Filters.city'})
    if (demographics["department"])
        demographicButtons.push({type: "department", label: 'dashboard.Filters.departments'})
    if (demographics["role"])
        demographicButtons.push({type: "role", label: 'dashboard.Filters.role'})
    if (demographics["years"])
        demographicButtons.push({type: "years", label: 'dashboard.Filters.years'})

    const filterButtons = [
        ...demographicButtons,
        {type: "type", label: 'dashboard.Filters.types'},
        {type: "decision_owner", label: 'dashboard.Filters.decision_owner'},
        {type: "experiment", label: 'dashboard.Filters.experiment'}
    ]

    return (
        <>
            {
                toggle
                    ? <FilterCloseButton clickHandler={() => setToggle(false)}/>
                    : <FilterButton label={label} clickHandler={() => setToggle(true)} margin dropSide/>
            }
            {
                toggle &&
                <>
                    {
                        filterButtons.map( e =>
                            <FilterButton
                                key={ e.type }
                                demographicType={ e.type }
                                label={ I18n.t(e.label) }
                                showBadge
                            />
                    )}
                    {
                        showPeriods && <FilterButton
                            demographicType="periods"
                            label={ I18n.t('dashboard.Filters.periods') }
                            anchorRight
                        />
                    }
                </>
            }
        </>
    )
}

const Filters = ({filterLabel, showPeriods}) =>
    <FilterWrapper>
        <FilterRow>
            <FilterItems showPeriods={showPeriods || false} label={filterLabel || I18n.t('dashboard.Filters.filters')} />
        </FilterRow>
        <FilterRow>
            <SelectedFilters />
        </FilterRow>
    </FilterWrapper>;

export default Filters;
