import React, {useEffect, useReducer, useState} from 'react'
import {I18n} from 'react-i18nify'
import PropTypes from 'prop-types'
import HeadingWithActionAndDropdown from '../Components/HeadingWithActionAndDropdown'
import {userSlice} from '../../auth/redux/user.slices'
import {SidebarFooter, SidebarFooterHeight, SidebarHeader, SidebarTabs, SideBar} from '../../core/ui/Sidebar.styles'
import {ButtonPrimary} from '../../core/components/Buttons'
import {useOverride} from '../../core/hooks/useOverride'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {useActions} from '../../core/hooks/useActions'
import {useDebouncedCallback} from '../../core/hooks/useDebouncedCallback'
import {value} from '../../core/utils/object'
import {useReplace} from '../../core/hooks/useReplace'
import Dropzone from 'react-dropzone'
import {Description, Modal} from '../../core/ui/modal/Modal'
import {FileContainer} from '../../billings/UserManagement'
import {showPopupMessage} from '../../core/utils/notifications'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import UserProfile from "./UserProfile";
import Experiments from "./Experiments";
import A42Tabs from "../../core/components/Tabs";
import {DASHBOARD_OPTIONS} from "../../core/const"

const initialPasswordState = {
    passwordFormErrors: {},
    passwordFormValid: true,
    currentPassword: '',
    newPassword: '',
}

function passwordReducer(state, [type, payload]) {
    const m = {
        SET: {...state, ...payload},
        UPDATED: {...initialPasswordState},
        ERROR: {...state, ...payload, passwordFormValid: false},
    }
    return m[type]
}

const initialUserState = {
    _boundFirstName: '',
    _boundLastName: '',
    formErrors: {},
    firstNameValid: true,
    lastNameValid: true,
}
const isNameValid = (n) => n && n.length > 0 && n.length <= 30

function userReducer(state, [type, payload]) {
    const nameValid = isNameValid(payload)
    const m = {
        SET_FIRST_NAME: {
            ...state,
            _boundFirstName: payload,
            firstNameValid: nameValid,
            formErrors: {
                ...state.formErrors,
                firstName: nameValid ? '' : I18n.t('profile.Validation.ForFirstName'),
            },
        },
        SET_LAST_NAME: {
            ...state,
            _boundLastName: payload,
            lastNameValid: nameValid,
            formErrors: {
                ...state.formErrors,
                lastName: nameValid ? '' : I18n.t('profile.Validation.ForLastName'),
            },
        },
        UPDATED: {
            ...state,
            formErrors: {},
        },
    }
    return m[type]
}

const UserProfileContainer = () => {

    const [showInfoModal, setShowInfoModal] = useState(false)
    const {logout, updateUser, setPassword, saveProfilePic} = useActions(
        userSlice.actions,
    )
    const user = useUserSlice();
    const {isSubscriptionActive, features, Actions, client} = useCoreSlice();
    const isDashboardFull = client.dashboard_option ===  DASHBOARD_OPTIONS.full;

    const [
        {newPassword, currentPassword, passwordFormErrors, passwordFormValid},
        passwordDispatch,
    ] = useReducer(passwordReducer, initialPasswordState)
    const [
        {_boundLastName, _boundFirstName, formErrors, firstNameValid, lastNameValid},
        userDispatch,
    ] = useReducer(userReducer, {
        ...initialUserState,
        _boundFirstName: user.first_name,
        _boundLastName: user.last_name,
    })
    const boundFirstName = useReplace(user.first_name, _boundFirstName)
    const boundLastName = useReplace(user.last_name, _boundLastName)
    const [boundAllowNotifications, setBoundAllowNotifications] = useOverride(
        user.allow_strategy_map_notifications,
    )
    const [boundLeadershipAssessmentNotificationType, setBoundLeadershipAssessmentNotificationType] = useOverride(
        user.leadership_assessment_email_type,
    )

    const formValid = firstNameValid && lastNameValid
    const experiments = user.in_experiment_team ? user.in_experiment_team.map((a) => a.id) : []

    const saveUserProfile = useDebouncedCallback((userData) => {
        if (!formValid) return
        updateUser(userData)
    }, 1000)

    useEffect(() => {
        if (user.userSaved) {
            userDispatch(['UPDATED'])
        }
    }, [user.userSaved])

    useEffect(() => {
        if (user.passwordSaved) {
            passwordDispatch(['UPDATED'])
        }
    }, [user.passwordSaved])

    useEffect(() => {
        const isPasswordError = user.errors && 'Invalid password.'.includes(value(user.errors)[0])
        if (isPasswordError) {
            passwordDispatch([
                'ERROR',
                {
                    currentPassword,
                    newPassword,
                    passwordFormErrors: user.errors,
                },
            ])
        }
    }, [user.errors, currentPassword, newPassword])

    useEffect(() => {
        if (
            boundAllowNotifications !== user.allow_strategy_map_notifications ||
            boundLeadershipAssessmentNotificationType !== user.leadership_assessment_email_type ||
            boundLastName !== user.last_name ||
            boundFirstName !== user.first_name
        ) {
            const userData = {
                first_name: boundFirstName,
                last_name: boundLastName,
                allow_strategy_map_notifications: boundAllowNotifications,
                leadership_assessment_email_type: boundLeadershipAssessmentNotificationType
            }
            saveUserProfile(userData)
        }
    }, [boundAllowNotifications, boundLeadershipAssessmentNotificationType, boundFirstName, boundLastName])

    const MAX_IMAGE_SIZE_IN_MB = 2
    const MAX_IMAGE_SIZE_IN_BYTES = MAX_IMAGE_SIZE_IN_MB * Math.pow(1024, 2)

    return (
        <SideBar
            id="user-profile-sidebar"
            className="show-sidebar"
            data-testid={'user-profile-sidebar'}
        >
            <Modal 
                isOpen={showInfoModal}
                title={I18n.t('profile.uploadProfileImage')}
                footer={{
                    cancelText: I18n.t('strategyMap.messages.cancel'),
                    onCancel: () => setShowInfoModal(false),
                    onConfirm: null
                }}
            >
                <Description>
                    <Dropzone
                        maxSize={MAX_IMAGE_SIZE_IN_BYTES}
                        accept={'image/*'}
                        multiple={false}
                        onDrop={(files) => {
                            if (files.length > 0) {
                                const data = new FormData()
                                data.append('profile_picture', files[0])
                                saveProfilePic(data)
                                setShowInfoModal(false)
                            }
                        }}
                        onDropRejected={(err) => {
                            setShowInfoModal(false)
                            const warning = {
                                title: I18n.t('strategyMap.messages.warning'),
                                message: err[0].errors[0].message,
                                confirmLabel: I18n.t('strategyMap.messages.ok'),
                                onConfirmCallback: () => {
                                },
                            }
                            showPopupMessage(warning)
                        }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <FileContainer>
                                        {I18n.t('profile.uploadFileInstructionLine1')}
                                        <br/>
                                        {I18n.t('profile.uploadFileInstructionLine2').replace(
                                            '%s',
                                            MAX_IMAGE_SIZE_IN_MB,
                                        )}
                                    </FileContainer>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Description>
            </Modal>
            <SidebarTabs style={{height: 'calc(100% - '+SidebarFooterHeight+'px)'}}>
                <SidebarHeader tabs={isDashboardFull ? 2 : 1}>
                    <HeadingWithActionAndDropdown
                        className="padding-bottom"
                        title={I18n.t('profile.Title')}
                        icon="clear"
                        showAction={true}
                    />
                </SidebarHeader>
                <A42Tabs id="profileTabCntainer"
                     tabs={[
                         {
                             key: 'profile',
                             title: I18n.t('profile.Label.Profile'),
                             content: <UserProfile
                                 Actions={Actions}
                                 _user={{
                                     user: user,
                                     firstNameValid: firstNameValid,
                                     lastNameValid: lastNameValid,
                                     boundFirstName: boundFirstName,
                                     boundLastName: boundLastName,
                                     userDispatch: userDispatch
                                 }}
                                 _password={{
                                     currentPassword: currentPassword,
                                     newPassword: newPassword,
                                     passwordFormErrors: passwordFormErrors,
                                     passwordFormValid: passwordFormValid,
                                     setPassword: setPassword,
                                     passwordDispatch: passwordDispatch
                                 }}
                                 isSubscriptionActive={isSubscriptionActive}
                                 setShowInfoModal={setShowInfoModal}
                                 formErrors={formErrors}
                                 setBoundAllowNotifications={setBoundAllowNotifications}
                                 setBoundLeadershipAssessmentNotificationType={setBoundLeadershipAssessmentNotificationType}
                                 features={features}
                             />
                         },
                         {
                             key: 'experiment',
                             title: I18n.t('profile.Label.Experiments'),
                             content: <Experiments isDashboardFull={isDashboardFull} experiments={experiments} />
                         }
                     ]}
                />
                <SidebarFooter items={1}>
                    <ButtonPrimary fullWidth onClick={logout}>
                        {I18n.t('profile.Button.Logout')}
                    </ButtonPrimary>
                </SidebarFooter>
            </SidebarTabs>
        </SideBar>
    )
}

UserProfileContainer.propTypes = {
    user: PropTypes.object,
    sender: PropTypes.string,
    fetchUser: PropTypes.func,
}

UserProfileContainer.defaultProps = {
    user: null,
}

export default UserProfileContainer
