import React, {useEffect, useState} from 'react'
import Checkbox from '../capture/signifier/Checkbox'
import {Table} from 'react-bootstrap'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import {useDemographicsSlice} from '../core/hooks/useDemographicsSlice'
import YearDialog from './YearDialog'
import {GREY, LIGHT_GREY} from '../core/ui/_colors'
import DropDownMenu, {DropDownMenuOptionType} from '../core/components/DropDownMenu'
import {countries, getFullLanguage} from '../core/utils/language'
import Footer from '../core/ui/modal/Footer'
import Modal from '../core/components/Modal'
import { RESTRICTIONS } from '../core/const'

const InputField = styled.input`
    border: 0;
    outline: 0;
    border: solid 1px transparent;
    :hover {
        border: ${(props) =>
            props.readOnly ? 'solid 1px transparent' : 'solid 1px ' + LIGHT_GREY};
        cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
    }
    :focus {
        border: ${(props) =>
            props.readOnly ? 'solid 1px transparent' : 'solid 1px ' + LIGHT_GREY};
    }
    :disabled {
        background-color: transparent;
        opacity: 60%;
    }
`
const ActionButton = styled.span`
    cursor: pointer;
    vertical-align: middle;
`
const CheckerTh = styled.th`
    width: 30px;
`
const CheckerTd = styled.td`
    width: 30px;
`
export const ScrollAble = styled.div`
    max-height: 50vh;
    overflow: hidden;
    overflow-y: auto;
`
export const ArchivedSymbol = styled.span`
    font-size: 9px;
    color: ${GREY};
    padding: 3px 8px;
    border: 1px solid ${LIGHT_GREY};
    border-radius: 5px;
    margin-left: 10px;
`

export const CountryDialog = ({markedCountries, onClose}) => {
    const _countries = countries()
    const [isOpen, setIsOpen] = useState(true)

    let marks = markedCountries || []
    const markEntry = (code) => {
        const entry = marks.filter((e) => e.name === code)
        if (entry.length) {
            entry[0].archived = !entry[0].archived
            marks = [...new Set([...marks.filter((e) => e.name !== code), entry[0]])]
        } else marks = [...new Set([...marks, {name: code}])]
    }

    const isChecked = (code) =>
        !!marks.filter((mk) => mk.name === code).length &&
        !marks.filter((mk) => mk.name === code)[0].archived
    const isArchived = (code) =>
        !!marks.filter((mk) => mk.name === code).length &&
        marks.filter((mk) => mk.name === code)[0].archived

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {}}
            title={I18n.t('billing.demographics.chooseCountry')}
            footerContent={
                <Footer
                    confirmText={I18n.t('app.buttons.confirm')}
                    onConfirm={() => {
                        setIsOpen(false)
                        onClose(marks)
                    }}
                    onCancel={() => {
                        setIsOpen(false)
                        onClose()
                    }}
                />
            }
        >
            <ScrollAble>
                <Table>
                    <tbody>
                        {_countries.map((e) => (
                            <tr key={e.name}>
                                <td>
                                    <Checkbox
                                        onClick={() => markEntry(e.code)}
                                        checked={isChecked(e.code)}
                                        testId={e.name}
                                    />
                                </td>
                                <td>
                                    {e.name}
                                    {isArchived(e.code) && (
                                        <ArchivedSymbol>
                                            {I18n.t('app.labels.archived')}
                                        </ArchivedSymbol>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ScrollAble>
        </Modal>
    )
}

export const DemographicsTable = (props) => {
    const languages = ['name', 'english', 'german', 'spanish', 'portuguese', 'turkish']

    const [demographics, setDemographics] = useState([])
    const [markPK, setMarkPK] = useState([])
    const [clickedChangeButton, setClickedChangeButton] = useState()
    const {Actions} = useDemographicsSlice()

    useEffect(() => {
        setDemographics(props.demographics || [])
    }, [props.demographics])

    const changeValue = (pk, language, value) => {
        const newDemographics = demographics.slice(0)
        newDemographics.find((e) => e.pk === pk)[language] = value
        setDemographics(newDemographics)
    }
    const storeChanges = () => {
        if (demographics.some((demo) => !demo.name)) return
        Actions.updateDemographicsTenant({data: demographics, detail: props.type})
    }
    const markEntry = (pk) => {
        if (markPK.includes(pk)) setMarkPK(markPK.filter((e) => e !== pk))
        else setMarkPK([...new Set([...markPK, pk])])
    }
    const markEntryAll = () =>
        setMarkPK(markPK.length !== demographics.length ? demographics.map((e) => e.pk) : [])
    const deleteEntries = () => {
        demographics.filter((e) => markPK.includes(e.pk)).forEach((e) => (e.archived = true))
        Actions.updateDemographicsTenant({data: [...demographics], detail: props.type})
        setMarkPK([])
    }
    const addNewRow = () => {
        if (demographics && demographics.length)
            setDemographics([...new Set([{pk: -demographics.length, name: ''}, ...demographics])])
        else setDemographics([{pk: -1, name: ''}])
    }

    const showSpecialDialog = () => {
        switch (clickedChangeButton) {
            case 'country':
                return (
                    <CountryDialog
                        markedCountries={demographics.map((e) => ({
                            name: e.name,
                            archived: e.archived,
                        }))}
                        onClose={(list) => {
                            if (list) {
                                setMarkPK([])
                                Actions.updateDemographicsTenant({data: list, detail: props.type})
                            }
                            setClickedChangeButton(undefined)
                        }}
                    />
                )
            case 'year':
                return (
                    <YearDialog
                        demographics={demographics}
                        onClose={() => setClickedChangeButton(undefined)}
                    />
                )
            default:
                break
        }
    }

    const isSpecialDemographics = ['country', 'year'].includes(props.type);

    const showAddButton = () => {
        const demographicsLength = props.demographics && props.demographics.length;
        const property = 'MAX_' + props.type.toUpperCase();
        if(RESTRICTIONS.Demographics[property] && RESTRICTIONS.Demographics[property] <= demographicsLength)
            return false;
        
        return true;
    }

    const ActionButtonList = ({}) => {
        if (props.type === 'country')
            return (
                <ActionButton
                    className="material-icons"
                    onClick={() => setClickedChangeButton(props.type)}
                    data-testid={'edit-' + props.type}
                >
                    edit
                </ActionButton>
            )
        if (props.type === 'year')
            return (
                <ActionButton
                    className="material-icons"
                    onClick={() => setClickedChangeButton(props.type)}
                    data-testid={'edit-' + props.type}
                >
                    edit
                </ActionButton>
            )
        return (
            <>
                { showAddButton() && <ActionButton
                    className="material-icons"
                    onClick={addNewRow}
                    data-testid={'add-' + props.type}
                >
                    add
                </ActionButton>}
                {markPK.length > 0 && (
                    <ActionButton
                        className="material-icons"
                        onClick={deleteEntries}
                        data-testid={'remove-' + props.type}
                    >
                        remove
                    </ActionButton>
                )}
            </>
        )
    }
    const activate = (entry) => {
        entry.archived = false
        setDemographics([...demographics])
        storeChanges()
    }

    const CheckBoxOption = ({demo, isArchived}) => {
        if (isArchived)
            return (
                <DropDownMenu
                    materialIconName={'arrow_drop_down'}
                    options={[
                        {
                            title: I18n.t('billing.demographics.actions.activate'),
                            key: 'activate',
                            type: DropDownMenuOptionType.PrimaryButton,
                        },
                    ]}
                    onChange={() => activate(demo)}
                />
            )
        return (
            <Checkbox
                onClick={() => markEntry(demo.pk)}
                disabled={demo.archived}
                checked={!!markPK.filter((e) => e === demo.pk).length}
            />
        )
    }
    const someIsNotArchived = demographics.some((e) => !e.archived && e.name)
    const allSelected =
        markPK &&
        demographics &&
        markPK.length === demographics.filter((e) => e.archived == false).length &&
        someIsNotArchived

    return (
        <>
            {showSpecialDialog()}
            <Table>
                <thead>
                    <tr>
                        {!isSpecialDemographics && (
                            <CheckerTh>
                                {someIsNotArchived && (
                                    <Checkbox
                                        onClick={markEntryAll}
                                        checked={allSelected}
                                        testId={props.type}
                                    />
                                )}
                            </CheckerTh>
                        )}
                        {languages
                            .filter((e) => (isSpecialDemographics ? e === 'name' : true))
                            .map((language) =>
                                language === 'name' ? (
                                    <th key={language}>
                                        <ActionButtonList />
                                    </th>
                                ) : (
                                    <th key={language}>{I18n.t('app.languages.' + language)}</th>
                                ),
                            )}
                        {props.type === 'department' && (
                            <th>{I18n.t('billing.demographics.peopleCount')}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {demographics &&
                        demographics.map((demo, id) => {
                            return (
                                <tr key={id} data-testid={`${props.type}`}>
                                    {!isSpecialDemographics && (
                                        <CheckerTd>
                                            <CheckBoxOption
                                                demo={demo}
                                                isArchived={demo.archived}
                                                testId={`${props.type}-${id}`}
                                            />
                                        </CheckerTd>
                                    )}
                                    {languages
                                        .filter((e) =>
                                            isSpecialDemographics ? e === getFullLanguage() : true,
                                        )
                                        .map((language) => (
                                            <td key={language}>
                                                <InputField
                                                    type="text"
                                                    value={demo[language] || ''}
                                                    style={{width: '100%'}}
                                                    onBlur={storeChanges}
                                                    disabled={demo.archived}
                                                    readOnly={isSpecialDemographics}
                                                    data-testid={`${props.type}-${language}`}
                                                    onChange={(value) =>
                                                        changeValue(
                                                            demo.pk,
                                                            language,
                                                            value.target.value,
                                                        )
                                                    }
                                                />
                                            </td>
                                        ))}
                                    {props.type === 'department' && (
                                        <td>
                                            <InputField
                                                type="number"
                                                value={demo['people_count'] || ''}
                                                style={{width: '100%'}}
                                                onBlur={storeChanges}
                                                testId={`${props.type}-${demo.id}`}
                                                readOnly={isSpecialDemographics}
                                                disabled={demo.archived}
                                                min={0}
                                                onChange={(value) =>
                                                    changeValue(
                                                        demo.pk,
                                                        'people_count',
                                                        value.target.value,
                                                    )
                                                }
                                            />
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
        </>
    )
}

export default DemographicsTable
