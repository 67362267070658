import React from "react";
import {
    intro,
    systemSettings,
    userSettings
} from "./Stories/dashboard";
import {PAGES, RESTRICTIONS} from "../core/const";
import styled from "styled-components";
import {orgscanUserJourney} from "./Stories/OrgScan";
import {setupLeadershipInstance} from "./Stories/LeaderShipAssessment";
import {u2fAdding, u2fRemoving} from "./Stories/System";


export const TOURS = (user) => ([
    {
        title: "stories.dashboard.intro.title",
        tour: "dashboard.global",
        page: PAGES.OrgScan.Dashboard,
        required: user && RESTRICTIONS.OrgScan.RULES.CAN_ANALYSE_DATA.some( role => user[role] )
    }, {
        title: "stories.dashboard.user-settings.title",
        tour: "dashboard.userSettings",
        page: PAGES.OrgScan.Dashboard,
        required: true
    }, {
        title: "stories.dashboard.system-settings.title",
        tour: "dashboard.systemSettings",
        page: PAGES.OrgScan.Dashboard,
        required: user && Object.values( RESTRICTIONS.Settings ).flat().some( role => user[role] )
    }, {
        title: "stories.capture.orgscan-user-journey.title",
        tour: "capture.orgscanUserJourney",
        page: PAGES.OrgScan.Dashboard,
        required: user && user.is_a42
    }, {
        title: "stories.capture.orgscan-user-journey.title",
        tour: "capture.orgscanUserJourney",
        page: PAGES.OrgScan.Capture,
        required: user && user.is_a42
    }, {
        title: "stories.leadership.setup-instance.title",
        tour: "leadership.setupLeadershipInstance",
        page: PAGES.LeaderShip.Dashboard,
        required: true
    }, {
        tour: "system.u2f_add",
        page: ""
    }, {
        tour: "system.u2f_remove",
        page: ""
    }
]);

const stories = (props) => ({
    dashboard: {
        global: intro( props ),
        userSettings: userSettings( props ),
        systemSettings: systemSettings( props )
    },
    capture: {
        orgscanUserJourney: orgscanUserJourney( props )
    },
    leadership: {
        setupLeadershipInstance: setupLeadershipInstance( props )
    },
    system: {
        u2f_add: u2fAdding( props ),
        u2f_remove: u2fRemoving( props )
    }
});


export const Header = styled.h4`
    margin-bottom: 24px;
`;
export const Context = styled.div`
    white-space: "pre-line",
    padding-top: 6px;
    padding-bottom: 6px;
`;

export const ClickElement = {
    byID: id => document.getElementById(id) && document.getElementById(id).click(),
    byClassName: className => {
        if( document.getElementsByClassName( className ).length )
            document.getElementsByClassName( className )[0].click()
    }
}

export const TitleDescription = ({title, description}) =>
    <div style={{marginTop: 20}}>
        { title && title.length && <Header>{ title }</Header> }
        { description && description.length && description.split("\\n").map( (text,i) => <Context key={i}>{ text }</Context>) }
    </div>;

export const TwoColumns = ({title, descriptionLeft, descriptionRight, closing, style}) =>
    <div style={style}>
        { title && <Header>{ title }</Header> }
        <div>
            <div style={{float: "left"}}>{ descriptionLeft }</div>
            <div style={{float: "right"}}>{ descriptionRight }</div>
        </div>
        <div style={{clear: "both"}}>{ closing }</div>
    </div>;

export default stories;