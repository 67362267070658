import React, {useEffect} from 'react';
import {I18n} from 'react-i18nify';
import HeadingWithAction from './HeadingWithAction';
import {LinkedToListStyled, NodeLinkItem} from './LinkedToList';
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice';
import {useNode} from '../../strategyMap/hooks/useNode';
import {getNonArchivedNodes, isExperiment} from '../../strategyMap/utils';
import { ContextMenuEvents } from './HeadingWithActionDropdownMenu';

const LinkedToList = ({nodes, removeItem, removable}) => {
    const {Actions, nodeMap, showHiddenExperiment, goal, goalNodes} = useStrategyMapSlice();

    useEffect( () => {
        nodes
            .forEach( p => {
                if( ! nodeMap[p] || ! nodeMap[p].title ) {
                    Actions.fetchNode(p);
                }
            })
    }, []);

    const getFormatedParents = (node) => {
        return node?.goals?.filter(g => g !== goal.id)
            .map( p => { 
                const itemGoal = goalNodes.filter( g => g.id === p )[0];
                return {
                    ...itemGoal,
                    title: itemGoal.map_name || itemGoal.title,
                }
        })
    };

    const linkedNodes = Object.keys(nodeMap).length > 1 &&
        nodes &&
        nodes
            .filter( nodeID => nodeMap[nodeID]?.goal !== goal.id || nodeMap[nodeID]?.goals?.length > 1 )
            .filter( nodeID => ! nodeMap[nodeID]?.is_archived )
            .filter( nodeID => nodeID !== goal.id )
            .filter( nodeID => ! isExperiment( nodeMap[nodeID]) || ! nodeMap[nodeID].is_finished || nodeMap[nodeID].is_finished && showHiddenExperiment );



    return (
        <LinkedToListStyled>
            { linkedNodes.length === 0 && I18n.t("strategyMap.nodes.linkedToOtherGoalNotExists") }
            { linkedNodes
                .map((nodeId) => {
                    return (
                        <NodeLinkItem
                            sameMap={false}
                            key={nodeId}
                            goals={getFormatedParents(nodeMap[nodeId])}
                            node={nodeMap[nodeId]}
                            removeItem={removeItem}
                            removable={removable}
                        />
                    )
                })}
        </LinkedToListStyled>
    )
}

const OtherGoalsLinks = (props) => {
    const {Actions, nodeMap} = useStrategyMapSlice()
    const {
        node,
        isNC,
        isFinishedExp,
        isEditable,
        isUserChampionOrSquadMember
    } = useNode();

    const showAddRemoveParent =
        (isNC || ! isFinishedExp) && isEditable && isUserChampionOrSquadMember;

    const removeNodeFromOtherGoal = (nodeId) => {
        const parents = nodeMap[nodeId].parents.filter( id => id !== node.id );
        Actions.updateNode({...nodeMap[nodeId], parents, removeNodeFromOtherGoal: node.id});
    }

    const linkedNodes = [getNonArchivedNodes(node.parents, nodeMap),getNonArchivedNodes(node.children, nodeMap)].flat();

    return (
        <div>
            <HeadingWithAction
                title={I18n.t('strategyMap.nodes.linkedToOtherGoal')}
                showAction={showAddRemoveParent}
                icon={'add'}
                testId={'linked-to'}
                onClick={() => props.onEvent( ContextMenuEvents.onNodeShareOtherGoal, {} )}
            />
            <LinkedToList
                nodes={ linkedNodes }
                removable={showAddRemoveParent}
                removeItem={removeNodeFromOtherGoal}
            />
        </div>
    )
}

export default OtherGoalsLinks