import PropTypes from 'prop-types'
import React, {useEffect, useReducer, useState} from 'react'
import {Alert, Col, FormControl, FormLabel, Row} from 'react-bootstrap'
import {I18n} from 'react-i18nify'
import {NotificationContainer} from 'react-notifications'
import {Redirect} from 'react-router-dom'
import {ButtonPrimary} from '../core/components/Buttons'
import {FullScreenLoading} from '../core/components/Loading'
import PasswordForm from '../core/components/PasswordForm'
import {useLanguageSlice} from '../core/hooks/useLanguageSlice'
import PageHeader from '../core/components/pageHeader'
import {InfoText} from '../core/ui/ui'
import Translation from '../core/components/Translation'
import FallbackComponent from '../FallbackComponent'
import {useUserSlice} from '../strategyMap/hooks/useUserSlice'
import history from '../core/utils/history'
import WithSentryErrorHandler from './../hoc/SentryErrorHandler.js'
import organicAgilityLogo from "./../img/organic_agility_logo.png"
import {
    FormContainer,
    FormWrapper,
    Info,
    LanguageContainer,
    Link,
    Links,
    LoginContainer,
    LogoImg,
    Title
} from './Login.styles'
import {A42FormGroup} from "../core/components/BootstrapWrapper";
import {isPublicInstance} from "../core/utils/network";
import { getPageParam } from '../core/components/CommonPage.jsx'


const extractPasswordResetConfirmErrorMessage = (responseData) => {
    let setPasswordErrorMessage
    if (responseData.hasOwnProperty('non_field_errors')) {
        setPasswordErrorMessage = responseData['non_field_errors']
    } else if (responseData.hasOwnProperty('new_password')) {
        setPasswordErrorMessage = responseData['new_password']
    } else if (responseData.hasOwnProperty('token')) {
        setPasswordErrorMessage = responseData['token'][0]
    } else if (
        responseData.hasOwnProperty('detail') &&
        responseData.detail === 'CSRF Failed: CSRF token missing or incorrect.'
    ) {
        setPasswordErrorMessage = 'CSRF Failed: CSRF token missing or incorrect.'
    }
    return setPasswordErrorMessage
}

const initialState = (init) => ({
    showLogin: init.showLogin,
    showPasswordForgot: init.showPasswordForgot,
    passwordFormValid: true,
    showPasswordReset: init.showPasswordReset,
    passwordFormErrors: {},
    newPassword: '',
    alert: null,
    error: null,
    email: '',
    password: '',
    loginError: '',
    loginErrorMessage: null,
    hasError: false,
})

function reducer(state, [type, payload]) {
    const m = {
        PASSWORD_RESET: {
            ...state,
            showLogin: true,
            showPasswordForgot: false,
            alert:
                'If we are able to find this email address in our system, ' +
                'we will send you an email with the password reset information.',
        },
        PASSWORD_RESET_ERROR: {
            ...state,
            hasError: true,
            error: payload,
        },
        PASSWORD_RESET_CONFIRM: {
            ...state,
            passwordFormValid: true,
            passwordFormErrors: {},
            newPassword: '',
            alert: I18n.t('profile.ResultFlashMessage.PasswordOK'),
            showLogin: true,
            showPasswordForgot: false,
            showPasswordReset: false,
        },
        PASSWORD_RESET_CONFIRM_FORM_ERROR: {
            ...state,
            passwordFormValid: false,
            passwordFormErrors: payload && payload.response,
            newPassword: payload && payload.newPassword,
        },
        PASSWORD_RESET_CONFIRM_ERROR: {
            ...state,
            hasError: true,
            error: payload,
        },
        UPDATE: {
            ...state,
            ...payload,
            loginError: false,
        },
        LOGIN_FORM_NOT_FILLED: {
            ...state,
            loginError: true,
            loginErrorMessage: 'WrongCredentials',
        },
        FORGOT_PASSWORD: {
            ...state,
            showLogin: false,
            showPasswordForgot: true,
            showPasswordReset: false,
            loginError: false,
            loginErrorMessage: null,
            alert: null,
        },
        SET_EMAIL: {
            ...state,
            email: payload,
        },
        BACK_TO_LOGIN: {
            ...state,
            showLogin: true,
            showPasswordForgot: false,
            showPasswordReset: false,
            hasError: false,
            error: null,
            loginError: false,
        },
    }
    return m[type]
}

export const Login = (props) => {
    const {current} = useLanguageSlice();
    const {
        Actions,
        isFetching,
        loginAttempts,
        errors,
        authSuccess,
        passwordReset,
        passwordResetConfirmResponse,
        passwordResetError,
        passwordResetConfirmError,
    } = useUserSlice()

    const [state, dispatch] = useReducer(reducer, initialState(props))
    const [redirectToReferrer, setRedirectToReferrer] = useState(false)
    const [isSoftLogout, setIsSoftLogout] = useState(window.location.search.substr(1) === "softlogout")
    const [isUserDeactivated, setIsUserDeactivated] = useState(window.location.search.substr(1) === "userDeactivated")

    const fromPath =
        props.location && props.location.state && props.location.state.from.pathname
            ? props.location.state.from.pathname
            : {pathname: '/'}

    useEffect(() => {
        if( ! isPublicInstance() ) {
            localStorage.removeItem('token')
            setTimeout(() => dispatch(['UPDATE', {}]), 500)

            return () => {
                Actions.setAuthSuccess(false)
            }
        }
    }, []);

    useEffect(() => {
        dispatch(['UPDATE', {}])
    }, [current]);

    useEffect(() => {
        if (authSuccess) {
            setRedirectToReferrer(true)
        }
    }, [authSuccess])

    useEffect(() => {
        if (passwordReset === true) {
            dispatch(['PASSWORD_RESET'])
        } else if (passwordResetError) {
            dispatch(['PASSWORD_RESET_ERROR', passwordResetError.response.statusText])
        }
    }, [passwordReset, passwordResetError])

    useEffect(() => {
        if (passwordResetConfirmResponse && passwordResetConfirmResponse.status === 204) {
            dispatch(['PASSWORD_RESET_CONFIRM'])
            history.push('/login')
        } else if (!passwordResetConfirmError) {
            dispatch([
                'PASSWORD_RESET_CONFIRM_FORM_ERROR',
                {newPassword: state.newPassword, response: passwordResetConfirmResponse},
            ])
        } else if (passwordResetConfirmError) {
            const error = extractPasswordResetConfirmErrorMessage(
                passwordResetConfirmError.response.data,
            )
            dispatch(['PASSWORD_RESET_CONFIRM_ERROR', error])
        }
    }, [passwordResetConfirmResponse, passwordResetConfirmError])

    const handleLogIn = (email, password) => {
        dispatch(['UPDATE', {alert: null}]);
        Actions.fetchToken({email, password})
        Actions.setLoginAttempts(loginAttempts + 1)
    }

    const clearInfoText = () => {
        setIsSoftLogout(false)
        setIsUserDeactivated(false)
    }

    const submit = (e) => {
        e.preventDefault();
        clearInfoText();
        if( ! state.password || !state.email ) {
            dispatch(['LOGIN_FORM_NOT_FILLED']);
            return;
        }
        handleLogIn( state.email, state.password );
    }

    const doPasswordReset = (e) => {
        e.preventDefault()
        Actions.resetPassword({email: state.email})
    }

    const forgotPasswordClick = (e) => {
        e.preventDefault()
        clearInfoText()
        dispatch(['FORGOT_PASSWORD'])
        dispatch(['UPDATE', {password: ''}])
        Actions.setLoginAttempts(0)
        Actions.resetErrors()
    }

    const backToLoginClick = (e) => {
        e.preventDefault()
        dispatch(['BACK_TO_LOGIN'])
    }

    const handleResetPasswordConfirm = (_, newPassword) => {
        const requestData = {
            new_password: newPassword,
            uid: getPageParam(props, 'uid'),
            token: getPageParam(props, 'token'),
        };
        Actions.resetPasswordConfirm(requestData);
    }

    if (redirectToReferrer === true) {
        return <Redirect to={fromPath}/>
    }

    const showLogin = () =>
        <div>
            <Info>{I18n.t('login.Info')}</Info>

            <FormWrapper>
                {loginAttempts > 0 && loginAttempts < 3 && !isFetching && !authSuccess && errors && (
                    <InfoText className="fade in">
                        {I18n.t('login.LoginFailed.AttemptWarning').replace(
                            ':COUNT:',
                            3 - loginAttempts,
                        )}
                    </InfoText>
                )}
                {isSoftLogout && <InfoText className="fade in">
                    {I18n.t('login.LoginFailed.AutoLogout')}
                </InfoText>}
                {isUserDeactivated && <InfoText className="fade in">
                    {I18n.t('login.LoginFailed.UserDeactivated')}
                </InfoText>}
                {state.alert && (
                    <Alert variant="success" className="fade in" data-testID={"success-alert"}>
                        {state.alert}
                    </Alert>
                )}
                {state.loginError && state.loginErrorMessage && !isFetching && (
                    <Alert variant="warning" className="fade in">
                        {I18n.t(`login.LoginFailed.${state.loginErrorMessage}`)}
                    </Alert>
                )}
                { state.hasError && <Alert variant="warning" className="fade in">{ state.error }</Alert> }

                <form onSubmit={submit}>
                    <A42FormGroup>
                        <FormLabel
                            htmlFor={"email-login"}>{I18n.t('login.PlaceHolder.User')}</FormLabel>
                        <FormControl
                            type="email"
                            autoComplete={'off'}
                            id={"email-login"}
                            value={state.email}
                            style={{borderColor: errors && 'red'}}
                            onChange={(e) => {
                                dispatch([
                                    'SET_EMAIL',
                                    e.target.value.toLocaleLowerCase(),
                                ])
                            }
                            }
                        />
                        <FormLabel htmlFor={"password-login"}>
                            {I18n.t('login.PlaceHolder.Password')}
                        </FormLabel>
                        <FormControl
                            type="password"
                            autoComplete={'off'}
                            id={"password-login"}
                            value={state.password}
                            style={{borderColor: errors && 'red'}}
                            onChange={(e) =>
                                dispatch(['UPDATE', {password: e.target.value}])
                            }
                        />
                    </A42FormGroup>
                    <A42FormGroup>
                        <ButtonPrimary type="submit" onClick={submit} fullWidth>
                            {I18n.t('login.Button.Submit')}
                        </ButtonPrimary>
                    </A42FormGroup>
                </form>
                <Links>
                    <Link
                        className="password-link"
                        href=" "
                        onClick={forgotPasswordClick}
                        role="button"
                    >
                        {I18n.t('login.ForgotPassword')}
                    </Link>
                </Links>
            </FormWrapper>
        </div>;

    const showForgotPassword = () =>
        <FormWrapper xs={12} className="password">
            <p>{I18n.t('login.ResetPasswordDescription')}</p>
            <form onSubmit={doPasswordReset}>
                <A42FormGroup>
                    <FormLabel
                        htmlFor="password-reset-email">{I18n.t('login.PlaceHolder.User')}</FormLabel>
                    <FormControl
                        required
                        type="email"
                        id={"password-reset-email"}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                        value={state.email}
                        onChange={(e) =>
                            dispatch(['SET_EMAIL', e.target.value.toLocaleLowerCase()])
                        }
                    />
                </A42FormGroup>
                <A42FormGroup>
                    <ButtonPrimary fullWidth type="submit">
                        {I18n.t('login.ResetPasswordSendResetLink')}
                    </ButtonPrimary>
                </A42FormGroup>
            </form>
            <Links>
                <i className="material-icons">keyboard_arrow_left</i>
                <Link
                    onClick={backToLoginClick}
                    role="button"
                    href=" "
                    className="pull-left"
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    {I18n.t('login.BackToLogin')}
                </Link>
            </Links>
        </FormWrapper>;

    const showResetPassword = () =>
        <FormWrapper className="initial-password">
            <Row>
                <Col xs={12}>
                    <PasswordForm
                        errors={state.passwordFormErrors}
                        confirmCurrentPassword={false}
                        passwordFormValid={state.passwordFormValid}
                        onPasswordSave={handleResetPasswordConfirm}
                        newPassword={state.newPassword}
                        firstAccess={props.firstAccess}
                    />
                    <Links>
                        <Link
                            onClick={backToLoginClick}
                            role="button"
                            href=" "
                            className="pull-left"
                        >
                            <i className="material-icons">keyboard_arrow_left</i>
                            {I18n.t('login.BackToLogin')}
                        </Link>
                    </Links>
                </Col>
            </Row>
        </FormWrapper>;

    return (
        <LoginContainer data-testid={'login'}>
            <PageHeader title={I18n.t('login.PageTitleBrowser')}/>
            <NotificationContainer/>
            <FullScreenLoading loading={isFetching}/>
            <FormContainer>
                <Row>
                    <LogoImg
                        src={organicAgilityLogo}
                        alt="agile42 logo"
                    />
                </Row>
                <Row>
                    <Title>ORGANIC agility</Title>
                </Row>

                { state.showLogin && showLogin() }
                { state.showPasswordForgot && showForgotPassword() }
                { state.showPasswordReset && showResetPassword() }

            </FormContainer>
            <LanguageContainer data-testid={'language-select-container'} className="language">
                <Translation hideTitle/>
            </LanguageContainer>
        </LoginContainer>
    )
}

Login.propTypes = {
    showLogin: PropTypes.bool,
    showPasswordForgot: PropTypes.bool,
    showPasswordReset: PropTypes.bool,
    firstAccess: PropTypes.bool,
    raven: PropTypes.object,
}

Login.defaultProps = {
    showLogin: true,
    showPasswordForgot: false,
    showPasswordReset: false,
    firstAccess: false,
}

export default WithSentryErrorHandler(FallbackComponent, Login)
