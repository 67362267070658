import settings from '../config/settings'
import axios from 'axios'
import {getRequestHeader} from '../core/utils/network'

export default class Api {
    static fetchComments(nodeId) {
        return axios.get(`${settings.apiBaseURL}/nodes/${nodeId}/comments/`, getRequestHeader())
    }

    static addComment(comment) {
        return axios.post(`${settings.apiBaseURL}/node-comments/`, comment, getRequestHeader())
    }

    static vote(commentId, data) {
        return axios.post(
            `${settings.apiBaseURL}/node-comments/${commentId}/vote/`,
            data,
            getRequestHeader(),
        )
    }

    static resolve(commentId) {
        return axios.post(
            `${settings.apiBaseURL}/node-comments/${commentId}/resolve/`,
            {},
            getRequestHeader(),
        )
    }
}
