import Point from "./point.js";
import Shape from "./shape.js";
import Triangle from "./triangle.js";

class Trapezoid extends Shape {
    /**
     * This represents a trapezoid in the 2D plane. It is defined by four vertices.
     * @param {Point} p1 The first vertex of the first base of the trapezoid
     * @param {Point} p2 The second vertex of the first base of the trapezoid
     * @param {Point} p3 The first vertex of the second base of the trapezoid
     * @param {Point} p4 The second vertex of the second base of the trapezoid
     * @param {String} name The name of the trapezoid
     */
    constructor(p1, p2, p3, p4, name = "Trapezoid") {
        super(name);
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
        this.p4 = p4;
    }

    containsPoint(P) {
        /**
         * Method to check if a point is inside the trapezoid.
         * @param {Point} P The point to check
         */
        // Divide the trapezoid into two triangles and check if P is in either of them
        const triangle1 = new Triangle(this.p1, this.p2, this.p3);
        const triangle2 = new Triangle(this.p2, this.p3, this.p4);

        return triangle1.containsPoint(P) || triangle2.containsPoint(P);
    }

    get base1() {
        /**
         * Method to calculate the length of the first base of the trapezoid.
         */
        return Point.distance(this.p1, this.p2);
    }

    get base2() {
        /**
         * Method to calculate the length of the second base of the trapezoid.
         */
        return Point.distance(this.p3, this.p4);
    }

    get side1() {
        /**
         * Method to calculate the length of the first side of the trapezoid.
         */
        return Point.distance(this.p1, this.p4);
    }

    get side2() {
        /**
         * Method to calculate the length of the second side of the trapezoid.
         */
        return Point.distance(this.p2, this.p3);
    }

    get height() {
        /**
         * Method to calculate the height of the trapezoid. The height is the perpendicular distance 
         * between the two bases. The height is calculated by dividing the trapezoid into two triangles 
         * and calculating the height of each triangle. The height of the trapezoid is the average of 
         * the heights of the two triangles. It also checks that the 2 bases are parallel.
         * @returns {Number} The height of the trapezoid
         */
        // Check if the two bases are parallel
        const slope1 = Point.slope(this.p1, this.p2);
        const slope2 = Point.slope(this.p3, this.p4);

        if (Math.abs(slope1 - slope2) > 1e-10) {
            throw new Error(`The two bases of the trapezoid ${this.name} are not parallel ${slope1} !== ${slope2}`);
        }

        // Calculate the height of the trapezoid
        const triangle = new Triangle(this.p1, this.p2, this.p3);

        return triangle.area * 2 / this.base1;
    }


    get area() {
        /**
         * Method to calculate the area of the trapezoid.
         */
        return 0.5 * (this.base1 + this.base2) * this.height;
    }
}

export default Trapezoid;