import {createSlice} from 'redux-starter-kit'
import * as ErrorUtils from '../../core/utils/error'

const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isSaving: false,
    errors: ErrorUtils.getApiErrors(action.payload),
})
const fetchingHandler = (state) => ({...state, isFetching: true})
const savingHandler = (state) => ({...state, isSaving: true})

export const notificationSlice = createSlice({
    slice: 'NOTIFICATION',
    initialState: {
        results: [],
        isSaving: false,
        isFetching: false,
    },
    reducers: {
        fetchNotifications: fetchingHandler,
        fetchNotificationsSuccess: (state, action) => ({
            ...state,
            isFetching: false,
            results: [...action.payload],
        }),
        markNotificationAsDeleted: savingHandler,
        markNotificationAsDeletedSuccess: (state, action) => {
            state.isSaving = false
            state.results = state.results.filter((notification) =>
                action.payload.notifications.every(
                    (notificationId) => notificationId !== notification.id,
                ),
            )
        },
        markNotificationAsRead: savingHandler,
        markNotificationAsReadSuccess: (state, action) => {
            state.isSaving = false
            state.results = action.payload
        },
        requestFailure: errorHandler,
    },
})
