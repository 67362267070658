import styled from 'styled-components'
import {MAGENTA, PALE_GREY} from '../core/ui/_colors'

export const SendBtnContainer = styled.div`
    text-align: center;
    button {
        width: 160px;
    }
`

export const ContentGrid = styled.div`
    padding: 90px 0;
    margin-left: auto;
    margin-right: auto;
    
    width: 1132px;
    max-width: 100%;

    .panel {
        clear: both;
        padding: 5px 15px 15px 20px;
        border-radius: 0px;
        margin-bottom: 40px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);

        .panel-heading {
            padding: 10px 0px;
            font-size: 20px;
            font-weight: 700;
            background: transparent none;
        }
        &.field-error {
            border: 3px solid ${MAGENTA};
            .panel-heading {
                color: ${MAGENTA};
            }
        }
        &.competingValues {
            padding: 20px 0 0;
        }
    }
    @media (min-width: 576px) {
        .panel {
            &.competingValues {
                padding: 25px 15px;
            }
        }
    }
    @media (min-width: 992px) {
        .panel {
            .panel-body {
                padding: 0;
            }
        }
    }
    @media (min-width: 1200px) {
        .panel {
            .panel-heading {
                margin-bottom: 10px;
            }
            .panel-body {
                padding: 0;
            }
        }
    }
    @media print {
        @page { size: A3; }
        break-inside: avoid;
        padding: 20px;
        padding-top: 0;
        margin-top: 0;
    }
`

export const _Table = styled.table`
    width: 100%;
    tr:nth-child(even) {
        background-color: ${PALE_GREY};
    }
    td, th {
        padding: 5px 10px;
        text-align: center;
    }
    tr td:first-child {
        text-align: left;
    }
`