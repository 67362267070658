import styled from 'styled-components'
import 'react-rangeslider/lib/index.css'
import {BLACK_GREY, DARK_GREY, LIGHT_GREY, WHITE} from '../../core/ui/_colors'

export const Container = styled.div`
    width: 100%;
    margin: 10px 10px 500px;
`

export const FilterWrapper = styled.div`
    margin-bottom: 30px;
`

export const FilterRow = styled.div`
    width: 100%;
    @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }
`

export const SearchContainer = styled.div`
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    float: left;
    border-bottom: 1px solid ${LIGHT_GREY};
    height: 56px;
    padding: 16px 12px 16px 24px;
    input {
        float: left;
        padding-left: 5px;
        border: none;
        line-height: 24px;
        min-width: 240px;
        outline: none;
    }
`

export const SelectContainer = styled.div`
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    border-bottom: 1px solid ${LIGHT_GREY};
`

export const SelectContainerItem = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 35px;
    color: ${DARK_GREY};
    flex: 1;
    cursor: pointer;
    ${(p) => (p.dimmed ? 'opacity: 0.3;' : '')}
`

export const FilterButtonContainer = styled.div`
    position: relative;
    min-width: 150px;
    height: 56px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
    cursor: pointer;
    background-color: ${WHITE};
    ${(p) => (p.margin ? 'margin-right: 12px;' : '')}
    ${(p) => (p.inverse ? 'background-color: ' + DARK_GREY + ';' : '')}
    display: flex;
    align-items: center;
    padding: 20px 16px;
    @media only screen and (max-width: 768px) {
        width: 95%;
    }
`

export const FilterButtonContainerWrapper = styled.div`
    position: relative;
    @media only screen and (max-width: 768px) {
        float: none;
        clear: both;
    }
    margin-bottom: 6px;
`

export const FilterCloseButtonContainer = styled.div`
    position: relative;
    float: left;
    width: 70px;
    height: 56px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
    cursor: pointer;
    background-color: ${WHITE};
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FilterContentContainer = styled.div`
    width: 337px;
    max-height: 360px;
    position: absolute;
    top: 56px;
    ${(p) => (!p.anchorRight ? 'left: 0;' : 'right: 0;')}
    background-color: ${WHITE};
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 32%);
    z-index: 999;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
        width: 100%;
        position: relative;
        top: 0px;
    }
`

export const ChoiceContentContainer = styled.div`
    max-height: 300px;
    overflow-y: auto;
    padding: 12px 12px 30px;
`

export const ChoiceRow = styled.div`
    width: 100%;
    height: 44px;
    display: -webkit-inline-flex;
    display: inline-flex;
    cursor: pointer;
    span {
        color: ${(p) => (p.disabled ? LIGHT_GREY : DARK_GREY)};
        margin-left: 10px;
        line-height: 25px;
    }
`

export const Badge = styled.span`
    border-radius: 100%;
    padding: 4px 10px;
    color: white;
`

export const ChoiceBox = styled.div`
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: flex;
    margin: 0;
    border: 1px solid ${(p) => (p.disabled ? LIGHT_GREY : DARK_GREY)};
    align-items: center;
    justify-content: center;
`

export const FilterButtonLabel = styled.div`
    color: ${BLACK_GREY};
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    flex: 1;
    padding-right: 15px;

    ${(p) => (p.inverse ? 'color: ' + WHITE + '; text-align: center' : '')}
`

export const FilterButtonIcon = styled.div`
    @media only screen and (min-width: 768px) {
        ${(p) =>
            p.dropSide ? '-webkit-transform: rotate(270deg); transform: rotate(270deg);' : ''}
    }
    width: 30px;
    i {
        font-size: 20px;
        margin-right: 5px;
    }
`

export const FilterInput = styled.input`
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
`
