import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {GREY} from '../../core/ui/_colors'

const NoMembersSpan = styled.span`
    color: ${GREY}
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
`

const NoUsers = ({text}) => {
    return <NoMembersSpan>{text}</NoMembersSpan>
}

NoUsers.propTypes = {
    text: PropTypes.string,
}

export default NoUsers
