import React from 'react'
import PropTypes from 'prop-types'
import ClickableInput from './ClickableInput'
import Checkbox from '../../../capture/signifier/Checkbox'
import {
    DesiredOutcomeCont,
    DesiredOutcomeText,
    DesiredOutcomeWrapper,
    ListGroupItemStyled,
    ListItemIcon,
} from './DesiredOutcome.styles'

const DesiredOutcomeEdit = (props) => {
    const handleDesiredOutcomeChange = (desiredOutcomePartial) => {
        if (desiredOutcomePartial === undefined) {
            props.onDesiredOutcomeChange({
                type: 'DELETE',
                desiredOutcome: props.desiredOutcome,
                position: props.position,
            })
            return
        }
        if (desiredOutcomePartial.text.trim() === '') {
            return
        }
        if (props.desiredOutcome === undefined) {
            props.onDesiredOutcomeChange({
                type: 'ADD',
                desiredOutcome: desiredOutcomePartial,
            })
            return
        }
        if (props.desiredOutcome.pk === undefined) {
            props.onDesiredOutcomeChange({
                type: 'MODIFY_NEW',
                desiredOutcome: desiredOutcomePartial,
                oldDesiredOutcome: props.desiredOutcome,
                position: props.position,
            })
        } else {
            props.onDesiredOutcomeChange({
                type: 'MODIFY_EXISTING',
                desiredOutcome: Object.assign({}, props.desiredOutcome, {text: desiredOutcomePartial.text}),
                position: props.position,
            })
        }
    }

    const deleteDesiredOutcome = () => {
        if (props.desiredOutcome && props.desiredOutcome.text) {
            handleDesiredOutcomeChange()
        }
    }

    const onCheckbox = () => {
        if (!props.desiredOutcome) return false

        const localDesiredOutcome = Object.assign(props.desiredOutcome, {
            done: !props.desiredOutcome.done,
        })
        handleDesiredOutcomeChange(localDesiredOutcome)
    }

    const text = props.desiredOutcome ? props.desiredOutcome.text : ''
    const isDesiredOutcomeEmpty = text === ''

    return (
        <ListGroupItemStyled>
            <DesiredOutcomeWrapper data-testid={'DesiredOutcomeEdit-container'} className={'clearfix'}>
                {props.isReadOnly ? (
                    <div data-testid={'DesiredOutcomeEdit-readonly'}>
                        <DesiredOutcomeText>{text}</DesiredOutcomeText>
                        {props.done && (
                            <ListItemIcon
                                className={'material-icons pull-left'}
                                aria-hidden="true"
                                role="button"
                            >
                                check
                            </ListItemIcon>
                        )}
                    </div>
                ) : (
                    <DesiredOutcomeCont data-testid={'DesiredOutcomeEdit-edit-field'}>
                        <Checkbox checked={props.done} onClick={onCheckbox} className="pull-left" />

                        <ClickableInput
                            value={text}
                            onChangeFinished={handleDesiredOutcomeChange}
                            className="pull-left"
                            placeholderText={props.placeholder}
                            isEditing={isDesiredOutcomeEmpty}
                        />
                        {!props.isReadOnly && text && (
                            <ListItemIcon
                                onClick={deleteDesiredOutcome}
                                className={'material-icons pull-right'}
                                data-testid={'DesiredOutcomeEdit-delete-button'}
                                aria-hidden="true"
                                role="button"
                            >
                                delete
                            </ListItemIcon>
                        )}
                    </DesiredOutcomeCont>
                )}
            </DesiredOutcomeWrapper>
        </ListGroupItemStyled>
    )
}

DesiredOutcomeEdit.propTypes = {
    desiredOutcome: PropTypes.object,
    onDesiredOutcomeChange: PropTypes.func,
    checkbox: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    position: PropTypes.number,
}

DesiredOutcomeEdit.defaultProps = {
    checkbox: true,
    isReadOnly: false,
}

export default DesiredOutcomeEdit
