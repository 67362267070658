import React, {useRef} from 'react'
import {Overlay} from 'react-bootstrap'
import {NODE_TYPES_TO_NODE_COLOR_MAP} from '../const'
import styled from 'styled-components'
import {useActions} from '../../core/hooks/useActions'
import {strategyMapSlice} from '../redux/slices'
import {getNodeDisplay, isCreatedOrUnfulfilled} from '../utils'
import {useSelector} from 'react-redux'
import {DARK_GREY, WHITE} from '../../core/ui/_colors'
import {A42Popover} from "../../core/components/BootstrapWrapper";

const Header = styled.div`
    width: 205px;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    :hover {
        cursor: pointer;
        span {
            text-decoration: underline;
        }
    }
    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
    }
`

const Bar = styled.div`
    width: 4px;
    height: 12px;
    ${(p) => p.isOpaque && 'opacity: 0.4;'}
    background: ${(p) => p.color};
    margin-right: 6px;
`

const Badge = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(p) => p.color};
    width: 18px;
    height: 18px;
    right: -10px;
    top: -10px;
    border-radius: 50%;
    position: absolute;
    z-index: 999;
    span {
        font-size: 14px;
        font-weight: 500;
        color: ${WHITE};
    }
`

const MiniNode = styled.div`
    width: 32px;
    height: 12px;
    margin: 4px 10px 4px 0px;
    ${(p) => !p.isOutline && `background: ${p.color};`}
    border: 2px solid ${(props) => props.color};
    z-index: 10;
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    color: ${WHITE};
    font-size: 5px;
    font-weight: bold;
`

const NodeItem = (props) => {
    const Actions = useActions(strategyMapSlice.actions)
    const handleMiniChild = () => {
        props.openChildrenNode()
        Actions.showNodeEditingSidebar({id: props.id})
        props.onClick && props.onClick()
    }
    return (
        <div>
            <Header onClick={handleMiniChild} data-testid={`mini-node-item-${props.id}`}>
                <Bar color={props.color} isOpaque={props.isOpaque} />
                <span>
                    {props.symbol} {props.title && `- ${props.title}`}
                </span>
            </Header>
        </div>
    )
}

const MiniChildStackTooltip = (props) => {
    const {nodes, isExperiment, openChildrenNode} = props
    const Actions = useActions(strategyMapSlice.actions)
    const {popoverTarget} = useSelector((state) => state.strategyMap)
    const targetKey = isExperiment ? `exp-${props.node.id}` : `nc-${props.node.id}`
    const target = useRef(null);

    return (
        <div>
            <Overlay
                placement="top"
                show={!!popoverTarget[targetKey]}
                target={popoverTarget[targetKey]}
            >
                {({ placement, arrowProps, show: _show, popper, ...props }) =>
                    <A42Popover
                        placement={'top'}
                        {...props}
                        id={`popover-mini-child-stack-${isExperiment ? 'exp' : 'nc'}`}
                        data-testid={`popover-mini-child-stack`}
                        style={{
                            background: WHITE,
                            color: DARK_GREY,
                            borderRadius: 0,
                            zIndex: 5000,
                            padding: '5px',
                            opacity: 1,
                            ...props.style,
                            position: "absolute",
                            marginLeft: '100px',
                            marginBottom: '10px'
                        }}
                    >
                        {
                            nodes.map( (node, id) =>
                                <NodeItem
                                    key={id}
                                    color={NODE_TYPES_TO_NODE_COLOR_MAP[node.type]}
                                    symbol={getNodeDisplay(node)}
                                    title={node.title}
                                    isOpaque={isCreatedOrUnfulfilled(node)}
                                    openChildrenNode={openChildrenNode}
                                    id={node.id}
                                    onClick={() => {
                                        Actions.setPopoverTarget({key: targetKey, target: null})
                                    }}
                                />
                        )}
                    </A42Popover>
                }
            </Overlay>

            <MiniNode
                ref={target}
                className="col-sm-2"
                data-testid={`mini-node-toggle-${isExperiment ? 'E' : 'N'}`}
                color={NODE_TYPES_TO_NODE_COLOR_MAP[isExperiment ? 'E' : 'N']}
                isOutline={false}
                onClick={(e) => {
                    e.stopPropagation();
                    Actions.setPopoverTarget({
                        key: targetKey,
                        target: !popoverTarget[targetKey] ? e.target : null,
                    });
                }}
            >
                <Badge color={NODE_TYPES_TO_NODE_COLOR_MAP[isExperiment ? 'E' : 'N']}>
                    <span>{nodes.length}</span>
                </Badge>
            </MiniNode>
        </div>
    )
}

export default MiniChildStackTooltip
