import React from 'react'
import {DndProvider} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import {isMobile} from 'react-device-detect'
import {useSelector} from 'react-redux'
import CustomDragLayer from './CustomDragLayer'
import BoardHeader from './BoardHeader'
import BoardRows from './BoardRows'
import ExpandableBoardRows from './ExpandableBoardRows'
import BoardSections from './BoardSections'
import NodeSidebar from './NodeSidebar'
import {FullScreenLoading} from '../../core/components/Loading'
import ExperimentCanvas from './ExperimentCanvas'
import {useWebsocket} from '../../core/hooks/useWebsocket'
import {useActions} from '../../core/hooks/useActions'
import {strategyMapSlice} from '../redux/slices'
import {notificationSlice} from '../redux/notification.slices'
import {commentSlice} from '../redux/comment.slices'
import {getTree, NODE} from './Node'
import {fieldsToSelectors} from '../redux/selectors'
import {useUserSlice} from '../hooks/useUserSlice'
import NodeProvider from '../providers/NodeProvider'
import {useCoreSlice} from '../../core/hooks/useCoreSlice'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {SIDEBARS} from "../../core/redux/slices";

const StrategyMap = (props) => {
    const {
        sfPerTimeArea,
        goal,
        currentOpenNodeId,
        nodeMap,
        experimentCanvasVisible,
        isFetching
    } = useStrategyMapSlice()
    const clickedNodesToChildren = useSelector(fieldsToSelectors['clickedNodesToChildren'])
    const user = useUserSlice()
    const MapActions = useActions(strategyMapSlice.actions)
    const {addCommentSuccess, voteSuccess, resolveSuccess} = useActions(commentSlice.actions)
    const {fetchNotifications} = useActions(notificationSlice.actions)
    const {Actions, sidebarOpen} = useCoreSlice()

    const handleStrategyMapWebsocketChange = (data) => {
        const newNode = data.object

        switch (data.action) {
            case 'created':
                MapActions.addNodeSuccess({...newNode, fromWebsocket: true})
                break

            case 'updated':
                MapActions.updateNodeSuccess(newNode)
                MapActions.logNodeActivity({node: newNode, user: data.user})
                break

            case 'removed':
                MapActions.deleteNodeSuccess(newNode)
                if (newNode.id === currentOpenNodeId) MapActions.onHideSidebar()
                break

            case 'reload':
                Actions.showPageReloadPrompt()
                break
            default:
                break
        }
    }

    const handleWebSocketData = (data) => {
        if( data.user && data.user.pk === user.pk )
            return false;

        switch (data['type']) {
            case 'comment.update':
                if (data.created) {
                    addCommentSuccess(data.comment)
                } else {
                    voteSuccess(data.comment)
                    if (data.comment.resolved) {
                        resolveSuccess(data.comment.id)
                    }
                }
                break

            case 'strategy_map.update':
                handleStrategyMapWebsocketChange(data)
                break

            case 'sf_period_of_inactivity.update':
                const {period_of_inactivity, pk} = data
                MapActions.updatePeriodOfInactivity({pk, period_of_inactivity})
                break

            case 'notification.update':
                setTimeout(() => {
                    fetchNotifications()
                }, 1000)
                break

            default:
                break
        }
    }
    useWebsocket(handleWebSocketData, 'sm', [user])

    const nodeSidebarVisible = sidebarOpen === SIDEBARS.Node;
    const sideBarWidth = nodeSidebarVisible && !isMobile ? 320 : 0

    if (props.params && nodeMap[props.params.nodeId]) {
        if (!nodeSidebarVisible) {
            const parents = getTree(parseInt(props.params.nodeId), nodeMap, NODE.parents)
            parents.map((parent) => MapActions.setClickedNode(nodeMap[parent]))
            MapActions.showNodeEditingSidebar({id: parseInt(props.params.nodeId)})
        }
        props.resetParams()
    }

    const containerStyles = {
        paddingTop: isMobile ? 60 : 90,
        paddingRight: sideBarWidth,
        paddingBottom: 20,
        paddingLeft: 0,
        overflowX: 'hidden'
    }

    const closeTooltip = (e) => MapActions.closePopover();

    const nodeMapForGoalID = [...Object.keys(nodeMap).filter( id => nodeMap[id].goal === goal.id)];

    const nodeMapForGoal = {};
    nodeMapForGoalID.forEach( nodeID => {
        nodeMapForGoal[nodeID] = nodeMap[nodeID];
    });

    return (
        <NodeProvider style={{overflowX: 'hidden'}}>
            <div onClick={closeTooltip}>
                <FullScreenLoading loading={isFetching} />
                {experimentCanvasVisible && <ExperimentCanvas />}
                <DndProvider backend={HTML5Backend}>
                    <CustomDragLayer />
                    <div style={containerStyles} className={'container-fluid'}>
                        <BoardHeader sidebarWidth={sideBarWidth} />
                        <BoardSections sidebarWidth={sideBarWidth} />
                        <BoardRows nodesPerTimeArea={[[], [], [goal.id]]} />
                        <ExpandableBoardRows
                            nodesPerTimeArea={sfPerTimeArea}
                            clickedNodesToChildren={clickedNodesToChildren}
                            nodeParentId={goal && goal.id}
                            nodeMap={nodeMapForGoal}
                        />
                    </div>
                </DndProvider>
                {nodeSidebarVisible && <NodeSidebar />}
            </div>
        </NodeProvider>
    )
}

export default StrategyMap
