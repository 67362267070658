import React from "react";
import {useUserSlice} from "../../strategyMap/hooks/useUserSlice";
import {ButtonTertiary} from "../../core/components/Buttons";
import styled from "styled-components";
import {I18n} from "react-i18nify";
import {useCoreSlice} from "../../core/hooks/useCoreSlice";

const Icon = styled.i`
    margin-top: -3px;
    margin-right: 8px;
    float: left;
    width: 25px;
    overflow: hidden;
`;

const SecondAuthenticationComponent = () => {

    const {is_dongle_user} = useUserSlice();
    const {Actions: CoreActions} = useCoreSlice();

    if( is_dongle_user )
        return <ButtonTertiary onClick={() => CoreActions.startHelpTour("system.u2f_remove")} >
            <Icon className="material-icons-outlined">lock</Icon>{I18n.t("app.auth.deleteToken")}
        </ButtonTertiary>;
    else
        return <ButtonTertiary onClick={() => CoreActions.startHelpTour("system.u2f_add")} >
            <Icon className="material-icons-outlined" >lock-open</Icon>{I18n.t("app.auth.addToken")}
        </ButtonTertiary>;
}

export default SecondAuthenticationComponent;