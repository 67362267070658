import React from 'react';
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {SIDEBARS} from "../core/redux/slices";
import {SidebarNotificationContainer} from "./SidebarNotificationContainer";
import UserProfileContainer from "./UserProfileContainer/UserProfileContainer";
import DashboardConfig from "./DashboardConfig";
import DemoTool from '../core/components/DemoTool';

const Sidebar = () => {
    const {sidebarOpen} = useCoreSlice();

    switch(sidebarOpen){

        case SIDEBARS.UserProfile:
            return <UserProfileContainer />;

        case SIDEBARS.Notification:
            return <SidebarNotificationContainer />;

        case SIDEBARS.Dashboard:
            return <DashboardConfig />;

        case SIDEBARS.Admin:
            return <DemoTool />;

        default:
            return null;
    }
}

export default Sidebar;