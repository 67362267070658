import axios from 'axios'
import {getRequestHeader} from '../core/utils/network'
import settings from '../config/settings'

export default class Api {
    static disconnectSlack() {
        return axios.get(`${settings.apiBaseURL}/slack/disconnect/`, getRequestHeader())
    }

    static disconnectTrello() {
        return axios.get(`${settings.apiBaseURL}/trello/disconnect/`, getRequestHeader())
    }

    static connectTrello(token) {
        return axios.get(
            `${settings.apiBaseURL}/trello/authorization/?access_token=${token}`,
            getRequestHeader(),
        )
    }

    static trelloWorkspaces() {
        return axios.get(`${settings.apiBaseURL}/trello/workspaces/`, getRequestHeader())
    }
}
