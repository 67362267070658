import React, {useState} from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import UserWithAvatar from './UserWithAvatar'
import UserListPopover from './UserListPopover'
import ClickOutside from '@tntd/react-click-outside'
import {isUserASponsor} from '../utils'
import {I18n} from 'react-i18nify'
import {useUserSlice} from '../hooks/useUserSlice'
import {showPopupMessage} from '../../core/utils/notifications'

const SponsorItem = ({sponsor, node, isAbleToEdit}) => {
    const {Actions, users, nodeMap} = useStrategyMapSlice()
    const [open, setOpen] = useState(false)
    const {user} = sponsor
    const currentActiveUser = useUserSlice()
    const isSponsor = isAbleToEdit && isUserASponsor(currentActiveUser, node, nodeMap)
    const totalSponsors = node.sponsors.length

    return (
        <UserWithAvatar
            testId={`sponsor-user-avatar-${sponsor.id}-${user.pk}`}
            user={user}
            showAvatar
            enableRemove={isSponsor}
            onClick={() => isSponsor && setOpen(!open)}
            handleRemove={() => {
                if (totalSponsors > 1 && sponsor.user.pk === currentActiveUser.pk) {
                    showPopupMessage({
                        title: I18n.t('strategyMap.messages.warning'),
                        message: I18n.t('strategyMap.messages.warningRemoveMyselfAsSponsor'),
                        cancelLabel: I18n.t('strategyMap.messages.cancel'),
                        confirmLabel: I18n.t('strategyMap.messages.yes'),
                        onConfirmCallback: () =>
                            Actions.deleteSponsor({nodeId: node.id, sponsorID: sponsor.id}),
                        onCancelCallback: () => {},
                    })
                    return false
                }
                Actions.deleteSponsor({nodeId: node.id, sponsorID: sponsor.id})
            }}
        >
            <ClickOutside
                onClickOutside={() => {
                    open && setOpen(false)
                }}
            >
                {open && (
                    <UserListPopover
                        testId={`sponsor-user-list-popover-${sponsor.id}-${user.pk}`}
                        users={users.filter((user) =>
                            node.sponsors.every((sponsor) => sponsor.user.pk !== user.pk),
                        )}
                        open={open}
                        handleItemClick={(user) => {
                            if (user.pk !== currentActiveUser.pk) {
                                showPopupMessage({
                                    title: I18n.t('strategyMap.messages.warning'),
                                    message: I18n.t(
                                        'strategyMap.messages.warningAddingSomeoneElseAsSponsor',
                                    ),
                                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                                    confirmLabel: I18n.t('strategyMap.messages.yes'),
                                    onConfirmCallback: () =>
                                        Actions.switchSponsor({
                                            user: user.pk,
                                            nodeId: node.id,
                                            sponsorID: sponsor.id,
                                        }),
                                    onCancelCallback: () => {},
                                })
                                return false
                            }
                            Actions.switchSponsor({
                                user: user.pk,
                                nodeId: node.id,
                                sponsorID: sponsor.id,
                            })
                            setOpen(false)
                        }}
                    />
                )}
            </ClickOutside>
        </UserWithAvatar>
    )
}

export default SponsorItem
