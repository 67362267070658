import React from 'react'
import styled from 'styled-components'
import { I18n } from 'react-i18nify'
import { displayToastMessage } from '../utils/notifications'
import { ButtonPrimary } from './Buttons'
import { FormControl } from 'react-bootstrap'

const CopyToClipboardContainer = styled.div`
    overflow: hidden;
    button {
        margin: 0;
        height: 34px;
        padding: 0;
        font-size: 20px;
        line-height: 1.5px;
    }
    input {
        width: calc(100% - 46px);
    }
    input, button {
        float: left;
    }
    @media print {
        display: none;
    }
    i {
        height: 100%;
        width: 30px;
        margin: 0 8px;
        display: flex !important;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
`

export const CopyToClipboard = (props) => {

    const onButtonClick = () => {
        navigator.clipboard.writeText(props.text)
        displayToastMessage('success', I18n.t('app.copyToClipboardSuccess'))
    }

    return (
        <CopyToClipboardContainer>
            <FormControl defaultValue={props.text} onFocus={(event) => event.target.select()} />
            <ButtonPrimary onClick={onButtonClick}>
                <i className="material-icons pull-right" aria-hidden="true" role="button">content_copy</i>
            </ButtonPrimary>
        </CopyToClipboardContainer>
    )
}