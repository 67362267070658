import React from 'react'
import styled from 'styled-components'
import {AVATAR_COLORS, BRIGHT_GREY, DARK_GREY} from '../ui/_colors'

const AvatarContainer = styled.div`
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: ${DARK_GREY};
    box-shadow: 0px 0px 0px 1px lightblue;
    color: ${props => props.fontColour || BRIGHT_GREY};
    font-weight: normal;
    text-align: center;
    font-size: 15px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    background-color: ${(props) => AVATAR_COLORS['COLOR' + (props.colorCode || '0')]}
    float: ${(props) => props.pull || 'none'}
    img {
        width: 100%!important;
        height: 100%!important;
        border-radius: 50%;
        vertical-align: initial;
        margin: 0!important;
    }
`
const Avatar = (props) => <AvatarContainer {...props} data-testid={props.dataTestid}>
        {props.profilePicture ?
            <img src={props.profilePicture} /> :
            props.text
        }
    </AvatarContainer>

export default Avatar
