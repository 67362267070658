import React from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {isUserASponsor} from '../utils'
import SponsorItem from './SponsorItem'
import AddSponsor from './AddSponsor'
import {RESTRICTIONS} from '../../core/const'
import {useUserSlice} from '../hooks/useUserSlice'

const Sponsor = (props) => {
    const {nodeMap} = useStrategyMapSlice()
    const currentActiveUser = useUserSlice()
    const totalSponsors = props.node.sponsors.length

    return (
        <div>
            {props.node.sponsors.map((sponsor) => (
                <SponsorItem
                    key={sponsor.id}
                    sponsor={sponsor}
                    node={props.node}
                    isAbleToEdit={props.isAbleToEdit}
                />
            ))}
            {props.isAbleToEdit &&
                (totalSponsors === 0 ||
                    (totalSponsors < RESTRICTIONS.StrategyMap.MAX_SPONSORS_ALLOWED &&
                        isUserASponsor(currentActiveUser, props.node, nodeMap))) && (
                    <AddSponsor node={props.node} />
                )}
        </div>
    )
}

export default Sponsor
