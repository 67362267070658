import React, {useState} from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import UserListPopover from './UserListPopover'
import styled from 'styled-components'
import {PALE_GREY} from '../../core/ui/_colors'
import ClickOutside from '@tntd/react-click-outside'
import {I18n} from 'react-i18nify'
import {useUserSlice} from '../hooks/useUserSlice'
import {showPopupMessage} from '../../core/utils/notifications'

export const Container = styled.div`
    width: 100%;
    padding: 4px;
    display: flex;
    position: relative;
    align-items: center;
    ${(p) => (p.clickable ? 'cursor: pointer;' : '')}
    transition: background .5s;
    &:hover {
        background: ${PALE_GREY};
        .show-hover {
            display: block;
        }
    }
`

const Title = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px 5px;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
`

const AddSponsor = (props) => {
    const {Actions, users} = useStrategyMapSlice()
    const [open, setOpen] = useState(false)
    const currentActiveUser = useUserSlice()

    return (
        <Container clickable>
            <Title onClick={() => setOpen(!open)} data-testid={'add-sponsor-button'}>
                {I18n.t("strategyMap.nodes.add a sponsor")}
            </Title>
            <ClickOutside
                onClickOutside={() => {
                    open && setOpen(false)
                }}
            >
                {open && (
                    <UserListPopover
                        users={users.filter((user) =>
                            props.node.sponsors.every((sponsor) => sponsor.user.pk !== user.pk),
                        )}
                        open={open}
                        handleItemClick={(user) => {
                            if (!props.node.sponsors.length && user.pk !== currentActiveUser.pk) {
                                showPopupMessage({
                                    title: I18n.t('strategyMap.messages.warning'),
                                    message: I18n.t(
                                        'strategyMap.messages.warningAddingSomeoneElseAsSponsor',
                                    ),
                                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                                    confirmLabel: I18n.t('strategyMap.messages.yes'),
                                    onConfirmCallback: () =>
                                        Actions.addSponsor({
                                            userPk: user.pk,
                                            nodeId: props.node.id,
                                        }),
                                    onCancelCallback: () => {},
                                })
                                return false
                            }
                            Actions.addSponsor({userPk: user.pk, nodeId: props.node.id})
                            setOpen(false)
                        }}
                    />
                )}
            </ClickOutside>
        </Container>
    )
}

export default AddSponsor
