import React from 'react'

const ICBottom = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="ic_bottom"
            width="11.486"
            height="18.536"
            viewBox="0 0 11.486 18.536"
        >
            <g id="Gruppe_7969" data-name="Gruppe 7969" transform="rotate(90 345.768 -300.818)">
                <path
                    id="Rechteck_446"
                    d="M0 0h2v8H0z"
                    className="cls-1"
                    data-name="Rechteck 446"
                    transform="rotate(-135 336.541 -113.069)"
                    fill={'#444f57'}
                />
                <path
                    id="Rechteck_447"
                    d="M0 0h2v8H0z"
                    className="cls-1"
                    data-name="Rechteck 447"
                    transform="rotate(-45 368.628 -770.862)"
                    fill={'#444f57'}
                />
                <path
                    id="Rechteck_19750"
                    d="M0 0h2v11H0z"
                    className="cls-1"
                    data-name="Rechteck 19750"
                    transform="rotate(-90 343.373 -303.213)"
                    fill={'#444f57'}
                />
                <path
                    id="Rechteck_19751"
                    d="M0 0h2v8H0z"
                    className="cls-1"
                    data-name="Rechteck 19751"
                    transform="rotate(-135 339.042 -114.105)"
                    fill={'#444f57'}
                />
                <path
                    id="Rechteck_19752"
                    d="M0 0h2v8H0z"
                    className="cls-1"
                    data-name="Rechteck 19752"
                    transform="rotate(-45 371.128 -776.897)"
                    fill={'#444f57'}
                />
            </g>
        </svg>
    )
}
export default ICBottom
