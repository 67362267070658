import React from 'react'
import {renderNodes} from '../utils'

const ExpandableBoardRows = ({nodesPerTimeArea, clickedNodesToChildren, nodeMap, nodeParentId}) => (
    <div style={{width: '100%'}} data-testid={'expandable-board-rows'}>
        {renderNodes(nodesPerTimeArea, clickedNodesToChildren, nodeMap, nodeParentId)}
    </div>
)

export default ExpandableBoardRows
