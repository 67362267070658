import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'

export const createErrorClass = (fieldName, errors, useLodash) => {
    return classNames(fieldName, {
        'field-error': useLodash ? !_.isEmpty(errors) : errors.includes(fieldName),
    })
}

export const fillingPlaceholdersHTML = (string, placeholders) => {
    let workingString = '-' + string + '-'

    Object.keys(placeholders).forEach((keyword) => {
        let newstring = ''
        if (workingString.search(keyword)) {
            const split_string = workingString.split(keyword)

            for (const part in split_string) {
                newstring += split_string[part]
                if (part + 1 < split_string.length) {
                    newstring += '<b>' + placeholders[keyword] + '</b>'
                }
            }
            workingString = newstring
        }
    })
    workingString = workingString.substr(1, workingString.length - 2)
    return <h5 dangerouslySetInnerHTML={{__html: workingString}} />
}