import React, {useEffect, useState} from 'react'
import {Image} from 'react-bootstrap'
import {isMacOs, isMobile, isSafari} from 'react-device-detect'
import {connect} from 'react-redux'
import Avatar from '../Avatar'
import {bindActionCreators} from 'redux'
import {strategyMapSlice} from '../../../strategyMap/redux/slices'
import {coreSlice, SIDEBARS} from '../../redux/slices'
import {notificationSlice} from '../../../strategyMap/redux/notification.slices'
import NodeSearch from './NodeSearch'
import {useHistory} from '../../hooks/useHistory'
import {PAGES} from '../../const'
import {LIGHT_GREY} from '../../ui/_colors'
import {
    BadgeBox,
    CenterContainer,
    MainNavbar,
    MainNavIcon,
    SenseMakerLogo,
    SettingsIcon,
} from './Navbar.styles'
import verticalSensemakerIcon from "../../../img/power-by-sense-maker-vertical.png"
import horizontalSensemakerIcon from "../../../img/power-by-sense-maker-horizontal.png"
import Translation from '../Translation'
import {isLoggedIn} from '../../utils/auth'
import ExpandCollapseNodes from "./ExpandCollapseNodes"
import ToggleFinishedExperiments from "./ToggleFinishedExperiments"
import StrategyMapSwitch from "./StrategyMapSwitch";
import {useCoreSlice} from "../../hooks/useCoreSlice";
import {useStrategyMapSlice} from '../../../strategyMap/hooks/useStrategyMapSlice'
import {Nav, NavActions, NavItem, NavItemIntern} from "../BootstrapWrapper";
import TutorialsIcon from "../../../ReactTourStories/TutorialsIcon";
import MainNavigation from "./MainNavigation/MainNavigation";
import NavbarCadenceSummary from "./StrategyMap/NavbarCadenceSummary";
import {NavbarCadenceProgress} from "./StrategyMap/NavbarCadenceProgress";
import Cadence from "./StrategyMap/Cadence/Cadence";
import { windowOpen } from '../../utils/navigation'
import { UserEngagement } from './UserEngagement'

const DOCUMENT_ETF_STRATEGY_MAP_EXPLANATION_HELP_PDF =
    'https://www.organic-agility.com/agile_strategy_map/';

const Pages = {
    ...PAGES,
    Settings: 'settings',
    All: 'all'
}

const FeatureList = {
    admintools: 'admintools',
    strategymap: 'strategymap'
}

export const NavbarB = (props) => {
    const {isSubscriptionActive, features, Actions} = useCoreSlice();
    const {isStrategyMapPage, isLeaderShip, currentPage} = useHistory();
    const [toggleShowSecondBar, setToggleShowSecondBar] = useState(false);
    const {strategicIterations, targets, goal} = useStrategyMapSlice();

    const currentStrategicIteration = strategicIterations.find( iteration => iteration.is_running && iteration.goal_id === goal.id );

    const filterPage = (page) => {
        const currentPageFormated = currentPage !== '' ? currentPage : Pages.Dashboard
        
        return page.forPage.includes(currentPageFormated) || page.forPage.includes(Pages.All)
    };

    const isAgile42 = props.user.is_a42;
    const isAdmin = props.user.is_admin;

    const NavBarItemsCollection = [
        {
            forMobile: true,
            forDesktop: true,
            forPage: [Pages.OrgScan.Capture],
            logo: true,
            item: () =>
                <SenseMakerLogo>
                    <Image src={getSenseMakerImage()} height={'60px'} alt={'Power By Sense Maker'}/>
                </SenseMakerLogo>
        },
        {
            forMobile: true,
            forMobileOnSub: true,
            forDesktop: true,
            forPage: [Pages.StrategyMap],
            featuresRequired: [FeatureList.strategymap],
            item: () =>
                <ExpandCollapseNodes />
        },
        {
            forMobile: true,
            forMobileOnSub: true,
            forDesktop: true,
            forPage: [Pages.StrategyMap],
            featuresRequired: [FeatureList.strategymap],
            item: () =>
                <ToggleFinishedExperiments />
        },
        {
            forMobile: true,
            forMobileOnSub: true,
            forDesktop: true,
            forPage: [Pages.Dashboard],
            featuresRequired: [FeatureList.admintools],
            permissions: () => isSubscriptionActive,
            item: () =>
                <NavItemIntern
                    className="dashboard_customize"
                    onClick={() => Actions.toggleSidebarVisibility(SIDEBARS.Dashboard)}
                >
                    <MainNavIcon className="material-icons-outlined">dashboard_customize</MainNavIcon>
                </NavItemIntern>
        },
        {
            forMobile: true,
            forMobileOnSub: true,
            forDesktop: true,
            forPage: [Pages.StrategyMap, Pages.OrgScan.Dashboard, Pages.LeaderShip.Dashboard, Pages.Dashboard],
            featuresRequired: [FeatureList.admintools, FeatureList.strategymap],
            permissions: () => isSubscriptionActive && !props.experimentCanvasVisible && isAgile42 && isAdmin,
            item: () =>
                <NavItemIntern
                    className="bolt"
                    onClick={() => Actions.toggleSidebarVisibility(SIDEBARS.Admin)}
                >
                    <MainNavIcon className="material-icons-outlined">bolt</MainNavIcon>
                </NavItemIntern>
        },
        {
            forMobile: true,
            forMobileOnSub: true,
            forDesktop: true,
            forPage: [Pages.StrategyMap],
            featuresRequired: [FeatureList.strategymap],
            item: () => <StrategyMapSwitch />
        },
        {
            forMobile: true,
            forMobileOnSub: true,
            forDesktop: true,
            forPage: [Pages.StrategyMap],
            featuresRequired: [FeatureList.strategymap],
            item: () =>
                <NodeSearch/>
        },
        {
            forMobile: false,
            forDesktop: true,
            forPage: [Pages.OrgScan.Dashboard, Pages.OrgScan.Capture, Pages.LeaderShip.Dashboard],
            item: () =>
                <TutorialsIcon />
        },
        {
            forMobile: true,
            forDesktop: true,
            forPage: [Pages.OrgScan.Analysis, Pages.LeaderShip.Analysis],
            featuresRequired: [FeatureList.admintools],
            permissions: () => props.user && props.user.is_analyst && isAdmin,
            item: () =>
                <UserEngagement />
        },
        {
            forMobile: false,
            forDesktop: true,
            forPage: [Pages.StrategyMap],
            featuresRequired: [FeatureList.strategymap],
            item: () =>
                    <NavItemIntern
                        className="help"
                        onClick={() => windowOpen(DOCUMENT_ETF_STRATEGY_MAP_EXPLANATION_HELP_PDF, '_blank').focus()}
                    >
                        <MainNavIcon className="material-icons-outlined">info</MainNavIcon>
                    </NavItemIntern>
        },
        {
            forMobile: true,
            forDesktop: true,
            forPage: [Pages.All],
            featuresRequired: [FeatureList.strategymap],
            item: () =>
                <NavItemIntern
                        className="notifications"
                        data-testid={'notification-navbar'}
                        active={props.notificationActive}
                        onClick={() => Actions.toggleSidebarVisibility(SIDEBARS.Notification)}
                    >
                        <MainNavIcon className="material-icons-outlined">notifications</MainNavIcon>
                        {notificationCount > 0 && <BadgeBox>{notificationCount}</BadgeBox>}
                    </NavItemIntern>
        },
        {
            forMobile: true,
            forDesktop: true,
            forPage: [Pages.All],
            permissions: () => props.user && props.user.pk && props.userProfileIconVisible,
            item: () =>
                <NavItemIntern
                    data-testid={'user-navbar'}
                    className="user"
                    onClick={(e) => {
                        e.preventDefault();
                        Actions.toggleSidebarVisibility(SIDEBARS.UserProfile);
                    }}
                    active={props.userActive}
                >
                    <Avatar colorCode={props.user && props.user.color_code}
                            text={props.user && props.user.initials}
                            profilePicture={props.user && props.user.profile_picture}/>
                    <SettingsIcon className="material-icons-outlined">settings</SettingsIcon>
                </NavItemIntern>
        },
        {
            forMobile: true,
            forDesktop: false,
            forPage: [Pages.All],
            permissions: () =>
                NavBarItemsCollection
                    .filter( version => version.forMobile && isMobile && version.forMobileOnSub)
                    .filter( filterPage )
                    .filter( feature => !feature.featuresRequired || feature.featuresRequired.every( f => features[f]))
                    .filter( permission => !permission.permissions || permission.permissions()).length -1 > 0,
            item: () =>
                <NavItemIntern
                    data-testid={'mobile-navbar-switch'}
                    onClick={() => setToggleShowSecondBar(!toggleShowSecondBar)}
                >
                    <span className="material-icons-outlined">more_vert</span>
                </NavItemIntern>
        },
        {
            forMobile: true,
            forDesktop: true,
            forPage: [Pages.All],
            logo: true,
            permissions: () => ! isLoggedIn(),
            item: () =>
                <CenterContainer style={{height: 60, marginRight: 10}}>
                    <Translation hideTitle/>
                </CenterContainer>
        }
    ];

    useEffect(() => {
        const { results } = props.notifications;
        const shouldFetchNotifications =
            isStrategyMapPage && results && results.length === 0;

        if (shouldFetchNotifications) {
            props.fetchNotifications();
        }
    }, []);

    const backClick = () => {
        if (props.experimentCanvasVisible) {
            props.setExperimentCanvasVisible(false);
            props.setPageHeader('');
            return;
        }
        Actions.closeSidebar();
        let nextPage = PAGES.Dashboard;
        if (currentPage !== PAGES.Billing) {
            nextPage = isLeaderShip ? PAGES.LeaderShip.Dashboard : PAGES.OrgScan.Dashboard;
        }
        props.gotoPage( nextPage );
    }

    const getSenseMakerImage = () => {
        return window.innerWidth < 500
            ? verticalSensemakerIcon
            : horizontalSensemakerIcon
    }

    const notificationCount = props.notifications.results.filter(
        (notification) => !notification.is_read,
    ).length || 0;

    const reduceVW = !isMacOs && isSafari && !isMobile && window.innerHeight < document.body.clientHeight ? 20 : 0;

    const getNavigationTitle = () => {
        if (props.subPageHeading) {
            return props.subPageHeading;
        } else if (isStrategyMapPage && currentStrategicIteration) {
            return false;
        } else {
            return props.pageHeading;
        }
    };

    return (
        <MainNavbar id={'navbar'} reduceWidth={reduceVW} staticTop fluid className={'header'}>

            <MainNavigation title={getNavigationTitle()} onClick={backClick} icon={props.pageIcon} />

            { isStrategyMapPage && <NavbarCadenceSummary cadence={new Cadence(currentStrategicIteration, targets)} /> }

            <NavActions style={{marginRight: 0}}>
                { NavBarItemsCollection
                    .filter( version => version.forMobile && isMobile && !version.forMobileOnSub || version.forDesktop && !isMobile )
                    .filter( filterPage )
                    .filter( feature => !feature.featuresRequired || feature.featuresRequired.every( f => features[f]))
                    .filter( permission => !permission.permissions || permission.permissions())
                    .map( (item,idx) => <NavItem key={idx} logo={item.logo} noHover={item.noHover || false}>{item.item()}</NavItem>
                )}

                { toggleShowSecondBar && <div style={{width: '100%', height: '1px', borderTop: '1px solid '+LIGHT_GREY, top: '60px', position: 'absolute'}} /> }
                { toggleShowSecondBar && <Nav style={{clear: 'both', borderTop: '1px solid '+LIGHT_GREY, float: 'right'}}>
                        { NavBarItemsCollection
                            .filter( version => version.forMobile && isMobile )
                            .filter( version => version.forMobileOnSub )
                            .filter( filterPage )
                            .filter( feature => !feature.featuresRequired || feature.featuresRequired.every( f => features[f]))
                            .filter( permission => !permission.permissions || permission.permissions())
                            .map( (item,idx) => <NavItem style={{paddingBottom: '15px'}} key={idx} noHover={item.noHover || false}>{item.item()}</NavItem>
                        )}
                </Nav>}
            </NavActions>

            { isStrategyMapPage && <NavbarCadenceProgress cadence={new Cadence(currentStrategicIteration, targets)} />}

        </MainNavbar>
    )
}

NavbarB.defaultProps = {
    pageHeading: '',
    subPageHeading: '',
    notificationActive: false,
    userActive: false,
}

const mapStateToProps = ({sidebarVisible, strategyMap, user, iconVisible, notifications, core}) => {
    return {
        notificationsIconVisible: iconVisible.notifications,
        userProfileIconVisible: iconVisible.userProfile,
        helpIconVisible: iconVisible.help,
        experimentCanvasVisible: strategyMap.experimentCanvasVisible,
        notifications,
        user,
        ...core,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...strategyMapSlice.actions,
            ...coreSlice.actions,
            ...notificationSlice.actions,
        },
        dispatch,
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarB)
