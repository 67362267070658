import React from 'react'
import Node from './Node'

const NodeDragPreview = (props) => {
    const styles = {
        display: 'inline-block',
        transform: 'rotate(-7deg)',
        WebkitTransform: 'rotate(-7deg)',
    }

    styles.width = `${props.node.clientWidth || 243}px`
    styles.height = `${props.node.clientHeight || 243}px`

    return (
        <div style={styles}>
            <Node nodeId={props.node.id} />
        </div>
    )
}

export default NodeDragPreview
