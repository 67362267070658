import React, {useMemo} from 'react'
import * as calc from './ChartCalculations'
import {DecisionAnswers} from './StackedBar/agileBarConfig'
import {I18n} from 'react-i18nify'
import _ from 'lodash'
import {ChartType, SubTypes, ordering} from './ChartsConfig'
import {useSelector} from 'react-redux'
import {useAnalysisSlice} from '../analysis/hooks/useAnalysisSlice'
import {useCoreSlice} from '../core/hooks/useCoreSlice'

import StackedBar from './StackedBar/StackedBar'
import ExperimentActivity from './ExperimentActivity/ExperimentActivity'
import {barSchema, tenureColor, tenureColorPie, trafficLightColor} from './ChartColorSchema'
import PieChartsProgress from './PieChartProgress/PieChartsProgress'
import BarHorizontalCustom from './BarHorizontalCustom/BarHorizontalCustom'
import {LineChart} from './LineChart/LineChart'
import {TableChart} from './Table/TableChart'
import HeatMap from './HeatMap/HeatMap'
import PrintablePiecharts, { orderPie } from './Piechart/PrintablePiecharts'
import {AutonomyVsControl} from './AreaChart/AutonomyVsControl'

export const Charts = (props) => {
    const demographics = useSelector((state) => state.demographics)
    const {affinity, wholeCompanyPeopleCount, departments} = useAnalysisSlice()
    const {client} = useCoreSlice()

    const dataPoints = useMemo(() => {
        if (
            props.Type !== ChartType.ExperimentActivity &&
            props.dataObject &&
            props.dataObject.dataShown &&
            ! demographics.loading
        ) {
            const dataShown = calc.externalFiltering(props.dataObject.dataShown, props.filter)
            const dataCompare = calc.externalFiltering(props.dataObject.dataCompare, props.filter)
            return props.dataObject.dataShown.length === 0
                ? [props.dataObject.dataShown]
                : calc.getPoints(
                      [dataShown, dataCompare],
                      props.isCompared,
                      props.Type,
                      props.subType,
                      props.subTypes,
                      props.testId || 'test',
                      demographics
                  )
        }
        return null
    }, [props.dataObject, props.isCompared, demographics])

    if ( props.Type !== ChartType.ExperimentActivity && !dataPoints )
        return <div id={props.testId} data-testid={props.testId} />

    switch (props.Type) {

        case ChartType.PieChart:
            let colorSchemePie = [];
            if (props.subType === 'period') {
                const orderedDataPoints = orderPie(dataPoints, DecisionAnswers.speed, false);
                colorSchemePie = Object.keys(orderedDataPoints).map( point => tenureColorPie[point]);
            } else {
                const tenureColorArr = Object.values(tenureColor);
                let tenureColorIndex = 0;
                for( let i = 0; i < Object.keys(dataPoints).length; i++ ) {
                    colorSchemePie.push(tenureColorArr[tenureColorIndex]);
                    tenureColorIndex++;
                    if (tenureColorIndex >= Object.keys(dataPoints).length) {
                        tenureColorIndex = 0;
                    }
                }
            }
            return (
                <PrintablePiecharts testId={props.testId}
                           data={dataPoints}
                           subType={props.subType}
                           demographics={demographics}
                           colorScheme={colorSchemePie} />
            )
        case ChartType.HeatMapCrossProduct:
            const tableColumns = _.cloneDeep(props.columns);
            const index = tableColumns.indexOf('notsure');
            if (index > -1)
                tableColumns.splice(index, 1);
            
            return (
                <TableChart
                    data={dataPoints}
                    ordering={props.ordering}
                    columns={tableColumns}
                    colorSchema={props.colorSchema}
                />
            )
        case ChartType.Statistics:
            switch(props.subType) {
                case SubTypes.statistics.stonesAverage:
                case SubTypes.statistics.trianglesAverage:
                case SubTypes.statistics.mostInfluencedValues:
                    return <LineChart
                            testId={props.testId}
                            data={dataPoints}
                            coordinate={props.coordinate || {}}
                            range={props.range}
                        />

                case SubTypes.statistics.emotionByQuestion:
                    const mappedColumns = DecisionAnswers.mood.map(emote => I18n.t('charts.Answers.' + emote))
                    return <TableChart
                            testId={props.testId}
                            ordering={ordering}
                            data={dataPoints}
                            columns={mappedColumns}
                            colorSchema={props.colorSchema}
                        />
            }
            break
        case ChartType.CrossProduct:
        case ChartType.BarStacked:
            return (
                <StackedBar
                    testId={props.testId}
                    data={dataPoints}
                    subTypes={props.subTypes}
                    barColors={barSchema[props.subTypes[1]]}
                    noLabel={true}
                    legend={props.legend}
                />
            )
        case ChartType.BarStackedHorizontal:
            return (
                <StackedBar
                    testId={props.testId}
                    data={dataPoints}
                    subTypes={props.subTypes}
                    barColors={calc.getStackBarColorSchema(props.subTypes)}
                    layout={'horizontal'}
                />
            )
        case ChartType.Triangle:
        case ChartType.HeatMapReactangle:
            let heatMapLabel = {};

            if( props.subTypes )
                heatMapLabel = props.subTypes;
            else if( props.Type === ChartType.Triangle )
                heatMapLabel = calc.getTriangleDiagramsLabel(props.subType);
            else
                heatMapLabel = calc.getCultureDiagramsLabel(props.subType);

            return (
                <HeatMap
                    testId={props.testId}
                    demo={false}
                    data={dataPoints}
                    label={heatMapLabel}
                    color={props.subType && calc.getDiagramColor(props.subType)}
                    isCompared={props.isCompared}
                    inspectMode={props.inspectMode || false}
                    type={props.Type}
                    subType={props.subType}
                    subTypes={props.subTypes}
                    coordinate={props.coordinate}
                    zoom={props.zoom}
                />
            )
        case ChartType.BarVertical:
            return (
                <StackedBar
                    testId={props.testId}
                    data={dataPoints}
                    width={ props.coordinate && props.coordinate.width || 600}
                    height={ props.coordinate && props.coordinate.height}
                    stacked={false}
                    subType={props.subType}
                    barColors={props.colorSchema || barSchema.reduceColor[props.subType]}
                />
            )
        case ChartType.BarHorizontal:
            return (
                <StackedBar
                    testId={props.testId}
                    data={dataPoints}
                    width={ props.coordinate && props.coordinate.width || 700}
                    height={ props.coordinate && props.coordinate.height}
                    stacked={false}
                    subType={props.subType}
                    barColors={barSchema.reduceColor[props.subType]}
                    layout={'horizontal'}
                />
            )
        case ChartType.ExperimentActivity:
            return <ExperimentActivity testId={'experiment-activity'} data={props.data[0]} />
        case ChartType.CircleProgress:
            switch (props.subType) {
                case SubTypes.circleProgress.affinity:
                    const maxValue = 100
                    return (
                        <PieChartsProgress
                            testId={props.testId}
                            noData={affinity === null}
                            data={affinity !== null ? {x: affinity, unit: '%'} :  {x: I18n.t('charts.Topic.PieChart.noDepartments'), unit: ''}}
                            maxValue={maxValue}
                            subType={SubTypes.circleProgress.affinity}
                            colorScheme={{
                                89: trafficLightColor.gray,
                                [maxValue]: trafficLightColor.green,
                            }}
                            zoom={props.zoom}
                        />
                    )
                case SubTypes.circleProgress.capturedDecisions:
                    const maxValueDecision = Math.max(
                        client.max_decision_count,
                        props.dataObject.dataShown.length,
                    )
                    return (
                        <PieChartsProgress
                            testId={props.testId}
                            data={{x: props.dataObject.dataShown.length, unit: ''}}
                            maxValue={maxValueDecision + 100}
                            subType={SubTypes.circleProgress.capturedDecisions}
                            colorScheme={{
                                [maxValueDecision - 1]: trafficLightColor.gray,
                                [maxValueDecision + 100]: trafficLightColor.green,
                            }}
                            zoom={props.zoom}
                        />
                    )
                default:
                    throw Error('unknown subtype: ', props.subType)
            }
        case ChartType.BarHorizontalCustom:
            const {demographics: demographicsStats, departmentStat} = calc.getDemographicsAndDepartmentStats(departments, wholeCompanyPeopleCount, client.max_decision_count);

            return <BarHorizontalCustom
                    zoom={props.zoom}
                    testId={props.testId}
                    data={demographicsStats}
                    departmentStat={departmentStat}
                    legend={props.legend}
                />;

        case ChartType.GroupedBar:
            return <StackedBar
                    testId={props.testId}
                    data={dataPoints}
                    width={ props.coordinate && props.coordinate.width || 700}
                    height={ props.coordinate && props.coordinate.height}
                    stacked={false}
                    grouped={true}
                    subTypes={props.subTypes}
                    barColors={barSchema.reduceColor[props.subTypes[0]]}
                    layout={'horizontal'}
                    legend={props.legend}
            />

        case ChartType.AreaChart:
                return (
                    <AutonomyVsControl
                        testId={props.testId}
                        data={props.dataObject.dataShown}
                        width={ props.coordinate && props.coordinate.width || 550}
                        height={ props.coordinate && props.coordinate.height || 400}
                        strokeColor={props.strokeColor || false}
                    />
                )
        default:
            throw Error('unknown type: ', props.Type)
    }
}

export default Charts