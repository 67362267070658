import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Image} from 'react-bootstrap'
import {I18n} from 'react-i18nify'
import {Div} from '../../core/ui/Div'
import {DARK_GREY, LIGHT_GREY} from '../../core/ui/_colors'
import {createErrorClass} from '../../core/utils/html'
import ErrorOutput from './ErrorOutput'
import rightAwayIcon from '../../img/Capture/ic-rightaway@unknown.png'
import rightAwayEnabledIcon from '../../img/Capture/ic-rightaway-enabled@unknown.png'
import hourIcon from '../../img/Capture/ic-hour@unknown.png'
import hourEnabledIcon from '../../img/Capture/ic-hour-enabled@unknown.png'
import dayIcon from '../../img/Capture/ic-day@unknown.png'
import dayEnabledIcon from '../../img/Capture/ic-day-enabled@unknown.png'
import {isSelected} from '../../core/utils/form'
import {Panel} from "../../core/components/BootstrapWrapper";

const icons = {
    'rightaway': rightAwayIcon,
    'rightaway-enabled': rightAwayEnabledIcon,
    'hour': hourIcon,
    'hour-enabled': hourEnabledIcon,
    'day': dayIcon,
    'day-enabled': dayEnabledIcon
}

export const ChoiceContainer = styled(Div)`
    display: flex;
    align-items: center;
    flex-direction: ${(props) => (props.isVertical ? 'row' : 'column')};
    ${(props) =>
        props.isVertical &&
        css`
            padding-right: 0;
        `}
    width: ${(props) => (props.isVertical ? '100%' : '94px')};
    height: ${(props) => (props.isVertical ? 'auto' : '113px')};
    margin: ${(props) => (props.isVertical ? '0' : '0 5px')};
    margin-bottom:not(:last-child): 15px;&:not(:last-child) {
        margin-bottom: 15px;
    }
    text-align: ${(props) => (props.isVertical ? 'left' : 'center')};
    cursor: pointer;
    @media only screen and (min-width: 576px) {
        ${(props) =>
            !props.isVertical &&
            css`
                width: 90px;
                margin: 0 5px 5px 5px;
            `}
    }
    @media only screen and (min-width: 768px) {
        ${(props) =>
            !props.isVertical &&
            css`
                width: 123px;
                margin: 0 5px 5px 5px;
            `}
    }
    @media only screen and (min-width: 992px) {
        ${(props) =>
            !props.isVertical &&
            css`
                width: 115px;
                height: 125px;
                margin: 0 5px 15px 5px;
            `}
    }
    @media only screen and (min-width: 1200px) {
        ${(props) =>
            !props.isVertical &&
            css`
                width: 121px;
            `}
    }
`

export const Value = styled(Div)`
    height: auto;
    width: 90%;
    ${(props) => (!props.isVertical && 'min-height: 50px;')}
    line-height: 20px;
    margin-left: 10px;
    @media only screen and (min-width: 320px) {
        word-break: break-word;
    }
    @media only screen and (min-width: 576px) {
        height: auto;
        width: 90%;
        line-height: 20px;
    }
`

export const ImgContainer = styled(Div)`
    width: 60px;
    height: 60px;
    ${(props) => (!props.isVertical && "margin-bottom: 20px;")}
    ${(props) => (props.selected && "margin-bottom: 10px; margin-right: 10px")}
    text-align: center;

    img {
        width: 60px;
        height: 60px;
        opacity: ${(props) => (props.selected ? 1 : 0.8)};
        transform: ${(props) => (props.selected ? 'scale(1.1, 1.1)' : 'scale(1, 1)')};
        transition: transform 0.2s ease, opacity 0.2s;

        &:hover {
            opacity: 1;
            transform: scale(1.1, 1.1);
        }
    }

    @media only screen and (min-width: 576px) {
        img {
            transition: transform 0.3s ease, opacity 0.3s;
        }
    }

    @media only screen and (max-width: 768px) {
        margin-right: 20px;
    }
    @media only screen and (min-width: 992px) {
        transform: ${(props) => (props.selected ? 'scale(1.1, 1.1)' : 'scale(1, 1)')};
        &:hover {
            transform: scale(1.1, 1.1);
        }
    }
`

export const Choices = styled(Div)`
    display: flex;
    flex-wrap: wrap;
  ${(props) =>
      props.isVertical &&
      css`
          margin: 0;
      `}

  ${(props) =>
      props.isVertical &&
      css`
          flex-direction: column;
      `}
  ${(props) =>
      props.isVertical &&
      css`
          width: 100%;
      `}
}

`

const CheckboxIcon = styled.i`
    font-size: 22px;
    line-height: 25px;
    padding: 0;

    ${(props) =>
        props.disabled &&
        css`
            color: ${LIGHT_GREY};
        `}
    &:hover,
  &:active,
  &:focus {
        background-color: transparent;
    }
`
const Checkbox = styled.div`
    ${(props) =>
        !props.isVertical ?
        css`
            margin-bottom: 30px;
        ` : css`
    ` }
    height: 25px;
    width: 25px;
    border: ${(props) => (props.disabled ? `1px solid ${LIGHT_GREY}` : `1px solid ${DARK_GREY}`)};
`


const MultiSelect = (props) => {
    const [selected, setSelected] = useState(props.selected || [])
    const [validationErrors, setValidationErrors] = useState(null)

    const validateInput = () => {
        if (props.required) {
            if (selected.length === 0) {
                setValidationErrors({required: I18n.t("capture.Form.Status.Validation.SectionRequired")})
                props.onError && props.onError(props.fieldName)
            } else {
                setValidationErrors(null)
            }
        } else {
            setValidationErrors(null)
        }
    }

    useEffect(() => {
        props.onChoiceSelect && props.onChoiceSelect(selected, props.fieldName)
    }, [selected])

    useEffect(() => {
        if (props.shouldValidate) {
            validateInput()
        }
    }, [props.shouldValidate])

    const onItemSelect = (choice) => {
        setValidationErrors(null)
        if (isSelected(selected, {value: choice.value})) {
            setSelected(selected.filter((item) => item.value !== choice.value))
        } else {
            setSelected([...selected, choice])
        }
    }

    const renderSelector = (choice, selected) => {
        if (!choice.imageName && !choice.imageUrl) {
            return (
                <Checkbox
                    disabled={props.disabled}
                    isVertical={props.isVertical}
                    data-testid={`checkbox-button${props.testId ? '-' + props.testId : ''}`}
                >
                    {selected && (
                        <CheckboxIcon
                            data-testid={`check-icon${props.testId ? '-' + props.testId : ''}`}
                            className="material-icons"
                            disabled={props.disabled}
                        >
                            check
                        </CheckboxIcon>
                    )}
                </Checkbox>
            )
        } else {
            const imageName = selected ? choice.imageName + '-enabled' : choice.imageName
            const imageSrc = choice.imageUrl || icons[imageName]
            return (
                <ImgContainer className={'img-container'} selected={selected} isVertical={props.isVertical}>
                    <Image
                        src={imageSrc}
                        circle
                        alt={choice.label}
                    />
                </ImgContainer>
            )
        }
    }

    return (
        <Panel
            default
            data-testid="MultiSelect-choices"
            className={createErrorClass(props.fieldName, props.errors || validationErrors, !props.errors)}
            header={props.header}
        >
        <Choices
            isVertical={props.isVertical}
            className={classNames('choices', props.className, props.isVerticalList ? 'listed' : '')}
        >
            {props.choices &&
                props.choices.map((choice) => {
                    const checked = isSelected(selected, {value: choice.value})
                    return (
                        <ChoiceContainer
                            data-testid="MultiSelect-choice"
                            isVertical={props.isVertical}
                            key={choice.value}
                            selected={checked}
                            onClick={() => onItemSelect(choice)}
                        >
                            {renderSelector(choice, checked)}

                            <Value isVertical={props.isVertical} selected={checked}>
                                {choice.label}
                            </Value>
                        </ChoiceContainer>
                    )
                })}
        </Choices>
        <ErrorOutput errors={props.errors || validationErrors}/>
        </Panel>
    )
}

MultiSelect.propTypes = {
    choices: PropTypes.array,
    errors: PropTypes.object,
    onChoiceSelect: PropTypes.func,
    value: PropTypes.string,
    isVertical: PropTypes.bool,
}

MultiSelect.defaultProps = {
    choices: [],
    isVertical: false,
}

export default MultiSelect
