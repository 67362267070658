import {useEffect, useState} from 'react'

export const useOverride = (overriding) => {
    const [overridable, setOverridable] = useState(overriding)

    useEffect(() => {
        setOverridable(overriding)
    }, [overriding])

    return [overridable, setOverridable]
}
