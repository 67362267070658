import React, {useEffect, useMemo, useState} from 'react'

import {I18n} from 'react-i18nify'
import TeamList from './ExperimentComponents/TeamList'
import VolunteerList from './ExperimentComponents/VolunteerList'
import VolunteerCondition from './ExperimentComponents/VolunteerCondition'
import ExperimentPeriod from './ExperimentComponents/ExperimentPeriod'
import moment from 'moment'
import ExperimentActivity from './ExperimentComponents/ExperimentActivity'
import HeadingWithAction from '../Components/HeadingWithAction'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {coreSlice} from '../../core/redux/slices'
import {
    getNodeState,
    isUserAbleToEditNode,
    isUserAChampion,
    isUserChampionOrSquadMember,
} from '../../strategyMap/utils'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import Avatar from '../../core/components/Avatar'
import _ from 'lodash'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {ChampionItem, ChampionLabel} from './ExperimentComponents/Experiment.styles'
import {ButtonSecondary} from '../../core/components/Buttons'
import {RESTRICTIONS} from "../../core/const";

export const Experiment = (props) => {
    const [currentData, setCurrentData] = useState(0)
    const {Actions, nodeMap} = useStrategyMapSlice()
    const user = useUserSlice()

    const status = getNodeState(props.node)
    const experimentDurationInfoText = !props.isEditMode
        ? I18n.t('strategyMap.messages.setDeadlineInfo')
        : ''
    const activityData = props.node.activities || []
    const isUserChampion = isUserAChampion(user, props.node, nodeMap)

    const [stateExperimentTeam, setStateExperimetTeam] = useState({})

    useMemo(() => {
        const experiments = _.filter(nodeMap, (node) => node.type === 'E')
        const getExperimentTeam = (experiment) => experiment.experiment_team_members
        const experimentTeams = experiments.reduce(
            (acc, experiment) => acc.concat(getExperimentTeam(experiment)),
            [],
        )

        const userExperimentTeams = experimentTeams.filter(
            (userExperimentTeam) =>
                userExperimentTeam &&
                userExperimentTeam.user.pk === user.pk &&
                userExperimentTeam.date_left === null,
        )
        const isUserAwaitingAnyRequest = !!userExperimentTeams.filter(
            (userExperimentTeam) => userExperimentTeam.pending,
        ).length
        const isUserParticipatingAnyRequest = !!userExperimentTeams.filter(
            (userExperimentTeam) =>
                userExperimentTeam.experiment !== props.node.id && userExperimentTeam.approved,
        ).length
        const isUserParticipatingInCurrentExperiment = !!userExperimentTeams.filter(
            (userExperimentTeam) =>
                userExperimentTeam.experiment === props.node.id && userExperimentTeam.approved,
        ).length
        const isUserPendingRequestInCurrentExperiment = !!userExperimentTeams.filter(
            (userExperimentTeam) =>
                userExperimentTeam.experiment === props.node.id && userExperimentTeam.pending,
        ).length
        const currentExperimentTeam = userExperimentTeams.filter(
            (userExperimentTeam) => userExperimentTeam.experiment === props.node.id,
        )

        const userIsChampionOrSquadMember = isUserChampionOrSquadMember(user, props.node, nodeMap)
        setStateExperimetTeam({
            userIsChampionOrSquadMember: userIsChampionOrSquadMember,
            canRequestVolunteer:
                !isUserPendingRequestInCurrentExperiment && !isUserParticipatingInCurrentExperiment,
            isPendingInOtherRequest: isUserAwaitingAnyRequest,
            isParticipatingInOtherExperiment: isUserParticipatingAnyRequest,
            isPendingRequest: isUserPendingRequestInCurrentExperiment,
            isParticipatingInExperiment: isUserParticipatingInCurrentExperiment,
            currentExperimentTeam:
                currentExperimentTeam.length > 0 ? currentExperimentTeam[0] : false,
        })
    }, [props.node.experiment_team_members])

    useEffect(() => {
        if (props.isEditMode) {
            setCurrentData(
                props.node.experiment_success_conditions.filter((item) => item.done).length -
                    props.node.experiment_failure_conditions.filter((item) => item.done).length,
            )
        }
    }, [props.node])

    // functions for experiment success and fail evaluation
    const markSuccessful = () => {
        Actions.updateNode({...props.node, successful: true})
    }

    const markFailed = () => {
        Actions.updateNode({...props.node, failed: true})
    }

    // functions for start and end experiment
    const startExperiment = () => {
        const updatedNode = {
            ...props.node,
            start_date: moment().format('YYYY-MM-DD'),
            is_started: true,
        }
        Actions.updateNode(updatedNode)
    }

    const terminateExperiment = () => {
        Actions.terminateExperiment({experimentId: props.node.id})
    }

    // functions for experiment volunteering
    const applyToExperimentTeam = () => {
        Actions.applyToExperiment({userId: user.pk, nodeId: props.node.id})
    }

    const leaveExperimentTeam = (id) => {
        Actions.leaveExperiment(id)
    }

    const approveVolunteerRequest = (id) => {
        Actions.approveVolunteerRequest(id)
    }

    const declineVolunteerRequest = (id) => {
        Actions.declineVolunteerRequest(id)
    }

    const handleDeadlineChange = (deadline, duration) => {
        if (props.isEditMode) {
            const updatedNode = {
                ...props.node,
                deadline: deadline.format('YYYY-MM-DD'),
                duration,
            }
            Actions.updateNode(updatedNode)
        } else {
            Actions.updatePendingNode({
                deadline: deadline.format('YYYY-MM-DD'),
                duration,
            })
        }
    }

    const openExperimentCanvas = () => {
        Actions.setExperimentCanvasVisible(true)
        props.setPageHeader(I18n.t('strategyMap.experiment.experimentCanvas'))
    }

    return (
        <div className={'experiment'}>
            <ExperimentPeriod
                node={props.node}
                onDeadlineChange={handleDeadlineChange}
                infoText={experimentDurationInfoText}
            />
            <div>
                {props.isEditMode && (
                    <ExperimentActivity
                        deadlineSet={props.node.deadline !== null}
                        isChampion={isUserChampion}
                        isUserAbleToEditNode={isUserAbleToEditNode(user, props.node, nodeMap)}
                        status={status}
                        startDate={props.node.start_date}
                        terminateExperimentCallback={terminateExperiment}
                        startExperimentCallback={startExperiment}
                        markFailedCallback={markFailed}
                        markSuccessfulCallback={markSuccessful}
                        activityData={activityData}
                        duration={props.node.duration}
                        currentData={currentData}
                    />
                )}
                <ButtonSecondary
                    fullWidth
                    data-testid={'experiment-open-canvas-button'}
                    onClick={openExperimentCanvas}
                >
                    {I18n.t('strategyMap.experiment.experimentCanvas')}
                </ButtonSecondary>
            </div>
            {props.isEditMode && (
                <div data-testid={'experiment-details-container'}>
                    {props.champions && (
                        <div>
                            <HeadingWithAction title={I18n.t('strategyMap.experiment.champion')} />
                            {props.champions.map((item, index) => {
                                return item.full_name !== '' ? (
                                    <ChampionItem
                                        key={item.pk}
                                        data-testid={'experiment-champion-name-' + index}
                                    >
                                        <ChampionLabel>{item.full_name}</ChampionLabel>
                                        <Avatar pull="right" 
                                            colorCode={item.color_code}
                                            text={item.initials}
                                            profilePicture={item.profile_picture} 
                                        />
                                    </ChampionItem>
                                ) : (
                                    <ChampionLabel
                                        key={item.pk}
                                        data-testid={'experiment-champion-email-' + index}
                                        email
                                    >
                                        {item.email}
                                    </ChampionLabel>
                                )
                            })}
                            <div style={{clear: 'both'}} />
                        </div>
                    )}

                    { RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => props.user[role] ) &&
                        stateExperimentTeam.userIsChampionOrSquadMember &&
                        !props.node.is_finished &&
                        props.node.experiment_team_members && (
                            <VolunteerList
                                volunteerList={props.node.experiment_team_members.filter(
                                    (member) => member.pending,
                                )}
                                declineVolunteerRequest={declineVolunteerRequest}
                                approveVolunteerRequest={approveVolunteerRequest}
                            />
                        )}
                    {props.node.experiment_team_members && (
                        <TeamList
                            experimentTeam={props.node.experiment_team_members.filter(
                                (member) => member.approved && member.is_active,
                            )}
                            isFinished={props.node.is_finished}
                            removeTeamMember={leaveExperimentTeam}
                            node={props.node}
                        />
                    )}

                    { RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role =>  props.user[role] )
                        && ! isUserChampion && ! props.node.is_finished && (
                        <VolunteerCondition
                            node={props.node}
                            canRequestVolunteer={stateExperimentTeam.canRequestVolunteer}
                            isPendingInOtherRequest={stateExperimentTeam.isPendingInOtherRequest}
                            isParticipatingInOtherExperiment={
                                stateExperimentTeam.isParticipatingInOtherExperiment
                            }
                            isPendingRequest={stateExperimentTeam.isPendingRequest}
                            isParticipatingInExperiment={
                                stateExperimentTeam.isParticipatingInExperiment
                            }
                            signInAsVolunteer={applyToExperimentTeam}
                            leaveExperiment={leaveExperimentTeam}
                            currentExperimentTeam={stateExperimentTeam.currentExperimentTeam}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({strategyMap, user}) => {
    return {
        ...strategyMap,
        user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...coreSlice.actions,
        },
        dispatch,
    )
}

const ConnectedExperiment = connect(mapStateToProps, mapDispatchToProps)(Experiment)

export default ConnectedExperiment
