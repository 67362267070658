import React, {useEffect} from 'react'
import PageHeader from './core/components/pageHeader'
import AuthWebsocketContext from './core/contexts/AuthWebsocketContext'
import PageReloadPrompt from './core/ui/PageReloadPrompt'
import {Authenticator} from './Authenticator'
import {AuthenticatedRouter, public_routes, routes} from './AuthenticatedRouter'
import {DndProvider} from 'react-dnd'
import SM_WebsocketContext from './core/contexts/SM_WebsocketContext'
import GlobalStyle from './core/ui/_global'
import {Provider} from 'react-redux'
import {loadTranslations} from './core/components/CommonPage'
import SoftLogout from './core/components/SoftLogout'
import {isPublicInstance} from './core/utils/network';
import InitialData from './core/components/InitialData'
import Tutorials from "./ReactTourStories/Tutorials";
import {NotificationContainer} from "react-notifications";
import {I18n} from "react-i18nify";


const App = (props) => {
    loadTranslations();

    useEffect( () => {
        const languageCode = localStorage.getItem('language');
        if( languageCode ) {
            I18n.setLocale(languageCode);
        }
    }, [])

    return (
        <Provider store={props.store}>
            <DndProvider backend={props.HTML5Backend}>
                <Tutorials />
                <NotificationContainer />
                <SM_WebsocketContext.Provider value={props.strategyMapSocket}>
                    <AuthWebsocketContext.Provider value={props.authSocket}>
                        <GlobalStyle />
                        <AuthenticatedRouter history={props.history}>
                            <Authenticator history={props.history}>
                                <SoftLogout>
                                    <PageHeader title="ORGANIC agility" />
                                    <PageReloadPrompt />
                                    { isPublicInstance()
                                        ? public_routes
                                        : <InitialData>{ routes }</InitialData>
                                    }
                                </SoftLogout>
                            </Authenticator>
                        </AuthenticatedRouter>
                    </AuthWebsocketContext.Provider>
                </SM_WebsocketContext.Provider>
            </DndProvider>
        </Provider>
    )
}

export default App
