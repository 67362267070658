import React, {useCallback, useEffect, useMemo, useState} from 'react'
import SearchIcon from './SearchIcon'
import ClickOutside from '@tntd/react-click-outside'
import NodeIcon from '../../../strategyMap/components/NodeIcon'
import {useStrategyMapSlice} from '../../../strategyMap/hooks/useStrategyMapSlice'
import {isMobile} from 'react-device-detect'

import {
    getNodeDisplay,
    isExperiment,
    isNC,
    isUserAChampion,
    isUserAParticipant,
    isUserASquadMember,
    isUserChampionOrSquadMember,
    isUserInExperimentTeam,
} from '../../../strategyMap/utils'
import {
    Container,
    Hint,
    Input,
    ItemContainer,
    ItemDescription,
    NodeSearchMenu,
    NoResultContainer,
    PopoverContainer,
    SearchContainer,
    SearchIconContainer,
} from './NodeSearch.styles'
import {RippleLoading} from '../Loading'
import {useActions} from '../../hooks/useActions'
import {coreSlice} from '../../redux/slices'
import {PAGES} from '../../const'
import {useUserSlice} from '../../../strategyMap/hooks/useUserSlice'
import {I18n} from "react-i18nify"
import {NODE_TYPES_TO_EXTENDED_NAMES, NODE_TYPES_TO_FULL_NODE_TYPES} from "../../../strategyMap/const"
import {useSearch} from "../../hooks/useSearch"
import {equals, findIndex, pipe, sort, values} from 'ramda'
import {NavItemIntern} from "../BootstrapWrapper";


const localeCompareTitle = (a, b) => {
    return a.title && a.title.toLocaleUpperCase().localeCompare(b.title && b.title.toLocaleUpperCase())
}
const orderedTypes = ['G', 'C', 'P', 'E', 'N']
const compareNodeTypes = (a, b) => {
    const precedence = n => findIndex(equals(n.type), orderedTypes)
    return precedence(a) - precedence(b)
}

const sortNodes = pipe(
    sort(localeCompareTitle),
    sort(compareNodeTypes)
)

const SearchField = ({onChange, value, toClose}) => (
    <SearchContainer>
        <Input type="text" autoFocus value={value} aria-label="node-search" onChange={onChange}/>
        <SearchIcon open={true} toClose={toClose} style={{position: 'absolute', right: 12, top: 2}}/>
    </SearchContainer>
)

const NodeLinkItem = (props) => {
    const {Actions, nodeMap} = useStrategyMapSlice()
    const {gotoPage, setPageHeader} = useActions(coreSlice.actions)
    const isChampion = isUserAChampion(props.user, props.node, nodeMap)
    const isSquadMember = isUserASquadMember(props.user, props.node, nodeMap)
    return (
        props.node && (
            <ItemContainer
                key={props.node.id}
                onClick={() => {
                    gotoPage(PAGES.StrategyMap)
                    setPageHeader('')
                    Actions.showNodeEditingSidebar({id: props.node.id})
                    props.setOpen(false)
                }}
            >
                <ItemDescription>
                    <NodeIcon node={props.node} style={{margin: 0, width: 50}}/>
                    {getNodeDisplay(props.node)} {props.node.title}
                </ItemDescription>
                {isChampion && <Hint>Champion</Hint>}
                {isSquadMember && !isChampion && <Hint>Squad member</Hint>}
                {isExperiment(props.node) && isUserInExperimentTeam(props.user, props.node) && (
                    <Hint>Experiment team</Hint>
                )}
            </ItemContainer>
        )
    )
}

const NodeSearch = () => {
    const [open, setOpen] = useState(false)
    const {Actions, isFetching, users, nodeMap} = useStrategyMapSlice()
    const match = useCallback((node, str) => {

        if (!str) {
            if (isNC(node)) return false
            if (isExperiment(node)) {
                if (isUserInExperimentTeam(user, node)) {
                    return true
                }
            } else {
                if (isUserChampionOrSquadMember(user, node, nodeMap)) {
                    return true
                }
            }
            return false
        }

        const searchKey = str.toLowerCase();
        const isIncluded =
            node?.sid?.toString().includes(searchKey) ||
            node?.title?.toLowerCase().includes(searchKey) ||
            node?.description?.toLowerCase().includes(searchKey) ||
            NODE_TYPES_TO_EXTENDED_NAMES[node.type]?.toLowerCase().includes(searchKey) ||
            NODE_TYPES_TO_FULL_NODE_TYPES[node.type]?.toLowerCase().includes(searchKey)
        
        return (
            isIncluded ||
            users
                .filter(
                    (u) =>
                        (u.full_name && u.full_name.toLowerCase().includes(searchKey)) ||
                        (u.email && u.email.toLowerCase().includes(searchKey)),
                )
                .some((u) => isUserAParticipant(u, node, nodeMap))
        )
    }, [users, nodeMap])
    const searchableItems = useMemo(() => values(nodeMap), [nodeMap])
    const [nodes, handleNodeSearch, searchTerm] = useSearch(searchableItems, match)

    const user = useUserSlice()

    useEffect(() => {
        if (!users.length) {
            Actions.fetchActiveUsers()
        }
    }, [])


    return (
        <NavItemIntern onClick={() => !open && setOpen(!open)}>
            <NodeSearchMenu isMobile={isMobile && open} open={open && !isMobile}>
                <ClickOutside onClickOutside={() => setOpen(false)}>
                    <Container isMobile={isMobile}>
                        {open ? (
                            <SearchField
                                value={searchTerm}
                                onChange={handleNodeSearch}
                                toClose={() => setOpen(false)}
                            />
                        ) : (
                            <SearchIconContainer data-testid={'search-icon'}>
                                <SearchIcon open={open} />
                            </SearchIconContainer>
                        )}
                        {open && (
                            <PopoverContainer isMobile={isMobile} open={open} data-testid={'search-result'}>
                                {isFetching && <RippleLoading loading={true}/>}
                                {!isFetching &&
                                    !nodes.length && (
                                        <NoResultContainer>
                                            <p>{I18n.t('dashboard.noResult')}</p>
                                        </NoResultContainer>
                                    )}
                                {!isFetching &&
                                    sortNodes(nodes)
                                        .map((node) => (
                                            <NodeLinkItem
                                                key={node.id}
                                                user={user}
                                                node={node}
                                                setOpen={setOpen}
                                            />
                                        ))}
                            </PopoverContainer>
                        )}
                    </Container>
                </ClickOutside>
            </NodeSearchMenu>
        </NavItemIntern>
    )
}

export default NodeSearch
