import React from 'react'
import {findDOMNode} from 'react-dom'
import {DragSource} from 'react-dnd'
import {getEmptyImage} from 'react-dnd-html5-backend'

import { ITEM_TYPES } from '../const'
import Node from './Node'
import {isGoal} from '../utils'

const nodeSource = {
    beginDrag(props, monitor, component) {
        // dispatch to redux store that drag is started
        const {node, x, y, nodeParentId} = props
        const {clientWidth, clientHeight} = findDOMNode(component)
        return {...node, x, y, nodeParentId, clientWidth, clientHeight}
    },
    isDragging(props, monitor) {
        return props.node && props.node.id === monitor.getItem().id
    },
}

// options: 4rd param to DragSource https://gaearon.github.io/react-dnd/docs-drag-source.html
const OPTIONS = {
    arePropsEqual: (props, otherProps) => {
        return (
            props.node.id === otherProps.node.id &&
            props.x === otherProps.x &&
            props.y === otherProps.y
        )
    },
}

const collectDragSource = (connectDragSource, monitor) => {
    return {
        connectDragSource: connectDragSource.dragSource(),
        connectDragPreview: connectDragSource.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

class DraggableNode extends React.Component {
    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), {
            captureDraggingState: true,
        })
    }

    render() {
        const {
            isDragging,
            connectDragSource,
            nodeId,
            node,
            userAbleToEditNode,
            nodesLength,
            x,
            y,
            nodesPerTimeArea,
        } = this.props
        const scrollY = window.scrollY
        window.onscroll = () => {
            if (isDragging) {
                window.scrollTo(0, scrollY)
            }
        }
        if (isGoal(node) || !userAbleToEditNode) {
            return (
                <div>
                    <Node nodeId={nodeId} isDragging={isDragging} isDraggable={false} />
                </div>
            )
        }
        return connectDragSource(
            <div>
                <Node
                    nodeId={nodeId}
                    isDragging={isDragging}
                    isDraggable={true}
                    timeArea={this.props.timeArea}
                    nodesLength={nodesLength}
                    x={x}
                    y={y}
                    nodesPerTimeArea={nodesPerTimeArea}
                />
            </div>,
        )
    }
}

export default DragSource(ITEM_TYPES.NODE, nodeSource, collectDragSource, OPTIONS)(DraggableNode)
