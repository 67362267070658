import React from 'react'
import styled from 'styled-components'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {DARK_GREY, LIGHT_GREY} from '../../core/ui/_colors'

export const TitleContainer = styled.div`
    border-bottom: 1px solid ${LIGHT_GREY};
    padding-top: 20px;
    margin-bottom: 6px;
    clear: both;
`

export const Title = styled.h4`
    color: ${DARK_GREY};
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
`

export const TitleContainerChampion = styled(TitleContainer)`
    padding-top: 10px;
`

export const Icon = styled.i`
    margin-top: -4px;
    font-size: 20px;
    color: ${DARK_GREY};
    float: right;
`

const HeadingWithAction = (props) => {
    const Div = props.title.toLowerCase() === 'champion' ? TitleContainerChampion : TitleContainer

    return (
        <Div>
            <Title>
                {props.title}
                {props.showHint && (
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-${'top'}`} style={{opacity: 1}}>
                                <b>{props.hintMessage}</b>
                            </Tooltip>
                        }
                    >
                        <Icon
                            className={'material-icons'}
                            aria-hidden="true"
                            role="button"
                            data-testid={'heading-hint-icon' + (props.testId || '')}
                        >
                            help_outline
                        </Icon>
                    </OverlayTrigger>
                )}
                {props.showAction && (
                    <Icon
                        onClick={props.onClick}
                        className={'material-icons' + (props.iconOutlined ? '-outlined' : '')}
                        aria-hidden="true"
                        role="button"
                        data-testid={'heading-action-button' + (props.testId || '')}
                    >
                        {props.icon}
                    </Icon>
                )}
            </Title>
        </Div>
    )
}

export default HeadingWithAction
