// there are some helpful functions for network
import { DEFAULT_LANGUAGE, LANGUAGE_CODE } from './language'

export const getTenantName = function () {
    switch (window.location.host.split('.').length) {
        case 0:
            return window.location.host;
        default:
            return window.location.host.split('.')[0];
    }
}
export const isLocalhost = () => window.location.hostname === 'localhost';
export const isPublicInstance = () => getTenantName() === "public";

export function validURL(str) {
    if (!str) return false
    const pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ) // fragment locator
    return !!pattern.test(str)
}

export const getCookieByName = function (name) {
    const cookies = document.cookie
        .split(';')
        .filter((item) => item.replace(' ', '').startsWith(name + '='))

    if (cookies.length === 1) {
        return cookies[0].replace(' ', '').substring(name.length + 1)
    }
    return ''
}

export const getRequestHeader = () => {
    const headers = getRequestHeaderRaw();
    if( localStorage.token )
        headers.Authorization = 'Token ' + localStorage.token;
    return { headers };
}

export const getRequestHeaderUserBlob = token => {
    const headers = getRequestHeaderRaw();
    if( token )
        headers.Authorization = 'Token ' + token;
    return { headers };
}

export const getRequestHeaderRaw = () => {
    const headers = {
        'X-CSRFToken': getCookieByName('csrftoken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.language || LANGUAGE_CODE[DEFAULT_LANGUAGE]
    };
    return headers;
}

export const getAuthRequestHeader = () => {
    return {
        headers: {
            Authorization: 'Token ' + localStorage.token,
            'X-CSRFToken': getCookieByName('csrftoken'),
            'Accept-Language': localStorage.language || LANGUAGE_CODE[DEFAULT_LANGUAGE],
        },
    }
}

export const getSimpleRequestHeader = () => {
    return {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.language || LANGUAGE_CODE[DEFAULT_LANGUAGE],
        },
    }
}

export const getSessionRequestHeaderWithoutToken = () => {
    return {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookieByName('csrftoken'),
            'X-Pantheon-Session': '',
            'Accept-Language': localStorage.language || LANGUAGE_CODE[DEFAULT_LANGUAGE]
        }
    }
};

export const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1])
        }
    }
}

export const createQueryParams = params =>
    params
        ? Object.keys( params )
            .map( tag => `${tag}=${params[tag]}` )
            .reduce( (acc,cur) => acc + "&" + cur, "?" )
        : '';
