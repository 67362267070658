import {createSelector} from 'redux-starter-kit'
import {isEmpty} from 'ramda'


const clientSelector = (state) => state.core.client;

const isSubscriptionActive = createSelector(clientSelector, (client) => {
    return !!(client.subscriptions && client.subscriptions.length) && !client.is_sub_expired
})

const noActiveSubscriptions = createSelector(clientSelector, (client) => {
    return isEmpty(client.subscriptions)
})

const features = createSelector(clientSelector, (client) => client.feature || {});

export const fieldsToSelectors = {
    isSubscriptionActive,
    noActiveSubscriptions,
    features
}
