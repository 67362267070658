import React, { useEffect, useState } from 'react';
import { useStrategyMapSlice } from '../../strategyMap/hooks/useStrategyMapSlice';
import moment from 'moment';
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';
import { NODE_TYPES_TO_EXTENDED_NAMES } from '../../strategyMap/const';
import _ from 'lodash';
import { Panel } from '../../core/components/BootstrapWrapper';
import { Col, Row } from 'react-bootstrap';
import { I18n } from 'react-i18nify';
import { Modal } from '../../core/ui/modal/Modal';
import Api from '../../strategyMap/api';
import styled from 'styled-components';
import { useCoreSlice } from '../../core/hooks/useCoreSlice';
import { getNodeLinkByNodeIdAndGoalId } from '../../strategyMap/utils';
import { windowOpen } from '../../core/utils/navigation';
import { BLUE } from '../../core/ui/_colors';
import { Symbol } from '../../core/components/Navbar/Navbar.styles';
import Cadence from '../../core/components/Navbar/StrategyMap/Cadence/Cadence';
import { Div } from '../../core/ui/Div';
import NodeIcon from '../../strategyMap/components/NodeIcon';
import { SelectItemWidgetModal } from './CustomizeChartsDashboard';
import { SIDEBARS } from '../../core/redux/slices';

export const NodeLink = styled.b`
    cursor: pointer;
    color: ${BLUE}
`;

export const goToNode = (nodeId, goalId) => windowOpen(getNodeLinkByNodeIdAndGoalId(nodeId, goalId));

const StrategyMapOverview = (props) => {
    const currentActiveUser = useUserSlice();
    const {nodeMap, Actions} = useStrategyMapSlice();
    const [latestActivitiesForNodeAndChildren, setLatestActivitiesForNodeAndChildren] = useState([]);
    const [nodesChanged, setNodesChanged] = useState([]);
    const [showItemsOnModal, setShowItemsOnModal] = useState([]);

    useEffect(() => {
        setNodesChanged([]);
        Api.fetchLatestActivitiesForNodeAndChildren({id: props.goalId, start_date: props.startDate, end_date: props.endDate})
            .then((result) => setLatestActivitiesForNodeAndChildren(result.data));

        Actions.fetchSfs({goalId: props.goalId});
    }, []);

    const getDifference = (a, b) => {
        if (!a && b)
            return b;
        if (!a && !b)
            return {};
        
        return Object.entries(b)
                .filter(([key, val]) => a[key] !== val && key in a)
                .reduce((a, [key, v]) => ({...a, [key]: v}), {});
    }

    const checkChangesOnNode = (activitiesList) => {
        if(activitiesList.length === 0)
            return false

        const nodeId = activitiesList[0].data.id;
        const nodeIcon = nodeMap[nodeId] && <NodeIcon node={nodeMap[nodeId]} style={{margin: "0px -16px"}} />;
        const nodeName = `${NODE_TYPES_TO_EXTENDED_NAMES[activitiesList[0].node_type]} #${nodeId}`;
        const changeMadeBy = <b>({I18n.t('dashboard.changeMadeBy')} {activitiesList[0].user})</b>

        if(activitiesList[0].data.history_type === '+') // created
            return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {I18n.t('dashboard.wasCreated')} {changeMadeBy}<br /></>;
        if(activitiesList[0].data.history_type === '-') // deleted
            return <><b>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</b> {activitiesList[0].data.title} {I18n.t('dashboard.wasDeleted')} {changeMadeBy}<br /></>;
        if(activitiesList[0].data.history_type === '~') { // changed
            const previous_data = !activitiesList[0].previous_data && activitiesList[1] ? activitiesList[1].data : activitiesList[0].previous_data;
            const differences = getDifference(previous_data, activitiesList[0].data);
            
            if (differences.hasOwnProperty('title')) {
                return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {I18n.t('dashboard.titleChanged')} {changeMadeBy}<br /></>;
            } else if(differences.hasOwnProperty('description')) {
                return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {I18n.t('dashboard.descriptionChanged')} {changeMadeBy}<br /></>;
            } else if(differences.hasOwnProperty('time_area')) {
                return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {I18n.t('dashboard.wasMoved').replace('FROM_TIME_AREA', previous_data.time_area).replace('TO_TIME_AREA', differences.time_area)} {changeMadeBy}<br /></>;
            } else if(differences.hasOwnProperty('is_started')) {
                return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {I18n.t('dashboard.wasStarted')} {changeMadeBy}<br /></>;
            } else if(differences.hasOwnProperty('is_finished')) {
                const wasSuccessful = differences.hasOwnProperty('successful') && differences.successful;
                const wasFailed = differences.hasOwnProperty('failed') && differences.failed;
                let finishResult = I18n.t('dashboard.wasFinished');
                if(wasSuccessful)
                    finishResult = I18n.t('dashboard.wasFinishedSuccessfully');
                else if(wasFailed)
                    finishResult = I18n.t('dashboard.wasFinishedUnsuccessfully');
                
                return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {finishResult} {changeMadeBy}<br /></>;
            } else if(differences.hasOwnProperty('fulfilled')) {
                const fullfiledResult = differences.fulfilled ? 
                                            I18n.t('dashboard.wasFulfilled'):
                                            I18n.t('dashboard.wasUnfulfilled');

                return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {fullfiledResult} {changeMadeBy}<br /></>;
            }
            
            return <><NodeLink onClick={(e) => goToNode(nodeId, props.goalId)}>{I18n.t('dashboard.theNode')} {nodeIcon} {nodeName}</NodeLink> {activitiesList[0].data.title} {I18n.t('dashboard.wasChanged')} {changeMadeBy}<br /></>;
        }
        return false;
    }

    useEffect(() => {
        const grouped = _.mapValues(_.groupBy(latestActivitiesForNodeAndChildren, 'id'),
                          clist => clist.map(activity => _.omit(activity, 'id')));
        
        const results = Object.keys(grouped).map((item) => {
            const result = checkChangesOnNode(grouped[item]);
            if(result)
                return ({insight: result, type: grouped[item][0].data.history_type});
        });

        setNodesChanged(results);
    }, [latestActivitiesForNodeAndChildren]);

    const showAllOnModal = (items) => setShowItemsOnModal(items);

    const strategyMapInsights = () => {
        const MAX_INSIGHTS_ALLOWED = 3;
        if(nodesChanged.length === 0 ) {
            return <p>
                {I18n.t('dashboard.thereHasBeenNoUpdatesDuringCurrentIteration')}
            </p>
        }
        const created = nodesChanged.filter(node => node.type === '+');
        const deleted = nodesChanged.filter(node => node.type === '-');
        const changed = nodesChanged.filter(node => node.type === '~');
        
        return <p>
            {I18n.t('dashboard.updatesDuringCurrentIteration')} <br /><br />
            { changed.slice(0, MAX_INSIGHTS_ALLOWED).map((node, idx) => <span key={'changed-'+idx}>{node.insight}</span>) }
            { changed.length > MAX_INSIGHTS_ALLOWED && <><b style={{cursor:'pointer'}} onClick={() => showAllOnModal(changed)} key={'changed-'+(MAX_INSIGHTS_ALLOWED+1)}> {I18n.t('dashboard.andNodesLeftMoreWereChanged').replace('NODES_LEFT', changed.length - MAX_INSIGHTS_ALLOWED)}</b><br /></> }
            <br />
            { created.slice(0, MAX_INSIGHTS_ALLOWED).map((node, idx) => <span key={'created-'+idx}>{node.insight}</span>) }
            { created.length > MAX_INSIGHTS_ALLOWED && <><b style={{cursor:'pointer'}} onClick={() => showAllOnModal(created)} key={'created-'+(MAX_INSIGHTS_ALLOWED+1)}> {I18n.t('dashboard.andNodesLeftMoreWereCreated').replace('NODES_LEFT', created.length - MAX_INSIGHTS_ALLOWED)}</b><br /></> }
            <br />
            { deleted.slice(0, MAX_INSIGHTS_ALLOWED).map((node, idx) => <span key={'deleted-'+idx}>{node.insight}</span>) }
            { deleted.length > MAX_INSIGHTS_ALLOWED && <><b style={{cursor:'pointer'}} onClick={() => showAllOnModal(deleted)} key={'deleted-'+(MAX_INSIGHTS_ALLOWED+1)}> {I18n.t('dashboard.andNodesLeftMoreWereDeleted').replace('NODES_LEFT', deleted.length - MAX_INSIGHTS_ALLOWED)}</b><br /></> }
        </p>
    }

    const getModalTitle = () => 
        props.isRunning ? 
            I18n.t('dashboard.allItemsOnRunningIteration') :
            I18n.t('dashboard.allItemsOnLastIteration');
    
    return <div>
        <Modal 
            isOpen={showItemsOnModal.length > 0}
            title={getModalTitle()}
            footer={{
                onCancel:() => setShowItemsOnModal([])
            }}
        >
            <ul style={{
                maxHeight: '460px',
                overflowY: 'scroll'
            }}>
                {showItemsOnModal.map((node, idx) => <li key={'all-'+idx}>{node.insight}</li>)}
            </ul>
        </Modal>
        {I18n.t('dashboard.hiFirstName').replace('FIRST_NAME', currentActiveUser.first_name)}
        {strategyMapInsights()}
    </div>;
}

const _UL = styled.ul`
    display: none;
    list-style: none;
    &.show {
        display: hidden!important;
    }
`;

const StrategyMapWidget = (props) => {
    const [runningStrategicIterations, setRunningStrategicIterations] = useState([]);
    const [oldStrategicIterations, setOldStrategicIterations] = useState([]);
    const [targets, setTargets] = useState([]);
    const {sidebarOpen} = useCoreSlice();
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;
    const [clickedWidget, setClickedWidget] = useState(false);
    const {nodeMap} = useStrategyMapSlice();
    const goalId = props.widget.filter.goal;

    moment.locale(I18n._localeKey);
    
    const sortByDate = (left, right) => moment.utc(right.end_date).diff(moment.utc(left.end_date));

    const iterationsDescription = (strategicIterationItems, isRunning) => {
        if (strategicIterationItems.length === 0)
            return <b>{I18n.t('dashboard.thereAreNoRunning')}</b>;

        const expand_target_list = (e) => {
            const targetList = e.target.nextSibling;
            if(targetList.classList.contains('show')) {
                targetList.classList.remove('show');
                e.target.innerHTML = 'expand_more';
            } else {
                targetList.classList.add('show');
                e.target.innerHTML = 'expand_less';
            }
        }
        
        return strategicIterationItems.map((si, index) => {
            const cadence = new Cadence(si, targets);

            const currentTargets = targets.filter((t) => si.targets.includes(t.id));

            return (<Div key={index} margin="10px 0">
                <h4>{si.title}</h4>
                <div>
                    <Symbol style={{verticalAlign: 'middle'}} className='material-icons'>library_add_check</Symbol>
                    <span style={{verticalAlign: 'middle'}}>{ cadence.getCompletedTargetsCountOnCurrentIteration() } / { cadence.getTargetsCountOnCurrentIteration() } {I18n.t("strategyMap.cadence.targets")}</span>

                    {isRunning && 
                        <>
                            <Symbol style={{verticalAlign: 'middle'}}  className='material-icons'>schedule</Symbol>
                            <span style={{verticalAlign: 'middle'}} >{ cadence.getDaysLeftCountOnCurrentIteration() } {I18n.t("strategyMap.cadence.daysLeft")}</span>
                        </>
                    }
                    <Symbol style={{verticalAlign: 'middle', cursor: 'pointer'}}  className='material-icons' onClick={expand_target_list}>expand_more</Symbol>
                    <_UL>
                    { currentTargets.length > 0 && currentTargets.map((t, idx) => 
                        <li key={idx} style={{verticalAlign: 'middle'}} >
                            {t.is_done ? 
                                <Symbol style={{verticalAlign: 'middle'}}  className='material-icons'>check_circle</Symbol> : 
                                <Symbol style={{verticalAlign: 'middle'}}  className='material-icons'>radio_button_unchecked</Symbol>}
                            {t.title}
                        </li>
                    ) }
                    </_UL>
                </div>
                <b>{I18n.t('charts.Topic.PieChart.period')}:</b> {moment(si.start_date).format('[from] D MMMM YYYY')} {moment(si.end_date).format('[to] D MMMM YYYY')}
                <StrategyMapOverview goalId={si.goal_id} startDate={si.start_date} endDate={si.end_date} isRunning={isRunning} />
            </Div>)
        })
    };
    
    useEffect(() => {
        Api.fetchStrategicIterations(goalId)
            .then((result) => {
                const strategicIterationsOrderedByDate = result.data.sort(sortByDate);
                setRunningStrategicIterations(strategicIterationsOrderedByDate.filter((si) => si.is_running));
                setOldStrategicIterations(strategicIterationsOrderedByDate.filter((si) => !si.is_running).slice(0, 1));
            } );
        Api.fetchSfs({goalId: goalId})
            .then((result) => {
                const targets = result.data.flatMap( sf => sf['targets']);
                setTargets(targets);
            } );
    }, []);

    const mainPanelActions = [];

    if (isEditMode) {
        mainPanelActions.push(
            {
                icon: 'delete',
                onClick: () => props.handleDeleteSMWidget(props.widget)
            }
        );
        mainPanelActions.push(
            {
                icon: 'settings',
                onClick: () => setClickedWidget(props.widget)
            }
        );
    }

    const panelHeader = <NodeLink onClick={(e) => goToNode(goalId, goalId)}>Goal #{goalId} {nodeMap[goalId]?.title || ''}</NodeLink>

    return <Panel header={panelHeader} actions={mainPanelActions}>
        <SelectItemWidgetModal
            clickedWidget={clickedWidget}
            closeModal={() => setClickedWidget(false)}
            selectedItemId={goalId}
        />
        <Row>
            <Col xs="12" sm="12" md="6" lg="6">
                <h3>{I18n.t('dashboard.runningIterations')}</h3>
                { iterationsDescription(runningStrategicIterations, true) }
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
                <h3>{I18n.t('dashboard.lastIteration')}</h3>
                { iterationsDescription(oldStrategicIterations, false) }
            </Col>
        </Row>
    </Panel>
}

const DashboardStrategyMap = () => {
    const {dashboardWidgetsVisible, Actions} = useCoreSlice();
    const [widgets, setWidgets] = useState([]);
    const widgetName = "Strategy map insights";

    useEffect(() => {
        const strategyMapGoalWidgets = dashboardWidgetsVisible ? 
                                        dashboardWidgetsVisible.filter((w) => w.name === widgetName) :
                                        [];
        setWidgets(strategyMapGoalWidgets);
    }, [dashboardWidgetsVisible])

    
    const handleDeleteSMWidget = (dashboardWidget) => {
        Actions.updateDashboard(
            dashboardWidgetsVisible
                .filter((w) => ! _.isEqual(w, dashboardWidget) )
        );
    };

    return <>
        {widgets.map((w) => <StrategyMapWidget key={w.filter.goal} widget={w} handleDeleteSMWidget={handleDeleteSMWidget} />)}
    </>
}

export default DashboardStrategyMap;