import {createSlice} from 'redux-starter-kit'
import {errorHandler} from '../../core/redux/slices'

export const integrationsSlice = createSlice({
    slice: 'INTEGRATIONS',
    initialState: {
        error: {},
        isFetching: false,
        trelloWorkspaces: [],
    },
    reducers: {
        disconnectSlack: (state) => {
            state.isFetching = true
        },
        disconnectSlackSuccess: (state) => {
            state.isFetching = false
        },
        disconnectTrello: (state) => {
            state.isFetching = true
        },
        disconnectTrelloSuccess: (state) => {
            state.isFetching = false
        },
        connectTrello: (state) => {
            state.isFetching = true
        },
        connectTrelloSuccess: (state) => {
            state.isFetching = false
        },
        fetchTrelloWorkspaces: (state) => {
            state.isFetching = true
        },
        fetchTrelloWorkspacesSuccess: (state, action) => {
            state.isFetching = false
            state.trelloWorkspaces = action.payload
        },
        requestFailure: errorHandler,
    },
})
