import React, {useEffect, useState} from 'react';
import { FormLabel } from 'react-bootstrap';
import { I18n } from 'react-i18nify';
import { Header } from '../../../ReactTourStories/Stories';
import { useDemographicsSlice } from '../../hooks/useDemographicsSlice';
import {NavItemIntern} from "../BootstrapWrapper";
import { ButtonPrimary, ButtonSecondary } from '../Buttons';
import Modal from '../Modal';
import { ModalFooterContainer, ModalFooterRight } from '../Modal.styles';
import { MainNavIcon } from './Navbar.styles';
import { useDashboardSlice } from '../../../dashboard/hooks/useDashboardSlice';
import { Smiley } from '../../../analysis/inspect/InspectDetails';
import { useCoreSlice } from '../../hooks/useCoreSlice';
import RTEditor from '../RTEditor';
import { useAnalysisSlice } from '../../../analysis/hooks/useAnalysisSlice';
import { getDemographicsAndDepartmentStats } from '../../../Charts/ChartCalculations';
import { CheckboxItem } from '../../../analysis/reports/GenerateHighResImageModal';
import { LoaderContainer } from '../../../strategyMap/components/Modal/ShareItems';
import { BarLoader } from 'react-spinners';

export const UserEngagement = () => {
    const [refreshRTEditor, setRefreshRTEditor] = useState(Date.now());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messageBody, setMessageBody] = useState(I18n.t('analysis.userEngagement.defaultText'));
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    const {wholeCompanyPeopleCount, departments} = useAnalysisSlice();
    const {decisions} = useDashboardSlice();
    const {department} = useDemographicsSlice();
    const {Actions: CoreActions, client, userEngagementLastEmail, isFetching} = useCoreSlice();

    useEffect(() => {
        CoreActions.getUserEngagementLastEmail()
    }, []);

    const {demographics: demographicsStats, departmentStat} = getDemographicsAndDepartmentStats(departments, wholeCompanyPeopleCount, client.max_decision_count);

    const getLabel = (name) => `${name} (${I18n.t('analysis.userEngagement.completion')}: ${departmentStat[name]}%)`;
    
    const getDepartmentName = (id) => {
        const dept = department && department.find( d => d.pk === id );
        return dept && dept.name;
    }

    useEffect(() => {
        if (userEngagementLastEmail && userEngagementLastEmail.messageBody !== "") { 
            setMessageBody(userEngagementLastEmail.messageBody);
        }
    }, [userEngagementLastEmail]);  

    const handleItemSelect = (item, checked) => {
        if (isFetching) return;
        
        if (checked)
            setSelectedDepartments( selectedDepartments.filter( it => it !== item.pk ) );
        else
            setSelectedDepartments( [...selectedDepartments, item.pk] );
    }

    return (<>
        <NavItemIntern
            onClick={() => setIsModalOpen(true)}
        >
            <MainNavIcon className="material-icons-outlined">campaign</MainNavIcon>
        </NavItemIntern>
        <Modal
            isOpen={ isModalOpen }
            footerContent={
                <ModalFooterContainer>
                    <ModalFooterRight>
                        <ButtonSecondary 
                            type="button" 
                            onClick={ () => setIsModalOpen( false )  }>
                            {I18n.t('analysis.buttons.cancel')}
                        </ButtonSecondary>
                        <ButtonPrimary
                            type="button"
                            disabled={messageBody === "" || selectedDepartments.length === 0}
                            onClick={ () => {
                                if (messageBody === "" || selectedDepartments.length === 0) return;
                                CoreActions.sendUserEngagementEmail({message_body: messageBody, departments_id: selectedDepartments})
                            }}
                        >{ I18n.t('capture.form.Send') }</ButtonPrimary>
                    </ModalFooterRight>
                </ModalFooterContainer>
            }
        >
            <Header>{I18n.t('analysis.userEngagement.userEngagement')} (beta)</Header>
            {isFetching && 
                <LoaderContainer>
                    <BarLoader />
                </LoaderContainer>
            }
            <div style={{margin:'0 2px', opacity: isFetching ? 0.3 : 1}}>
                <FormLabel>{I18n.t('analysis.overview.departments')}</FormLabel>
                { (department === null || department.length === 0) && <div>{I18n.t('analysis.userEngagement.NoDepartments')}</div> }
                {
                    department && 
                        department
                            .filter( d => !d.archived )
                            .map( dept => <CheckboxItem
                                                key={dept.pk}
                                                item={{title: getLabel(dept.name), pk: dept.pk}}
                                                checked={selectedDepartments.some( (it) => it === dept.pk )}
                                                handleItemSelect={handleItemSelect}
                                            />)
                }
                <FormLabel>{I18n.t('analysis.userEngagement.message')}</FormLabel>
                <RTEditor
                    key={refreshRTEditor}
                    text={messageBody}
                    placeholderText={I18n.t('analysis.userEngagement.placeholderMessage')}
                    onTextChange={(inputText) => setMessageBody(inputText) }
                    isReadOnly={isFetching}
                />
                <ButtonSecondary 
                    onClick={() => {
                        setMessageBody(I18n.t('analysis.userEngagement.defaultText'));
                        setRefreshRTEditor(Date.now());
                    }}
                >{I18n.t('analysis.userEngagement.backToDefaultText')}</ButtonSecondary>
                <br />
                <FormLabel>{I18n.t('analysis.userEngagement.availableTags')}</FormLabel>
                <br /><code>[field=first_name]</code>
                <br /><code>[field=domain]</code>
                <br /><code>[field=capture_instructions_link]</code>
                <hr />
                <FormLabel>{I18n.t('analysis.userEngagement.LastestDecisions')}</FormLabel>
                {decisions && 
                    decisions
                        .filter((a) => !a.hide_description)
                        .slice(0, 20)
                        .map((a, i) => (
                            <div key={i} style={{margin: '15px 0'}}>
                                <Smiley mood={a.mood} /> {a.title} ({getDepartmentName(a.demographics.department)})
                            </div>
                        ))
                }
                {decisions && decisions.filter((a) => !a.hide_description).length === 0 && <div>{I18n.t('analysis.userEngagement.NoDecisions')}</div>}
            </div>
        </Modal>
    </>);
};