import {PAGES} from "../../core/const";
import {ClickElement, TitleDescription} from "../Stories";
import {I18n} from "react-i18nify";
import React from "react";

export const orgscanUserJourney = (props) => ({

    page: PAGES.OrgScan.Dashboard,
    steps: [
        {
            style: {maxWidth: 970},
            content: <div style={{marginTop: 20}}>
                <iframe width="900" height="507" src="https://www.youtube.com/embed/f0FHLwW-pqg"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>,
            action: () => ClickElement.byClassName("capture")
        },
        {
            selector: '.dashboard-body',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    description={I18n.t("stories.capture.orgscan-user-journey.steps.dashboard-graphs.content")}
                />
            </div>,
            action: () => ClickElement.byClassName("back")
        }
    ]
});