import React, {useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {I18n} from 'react-i18nify'
import {Icon} from '../Charts/ChartsOrgScan'
import { Panel } from '../core/components/BootstrapWrapper';
import {showPopupMessage} from "../core/utils/notifications";
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import { ButtonSecondary } from '../core/components/Buttons'
import { SUBSCRIPTIONS, RESTRICTIONS, DASHBOARD_OPTIONS } from '../core/const';
import Modal from '../core/components/Modal'
import PriceComparisonTable from '../capture/PriceComparisonTable'
import Footer from '../core/ui/modal/Footer'
import { SwitchWithLabel } from '../core/components/Switch';

const ModalVersionOverview = () => {
    const [isOpen, setIsOpen] = useState(false)
    return <>
            <Modal
                isOpen={isOpen}
                onClose={() => {}}
                title={I18n.t('capture.compareTable.title')}
                footerContent={
                    <Footer
                        cancelText={I18n.t('strategyMap.messages.close')}
                        onCancel={() => setIsOpen(false)}
                    />
                }
            >
                <PriceComparisonTable />
            </Modal>
            <Icon style={{marginLeft: '5px'}} className="material-icons help" onClick={() => setIsOpen(true)}>help</Icon>
    </>
}

const VersionManagement = () => {
    const {Actions, client} = useCoreSlice();
    const user = useUserSlice();

    const onClickUpgradeToFull = () =>
        showPopupMessage({
                title: I18n.t('leadership.messages.upgradeToFull'),
                message: I18n.t('leadership.messages.doYouWantToUpgradeToFull'),
                confirmLabel: I18n.t('app.buttons.confirm'),
                cancelLabel: I18n.t('app.buttons.cancel'),
                onConfirmCallback: Actions.upgradeLeadership,
                onCancelCallback: () => {}
            });

    const onClickDownGradeToTeam = () => 
        showPopupMessage({
            title: I18n.t('leadership.messages.downgradeToTeam'),
            message: I18n.t('leadership.messages.doYouWantToDowngradeToTeam'),
            confirmLabel: I18n.t('app.buttons.confirm'),
            cancelLabel: I18n.t('app.buttons.cancel'),
            onConfirmCallback: Actions.downgradeLeadership,
            onCancelCallback: () => {}
        });

    const subscriptionPlan = client && client.subscriptions.slice(-1)[0]

    if( RESTRICTIONS.Settings.CAN_CHANGE_VERSION.some( role => user[role] ) && subscriptionPlan.plan.title === SUBSCRIPTIONS.LeadershipTeam )
        return <>
                <ButtonSecondary onClick={ onClickUpgradeToFull }>{I18n.t('leadership.messages.upgradeToFull')}</ButtonSecondary>
                <ModalVersionOverview />
            </>;
    if( RESTRICTIONS.Settings.CAN_CHANGE_VERSION.some( role => user[role] ) && subscriptionPlan.plan.title === SUBSCRIPTIONS.LeadershipFull )
        return <>
                <ButtonSecondary onClick={ onClickDownGradeToTeam }>{I18n.t('leadership.messages.downgradeToTeam')}</ButtonSecondary>
                <ModalVersionOverview />
            </>;

    return <></>
}

const BillOverview = () => {
    const {client, Actions} = useCoreSlice()

    useEffect(() => {
        if( ! client.name )
            Actions.getClient();
    }, []);

    const {subscriptions, billing_emails, total_users, billable_users_count} = client;
    const lastSub = subscriptions && subscriptions[subscriptions.length - 1] || {};

    return (
        <div>
            <Panel header={I18n.t('billing.Label.Subscription')}>
                {lastSub && (
                    <div>
                        <p>
                            {I18n.t('billing.Label.PlanDescription')}:{' '}
                            <strong>{lastSub.plan.title}</strong>
                            <VersionManagement />
                        </p>
                        <p>
                            {I18n.t('billing.Label.PlanStartDescription')}:{' '}
                            <strong>
                                {I18n.l(new Date(lastSub.created_at), {
                                    dateFormat: 'D MMMM YYYY',
                                })}
                            </strong>
                        </p>
                    </div>
                )}
                {subscriptions && !subscriptions.length && (
                    <div>
                        <p>{I18n.t('billing.Label.NoSubscriptionMessage')}</p>
                    </div>
                )}

                <div style={{width: 300}}>
                    <SwitchWithLabel
                        label={"OrgScan Dashboard Full"}
                        defaultValue={client.dashboard_option === DASHBOARD_OPTIONS.full}
                        onChange={() => {
                            const isDashboardFull = client.dashboard_option === DASHBOARD_OPTIONS.full
                            Actions.updateClient({ dashboard_option: isDashboardFull ? DASHBOARD_OPTIONS.lite : DASHBOARD_OPTIONS.full })
                        }}
                    />
                </div>

            </Panel>
            <Panel header={I18n.t('billing.Label.AccountStatus')}>
                <p>
                    {I18n.t('billing.Label.UserCountDescription')}:{' '}
                    <strong>
                        {total_users} {I18n.t('billing.Label.RegisteredUsers')}
                    </strong>
                </p>
                <p>
                    {I18n.t('billing.Label.ActiveUsersDescription')}:{' '}
                    <strong>
                        {billable_users_count} {I18n.t('billing.Label.ActiveUsers')}
                    </strong>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-${'top'}`} style={{opacity: 1}}>
                                <b>{I18n.t('billing.Label.ActiveUsersTooltip')}</b>
                            </Tooltip>
                        }
                    >
                        <Icon style={{marginLeft: '5px'}} className="material-icons help">
                            help
                        </Icon>
                    </OverlayTrigger>
                </p>
                {billing_emails && !!billing_emails.length && (
                    <p>
                        {I18n.t('billing.Label.BillingEmailsDescription')}:{' '}
                        <strong>{billing_emails.map((item) => item.email).join(', ')}</strong>
                    </p>
                )}
            </Panel>
        </div>
    )
}

export default BillOverview
