import React, {useState} from 'react'
import {I18n} from 'react-i18nify'
import PropTypes from 'prop-types'
import {FormControl, FormLabel} from 'react-bootstrap'
import FormError from './FormError'
import {connect} from 'react-redux'
import {ButtonSecondary} from './Buttons'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {A42FormGroup, FormCheckbox} from "./BootstrapWrapper";

const commonPasswords = require('dumb-passwords')

const passwordContainsUserDetails = (user, password) => {
    const {first_name, last_name, email} = user

    const passwordContainsFirstName =
        first_name && password.toLowerCase().includes(first_name.toLowerCase())
    const passwordContainsLastName =
        last_name && password.toLowerCase().includes(last_name.toLowerCase())
    const passwordContainsEmail = email && password.toLowerCase().includes(email.toLowerCase())

    return passwordContainsFirstName || passwordContainsLastName || passwordContainsEmail
}

const PasswordFormState = {
    init: 'init',
    progress: 'progress'
};

const PasswordForm = (props) => {
    const user = useUserSlice();
    const [passwordFormStatus, setPasswordFormStatus] = useState( PasswordFormState.init );
    const [errors, setErrors] = useState(props.errors || [] );
    const [passwordFormValid, setPasswordFormValid] = useState(props.passwordFormValid);
    const [currentPassword, setCurrentPassword] = useState(props.currentPassword);
    const [newPassword, setNewPassword] = useState(props.newPassword);
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [newPasswordValid, setNewPasswordValid] = useState(props.newPasswordValid);
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordChange = (field, value) => {
        switch(field){
            case 'newPassword':
                setNewPassword(value);
                break;
            case 'confirmNewPassword':
                setConfirmNewPassword(value);
                break;
            case 'currentPassword':
                setCurrentPassword(value);
                break;
        }
        validatePasswordField(field, value);
    }

    const validatePasswordField = (field, value) => {
        const validationErrors = errors;
        const newPasswordErrors = [];

        if (field === 'newPassword') {

            if ( value.length < 8 )
                newPasswordErrors.push(I18n.t('formError.validationLength'))
            if ( ! isNaN(value) )
                newPasswordErrors.push(I18n.t('formError.validationNumeric'))
            if ( value === '' )
                newPasswordErrors.push(I18n.t('formError.validationBlank'))
            if ( passwordContainsUserDetails( user, value ) )
                newPasswordErrors.push(I18n.t('formError.validationContainsPersonalInfo'))
            if ( commonPasswords.check( value ) )
                newPasswordErrors.push(I18n.t('formError.cantHaveCommonlyUsed'))
            if (value !== confirmNewPassword)
                newPasswordErrors.push(I18n.t('formError.confirmPasswordDoesntMatch'))
        }

        if (field === 'confirmNewPassword') {
            if (value !== newPassword)
                newPasswordErrors.push(I18n.t('formError.confirmPasswordDoesntMatch'))
        }

        validationErrors.newPassword = newPasswordErrors

        const passwordFormValid = newPasswordErrors.length === 0;

        setPasswordFormValid( passwordFormValid );
        setErrors( validationErrors );
        setNewPasswordValid( newPasswordErrors );
        setPasswordFormStatus( PasswordFormState.progress );
    }

    const handleKeypressEnter = (key) => {
        if (key.key === 'Enter' && newPasswordValid) {
          submitForm();
        }
    };

    const submitForm = () =>
        setTimeout( () => props.onPasswordSave(currentPassword, newPassword), 1000 );

    const getInputType = () => showPassword ? "text" : "password";

    return (
        <div data-testid={'password-form'} id={'password-form'}>
            <FormError errors={errors} />
            { props.confirmCurrentPassword &&
                <A42FormGroup controlId="formCurrentPassword">
                    <FormLabel>{I18n.t('profile.Label.OldPassword')}</FormLabel>
                    <FormControl
                        type={getInputType()}
                        disabled={props.disabled || false}
                        value={currentPassword}
                        onChange={ ({target}) => handlePasswordChange('currentPassword', target.value) }
                    />
                    <FormControl.Feedback />
                </A42FormGroup>
            }
            <A42FormGroup controlId="formNewPassword">
                <FormLabel>{I18n.t('profile.Label.NewPassword')}</FormLabel>
                <FormControl
                    type={getInputType()}
                    disabled={props.disabled || false}
                    value={newPassword}
                    onChange={ ({target}) => handlePasswordChange('newPassword', target.value) }
                    onKeyPress={ handleKeypressEnter }
                />
                <FormControl.Feedback />
            </A42FormGroup>
            <A42FormGroup controlId="formNewPasswordConfirm">
                <FormLabel>{I18n.t('profile.Label.ConfirmNewPassword')}</FormLabel>
                <FormControl
                    type={getInputType()}
                    disabled={props.disabled || false}
                    value={confirmNewPassword}
                    onChange={ ({target}) => handlePasswordChange('confirmNewPassword', target.value) }
                    onKeyPress={ handleKeypressEnter }
                />
                <FormControl.Feedback />
            </A42FormGroup>

            <A42FormGroup>
                <FormCheckbox
                    type='checkbox'
                    key={'show-password-' + showPassword}
                    id='show-password'
                    label='Show password'
                    checked={showPassword}
                    onChange={({target}) => setShowPassword(target.checked)}
                />
            </A42FormGroup>

            <ButtonSecondary
                type="button"
                fullWidth
                onClick={ submitForm }
                disabled={ passwordFormStatus === PasswordFormState.init || ! passwordFormValid || currentPassword === '' || confirmNewPassword === '' }
            >
                {props.firstAccess
                    ? I18n.t('profile.Button.CreatePassword')
                    : I18n.t('profile.Button.UpdatePassword')}
            </ButtonSecondary>
        </div>
    )
}

PasswordForm.propTypes = {
    errors: PropTypes.object,
    passwordFormValid: PropTypes.bool,
    currentPassword: PropTypes.string,
    newPassword: PropTypes.string,
    newPasswordValid: PropTypes.bool,
    confirmCurrentPassword: PropTypes.bool,
    firstAccess: PropTypes.bool,
}

PasswordForm.defaultProps = {
    errors: {},
    passwordFormValid: false,
    currentPassword: null,
    newPassword: '',
    newPasswordValid: true,
    confirmCurrentPassword: true,
    firstAccess: false,
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        currentLanguage: state.language.current,
    }
}

const ConnectedPasswordForm = connect(mapStateToProps)(PasswordForm)

export default ConnectedPasswordForm
