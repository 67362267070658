import React from 'react'
import CommonPage from '../core/components/CommonPage'
import {I18n} from 'react-i18nify'
import {Analysis} from './Analysis'
import FeaturePermission from '../core/components/FeaturePermission'
import LeadershipAssessmentAnalysis from "./leadershipAssessment/LeadershipAssessmentAnalysis";
import { useHistory } from '../core/hooks/useHistory'

const AnalysisPage = () => {
    const {isLeadershipAnalysisToolPage} = useHistory();

    const target = () => isLeadershipAnalysisToolPage ? <LeadershipAssessmentAnalysis /> : <Analysis />;

    return (
        <FeaturePermission name="analyze">
            <CommonPage pageHeading={I18n.t('analysis.title')} render={target} />
        </FeaturePermission>
    )
}

export default AnalysisPage
