import React from 'react'
import {useDrop} from 'react-dnd'
import { ITEM_TYPES } from '../const'
import {handleDrop} from '../utils'
import {useActions} from '../../core/hooks/useActions'
import {strategyMapSlice} from '../redux/slices'
import {useSelector} from 'react-redux'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'

const DroppableBoard = ({style, timeArea, x}) => {
    const {clickedNodesToChildren} = useStrategyMapSlice()

    const Actions = useActions(strategyMapSlice.actions)
    const {nodeMap} = useSelector((state) => state.strategyMap)
    const [_, drop] = useDrop({
        accept: ITEM_TYPES.NODE,
        drop(props, monitor) {
            handleDrop(monitor, Actions, x, -1, timeArea, nodeMap, clickedNodesToChildren)
        },
    })

    return <div style={{...style, width: 'calc(100% / 3)'}} ref={drop} />
}

export default DroppableBoard
