import React, {useState} from 'react'
import {Input} from '../core/components/Navbar/NodeSearch.styles'
import {ButtonSecondary} from '../core/components/Buttons'
import {useActions} from '../core/hooks/useActions'
import {userSlice} from '../auth/redux/user.slices'
import {I18n} from 'react-i18nify'
import { validateValue } from '../core/utils/form'

export const NewUser = () => {
    const [state, setState] = useState({})
    const [errors, setErrors] = useState({})
    const {inviteUser} = useActions(userSlice.actions)

    const inputStyles = {width: '100%', margin: 0}
    const btnStyles = {margin: 0, height: 34}

    return (
        <tr data-testid="newuser-row">
            <td />
            <td>
                <Input
                    style={inputStyles}
                    value={state.email || ''}
                    onChange={(e) => {
                        const emailValue = e.target.value.toLowerCase().trim()
                        setErrors({...errors, email: !validateValue('email', emailValue)[0]})
                        setState({...state, email: emailValue})
                    }}
                    data-testid="newuser-email"
                />
            </td>
            <td>
                <Input
                    style={inputStyles}
                    value={state.first_name || ''}
                    onChange={(e) => setState({...state, first_name: e.target.value})}
                    data-testid="newuser-firstname"
                />
            </td>
            <td>
                <Input
                    style={inputStyles}
                    value={state.last_name || ''}
                    onChange={(e) => setState({...state, last_name: e.target.value})}
                    data-testid="newuser-lastname"
                />
            </td>
            <td>
                <ButtonSecondary
                    disabled={
                        !(state.first_name && state.last_name && state.email && !errors.email)
                    }
                    style={btnStyles}
                    onClick={() => inviteUser(state)}
                    data-testid="invite-user-button"
                >
                    {I18n.t('billing.Label.Invite')}
                </ButtonSecondary>
            </td>
        </tr>
    )
}

export default NewUser
