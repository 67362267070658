import React, { useEffect, useState } from 'react'
import {I18n} from 'react-i18nify'
import {SubTypes} from '../ChartsConfig'
import styled from 'styled-components'

import {Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, XAxis, YAxis} from 'recharts'
import {DecisionAnswers} from './agileBarConfig'
import {useLanguageSlice} from '../../core/hooks/useLanguageSlice'
import {union} from 'ramda'
import {useSelector} from 'react-redux'
import {truncateLabel} from '../../core/utils/string';

const ChartDIV = styled.div`
    .xAxis {
        background-color: blue;
        .recharts-cartesian-axis-ticks {
            transform: translateY(17px);
            line {
                transform: translateY(-15px);
            }
        }
    }
`;

const mergeData = (dataPresent, dataPast) => {
    let merged = []
    merged = dataPresent

    Object.keys(dataPast).forEach((key) => {
        const existInMerged = merged.find((item) => item.name === dataPast[key].name)
        if (existInMerged) {
            existInMerged[dataPast[key].name + '_past'] = dataPast[key][dataPast[key].name]
        }
    })

    return merged
}

const barChartData = (labels, oData) => {
    labels.forEach((sort, idx) => {
        const finding = oData && oData.filter((da) => da.name === sort.name)
        labels[idx] =
            finding && finding.length ? {...finding[0]} : {name: sort.name, [sort.name]: 0}
    })
    return [...labels]
}

const reformatDataOnSingleBar = (subType, dataParam) => {
    const result = []
    const selectedGroup = dataParam.filter((item) => item.name === subType)

    if (selectedGroup.length !== 0) {
        Object.keys(selectedGroup[0]).forEach((item) => {
            if (item !== 'name') {
                const emptyObject = {}

                emptyObject.name = item
                emptyObject[item] = selectedGroup[0][item]

                result.push(emptyObject)
            }
        })
    }

    return result
}

const orderBars = (points, order) => {
    const orderedPoints = []

    // order points
    order.forEach((label) => {
        const point = points.find((point) => point.name === I18n.t('charts.Answers.' + label))
        if (point !== undefined) {
            orderedPoints.push(point)
        }
    })

    // unordered points
    points.forEach((point) => {
        const unsortedPoint = order.find(
            (label) => I18n.t('charts.Answers.' + label) === point.name,
        )
        if (unsortedPoint === undefined) {
            orderedPoints.push(point)
        }
    })
    return orderedPoints
}

const sortUniqueAnswers = (uniqueAnswers, type) => {
    switch (type) {
        case SubTypes.bar.mood:
            const moods = DecisionAnswers.mood.map((mood) => I18n.t('charts.Answers.' + mood))
            return union(moods, uniqueAnswers)

        case SubTypes.bar.frequency:
            const frequency = DecisionAnswers.frequency.map((fre) =>
                I18n.t('charts.Answers.' + fre),
            )
            return union(frequency, uniqueAnswers)

        case SubTypes.bar.period:
            const speed = DecisionAnswers.speed.map((spe) => I18n.t('charts.Answers.' + spe))
            return union(speed, uniqueAnswers)

        case SubTypes.bar.decisionOwner:
            const owners = DecisionAnswers.owner.map((owner) => I18n.t('charts.Answers.' + owner))
            return union(owners, uniqueAnswers)

        case SubTypes.bar.type:
            const types = DecisionAnswers.type.map((_type) => I18n.t('charts.Answers.' + _type))
            return union(types, uniqueAnswers)

        default:
            return uniqueAnswers
    }
}
const getUniqueAnswers = (dataParam) => {
    const uniqueAnswers = []

    dataParam.forEach((question) => {
        Object.keys(question).forEach((answer) => {
            if (answer !== 'name' && uniqueAnswers.indexOf(answer) === -1) {
                uniqueAnswers.push(answer)
            }
        })
    })

    return uniqueAnswers
}

const StackedBar = (props) => {
    const {current} = useLanguageSlice()
    const demographics = useSelector((state) => state.demographics)

    const getPointsOfProps = () => {
        if( props.stacked || props.grouped ){
            return props.data[0]
        } else {
            if (Array.isArray(props.data) && props.data.length === 1 && props.data[0].length === 0) {
                return undefined
            } else {
                const pointsPresent = reformatDataOnSingleBar(props.subType, props.data[0])
                const pointsPast =
                    props.data.length >= 2
                        ? reformatDataOnSingleBar(props.subType, props.data[1])
                        : []

                const points =
                    props.data.length >= 2 ? mergeData(pointsPresent, pointsPast) : pointsPresent

                switch (props.subTypes) {
                    case SubTypes.bar.period:
                        return orderBars(points, DecisionAnswers.speed)

                    case SubTypes.bar.frequency:
                        return orderBars(points, DecisionAnswers.frequency)

                    case SubTypes.bar.mood:
                        return orderBars(points, DecisionAnswers.mood)

                    default:
                        return pointsPresent
                }
            }
        }
    }

    const getBar = (dataParam, subTypes) => {
        const bar = []

        let uniqueAnswers = getUniqueAnswers(dataParam)
        uniqueAnswers = sortUniqueAnswers(uniqueAnswers, subTypes && subTypes[1])

        uniqueAnswers.forEach((answer) => {
            let colorCode = undefined

            if (props.stacked) {
                if (props.barColors) {
                    Object.keys(props.barColors).forEach((item) => {
                        if (I18n.t('charts.Answers.' + item) === answer)
                            colorCode = props.barColors[item]
                    })
                }

                bar.push(
                    <Bar
                        key={answer}
                        name={answer}
                        dataKey={answer}
                        stackId={'OneStack'}
                        fill={colorCode}
                    />,
                )
            } else {
                const stackID =
                    dataParam.filter((item) => item.name === answer).length === 1
                        ? 'OneStack'
                        : 'SecondStack'
                const columnWidth = (props.width - 80) / (dataParam.length + 5)

                if (props.barColors) {
                    if (answer.endsWith('_past')) {
                        Object.keys(props.barColors.confirmed).forEach((item) => {
                            if (I18n.t('charts.Answers.' + item) + '_past' === answer) {
                                colorCode = props.barColors.confirmed[item]
                            }
                        })
                        colorCode =
                            colorCode === undefined ? props.barColors.confirmed.default : colorCode
                    } else {
                        Object.keys(props.barColors.validating).forEach((item) => {
                            if( I18n.t('charts.Answers.' + item) === answer ) {
                                colorCode = props.barColors.validating[item]
                            }
                        })
                        colorCode =
                            colorCode === undefined ? props.barColors.validating.default : colorCode
                    }
                }

                bar.push(
                    <Bar
                        key={answer}
                        name={ props.grouped ? I18n.t("charts.Answers.BarGrouped."+answer) : answer}
                        dataKey={answer}
                        stackId={ props.grouped ? undefined : stackID}
                        barSize={ props.grouped ? undefined : stackID === 'OneStack' ? columnWidth : -20}
                        fill={colorCode}
                        isAnimationActive={false}
                    >
                        {showValues(answer)}
                    </Bar>,
                )
            }
        })

        return bar
    }

    const showValues = (datakey) => {
        if (props.noLabel) {
            return ''
        }

        switch (props.layout) {
            case 'horizontal':
                return <LabelList dataKey={datakey} value="any" position="top" />
            case 'vertical':
                return <LabelList dataKey={datakey} value="any" position="left" />
            default:
                return ''
        }
    }

    const getXAxisConfig = () => {
        switch (props.layout) {
            case 'vertical':
                const domain = props.noLabel ? [0, 'auto'] : ['0', (dataMax) => dataMax * 1.25]
                return <XAxis type="number" domain={domain} />
            case 'horizontal':
            default:
                return <XAxis dataKey="name" angle={-35} padding={{top: 10}} interval={0} height={50} />
        }
    }

    const getYAxisConfig = () => {
        switch (props.layout) {
            case 'vertical':
                return (
                    <YAxis
                        dataKey="name"
                        type="category"
                        mirror={!props.stacked}
                        orientation={props.stacked ? undefined : 'right'}
                        axisLine={false}
                        width={80}
                        padding={{left: 100}}
                    />
                )
            case 'horizontal':
            default:
                return <YAxis orientation={'right'} axisLine={false} padding={{top: 20}} />
        }
    }

    const sortBarsByType = (dataParam, type) => {
        switch (type) {
            case SubTypes.bar.decisionOwner:
                const owners = DecisionAnswers.owner.map((owner) => ({
                    name: I18n.t('charts.Answers.' + owner),
                }))
                return barChartData(owners, dataParam)
            case SubTypes.bar.mood:
                const moods = DecisionAnswers.mood.map((mood) => ({
                    name: I18n.t('charts.Answers.' + mood),
                }))
                return barChartData(moods, dataParam)
            case SubTypes.bar.frequency:
                const frequencies = DecisionAnswers.frequency.map((fre) => ({
                    name: I18n.t('charts.Answers.' + fre),
                }))
                return barChartData(frequencies, dataParam)
            case SubTypes.bar.period:
                const periods = DecisionAnswers.speed.map((speed) => ({
                    name: I18n.t('charts.Answers.' + speed),
                }))
                return barChartData(periods, dataParam)
            case SubTypes.bar.type:
                const types = DecisionAnswers.type.map((_type) => ({
                    name: I18n.t('charts.Answers.' + _type),
                }))
                return barChartData(types, dataParam)

            case SubTypes.bar.year:
                const years = demographics.years
                    ? demographics.years
                          .sort((a, b) => a.position - b.position)
                          .map((year) => ({name: year[current]}))
                    : []
                return barChartData(years, dataParam)

            default:
                const maxLabelLength = 18
                return (
                    dataParam &&
                    dataParam.map((dataItem) => {
                        return {...dataItem, name: truncateLabel(dataItem.name, maxLabelLength)}
                    })
                )
        }
    }

    const [data, setData] = useState(null)
    const checkIfDataIsInvalid = () => JSON.stringify(props.data) === '[[]]'
    const setDefaultDataValue = () => (checkIfDataIsInvalid() ? null : getPointsOfProps())

    useEffect(() => {
        if (props.data.length > 0) {
            setData(setDefaultDataValue())
        }
    }, [props])

    const subType = props.subTypes && props.subTypes.length ? props.subTypes[0] : props.subType
    const invalid = data === null
    const extraHeightNeeded = props.layout === 'vertical' && data && data.length > 10 && props.extraHeightNeeded

    const height = extraHeightNeeded ? props.height * (data.length / 10) : props.height

    const RC = ({children}) =>
        extraHeightNeeded
        ? <ResponsiveContainer aspect={1} width={ props.width } height={height}>{ children }</ResponsiveContainer>
            : <>{ children }</>;

    return (
        <ChartDIV data-testid={props.testId}>
            <RC>
                    <CartesianGrid
                        strokeDasharray="3 3"
                        vertical={props.layout === 'vertical'}
                        horizontal={props.layout === 'horizontal'}
                    />
                <BarChart
                    labelSize={12}
                    autoscale={false}
                    width={props.width}
                    height={height}
                    layout={props.layout}
                    data={sortBarsByType(data, subType)}
                    margin={{top: 5, right: 30, left: 50, bottom: 5}}
                >
                    {invalid ? '' : getXAxisConfig()}
                    {invalid ? '' : getYAxisConfig()}
                    {invalid ? '' : getBar(data, props.subTypes)}
                    {props.legend && <Legend verticalAlign="top" height={18} />}
                </BarChart>
            </RC>
        </ChartDIV>
    )
}

StackedBar.defaultProps = {
    width: 550,
    height: 500,
    layout: 'vertical',
    stacked: true,
}

export default StackedBar
