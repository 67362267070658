import {all, call, put, select, takeEvery, takeLeading} from 'redux-saga/effects';

import {coreSlice, demographicsSlice, languageSlice} from './slices';
import Api from '../api';
import {javascriptize} from '../utils/object';
import {I18n} from 'react-i18nify';
import {captureRavenException, consoleError} from '../utils/error';
import Raven from "raven-js"
import {strategyMapSlice} from "../../strategyMap/redux/slices";
import {isLoggedIn} from "../utils/auth";
import {isPublicInstance} from "../utils/network";
import { displayToastMessage, showPopupMessage } from '../utils/notifications';
import {updateUserProfile} from "../../sidebars/UserProfileContainer/UserProfileContainer.actions";

export const createAPISaga = (getAPI, successHandler, errorHandler) => {
    return function* (action) {
        try {
            const response = yield call(getAPI, action.payload)
            yield put(successHandler(response.data))
        } catch (error) {
            consoleError(error)
            yield put(errorHandler(error))
        }
    }
}

function* fetchSubScriptions(){
    try {
        const response = yield call(Api.fetchSubScriptions)
        yield put(coreSlice.actions.fetchSubScriptionsSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* upgradeLeadership() {
    try {
        yield call(Api.upgradeLeadership)
        yield put(coreSlice.actions.getClient())
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* downgradeLeadership() {
    try {
        const response = yield call(Api.downgradeLeadership)
        const errors = response.data && response.data.errors
        if(errors) {
            const message = Object.keys(errors)
                                .filter( (e) => errors[e] )
                                .map((e) => I18n.t(`leadership.messages.downgradeVersionToFull.${e}`))
            const warning = {
                title: I18n.t('leadership.messages.downgradeVersionToFull.callbackErrorTitle'),
                message: [I18n.t(`leadership.messages.downgradeVersionToFull.restrictionsMessage`), ...message].join("\n "),
                confirmLabel: I18n.t('strategyMap.messages.ok'),
                onConfirmCallback: () => {},
            }
            showPopupMessage(warning)
        } else {
            yield put(coreSlice.actions.getClient())
        }
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* fetchGitSaga() {
    try {
        const reduxState = yield select();
        const response = yield call(Api.fetchGit)
        yield put(coreSlice.actions.fetchGitSuccess(response.data))
        if( reduxState.core && reduxState.core.git && reduxState.core.git.latestCommitVersion && reduxState.core.git.latestCommitVersion !== response.data.git_commit_version ){
            yield put(coreSlice.actions.showPageReloadPrompt() )
        }

    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* runCommandSaga(action) {
    try {
        const response = yield call(Api.runCommand, action.payload)
        yield put(coreSlice.actions.runCommandSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* sendUserEngagementEmailSaga(action) {
    try {
        const response = yield call(Api.sendUserEngagementEmail, action.payload)
        displayToastMessage('success', I18n.t('sidebar.sharing.dialog.response.onSuccess'))
        yield put(coreSlice.actions.sendUserEngagementEmailSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* getUserEngagementLastEmailSaga() {
    try {
        const response = yield call(Api.getUserEngagementLastEmail)
        yield put(coreSlice.actions.getUserEngagementLastEmailSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* fetchLanguagesSaga(action) {

    if( ! isPublicInstance() )
        try {
            if (action.payload &&  action.payload.staticLanguage){
                yield put(languageSlice.actions.fetchLanguagesStaticSuccess())
            } else{
                const response = yield call(Api.fetchLanguages)
                const data = javascriptize(response.data)
                yield put(languageSlice.actions.fetchLanguagesSuccess(data))
            }
            yield put(demographicsSlice.actions.sortDemographicsAlphabetically())
        } catch (error) {
            consoleError(error)
            yield put(languageSlice.actions.requestFailure(error))
        }
}

function* updateLanguageSaga(action) {
    try {
        const language = action.payload;
        yield call(Api.updateLanguage, language);
        yield put(languageSlice.actions.setLanguage(language));
        yield put(coreSlice.actions)
    } catch (error) {
        consoleError(error);
        yield put(languageSlice.actions.requestFailure(error));
    }
}
function* showStrategyMapNode(action){
    yield put( strategyMapSlice.actions.openNode( action.payload ) );
}

function* fetchDemographicsSaga() {
    if( isLoggedIn() )
        try {
            const response = yield call(Api.fetchDemographics)
            yield put(demographicsSlice.actions.fetchDemographicsSuccess(response.data))
            yield put(demographicsSlice.actions.sortDemographicsAlphabetically())
        } catch (error) {
            consoleError(error)
            captureRavenException('fetchDemographics', {
                extra: {'error message': error},
            })
        }
}
function* fetchTeamsSaga() {
    try {
        const response = yield call(Api.fetchTeams)
        yield put(coreSlice.actions.fetchTeamsSuccess(response.data))
    } catch (error) {
        consoleError(error)
        captureRavenException('fetchTeams', {
            extra: {'error message': error},
        })
    }
}

function* createTeamSaga(action) {
    try {
        const response = yield call(Api.createTeam, action.payload)
        yield put(coreSlice.actions.createTeamSuccess(response.data))
    } catch (error) {
        consoleError(error)
        captureRavenException('createTeams', {
            extra: {'error message': error},
        })
    }
}
function* updateTeamSaga(action) {
    try {
        const response = yield call(Api.updateTeam, action.payload)
        yield put(coreSlice.actions.updateTeamSuccess(response.data))
    } catch (error) {
        yield put(coreSlice.actions.requestFailure(error))
        Raven.captureException("updateTeam", {error})
    }
}

function* deleteTeamSaga(action) {
    try {
        yield call(Api.deleteTeam, action.payload)
        yield put(coreSlice.actions.deleteTeamSuccess(action.payload))
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

function* updateDemographicsTenantSaga(action) {
    try {
        const payload = action.payload
        yield call(Api.updateDemographicsTenant, payload.data, payload.detail)

        const response = yield call(Api.fetchDemographics)
        yield put(demographicsSlice.actions.fetchDemographicsSuccess(response.data))
        yield put(demographicsSlice.actions.sortDemographicsAlphabetically())
    } catch (error) {
        consoleError(error)
        captureRavenException('updateDemographicsTenant', {
            extra: {'error message': error}
        });
    }
}

function* getClient(){
    try {
        const response = yield call( Api.getClient );
        yield put( coreSlice.actions.getClientSuccess( response.data ) );
    } catch( error ){
        consoleError( error );
        captureRavenException('getClient', {
            extra: {'error message': error}
        });
        yield put( coreSlice.actions.requestFailure() );
    }

}

function* updateDashboard(action){
    const {payload} = action;

    if(payload) {
        yield call(updateUserProfile, { 'dashboard': payload });
        
        yield put( coreSlice.actions.updateDashboardWidgets(payload) );
    }
    else {
        yield put( coreSlice.actions.dashboardWidgetDraggingEnds() );

        const reduxState = yield select();

        yield call(updateUserProfile, { 'dashboard': reduxState.core.dashboardWidgetsVisible } );
    }
}

function* updateDashboardOrder(action){
    const {payload} = action;

    const reduxState = yield select();
    if( reduxState.core.dashboardWidgetsOrder.length )
        yield call(updateUserProfile, { 'dashboardOrder': payload } );
}

const createClient = createAPISaga(
    Api.createClient,
    coreSlice.actions.createClientSuccess,
    coreSlice.actions.requestFailure
)

function* updateClient(action) {
    try {
        yield call(Api.updateClient, action.payload )
        yield put(coreSlice.actions.getClient())
    } catch (error) {
        consoleError(error)
        yield put(coreSlice.actions.requestFailure(error))
    }
}

export default function* flow() {
    yield all([
        takeEvery(demographicsSlice.actions.fetchDemographics, fetchDemographicsSaga),
        takeEvery(coreSlice.actions.updateDashboard, updateDashboard),
        takeEvery(coreSlice.actions.updateDashboardOrder, updateDashboardOrder),
        takeEvery(coreSlice.actions.fetchTeams, fetchTeamsSaga),
        takeEvery(coreSlice.actions.createTeam, createTeamSaga),
        takeEvery(coreSlice.actions.updateTeam, updateTeamSaga),
        takeEvery(coreSlice.actions.deleteTeam, deleteTeamSaga),
        takeEvery(demographicsSlice.actions.updateDemographicsTenant, updateDemographicsTenantSaga),
        takeEvery(coreSlice.actions.fetchGit, fetchGitSaga),
        takeEvery(languageSlice.actions.fetchLanguages, fetchLanguagesSaga),
        takeEvery(languageSlice.actions.updateLanguage, updateLanguageSaga),
        takeLeading(coreSlice.actions.getClient, getClient),
        takeLeading(coreSlice.actions.createClient, createClient),
        takeLeading(coreSlice.actions.updateClient, updateClient),
        takeLeading(coreSlice.actions.showStrategyMapNode, showStrategyMapNode),
        takeLeading(coreSlice.actions.fetchSubScriptions, fetchSubScriptions),
        takeLeading(coreSlice.actions.upgradeLeadership, upgradeLeadership),
        takeLeading(coreSlice.actions.downgradeLeadership, downgradeLeadership),
        takeEvery(coreSlice.actions.runCommand, runCommandSaga),
        takeEvery(coreSlice.actions.sendUserEngagementEmail, sendUserEngagementEmailSaga),
        takeEvery(coreSlice.actions.getUserEngagementLastEmail, getUserEngagementLastEmailSaga),
    ])
}
