import {useMemo, useState} from 'react'
import {
    __,
    adjust,
    ascend,
    complement,
    cond,
    curry,
    descend,
    filter,
    findIndex,
    has,
    head,
    isEmpty,
    map,
    move,
    pipe,
    prepend,
    prop,
    remove,
    sortWith,
    when,
} from 'ramda'
import {isString} from 'ramda-adjunct'
import {callBoth} from '../utils/functional'
import {key, value} from '../utils/object'

export const ASCENDING = 'ASCENDING'
export const DESCENDING = 'DESCENDING'

const simplifyString = (s) => {
    return s
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // remove accent characters
}

const sortingFn = (obj) => {
    const field = key(obj)
    const direction = value(obj)
    return direction === ASCENDING
        ? ascend(pipe(prop(field), when(isString, simplifyString)))
        : descend(pipe(prop(field), when(isString, simplifyString)))
}

export const sortItems = (ordering, orderedItems) => {
    return sortWith(map(sortingFn, ordering), orderedItems)
}

const fieldIndex = curry((field, ordering) => {
    return findIndex(has(field), ordering)
})

export const _removeOrdering = (field, ordering) => {
    return remove(fieldIndex(field, ordering), 1, ordering)
}

const toggleObjectOrdering = (obj) => {
    const field = key(obj)
    const prevDirection = value(obj)
    const nextDirection = prevDirection === ASCENDING ? DESCENDING : ASCENDING
    return {[field]: nextDirection}
}
export const _toggleOrdering = curry((field, ordering) => {
    return adjust(fieldIndex(field, ordering), toggleObjectOrdering, ordering)
})

export const _stackOrdering = (field, ordering) => {
    const isAlreadyFirst = pipe(head, has(field))
    const isNotInList = pipe(filter(has(field)), isEmpty)
    const isInList = complement(isNotInList)

    const toggleDirectionAndMoveToFirst = pipe(
        _toggleOrdering(field),
        callBoth(fieldIndex(field), move(__, 0)),
    )

    const newOrdering = cond([
        [isEmpty, (_) => [{[field]: ASCENDING}]],
        [isAlreadyFirst, adjust(0, toggleObjectOrdering)],
        [isNotInList, prepend({[field]: ASCENDING})],
        [isInList, toggleDirectionAndMoveToFirst],
    ])
    return newOrdering(ordering)
}

export const useOrdering = (items) => {
    const [ordering, setOrdering] = useState([])
    const orderedItems = useMemo(() => sortItems(ordering, items), [ordering, items])

    const stackOrdering = (field) => {
        setOrdering(_stackOrdering(field, ordering))
    }

    const toggleOrdering = (field) => {
        setOrdering(_toggleOrdering(field, ordering))
    }

    const removeOrdering = (field) => {
        setOrdering(_removeOrdering(field, ordering))
    }

    const getFieldIndex = (field) => fieldIndex(field, ordering)

    const fieldDirection = (field) => {
        const fieldObj = ordering[getFieldIndex(field)]
        return value(fieldObj)
    }

    const shouldShowPrecedence = (field) => {
        const fieldIndex = getFieldIndex(field)
        const orderingSet = fieldIndex !== -1
        return ordering.length > 1 && orderingSet
    }

    return [
        orderedItems,
        {
            stackOrdering,
            shouldShowPrecedence,
            toggleOrdering,
            removeOrdering,
            getFieldIndex,
            fieldDirection,
        },
    ]
}
