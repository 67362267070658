import styled from 'styled-components'
import {DARK_GREY, LIGHT_GREY, WHITE, BLACK_GREY, MID_GREY} from './_colors'
import React from "react";

export const SidebarTabs = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-right: 14px;
    padding-left: 16px;
    padding-bottom: 6px;

    h3 {
        margin-top: 20px;
    }

    .nav-tabs {
        > li {
            a {
                text-align: center;
                font-size: 14px;
                text-transform: uppercase;
                color: ${DARK_GREY};
                border: 0;

                &:hover {
                    background-color: transparent;
                    border: 0;
                }

                &:focus {
                    outline: none;
                }
            }

            &.active {
                background-color: ${WHITE};

                a {
                    margin-right: 0;
                    border: 0;
                    border-bottom: 3px solid ${DARK_GREY};
                    font-weight: bold;
                }
            }
        }
    }
`

export const SidebarHeader = styled(Header)`
    background-size: 100% 6px;

    .nav-tabs {
        border: 0;

        > li {
            width: ${({tabs}) => (tabs ? `${100 / tabs}%` : '100%')};
        }
    }
`
export const SidebarBody = styled.div`
     height: ${props => props.footerSize ? 'calc(100% - '+props.footerSize+'px' :  '100%'}
`

export const SidebarContent = styled.div`
    overflow-y: auto;
    height: 100%;
    margin-bottom: auto;
`

export const _SideBarContent_ = ({children}) =>
    <div style={{height: '100%', overflowY: 'auto'}}>
        {children}
    </div>

export const SidebarFooterHeight = 40;
export const SidebarFooter = styled.div`
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    position: absolute;

    button {
        width: ${({items}) => `${100 / items}%`};
        height: 40px;
        float: left;
        margin: 0;

        &:first-child {
            border-right: 1px solid ${LIGHT_GREY};
        }
    }
`

export const SideBarTitle = styled.h4`
    margin-top: 30px;
    margin-bottom: 15px !important;
    padding-bottom: 5px;
    letter-spacing: 1px;
    font-size: 17px;
    text-transform: uppercase;
    color: ${MID_GREY};
    font-weight: 400;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .first {
            margin-top: 35px !important;
        }
    }
`

export const SideBarParagraph = styled.p`
    margin: 16px 10px 0 0;
    font-size: 13px;
    color: ${BLACK_GREY};
`

const NAVBAR_HEIGHT = 60;

export const SideBar = styled.div`
    height: calc(100vh - ${NAVBAR_HEIGHT}px);
    position: fixed;
    top: 0;
    margin-top: 60px;
    right: 0;
    background-color: ${WHITE};
    overflow: hidden;
    z-index: 1000;

    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-left: 1px solid ${LIGHT_GREY};

    width: 320px;

    .content {
        height: calc(100vh - 60px);
    }
    .tab-content {
        padding-bottom: 20px;
    }
`