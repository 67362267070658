import PropTypes from 'prop-types'
import {remove} from 'ramda'
import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import 'react-rangeslider/lib/index.css'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {useAnalysisSlice} from '../../analysis/hooks/useAnalysisSlice'
import {BarLoading} from '../../core/components/Loading'
import {PAGES, DASHBOARD_OPTIONS} from '../../core/const'
import {coreSlice, iconVisible} from '../../core/redux/slices'
import {updateValue} from '../../core/utils/object'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {strategyMapSlice} from '../../strategyMap/redux/slices'
import {getRemainingCaptureDays} from '../../strategyMap/utils'
import {dashboardSlice} from '../redux/slices'
import {filterDecisions} from './DecisionFilter/Dashboard.functions'
import {DashboardGroup, Title} from './Dashboard.styles'
import StoryCounter from './StoryCounter'
import DashboardChartSection from './DashboardChartSection'
import DaysCounter from './DaysCounter'
import ExperimentChartSection from './ExperimentChartSection'
import DashboardFiltersContainer from './DashboardFilters/DashboardFiltersContainer'
import {Header} from './Header'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import {buildEmptyAppliedFilters} from "./DecisionFilter/utils";
import { Container } from '../../core/ui/_global';
import DashboardMessageCentered from './DashboardMessageCentered'
import {useCaptureSlice} from "../../capture/hooks/useCaptureSlice";
import MiroImportDialog from '../../integrations/components/MiroImportDialog'
import DashboardStrategyMap from './DashboardStrategyMap'
import PSFOverview from './PSFOverview'
import { DashboardArchetypes } from '../../analysis/leadershipAssessment/ArchetypesOverview'
import { useHistory } from '../../core/hooks/useHistory'
import DashboardTitle from "./DashboardTitle";
import { WidgetLibrary, getIconImage } from './CustomizeChartsDashboard'
import { DashboardWidgets } from './CustomizeChartsDashboardConst'
import {isFeatureEnabled} from "../../core/components/FeaturePermission";
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice'

export const SPECIAL_CHART_LABELS = ['often', 'rarely', 'allthetime', 'nowandthen', 'notsure', 'decisions', 'headcount'];

export const DURATION_OPTIONS = {
    NOW: 0,
    ONE_MONTH: 28,
    TWO_MONTHS: 56,
    THREE_MONTHS: 84,
    ALL: 99999,
}

export const getDurationOptionsLabels = (days) => {
    const durationKey = days === DURATION_OPTIONS.ALL ? 'All' : days / 7 + 'weeks'
    return 'dashboard.Duration.' + durationKey
}

export const getExperimentCompareLabels = (days) => {
    const weeks = days / 7
    const durations = [0, 2, 3, 4, 8, 12]
    return 'dashboard.Compare.' + (durations.includes(weeks) ? weeks : 0) + 'weeksago'
}

export const getChartsLegend = (key, time) => {
    if (SPECIAL_CHART_LABELS.includes(key)) {
        return `dashboard.Duration.${key}`
    }
    let postfix = ''
    if (time !== undefined && time === 'confirmed') {
        postfix = 'before'
    }
    const durationKey = key === DURATION_OPTIONS.ALL ? 'All' : key / 7 + 'weeks'
    return `dashboard.Duration.${durationKey}${postfix}`
}

const compareExperimentsToOrder = (a, b) => {
    const expA = a.order
    const expB = b.order

    let comparison = 0
    if (expA > expB) {
        comparison = 1
    } else if (expA < expB) {
        comparison = -1
    }
    return comparison
}

const NoDecisionsPage = ({client, allowCaptureAccess, gotoPage, isDashboardFull}) => {

    return <div className={'dashboard-body'}>
        <Title>{I18n.t('dashboard.Dashboard')}</Title>
        {isDashboardFull && <StoryCounter
                filteredCount={0}
                count={client.max_decision_count}
        />}
        {!isDashboardFull && <DaysCounter/>}
        <DashboardMessageCentered
            message={I18n.t('dashboard.messages.noDecisionsWereDocumentedYet')}
            allowCaptureAccess={allowCaptureAccess}
            gotoPage={() => gotoPage(PAGES.OrgScan.Capture)}
        />
    </div>
};

const GraphsCards = ({allowStrategyMapAccess, state, isDashboardLite, dataObject, appliedFilters}) => {

    return <div className={'dashboard-body row'}>
        { allowStrategyMapAccess && <ExperimentChartSection /> }
        { isDashboardLite && <DaysCounter /> }
        <DashboardGroup>
            <DashboardChartSection
                isDashboardLite={isDashboardLite}
                isCompared={state.isCompared}
                dataObject={dataObject}
                appliedFilters={appliedFilters}
            />
        </DashboardGroup>
    </div>
};

const SectionTitle = ({section}) => {
    return(
        <h3>
            <img style={{marginRight: "8px", width: "24px"}} className="material-icons" src={getIconImage(section)} />
            {I18n.t('app.dashboard.' + section)}
        </h3>
    )
}

export const DashboardModules = {'StrategyMap': 'strategymap', 'LeadershipAssessment': 'leadership.full', 'OrgScan': 'capture'};
const MainDashboard = (props) => {
    const _ = require("lodash");
    const {features, dashboardWidgetsVisible, dashboardWidgetsOrder, client, Actions, isFetching} = useCoreSlice();
    const user = useUserSlice();

    const checkIfThereAreWidgetsToShow = (section) => {
        const sectionWidgets = DashboardWidgets[_.invert(DashboardModules)[section]];
        if( ! sectionWidgets || ! isFeatureEnabled(features, section)) {
            return false;
        }
        return dashboardWidgetsVisible
                    .filter((dashboardWidget) => 
                        sectionWidgets.map(sw => sw.name).includes(dashboardWidget.name) ).length > 0;
    }

    const checkIfDashboardHasWidgets = () =>
        Object.values(DashboardModules).some( a => checkIfThereAreWidgetsToShow(a) );

    useEffect(() => {
        if(user.dashboard.length === 0 && user.pk &&
            client.custom_dashboard_widgets.length > 0 && 
            isFetching === false) {
            Actions.updateDashboard(client.custom_dashboard_widgets);
        }
    }, []);

    if(props.decisions.length === 0) {
        return (
            <DashboardMessageCentered
                message={I18n.t('dashboard.messages.noDecisionsWereDocumentedYet')}
                allowCaptureAccess={false}
            />
        )
    }

    return (
        <>
            <DashboardTitle />

            {
                dashboardWidgetsOrder.map( widgetSection => {
                    switch( widgetSection ) {
                        case "StrategyMap":
                            if( checkIfThereAreWidgetsToShow(DashboardModules.StrategyMap) )
                                return <div key={'sm'}>
                                    <SectionTitle section={'StrategyMap'}/>
                                    <DashboardStrategyMap/>
                                    <PSFOverview/>
                                </div>;
                            break;
                        case "OrgScan":
                            if( checkIfThereAreWidgetsToShow(DashboardModules.OrgScan) )
                                return <div key={'os'}>
                                    <SectionTitle section={'OrgScan'}/>
                                    <div className={'dashboard-body row'}>
                                        <ExperimentChartSection/>
                                        <DashboardGroup>
                                            <DashboardChartSection
                                                isDashboardLite={false}
                                                isCompared={false}
                                                dataObject={props.dataObject}
                                                appliedFilters={props.appliedFilters}
                                                section={'OrgScan'}
                                            />
                                        </DashboardGroup>
                                    </div>
                                </div>
                            break;
                        case "LeadershipAssessment":
                            if( checkIfThereAreWidgetsToShow(DashboardModules.LeadershipAssessment) )
                                return <div key={'la'}>
                                    <SectionTitle section={'LeadershipAssessment'}/>
                                    <DashboardArchetypes/>
                                    <div className={'dashboard-body row'}>
                                        <DashboardGroup>
                                            <DashboardChartSection
                                                isDashboardLite={false}
                                                isCompared={false}
                                                dataObject={props.dataObject}
                                                appliedFilters={props.appliedFilters}
                                                section={'LeadershipAssessment'}
                                            />
                                        </DashboardGroup>
                                    </div>
                                </div>
                            break;
                        default:
                            return <div key={'def'}></div>
                    } } )
            }

            { ! checkIfDashboardHasWidgets() &&
                <div className={'dashboard-body row'}>
                    <DashboardMessageCentered 
                        message={I18n.t('dashboard.messages.noWidgetsToShow')} />

                    <WidgetLibrary showAsTiles={true} />
                </div>
            }
        </>
    )
}

export const Dashboard = (props) => {
    const {isSubscriptionActive, features, client, isFetching: isFetchingCore, Actions, miroParam, sidebarOpen} = useCoreSlice();
    const {Actions: CaptureActions} = useCaptureSlice();
    const {isOrgscanDashboardPage, isMainDashboardPage} = useHistory();
    const isDashboardFull = client.dashboard_option === DASHBOARD_OPTIONS.full;

    const days = getRemainingCaptureDays(client);
    const allowStrategyMapAccess = features.strategymap;
    const allowCaptureAccess = features.capture && days > 0;
    const {goal, Actions: StrategyMapActions} = useStrategyMapSlice();
    const analysisSlice = useAnalysisSlice();

    const [openMiroImport, setOpenMiroImport] = useState(false);
    if( window.location.search
        && ( ! miroParam || miroParam && ! miroParam.code ) ) {
            Actions.setMiroParam(
                window.location.search
                    .substring(1)
                    .split("&")
                    .reduce((acc, cur) => {
                        acc[cur.split("=")[0]] = cur.split("=")[1];
                        return acc;
                    }, {}));
            setOpenMiroImport( true );
    }

    const defaultDataObject = {
        dataShown: [],
        dataCompare: []
    };
    const [state, setState] = useState({
        experiments: [],
        isCompared: false
    });

    const [appliedFilters, setAppliedFilters] = useState( buildEmptyAppliedFilters(props.demographics, DURATION_OPTIONS.ALL) );
    const [dataObject, setDataObject] = useState(defaultDataObject);

    const applyFilters = (newFilters) => {
        Object.keys(newFilters).forEach( filter => {
            if( filter !== 'duration' )
                newFilters[filter] = props.filters[filter] || [];
        });
        setAppliedFilters(newFilters);
        setDataObject(
            props.decisions.length === 0
            ? defaultDataObject
            : filterDecisions( props.decisions, newFilters ) );
    }

    const removeFilterUsingLabel = (filterType, index, allSelected) => {
        const newAppliedFilters = updateValue(
            filterType,
            allSelected ? (_) => [] : remove(index, 1),
            appliedFilters
        )
        if (filterType === 'experiment' && newAppliedFilters.duration === 0) {
            newAppliedFilters.duration = DURATION_OPTIONS.ONE_MONTH
        }
        applyFilters(newAppliedFilters)
    }

    const resetFilters = () => {
        const emptyAppliedFilter = buildEmptyAppliedFilters(props.demographics, DURATION_OPTIONS.ALL);
        emptyAppliedFilter.duration = appliedFilters.duration;
        applyFilters(emptyAppliedFilter);
    }

    const isFetching = () =>
        props.isFetching ||
        isFetchingCore ||
        props.isFetchingActivities ||
        props.isFetchingGoals ||
        props.isFetchingNode ||
        props.isFetchingSfs ||
        props.isFetchingUsers;
    
    useEffect(() => {
        props.setNotificationsIconVisible(true);
        props.setUserProfileIconVisible(true);
        props.setHelpIconVisible(false);

        if( props.decisions.length === 0 )
            props.fetchDecisions();

        props.fetchGoals();
        props.logPageView({dashboard_visits: 1});

        analysisSlice.Actions.fetchClientStatistics();

        return () => {
            props.clearFilters();
        }
    }, [])

    useEffect(() => {
        if (goal.id && isDashboardFull) {
            props.fetchAllExperiments( goal.id );
        }
    }, [goal]);

    useEffect(() => {
        setAppliedFilters(buildEmptyAppliedFilters(props.demographics, DURATION_OPTIONS.ALL))
    }, [props.demographics])

    useEffect(() => {
        if (props.decisions) {
            const newAppliedFilters = {
                ...appliedFilters,
                duration: props.filters.experiment && props.filters.experiment.length > 0 ? DURATION_OPTIONS.NOW : DURATION_OPTIONS.ALL,
            }
            applyFilters(newAppliedFilters)
        }
    }, [props.decisions, props.filters])

    useEffect(() => {
        if (props.experiments) {
            setState({
                ...state,
                experiments: props.experiments.sort(compareExperimentsToOrder),
            })
        }
    }, [props.experiments])

    const showFilters = isDashboardFull && isSubscriptionActive && !isFetching() && props.decisions.length > 0;
    const showHeaderAndFilters = isOrgscanDashboardPage;

    const styles = {}

    if (sidebarOpen && window.innerWidth < 1800) {
        styles.marginRight = '320px';
        styles.maxWidth = '1170px';
    }

    const fixOverlapingSidebarOnContainer = () => {
        if (sidebarOpen && window.innerWidth < 1800) {
            return 'container-fluid';
        }
        return 'container';
    }

    return (
            <Container className={fixOverlapingSidebarOnContainer()} style={styles} data-testid={'dashboard-container'}>
                {
                    openMiroImport
                        ? <MiroImportDialog
                            start={ openMiroImport }
                            handleModalDisplay={setOpenMiroImport}
                            handleDecisionsImport={ CaptureActions.importDecisionsFromMiro }
                            handleSMImport={ StrategyMapActions.importStrategyMapFromMiro }
                            param={miroParam}/>
                        : undefined
                }
                {showHeaderAndFilters && <div className="dashboard-header">

                    <Header />

                    { isFetching() && <BarLoading /> }

                    { showFilters && (
                        <DashboardFiltersContainer
                            loading={false}
                            allowStrategyMapAccess={allowStrategyMapAccess}
                            decisions={props.decisions}
                            decisionsCount={props.decisionsCount}
                            demographics={props.demographics}
                            isCompared={state.isCompared}
                            appliedFilters={appliedFilters}
                            handleFiltersChange={applyFilters}
                            removeAppliedFilterCallback={removeFilterUsingLabel}
                            filteredDecisionCount={dataObject.dataShown.length}
                            resetFiltersCallback={resetFilters}
                            clickCompareCallback={() => {
                                setState({...state, isCompared: !state.isCompared})
                            }}
                        />
                    )}
                </div>}

                {isMainDashboardPage && isSubscriptionActive && <MainDashboard {...props} dataObject={dataObject} appliedFilters={appliedFilters} />}
                
                { ! isSubscriptionActive && isSubscriptionActive !== undefined && (
                    <div className={'dashboard-body row'}>
                        <DashboardMessageCentered 
                            message={I18n.t('dashboard.messages.subscriptionExpired')} />
                    </div>
                )}
                { ! isFetching() && isSubscriptionActive && !isMainDashboardPage && 
                    <>{
                        props.decisions.length === 0
                            ?
                                <NoDecisionsPage
                                    client={client}
                                    gotoPage={props.gotoPage}
                                    allowCaptureAccess={allowCaptureAccess}
                                    isDashboardFull={isDashboardFull}
                                />
                            :
                                <GraphsCards
                                    allowStrategyMapAccess={allowStrategyMapAccess}
                                    state={state}
                                    isDashboardLite={!isDashboardFull}
                                    decisions={props.decisions}
                                    dataObject={dataObject}
                                    appliedFilters={appliedFilters}
                                />
                    }</>
                }
            </Container>
    )


}

Dashboard.propTypes = {
    demographics: PropTypes.object
}

const mapStateToProps = ({strategyMap, language, dashboard, demographics}) => {
    return {
        ...strategyMap,
        ...dashboard,
        demographics,
        currentLanguage: language.current
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setNotificationsIconVisible: iconVisible.actions.setNotificationsIconVisible,
            setUserProfileIconVisible: iconVisible.actions.setUserProfileIconVisible,
            setHelpIconVisible: iconVisible.actions.setHelpIconVisible,
            ...strategyMapSlice.actions,
            ...coreSlice.actions,
            ...dashboardSlice.actions,
        },
        dispatch
    )
};

const ConnectedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default ConnectedDashboard;
