import React from 'react'
import styled, {css} from 'styled-components'
import {I18n} from 'react-i18nify'
import { BLUE } from './_colors'

const Shift = css`
    position: relative;
    bottom: 50px;
    right: 2px;
`

const Outer = styled.div`
    ${(props) => props.otherUserIsEditing && Shift}
`

const OtherUserIsEditingContainer = styled.div`
    background-color: ${BLUE};
    color: white;
    font-size: 12px;
    border-color: ${BLUE};
    border-style: solid;
    border-width: thick;
    float: right;
    width: 60%;
    margin-top: 20px;
`

const LockIcon = styled.span`
    font-size: 12px;
`

const OtherUserIsEditing = (props) => {
    const user = props.user && (props.user.full_name || props.user.email)
    const user_editing = user && (user.length > 12 ? user.slice(0, 12).concat('...') : user)
    return (
        <Outer otherUserIsEditing={props.otherUserIsEditing}>
            {!!props.user && props.otherUserIsEditing && (
                <OtherUserIsEditingContainer>
                    <LockIcon className="material-icons-outlined">lock</LockIcon>
                    {'   ' + user_editing + ' ' + I18n.t('strategyMap.messages.isEditing')}
                </OtherUserIsEditingContainer>
            )}
            <div
                style={{
                    float: 'left',
                    position: 'relative',
                    width: '100%',
                    marginTop: props.richtexteditor ? 16 : 0,
                }}
            >
                {props.children}
            </div>
        </Outer>
    )
}

export default OtherUserIsEditing
