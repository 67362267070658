import React, {useEffect} from 'react';
import styled from "styled-components";
import {SearchContainer} from "../Filter/Filters.styles";
import {I18n} from "react-i18nify";
import {useDashboardSlice} from "../../dashboard/hooks/useDashboardSlice";

const SearchContainerAnalysis = styled(SearchContainer)`
    background-color: #ffffff;
    margin-bottom: 20px;
`;
const InspectSearch = () => {

    const {Actions, searchByWords} = useDashboardSlice();
    const InputRef = React.createRef();

    let timeoutID;

    useEffect( () => {
        if( searchByWords.length === 0 )
            InputRef.current.value = searchByWords;
    }, [searchByWords])

    const setSearchKeyWords = word => {
        if( timeoutID )
            clearTimeout( timeoutID );
        timeoutID = setTimeout( () => {
            Actions.setSearchWord( word );
        }, 2000 );
    }
    const onKeyStroke = e => {
        if( e.key === "Enter" ){
            if( timeoutID )
                clearTimeout( timeoutID );
            Actions.setSearchWord( InputRef.current.value );
        }
    }

    return <SearchContainerAnalysis>
        <span className="material-icons-outlined">search</span>
        <input
            ref={InputRef}
            style={{width: 'calc(100% - 50px'}}
            className="search-input"
            data-testid={"search-input-filter"}
            placeholder={I18n.t('dashboard.search')}
            onChange={ e => setSearchKeyWords( e.target.value ) }
            onKeyUp={ e => onKeyStroke(e) }
        />
    </SearchContainerAnalysis>
}

export default InspectSearch;