import {useState} from 'react'

export const useToggle = () => {
    const [on, setOn] = useState(false)

    const toggle = (v) => {
        if (!v) {
            setOn(!on)
        } else {
            setOn(v)
        }
    }
    return [on, toggle]
}
