export const DEFAULT_CAPTURE_QUESTION_KEY = '1db9d01a-693b-411b-bd3d-db173cd53d62';
export const LEADERSHIP_CAPTURE_MOCK_KEY = 'd91ffddb-3a8d-4f1c-aa51-725ff818cfa7';
export const LEADERSHIP_CAPTURE_SURVEY = 'de455352-5224-4400-bbe8-2f04069b94b3';

export const QUESTION_TYPES = {
    leadershipAssessment: 'leadership_assesment',
    decision: 'decision',
}

export const DECISION_CHOICES = [
    {
        fieldName: 'decision_type',
        className: '',
        isVertical: false,
        header: 'The decision was',
        choices: [
            {
                value: 'tactical',
                label: 'Tactical/ Operational',
            },
            {
                value: 'strategic',
                label: 'Strategic',
            },
            {
                value: 'notsure',
                label: 'Not Sure',
            },
        ],
    },
    {
        fieldName: 'decision_period',
        className: '',
        isVertical: false,
        header: 'The decision was made',
        choices: [
            {
                value: 'rightaway',
                label: 'Right away',
            },
            {
                value: 'hour',
                label: 'Within the hour',
            },
            {
                value: 'day',
                label: 'Within the day',
            },
            {
                value: 'week',
                label: 'Within the week',
            },
            {
                value: 'month',
                label: 'Within the month',
            },
            {
                value: 'forever',
                label: 'It seemed to take forever',
            },
            {
                value: 'notsure',
                label: 'I don´t know',
            },
        ],
    },
    {
        fieldName: 'communication_method',
        className: 'listed',
        isVertical: true,
        header: 'The decision was communicated through',
        choices: [
            {
                value: 'oneonone',
                label: 'A formal one on one conversation (including via telephone)',
            },
            {
                value: 'group',
                label: 'A formal group conversation(including via telephone)',
            },
            {
                value: 'tool',
                label: 'A tool (eg email or other communication tool, but not in real-time)',
            },
            {
                value: 'announcement',
                label: 'A formal announcement or presentation to a larger group',
            },
            {
                value: 'noformalconversation',
                label: 'There was no formal communication (eg the grapevine, at the water cooler, or coffee corner)',
            },
            {
                value: 'nocommunication',
                label: 'It wasn´t communicated',
            },
        ],
    },
    {
        fieldName: 'decision_owner',
        className: '',
        isVertical: false,
        header: 'The decision was made by',
        choices: [
            {
                value: 'me',
                label: 'Me',
            },
            {
                value: 'colleague',
                label: 'Colleague',
            },
            {
                value: 'team',
                label: 'Team',
            },
            {
                value: 'manager',
                label: 'Manager/ Leader',
            },
            {
                value: 'external',
                label: 'External Advisor/ Expert',
            },
            {
                value: 'groupofpeople',
                label: 'Group of people',
            },
            {
                value: 'notsure',
                label: 'Not Sure',
            },
        ],
    },
    {
        fieldName: 'decision_mood',
        className: '',
        isVertical: false,
        header: 'The decision made me feel',
        choices: [
            {
                value: 'positive',
                label: 'Positive',
            },
            {
                value: 'neutral',
                label: 'Neutral',
            },
            {
                value: 'negative',
                label: 'Negative',
            },
            {
                value: 'notsure',
                label: 'Not Sure',
            },
        ],
    },
    {
        fieldName: 'decision_frequency',
        className: '',
        isVertical: false,
        header: 'These kind of decisions are made',
        choices: [
            {
                value: 'allthetime',
                label: 'All the time',
            },
            {
                value: 'often',
                label: 'Often',
            },
            {
                value: 'nowandthen',
                label: 'Now and then',
            },
            {
                value: 'rarely',
                label: 'Rarely',
            },
            {
                value: 'notsure',
                label: 'Not Sure',
            },
        ],
    },
]

export const LABELS_TO_CHANGE = {
    "leadership.Triangle.Topic.My expectations of the team were to": "leadership.Triangle.Topic.Our leaders expectation of the team were",
    "leadership.Triangle.Topic.My responsibility as leader was to": "leadership.Triangle.Topic.Their responsibility as leaders was to",
    "leadership.Triangle.Topic.People chose what to do based on": "leadership.Triangle.Topic.I chose what to do based on",
    "leadership.Triangle.Topic.To succeed I knew I needed to": "leadership.Triangle.Topic.To succeed our leaders knew they needed to",
    "leadership.Triangle.Topic.To motivate my people to perform, I": "leadership.Triangle.Topic.To motivate me to perform our leaders",
    "leadership.Triangle.Topic.People felt comfortable because": "leadership.Triangle.Topic.I felt comfortable because",
    "leadership.Triangle.Corner.meet my standards": "leadership.Triangle.Corner.meet their standards",
    "leadership.Triangle.Corner.be willing to be supported by me": "leadership.Triangle.Corner.be willing to be supported by them",
    "leadership.Triangle.Corner.inspire the team to challenge their boundaries": "leadership.Triangle.Corner.inspire the team to change our boundaries",
    "leadership.Triangle.Corner.targets assigned to individuals": "leadership.Triangle.Corner.targets assigned to me",
    "leadership.Triangle.Corner.I was taking responsibility": "leadership.Triangle.Corner.the leader was taking responsibility",
    "leadership.Triangle.Corner.they believed they could handle it": "leadership.Triangle.Corner.I believed that the leader could handle it"
}