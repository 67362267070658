import React from 'react';
import styled from "styled-components";

import {Card as ReactCard, Container, FormGroup, Popover, FormCheck} from 'react-bootstrap';
import {GREY, LIGHT_GREY, WHITE} from "../ui/_colors";
import { ButtonTertiary } from './Buttons';

export const MainContainer = styled(Container)`
    padding: 0;
`
const _Panel = styled(ReactCard)`
    clear: both;
    padding: 15px 15px 15px 20px;
    border-radius: 0px;
    margin-bottom: 40px;
    box-shadow: rgb(0 0 0 / 30%) 0px 3px 10px;
    background-color: ${WHITE};
    page-break-before: always;
`
const _PanelHeader = styled.div`
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 700;
    background: none transparent;
    margin-bottom: 10px;
    @media only screen and (max-width: 600px) {
        font-size: 18px;
        font-weight: 500;
    }
`
const _PanelActions = styled.div`
    width: fit-content;
    float: right;
    button {
        margin-top: -8px;
    }
    i {
        vertical-align: middle;
    }
    @media print {
        display: none;
    }
    @media only screen and (max-width: 600px) {
        font-size: 20px;
    }
`

const PannelActions = ({actions}) => (
    <_PanelActions>
        {actions.map((action, key) => <ButtonTertiary key={key} onClick={()=> action.onClick()}>
            <i className="material-icons-outlined">{action.icon}</i> {action.title}
        </ButtonTertiary>)}
    </_PanelActions>
)

export const Panel = (props) =>
    <_Panel className={props.className}>
        {(props.header || props.actions) && <_PanelHeader>
                            {props.header || ""}
                            {props.actions && <PannelActions actions={props.actions} />}
                        </_PanelHeader>}
        {props.children}
    </_Panel>;

export const PanelGroup = styled.div`
    padding: 15px;

    @media (min-width: 992px) {
        padding: 0;
    }
`

export const Nav = styled.div`
    float: left;
    height: 50px;
`
export const NavItem = styled.div`
    z-index: 5000;
    float: left;
    min-width: 60px;
    height: 60px;
    border-left: ${props => props.logo ? "0" : "1px solid "+LIGHT_GREY};
    display: flex;
    cursor: pointer;
    :hover {
        background-color: ${props => props.noHover ? WHITE : LIGHT_GREY};
    }
`
export const NavItemIntern = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`
export const NavActions = styled.div`
    float: right;
`
export const NavbarBrand = styled.div`
    height: 60px;
    width: 60px;
    float: left;
    cursor: ${ props => props.onClick ? 'pointer' : 'cursor'};
`
export const A42FormGroup = styled(FormGroup)`
    margin-bottom: 15px;
    
    &.password {
        padding-top: 30px;
        p {
            margin-bottom: 20px;
        }
    }
    &.initial-password {
        padding-top: 30px;
        .form-group {
            margin-top: 20px;
        }
    }
    input {
        border: 1px solid ${GREY};
        margin-bottom: 15px;
    }
`;

export const A42Popover = styled(Popover)`
    z-index: 5000;
    opacity: 1;
    padding: 10px;
`;

export const FormCheckbox = styled(FormCheck)`
    input {
        height: auto!important;
        margin-right: 10px;
    }
`;