import React, { useEffect, useState } from 'react'
import {SwitchWithLabel} from '../../core/components/Switch'
import { DashboardLeaderShipSurvey } from './DashboardLeaderShipSurvey';
import { Header } from './Header';
import { Container } from '../../core/ui/_global';
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';
import { TeamSelector } from './TeamSelector';
import { useCaptureSlice } from '../../capture/hooks/useCaptureSlice';
import { BarLoading } from '../../core/components/Loading';
import DashboardMessageCentered from './DashboardMessageCentered';
import { I18n } from 'react-i18nify';
import {RESTRICTIONS} from "../../core/const";

export const DashboardLeadershipSurveyWrapper = () => {
    const {Actions, leaderShipAssessmentSurvey, isFetching} = useCaptureSlice();
    const user = useUserSlice()
    const [splitView, setSplitView] = useState(false);
    const [selectedTeam, setSelectedTeam] =
        useState( localStorage.getItem('leadershipAssessmentSelectedTeam')
            && ! RESTRICTIONS.Leadership.RULES.CAN_SEE_ALL_TEAMS.some(role => user[role] )
                ? parseInt(localStorage.getItem('leadershipAssessmentSelectedTeam'))
                : "");

    const areCapturedDataStored = !! leaderShipAssessmentSurvey.length;

    useEffect(() => {
        Actions.getCaptures();
    }, [])
    
    return (
        <>
            
            <Container className="container">
                { !isFetching && (
                    <div className="dashboard-header">
                        <Header />
                    </div>
                )}

                {isFetching && <BarLoading />}

                { !isFetching && !areCapturedDataStored && (
                    <div className={'dashboard-body row'}>
                        <DashboardMessageCentered 
                            message={I18n.t('dashboard.messages.noDecisionsWereDocumentedYet')} />
                    </div>
                )}

                { !isFetching && areCapturedDataStored && (
                    <>
                        <div className='row' style={{padding: "0 15px"}}>
                            <div className='pull-left' style={{ width: "20%" }}>
                                <SwitchWithLabel label="Split view" defaultValue={splitView} onChange={() => setSplitView(!splitView)} />
                            </div>
            
                            <div className='pull-right' style={{ width: "50%" }}>
                                <TeamSelector onChange={ t => setSelectedTeam( t.value ) } />
                            </div>
                        </div>
            
                        { ! splitView && <DashboardLeaderShipSurvey key="everything" selectedTeam={selectedTeam} display="everything" /> }
                        { splitView && <>
                            <DashboardLeaderShipSurvey key="split-all" selectedTeam={selectedTeam} display="split-all" />
                            <DashboardLeaderShipSurvey key="split-details" selectedTeam={selectedTeam} display="split-details" />
                        </>}
                    </>
                )}
            </Container>
        </>
    )
}