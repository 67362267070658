import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {ButtonPrimary, ButtonSecondary} from './Buttons'
import {DARK_GREY, GREY, LIGHT_GREY, BLACK_GREY} from '../ui/_colors'
import Avatar from './Avatar';

export const ALIGN_DROP_DOWN = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
};

const Icon = styled.span`
    color: ${DARK_GREY};
`
const IconWrapper = styled.div`
    width: 100%;
    height: inherit;
    cursor: pointer;
    background-color: ${(props) => (props.pressed ? 'lightgray' : 'transparent')};
    position: relative;
    justify-content: center;
    display: grid;
    align-items: center;
    :hover {
        background-color: ${LIGHT_GREY};
    }
`
const OptionContainer = styled.div`
    position: absolute;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px 0px;
    display: block;
    left: auto;
    top: 30px;
    list-style: none;
    margin: 0px;
    min-width: 160px;
    padding: 0px 0px;
    z-index: 99;
    margin-top: 30px;
`
const Option = styled.div`
    color: rgb(38, 38, 38);
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 3px 20px;
    white-space: nowrap;
    :hover {
        background-color: ${LIGHT_GREY};
    }
`
const OptionIconText = styled(Option)`
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    height: 56px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) => (props.enabled ? BLACK_GREY : GREY)};
`
const OptionHeader = styled(Option)`
    padding-top: 24px;
    padding-bottom: 12px;
    color: ${GREY};
    text-align: left;
    :hover { background-color: transparent};
    font-size: 16px;
    font-weight: bold;
`
const _ButtonPrimary = styled(ButtonPrimary)`
    margin: 0;
    min-width: 160px;
`
const _ButtonSecondary = styled(ButtonSecondary)`
    margin: 0;
    min-width: 160px;
`

export const DropDownMenuOptionType = {
    PrimaryButton: 'PrimaryButton',
    SecondaryButton: 'SecondaryButton',
    Text: 'Text',
    IconAndText: 'IconAndText',
    AvatarAndText: 'AvatarAndText',
    Header: 'Header'
}

const DropDownMenu = ({materialIconName, options, onChange, centerInParent, alignDropDown, datatestid}) => {
    const [openMenu, setOpenMenu] = useState(false)
    let target = ''

    const openClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        target = e.target
        setOpenMenu(!openMenu)
    }

    const optionClick = (e, key) => {
        e.preventDefault()
        e.stopPropagation()
        target = e.target
        setOpenMenu(false)
        onChange(key)
    }

    const handleClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (openMenu && !(e.target === target)) setOpenMenu(false)
    }

    useEffect(() => {
        window.addEventListener( 'click', handleClick, false )
        return () => { 
            window.removeEventListener( 'click', handleClick, false )
        }
    }, [])

    const style = centerInParent
        ? {
            width: '100%',
            height: 'inherit',
            marginTop: 'auto',
            marginBottom: 'auto',
            fontSize: '24px'
        }
        : { position: 'relative' }

    return (
        <div style={style} onClick={openClick} data-testid={datatestid}>
            <IconWrapper className={'wrapper'}>
                <Icon className="material-icons" pressed={openMenu} >
                    { materialIconName }
                </Icon>
            </IconWrapper>
            {
                openMenu && (
                <OptionContainer centerInParent={centerInParent} alignDropDown={alignDropDown}>
                    {options.map((option) => {
                        switch (option.type) {
                            case DropDownMenuOptionType.PrimaryButton:
                                return (
                                    <_ButtonPrimary
                                        key={option.key}
                                        onClick={(e) => optionClick(e, option.key)}
                                    >
                                        {option.title}
                                    </_ButtonPrimary>
                                )
                            case DropDownMenuOptionType.SecondaryButton:
                                return (
                                    <_ButtonSecondary
                                        key={option.key}
                                        onClick={(e) => optionClick(e, option.key)}
                                    >
                                        {option.title}
                                    </_ButtonSecondary>
                                )
                            case DropDownMenuOptionType.IconAndText:
                                return (
                                    <OptionIconText
                                        key={option.key}
                                        data-testid={option.datatestid}
                                        onClick={(e) => optionClick(e, option.key)}
                                        enabled={option.permissions}
                                    >
                                        { option.icon && <Icon style={{paddingRight: "16px"}} className="material-icons">{ option.icon }</Icon> }
                                        { option.iconImage && <img style={{paddingRight: "16px", width: "36px"}} className="material-icons" src={option.iconImage} /> }
                                        <span>{option.title}</span>
                                    </OptionIconText>
                                )
                            case DropDownMenuOptionType.AvatarAndText:
                                return (
                                    <OptionIconText
                                        key={option.key}
                                        data-testid={option.datatestid}
                                        onClick={(e) => optionClick(e, option.key)}
                                        enabled={option.permissions}
                                    >
                                        <Avatar 
                                            colorCode={option.user && option.user.color_code}
                                            text={option.user && option.user.initials}
                                            profilePicture={option.user && option.user.profile_picture}
                                        />
                                        <span>{option.title}</span>
                                    </OptionIconText>
                                )
                            case DropDownMenuOptionType.Header:
                                return (
                                    <OptionHeader key={option.key}>
                                        <span>{option.title}</span>
                                    </OptionHeader>
                                )
                            default:
                                return (
                                    <Option
                                        key={option.key}
                                        onClick={(e) => optionClick(e, option.key)}
                                    >
                                        {option.title}
                                    </Option>
                                )
                        }
                    })}
                </OptionContainer>
            )}
        </div>
    )
}

export default DropDownMenu
