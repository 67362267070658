import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import {isMobile} from 'react-device-detect'
import {BottomLabel, BSLeft, BSRight, DraggableItem, Triangle, TriangleImage,} from './Triangle.styles'
import yellowBackgroundIcon from "../../../img/Capture/bg-triangle-yellow.png"
import redBackgroundIcon from "../../../img/Capture/bg-triangle-red.png"
import greyBackgroundIcon from "../../../img/Capture/bg-triangle-grey.png"
import blueBackgroundIcon from "../../../img/Capture/bg-triangle-blue.png"
import greenBackgroundIcon from "../../../img/Capture/bg-triangle-green.png"
import orangeBackgroundIcon from "../../../img/Capture/bg-triangle-orange.png"
import yellowPointIcon from "../../../img/Capture/ic-point-yellow.png"
import greyPointIcon from "../../../img/Capture/ic-point-grey.png"
import bluePointIcon from "../../../img/Capture/ic-point-blue.png"
import greenPointIcon from "../../../img/Capture/ic-point-green.png"
import orangePointIcon from "../../../img/Capture/ic-point-orange.png"
import redPointIcon from "../../../img/Capture/ic-point-red.png"
import Triad from '../../../Charts/calculationsUtils/triad'
import Point from '../../../Charts/calculationsUtils/point'

const backgroundIcons = {
    yellow: yellowBackgroundIcon,
    red: redBackgroundIcon,
    grey: greyBackgroundIcon,
    blue: blueBackgroundIcon,
    green: greenBackgroundIcon,
    orange: orangeBackgroundIcon
}

export const pointIcons = {
    yellow: yellowPointIcon,
    red: redPointIcon,
    grey: greyPointIcon,
    blue: bluePointIcon,
    green: greenPointIcon,
    orange: orangePointIcon
}

export const TRIANGLE_DIMENSION = {
    width: 221.6,
    height: 191.4,
    leftmostPoint: {x: -18, y: 174.4},
    rightmostPoint: {x: 203.6, y: 174.4},
    topmostPoint: {x: 92.8, y: -17},
}

export const BALL_DIMENSION = {
    // the center of the ball is not in the center of that image due to image having extra shadow
    topLengthToCenter: 17,
    leftLengthToCenter: 18,
    centerPosition: {
        x: TRIANGLE_DIMENSION.width / 2 - 18,
        y: (2 / 3) * TRIANGLE_DIMENSION.height - 17,
    },
}

export const round = (value) => {
    return Math.round(value * 100) / 100
}

const TriangleComponent = (props) => {
    const [nativeDraggableEventPosition, setNativeDraggableEventPosition] = useState(
        BALL_DIMENSION.centerPosition,
    ) // current coordinates of the ball in rectangle coordinates
    const [propertySpecificWeights, setPropertySpecificWeights] = useState({
        left: Triad.DEFAULT_WEIGHT,
        right: Triad.DEFAULT_WEIGHT,
        top: Triad.DEFAULT_WEIGHT,
    })
    const [bounds, setBounds] = useState({}) // free movement area for drag ball, it has to calculate on moving because its not a rectangle
    const [dragged, setDragged] = useState(false) // for highlighting ball on moving
    const [panning, setPanning] = useState(false) // necessary for mobile mouseDown (touch move)

    const [normalisedPositionState, setNormalisedPositionState] = useState({x:null,y:null})

    const convertBrowserClickEventPositionToDraggablePosition = (clickEvent) => {
        const boundingRectangle = clickEvent.target.getBoundingClientRect()
        const xPositionRelativeToBoundingRectangle = clickEvent.clientX - boundingRectangle.left
        const yPositionRelativeToBoundingRectangle = clickEvent.clientY - boundingRectangle.top

        const xPositionCorrectedForBallLength =
            xPositionRelativeToBoundingRectangle - BALL_DIMENSION.leftLengthToCenter
        const yPositionCorrectedForBallLength =
            yPositionRelativeToBoundingRectangle - BALL_DIMENSION.topLengthToCenter

        return {
            x: xPositionCorrectedForBallLength,
            y: yPositionCorrectedForBallLength,
        }
    }

    useEffect(() => {
        window.document.body.addEventListener('touchmove', handlePreventTouchmoveWhenPanning, {
            passive: false,
        })
        return () => {
            // window.removeEventListener('resize', checkDraggableItemPositionOnResize);
            window.document.body.removeEventListener(
                'touchmove',
                handlePreventTouchmoveWhenPanning,
                {
                    passive: false,
                },
            )
        }
    }, [])

    useEffect(() => {
        if (props.disabled === true) {
            setDragged(false)
        }
        if (props.shouldResetPosition || props.disabled) {
            setNativeDraggableEventPosition(BALL_DIMENSION.centerPosition)
        }
    }, [props])

    useEffect(() => {
        props.updatePoint(
            {
                left: Math.abs(propertySpecificWeights.left),
                right: Math.abs(propertySpecificWeights.right),
                top: Math.abs(propertySpecificWeights.top),
            },
            normalisedPositionState,
            false,
        )
    }, [normalisedPositionState, propertySpecificWeights])

    const normalisePosition = (position) => {
        return {
            // human readable rectangle coordinates and needed for R-Script
            x: round((position.x + BALL_DIMENSION.leftLengthToCenter) / TRIANGLE_DIMENSION.width),
            y: round(
                (TRIANGLE_DIMENSION.height - position.y - BALL_DIMENSION.topLengthToCenter) /
                    TRIANGLE_DIMENSION.height,
            ),
        }
    }

    const pointIsInsideTriangle = (position) => {
        // Barycentric coordinate system
        const [p, p0, p1, p2] = [
            position,
            TRIANGLE_DIMENSION.leftmostPoint,
            TRIANGLE_DIMENSION.topmostPoint,
            TRIANGLE_DIMENSION.rightmostPoint,
        ]

        const Area =
            0.5 * (-p1.y * p2.x + p0.y * (-p1.x + p2.x) + p0.x * (p1.y - p2.y) + p1.x * p2.y)

        const s =
            (1 / (2 * Area)) *
            (p0.y * p2.x - p0.x * p2.y + (p2.y - p0.y) * p.x + (p0.x - p2.x) * p.y)
        const t =
            (1 / (2 * Area)) *
            (p0.x * p1.y - p0.y * p1.x + (p0.y - p1.y) * p.x + (p1.x - p0.x) * p.y)

        return s > 0 && t > 0 && 1 - s - t > 0
    }

    const calculateBoundsAndWeights = (position) => {
        // triangle corner coords with origin on bottom left
        // scalar values based on coordinates of Draggable child component

        const currentHalfBottomLength =
            ((position.y + BALL_DIMENSION.topLengthToCenter) *
                (TRIANGLE_DIMENSION.width / TRIANGLE_DIMENSION.height)) /
            2

        const normalisedPosition = {
            // is position with an offset and human readable coordinates
            x: position.x + BALL_DIMENSION.leftLengthToCenter,
            y: TRIANGLE_DIMENSION.height - position.y - BALL_DIMENSION.topLengthToCenter,
        }

        const triad = new Triad();
        const point = new Point(normalisedPosition.x, normalisedPosition.y);
        const [topPropertyWeight, rightPropertyWeight, leftPropertyWeight] = triad.getWeights(point);

        setDragged(true)
        setNativeDraggableEventPosition(position)
        setPropertySpecificWeights({
            left: leftPropertyWeight,
            right: rightPropertyWeight,
            top: topPropertyWeight,
        })
        setBounds({
            // free moving area of the ball
            top: -BALL_DIMENSION.topLengthToCenter,
            left: BALL_DIMENSION.centerPosition.x - currentHalfBottomLength,
            right: BALL_DIMENSION.centerPosition.x + currentHalfBottomLength,
            bottom: TRIANGLE_DIMENSION.height - BALL_DIMENSION.topLengthToCenter,
        })
    }

    const handleDrag = (e, position) => {
        calculateBoundsAndWeights(position)
    }

    const handleClick = (e) => {
        if (props.disabled) return
        const draggablePosition = convertBrowserClickEventPositionToDraggablePosition(e)
        if (!pointIsInsideTriangle(draggablePosition)) return
        calculateBoundsAndWeights(draggablePosition)
        setNormalisedPositionState(normalisePosition(draggablePosition))
    }

    const handlePreventTouchmoveWhenPanning = (event) => {
        if (panning) {
            event.preventDefault()
        }
    }

    // maybe for mouseDown on ipad or mobile phone
    const handleStart = () => {
        if (isMobile) {
            handlePreventTouchmoveWhenPanning()
        }
        setPanning(true)
    }

    const handleStop = () => {
        setPanning(false)

        props.updatePoint(
            {
                left: Math.abs(propertySpecificWeights.left),
                right: Math.abs(propertySpecificWeights.right),
                top: Math.abs(propertySpecificWeights.top),
            },
            normalisePosition(nativeDraggableEventPosition),
            false,
        )
    }

    let draggableClassNames = ''

    if (props.disabled) {
        draggableClassNames += ' not-app'
    } else if (dragged) {
        draggableClassNames += ' dragged'
    }
    const imageSource =  props.imageUrl || backgroundIcons[props.imageName]
    const pointImageSource =  props.pointImageUrl || pointIcons[props.imageName]
    return (
        <Triangle className={props.disabled ? 'disabled' : ''}>
            <div style={{margin: '10px 0'}}>{props.topLabel}</div>
            <TriangleImage
                data-testid="TriangleComponent-triangle-image"
                onClick={handleClick}
                style={{
                    backgroundImage: `url('${imageSource}')`
                }}
            >
                <Draggable
                    position={nativeDraggableEventPosition}
                    onDrag={handleDrag}
                    bounds={{
                        top: bounds.top,
                        left: bounds.left,
                        right: bounds.right,
                        bottom: bounds.bottom,
                    }}
                    onStart={handleStart}
                    onStop={handleStop}
                    disabled={props.disabled}
                >
                    <DraggableItem
                        className={draggableClassNames}
                        data-testid="TriangleComponent-point-image"
                        style={{
                            backgroundImage: `url('${pointImageSource}')`
                        }}
                    ></DraggableItem>
                </Draggable>
            </TriangleImage>
            <BottomLabel>
                <BSLeft>{props.leftLabel}</BSLeft>
                <BSRight>{props.rightLabel}</BSRight>
            </BottomLabel>
        </Triangle>
    )
}

TriangleComponent.propTypes = {
    topLabel: PropTypes.string,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    imageName: PropTypes.string,
    imageUrl: PropTypes.string,
    pointImageName: PropTypes.string,
    pointImageUrl: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    handlePositionChange: PropTypes.func,
    shouldResetPosition: PropTypes.bool,
    isNotAvailableChecked: PropTypes.bool,
    updatePoint: PropTypes.func,
}

TriangleComponent.defaultProps = {
    imageName: '../../../img/Capture/triad.png',
    disabled: false,
}

export default TriangleComponent
