import {consoleError} from "./../utils/error"

class Socket {
    constructor(endpoint) {
        this.endpoint = endpoint
        this.connect()
    }

    _socket = null

    getWebsocketUrl = (token, endpoint) => {
        let protocol = 'ws://'
        if (window.location.protocol === 'https:') {
            protocol = 'wss://'
        }

        const host =
            process.env.NODE_ENV === 'production'
                ? this.getServerURL()
                : this.getServerURL() + ':8000'
        return protocol + host + '/' + endpoint + '/?token=' + token
    }

    getServerURL = () => window.location.host.split(':')[0]

    connect = () => {
        if (this._socket) {
            const newSocket = new WebSocket(
                this.getWebsocketUrl(localStorage.getItem('token'), this.endpoint),
            )
            newSocket.onclose = this.setOnClose
            this._socket = newSocket
        } else {
            this._socket = new WebSocket(
                this.getWebsocketUrl(localStorage.getItem('token'), this.endpoint),
            )
        }
    }

    send = (data) => {
        try {
            this._socket.send(JSON.stringify(data))
        } catch (error) {
            consoleError(error)
        }
    }

    setOnClose = (handleSocketClose) => {
        this._socket.onclose = handleSocketClose
    }

    setOnError = (handleSocketError) => {
        this._socket.onerror = function (e) {
            handleSocketError(e)
        }
    }

    setOnMessage = (callback) => {
        this._socket.onmessage = function (e) {
            const data = JSON.parse(e.data)
            callback(data)
        }
    }

    setOnOpen = (callback) => {
        this._socket.onopen = function (e) {
            callback && callback(e)
        }
    }
}

export default Socket
