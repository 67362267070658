import {TIME_AREAS} from '../const'
import {NODE_ACTION_KEYS, NODE_STATES, NODE_TYPES, NODE_TYPES_TO_EXTENDED_NAMES} from '../const'
import BoardRows from '../components/BoardRows'
import React from 'react'
import {I18n} from 'react-i18nify'
import {isEmpty, prop, tail, uniqBy} from 'ramda'
import {useUserSlice} from '../hooks/useUserSlice'
import {useSelector} from 'react-redux'
import {UserInformationAsList, UserTitle} from '../../core/ui/modal/Modal'
import {showPopupMessage} from '../../core/utils/notifications'
import Avatar from "../../core/components/Avatar";
import {RESTRICTIONS} from "../../core/const";

export const shouldDrop = (node, timeArea) => {
    switch (timeArea) {
        case TIME_AREAS.CONFIRMED:
            return node.type === NODE_TYPES.NC || node.type === NODE_TYPES.CSF
        case TIME_AREAS.VALIDATING:
            return (
                node.type === NODE_TYPES.NC ||
                node.type === NODE_TYPES.CSF ||
                node.type === NODE_TYPES.PSF ||
                node.type === NODE_TYPES.EXPERIMENT
            )
        case TIME_AREAS.POTENTIAL:
            return (
                node.type === NODE_TYPES.NC ||
                node.type === NODE_TYPES.PSF ||
                node.type === NODE_TYPES.EXPERIMENT ||
                isGoal(node)
            )
        default:
            return false
    }
}

export const isMe = (pk) => {
    return useUserSlice().pk === pk
}

export const getTimeAreaIndex = (timeArea) => {
    let timeAreaIndex = 0
    if (timeArea === TIME_AREAS.VALIDATING) {
        timeAreaIndex = 1
    } else if (timeArea === TIME_AREAS.POTENTIAL) {
        timeAreaIndex = 2
    }
    return timeAreaIndex
}

export const getTimeAreaFromIndex = (index) => {
    const timeAreas = [TIME_AREAS.CONFIRMED, TIME_AREAS.VALIDATING, TIME_AREAS.POTENTIAL]
    return timeAreas[index]
}

export const getNodeChildrenByTimeArea = (children, nodeMap) => {
    const childrenPerTimeArea = [[], [], []]
    children.forEach((nodeId) => {
        if (!nodeMap[nodeId]) return
        const timeAreaIndex = getTimeAreaIndex(nodeMap[nodeId].time_area)
        childrenPerTimeArea[timeAreaIndex].push(nodeId)
    })
    return childrenPerTimeArea
}

export const handleDrop = (
    monitor,
    Actions,
    nextX,
    nextY,
    timeArea,
    nodeMap,
    clickedNodesToChildren,
) => {
    if (monitor.canDrop({shallow: true})) {
        const draggedItem = {
            ...monitor.getItem(),
            ...nodeMap[monitor.getItem().id],
        }
        const lastX = draggedItem.x
        const lastY = draggedItem.y
        const nodeParentId = draggedItem.nodeParentId
        if (shouldDrop(draggedItem, timeArea)) {
            if (lastX !== nextX) {
                Actions.moveNode({
                    lastX,
                    lastY,
                    nextX,
                    nextY,
                    timeArea,
                    nodeParentId,
                    nodeId: draggedItem.id,
                    clickedNodesToChildren,
                })
                Actions.updateNode({...draggedItem, time_area: timeArea})
            }
        }
    }
}

export const renderNodes = (nodesPerTimeArea, clickedNodesToChildren, nodeMap, nodeParentId) => {
    const {showHiddenExperiment} = useSelector((state) => state.strategyMap)
    const nodesToDisplay = []
    let rowDisplayPerTimeArea = [[], [], []]
    let nodeInserted = false

    nodesPerTimeArea.forEach((items, i) => {
        if (items.length > 0) {
            items.forEach((nodeId, j) => {
                if (!nodeMap[nodeId]) return
                if (
                    isExperiment(nodeMap[nodeId]) &&
                    nodeMap[nodeId].is_finished &&
                    !showHiddenExperiment
                )
                    return
                const timeAreaIndex = getTimeAreaIndex(nodeMap[nodeId].time_area)
                if (clickedNodesToChildren[nodeId]) {
                    if (nodeInserted) {
                        nodesToDisplay.push(
                            <BoardRows
                                key={`closed${i}${j}`}
                                nodesPerTimeArea={rowDisplayPerTimeArea}
                                nodeParentId={nodeParentId}
                            />,
                        )
                        nodeInserted = false
                        rowDisplayPerTimeArea = [[], [], []]
                    }
                    const tempList = [[], [], []]
                    tempList[timeAreaIndex].push(nodeId)
                    nodesToDisplay.push(
                        <BoardRows
                            key={`expanded${i}${j}`}
                            nodesPerTimeArea={tempList}
                            nodeId={nodeId}
                            nodeParentId={nodeParentId}
                        />,
                    )
                } else {
                    rowDisplayPerTimeArea[timeAreaIndex].push(nodeId)
                    nodeInserted = true
                }
            })
        }
    })
    if (nodeInserted) {
        nodesToDisplay.push(
            <BoardRows
                key={`closed999999`}
                nodesPerTimeArea={rowDisplayPerTimeArea}
                nodeParentId={nodeParentId}
                expandOnly
            />,
        )
    }
    return nodesToDisplay
}

export const getAbsolutePosition = (obj) => {
    let curleft = 0
    let curtop = 0

    if (obj.offsetParent) {
        do {
            curleft += obj.offsetLeft
            curtop += obj.offsetTop
        } while ((obj = obj.offsetParent))
    }

    return {top: curtop, left: curleft}
}

export const getAncestorsDataForField = (node, nodeMap, fieldName) => {
    if( ! node.parents || node.parents.length === 0 || ! nodeMap[node.id] )
        return [];

    const parentObjects = node.parents.map( parentID => nodeMap[parentID]).filter( a => !! a );

    const hasParents = (node) =>
        node && !! node.parents && nodeMap[node.id];

    const parentFieldData = (parent) =>
        (parent && parent[fieldName]) || [];
    const parentAncestorsFieldData = (parent) => {
        return hasParents(parent) ? getAncestorsDataForField(parent, nodeMap, fieldName) : [];
    }
    const allAncestorsFieldData = (parent) => {
        return parentFieldData(parent).concat(parentAncestorsFieldData(parent));
    }

    const data = parentObjects?.map( (parent) => allAncestorsFieldData(parent)).flat();
    return data || [];
}

export const isGoal = (node) => node.type === NODE_TYPES.GOAL;
export const isSF = (node) => [NODE_TYPES.CSF, NODE_TYPES.PSF].includes(node.type);
export const isPSF = (node) => node.type === NODE_TYPES.PSF;
export const isCSF = (node) => node.type === NODE_TYPES.CSF;
export const isNC = (node) => node.type === NODE_TYPES.NC;
export const isExperiment = (node) => node.type === NODE_TYPES.EXPERIMENT;

export const userCanAddOrDeleteChampion = (user, node, nodeMap) => isUserAChampion( user, node, nodeMap ) || isUserASponsor( user, node, nodeMap )

export const experimentIsReadOnly = (node, nodeMap, user) => {
    if (!node || !node.id) {
        return false
    }

    const isReadOnly = ! RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role] );
    const champions = getAncestorsDataForField(node, nodeMap, 'champions_data')
    const isUserChampion = champions.find((item) => item.email === user.email) !== undefined
    const isUserInExperimentTeam = user.in_experiment_team && user.in_experiment_team.id === node.id

    return isReadOnly || (node && node.is_finished) || (!isUserInExperimentTeam && !isUserChampion)
}

export const getNodeState = (node) => {
    switch (node.type || node.sf_type) {
        case NODE_TYPES.NC:
            return node.fulfilled ? NODE_STATES.N.fulfilled : NODE_STATES.N.unfulfilled
        case NODE_TYPES.EXPERIMENT:
            if (node.is_started) {
                if (node.is_finished) {
                    if (node.successful) return NODE_STATES.E.successful
                    if (node.failed) return NODE_STATES.E.failed
                    return NODE_STATES.E.finished
                }
                return NODE_STATES.E.started
            }
            return NODE_STATES.E.created
        default:
            return ''
    }
}

export const isCreatedOrUnfulfilled = (node) => {
    const state = getNodeState(node)
    return state === NODE_STATES.E.created || state === NODE_STATES.N.unfulfilled
}

export const isExperimentExecuting = (node) => {
    const state = getNodeState(node)
    return state === NODE_STATES.E.started
}

export const isExperimentSuccessful = (node) => {
    const state = getNodeState(node)
    return state === NODE_STATES.E.successful
}
export const getDescriptionPlaceholder = (nodeType) => {
    return nodeType && nodeType !== NODE_TYPES.EXPERIMENT
        ? I18n.t(
              'strategyMap.nodes.descriptionPlaceholder' +
                  NODE_ACTION_KEYS[nodeType].icon_key.toUpperCase(),
          )
        : ''
}

export const getNonArchivedNodes = (nodeIDs, nodeMap) => {
    return nodeIDs.filter(id => nodeMap[id] && !nodeMap[id].is_archived)
}

export const getNonHiddenNodes = (nodeIDs, nodeMap) => {
    return nodeIDs.filter(id => nodeMap[id] && (nodeMap[id].type !== NODE_TYPES.EXPERIMENT || (nodeMap[id].type === NODE_TYPES.EXPERIMENT && !nodeMap[id].is_finished)))
}

export const getArchivedNodes = (nodeIDs, nodeMap) => {
    return nodeIDs.filter(id => nodeMap[id] && nodeMap[id].is_archived)
}

export const getChampions = (node, nodeMap) => {
    if (isSF(node) && node.champions_data) {
        return node.champions_data
    } else {
        const ancestralChampions = getAncestorsDataForField(node, nodeMap, 'champions_data')
        return uniqBy(prop('pk'), ancestralChampions)
    }
}

export const getSquadMember = (node, nodeMap) => {
    return isSF(node) && node.squad ? node.squad : getAncestorsDataForField(node, nodeMap, 'squad');
}

export const getSponsors = (node, nodeMap) => {
    return isGoal(node) && node.sponsors
        ? node.sponsors
        : getAncestorsDataForField(node, nodeMap, 'sponsors');
}

export const isExperimentFailed = (node) => {
    const state = getNodeState(node)
    return state === NODE_STATES.E.failed
}

export const isNCFulfilled = (node) => {
    const state = getNodeState(node)
    return state === NODE_STATES.N.fulfilled
}

export const nodeHasIntegration = (currentOpenNodeId, nodeMap, integrationType) => {
    const integrations =
        (nodeMap[currentOpenNodeId] && nodeMap[currentOpenNodeId].node_integrations) || []
    return integrations.filter((integration) => integration.tool === integrationType).length > 0
}

export const isUserAChampion = (user, node, nodeMap) => {
    const champions = getChampions(node, nodeMap)

    return champions.find((item) => item.pk === user.pk) !== undefined
}

export const isCurrentUserChampion = (node, nodeMap) => {
    return isUserAChampion(useUserSlice(), node, nodeMap)
}

export const checkIfUserIsInExperimentTeam = (user, nodeId) => {
    return user.in_experiment_team && user.in_experiment_team.id === nodeId
}

export const isUserInExperimentTeam = (user, node) => {
    if (!isExperiment(node) || !node.experiment_team_members) return false
    return node.experiment_team_members.some(
        (expTeam) =>
            expTeam.user.pk === user.pk &&
            ((expTeam.approved && expTeam.is_active) || expTeam.pending),
    )
}

export const isCurrentUserInExperimentTeam = (nodeId) => {
    return checkIfUserIsInExperimentTeam(useUserSlice(), nodeId)
}

export const isUserASponsor = (user, node, nodeMap, zeroTrue) => {
    const sponsors = getSponsors(node, nodeMap)
    if (sponsors.length === 0 && zeroTrue) return true

    return (
        sponsors.filter((sponsor) => {
            return sponsor.user.pk === user.pk
        }).length > 0
    )
}

export const isUserASquadMember = (user, node, nodeMap) => {
    const squadMembers = getSquadMember(node, nodeMap)
    return (
        squadMembers.find(
            (squadMember) => squadMember.user.pk === user.pk && squadMember.is_accepted,
        ) !== undefined
    )
}

export const isUserAbleToEditNode = (user, node, nodeMap) => {
    if( node.id && ! nodeMap[node.id] ) {
        return false;
    }
    if( ! RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role] ) ) {
        return false;
    }
    if (getChampions(node, nodeMap).length === 0 && getSquadMember(node, nodeMap).length === 0) {
        return true
    }
    if (isGoal(node)) {
        return isUserASponsor(user, node, nodeMap, true)
    }
    const isUserInExperimentTeam = checkIfUserIsInExperimentTeam(user, node.id)
    return (
        isUserASponsor(user, node, nodeMap) ||
        isUserAChampion(user, node, nodeMap) ||
        isUserASquadMember(user, node, nodeMap) ||
        (isExperiment(node) && isUserInExperimentTeam)
    )
}

export const isUserAParticipant = (user, node, nodeMap) => {
    return (
        isUserAChampion(user, node, nodeMap) ||
        isUserASquadMember(user, node, nodeMap) ||
        isUserInExperimentTeam(user, node)
    )
}

export const isUserChampionOrSquadMember = (user, node, nodeMap) => {
    return isUserAChampion(user, node, nodeMap) || isUserASquadMember(user, node, nodeMap)
}

export const isNodeReadOnly = (user, node, nodeMap) => {
    return (
        (isExperiment(node) && node.is_finished) ||
        !isUserAbleToEditNode(user, node, nodeMap) ||
        (isSF(node) &&
            getChampions(node, nodeMap).length > 0 &&
            !isUserAChampion(user, node, nodeMap))
    )
}

export const getValidChildTypesForParent = (node, nodeMap, user) => {
    const isUserChampion = isCurrentUserChampion(node, nodeMap)
    const isUserInSquad = isUserASquadMember(user, node, nodeMap)
    const isUserInExperimentTeam = isCurrentUserInExperimentTeam(node.id)

    const nodeBaseObject = {
        type: '',
        isDisabled: false,
        message: '',
    }

    const nodesArray = isGoal(node)
        ? [
              {...nodeBaseObject, type: 'CSF'},
              {...nodeBaseObject, type: 'PSF'},
          ]
        : isCSF(node)
        ? [{...nodeBaseObject, type: 'NC'}]
        : isPSF(node) && !isUserChampion && !isUserInExperimentTeam && !isUserInSquad
        ? [
              {...nodeBaseObject, type: 'NC'},
              {
                  ...nodeBaseObject,
                  type: 'EXPERIMENT',
                  isDisabled: true,
                  message: I18n.t('strategyMap.messages.doNotHaveHavePermissionsExperimentMsg'),
              },
          ]
        : isPSF(node)
        ? [
              {...nodeBaseObject, type: 'NC'},
              {...nodeBaseObject, type: 'EXPERIMENT'},
          ]
        : isExperiment(node) && !node.is_finished
        ? [{...nodeBaseObject, type: 'NC'}]
        : isExperiment(node)
        ? [
              {
                  ...nodeBaseObject,
                  type: 'NC',
                  isDisabled: true,
                  message: I18n.t('strategyMap.messages.theExperimentHasEndedMessage'),
              },
          ]
        : isNC(node)
        ? [{...nodeBaseObject, type: 'NC'}]
        : []

    return nodesArray
}

export const getNodeLineage = (node, nodeMap, lineage=[]) => {
    if (!node.parents || isEmpty(node.parents)) {
        return lineage
    }
    const parentID = node.parents[0]
    const parent = nodeMap[parentID]
    if (!parent) return false
    return getNodeLineage(parent, nodeMap, lineage.concat(parentID))
}

export const removeSelfFromSquad = (
    me,
    squadMember,
    isUserChampion,
    totalChampions,
    onRequestClose,
    Actions,
) => {
    if (isUserChampion && squadMember.user.pk === me && totalChampions > 1) {
        onRequestClose()
        showPopupMessage({
            title: I18n.t('strategyMap.messages.warning'),
            message: I18n.t('strategyMap.messages.warningRemoveMyselfAsChampion'),
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.messages.yes'),
            onConfirmCallback: () => Actions.removeSquadMember(squadMember),
            onCancelCallback: () => Actions.setShowModalSquad(true),
        })
        return false
    } else if (squadMember.user.pk === me) {
        const warning = I18n.t('strategyMap.messages.warningRemoveMyselfAsSquadMember')
        onRequestClose()
        showPopupMessage({
            title: I18n.t('strategyMap.messages.warning'),
            message: warning,
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.messages.yes'),
            onConfirmCallback: () => Actions.removeSquadMember(squadMember),
            onCancelCallback: () => Actions.setShowModalSquad(true),
        })
        return false
    }

    Actions.removeSquadMember(squadMember)
}

export const renderUserItemInline = (useDiv) => (option, showAvatar) => {
    // this function is used for both sharing to users and selecting users with roles, but the context is different,
    // therefore it was not possible to have common styles that worked for both
    // so unfortunately have to add logic to change the title container
    const optionTitle = option.full_name ? option.full_name : option.email

    const renderOptionTitle = useDiv
        ? () => <UserTitle>{optionTitle}</UserTitle>
        : () => <div>{optionTitle}</div>

    return (
        <UserInformationAsList>
            {showAvatar && (
                <Avatar
                    pull="right"
                    colorCode={option.color_code}
                    text={option.initials}
                    profilePicture={option.profile_picture}
                />
            )}
            {renderOptionTitle()}
        </UserInformationAsList>
    )
}

export const getPendingNode = (type, description, parentNode) => ({
    resourcetype: NODE_ACTION_KEYS[type].resourcetype,
    sf_type: type,
    title: '',
    description,
    parentNode,
})

export const getNodeDisplay = (node) => {
    return `${NODE_TYPES_TO_EXTENDED_NAMES[node.type]} #${node.sid}`
}

export const getNodeLinkByNodeIdAndGoalId = (nodeId, goalId) => {
    return `${window.location.origin}/strategy-map/${goalId}/${nodeId}`
}

export const getNodeLink = (nodeId) => {
    const lastOpenedMap = localStorage.getItem('lastOpenedMap') || ''
    return getNodeLinkByNodeIdAndGoalId(nodeId, lastOpenedMap)
}

export const getRemainingCaptureDays = (client) => {
    if (!client.end_date) return 1
    const days = Math.floor((Date.parse(client.end_date) - Date.now()) / 86400000)
    return days
}

const _getDescendants = (nodeMap, id) => {
    if( isEmpty(nodeMap) || ! id )
        return [];

    const node = nodeMap[id];

    if( ! node ){
        return [];
    } else if( ! node.children.length ){
        return [node.id];
    }

    const descendants = node.children.map( (childID) => _getDescendants(nodeMap, childID)).flat();
    return [node.id].concat(descendants);
}

export const getDescendants = (nodeMap, id) => tail(_getDescendants(nodeMap, id))

export const downloadData = (data, name) => {
    const content = JSON.stringify(data)
    const a = document.createElement('a')
    const file = new Blob([content], {type: 'text/plain'})
    a.href = URL.createObjectURL(file)
    a.download = name
    a.click()
}