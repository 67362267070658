import React from 'react';
import {I18n} from "react-i18nify";
import Charts from "../../Charts/Charts";
import {ChartType, SubTypes as SubTypesCharts} from "../../Charts/ChartsConfig";
import {
    InspectGraphsChartContainer, 
    InspectGraphsChartStyle, 
    InspectGraphsChartTitle
} from "../inspect/Inspect.styles";
import {subtypes} from "../../dashboard/components/DashboardLeaderShipSurvey";
import {useDashboardSlice} from "../../dashboard/hooks/useDashboardSlice";
import { moodColorMap } from '../../Charts/ChartColor';

const Graphs = ({surveys, showAutonomyVsContolChart}) => {

    const {selectedPoints} = useDashboardSlice()

    const checkForSelectedPointsExistance = () => selectedPoints && selectedPoints.decisionIDs && selectedPoints.decisionIDs.length > 0
    const checkIfIsSelectedPoints = (key) => selectedPoints && 
                                            ( ! selectedPoints.decisionIDs || 
                                                selectedPoints.decisionIDs.length === 0 || 
                                                selectedPoints.decisionIDs.includes( key ) ) 

    const filteredSurveys = surveys.filter( a => checkIfIsSelectedPoints( a.pk ) );

    const formatDate = (date) => {
        const d = new Date(date)
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }

    const getMedianValue = (key) => {
        const assessments = surveys
                                .filter( (value) => value.parent_capture === key || value.pk === key )
        const summation = assessments.reduce( (total, curr) => total + curr.autonomy_vs_control, 0 )
        return summation / assessments.length
    }

    const getMoodColour = (key) => moodColorMap[surveys.find( (value) => value.pk === key).mood]

    const charts = Object.values(SubTypesCharts.triangle).map( triangle => ({
        chartData: {
            title: I18n.t(subtypes()[triangle].title),
            Type: ChartType.Triangle,
            subType: triangle,
            subTypes: subtypes()[triangle],
        },
        dataShown: filteredSurveys,
    }) )

    if(showAutonomyVsContolChart) {
        charts.push({
            chartData: {
                title: I18n.t("leadership.Panel.Topic.Autonomy vs Control"),
                Type: ChartType.AreaChart
            },
            dataShown: surveys
                        .filter( value => value.parent_capture === null)
                        .map( value => ({
                            name: formatDate(value.created), 
                            autonomyVsControl: getMedianValue(value.pk) - 50,
                            selectedDatapoint: checkForSelectedPointsExistance() && checkIfIsSelectedPoints(value.pk) ? getMedianValue(value.pk) - 50 : null,
                            moodColour: getMoodColour(value.pk)
                        }) )
        })
    }

    return (
        <div>
            {
                filteredSurveys.length &&
                charts.map( (chart,key) =>
                        <InspectGraphsChartStyle width={'360px'} even={key % 2} key={key}>
                            <InspectGraphsChartTitle>
                                <p style={{float: 'left'}}>{ chart.chartData.title }</p>
                            </InspectGraphsChartTitle>
                            <InspectGraphsChartContainer key={key}>
                                <Charts
                                    {...chart.chartData}
                                    dataObject={{dataShown: chart.dataShown, dataCompare: []}}
                                    inspectMode={false}
                                    isCompared={false}
                                    language={I18n._localeKey}
                                    coordinate={{height: 300, width: 300}}
                                />
                            </InspectGraphsChartContainer>
                        </InspectGraphsChartStyle>
                    )
            }
        </div>
    )
}

export default Graphs;