import styled from 'styled-components'
import {BaseMixin} from './BaseMixin'

export const Img = styled.img`
    ${BaseMixin}
`

export const H2 = styled.h2`
    ${BaseMixin}
`

export const H5 = styled.h5`
    ${BaseMixin}
`

export const Input = styled.input`
    ${BaseMixin}
`

export const I = styled.i`
    ${BaseMixin}
`

export const P = styled.p`
    ${BaseMixin}
`

export const Span = styled.span`
    ${BaseMixin}
`