import settings from '../config/settings'
import axios from 'axios'
import {getRequestHeader} from '../core/utils/network'
import {I18n} from "react-i18nify";

export default class Api {
    static fetchClientStatistics() {
        return axios.get(`${settings.apiBaseURL}/statistics/`, getRequestHeader())
    }

    static saveImage(data) {
        return axios.post(`${settings.apiBaseURL}/report/images/`, data, getRequestHeader())
    }

    static saveReportAnswers(data, language) {
        return axios.post(
            `${settings.apiBaseURL}/sreport/customer-fields/${I18n._localeKey}/`,
            {report_data: data},
            getRequestHeader(),
        )
    }

    static generateReport(version) {
        return axios.get(
            `${settings.apiBaseURL}/sreport/generate-report/${version}/?token=${localStorage.token}`,
            getRequestHeader(),
        )
    }

    static getReportAnswers() {
        return axios.get(`${settings.apiBaseURL}/sreport/customer-fields/${I18n._localeKey}/`, getRequestHeader())
    }
}
