import React from 'react'
import {InspectGraph} from './InspectGraph'
import GenerateHighResImageModal from './GenerateHighResImageModal'
import GenerateReportModal from './GenerateReportModal'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import { Panel } from '../../core/components/BootstrapWrapper'
import {RESTRICTIONS} from "../../core/const";

const Reports = () => {
    const user = useUserSlice()

    return (
        <Panel>
            <div className="row pull-right">
                {RESTRICTIONS.OrgScan.RULES.CAN_GENERATE_REPORT.some( role => user[role] ) && <GenerateReportModal />}
                {RESTRICTIONS.OrgScan.RULES.CAN_GENERATE_HiResIMAGES.some( role => user[role] )  && <GenerateHighResImageModal />}
            </div>
            <div className="row">
                <InspectGraph />
            </div>
        </Panel>
    )
}

export default React.memo(Reports)
