import settings from '../config/settings'
import axios from 'axios'
import {getRequestHeader} from '../core/utils/network'

export const NODES_API_ENDPOINT = `${settings.apiBaseURL}/nodes/`

export default class Api {

    fetchOtherNodes(goalID) {
        return axios.get(`${NODES_API_ENDPOINT}nodes_from_other_goals/?goalId=${goalID}`, getRequestHeader())
    }

    static fetchNode(nodeId) {
        return nodeId ? axios.get(`${NODES_API_ENDPOINT}${nodeId}/`, getRequestHeader()) : false
    }

    static fetchNodes() {
        return axios.get(NODES_API_ENDPOINT, getRequestHeader())
    }

    static fetchGoals() {
        return axios.get(`${NODES_API_ENDPOINT}goals/`, getRequestHeader())
    }

    static getGoal(params) {
        return axios.get(`${NODES_API_ENDPOINT}goal/`, {...getRequestHeader(), params})
    }

    static fetchSfs(params) {
        return axios.get(`${NODES_API_ENDPOINT}sfs/`, {...getRequestHeader(), params})
    }

    static clearNodes(params) {
        return axios.get(`${NODES_API_ENDPOINT}clear_nodes/`, {
            ...getRequestHeader(),
            params})
    }

    static generateDecisions(type) {
        return axios.get(
            `${settings.apiBaseURL}/decisions/generate_random_decisions/?type=${type}`,
            getRequestHeader(),
        )
    }

    static generateSMNodes() {
        return axios.get(`${NODES_API_ENDPOINT}generate_sm_nodes/`, getRequestHeader())
    }

    static importSMNodes(data) {
        return axios.post(`${NODES_API_ENDPOINT}import_sm_nodes/`, data, getRequestHeader())
    }

    static exportSM(params) {
        return axios.get(`${NODES_API_ENDPOINT}export_sm/`, {...getRequestHeader(), params})
    }

    static fetchChildren(id) {
        return id ? axios.get(`${NODES_API_ENDPOINT}${id}/children/`, getRequestHeader()) : false
    }

    static getNode(id) {
        return id ? axios.get(`${NODES_API_ENDPOINT}${id}/`, getRequestHeader()) : false
    }

    static convertNode(payload) {
        return axios.post(
            `${NODES_API_ENDPOINT}${payload.nodeId}/convert_node/`,
            payload,
            getRequestHeader(),
        )
    }

    static fetchAllowedPriorities(goalPk) {
        return axios.get(
            `${NODES_API_ENDPOINT}${goalPk}/allowed_priorities/`,
            getRequestHeader(),
        )
    }

    static fetchActivities(id) {
        return axios.get(`${NODES_API_ENDPOINT}${id}/activities/`, getRequestHeader())
    }

    static fetchLatestActivitiesForNodeAndChildren(payload) {
        return axios.post(
            `${NODES_API_ENDPOINT}${payload.id}/get_latest_activities_for_node_and_children/`, 
            payload,
            getRequestHeader()
        )
    }

    static fetchActiveUsers() {
        return axios.get(`${settings.apiBaseURL}/users/`, {
            ...getRequestHeader(),
            params: {active: true, all: true},
        })
    }

    static deactivateUser(data) {
        return axios.post(
            `${settings.apiBaseURL}/users/${data.pk}/deactivate/`,
            data,
            getRequestHeader(),
        )
    }

    static removePrimaryParent(data) {
        return axios.post(`${settings.apiBaseURL}/remove-primary-parent/`, data, getRequestHeader())
    }

    static addNode(node) {
        return axios.post(NODES_API_ENDPOINT, node, getRequestHeader())
    }

    static createGoal() {
        return axios.post(`${NODES_API_ENDPOINT}create_goal/`, {}, getRequestHeader())
    }

    static addNodeIntegration(nodeIntegration) {
        return axios.post(
            `${settings.apiBaseURL}/node-integration/`,
            nodeIntegration,
            getRequestHeader(),
        )
    }

    static fetchStrategicIterations(goalId) {
        return axios.get(
            `${settings.apiBaseURL}/strategic-iterations/?goalId=${goalId}`,
            getRequestHeader(),
        )
    }

    static addStrategicIteration(iteration) {
        return axios.post(
            `${settings.apiBaseURL}/strategic-iterations/`,
            iteration,
            getRequestHeader(),
        )
    }

    static updateStrategicIteration(iteration) {
        return axios.patch(
            `${settings.apiBaseURL}/strategic-iterations/${iteration.id}/`,
            iteration,
            getRequestHeader(),
        )
    }

    static deleteStrategicIteration(iterationId) {
        return axios.delete(
            `${settings.apiBaseURL}/strategic-iterations/${iterationId}/`,
            getRequestHeader(),
        )
    }

    static addSponsor(payload) {
        const {nodeId, userPk} = payload
        return axios.post(
            `${NODES_API_ENDPOINT}${nodeId}/add_sponsor/`,
            {user: userPk},
            getRequestHeader(),
        )
    }

    static updatePriority(data) {
        return axios.patch(
            `${NODES_API_ENDPOINT}${data.id}/priorities/`,
            data,
            getRequestHeader(),
        )
    }

    static updateNode(node) {
        delete node.sponsors
        return axios.patch(`${NODES_API_ENDPOINT}${node.id}/`, node, getRequestHeader())
    }

    static deleteNode(node) {
        return axios.delete(`${NODES_API_ENDPOINT}${node.id}/`, getRequestHeader())
    }

    static archiveNode(nodeID) {
        return axios.post(`${NODES_API_ENDPOINT}${nodeID}/archive/`, {}, getRequestHeader())
    }

    static deleteAllChildNodes(node) {
        return axios.delete(
            `${NODES_API_ENDPOINT}${node.id}/delete_children/`,
            getRequestHeader(),
        )
    }

    static archiveChildNodes(nodeID) {
        return axios.post(
            `${NODES_API_ENDPOINT}${nodeID}/archive_children/`,
            {},
            getRequestHeader(),
        )
    }

    static unarchiveNodes(nodeIDs) {
        return axios.post(`${NODES_API_ENDPOINT}unarchive/`, {nodes: nodeIDs}, getRequestHeader())
    }

    static deleteSponsor({nodeId, sponsorID}) {
        return axios.post(
            `${NODES_API_ENDPOINT}${nodeId}/remove_sponsor/`,
            {pk: sponsorID},
            getRequestHeader(),
        )
    }

    static switchSponsor({nodeId, sponsorID, user}) {
        return axios.post(
            `${NODES_API_ENDPOINT}${nodeId}/switch_sponsor/`,
            {pk: sponsorID, user},
            getRequestHeader(),
        )
    }

    static deleteExperimentSuccessCondition(id) {
        return axios.delete(
            `${settings.apiBaseURL}/experiment-success-condition/${id}/`,
            getRequestHeader(),
        )
    }

    static deleteExperimentFailureCondition(id) {
        return axios.delete(
            `${settings.apiBaseURL}/experiment-failure-condition/${id}/`,
            getRequestHeader(),
        )
    }

    static deleteDesiredOutcome(id) {
        return axios.delete(`${settings.apiBaseURL}/desired_outcomes/${id}/`, getRequestHeader())
    }

    static terminateExperiment(experimentId) {
        return axios.post(
            `${NODES_API_ENDPOINT}${experimentId}/terminate_experiment/`,
            {},
            getRequestHeader(),
        )
    }

    static leaveExperiment(id) {
        return axios.post(
            `${settings.apiBaseURL}/experiment-teams/${id}/leave/`,
            {},
            getRequestHeader(),
        )
    }

    static approveVolunteerRequest(id) {
        return axios.post(
            `${settings.apiBaseURL}/experiment-teams/${id}/approve/`,
            {},
            getRequestHeader(),
        )
    }

    static declineVolunteerRequest(id) {
        return axios.post(
            `${settings.apiBaseURL}/experiment-teams/${id}/decline/`,
            {},
            getRequestHeader(),
        )
    }

    static sendSharedLink(postContent) {
        return axios.post('/api/send-email-on-node/', postContent, getRequestHeader())
    }

    static applyToExperiment(userId, nodeId) {
        return axios.post(
            `${NODES_API_ENDPOINT}${nodeId}/experiment_team_members/`,
            {
                user: userId,
            },
            getRequestHeader(),
        )
    }

    static inviteUserToSquad(userId, nodeId) {
        return axios.post(
            `${settings.apiBaseURL}/improvement-squads/`,
            {
                user: userId,
                sf: nodeId,
            },
            getRequestHeader(),
        )
    }

    static acceptUserToSquad(id) {
        return axios.patch(
            `${settings.apiBaseURL}/improvement-squads/${id}/`,
            {
                is_accepted: true,
            },
            getRequestHeader(),
        )
    }

    static fetchSquadMembers(nodeId) {
        return axios.get(`${settings.apiBaseURL}/improvement-squads/`, {
            params: {sf: nodeId},
            ...getRequestHeader(),
        })
    }

    static removeSquadMember(id) {
        return axios.delete(`${settings.apiBaseURL}/improvement-squads/${id}/`, getRequestHeader())
    }

    static fetchAllExperiments(goalId) {
        return axios.get(`${NODES_API_ENDPOINT}${goalId}/experiments/`, getRequestHeader())
    }

    static createTrelloBoard(data) {
        return axios.post(`${settings.apiBaseURL}/trello/create_board/`, data, getRequestHeader())
    }

    static connectSlackChannel(sfId) {
        return axios.get(
            `${settings.apiBaseURL}/slack/${sfId}/create_sf_channel/`,
            getRequestHeader(),
        )
    }

    static createTarget(data) {
        return axios.post(
            `${NODES_API_ENDPOINT}${data.sf}/target/`,
            data,
            getRequestHeader(),
        )
    }

    static updateTarget(data) {
        return axios.patch(`${settings.apiBaseURL}/targets/${data.id}/`, data, getRequestHeader())
    }

    static deleteTarget(data) {
        return axios.delete(`${settings.apiBaseURL}/targets/${data.id}/`, getRequestHeader())
    }

    static removeIntegrationLink(data) {
        return axios.delete(
            `${settings.apiBaseURL}/node-integration/${data[1]}/`,
            getRequestHeader(),
        )
    }

    static importStrategyMapFromMiro = data =>
        axios.post(`${NODES_API_ENDPOINT}import_sm_from_miro/`, data, getRequestHeader());
}
