import React, {useEffect} from 'react'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {useLanguageSlice} from '../hooks/useLanguageSlice'
import {FullScreenLoading} from './Loading'
import {useHistory} from '../hooks/useHistory'
import { isFreeUsage } from '../utils/navigation'
import {useCoreSlice} from "../hooks/useCoreSlice";
import {isPublicInstance} from "../utils/network";
import { NotFound } from '../../FallbackComponent'

export const authUrlNames = ['login', 'password'];
export const httpFailStatusCodes = [403,401,400]

const InitialData = (props) => {
    const {Actions, client} = useCoreSlice();

    const user = useUserSlice();
    const language = useLanguageSlice();
    const {history} = useHistory();
    const isAuthPage = authUrlNames.some( name => history.location.pathname.includes(name) );

    useEffect(() => {
        if( ! isPublicInstance() && 
            ! isAuthPage && 
            ! isFreeUsage() && 
            ! client.id && 
            localStorage.getItem('token')
        ) {
            Actions.getClient();
        }
    }, [user.authSuccess] );

    useEffect(() => {
        if( ! language.loaded) 
            language.Actions.fetchLanguages();
    }, [])

    if(!language.isFetching && !language.loaded && (language.errorCode && !httpFailStatusCodes.includes( language.errorCode ) ))
        return <NotFound />

    if ( ! isFreeUsage() && 
         ! isAuthPage && 
         ( ! language.loaded || !client.id ) &&
         localStorage.getItem('token') )
        return <FullScreenLoading loading={true} />

    return props.children;
}

export default InitialData;