import React from 'react'
import {ButtonPrimary, ButtonSecondary} from '../../components/Buttons'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import {Div} from '../Div'

const _Footer = styled(Div)`
    margin-top: 20px;
    align-items: right;
    float: right;
`

const Footer = (props) => {
    return (
        <_Footer>
            <ButtonSecondary type="button" onClick={props.onCancel}>
                {props.cancelText || I18n.t('strategyMap.messages.cancel')}
            </ButtonSecondary>
            {props.onConfirm && props.confirmText && (
                <ButtonPrimary type="button" noSideMrg onClick={props.onConfirm}>
                    {props.confirmText}
                </ButtonPrimary>
            )}
        </_Footer>
    )
}

export default Footer
