import React, { useEffect, useState } from 'react';
import {CadenceDetails, Symbol} from "../Navbar.styles";
import {I18n} from "react-i18nify";
import { Modal } from '../../../ui/modal/Modal';
import { SideBarContainerWrapper, Title } from '../../../../strategyMap/components/Node.styles';
import Checkbox from '../../../../capture/signifier/Checkbox';
import { useStrategyMapSlice } from '../../../../strategyMap/hooks/useStrategyMapSlice';
import { useUserSlice } from '../../../../strategyMap/hooks/useUserSlice';


const NavbarCadenceSummary = ({cadence}) => {
    if (!cadence.isActive())
        return <></>;
    
    const [isTargetModalOpen, setIsTargetModalOpen] = useState(false);
    const {Actions, goal} = useStrategyMapSlice();
    const user = useUserSlice();
    const filterTargets = (target) => cadence.currentStrategicIteration.targets.includes(target.id);
    const selectedTargets = cadence.targets.filter(filterTargets);
    const checkIfUserIsSponsor = () => goal.sponsors.filter(sponsor => sponsor.user.pk === user.pk).length > 0;
    const [isUserSponsor, setIsUserSponsor] = useState(checkIfUserIsSponsor());
    
    useEffect(() => {
        setIsUserSponsor(checkIfUserIsSponsor());
    }, [goal.sponsors]);

    return(
        <>
            <CadenceDetails>
                <div>
                    <div style={{cursor:'pointer'}} onClick={() => setIsTargetModalOpen(true)}>
                        <Symbol className='material-icons'>library_add_check</Symbol>
                        <p>{ cadence.getCompletedTargetsCountOnCurrentIteration() } / { cadence.getTargetsCountOnCurrentIteration() } {I18n.t("strategyMap.cadence.targets")}</p>
                    </div>
                    <Symbol className='material-icons'>schedule</Symbol>
                    <p>{ cadence.getDaysLeftCountOnCurrentIteration() } {I18n.t("strategyMap.cadence.daysLeft")}</p>
                </div>
            </CadenceDetails>

            <Modal 
                isOpen={isTargetModalOpen}
                title={I18n.t('strategyMap.cadence.targets')}
                footer={{
                    cancelText: I18n.t('strategyMap.messages.close'),
                    onCancel: () => setIsTargetModalOpen(false)
                }}
            >   
                {selectedTargets.map((target) => (
                    <SideBarContainerWrapper key={target.id} clickable style={{marginBottom: "10px"}}>
                        <Checkbox
                            onClick={() => {
                                if(isUserSponsor) {
                                    Actions.updateTarget({...target, is_done: !target.is_done})
                                }
                            }}
                            disabled={!isUserSponsor}
                            checked={target.is_done}
                        />
                        <Title
                            style={{marginLeft: 10}}
                        >
                            {target.title}
                        </Title>
                    </SideBarContainerWrapper>
                ))}
            </Modal>
        </>
    );
}

export default NavbarCadenceSummary;