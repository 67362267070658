import React, {useEffect} from 'react'
import {I18n} from 'react-i18nify'
import {ButtonPrimary, ButtonSecondary} from '../../core/components/Buttons'
import { CopyToClipboard } from '../../core/components/CopyToClipboard'
import {useHistory} from '../../core/hooks/useHistory'
import {CenterContainer, EvenlyContainer, PageCenterContainer, InfoLabel} from './Shared.styles'

const ThankYou = (props) => {
    const {history} = useHistory()

    useEffect(() => {
        return () => {
            props.resetCapture()
        }
    }, [])

    return (
        <PageCenterContainer>
            <CenterContainer style={{width: '50%'}}>
                <InfoLabel center data-testid="thankyou">{I18n.t("capture.ThankYouMessage")}</InfoLabel>
                <EvenlyContainer>
                    <ButtonSecondary onClick={() => history.push('/')}>
                        {I18n.t('capture.Form.BackToDashboard')}
                    </ButtonSecondary>
                    <ButtonPrimary
                        data-testid={'capture-new'}
                        onClick={props.resetCapture}
                    >
                        {I18n.t('capture.Form.NewCapture')}
                    </ButtonPrimary>
                </EvenlyContainer>
                <p>{I18n.t('capture.Form.CopyToClipboardDescription')}</p>
                <CopyToClipboard text={I18n.t('capture.Form.CopyToClipboardText').replace('%t', props.captureTitle).replace('%l', window.location.href)} />
            </CenterContainer>
        </PageCenterContainer>
        
    )
}

export default ThankYou
