import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {I18n} from 'react-i18nify'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {useSelector} from 'react-redux'
import HeadingWithAction from '../../Components/HeadingWithAction'
import {useNode} from '../../../strategyMap/hooks/useNode'
import {useUserSlice} from '../../../strategyMap/hooks/useUserSlice'
import {CalenderInputIcon, DateInput} from "../../../core/ui/DateInput"
import {RESTRICTIONS} from "../../../core/const";


const calcDuration = (deadline) => {
    const now = moment()
    const durationInDays = deadline.diff(now, 'days') + 1
    const durationInWeeks = durationInDays === 0 ? 1 / 7 : durationInDays / 7
    return durationInWeeks
}

const ExperimentPeriod = (props) => {
    const [focused, setFocused] = useState(false)
    const {pendingNode} = useSelector((state) => state.strategyMap)
    const {isUserChampionOrSquadMember, isUserSponsor} = useNode(props.node.id)
    const node = props.node || pendingNode
    const [deadline, setDeadline] = useState(node.deadline)
    const user = useUserSlice()

    const handleDateChange = (deadline) => {
        setDeadline(deadline)
        if (deadline === null) return
        const duration = calcDuration(deadline)
        props.onDeadlineChange(deadline, duration)
    }

    return (
        <div className="experiment-period">
            <HeadingWithAction title={I18n.t('strategyMap.experiment.deadline')} />
            {( ! isUserChampionOrSquadMember && ! isUserSponsor) || ! RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role]) ? (
                <div style={{height: '40px'}}>{node.deadline || 'N/A'}</div>
            ) : (
                <DateInput
                    deadline={deadline}
                    onFocusChange={({focused}) => setFocused(focused)}
                    onDateChange={handleDateChange}
                    focused={focused}
                    customInputIcon={<CalenderInputIcon />}
                />
            )}
        </div>
    )
}

ExperimentPeriod.propTypes = {
    onDeadlineChange: PropTypes.func,
    node: PropTypes.object,
    infoText: PropTypes.string,
}

ExperimentPeriod.defaultProps = {
    infoText: '',
    isStarted: false,
    isFinished: false,
}

export default ExperimentPeriod
