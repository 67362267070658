import {assoc, curry, keys, map, reduce, toPairs, values, zipObj} from 'ramda'
import {isObject} from 'ramda-adjunct'
import {snakeToCamel} from './string'

export const key = (obj) => keys(obj)[0]
export const value = (obj) => values(obj)[0]

export const updateValue = curry((key, updater, obj) => {
    const newValue = updater(obj[key])
    return assoc(key, newValue, obj)
})

export const mapKeys_ = curry((fn, obj) => {
    const [_keys, _values] = [keys(obj), values(obj)]
    return zipObj(map(fn, _keys), _values)
})

export const javascriptize = (obj) => {
    return reduce(
        (acc, [key, value]) => {
            return isObject(value)
                ? assoc(snakeToCamel(key), javascriptize(value), acc)
                : assoc(snakeToCamel(key), value, acc)
        },
        {},
        toPairs(obj),
    )
}

export const cascadingObjects = (currentObject, newTagName, newValue) => {
    const newValueArray = newTagName.split('.')

    let tmpObject = currentObject
    for (let cascadingSize = 0; cascadingSize < newValueArray.length; cascadingSize++) {
        if (tmpObject[newValueArray[cascadingSize]] === undefined) {
            tmpObject[newValueArray[cascadingSize]] = {}
        }

        if (cascadingSize === newValueArray.length - 1) {
            tmpObject[newValueArray[cascadingSize]] = newValue
        } else {
            tmpObject = tmpObject[newValueArray[cascadingSize]]
        }
    }
    return currentObject
}