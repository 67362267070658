import {createSelector} from 'redux-starter-kit'
import {SIDEBAR_MODE} from '../const'
import {getNodeChildrenByTimeArea, getTimeAreaIndex, isSF} from '../utils'
import _ from 'lodash'
import {filter, values} from 'ramda'

const strategyMapSelector = (state) => state.strategyMap
const sidebarModeSelector = (state) => state.strategyMap.sidebarMode
const nodeMapSelector = (state) => state.strategyMap.nodeMap

const nodesSelector = createSelector(
    nodeMapSelector,
    nodeMap => values( nodeMap )
)

const sfsSelector = createSelector(
    nodesSelector,
    nodes => filter(node => isSF(node), nodes)
)

const targetsSelector = createSelector(
    sfsSelector,
    sfs => sfs.flatMap( sf => sf['targets']
    )
)

const isEditModeSelector = createSelector(
    sidebarModeSelector,
    sidebarMode => sidebarMode !== SIDEBAR_MODE.CREATE,
)

const currentUserIdSelector = (state) => state.user.pk
const squadMemberSelector = (state) => state.strategyMap.squadMembers

const squadMemberRequestSelector = createSelector(
    currentUserIdSelector,
    squadMemberSelector,
    (me, squadMember) => {
        return (
            squadMember &&
            squadMember.filter((squadMember) => {
                const isPending = !squadMember.is_accepted
                const isMyOwnRequest = squadMember.user.pk === me
                return isPending && isMyOwnRequest
            })
        )
    },
)

const clickedNodesToChildrenSelector = createSelector(strategyMapSelector, (strategyMap) => {
    const {clickedNodes, nodeMap} = strategyMap
    const clickedNodesToChildren = {}

    strategyMap.clickedNodes.forEach((nodeId) => {
        if (clickedNodes.has(nodeId) && nodeMap[nodeId]) {
            clickedNodesToChildren[nodeId] = getNodeChildrenByTimeArea(
                nodeMap[nodeId].children,
                nodeMap,
            )
        }
    })

    return clickedNodesToChildren
})

const sfPerTimeAreaSelector = createSelector(nodeMapSelector, (nodeMap) => {
    const nodes = _.sortBy(values(nodeMap), (o) => o.priority || 9999)
    const sfs = nodes.filter(isSF)
    const sfPerTimeArea = [[], [], []]
    sfs.forEach((node) => {
        const timeAreaIndex = getTimeAreaIndex(node.time_area)
        sfPerTimeArea[timeAreaIndex].push(node.id)
    })
    return sfPerTimeArea
})

export const fieldsToSelectors = {
    isEditMode: isEditModeSelector,
    targets: targetsSelector,
    squadRequest: squadMemberRequestSelector,
    clickedNodesToChildren: clickedNodesToChildrenSelector,
    sfPerTimeArea: sfPerTimeAreaSelector,
}
