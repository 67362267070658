import React from 'react'
import {isMobile} from 'react-device-detect'
import {I18n} from 'react-i18nify'
import {useStrategyMapSlice} from '../strategyMap/hooks/useStrategyMapSlice'
import {ButtonSecondary} from '../core/components/Buttons'
import {showPopupMessage} from '../core/utils/notifications'

const NC = (props) => {
    const {Actions} = useStrategyMapSlice()
    const toggleFulfilledClick = () => {
        if (!props.isReadOnly && !props.node.fulfillable && !props.node.fulfilled) {
            const warning = {
                message: I18n.t('strategyMap.messages.fulfillableTooltipInfo'),
                confirmLabel: I18n.t('strategyMap.messages.ok'),
            }
            showPopupMessage(warning)
            return false
        }
        const updatedNode = {
            ...props.node,
            fulfilled: !props.node.fulfilled,
            fulfillable: !props.node.fulfillable,
        }
        Actions.updateNode(updatedNode)
    }

    let fulfill
    if (isMobile) {
        fulfill = <div></div>
    } else if (!props.isEditMode) {
        fulfill = ''
    } else {
        const isDisabled = !props.isReadOnly && !props.node.fulfillable && !props.node.fulfilled
        const buttonLabel = props.node.fulfilled
            ? I18n.t('strategyMap.nodes.markUnfulfilled')
            : I18n.t('strategyMap.nodes.markFulfilled')
        fulfill = (
            <ButtonSecondary
                fullWidth
                disabled={isDisabled}
                data-testid={'nc-fulfill-button'}
                onClick={toggleFulfilledClick}
            >
                {buttonLabel}
            </ButtonSecondary>
        )
    }
    return <div className={'nc'}>{fulfill}</div>
}

export default NC
