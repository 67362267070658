import React from 'react'
import Checkbox from '../capture/signifier/Checkbox'
import {Table} from 'react-bootstrap'
import NewUser from './NewUser'
import {I18n} from 'react-i18nify'
import {ASCENDING, useOrdering} from '../core/hooks/useOrdering'
import styled from 'styled-components'
import {Flex} from '../core/ui/Flex'
import {useToggle} from '../core/hooks/useToggle'
import {useCheckList} from '../core/hooks/useCheckList'
import sortReleaseIcon from "../img/icons/ic-sort-release.svg"
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import { useDemographicsSlice } from '../core/hooks/useDemographicsSlice'
import SelectWrapper from '../core/components/SelectWrapper'
import { useCaptureSlice } from '../capture/hooks/useCaptureSlice'
import _ from 'lodash'
import moment from 'moment'
import { Div } from '../core/ui/Div'

const FlexWithPointer = styled(Flex)`
    justify-content: space-between;
    cursor: pointer;
`

const RemoveOrdering = (props) => {
    return (
        <Div onClick={props.onRemoveOrdering} width={'12px'} padding={'2px'} data-testid={props.testId}>
            {props.show && <img alt="" src={sortReleaseIcon} />}
        </Div>
    )
}

const OrderingDirection = (props) => {
    if (!props.direction) return null
    const iconDirection = props.direction === ASCENDING ? 'down' : 'up'

    return (
        <Div onClick={props.onToggleOrdering} padding={'2px'}>
            <span data-testid={`${props.testId}-${iconDirection}`} className="material-icons">{`arrow_drop_${iconDirection}`}</span>
        </Div>
    )
}

export const Ordering = (props) => {
    const fieldIndex = props.getFieldIndex(props.field)
    const [on, toggle] = useToggle()

    return (
        <FlexWithPointer
            onMouseOver={() => toggle(true)}
            onMouseLeave={() => toggle(false)}
        >
            <div onClick={() => props.stackOrdering(props.field)} data-testid={"ordering-"+props.field}>{props.title}</div>
            <Flex>
                <Div
                    padding='5px 2px'
                    fontSize='11px'
                >
                    {props.shouldShowPrecedence(props.field) && fieldIndex + 1}
                </Div>
                {props.fieldDirection(props.field) && (
                    <RemoveOrdering
                        show={on}
                        testId={'remove-odering-'+props.field}
                        onRemoveOrdering={() => props.removeOrdering(props.field)}
                    />
                )}
                <OrderingDirection
                    testId={'ordering-direction-'+props.field}
                    onToggleOrdering={() => props.toggleOrdering(props.field)}
                    direction={props.fieldDirection(props.field)}
                />
            </Flex>
        </FlexWithPointer>
    )
}

export const DepartmentSelector = (props) => {
    const {department} = useDemographicsSlice();
    const {Actions: CaptureActions} = useCaptureSlice()

    const handleDepartmentChange = (user, departmentPk) => {
        const userDemographics = user.demographics ? _.cloneDeep(user.demographics) : {}
        userDemographics.department = departmentPk
        userDemographics.user = user.pk
        CaptureActions.updateUserProfileDemographics(userDemographics)
    }

    const getLabelNameForDropdown = (dept) => {
        const archivedSuffix = dept.archived ? 
                                ` (${I18n.t('app.labels.archived')})` :
                                ""
        return dept.name + archivedSuffix
    }

    const deparmentOptions = department ? 
                                department
                                    .map(d => ({value: d.pk, label: getLabelNameForDropdown(d)})) : 
                                [];

    const selectOptions = [
        {value: null, label: "---"},
        ...deparmentOptions
    ]

    const defaultValue = selectOptions.find( (dept) => 
            dept.value === props.departmentPk ) || selectOptions[0]

    return <SelectWrapper
        onChange={ (item) => handleDepartmentChange(props.user, item.value) }
        defaultValue={ defaultValue }
        options={ selectOptions }
        styles={{ input: () => ( { width: 150 } ) }}
    />
}

const checkifDateIsWithinThisMonth = (date) => moment(date).isSame(new Date(), 'month');

export const UserTable = (props) => {
    const [orderedUsers, orderingFns] = useOrdering(props.users);
    const {checkedItems, allChecked, handleCheckboxClick, handleAllCheckBoxClick} = useCheckList(
        orderedUsers,
        props.setSelectedUsers
    );
    const {features} = useCoreSlice();

    const table = (user) => ([
        {
            visible: true
        },
        {
            title: I18n.t('billing.Label.EmailAddress'),
            field: 'email',
            value: user && user.email,
            visible: true
        },
        {
            title: I18n.t('billing.Label.FirstName'),
            field: 'first_name',
            value: user && user.first_name,
            visible: true
        },
        {
            title: I18n.t('billing.Label.LastName'),
            field: 'last_name',
            value: user && user.last_name,
            visible: true
        },
        {
            title: I18n.t('billing.Label.Roles.IsAdmin'),
            field: 'is_admin',
            value: user
                && user.is_admin
                ? I18n.t('strategyMap.messages.yes')
                : I18n.t('strategyMap.messages.no'),
            visible: true
        },
        {
            title: <>{ I18n.t('billing.Label.Roles.IsAdmin') }<br />(Leadership)</>,
            field: 'is_admin_for_leadership',
            value: user
                && user.is_admin_for_leadership
                ? I18n.t('strategyMap.messages.yes')
                : I18n.t('strategyMap.messages.no'),
            visible: features["leadership.full"]
        },
        {
            title: I18n.t('billing.Label.Roles.IsStrategist'),
            field: 'is_strategist',
            value: user
                && user.is_strategist
                ? I18n.t('strategyMap.messages.yes')
                : I18n.t('strategyMap.messages.no'),
            visible: features["strategymap"]
        },
        {
            title: I18n.t('billing.Label.Roles.IsAnalyst'),
            field: 'is_analyst',
            value: user
                && user.is_analyst
                ? I18n.t('strategyMap.messages.yes')
                : I18n.t('strategyMap.messages.no'),
            visible: true
        },
        {
            title: I18n.t('billing.Label.Status'),
            field: 'is_active',
            value: user
                && user.is_active
                ? I18n.t('billing.Label.Enabled')
                : I18n.t('billing.Label.Disabled'),
            visible: true
        },
        {
            title: I18n.t('billing.Label.ActiveThisMonth'),
            field: 'last_active_interaction',
            value: user
                && user.last_active_interaction 
                && checkifDateIsWithinThisMonth(user.last_active_interaction)
                ? I18n.t('strategyMap.messages.yes')
                : I18n.t('strategyMap.messages.no'),
            visible: true
        },
        {
            title: I18n.t('billing.demographics.department'),
            field: 'demographics.department',
            value: user && user?.demographics?.department,
            visible: true
        }
    ]);

    return (
        <Table style={{tableLayout: 'fixed'}}>
            <thead>
                <tr>
                    {
                        table({})
                            .filter( a => a.visible )
                            .map( (column,i) =>
                            <th key={i} style={{wordWrap: 'break-word', width: i === 0 ? '40px':''}}>{
                                i === 0
                                ? <Checkbox onClick={handleAllCheckBoxClick} checked={allChecked} />
                                : <Ordering
                                    title={column.title}
                                    field={column.field}
                                    {...orderingFns}
                                />
                            }</th>
                        )
                    }
                </tr>
            </thead>
            <tbody data-testid={"ubody"}>
                { props.addUser && <NewUser /> }
                {
                    orderedUsers.map( (user, rowID) =>
                        <tr key={user.pk} data-testid={"userrow"}>
                            {
                                table(user)
                                    .filter( a => a.visible )
                                    .map( (column,columnID) =>
                                    <td key={columnID} style={{wordWrap: 'break-word'}}>
                                        {
                                            columnID === 0
                                            ? <Checkbox
                                                    testId={ "userrow-" + user.pk }
                                                    onClick={ (_) => handleCheckboxClick( rowID ) }
                                                    checked={ !! checkedItems[ rowID ] }
                                                />
                                            : 
                                                column.field === "demographics.department" ? 
                                                    <DepartmentSelector user={user} departmentPk={column.value} /> : 
                                                    column.value
                                        }
                                    </td>
                                )
                            }
                        </tr>
                )}
            </tbody>
        </Table>
    )
}

export default UserTable
