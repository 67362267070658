import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {I18n} from 'react-i18nify'
import TriangleComponent from './TriangleComponent'
import Checkbox from '../Checkbox'
import {BLACK, MAGENTA} from '../../../core/ui/_colors'
import {Body, Footer, Header, TriangleBox, TriangleContainerStyled} from './Triangle.styles'
import ErrorOutput from '../ErrorOutput'
import {Div} from '../../../core/ui/Div'
import Triad from '../../../Charts/calculationsUtils/triad'

const TrianglePanel = (props) => {
    const [checked, setChecked] = useState(false)
    const [value, setValue] = useState({})
    const [validationErrors, setValidationErrors] = useState(null)
    const validateInput = () => {
        if (props.data.required) {
            if (value.x === null && value.y === null && !checked) {
                setValidationErrors({
                    required: I18n.t('capture.Form.Status.Validation.SectionRequired'),
                })
                props.onError && props.onError(props.fieldName)
            } else {
                setValidationErrors(null)
            }
        } else {
            setValidationErrors(null)
        }
    }

    useEffect(() => {
        if (props.shouldValidate) {
            validateInput()
        }
    }, [props.shouldValidate])

    useEffect(() => {
        if (props.shouldResetPosition) {
            setChecked(false)
            props.onPositionReset()
        }
    }, [props.shouldResetPosition])

    const toggleCheckbox = () => {
        setChecked(!checked)
        updatePoint(
            {left: Triad.DEFAULT_WEIGHT, right: Triad.DEFAULT_WEIGHT, top: Triad.DEFAULT_WEIGHT},
            {x: null, y: null},
            !checked,
        )
    }

    const updatePoint = (propertySpecificWeights, draggableCoordinates, not_applicable) => {
        const values = {
            triangle: props.data.id,
            left_property_weight: propertySpecificWeights.left,
            right_property_weight: propertySpecificWeights.right,
            top_property_weight: propertySpecificWeights.top,
            x: draggableCoordinates.x,
            y: draggableCoordinates.y,
            not_applicable: not_applicable,
            labels: {...props.data.labels, header: props.data.header},
        }
        setValue(values)
        setValidationErrors(null)
        props.onChange && props.onChange(values)
    }

    const triangleClasses = classNames('triangle-container', 'triangle-type-' + props.data.id)

    useEffect(() => {
        if (validationErrors) validateInput()
    }, [I18n._localeKey])

    return (
        <TriangleContainerStyled
            md={props.fullWidth ? 12 : 6}
            lg={props.fullWidth ? 12 : 4}
            fullWidth={props.fullWidth}
            sm={12}
            className={triangleClasses}
            data-testid="TrianglePanel-container"
        >
            <TriangleBox default className={props.error || validationErrors ? 'field-error' : ''}>
                <Div paddingLeft={'20px'}>
                    {(props.error || validationErrors) && (
                        <ErrorOutput errors={props.error || validationErrors} />
                    )}
                </Div>

                <Header
                    data-testid={'trianglebox-header'}
                    titleColour={props.error ? MAGENTA : BLACK}
                >
                    {props.data.header}
                </Header>
                <Body>
                    <TriangleComponent
                        disabled={checked}
                        fullWidth={props.fullWidth}
                        topLabel={props.data.labels.top}
                        leftLabel={props.data.labels.left}
                        rightLabel={props.data.labels.right}
                        imageName={props.data.imageName}
                        imageUrl={props.data.imageUrl}
                        shouldResetPosition={props.shouldResetPosition}
                        updatePoint={updatePoint}
                    />
                </Body>
                <Footer data-testid={'trianglebox-footer'}>
                    <Checkbox
                        testId={'triangles'}
                        label={props.data.labels.na}
                        checked={checked}
                        onClick={toggleCheckbox}
                    />
                </Footer>
            </TriangleBox>
        </TriangleContainerStyled>
    )
}

TrianglePanel.propTypes = {
    onChange: PropTypes.func,
    onPositionReset: PropTypes.func,
    shouldResetPosition: PropTypes.bool,
    shouldValidate: PropTypes.bool,
    onError: PropTypes.func,
    fullWidth: PropTypes.bool,
    data: PropTypes.object,
}

export default TrianglePanel
