import settings from '../config/settings'
import axios from 'axios'
import {getRequestHeader} from '../core/utils/network'

export default class Api {
    static fetchDecisions = (page) =>
        axios.get(`${settings.apiBaseURL}/decisions/?page=` + page, getRequestHeader())
    static logPageView = (data) =>
        axios.post(`${settings.apiBaseURL}/users/interactions/`, data, getRequestHeader())
}
