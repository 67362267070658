import React, {useRef, useState} from 'react'
import {useReactToPrint} from 'react-to-print'
import styled from 'styled-components'
import NodePrint, {modes} from './sidebar/NodePrint'
import RadioButton from '../../core/ui/RadioButton'
import {Flex} from '../../core/ui/Flex'
import {I18n} from 'react-i18nify'
import {Modal} from '../../core/ui/modal/Modal'

export const OptionsText = styled.div`
    width: 352px;
    font-size: 16px;
`
export const OptionText = styled.div`
    margin-left: 10px;
    cursor: pointer;
`
export const OptionRow = styled(Flex)`
    margin: 10px;
`

const PrintPreDialog = (props) => {
    const printerRef = useRef()
    const [printMode, setPrintMode] = useState(modes.SINGLE_NODE_LARGE)

    const handlePrint = useReactToPrint({
        content: () => printerRef.current,
    })

    return (
        <Modal 
            isOpen={props.isOpen}
            title={I18n.t('strategyMap.printing.printThisNode')}
            footer={{
                confirmText: I18n.t('strategyMap.printing.goToPrintDialogue'),
                onConfirm: handlePrint,
                cancelText: I18n.t('sidebar.sharing.dialog.buttons.cancel'),
                onCancel: props.onClose
            }}
        >
            <div style={{display: 'none'}}>
                <NodePrint mode={printMode} ref={printerRef} />
            </div>
            <OptionsText>
                {I18n.t('strategyMap.printing.followingOptionsToPrintThisNode')}
            </OptionsText>

			{Object.keys(modes).map((mode) => {
			    return (<OptionRow key={mode}>
                    <RadioButton
                        enabled={mode === printMode}
                        onClick={() => setPrintMode(mode)}
                    />
                    <OptionText
                        onClick={() => setPrintMode(mode)}
                    >
                        {I18n.t('strategyMap.printing.' + mode)}
                    </OptionText>
                </OptionRow>)
            })}
        </Modal>
    )
}

export default PrintPreDialog