import React from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {RESTRICTIONS} from '../../core/const'
import ChampionItem from './ChampionItem'
import AddChampion from './AddChampion'
import {useUserSlice} from '../hooks/useUserSlice'
import {userCanAddOrDeleteChampion} from "../utils";

const Champion = (props) => {
    const {Actions, nodeMap} = useStrategyMapSlice();
    const currentActiveUser = useUserSlice();
    const championsData = props.node.champions_data || [];
    const totalChampions = championsData.length;

    const updateChampions = (champions) => {
        const node = {
            ...props.node,
            champions_data: champions,
            champions: champions.map( ( champion ) => champion.pk )
        };
        if( props.isEditMode ){
            Actions.updateNode( node );
        } else {
            Actions.updatePendingNode( node );
        }
    }

    const allowChampionsAddition =
            totalChampions === 0
            ||
                totalChampions < RESTRICTIONS.StrategyMap.MAX_CHAMPION_ALLOWED
                && userCanAddOrDeleteChampion( currentActiveUser, props.node, nodeMap );

    return (
        <div>
            {
                championsData.map( ( champion ) =>
                    <ChampionItem
                        key={ champion.pk }
                        champion={ champion }
                        isAbleToEdit={ props.isAbleToEdit }
                        node={ props.node }
                        updateChampions={ updateChampions }
                    />
                )
            }
            {
                props.isAbleToEdit
                && allowChampionsAddition
                &&
                    <AddChampion node={props.node} updateChampions={updateChampions} />
            }
        </div>
    )
}

export default Champion
