import React, {useEffect, useState} from "react";
import Miro, {MIRO_IMPORT_TYPES} from "./MiroIntegration/Miro";
import {Header} from "../../ReactTourStories/Stories";
import Modal from "../../core/components/Modal";
import {ButtonPrimary, ButtonSecondary} from "../../core/components/Buttons";
import { useDemographicsSlice } from "../../core/hooks/useDemographicsSlice";
import { activeDemographics } from "../../capture/signifier/demographics/UserDemographicsList";
import SelectWrapper from "../../core/components/SelectWrapper";
import { getTranslatedDemographicsLabel, ORDERED_DEMOGRAPHICS_TYPE } from "../../core/utils/demographics";
import I18n from "react-i18nify/build/lib/I18n";
import { BALL_DIMENSION, TRIANGLE_DIMENSION } from "../../capture/signifier/triangle/TriangleComponent";
import { ModalFooterContainer, ModalFooterRight } from "../../core/components/Modal.styles";
import { decodeEntity, removeTags } from "../../core/utils/string";
import RadioButton from "../../core/ui/RadioButton";
import { OptionRow, OptionText } from "../../strategyMap/components/PrintPreDialog";
import {BarLoader} from 'react-spinners';
import { LoaderContainer } from "../../strategyMap/components/Modal/SquadModal";
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import {displayToastMessage} from "../../core/utils/notifications";
import {MIRO_CHECKS, MIRO_VERSIONS} from "./MiroIntegration/OrgScan";
import Triad from "../../Charts/calculationsUtils/triad";
import Point from "../../Charts/calculationsUtils/point";

const MiroImportDialog = ({start, handleModalDisplay, handleDecisionsImport, handleSMImport, param}) => {

    const defaultDemographicList = {
        city: null,
        country: null,
        department: null,
        role: null,
        years: null
    };
    
    const {Actions} = useCoreSlice();
    const {...demographics} = useDemographicsSlice();
    const [selectAbleDemographics, setSelectAbleDemographics] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [frames, setFrames] = useState(null);
    const [page, setPage] = useState(0);
    const [miroInstance, setMiroInstance] = useState( undefined );
    const [miroBoardInstance, setMiroBoardInstance] = useState( undefined );
    const [miroOrgScanInstance, setMiroOrgScanInstance] = useState( undefined );
    const [miroStrategyMapInstance, setMiroStrategyMapInstance] = useState( undefined );
    const [miroBoard, setMiroBoard] = useState( undefined );
    const [miroImportType, setMiroImportType] = useState( MIRO_IMPORT_TYPES.OrgScan );
    const [miroVersion, setMiroVersion] = useState( MIRO_VERSIONS.DEFAULT );
    const [data, setData] = useState( undefined );
    const [miroParam, setMiroParam] = useState('');
    const [demographicsList, setDemographicsList] = useState(defaultDemographicList);

    useEffect(() => {
        if(frames !== null)
            setShowLoader(false)
    }, [frames])

    useEffect(() => {
        if ((demographics.city || demographics.department || demographics.country || demographics.role || demographics.years)) {
            setSelectAbleDemographics(activeDemographics(demographics))
        }
    }, [demographics.city, demographics.department, demographics.country, demographics.role, demographics.years]);

    useEffect( () => {
        if( param && param.client_id ){
            new Miro("").getAuthenticationTokenAxios( param.client_id, param.code)
                .then(response => {
                    const instance = new Miro( response.data.access_token );
                    instance
                        .login()
                        .then( (result) => {
                            setMiroInstance( result ? instance : undefined );
                            setShowLoader(false);
                        });

                })
                .catch(a => displayToastMessage('error', a, I18n.t("app.toastMessages.errorTitleRelated").replace("%appname", "Miro")));
        }
    }, [miroParam]);

    if( param && Object.keys( param ).length ) {
        if( param.code !== miroParam ) {
            if( window.location.search.length ) {
                setMiroParam( param );
                window.history.pushState({}, null, '/');
            }
        }
    }

    useEffect( () => {
        if( miroInstance )
            setData( miroInstance.getBoards() );
    }, [miroInstance] );

    const getDecisionObject = () => {
        
        return {
            experiments: [],
            hide_description: true,
            allow_marketing_contact: false,
            title: "",
            description: "Import of miro board: " + miroBoard.name,
            demographics: demographicsList,
            type: "notsure",// 'notsure', 'tactical', 'strategic'
            period: "notsure",// 'rightaway', 'hour', 'day', 'week', 'month', 'forever', 'notsure'
            communication_method: "nocommunication",// 'oneonone', 'group', 'tool', 'announcement', 'noformalconversation', 'nocommunication'
            decision_owner: "notsure",// 'me', 'colleague', 'team', 'manager', 'external', 'groupofpeople', 'notsure'
            mood: "notsure",// 'positive', 'neutral', 'negative', 'notsure'
            frequency: "notsure",// 'allthetime', 'often', 'nowandthen', 'rarely', 'notsure'
            triangles: Array.from({length: 6}, (e, i) => ({
                triangle: i + 1,
                x: 0,
                y: 0,
                left_property_weight: Triad.DEFAULT_WEIGHT,
                right_property_weight: Triad.DEFAULT_WEIGHT,
                top_property_weight: Triad.DEFAULT_WEIGHT,
                not_applicable: false,
            })),
            cultural_profile: [
                { name: "leader-type", x: 0, y: 0 },
                { name: "effectiveness", x: 0, y: 0 },
                { name: "orientation", x: 0, y: 0 },
                { name: "value-drivers", x: 0, y: 0 }
            ]
        };
    }

    const getCornerWeights = (position) => {
        const normalisedPosition = {
            x: position.x + BALL_DIMENSION.leftLengthToCenter,
            y: TRIANGLE_DIMENSION.height - position.y - BALL_DIMENSION.topLengthToCenter,
        };

        const triad = new Triad();
        const point = new Point(normalisedPosition.x, normalisedPosition.y);
        const [topPropertyWeight, rightPropertyWeight, leftPropertyWeight] = triad.getWeights(point);
        
        return {
            leftPropertyWeight: leftPropertyWeight,
            rightPropertyWeight: rightPropertyWeight,
            topPropertyWeight: topPropertyWeight
        };
    }

    const buildDecisionsData = () => {
        const decisionsData = {};
        
        const orgscanDataSet = miroOrgScanInstance.getTransformedData();
        for (const orgscanData in orgscanDataSet) {
            for (const orgscanDataItem in orgscanDataSet[orgscanData]) {
                const user = removeTags(decodeEntity(orgscanDataItem));
                if (decisionsData[user] === undefined) {
                    decisionsData[user] = getDecisionObject();
                }
                decisionsData[user].title = user === "" ? "-" : user;
                switch (orgscanData) {
                    case 'Mood':
                        decisionsData[user].mood = orgscanDataSet[orgscanData][orgscanDataItem];
                        break;
                    case "Decision Speed":
                        decisionsData[user].period = orgscanDataSet[orgscanData][orgscanDataItem];
                        break;
                    case "Decision Maker":
                        decisionsData[user].decision_owner = orgscanDataSet[orgscanData][orgscanDataItem];
                        break;
                    case 'Leadership':
                        decisionsData[user].cultural_profile[0].x = orgscanDataSet[orgscanData][orgscanDataItem].x;
                        decisionsData[user].cultural_profile[0].y = orgscanDataSet[orgscanData][orgscanDataItem].y;
                        break;
                    case 'Effectiveness':
                        decisionsData[user].cultural_profile[1].x = orgscanDataSet[orgscanData][orgscanDataItem].x;
                        decisionsData[user].cultural_profile[1].y = orgscanDataSet[orgscanData][orgscanDataItem].y;
                        break;
                    case 'Orientation':
                        decisionsData[user].cultural_profile[2].x = orgscanDataSet[orgscanData][orgscanDataItem].x;
                        decisionsData[user].cultural_profile[2].y = orgscanDataSet[orgscanData][orgscanDataItem].y;
                        break;
                    case 'Value Drivers':
                        decisionsData[user].cultural_profile[3].x = orgscanDataSet[orgscanData][orgscanDataItem].x;
                        decisionsData[user].cultural_profile[3].y = orgscanDataSet[orgscanData][orgscanDataItem].y;
                        break;
                    case 'T1':
                    case 'T2':
                    case 'T3':
                    case 'T4':
                    case 'T5':
                    case 'T6':
                        const index = parseInt(orgscanData[1]) - 1
                        const weights = getCornerWeights(orgscanDataSet[orgscanData][orgscanDataItem])
                        decisionsData[user].triangles[index].x = orgscanDataSet[orgscanData][orgscanDataItem].x;
                        decisionsData[user].triangles[index].y = orgscanDataSet[orgscanData][orgscanDataItem].y;
                        decisionsData[user].triangles[index].left_property_weight = weights.leftPropertyWeight;
                        decisionsData[user].triangles[index].right_property_weight = weights.rightPropertyWeight;
                        decisionsData[user].triangles[index].top_property_weight = weights.topPropertyWeight;
                        decisionsData[user].triangles[index].not_applicable = orgscanDataSet[orgscanData][orgscanDataItem].notApplicable;
                        break;
                }
            }
        }
        
        return Object.values(decisionsData);
    }

    const demographicsSelector = () => {
        return ORDERED_DEMOGRAPHICS_TYPE.filter((demographicType) =>
            selectAbleDemographics.hasOwnProperty(demographicType) && !!selectAbleDemographics[demographicType],
        ).map((demographicType) => {
            const options = selectAbleDemographics[demographicType].map((item) => ({
                value: item.pk,
                label: getTranslatedDemographicsLabel(item)
            }));
            return <>
                <b>{I18n.t('capture.Panel.Topic.' + demographicType)}</b><br />
                <SelectWrapper
                    key={demographicType}
                    options={options}
                    onChange={(e) => setDemographicsList({...demographicsList, [demographicType]: e.value})}
                />
            </>;
        })
    }

    const getEmptyDataMessage = (dataType) => {
        if (!data || !data[dataType]) {
            return <div>{I18n.t('sidebar.demoTools.miro.noDataToDisplay')}</div>;
        }

        if (dataType === MIRO_CHECKS.Summary || dataType === MIRO_CHECKS.MissingPoints) {
            return Object.keys(data[dataType]).length === 0
            ? <div>{I18n.t('sidebar.demoTools.miro.noDataToDisplay')}</div>
            : null;
        }

        if ([MIRO_CHECKS.DuplicatedPoints, MIRO_CHECKS.MisplacedPoints, MIRO_CHECKS.InconsistentMOODS, MIRO_CHECKS.NeutralMOODS].includes(dataType)) {
            const isDataEmpty = Object.values(data[dataType]).flat().length === 0;
            return isDataEmpty ? <div>{I18n.t('sidebar.demoTools.miro.noDataToDisplay')}</div> : null;
        }

        return null;
    };

    const pages = [
        {
            header: I18n.t('sidebar.demoTools.miro.chooseBoardToContinue'),
            run: () => {},
            visible: () => true,
            body: 
                <div style={{margin:'2px'}}>{
                    data && data.length && 
                        <SelectWrapper
                            onChange={ (item) => setMiroBoard(item.value) }
                            options={ data.map( board => ({value: board, label: board.name}) ) }
                        />
                }</div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.Type Of Import'),
            visible: () => true,
            run: () => {},
            body:
                <div>
                    {
                        Object.keys( MIRO_IMPORT_TYPES )
                            .filter( a => a !== 'DEFAULT')
                            .map( typ =>
                            <OptionRow key={typ}>
                                <RadioButton
                                    enabled={ typ === MIRO_IMPORT_TYPES[miroImportType] }
                                    onClick={ () => setMiroImportType( MIRO_IMPORT_TYPES[typ] ) }
                                />
                                <OptionText
                                    onClick={ () => setMiroImportType( MIRO_IMPORT_TYPES[typ] ) }
                                >
                                    { typ } { MIRO_IMPORT_TYPES[typ] === MIRO_IMPORT_TYPES[""] ? '(' + I18n.t("sidebar.demoTools.miro.default") + ')' : ''}
                                </OptionText>
                            </OptionRow>
                        )
                    }
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.frames'),
            visible: () => true,
            run: () => {
                const boardInstance = miroInstance.board( miroBoard.id );
                boardInstance.load().then( () => {
                    setMiroBoardInstance( boardInstance );
                    setFrames(boardInstance.getFrames())
                } );
                if(!frames)
                    setShowLoader(true)
            },
            body:
                <div>
                    {frames?.map((frame, index) => (
                    <div key={index}>
                        <b>{frame.data.title}</b> {I18n.t('sidebar.demoTools.miro.with')} <b>{miroBoardInstance.frame(frame.id).length}</b> {I18n.t('sidebar.demoTools.miro.records')}
                    </div>
                    ))}
                    {frames?.length === 0 && <div>No frames found</div>}
                    {showLoader && (
                    <LoaderContainer top={'50px'}>
                        <BarLoader />
                    </LoaderContainer>
                    )}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.Errors'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.StrategyMap,
            run: () => {
                const miroSM = miroBoardInstance.StrategyMap()
                miroSM.validate()
                setMiroStrategyMapInstance( miroSM );
            },
            body:
                <div>
                    {miroStrategyMapInstance?.errors?.length === 0 && <div>No errors found, you can import</div>}
                    <ul>{miroStrategyMapInstance?.errors?.length > 0 && miroStrategyMapInstance.errors.map( (error, idx) => <li key={idx}>{error}</li>)}</ul>
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.Version'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {},
            body:
                <div>
                    {
                        Object.keys( MIRO_VERSIONS )
                            .filter( a => a !== 'DEFAULT')
                            .map( version =>
                            <OptionRow key={version}>
                                <RadioButton
                                    enabled={ miroVersion === MIRO_VERSIONS[version] }
                                    onClick={ () => setMiroVersion( MIRO_VERSIONS[version] ) }
                                />
                                <OptionText
                                    onClick={ () => setMiroVersion( MIRO_VERSIONS[version] ) }
                                >
                                    { version } { MIRO_VERSIONS[version] === MIRO_VERSIONS.DEFAULT ? '(' + I18n.t("sidebar.demoTools.miro.default") + ')' : ''}
                                </OptionText>
                            </OptionRow>
                        )
                    }
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.summary'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {
                const OrgScanInstance = miroBoardInstance.OrgScan().setVersion( miroVersion );
                setData( OrgScanInstance.validate() );
                setMiroOrgScanInstance( OrgScanInstance );
            },
            body:
                <div>{
                    data
                    && data[MIRO_CHECKS.Summary]
                    && Object.keys( data[MIRO_CHECKS.Summary] )
                        .map( (key,idx) =>
                            <div key={idx}>
                                <div>
                                    <b style={{float: 'left'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</b>
                                    <div style={{float: 'right'}}>{ removeTags(data[MIRO_CHECKS.Summary][key]) }</div>
                                </div>
                                <br />
                            </div> ) }
                    {getEmptyDataMessage(MIRO_CHECKS.Summary)}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.MissingFrames'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {},
            body:
                <div>{
                    data
                    && data[MIRO_CHECKS.MissingFrames]
                    && Object.keys( data[MIRO_CHECKS.MissingFrames] )
                        .map( (key,idx) =>
                            <div key={idx}>
                                <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                <ul>{ data[MIRO_CHECKS.MissingFrames][key].sort( (a,b) => a.localeCompare(b) ).map( (a,i) => <li key={i}>{removeTags(a)}</li> ) }</ul>
                            </div> ) }
                    {getEmptyDataMessage(MIRO_CHECKS.MissingFrames)}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.missingStickyNoteOnFrames'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {},
            body:
                <div>
                    {
                        data
                        && data[MIRO_CHECKS.MissingPoints]
                        && <i>{ I18n.t("sidebar.demoTools.miro.missingStickyNoteOnFramesHint") }</i>
                    }
                    <div style={{marginTop: 20}}>
                    {
                        data
                        && data[MIRO_CHECKS.MissingPoints]
                        && Object.keys( data[MIRO_CHECKS.MissingPoints] )
                            .map( (key,idx) =>
                                <div key={idx}>
                                    <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                    <ul>{ data[MIRO_CHECKS.MissingPoints][key].sort( (a,b) => a.localeCompare(b) ).map( (a,i) => <li key={i}>{removeTags(a)}</li> ) }</ul>
                                </div> )
                    }
                    </div>
                    { getEmptyDataMessage(MIRO_CHECKS.MissingPoints) }
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.duplicatedStickyNoteOnFrames'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {},
            body:
                <div>{
                    data
                    && data[MIRO_CHECKS.DuplicatedPoints]
                    && Object.keys( data[MIRO_CHECKS.DuplicatedPoints] )
                        .filter( key => data[MIRO_CHECKS.DuplicatedPoints][key].length )
                        .map( (key,idx) =>
                            <div key={idx}>
                                <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                <ul>{ data[MIRO_CHECKS.DuplicatedPoints][key].sort( (a,b) => a.localeCompare(b) ).map( (a,i) => <li key={i}>{removeTags(a)}</li> ) }</ul>
                            </div> ) }
                    {getEmptyDataMessage(MIRO_CHECKS.DuplicatedPoints)}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.stickyNoteOutsideOfDesignatedAreas'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {},
            body:
                <div>{
                    data
                    && data[MIRO_CHECKS.MisplacedPoints]
                    && Object.keys( data[MIRO_CHECKS.MisplacedPoints] )
                        .sort()
                        .filter( key => data[MIRO_CHECKS.MisplacedPoints][key].length )
                        .map( (key,idx) =>
                            <div key={idx}>
                                <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                <ul>{ data[MIRO_CHECKS.MisplacedPoints][key].sort( (a,b) => a.localeCompare(b) ).map( (a,i) => <li key={i}>{removeTags(a)}</li> ) }</ul>
                            </div> ) }
                    {getEmptyDataMessage(MIRO_CHECKS.MisplacedPoints)}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.differentEmotesOnAStickyNote'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => {},
            body:
                <div>{
                    data
                    && data[MIRO_CHECKS.InconsistentMOODS]
                    && Object.keys( data[MIRO_CHECKS.InconsistentMOODS] )
                        .filter( key => data[MIRO_CHECKS.InconsistentMOODS][key].length )
                        .map( (key,idx) =>
                            <div key={idx}>
                                <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                <ul>{ data[MIRO_CHECKS.InconsistentMOODS][key].sort( (a,b) => a.localeCompare(b) ).map( (a, i) => <li key={i}>{removeTags(a)}</li> ) }</ul>
                            </div> ) }
                    {getEmptyDataMessage(MIRO_CHECKS.InconsistentMOODS)}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.neutralEmotesOnAStickyNote'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => setData( miroBoardInstance.OrgScan().validate() ),
            body:
                <div>{
                    data
                    && data[MIRO_CHECKS.NeutralMOODS]
                    && Object.keys( data[MIRO_CHECKS.NeutralMOODS] )
                        .filter( key => data[MIRO_CHECKS.NeutralMOODS][key].length )
                        .map( (key,idx) =>
                            <div key={idx}>
                                <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                <ul>{ data[MIRO_CHECKS.NeutralMOODS][key].sort( (a,b) => a.localeCompare(b) ).map( (a, i) => <li key={i}>{removeTags(a)}</li> ) }</ul>
                            </div> ) }
                    {getEmptyDataMessage(MIRO_CHECKS.NeutralMOODS)}
                </div>
        },
        {
            header: I18n.t('sidebar.demoTools.miro.getResults'),
            visible: () => miroImportType === MIRO_IMPORT_TYPES.OrgScan,
            run: () => setData( miroOrgScanInstance.getTransformedData() ),
            body:
                <div style={{width: '300px'}}>
                    {demographicsSelector()}
                    {
                        data
                        && Object.keys( data )
                            .map( (key,idx) =>
                                <div key={idx}>
                                    <div style={{fontWeight: 'bold'}}>{ I18n.t('sidebar.demoTools.miro.' + removeTags(key)) }</div>
                                    <ul>{ Object.keys( data[key] ).map( (dataset,i) => <li key={i}>{
                                        removeTags(dataset) + ", " + removeTags(JSON.stringify( data[key][dataset] ))
                                    }</li> ) }</ul>
                                </div> )
                    }
            </div>
        }
    ].filter( a => a.visible() );

    useEffect( () => pages[page].run && pages[page].run(), [page]);

    return (
        <Modal
            isOpen={ start }
            footerContent={
                <ModalFooterContainer>
                    <ModalFooterRight>
                        <ButtonSecondary 
                            type="button" 
                            onClick={ () => {
                                Actions.setMiroParam(undefined);
                                handleModalDisplay(false);
                            }  }>
                            {I18n.t('app.buttons.cancel')}
                        </ButtonSecondary>
                        { page > 0 &&
                            <ButtonSecondary
                                type="button"
                                disabled={showLoader}
                                onClick={ () => !showLoader && setPage( page - 1 )  }
                            >{ I18n.t('app.buttons.back') }</ButtonSecondary> }
                        { page < pages.length - 1 &&
                            <ButtonPrimary
                                type="button"
                                disabled={showLoader || ! miroBoard}
                                onClick={ () => (!showLoader || (page === 1 && miroBoard)) && setPage( page + 1 ) }
                            >{ I18n.t('app.buttons.continue') }</ButtonPrimary> }
                        { page === pages.length - 1 &&
                            <ButtonPrimary
                                type="button"
                                disabled={miroStrategyMapInstance?.errors?.length > 0}
                                onClick={ () => {
                                    if (miroImportType === MIRO_IMPORT_TYPES.StrategyMap) {
                                        handleSMImport( {
                                            nodes: {
                                                ...miroStrategyMapInstance.nodes, 
                                                title: miroBoard.name
                                            }
                                        } )
                                    } else {
                                        handleDecisionsImport( buildDecisionsData() )
                                    }
                                    handleModalDisplay( false )
                                } }
                            >{ I18n.t('app.buttons.import') }</ButtonPrimary> }
                    </ModalFooterRight>
                </ModalFooterContainer>
            }
            >
            <Header>{ pages[page].header }</Header>
            <div style={{minHeight: '300px'}}>{ pages[page].body }</div>
        </Modal>
    );

};

export default MiroImportDialog;