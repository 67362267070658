import styled from 'styled-components'

export const ExperimentSummary = styled.div`
    background-color: #fafafa;
    text-align: center;
    margin: 20px -14px;
    padding: 15px 14px 8px 14px;
    border-bottom: 1px solid #dfe1e6;

    .xAxis {
        display: none;
    }
    p {
        text-align: left;
        font-size: 16px;
        margin-bottom: 5px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .success {
        margin-right: 12px;
    }
`

export const ExpInfo = styled.div`
    width: 100%;
    margin: 0px 0px 10px 0px;
    padding-bottom: 4px;
    .right {
        text-align: right;
        padding-right: 0px;
    }
    .left {
        padding-left: 0px;
        text-align: left;
    }
`


export const ChampionItem = styled.div`
    padding: 5px 0;
`

export const ChampionLabel = styled.span`
    line-height: 32px;
    font-size: ${(props) => (props.email ? '14' : '16')}px;
    letter-spacing: 0.5px;
`

export const VolunteerLabel = styled.span`
    line-height: 30px;
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const ExperimentTeamContainer = styled.span`
    margin-bottom: 15px;
    font-size: 16px;
    width: 100%;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
`

export const TeamListMember = styled.span`
    float: left;
    width: 75%;
    overflow: hidden;
    .date {
        font-size: 14px;
    }
`
