import {browserName, isChrome, isChromium, isEdge, isFirefox, isIE, isSafari} from 'react-device-detect';
import { showPopupMessage } from './notifications';

export const isBraveBrowser = browserName === 'Brave';

export const showBrowserAlert = () => {

    if( isChrome || isBraveBrowser || isChromium || isFirefox || isEdge || isIE || isSafari )
        return false;

    showPopupMessage({
        title: 'Warning',
        message:
            'In order to use ORGANIC agility App features better, we highly recommend you to use Chrome, or Firefox' +
            ' Browsers.',
        confirmLabel: 'OK'
    });
}
