import * as Color from './ChartColor'
import {moodColorMap} from "./ChartColor";

export const cultureSchema = {
    leaderType: Color.ColorsTypes.orange,
    orientation: Color.ColorsTypes.blue,
    effectiveness: Color.ColorsTypes.violet,
    valueDrivers: Color.ColorsTypes.yellow,
}

export const trafficLightColor = {
    green: moodColorMap.positive,
    yellow: moodColorMap.neutral,
    red: moodColorMap.negative,
    gray: moodColorMap.notsure
}

export const moodColor = {
    positive: trafficLightColor.green,
    neutral: trafficLightColor.yellow,
    negative: trafficLightColor.red,
    notsure: trafficLightColor.gray
}

export const tenureColor = {
    week: '#82adcd',
    hour: '#edc959',
    rightaway: '#b87e9e',
    day: '#a9d079',
    month: '#5a93bb',
    forever: '#F8E9BD',
    notsure: '#FF864E',
}

export const tenureColorPie = {
    week: '#C0003C', // red
    hour: '#B0B000', // darker yellow
    rightaway: '#f7d83f', // yellow
    day: '#FF702E', // orange
    month: '#b35919', // light brown
    forever: '#4f270b', // darker brown
    notsure: '#000000', // black
}

export const barSchemaBlue = {
    default: Color.getBarColors(Color.ColorsTypes.blue),
    confirmed: Color.getBarColors(Color.ColorsTypes.yellow),
    notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
}

export const barSchema = {
    period: {
        rightaway: tenureColor.rightaway,
        hour: tenureColor.hour,
        day: tenureColor.day,
        week: tenureColor.week,
        month: tenureColor.month,
        forever: tenureColor.forever,
        notsure: tenureColor.notsure,
    },
    frequency: {
        often: Color.getBarColors(Color.ColorsTypes.blue),
        rarely: Color.getBarColors(Color.ColorsTypes.orange),
        nowandthen: Color.getBarColors(Color.ColorsTypes.yellow),
        allthetime: Color.getBarColors(Color.ColorsTypes.green),
        notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
    },
    mood: {
        positive: moodColorMap.positive,
        neutral: moodColorMap.neutral,
        negative: moodColorMap.negative,
        notsure: moodColorMap.notsure,
    },
    type: {
        tactical: Color.getBarColors(Color.ColorsTypes.green),
        strategic: Color.getBarColors(Color.ColorsTypes.blue),
        notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
    },
    decisionOwner: {
        me: Color.getBarColors(Color.ColorsTypes.darkBlue),
        colleague: Color.getBarColors(Color.ColorsTypes.orange),
        team: Color.getBarColors(Color.ColorsTypes.green),
        manager: Color.getBarColors(Color.ColorsTypes.red),
        external: Color.getBarColors(Color.ColorsTypes.yellow),
        groupofpeople: Color.getBarColors(Color.ColorsTypes.darkOrange),
        notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
    },
    reduceColor: {
        frequency: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.yellow),
                notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkYellow),
                notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
            },
        },
        type: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.green),
                notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkGreen),
                notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
            },
        },
        period: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.violet),
                notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.violetGreen),
                notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
            },
        },
        mood: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.blue),
                notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkBlue),
                notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
            },
        },
        moods: {
            positive: {
                validating: {
                    default: Color.getBarColors(Color.ColorsTypes.dataPointPositive),
                    notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
                },
                confirmed: {
                    default: Color.getBarColors(Color.ColorsTypes.dataPointPositive),
                    notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
                },
            },
            negative: {
                validating: {
                    default: Color.getBarColors(Color.ColorsTypes.dataPointNegative),
                    notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
                },
                confirmed: {
                    default: Color.getBarColors(Color.ColorsTypes.dataPointNegative),
                    notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
                }
            }
        },
        decision_owner: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.blue),
                notsure: Color.getBarColors(Color.ColorsTypes.lightGray),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkBlue),
                notsure: Color.getBarColors(Color.ColorsTypes.darkGray),
            },
        },
        department: {
            validating: {
                headcount: Color.getBarColors(Color.ColorsTypes.darkGray),
                default: Color.getBarColors(Color.ColorsTypes.green),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkGreen),
            },
        },
        city: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.green),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkGreen),
            },
        },
        country: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.green),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkGreen),
            },
        },
        role: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.green),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkGreen),
            },
        },
        years: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.green),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkGreen),
            },
        },
    },
}

export const cultureSchemaLabel = {
    reduceColor: {
        leaderType: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.orange),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkOrange),
            },
        },
        orientation: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.blue),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkBlue),
            },
        },
        effectiveness: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.violet),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.violet),
            },
        },
        valueDrivers: {
            validating: {
                default: Color.getBarColors(Color.ColorsTypes.yellow),
            },
            confirmed: {
                default: Color.getBarColors(Color.ColorsTypes.darkYellow),
            },
        },
    },
}
