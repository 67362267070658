import React from 'react';
import AppSwitch from "./AppSwitch";
import {BackNavIcon, PageHeader} from "../Navbar.styles";
import {useHistory} from "../../../hooks/useHistory";
import { useStrategyMapSlice } from '../../../../strategyMap/hooks/useStrategyMapSlice';
import { isMobile } from 'react-device-detect';
import {NavbarBrand, NavItemIntern} from "../../BootstrapWrapper";
import {Image} from "react-bootstrap";
import organicAgilityLogo from "../../../../img/organic_agility_logo.png";
import {isLoggedIn} from "../../../utils/auth";

const FirstIcon = ({onClick}) => {
    const {experimentCanvasVisible} = useStrategyMapSlice();
    const {isDashboardPage, isStrategyMapPage} = useHistory();

    const isOnStrategyMapBoard = isStrategyMapPage && ! experimentCanvasVisible;
    const showAppSwitch = isDashboardPage || isOnStrategyMapBoard;
    const showBackButton = isLoggedIn() && ! isOnStrategyMapBoard;

    if( showAppSwitch )
        return <AppSwitch />

    if( showBackButton )
        return <NavbarBrand className="back" onClick={onClick}>
            <NavItemIntern>
                <BackNavIcon className="material-icons-outlined">chevron_left</BackNavIcon>
            </NavItemIntern>
        </NavbarBrand>

    return <NavbarBrand>
            <NavItemIntern>
                <Image alt="OA logo" height={40} src={organicAgilityLogo} style={{borderRight: "0px solid white !important"}}/>
            </NavItemIntern>
        </NavbarBrand>
}

const MainNavigation = ({title, onClick, icon}) =>
    <div style={{float: 'left'}}>
        <FirstIcon onClick={onClick} />
        { ! isMobile && 
            <PageHeader>
                { icon && <img style={{paddingRight: "16px", width: "36px"}} className="material-icons" src={icon} /> }
                { title }
            </PageHeader>
        }
    </div>;

export default MainNavigation;