import React, {useEffect} from 'react'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {I18n} from 'react-i18nify'
import {getFilteredDecisionsByPoints} from './Inspect'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {
    InspectButtonClear,
    InspectDescription,
    InspectDetailIndex,
    InspectDetailLink,
    InspectDetailTitle,
    InspectFrame,
    InspectHeader,
    InspectHeaderHelp,
    InspectImageCenter,
} from './Inspect.styles'
import toolSquareIcon from "../../img/tool_square.png"
import toolFreeIcon from "../../img/tool_free.png"
import chooseStoryIcon from "../../img/choose-story-graphic.png"
import {ColorsTypes, getBarColors} from "../../Charts/ChartColor";
import {showPopupMessage} from '../../core/utils/notifications'
import { useCaptureSlice } from '../../capture/hooks/useCaptureSlice'
import { useHistory } from '../../core/hooks/useHistory'

export const Smiley = ({mood}) => {
    switch (mood) {
        case 'positive':
            return <i style={{color: getBarColors(ColorsTypes.dataPointPositive)}} className="far fa-smile-beam" />
        case 'negative':
            return <i style={{color: getBarColors(ColorsTypes.dataPointNegative)}} className="far fa-frown" />
        case 'neutral':
            return <i style={{color: getBarColors(ColorsTypes.dataPointNeutral)}} className="far fa-meh" />
        default:
            return <i style={{color: getBarColors(ColorsTypes.darkGray)}} className="fas fa-minus-circle" />
    }
}

const Stories = () => {
    const {decisionsSearch, selectedPoints, filters, Actions} = useDashboardSlice();
    const {isLeadershipAnalysisToolPage} = useHistory();
    let captureCollection = decisionsSearch;
    
    if (isLeadershipAnalysisToolPage) {
        const { leaderShipAssessmentSurvey } = useCaptureSlice()
        captureCollection = leaderShipAssessmentSurvey.flatMap(a => ({...a.data, pk: a.key, parent_capture: a.parent_capture}))
    }
    const currentActiveUser = useUserSlice()

    const getChildrenDescriptions = (capture) => {
        const children = captureCollection.filter(c => c.parent_capture === capture.pk)
        if (children.length === 0) {
            return null
        }
        return <ul style={{paddingLeft: "10px", listStyle: "none"}}>
            {
                children.map(c => (
                    <li key={c.pk} style={{padding:"5px 0"}}>
                        <Smiley mood={c.mood} />
                        <span style={{marginLeft:'8px'}}>{c.description}</span>
                    </li>
                ))
            }
        </ul>
    }

    const showDetails = (story) => {
        const {pk, title, description, mood} = story
        const details = {
            title: (!isNaN(pk) ? (pk + '. ') : "") + (title || ""),
            message: (
                <div>
                    <p style={{fontSize: '200%'}}>
                        <Smiley mood={mood} />
                    </p>
                    <p style={{marginBottom: '50px', overflowY: 'scroll', maxHeight: '200px'}}>
                        {description}
                        {isLeadershipAnalysisToolPage && getChildrenDescriptions(story)}
                    </p>
                </div>
            ),
            confirmLabel: I18n.t('strategyMap.messages.ok'),
        }
        showPopupMessage(details)
        return false
    }

    const showStoryText = (story) => {
        return !story.hide_description || currentActiveUser.email.endsWith('@agile42.com')
    }

    const clear = () => {
        Actions.setSearchWord( "" );
        Actions.setSelectedPoints({});
    }

    const visiblePoints = getFilteredDecisionsByPoints(decisionsSearch, selectedPoints, filters);
    return (
        <InspectFrame>
            <div style={{display: 'inline-block', width: '100%'}}>
                <InspectHeader>
                    {I18n.t('analysis.inspect.{s} selected stories').replace(
                        '{s}',
                        visiblePoints.length,
                    )}
                </InspectHeader>
                <InspectButtonClear onClick={ clear }>
                    {I18n.t('analysis.inspect.button clear')}
                </InspectButtonClear>
            </div>

            <div style={{fontWeight: 'bold'}}>
                <InspectDetailIndex>{I18n.t('analysis.inspect.header no')}</InspectDetailIndex>
                <InspectDetailTitle>
                    {I18n.t('analysis.inspect.header title')}
                </InspectDetailTitle>
            </div>
            {visiblePoints.map((a, i) => (
                <div key={i}>
                    <InspectDetailIndex>
                        {!isNaN(a.pk) && a.pk} <Smiley mood={a.mood} />
                    </InspectDetailIndex>

                    <InspectDetailTitle>
                        {!showStoryText(a) ? I18n.t('analysis.decisionHiddenByUser') : a.title}
                    </InspectDetailTitle>
                    {showStoryText(a) && (
                        <InspectDetailLink>
                            <span
                                className="material-icons-outlined"
                                style={{cursor: 'pointer'}}
                                onClick={ () => showDetails(a) }
                            >chevron_right</span>
                        </InspectDetailLink>
                    )}
                </div>
            ))}
        </InspectFrame>
    )
}

const Help = () => {
    const descriptionText = I18n.t('analysis.inspect.how to select').split('{s}')

    const description = (
        <p>
            {descriptionText[0]}
            <img alt={'Box Select'} height={'16px'} src={toolSquareIcon} />
            <img
                alt={'Lasso Select'}
                height={'16px'}
                style={{marginLeft: '5px'}}
                src={toolFreeIcon}
            />
            {descriptionText[1]}
        </p>
    )

    return (
        <InspectFrame>
            <InspectHeaderHelp>{I18n.t('analysis.inspect.select stories')}</InspectHeaderHelp>
            <InspectDescription>{description}</InspectDescription>

            <InspectImageCenter width={'85%'} src={chooseStoryIcon} />
        </InspectFrame>
    )
}

const InspectDetails = () => {
    const {selectedPoints, decisionsSearch, decisions, Actions} = useDashboardSlice();
    const {currentPage} = useHistory();

    useEffect( () => {
        Actions.setSelectedPoints({});
    }, [currentPage]);
    
    const hasStories = decisions.length > decisionsSearch.length || Object.keys(selectedPoints).length;

    return <div>{ hasStories ? <Stories /> : <Help /> }</div>
}

export default React.memo(InspectDetails)
