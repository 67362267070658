import React from 'react'
import MultiLineInput from './MultiLineInput'
import SingleLineInput from './SingleLineInput'
import SingleSelect from './SingleSelect'
import MultiSelect from './MultiSelect'
import TrianglePanel from './triangle/TrianglePanel'
import TriangleGroup from './triangle/TriangleGroup'
import Stones from './stones/Stones'
import { Panel } from '../../core/components/BootstrapWrapper'
import Slider from './Slider'
import {I18n} from 'react-i18nify'
import Consent from './Consent'
import UserDemographics from './demographics/UserDemographics'

const signifiers = {
    InfoLabel: (question, props = {}) => <Panel {...props}>{I18n.t(question.text)}</Panel>,
    Slider: (question, props = {}) => (
        <Slider
            header={I18n.t(question.header || '')}
            leftLabel={I18n.t(question.labels.left)}
            rightLabel={I18n.t(question.labels.right)}
            fieldName={question.field_name}
            required={question.required}
            onChange={(value) => {
                props.handleChange && props.handleChange(question.field_name, value)
            }}
            {...props}
        />
    ),
    MultiLineInput: (question, props = {}) => (
        <MultiLineInput
            header={I18n.t(question.header || '')}
            placeholder={I18n.t(question.placeholder || '')}
            validationRules={question.validation_rules}
            fieldName={question.field_name}
            onBlur={(value) => {
                props.handleChange && props.handleChange(question.field_name, value)
            }}
            {...props}
        />
    ),
    SingleLineInput: (question, props = {}) => (
        <SingleLineInput
            header={I18n.t(question.header || '')}
            placeholder={I18n.t(question.placeholder || '')}
            validationRules={question.validation_rules}
            fieldName={question.field_name}
            readOnly={question.readOnly}
            defaultValue={question.defaultValue}
            onBlur={(value) => {
                props.handleChange && props.handleChange(question.field_name, value)
            }}
            {...props}
        />
    ),
    SingleSelect: (question, props = {}) => (
        <SingleSelect
            header={`${I18n.t(question.header || '')}`}
            onChoiceSelect={(choice) => {
                props.handleChange && props.handleChange(question.field_name, choice.value)
            }}
            isVertical={question.is_vertical}
            fieldName={question.field_name}
            required={question.required}
            choices={question.choices}
            value={question.choices.length === 1 ? question.choices[0].value : null}
            {...props}
        />
    ),
    MultiSelect: (question, props = {}) => (
        <MultiSelect
            header={`${I18n.t(question.header || '')}${!question.hide_ellipsis && '...'}`}
            onChoiceSelect={(choices) => {
                props.handleChange && props.handleChange(question.field_name, choices)
            }}
            isVertical={question.is_vertical}
            fieldName={question.field_name}
            required={question.required}
            choices={question.choices}
            {...props}
        />
    ),
    TrianglePanel: (question, props = {}) => (
        <TrianglePanel
            data={{
                header: `${I18n.t(question.header || '')}${!question.hide_ellipsis && '...'}`,
                labels: {
                    top: I18n.t(question.labels.top || ''),
                    left: I18n.t(question.labels.left || ''),
                    right: I18n.t(question.labels.right || ''),
                    na: I18n.t(question.labels.na),
                },
                id: question.id,
                imageName: question.image_name,
                imageUrl: question.image_url,
                required: question.required,
            }}
            fullWidth={question.full_width}
            onChange={(data) => {
                props.handleChange && props.handleChange(question.field_name, data)
            }}
            {...props}
        />
    ),
    TriangleGroup: (question, props = {}) => (
        <TriangleGroup
            items={
                question.triangles &&
                question.triangles.map((item) => ({
                    header: I18n.t(item.header || ''),
                    labels: {
                        top: I18n.t(item.labels.top || ''),
                        left: I18n.t(item.labels.left || ''),
                        right: I18n.t(item.labels.right || ''),
                        na: I18n.t(item.labels.na),
                    },
                    id: item.id,
                    imageName: item.image_name,
                    imageUrl: item.image_url,
                    required: item.required,
                }))
            }
            onChange={(data) => {
                props.handleChange && props.handleChange(question.field_name, data)
            }}
            groupInfo={I18n.t(question.group_description)}
            {...props}
        />
    ),
    Stones: (question, props = {}) => {

        const competingValues = [
                {
                    name: question.competing_values[0].name,
                    value: question.competing_values[0].value,
                    description: question.competing_values[0].description,
                    bounds: {top: 0, left: 6.5},
                    x: 0,
                    y: 0,
                },
                {
                    name: question.competing_values[1].name,
                    value: question.competing_values[1].value,
                    description: question.competing_values[1].description,
                    bounds: {top: 25, left: 31.5},
                    x: 0,
                    y: 0,
                },
                {
                    name: question.competing_values[2].name,
                    value:question.competing_values[2].value,
                    description: question.competing_values[2].description,
                    bounds: {top: 50, left: 56.5},
                    x: 0,
                    y: 0,
                },
                {
                    name: question.competing_values[3].name,
                    value: question.competing_values[3].value,
                    description: question.competing_values[3].description,
                    bounds: {top: 75, left: 81.5},
                    x: 0,
                    y: 0,
                },
            ];


        return <Stones
            competingValues={ competingValues }
            required={question.required}
            requiredErrorMessage={I18n.t(question.required_error_message)}
            valuesIntro={I18n.t(question.values_intro)}
            graphIntro={I18n.t(question.graph_intro)}
            onPositionChange={(data) => {
                props.handleChange && props.handleChange(question.field_name, data)
            }}
            {...props}
        />
    },
    Consent: (question, props = {}) => (
        <Consent
            label={I18n.t(question.label)}
            header={question.header && I18n.t(question.header)}
            fieldName={question.field_name}
            required={question.required}
            checked={question.checked || false}
            {...props}
        />
    ),
    UserDemographics: (question, props = {}) => (
        <UserDemographics fieldName={question.field_name} {...props} />
    ),
}

export default signifiers
