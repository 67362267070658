import {useSelector} from 'react-redux'
import {keys, reduce} from 'ramda'
import {useActions} from './useActions'

export const useSlice = (slice, fieldsToSelectors, sliceLiteral) => {
    const primaryState = useSelector((state) => state[slice])

    const secondaryState = fieldsToSelectors
        ? reduce(
              (acc, field) => {
                  return {...acc, [field]: useSelector(fieldsToSelectors[field])}
              },
              {},
              keys(fieldsToSelectors),
          )
        : {}
    return sliceLiteral
        ? {
              ...primaryState,
              ...secondaryState,
              Actions: useActions(sliceLiteral.actions),
          }
        : {...primaryState, ...secondaryState}
}
