import React from 'react'
import SquadMember from '../SquadMember'
import {sortSquadMembersByFullname} from '../SquadOverlayTrigger'
import {I18n} from 'react-i18nify'
import NoUsers from '../NoUsers'
import {UserItem} from './SquadModal'

const Squad = (props) => {
    return (
        <>
            {props.members.length > 0 ? (
                props.members.sort(sortSquadMembersByFullname).map((squadMember) => (
                    <UserItem
                        data-testid={'squad-member-item-modal-' + squadMember.id}
                        key={squadMember.id}
                    >
                        <SquadMember
                            isUserChampion={props.isUserChampion}
                            isUserSponsor={props.isUserSponsor}
                            squadMember={squadMember}
                            totalChampions={props.totalChampions}
                            onRequestClose={props.onRequestClose}
                        />
                    </UserItem>
                ))
            ) : (
                <NoUsers text={I18n.t('strategyMap.nodes.noMembersYet')} />
            )}
        </>
    )
}

export default Squad
