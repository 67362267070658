import React, {useEffect} from 'react'
import {AVATAR_COLORS} from '../ui/_colors'
import {BarLoader} from 'react-spinners'
import {LoaderContainer} from '../../strategyMap/components/Modal/SquadModal'
import styled, {css} from 'styled-components'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {useCollaborativeQuill} from '../hooks/useCollaborativeQuill'

const ShiftUp = css`
    position: relative;
    bottom: 24px;
`
const HideToolbar = css`
    visibility: hidden !important;
    height: 0 !important;
`

const NoPointerEvents = css`
    pointer-events: none;
`

const Container = styled.div`
    .ql-toolbar {
        ${(p) => p.isReadOnly && HideToolbar}
    }
    .ql-tooltip {
        width: 260px;
        .ql-preview {
            width: 177px;
            text-overflow: ellipsis;
        }
        .ql-action {
            float: left;
        }
        .ql-remove {
            float: left;
        }
    }
    .ql-container {
        padding-top: 10px;
        height: 250px;
    }
    .ql-editor > p {
        ${(p) => p.isReadOnly && NoPointerEvents}
    }
    width: 100%;
    ${(p) => p.isReadOnly && ShiftUp};
    display: inline-block;

    .ql-snow .ql-tooltip {
        left: 5px !important;
    }

    .ql-snow .ql-tooltip input[type='text'] {
        width: 140px;
    }
`

function YQuill(props) {
    const user = useUserSlice()
    const [provider, quillRef, isLoading] = useCollaborativeQuill(
        props.nodeId,
        props.roomName,
        props.placeholder,
        props.handleTextChange,
        props.isReadOnly,
        props.text,
    )

    useEffect(() => {
        if (provider) {
            const colour = user.is_strategist
                ? AVATAR_COLORS['COLOR' + (user.color_code || '0')]
                : AVATAR_COLORS['COLOR0']
            const name = user.is_strategist ? user.full_name : ' '

            provider.awareness.setLocalStateField('user', {
                name,
                color: colour,
            })
        }
    }, [provider]);

    return (
        <Container isReadOnly={props.isReadOnly}>
            {isLoading && (
                <LoaderContainer top={'50%'}>
                    <BarLoader />
                </LoaderContainer>
            )}
            <div ref={quillRef} />
        </Container>
    )
}

export default YQuill
