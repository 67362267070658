import React from 'react'
import {I18n} from 'react-i18nify'

import Version from './Version'

const ActivityContainer = (props) => {
    if (!props.versions) {
        return <div />
    }

    const isMaxDifferenceInMinutesToDisplay = (date_from, date_to) => {
        if (!date_from) return true

        const MAX_DIFFERENCE_IN_MINUTES = 2
        const to = new Date(date_to)
        const from = new Date(date_from)
        const diffMs = from - to
        const diffMin = Math.round(((diffMs % 86400000) % 3600000) / 60000)

        return diffMin > MAX_DIFFERENCE_IN_MINUTES
    }
    let date_from = false
    return (
        <div>
            {props.versions.filter(item => {
                const displayVersion = isMaxDifferenceInMinutesToDisplay(date_from, item.date_created)
                date_from = item.date_created
                return displayVersion
            }).map((item, index) => (
                <div key={index}>
                    <Version version={item} />
                </div>
            ))}
            {props.versions.length === 0 && <p>{I18n.t('strategyMap.messages.noActivity')}</p>}
        </div>
    )
}

export default ActivityContainer
