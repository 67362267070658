import React from 'react'
import styled from 'styled-components'
import {BarLoader} from 'react-spinners'

const LoadingContainer = styled.div`
    display: ${(p) => p.display};
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
`

const RippleLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const RippleContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 64px !important;
    height: 64px !important;
    div {
        position: absolute;
        border: 4px solid #000;
        opacity: 1;
        border-radius: 50%;
        animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        &:nth-child(2) {
            animation-delay: -0.5s;
        }
        &.primary {
            border: 4px solid red;
        }
    }

    @keyframes ripple {
        0% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: -1px;
            left: -1px;
            width: 58px;
            height: 58px;
            opacity: 0;
        }
    }
`

export const FullScreenLoading = ({loading}) => (
    <LoadingContainer display={loading ? 'flex' : 'none'}>
        <RippleContainer className="ripple">
            <div data-testid={'full-screen-loading'} className={'primary'}></div>
            <div className={'primary'}></div>
        </RippleContainer>
    </LoadingContainer>
)

export const RippleLoading = ({loading, label = '', primary, css = {}}) => (
    <RippleLoadingContainer style={css}>
        {loading ? (
            <RippleContainer className="ripple">
                <div className={primary ? 'primary' : ''}></div>
                <div className={primary ? 'primary' : ''}></div>
            </RippleContainer>
        ) : (
            label
        )}
    </RippleLoadingContainer>
)
const BarLoadingContainer = styled.div`
    margin-top: 100px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
`

export const BarLoading = () => (
    <BarLoadingContainer>
        <BarLoader size={100} color={'lightgray'} />
    </BarLoadingContainer>
)

export default {FullScreenLoading, RippleLoading, BarLoading}
