import React from 'react'
import {I18n} from 'react-i18nify'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {getTranslatedDemographicsLabel, ORDERED_DEMOGRAPHICS_TYPE} from '../../../core/utils/demographics'
import {DemographicRow, DemographicsInfo, UserDemographics} from './Demographics.styles'
import {isNonEmptyArray} from 'ramda-adjunct'
import {Div} from '../../../core/ui/Div'

export const activeDemographics = (demographics) => {
    const selectAbleDemographics = {};
    Object.keys( demographics )
        .forEach( key => {
            if( isNonEmptyArray( demographics[key] ) )
                selectAbleDemographics[key] = demographics[key].filter( demo => demo.archived === false );
        })
    return selectAbleDemographics;
}

const UserDemographicsList = (props) => {

    const selectAbleDemographics = activeDemographics( props.selectableDemographics );

    return (
        <React.Fragment>
            <div className="demographics-body" data-testid="Demographics-body-without-editing">
                <DemographicsInfo>
                    <Div width={"100%"} lineHeight={2}>
                        {I18n.t('capture.Panel.Topic.DemographicsCollapsedInfo')}
                        <i
                            className="material-icons pull-right"
                            onClick={props.onEditClick}
                            aria-hidden="true"
                            role="button"
                        >
                            edit
                        </i>
                    </Div>
                </DemographicsInfo>
                <UserDemographics>
                    {!_.isEmpty(selectAbleDemographics) &&
                        ORDERED_DEMOGRAPHICS_TYPE.filter(
                            (demographicType) =>
                            props.selectedDemographics.hasOwnProperty(demographicType) &&
                                demographicType !== 'user',
                        ).map((demographicType) => {
                            if (!selectAbleDemographics[demographicType]) return null
                            const label = getTranslatedDemographicsLabel(
                                selectAbleDemographics[demographicType].find(
                                    (choice) => choice.pk === props.selectedDemographics[demographicType],
                                ),
                            )
                            return (
                                <DemographicRow
                                    data-testid="Demographics-panel-without-editing"
                                    className={demographicType}
                                    key={demographicType}
                                >
                                    <div className={'tag'}>
                                        {I18n.t('capture.Panel.Topic.' + demographicType)}
                                    </div>
                                    <div className={'value'}>{label}</div>
                                </DemographicRow>
                            )
                        })}
                </UserDemographics>
            </div>
        </React.Fragment>
    )
}


UserDemographicsList.propTypes = {
    onEditClick: PropTypes.func,
    selectableDemographics: PropTypes.object,
}

UserDemographicsList.defaultProps = {
    selectableDemographics: [],
}

export default UserDemographicsList
