import React, {useEffect, useState} from "react"
import {useCoreSlice} from "../core/hooks/useCoreSlice"
import {H3} from "./Demographics"
import {Ordering} from "./UserTable"
import {useOrdering} from "../core/hooks/useOrdering"
import {useCheckList} from "../core/hooks/useCheckList"
import {ActiveButton, ButtonPrimary, ButtonSecondary} from "../core/components/Buttons"
import Checkbox from '../capture/signifier/Checkbox'
import {Table} from 'react-bootstrap'
import {Input} from "../core/components/Navbar/NodeSearch.styles"
import {Span} from "../core/ui/generic"
import ClickableInput from "../core/components/DesiredOutcomes/ClickableInput"
import Footer from "../core/ui/modal/Footer"
import Modal from "../core/components/Modal"
import {I18n} from "react-i18nify"
import {useUserSlice} from "../strategyMap/hooks/useUserSlice"
import {SearchBox} from "../strategyMap/components/Modal/SquadModal"
import { Panel } from "../core/components/BootstrapWrapper"
import {RESTRICTIONS} from "../core/const";
import {showPopupMessage} from "../core/utils/notifications";

const AddRemoveGroupElement = ({onClick, tag, float}) =>
    <Span
        cursor="pointer"
        verticalAlign="middle"
        float={ float }
        className="material-icons"
        onClick={ onClick }
        data-testid={ tag }
    >{ tag }</Span>;

const AddNewTeam = ({onClick}) => <AddRemoveGroupElement onClick={ onClick } tag={'add'} float={"left"} />;
const SubNewTeam = ({onClick}) => <AddRemoveGroupElement onClick={ onClick } tag={'remove'} float={"left"} />;
const AddNewTeamMember = ({onClick}) => <AddRemoveGroupElement onClick={ onClick } tag={'manage_accounts'} float={"right"} />;

const maxTeamMembers = (features) =>
        features["leadership.full"] ? RESTRICTIONS.Leadership.MAX_TEAMS_ALLOWED.FULL_VERSION :
        features["leadership.team"] ? RESTRICTIONS.Leadership.MAX_TEAMS_ALLOWED.TEAM_VERSION : 0;


const TeamActivation = ({team}) => {
    const {teams, Actions, features} = useCoreSlice();

    const onClick = () => {
        if( 
            team.disabled && 
            maxTeamMembers(features) <= teams.filter( a => ! a.disabled ).length 
        ) {
            showPopupMessage({
                title: I18n.t('leadership.messages.FeatureLimitation.FeatureNeedUpgradeLimited.title'),
                message: I18n.t('leadership.messages.FeatureLimitation.FeatureNeedUpgradeLimited.message'),
                confirmLabel: I18n.t('app.buttons.cancel')
            })
        } else {
            Actions.updateTeam({id: team.id, name: team.name, disabled: !team.disabled})
        }
    };

    return <ActiveButton
                active={ ! team.disabled }
                onClick={ onClick }>
                { I18n.t("app.status." + (team.disabled ? 'disabled' : 'active') ) }
        </ActiveButton>;
}


export const TeamsTable = (props) => {
    const {features} = useCoreSlice();
    const [creatingTeam, setCreatingTeam] = useState(false)
    const [teamName, setTeamName] = useState('')
    const [selectedTeams, setSelectedTeams] = useState([])
    const {Actions} = useCoreSlice()
    const [orderedTeams, orderingFns] = useOrdering(props.teams)
    const {checkedItems, allChecked, handleCheckboxClick, handleAllCheckBoxClick} = useCheckList(
        orderedTeams,
        setSelectedTeams
    );
    const deleteTeams = () => selectedTeams.forEach( t => Actions.deleteTeam(t.id) );

    const onClickAddNewTeam = () => {
        if( orderedTeams.filter( t => !t.disabled ).length >= maxTeamMembers(features) )
            showPopupMessage({
                title: I18n.t('leadership.messages.FeatureLimitation.FeatureNeedUpgradeLimited.title'),
                message: I18n.t('leadership.messages.FeatureLimitation.FeatureNeedUpgradeLimited.message'),
                confirmLabel: I18n.t('app.buttons.cancel')
            })
        else
            setCreatingTeam(true);
    }

    return (
        <Table>
            <thead>
                <tr>
                    <th style={{width: "30px"}}>
                        <Checkbox onClick={handleAllCheckBoxClick} checked={allChecked} />
                    </th>
                    <th style={{width: "65px"}}>
                        <AddNewTeam onClick={ onClickAddNewTeam } />
                        { selectedTeams.length > 0 &&  <SubNewTeam onClick={ deleteTeams } /> }
                    </th>
                    <th>
                        <Ordering
                            key="name"
                            title={I18n.t('billing.Label.teamName')}
                            field={'name'}
                            {...orderingFns}
                        />
                    </th><th>
                        <Ordering
                            key="disabled"
                            title={I18n.t('billing.Label.Status')}
                            field={'disabled'}
                            {...orderingFns}
                        />
                    </th>
                </tr>
            </thead>
            <tbody data-testid={"ubody"}>
                {creatingTeam && 
                    <tr data-testid="newteam-row">
                        <td colSpan={3}>
                            <Input
                                value={teamName || ''}
                                onChange={(e) => setTeamName(e.target.value)}
                                data-testid="newteam-name"
                            />
                            <ButtonPrimary
                                disabled={ ! teamName }
                                onClick={() => {
                                    Actions.createTeam({name: teamName})
                                    setCreatingTeam(false)
                                    setTeamName('')
                                }}
                                data-testid="invite-user-button"
                            >
                                {I18n.t('setup.Create')}
                            </ButtonPrimary>
                            <ButtonSecondary
                                onClick={() => {
                                    setTeamName('')
                                    setCreatingTeam(false)
                                }}
                                data-testid="invite-user-button"
                            >
                                {I18n.t('analysis.buttons.cancel')}
                            </ButtonSecondary>
                        </td>
                    </tr>
                }
                {orderedTeams.map((team, i) => {
                    return (
                        <tr key={'teamrow-'+i} data-testid={"teamrow"}>
                            <td>
                                <Checkbox
                                    testId={"teamrow-"+team.pk}
                                    onClick={(_) => handleCheckboxClick(i)}
                                    checked={!!checkedItems[i]}
                                />
                            </td>
                            <td />
                            <td>
                                <ClickableInput
                                    value={ team.name }
                                    onChangeFinished={ teamText => Actions.updateTeam({id: team.id, name: teamText.text}) }
                                    className="pull-left"
                                    placeholderText={ team.name }
                                />
                            </td>
                            <td>
                                <TeamActivation team={team} />
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}

const UserDialogue = ({team, onClose}) => {
    const [selectedUsers, setSelectedUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    const {Actions} = useCoreSlice()
    const {users, Actions: UserActions} = useUserSlice()
    
    useEffect(() => {
        setSelectedUsers(team && team.users || [])
        setFilteredUsers(users)
    }, [team])

    const handleSetFilter = (searchString) => {
        searchString = searchString.toLowerCase()
        const filteredUsersList = users.filter(
            (user) =>
                user.email.toLowerCase().includes(searchString) ||
                user.first_name.toLowerCase().includes(searchString) ||
                user.last_name.toLowerCase().includes(searchString) ||
                (user.initials && user.initials.toLowerCase().includes(searchString)),
        )

        setFilteredUsers(filteredUsersList)
    }

    return (
        <Modal
            isOpen={team !== null}
            title={I18n.t('billing.Label.teamDialogueTitle')}
            footerContent={
                <Footer
                    confirmText={I18n.t('app.buttons.confirm')}
                    onConfirm={() => {
                        Actions.updateTeam({id: team.id, users: selectedUsers})
                        UserActions.fetchUsers()
                        setSelectedUsers([])
                        onClose()
                    }}
                    onCancel={() => {
                        setSelectedUsers([])
                        onClose()
                    }}
                />
            }
        >
            <Table width={"400px"}>
                <thead>
                    <tr>
                        <th colSpan={2}>
                            <SearchBox onSetFilter={handleSetFilter} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((e, i) => (
                        <tr key={i}>
                            <td>
                                <Checkbox
                                    disabled={ ! selectedUsers.includes(e.pk) && e.teams.length >= RESTRICTIONS.Leadership.MAX_GROUPS_ONE_PERSON_CAN_JOIN }
                                    onClick={() => {
                                        if (selectedUsers.includes(e.pk)) {
                                            setSelectedUsers(selectedUsers.filter(u => u !== e.pk))
                                        }
                                        else {
                                            setSelectedUsers([...selectedUsers, e.pk])
                                        }
                                    }}
                                    checked={selectedUsers.includes(e.pk)}
                                    testId={e.name}
                                />
                            </td>
                            <td>
                                {e.first_name} {e.last_name} ({e.email})
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Modal>
    )
}

const Teams = () => {
    const {teams, Actions, features} = useCoreSlice()
    const [selectedTeam, setSelectedTeam] = useState(null)
    const {users, Actions: UserActions} = useUserSlice()

    useEffect(() => {
        UserActions.fetchUsers()
        Actions.fetchTeams()
    }, [])

    const getTeamUsersRows = (team) => {
        const rows = []
        team.users.forEach(u => {
            const userObj = users.find(user => user.pk === u)
            if(userObj) {
                rows.push(
                    <tr key={u}>
                        <td>{userObj.first_name} {userObj.last_name} ({userObj.email})</td>
                    </tr>);
            }
        })
        if (rows.length === 0) {
            return <tr><td>{I18n.t('billing.Label.noUsersInThisTeam')}</td></tr>
        }
        return rows
    }

    const sortTeams = (teams) =>
        teams.sort( (a, b) => {
            if( a.name.toLowerCase() < b.name.toLowerCase() )
                return -1;
            if( a.name.toLowerCase() > b.name.toLowerCase() )
                return 1;
            return 0;
        });

    const addNewTeamMembersClick = ( team ) => {
        if( features["leadership.team"] && RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.TEAM_VERSION <= team.users.length )
            return showPopupMessage({
                title: I18n.t('leadership.messages.FeatureLimitation.TeamMemberLimitation.title'),
                message: I18n.t('leadership.messages.FeatureLimitation.TeamMemberLimitation.message').replace( "%count%", RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.TEAM_VERSION ),
                confirmLabel: I18n.t('app.buttons.cancel')
            });

        if( features["leadership.full"] && RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.FULL_VERSION <= team.users.length )
            return showPopupMessage({
                title: I18n.t('leadership.messages.FeatureLimitation.TeamMemberLimitation.title'),
                message: I18n.t('leadership.messages.FeatureLimitation.TeamMemberLimitation.message').replace( "%count%", RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.FULL_VERSION ),
                confirmLabel: I18n.t('app.buttons.cancel')
            });

        setSelectedTeam( team );
    }

    return (
        <>
            <Panel header={I18n.t('billing.Label.Teams')}>
                <TeamsTable 
                    teams={ sortTeams( teams ) }
                    key={ teams.length }
                />
            </Panel>
            <Panel>
                <UserDialogue 
                    team={selectedTeam}
                    onClose={() => setSelectedTeam(null) }
                />
                {
                    sortTeams(teams).map( (team, idx) =>
                        <div key={idx} style={{opacity: team.disabled ? .5 : 1}}>
                            <H3>
                                <i style={{marginRight: 10, fontStyle: 'normal'}}>{ team.name }</i>
                                <TeamActivation team={team} />
                                <AddNewTeamMember onClick={ () => addNewTeamMembersClick( team ) } />
                            </H3>
                            <Table>
                                <tbody>
                                    { getTeamUsersRows( team ) }
                                </tbody>
                            </Table>
                        </div>
                    )
                }
            </Panel>
        </>
    )
}

export default Teams