import styled, {css} from 'styled-components'
import {Col, Image} from 'react-bootstrap'
import {DARK_GREY} from '../../../core/ui/_colors'

export const CompetingValuesContainer = styled.div`
    position: relative;
    &.true {
        .react-draggable-dragged {
            opacity: 1 !important;
        }
    }
    @media (min-width: 576px) {
        height: 305px;
    }
    @media (min-width: 768px) {
        height: 373.14px;
        display: flex;
        align-items: flex-start;
    }
    @media (min-width: 992px) {
        width: 714px;
        height: 457px;
    }
    @media (min-width: 1200px) {
        width: 755px !important;
        height: 496px !important;
    }
`

export const CompetingValuesImg = styled.div`
    width: 100%;
    height: 468.75px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    @media (min-width: 320px) {
        height: 410px;
    }
    @media (min-width: 375px) {
        height: 468.75px;
    }
    @media (min-width: 411px) {
        height: 512px;
    }
    @media (min-width: 576px) {
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
    }
    @media (min-width: 768px) {
        height: 100%;
    }
`
export const DiagramLabels = styled.div`
    position: absolute;
    height: 80%;
    width: 100%;
    display: block;
    right: 0;
    top: 20%;
    padding: 25px;
    @media (min-width: 576px) {
        height: 100%;
        width: 80%;
        top: 0;
    }
`

export const DragInfo = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    @-ms-keyframes fadeInOut {
        0% {
            opacity: 0;
            left: 0;
        }
        50% {
            opacity: 1;
            left: 30%;
        }
        100% {
            opacity: 0;
            left: 0;
        }
    }
    @-moz-keyframes fadeInOut {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    @-webkit-keyframes fadeInOut {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes fadeInOut {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`

export const DragInfoInner = styled.div`
    color: ${DARK_GREY};
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 30%;
    opacity: 1;
    -webkit-animation: fadeInOut 3000ms 2s linear infinite;
    -moz-animation: fadeInOut 3000ms 2s linear infinite;
    -ms-animation: fadeInOut 3000ms 2s linear infinite;
    -o-animation: fadeInOut 3000ms 2s linear infinite;
    animation: fadeInOut 3000ms 2s linear infinite;
`

export const InsideLabelsCont = styled.div`
    width: 100%;
    height: 100%;
`

export const InsideLabel = styled.div`
    width: 50%;
    height: 50%;
    float: left;
    padding: 20px;
    @media (min-width: 320px) {
        font-size: 12px;
        padding: 10px;
    }
`

export const InsideLabelInner = styled.div`
    color: ${DARK_GREY};
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @media (min-width: 320px) {
        font-size: 12px;
    }
    @media (min-width: 992px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 30px;
    }
`

export const ArrowLabel = styled.div`
    position: absolute;
    text-align: center;
    width: 100%;
    height: 25px;
    font-size: 11px;
`

export const ArrowLabelTop = styled(ArrowLabel)`
    top: 0;
    left: 0;
`

export const ArrowLabelBottom = styled(ArrowLabel)`
    bottom: -10px;
    left: 0;
`

const arrowLabelRotate = css`
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
`

export const ArrowLabelRight = styled(ArrowLabel)`
    ${arrowLabelRotate}
    line-height: 25px;
    top: 50%;
    left: 47%;
    @media (min-width: 576px) {
        left: 48%;
    }
    @media (min-width: 768px) {
        left: 50%;
    }
`

export const ArrowLabelLeft = styled(ArrowLabel)`
    ${arrowLabelRotate}
    line-height: 25px;
    bottom: 50%;
    margin-left: calc(-50% - 17px);
    @media (min-width: 992px) {
        margin-left: calc(-50% - 10px);
    }
    @media (min-width: 1200px) {
        margin-left: calc(-50% - 25px);
    }
    @media (min-width: 768px) {
        margin-left: calc(-50% - 25px);
    }
`

export const CompetingValuesStand = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    width: 100%;
    height: 20%;
    @media (min-width: 576px) {
        display: block;
        float: left;
        width: 20%;
        height: 100%;
    }
`

export const CompetingValuesStandItem = styled.div`
    width: 25%;
    font-size: 12px;
    text-align: center;
    @media (min-width: 576px) {
        height: 25%;
        width: 100%;
    }
    @media (min-width: 992px) {
        font-size: 14px;
    }
`

export const CompetingValuesStandItemText = styled.div`
    padding-top: 50px;
    @media (min-width: 992px) {
        padding-top: 70px;
    }
`

export const Dimensions = styled.div`
    width: 100%;
    padding: 5%;
    margin: auto;
    font-size: 16px;
    @media (min-width: 320px) {
        word-break: break-word;
    }
    @media (min-width: 576px) {
        padding: 0;
    }
    @media (min-width: 768px) {
        padding: 0 4%;
    }
    @media (min-width: 992px) {
        padding: 0;
    }
    @media (min-width: 1200px) {
        padding: 0;
        width: 800px !important;
        margin: 0 !important;
    }
`

export const DimensionRow = styled(Col)`
    text-align: center;
    min-height: 215px;
    align-items: center;
    margin-bottom: 15px;
    padding: 0px 5px 10px 5px;
    line-height: 26px;
    @media (min-width: 576px) {
        min-height: 185px;
    }
    @media (min-width: 768px) {
        padding: 0px 15px;
        min-height: 160px;
    }
    @media (min-width: 992px) {
        margin-bottom: 0;
    }
    @media (min-width: 1200px) {
        padding: 0 15px 0 0 !important;
        margin-bottom: 0;
    }
`

export const DimensionTitle = styled.div`
    margin-top: 5px;
    font-weight: 600;
    @media (min-width: 576px) {
        margin-top: 10px;
    }
`

export const DimensionImg = styled(Image)`
    min-width: 40px;
    height: 40px;
    @media (min-width: 992px) {
        min-width: 60px;
        min-height: 60px;
        margin-bottom: 5px;
    }
    @media (min-width: 1200px) {
        min-width: 60px;
        min-height: 60px;
        margin-bottom: 5px;
    }
`
