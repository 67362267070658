class Point {
    /**
     * This represents a point in the 2D plane.
     * @param {Number} x The x coordinate of the point
     * @param {Number} y The y coordinate of the point
     */
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    static distance(P1, P2) {
        /**
         * Method to calculate the distance between two points.
         * @param {Point} P1 The first point
         * @param {Point} P2 The second point
         * @returns {Number} The distance between the two points
         */
        return Math.sqrt((P1.x - P2.x) ** 2 + (P1.y - P2.y) ** 2);
    }

    static midpoint(P1, P2) {
        /**
         * Method to calculate the midpoint between two points.
         * @param {Point} P1 The first point
         * @param {Point} P2 The second point
         * @returns {Point} The midpoint between the two points
         */
        return new Point((P1.x + P2.x) / 2, (P1.y + P2.y) / 2);
    }

    static slope(P1, P2) {
        /**
         * Static method to calculate the slope between two points.
         * @param {Point} P1 The first point
         * @param {Point} P2 The second point
         * @returns {Number} The slope between the two points
         */
        if (P2.x - P1.x === 0) return Infinity; // vertical line, undefined slope

        return (P2.y - P1.y) / (P2.x - P1.x);
    }

    distanceTo(P) {
        /**
         * Method to calculate the distance between this point and another point.
         * @param {Point} P The other point
         * @returns {Number} The distance between the two points
         */
        return Point.distance(this, P);
    }
}

export default Point;