import React from 'react'
import {I18n} from 'react-i18nify'
import {getNodeDisplay} from '../strategyMap/utils'
import Avatar from '../core/components/Avatar'
import styled from 'styled-components'
import {fillingPlaceholdersHTML} from '../core/utils/html'
import Moment from "react-moment";

const Container = styled.div`
    cursor: pointer;
    padding: 10px 0;
    height: 100%;
`

const Content = styled.div`
    flex: 1;
    padding-left: 16px;
    h5 {
        margin-top: 0;
        margin-bottom: 5px;
    }
`

const Notification = ({notification, clickCallback}) => {
    const user = notification.sender
    const target = getNodeDisplay(notification.node) + ' ' + notification.node.title

    const getVerb = (initUser, target) => {
        const userName =
            (notification.data && notification.data.user) ||
            notification.recipient ||
            notification.recipient.email ||
            '***'
        
        return fillingPlaceholdersHTML(I18n.t('notifications.' + notification.verb), {
            ':initUser:': initUser,
            ':user:': userName,
            ':target:': target,
        })
    }

    return (
        <Container
            onClick={() => clickCallback(notification.node, notification.id)}
            data-testid={'notification-item'}
        >
            <Avatar pull="left" 
                    colorCode={user.color_code}
                    text={user.initials}
                    profilePicture={user.profile_picture} 
            />
            <Content>
                <div style={{marginLeft: '32px'}}>{getVerb(user.full_name, target)}</div>
                <Moment locale={I18n._localeKey} fromNow>{ notification.timestamp }</Moment>
            </Content>
        </Container>
    )
}

export default Notification