import React from 'react';
import Dashboard from './Dashboard';
import CommonPage from '../../core/components/CommonPage';
import FeaturePermission from '../../core/components/FeaturePermission';

import ImageOrgScan from "../../img/Apps/Orgscan.png";

const DashboardPage = () => {

    return <FeaturePermission name="dashboard">
                <CommonPage
                    pageIcon={ImageOrgScan}
                    pageHeading={"ORGANIC agility"}
                    render={() => <Dashboard />}
                />
            </FeaturePermission>;
}

export default DashboardPage;
