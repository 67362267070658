import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {I18n} from 'react-i18nify'
import {Image} from 'react-bootstrap'
import {Div} from '../../core/ui/Div'
import {DARK_GREY} from '../../core/ui/_colors'
import {createErrorClass} from '../../core/utils/html'
import ErrorOutput from './ErrorOutput'
import tacticalIcon from "../../img/Capture/ic-tactical@unknown.png"
import tacticalEnabledIcon from "../../img/Capture/ic-tactical-enabled@unknown.png"
import strategicIcon from "../../img/Capture/ic-strategic@unknown.png"
import strategicEnabledIcon from "../../img/Capture/ic-strategic-enabled@unknown.png"
import notsureIcon from "../../img/Capture/ic-notsure@unknown.png"
import notsureEnabledIcon from "../../img/Capture/ic-notsure-enabled@unknown.png"
import rightawayEnabledIcon from "../../img/Capture/ic-rightaway-enabled@unknown.png"
import rightawayIcon from "../../img/Capture/ic-rightaway@unknown.png"
import hourEnabledIcon from "../../img/Capture/ic-hour-enabled@unknown.png"
import hourIcon from "../../img/Capture/ic-hour@unknown.png"
import dayEnabledIcon from "../../img/Capture/ic-day-enabled@unknown.png"
import dayIcon from "../../img/Capture/ic-day@unknown.png"
import weekEnabledIcon from "../../img/Capture/ic-week-enabled@unknown.png"
import weekIcon from "../../img/Capture/ic-week@unknown.png"
import monthEnabledIcon from "../../img/Capture/ic-month-enabled@unknown.png"
import monthIcon from "../../img/Capture/ic-month@unknown.png"
import foreverEnabledIcon from "../../img/Capture/ic-forever-enabled@unknown.png"
import foreverIcon from "../../img/Capture/ic-forever@unknown.png"
import meEnabledIcon from "../../img/Capture/ic-me-enabled@unknown.png"
import meIcon from "../../img/Capture/ic-me@unknown.png"
import colleagueEnabledIcon from "../../img/Capture/ic-colleague-enabled@unknown.png"
import colleagueIcon from "../../img/Capture/ic-colleague@unknown.png"
import managerEnabledIcon from "../../img/Capture/ic-manager-enabled@unknown.png"
import managerIcon from "../../img/Capture/ic-manager@unknown.png"
import teamEnabledIcon from "../../img/Capture/ic-team-enabled@unknown.png"
import teamIcon from "../../img/Capture/ic-team@unknown.png"
import externalEnabledIcon from "../../img/Capture/ic-external-enabled@unknown.png"
import externalIcon from "../../img/Capture/ic-external@unknown.png"
import groupofpeopleEnabledIcon from "../../img/Capture/ic-groupofpeople-enabled@unknown.png"
import groupofpeopleIcon from "../../img/Capture/ic-groupofpeople@unknown.png"
import positiveEnabledIcon from "../../img/Capture/ic-positive-enabled@unknown.png"
import positiveIcon from "../../img/Capture/ic-positive@unknown.png"
import neutralEnabledIcon from "../../img/Capture/ic-neutral-enabled@unknown.png"
import neutralIcon from "../../img/Capture/ic-neutral@unknown.png"
import negativeEnabledIcon from "../../img/Capture/ic-negative-enabled@unknown.png"
import negativeIcon from "../../img/Capture/ic-negative@unknown.png"
import allthetimeEnabledIcon from "../../img/Capture/ic-allthetime-enabled@unknown.png"
import allthetimeIcon from "../../img/Capture/ic-allthetime@unknown.png"
import oftenEnabledIcon from "../../img/Capture/ic-often-enabled@unknown.png"
import oftenIcon from "../../img/Capture/ic-often@unknown.png"
import nowandthenEnabledIcon from "../../img/Capture/ic-nowandthen-enabled@unknown.png"
import nowandthenIcon from "../../img/Capture/ic-nowandthen@unknown.png"
import rarelyEnabledIcon from "../../img/Capture/ic-rarely-enabled@unknown.png"
import rarelyIcon from "../../img/Capture/ic-rarely@unknown.png"
import {Panel} from "../../core/components/BootstrapWrapper";

const icons = {
    'tactical': tacticalIcon,
    'tactical-enabled': tacticalEnabledIcon,
    'strategic': strategicIcon,
    'strategic-enabled': strategicEnabledIcon,
    'notsure': notsureIcon,
    'notsure-enabled': notsureEnabledIcon,
    'rightaway': rightawayIcon,
    'rightaway-enabled': rightawayEnabledIcon,
    'hour': hourIcon,
    'hour-enabled': hourEnabledIcon,
    'day': dayIcon,
    'day-enabled': dayEnabledIcon,
    'week': weekIcon,
    'week-enabled': weekEnabledIcon,
    'month': monthIcon,
    'month-enabled': monthEnabledIcon,
    'forever': foreverIcon,
    'forever-enabled': foreverEnabledIcon,
    'me': meIcon,
    'me-enabled': meEnabledIcon,
    'colleague': colleagueIcon,
    'colleague-enabled': colleagueEnabledIcon,
    "manager": managerIcon,
    "manager-enabled": managerEnabledIcon,
    'team': teamIcon,
    'team-enabled': teamEnabledIcon,
    'external': externalIcon,
    'external-enabled': externalEnabledIcon,
    'groupofpeople': groupofpeopleIcon,
    'groupofpeople-enabled': groupofpeopleEnabledIcon,
    'positive': positiveIcon,
    'positive-enabled': positiveEnabledIcon,
    'neutral': neutralIcon,
    'neutral-enabled': neutralEnabledIcon,
    'negative': negativeIcon,
    'negative-enabled': negativeEnabledIcon,
    'allthetime': allthetimeIcon,
    'allthetime-enabled': allthetimeEnabledIcon,
    'often': oftenIcon,
    'often-enabled': oftenEnabledIcon,
    'nowandthen': nowandthenIcon,
    'nowandthen-enabled': nowandthenEnabledIcon,
    'rarely': rarelyIcon,
    'rarely-enabled': rarelyEnabledIcon,
}

export const Circle = styled(Div)`
    min-width: 24px;
    height: 24px;
    width: 24px;
    border: 1px solid ${DARK_GREY};
    border-radius: 50%;
    color: ${DARK_GREY};
    ${(props) =>
            !props.isVertical ?
                    css`
                        margin-bottom: 30px;
                    ` : css`
                        margin: auto 0;
                    `}
    ${(props) =>
            props.isVertical &&
            css`
                margin-right: 20px;
            `}
    transform: scale(1, 1);
    transition: transform 0.5s ease;
    display: inherit;

    &:hover {
        transform: scale(1.1, 1.1);
    }
`

export const ChoiceContainer = styled(Div)`
    display: flex;
    align-items: center;
    flex-direction: ${(props) => (props.isVertical ? 'row' : 'column')};
    ${(props) =>
            props.isVertical &&
            css`
                padding-right: 0;
            `}
    width: ${(props) => (props.isVertical ? '100%' : '94px')};
    height: fit-content;
    margin: ${(props) => (props.isVertical ? '0' : '0 5px')};

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    text-align: ${(props) => (props.isVertical ? 'left' : 'center')};
    cursor: pointer;
    @media only screen and (min-width: 576px) {
        ${(props) =>
                !props.isVertical &&
                css`
                    width: 90px;
                    margin: 0 5px 5px 5px;
                `}
    }
    @media only screen and (min-width: 768px) {
        ${(props) =>
                !props.isVertical &&
                css`
                    margin: 0 5px 5px 5px;
                `}
    }
    @media only screen and (min-width: 992px) {
        ${(props) =>
                !props.isVertical &&
                css`
                    width: 115px;
                    margin: 0 5px 15px 5px;
                `}
    }
`

export const Value = styled(Div)`
    height: auto;
    width: 90%;
    ${(props) => (!props.isVertical && 'min-height: 30px;')}
    font-weight: ${(props) => (props.selected ? 700 : 500)};
    font-size: 14px;
    line-height: 20px;

    @media only screen and (min-width: 320px) {
        word-break: break-word;
    }
    @media only screen and (min-width: 576px) {
        height: auto;
        width: 90%;
        font-size: 14px;
        line-height: 20px;
    }

    @media only screen and (min-width: 992px) {
        font-size: 16px;
        &:hover {
            font-size: 16px;
        }
    }
`

export const ImgContainer = styled(Div)`
    width: 60px;
    height: 60px;
    margin: ${(props) => (props.isVertical ? 'auto' : '20px')};
    text-align: center;

    img {
        width: 60px;
        height: 60px;
        opacity: ${(props) => (props.selected ? 1 : 0.8)};
        transform: ${(props) => (props.selected ? 'scale(1.2, 1.2)' : 'scale(1, 1)')};
        transition: transform 0.2s ease, opacity 0.2s;

        &:hover {
            opacity: 1;
            transform: scale(1.2, 1.2);
        }
    }

    @media only screen and (min-width: 576px) {
        img {
            transition: transform 0.3s ease, opacity 0.3s;
        }
    }

    @media only screen and (max-width: 768px) {
        margin-right: 20px;
    }
    @media only screen and (min-width: 992px) {
        transform: ${(props) => (props.selected ? 'scale(1.3, 1.3)' : 'scale(1, 1)')};
        &:hover {
            transform: scale(1.3, 1.3);
        }
    }
`

export const Choices = styled(Div)`
    display: flex;
    flex-wrap: wrap;
    ${(props) =>
            props.isVertical &&
            css`
                margin: 0;
            `}

    ${(props) =>
            props.isVertical &&
            css`
                flex-direction: column;
            `}
    ${(props) =>
            props.isVertical &&
            css`
                width: 100%;
            `}
}

`

export const CheckedBox = styled(Div)`
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
    background-color: ${DARK_GREY};
    border-radius: 100%;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    transition: opacity 0.5s;
`

const SingleSelect = (props) => {
    const [value, setValue] = useState(props.value || '')
    const [validationErrors, setValidationErrors] = useState(null)

    useEffect(() => {
        if (props.value) {
            const savedUserDemographicsValue = props.value
            const validSavedUserDemographics = props.choices.find(c => c.value === savedUserDemographicsValue)
            if (validSavedUserDemographics) {
                setValue(props.value)
                props.onChoiceSelect(props.choices.find((choice) => choice.value === props.value), props.fieldName)
            }
        } else if(props.choices.length === 1) {
            setValue(props.choices[0].value)
            props.onChoiceSelect(props.choices[0], props.fieldName)
        }
    }, [props.value])

    const validateInput = () => {
        if (props.required && value.length === 0) {
            setValidationErrors({required: I18n.t("capture.Form.Status.Validation.SectionRequired")})
            props.onError && props.onError(props.fieldName)
        }
        setValidationErrors(null)
    }

    useEffect(() => {
        if (props.shouldValidate)
            validateInput()
    }, [props.shouldValidate])

    useEffect(() => {
        if (validationErrors)
            validateInput()
    }, [I18n._localeKey])

    const renderSelector = (choice, selected) => {
        if (!choice.imageName && !choice.imageUrl) {
            return (
                <Circle isVertical={props.isVertical}>
                    <CheckedBox selected={selected}/>
                </Circle>
            )
        } else {
            const imageName = selected ? choice.imageName + '-enabled' : choice.imageName
            const imageSrc = choice.imageUrl || icons[imageName]
            return (
                <ImgContainer className={'img-container'} selected={selected}>
                    <Image
                        src={imageSrc}
                        circle
                        alt={choice.label}
                    />
                </ImgContainer>
            )
        }
    }

    return props.choices.length === 0 ? null : (
        <Panel
            default
            data-testid="SingleSelect-choices"
            className={createErrorClass(props.fieldName, props.errors || validationErrors, !props.errors)}
            header={props.header}
        >
            <Choices
                isVertical={props.isVertical}
                className={classNames('choices', props.className, props.isVerticalList ? 'listed' : '')}
            >
                {props.choices &&
                    props.choices.map((choice) => {
                        const selected = choice.value === value
                        return (
                            <ChoiceContainer
                                data-testid="SingleSelect-choice"
                                isVertical={props.isVertical}
                                key={choice.value}
                                selected={selected}
                                onClick={() => {
                                    setValue(choice.value)
                                    props.onChoiceSelect(choice, props.fieldName)
                                    setValidationErrors(null)
                                }}
                            >
                                {renderSelector(choice, selected)}

                                <Value isVertical={props.isVertical} selected={selected}>
                                    {I18n.t(choice.label)}
                                </Value>
                            </ChoiceContainer>
                        )
                    })}
            </Choices>
            <ErrorOutput errors={props.errors || validationErrors} data-testid="error-single-select"/>
        </Panel>
    )
}

SingleSelect.propTypes = {
    choices: PropTypes.array,
    errors: PropTypes.object,
    onChoiceSelect: PropTypes.func,
    value: PropTypes.string,
    isVertical: PropTypes.bool,
}

SingleSelect.defaultProps = {
    choices: [],
    isVertical: false,
}

export default SingleSelect
