import React from 'react';
import LeadershipAssessmentReport from './LeadershipAssessmentReport';
import CommonPage, { getPageParam } from '../core/components/CommonPage';
import {I18n} from 'react-i18nify';
import FeaturePermission from "../core/components/FeaturePermission";
import { PAGES } from '../core/const';

const LeadershipAssessmentPage = (props) => {
    
    const isAssessmentReportAll = window.location.pathname === `/${PAGES.LeadershipAssessment}/All`;

    return (
        <FeaturePermission name={isAssessmentReportAll ? ["leadership.full", "leadership.team" ]: ""}>
            <CommonPage pageHeading={I18n.t('capture.PageTitleLeadershipAssessment')}
                render={() => <LeadershipAssessmentReport id={getPageParam(props, 'id')}/>}/>
        </FeaturePermission>
    )
}

export default LeadershipAssessmentPage;