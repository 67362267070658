import React from "react"
import collapseExpandAllNodesIcon from "../../../img/icons/ic-expand-collapse-all.svg"
import {useStrategyMapSlice} from "../../../strategyMap/hooks/useStrategyMapSlice"
import {I18n} from "react-i18nify"
import {NODE_TYPES} from "../../../strategyMap/const"
import {isGoal} from "../../../strategyMap/utils"
import AnnotatedNavItem from "./AnnotatedNavItem"
import {useCoreSlice} from "../../hooks/useCoreSlice";


const ExpandCollapseNodes = (props) => {
    const {clickedNodesToChildren, nodeMap, Actions} = useStrategyMapSlice();
    const {Actions: CoreActions} = useCoreSlice();

    const openNodesExist = () => {
        return Object.entries(clickedNodesToChildren)
            .some(([clickedNodePk, children]) => {
                return children !== null
            })
    }
    return <AnnotatedNavItem
            title={openNodesExist() ? I18n.t('strategyMap.toolbar.collapseAll') : I18n.t('strategyMap.toolbar.expandAll')}
            icon={collapseExpandAllNodesIcon}
            onClick={() => {
                if (openNodesExist()) {
                    CoreActions.closeSidebar()
                    Actions.collapseAllNodes()
                    window.scrollTo(0, 0)
                } else {
                    Object.entries(nodeMap).map(([_, node]) => {
                        if (
                            node.children &&
                            node.children.length > 0 &&
                            node.type !== NODE_TYPES.GOAL
                        ) {
                            Actions.setClickedNode(node)
                            if (node.children.some((nodeId) => !nodeMap[nodeId]) && !isGoal(node)) {
                                Actions.fetchChildren(node.id)
                            }
                        }
                    })
                }
            }}/>
}


export default ExpandCollapseNodes