import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";
import {choiceHasDecision} from "../../../dashboard/components/DecisionFilter/Dashboard.functions";
import {DURATION_OPTIONS} from "../../../dashboard/components/Dashboard";
import {ChoiceBox, ChoiceRow} from "../Filters.styles";
import {getTranslatedDemographicsLabel} from "../../../core/utils/demographics";
import React from "react";

const DemographicItems = ({demographicType, item}) => {
    const {filters, Actions, decisions} = useDashboardSlice()
    const currentData = filters[demographicType] || []
    const checked = currentData.some((i) => i.pk === item.pk)

    const disabled = ! choiceHasDecision(
        ['demographics',demographicType],
        item.pk,
        decisions,
        filters.duration || DURATION_OPTIONS.ALL
    );

    const handleItemSelect = () => {
        const dataFilter = checked
            ? currentData.filter( i => item.pk !== i.pk)
            : [...currentData, item];

        Actions.setFilters({filterType: demographicType, data: dataFilter})
    };

    return  <ChoiceRow disabled={disabled} data-testid={"checkbox-container"} onClick={() => !disabled && handleItemSelect()}>
                <ChoiceBox role="button" disabled={disabled}>
                    {checked && <i className="material-icons" data-testid={"checkbox-checked"}>check</i>}
                </ChoiceBox>
                <span>{getTranslatedDemographicsLabel(item)}</span>
            </ChoiceRow>
}

export default DemographicItems;