import React, {useState} from 'react'
import {I18n} from 'react-i18nify'
import NodeIcon from '../../strategyMap/components/NodeIcon'
import {getNodeDisplay} from '../../strategyMap/utils'
import SelectInput from '../../core/ui/form/SelectInput'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {Modal, UserInformationAsList, UserItem, UserTitle} from '../../core/ui/modal/Modal'
import TextInput from '../../core/ui/form/TextInput'
import {Section, SectionTitle} from '../../strategyMap/components/Node.styles'

const NodeItem = (props) => {
    return (
        <UserItem>
            <UserInformationAsList>
                <UserTitle>
                    <NodeIcon node={props.node} />
                    {getNodeDisplay(props.node)} {props.node.title}
                </UserTitle>
            </UserInformationAsList>
        </UserItem>
    )
}

const TargetModal = (props) => {
    const {Actions, goal} = useStrategyMapSlice()
    const [target, setTarget] = useState(props.target || {sf: props.sf.id})

    const handleSave = () => {
        if (target.id) {
            Actions.updateTarget(target)
        } else {
            Actions.createTarget(target)
        }
        props.onClose()
    }

    const desiredOutcomes = goal.desired_outcomes.map((desiredOutcome) => ({
        label: desiredOutcome.text,
        value: desiredOutcome.id,
    }))

    return (
        <Modal 
            isOpen={props.isOpen}
            title={!props.target
                ? I18n.t('strategyMap.target.New Target')
                : I18n.t('strategyMap.target.Update Target')}
            footer={{
                confirmText: I18n.t('strategyMap.target.Save'),
                onConfirm: handleSave,
                onCancel: props.onClose
            }}
        >
            <Section>
                <TextInput
                    label="Title"
                    value={target.title || ''}
                    onChange={(e) => setTarget({...target, title: e.target.value})}
                />
            </Section>
            <Section>
                <SectionTitle>Success Factor</SectionTitle>
                <NodeItem node={props.sf} />
            </Section>

            <Section style={{height: 200}}>
                <SelectInput
                    value={desiredOutcomes.filter((m) => m.value === target.desired_outcome)[0]}
                    label={I18n.t('strategyMap.target.Expected Outcome')}
                    options={desiredOutcomes}
                    onChange={(e) => setTarget({...target, desired_outcome: e.value})}
                />
            </Section>
        </Modal>
    )
}

export default TargetModal
