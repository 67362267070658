import React from "react"
import {splitAt} from 'ramda'
import Avatar from "../../core/components/Avatar"
import styled from "styled-components"
import {SHADOW_COLOUR} from "../../core/ui/_colors"
import {useClickOutside} from "../../core/hooks/useClickOutside"
import {useDebouncedState} from "../../core/hooks/useDebouncedState"
import {Div} from "../../core/ui/Div"


const Bubbles = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    left: ${props => props.shiftRight}px;
`

const UsersList = styled.ul`
    list-style: none;
    display: ${props => props.isVisible ? 'block' : 'none'};
    position: absolute;
    bottom: calc(100vh - 225px - ${props => props.downShift}px);
    right: calc(10vw - 120px);
    width: 50%;
    z-index: 1000;
    box-shadow: 0px 2px 6px 0px ${SHADOW_COLOUR};
    margin: 10px;
    padding: 5px;
    background-color: white;
`

const UserItem = styled.li`
    padding: 5px;
`
const USER_ITEM_HEIGHT = 40

const HiddenUsersDropdown = (props) => {
    const ref = useClickOutside(() => props.setVisible(false))

    return (
        <UsersList ref={ref} isVisible={props.isVisible} downShift={USER_ITEM_HEIGHT * (props.users.length - 1)}>
            {
                props.users
                    .map((user, i) => {
                        return <UserItem key={user.pk}>
                            <Div display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                <Avatar colorCode={user && user.color_code}
                                        profilePicture={user.profile_picture}
                                        text={user.initials}/>
                                {user.full_name}
                            </Div>
                        </UserItem>
                    })
            }
        </UsersList>
    )
}

const COLOUR_CODE_WHITE = 13
const MAX_SHOWN_USERS = 4
const AVATAR_OVERLAP = 20

const ConcurrentUsers = (props) => {
    const [usersMenuVisible, setUsersMenuVisible] = useDebouncedState(false, 500)
    const numberOfOtherUsers = props.users.length - MAX_SHOWN_USERS > 0 ? props.users.length - MAX_SHOWN_USERS : 0
    const [shownUsers, hiddenUsers] = splitAt(MAX_SHOWN_USERS, props.users)

    return (
        <>
            <Bubbles shiftRight={
                numberOfOtherUsers ?
                    shownUsers.length * AVATAR_OVERLAP
                    : (shownUsers.length - 1) * AVATAR_OVERLAP}>{
                shownUsers
                    .map((user, i) => {
                        return <Avatar colorCode={user && user.color_code} shiftLeft={`${i * AVATAR_OVERLAP}px`}
                                       text={user.initials}
                                       profilePicture={user.profile_picture}
                                       key={user.pk}/>
                    })
            }
                {!!numberOfOtherUsers &&
                <Avatar colorCode={COLOUR_CODE_WHITE} fontColour={'black'} shiftLeft={`${shownUsers.length * 20}px`}
                        text={numberOfOtherUsers}
                        onClick={(e) => {
                            e.stopPropagation()
                            setUsersMenuVisible(!usersMenuVisible)
                        }}/>}
            </Bubbles>
            <HiddenUsersDropdown isVisible={usersMenuVisible} setVisible={setUsersMenuVisible} users={hiddenUsers}/>
        </>
    )
}


export default ConcurrentUsers