import React from 'react';
import {I18n} from "react-i18nify";
import CadenceProgressBars from "./Cadence/NavbarCadenceProgressBars";
import {BLUE, ORANGE} from "../../../ui/_colors";

export const NavbarCadenceProgress = ({cadence}) =>
    cadence.isActive()
        ? <CadenceProgressBars
            values={[
                {
                    value: cadence.getTargetsPercentage(),
                    description: I18n.t("strategyMap.cadence.tasksLeft"),
                    color: BLUE
                },
                {
                    value: cadence.getDaysLeftPercentage(),
                    description: I18n.t("strategyMap.cadence.timeLeft"),
                    color: ORANGE
                }]}/>
        : <></>;

export default NavbarCadenceProgress;