import React from 'react'
import 'react-rangeslider/lib/index.css'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {coreSlice} from '../core/redux/slices'
import {I18n} from 'react-i18nify'
import BillOverview from './BillOverview'
import {Redirect} from 'react-router-dom'
import UserManagement from './UserManagement'
import Integrations from './Integrations'
import Demographics from "./Demographics";
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import A42Tabs from "../core/components/Tabs";
import Teams from "./Teams"
import {Container} from '../core/ui/_global'
import {RESTRICTIONS} from "../core/const";
import RolesOverview from "./RolesOverview";

export const Billing = ({user}) => {
    const {features} = useCoreSlice();

    if( Object.values( RESTRICTIONS.Settings ).flat().some( role => user[role] ).length === 0 ){
        return <Redirect to={'/'} />;
    }

    return (
        <Container className="container" data-testid="billing-page">
            <A42Tabs tabs={[
                {
                    key: 'overview',
                    title: I18n.t('billing.Label.Overview'),
                    content: <BillOverview/>,
                    visible: features['account.overview'],
                    users: RESTRICTIONS.Settings.CAN_SEE_LICENSE
                },
                {
                    key: 'overview-roles',
                    title: I18n.t('billing.Label.RolesOverview'),
                    content: <RolesOverview />,
                    visible: true,
                    users: RESTRICTIONS.Settings.CAN_SEE_ROLES_OVERVIEW
                },
                {
                    key: 'usersManagement',
                    title: I18n.t('billing.Label.Users'),
                    content: <UserManagement/>,
                    visible: features['account.users'],
                    users: RESTRICTIONS.Settings.CAN_MANAGE_USERS
                },
                {
                    key: 'Teams',
                    title: I18n.t('billing.Label.Teams'),
                    content: <Teams/>,
                    visible: features['account.integrations'],
                    users: RESTRICTIONS.Settings.CAN_MANAGE_TEAMS
                },
                {
                    key: 'demographics',
                    title: I18n.t('billing.Label.demographics'),
                    content: <Demographics/>,
                    visible: features['account.topology'],
                    users: RESTRICTIONS.Settings.CAN_MANAGE_DEMOGRAPHICS
                },
                {
                    key: 'integrations',
                    title: I18n.t('billing.Label.Integrations'),
                    content: <Integrations/>,
                    visible: features['account.integrations'],
                    users: RESTRICTIONS.Settings.CAN_MANAGE_INTEGRATIONS
                }
            ]}/>
        </Container>
    )
}

const mapStateToProps = ({language, core, user}) => {
    return {
        ...core,
        user,
        currentLanguage: language.current,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...coreSlice.actions,
        },
        dispatch,
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
