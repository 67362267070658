import React from 'react'
import {MainNavIcon} from './Navbar.styles'

const SearchIcon = (props) => {
    return (
        <div onClick={props.onClick} style={{...props.style}} data-testid={'search-navbar'}>
            {
                props.open
                ? <MainNavIcon className="material-icons-outlined" onClick={props.toClose}>close</MainNavIcon>
                : <MainNavIcon className="material-icons-outlined">search</MainNavIcon>
            }
        </div>
    )
}

export default SearchIcon
