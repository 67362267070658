import React, {useEffect, useState} from 'react';
import {I18n} from "react-i18nify";
import styled, {css, keyframes} from "styled-components";
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import {DashboardWidgets, MODAL_ITEMS} from "./CustomizeChartsDashboardConst";
import {SideBarTitle} from "../../core/ui/Sidebar.styles";
import {DashboardItem, DashboardItemContainer, DashboardItemLabel, DiagramLegend} from "./Dashboard.styles";
import {SettingIcon, ZoomIcon} from "./DashboardChart";
import SelectWrapper from "../../core/components/SelectWrapper";
import {useDemographicsSlice} from "../../core/hooks/useDemographicsSlice";
import {RippleLoading} from "../../core/components/Loading";
import {PALE_BLUE} from "../../core/ui/_colors";
import { Modal } from '../../core/ui/modal/Modal';
import { Alert, FormControl } from 'react-bootstrap';
import { SIDEBARS } from '../../core/redux/slices';
import { truncateLabel } from '../../core/utils/string';
import RadioButton from '../../core/ui/RadioButton';
import { OptionRow, OptionText } from '../../strategyMap/components/PrintPreDialog';

import ImageLeaderShip from "../../img/Apps/Leadership.png";
import ImageOrgScan from "../../img/Apps/Orgscan.png";
import ImageStrategyMap from "../../img/Apps/StrategyMap.png";
import { useStrategyMapSlice } from '../../strategyMap/hooks/useStrategyMapSlice';
import { NODE_TYPES } from '../../strategyMap/const';
import { ButtonSecondary } from '../../core/components/Buttons';
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';
import {isFeatureEnabled} from "../../core/components/FeaturePermission";
import {DashboardModules} from "./Dashboard";

const WidgetListItem = styled.div`
    width: calc(100% - 20px);
    text-align: center;
    display: inline-block;
    height: 40px;
    background-color: ${PALE_BLUE};
    padding-left: 20px;
    margin: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 5px;
    z-index: 1;
    position: relative;
    cursor: pointer;
`;

export const TargetDiv = styled.div`
    opacity: ${ a => a.isEditMode ? .6 : 1 };
    position: ${ a => a.meZooming ? 'absolute' : 'relative' };
    z-index: 1;
    margin: 20px 0;
    cursor: pointer;
    .dashboard-item {
        margin: 0;
    }
    ${(props) =>
        props.isEditMode &&
        css`
            &:hover {
                opacity: 1;
            }
        `}
`;

const _OptionRow = styled(OptionRow)`
    opacity: ${(props) => (props.disabled ? `60%` : `100%`)};
`;

const ItemsListContainer = styled.div`
    height: 220px;
    overflow: scroll;
`;

export const DashboardCard = ({flip, title, children, zoom, zooming}) => {
    const {sidebarOpen} = useCoreSlice();
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;

    return (
        <DashboardItemContainer zooming={ zoom }>
            <DashboardItem className='dashboard-item' zoom={ zoom }>
                <DashboardItemLabel>
                    {I18n.t(title)}
                    {zoom}
                    {isEditMode && <SettingIcon onClick={flip} />}
                    <ZoomIcon zoom={zoom} setZoom={zooming} />
                </DashboardItemLabel>
                <DiagramLegend>
                </DiagramLegend>
                { children }
            </DashboardItem>
        </DashboardItemContainer>
    );
}

export const DashboardCardConfig = ({orderID}) => {

    const demographics = useDemographicsSlice();
    const {dashboardWidgetsVisible, Actions, teams} = useCoreSlice();
    const [loading, setLoading] = useState( true );
    const [teamsFetched, setTeamsFetched] = useState( false );

    const updateValue = (filter, value) => {
        setLoading( true );
        const widgets = dashboardWidgetsVisible;
        widgets[orderID].filter[filter] = value.value;

        Actions.updateDashboardWidgets(widgets);
        Actions.updateDashboard();
    }

    useEffect( () => {
        setLoading( false );
    }, [JSON.stringify( dashboardWidgetsVisible )]);

    const shouldFetchTeams = () => teams.length === 0 && !teamsFetched

    useEffect(() => {
        if(shouldFetchTeams()) {
            Actions.fetchTeams();
            setTeamsFetched(true);
        }
    }, [])


    return <>
        {
            dashboardWidgetsVisible[orderID] && Object.keys(dashboardWidgetsVisible[orderID].filter)
                .map( section => {
                    let options = [];
                    if (section === 'team') {
                        options = teams.length > 0
                            ? teams
                                .reduce( (acc,cur) => ([...acc, cur].flat()), [{id: 0, name: 'all'}])
                                .map(a => ({
                                    value: a.id,
                                    label: a.name
                                }))
                            : [];
                    } else {
                        options = demographics[section]
                            ? demographics[section]
                                .reduce( (acc,cur) => ([...acc, cur].flat()), [{pk: 0, name: 'all'}])
                                .map(a => ({
                                    value: a.pk,
                                    label: a.name
                                }))
                            : [];
                    }

                    return <div key={section}>
                        <RippleLoading loading={loading} css={{position: 'absolute', top: '250px', left: '0px'}}/>
                        <div>{I18n.t("billing.demographics." + section)}</div>
                        <SelectWrapper
                            searchable={ false }
                            styles={{}}
                            name="form-field-name"
                            options={ options }
                            onChange={ value => updateValue(section, value) }
                            value={ options?.find(a => a.value === dashboardWidgetsVisible[orderID].filter[section])}
                            clearable={false}
                        />
                    </div>
                }
            )
        }
    </>
}

const ItemsList = (props) => {
    const [selectedItem, setSelectedItem] = useState(props.selectedItemId);
    const [options, setOptions] = useState([]);

    const demographics = useDemographicsSlice();
    const {goalNodes, nodeMap, Actions: SMActions} = useStrategyMapSlice();
    const {teams, Actions: CoreActions} = useCoreSlice();

    const getGoalTitle = (g) => {
        if (g) {
            const node = nodeMap[g.id] || g
            return `Goal #${node.id} ${node.title || ''}`
        }
        return ''
    }

    const getPSFOptions = () => {
        const filteredOptions = Object.entries(nodeMap)
                                    .filter(([_, n]) => n.type === NODE_TYPES.PSF)
                                    .map( ([_, n]) => ({ value: n.id, label: `#${n.id} ${n.title}` }) );
        
        setOptions(filteredOptions);
    }

    useEffect(() => {
        switch (props.modalItems) {
            case MODAL_ITEMS.PSF:
                SMActions.fetchSfs();
                getPSFOptions();
                break;
            case MODAL_ITEMS.TEAMS:
                CoreActions.fetchTeams();
                setOptions(
                    teams.map( (t) => ({ value: t.id, label: t.name }) )
                    );
                break;
            case MODAL_ITEMS.DEPARTMENTS:
                const departments = demographics.department ? demographics.department.map( (department) => ({ value: department.pk, label: department.name }) ) : [];
                setOptions(
                    [{value: 'all', label: 'All'}].concat(departments)
                    );
                break;
            case MODAL_ITEMS.GOALS:
                setOptions(
                    goalNodes.map( (g) => ({ value: g.id, label: getGoalTitle(g) }) )
                    );
                break;
        
            default:
                break;
        }
        
    }, []);

    useEffect(() => {
        if(props.modalItems === MODAL_ITEMS.PSF) {
            getPSFOptions();
        }
    }, [nodeMap]);

    const onSelectOption = (o) => {
        if(!props.selectedItems.includes(o.value)) {
            props.handleOnChange(o);
            setSelectedItem(o.value);
        }
    }
    
    return <>
        {options.map( (o, idx) => 
            <_OptionRow key={idx} disabled={props.selectedItems.includes(o.value)}>
                <RadioButton
                    enabled={ selectedItem === o.value }
                    onClick={ () => onSelectOption(o) }
                />
                <OptionText
                    onClick={ () => onSelectOption(o) }
                >
                    { o.label }
                </OptionText>
            </_OptionRow>
        )}
    </>
}

export const SelectItemWidgetModal = (props) => {    
    const {dashboardWidgetsVisible, Actions} = useCoreSlice();
    const [widgets, setWidgets] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [modalType, setModalType] = useState(false);
    
    useEffect(() => {
        setSelectedItem(null);
        setShowErrorMessage(false);
        if (props.clickedWidget)
            setModalType(MODAL_ITEMS[props.clickedWidget.modalItems])        

    }, [props])

    useEffect(() => {
        if (dashboardWidgetsVisible && modalType) {
            const currentWidgetTypes = dashboardWidgetsVisible.filter((w) => MODAL_ITEMS[w.modalItems] === modalType);
            setWidgets(currentWidgetTypes);
        }
    }, [modalType]);

    const getSelectedItems = (filter) => widgets.filter(w => w.name === props.clickedWidget.name).map(w => w.filter[filter])

    const getModalContent = () => {
        const modalData = {
            title: "",
            selectedItems: null
        }
        switch (modalType) {
            case MODAL_ITEMS.GOALS:
                modalData.title = "Select Goal";
                modalData.selectedItems = getSelectedItems('goal');
                break;
            case MODAL_ITEMS.TEAMS:
                modalData.title = "Select Team";
                modalData.selectedItems = getSelectedItems('team');
                break;
            case MODAL_ITEMS.PSF:
                modalData.title = "Select PSF";
                modalData.selectedItems = getSelectedItems('psf');
                break;
            case MODAL_ITEMS.DEPARTMENTS:
                modalData.title = "Select Department";
                modalData.selectedItems = getSelectedItems('department');
                break;
            default:
                break;
        }

        return modalData;
    }

    const {title, selectedItems} = getModalContent();

    return (
        <Modal 
            isOpen={!!props.clickedWidget}
            title={title}
            footer={{
                onCancel: props.closeModal,
                confirmText: I18n.t('strategyMap.messages.save'),
                onConfirm:() => {
                    if(selectedItem) {
                        const clickedWidget = JSON.parse(JSON.stringify(props.clickedWidget));
                        const filter = clickedWidget.filter;
                        filter[[Object.keys(clickedWidget.filter)[0]]] = selectedItem.value;
                        if (props.selectedItemId) {
                            const updatedDashboardWidgetsVisible = dashboardWidgetsVisible.map(obj => {
                                if (obj.name === clickedWidget.name && Object.values(obj.filter)[0] === props.selectedItemId) {
                                  return {...obj, filter: filter};
                                }
                              
                                return obj;
                            });
                            Actions.updateDashboard(updatedDashboardWidgetsVisible);
                        } else {
                            Actions.updateDashboard(dashboardWidgetsVisible.concat(clickedWidget));
                        }                        
                        props.closeModal();
                    }
                    else {
                        setShowErrorMessage(true);
                    }
                },
            }}
        >
            {showErrorMessage && <Alert variant={'danger'} className="fade in">{I18n.t('dashboard.selectItemToContinue')}</Alert>}
            <ItemsListContainer>
                <ItemsList modalItems={modalType} selectedItemId={props.selectedItemId || null} selectedItems={selectedItems} handleOnChange={(s) => setSelectedItem(s)} />
            </ItemsListContainer>
        </Modal>
    )
}

export const getIconImage = (section) => {
    switch (section) {
        case "StrategyMap":
            return ImageStrategyMap;
        case "LeadershipAssessment":
            return ImageLeaderShip;
        case "OrgScan":
        default:
            return ImageOrgScan;
    }
}

export const WidgetLibrary = (props) => {
    const [clickedWidget, setClickedWidget] = useState(false);
    const [reoderSections, setReorderSections] = useState(false);
    const [filteredDashboardWidgets, setFilteredDashboardWidgets] = useState(DashboardWidgets);
    const {features, Actions, client, dashboardWidgetsVisible, dashboardWidgetsOrder} = useCoreSlice();
    const user = useUserSlice();
    const showAsTiles = props.showAsTiles || false;

    const resetToDefaultDashboard = () => {
        if (confirm(I18n.t('dashboard.resetToDefaultDashboardMessage'))) {
            Actions.updateDashboard(client.custom_dashboard_widgets);
        }
    }

    const setAsDefaultDashboard = () => {
        if (confirm(I18n.t('dashboard.setAsDefaultDashboardMessage'))) {
            Actions.updateClient({ custom_dashboard_widgets: dashboardWidgetsVisible });
        }
    }

    const deleteAllWidgets = () => {
        if (confirm(I18n.t('dashboard.deleteAllWidgetsMessage'))) {
            Actions.updateDashboard([]);
        }
    }

    return <>
        <SelectItemWidgetModal 
            clickedWidget={clickedWidget}
            closeModal={() => setClickedWidget(false)}
        />
        {!showAsTiles && <FormControl
            type="text"
            placeholder={I18n.t('dashboard.search')}
            onChange={(e) => {
                const filtered = Object.keys(DashboardWidgets)
                    .map( section => {
                        const filteredWidgets = DashboardWidgets[section].filter( widget => {
                            return I18n.t(widget.name).toLowerCase().includes(e.target.value.toLowerCase())
                        })
                        return {[section]: filteredWidgets}
                    })
                    .reduce( (acc, cur) => ({...acc, ...cur}), {})
                setFilteredDashboardWidgets(filtered)
            }}
        />}
        {
            reoderSections && <ModalReOrder list={dashboardWidgetsOrder} onClose={ newOrder => {
                Actions.updateDashboardOrder(newOrder);
                Actions.updateDashboard();
                setReorderSections(false);
            } } />
        }
        {!showAsTiles &&
            <div style={{margin: "10px 0"}}>
                <ButtonSecondary fullWidth onClick={() => setReorderSections(true)} >
                    {I18n.t('dashboard.reorder.button')}
                </ButtonSecondary>
                <ButtonSecondary fullWidth onClick={deleteAllWidgets}>
                    {I18n.t('dashboard.deleteAllWidgets')}
                </ButtonSecondary>
                {user.is_admin && <ButtonSecondary fullWidth onClick={setAsDefaultDashboard}>
                    {I18n.t('dashboard.setAsDefaultDashboard')}
                </ButtonSecondary>}
                <ButtonSecondary fullWidth onClick={resetToDefaultDashboard} disabled={client.custom_dashboard_widgets.length === 0}>
                    {I18n.t('dashboard.resetToDefaultDashboard')}
                </ButtonSecondary>
            </div>
        }
        {
            Object.keys(filteredDashboardWidgets)
                .filter( a => isFeatureEnabled(features, DashboardModules[a]) )
                .map( section  =>
                    <div key={section} style={showAsTiles ? {float:'left', width:'33%'} : {}}>
                        <SideBarTitle key={section}>
                            {showAsTiles && <img style={{margin: "0 8px", width: "22px"}} className="material-icons" src={getIconImage(section)} />}
                            { I18n.t(`app.dashboard.${section}`) }
                        </SideBarTitle>
                        {
                             filteredDashboardWidgets[section].map( (widget, i) =>
                                <WidgetListItem 
                                    key={i}
                                    onClick={() => setClickedWidget(widget)}
                                >
                                    {!showAsTiles && <img style={{position: "absolute", left: "8px", width: "22px"}} className="material-icons" src={getIconImage(section)} />}
                                    {truncateLabel(I18n.t(widget.name, 25))}<br />
                                    {I18n.t(widget.description)}
                                </WidgetListItem>
                            )
                        }
                    </div>
                )
        }
    </>;
}

const DragContainer = styled.div``;
const _KeyFrameBegin = keyframes`
      0% {
        transform: rotate(0deg)
      }
      10% {
        transform: rotate(-8deg)
      }
      30% {
        transform: rotate(6deg)
      }
      55% {
        transform: rotate(-3deg)
      }
      80% {
        transform: rotate(1deg)
      }
      100% {
        transform: rotate(-1deg)
      }
`;
const DragAbleItem = styled.div`
    padding-left: 10px;
    width: 300px; 
    height: 40px;
    background-color: lightblue;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    align-content: center;
    touch-action: none;
    transition: scale 0.3s ease-in-out;
    cursor: move;
    :hover {
      size: 110%;
      opacity: 50%;
    };
    animation: ˙${_KeyFrameBegin} 2s linear infinite˙;
`;
const ModalReOrder = ({list, onClose}) => {

    const [state,setState] = useState( list );
    let draggedItem = null;
    let draggedOver = null;

    const startingDragging = (a) => {
        draggedItem = a;

    }
    const draggingOver = (event, listEntry) => {
        event.preventDefault();
        draggedOver = listEntry;

    }
    const finishedDragging = (e) => {
        e.preventDefault();
        let newList = state;
        newList = newList.map( a => a === draggedItem ? draggedItem + "__" : a);
        newList = newList.map( a => a === draggedOver ? draggedItem : a);
        newList = newList.map( a => a === draggedItem + "__" ? draggedOver : a);
        draggedItem = null;
        draggedOver = null;
        setState( newList );
    }

    return <Modal
        isOpen={true}
        title={I18n.t("dashboard.reorder.title")}
        footer={{
            confirmText: I18n.t("app.buttons.confirm"),
            onConfirm: () => onClose( state ),
            cancelText: I18n.t("app.buttons.cancel"),
            onCancel: () => onClose( )
        }}
    >
        <div style={{marginTop: 20, marginBottom: 20}}>{I18n.t("dashboard.reorder.description")}</div>
        <DragContainer>
            { state
                .map( a => <DragAbleItem
                    onDragStart={ () => startingDragging(a) }
                    onDragOver={ (e) => draggingOver(e, a) }
                    onDragEnd={ finishedDragging }

                    draggable={true}
                    key={a}>{a}</DragAbleItem>)
            }
        </DragContainer>
    </Modal>
}