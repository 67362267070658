import React from 'react'
import {I18n} from 'react-i18nify'
import {Description, Modal} from '../../../core/ui/modal/Modal'

const NCDescription = (props) => {
    return <>
        {props.description.map( ([title, description]) => {
            return <>
                <strong>{title}</strong><br />
                {description}<br />
            </>
        })}
    </>
}

const InfoModal = (props) => {
    const descriptionContent = props.isNC ? 
                            <NCDescription description={props.description} /> :
                            props.description

    return (
        <Modal 
            isOpen={props.isOpen}
            title={props.title && props.title}
            footer={{
                confirmText: I18n.t('sidebar.addParent.dialog.buttons.add'),
                cancelText: I18n.t('strategyMap.messages.ok'),
                onConfirm: null,
                onCancel: props.onRequestClose
            }}
        >
            <Description>{descriptionContent}</Description>
        </Modal>
    )
}

export default InfoModal
