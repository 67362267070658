import _ from 'lodash'
import {I18n} from 'react-i18nify'

export const validateValue = (rules, value) => {
    const validations = {
        required: (v) => v && v.length > 0,
        email: (v) => {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(v).toLowerCase())
        },
        min: (v, minimum) => v && v.length >= minimum,
        max: (v, maximum) => v && v.length <= maximum,
    }
    const defaultValidationMessages = {
        required: () => I18n.t("capture.Form.Status.Validation.EmptyField"),
        email: () => I18n.t("capture.Form.Status.Validation.eMail"),
        min: (value) => { return I18n.t("capture.Form.Status.Validation.Min").replace("%s", value)},
        max: (value) => I18n.t("capture.Form.Status.Validation.Max").replace("%s", value),
    }
    const errors = {}
    rules.split('|').forEach((rule) => {
        const r = rule.split(':')
        if (validations[r[0]] && !validations[r[0]](value, r[1])) {
            errors[r[0]] = defaultValidationMessages[r[0]] && defaultValidationMessages[r[0]](r[1])
        }
    })
    return [_.isEmpty(errors), errors]
}

export const isSelected = (collection, choice) => {
    return _.findIndex(collection, choice) > -1
}