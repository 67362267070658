import styled from 'styled-components'
import {LIGHT_GREY} from '../../ui/_colors'
import {isMobile} from 'react-device-detect'

export const Input = styled.input`
    margin: 0 10px;
    width: ${isMobile ? '90vw' : '500px'};
    padding: 0 10px;
    height: 35px;
    border: 1px solid ${LIGHT_GREY};

    :focus {
        outline: -webkit-focus-ring-color 0;
        outline: -moz-focus-ring-color 0;
    }
`

export const SearchContainer = styled.div`
    position: relative;
    ${isMobile && 'padding-right: 0px;'}
    .icon {
        height: 16px !important;
    }
`

export const NodeSearchMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    padding: 5px;
    cursor: pointer;
    width: ${props => props.isMobile ? '100vw' : 'auto'};

    @media (max-width: 768px) {
        position: ${({open}) => (open ? 'fixed' : 'inherit')};
        top: 80px;
        left: 0;
        right: 0;
    }
`

export const SearchIconContainer = styled.div`
    padding-left: 5px;
    padding-top: 7px;
`

export const Container = styled.div`
    position: ${isMobile ? 'inherit' : 'relative'};
`

export const PopoverContainer = styled.div`
    position: absolute;
    margin-top: ${isMobile ? '60px' : 0};
    padding-top: ${isMobile ? '0px' : '0'};
    width: ${isMobile ? '90vw' : '500px'};
    max-height: 400px;
    background: white;
    overflow-y: scroll;
    top: ${isMobile ? '60px' : '35px'};
    left: ${isMobile ? '5vw' : '10px'};
    right: ${isMobile ? '5vw' : '10px'};
    display: ${(p) => (p.open ? 'block' : 'none')};
`

export const ItemDescription = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`

export const Hint = styled.small`
    color: ${LIGHT_GREY};
`

export const ItemContainer = styled.div`
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid ${LIGHT_GREY};
    padding: 10px 10px 10px 5px;
    display: flex;
    align-items: center;
`

export const NoResultContainer = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding: 10px 5px;
    align-items: center;
`
