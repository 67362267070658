import ItemUser from './ItemUser'
import PropTypes from 'prop-types'
import React from 'react'

const SquadMember = ({
    squadMember,
    totalChampions,
    onRequestClose,
    isUserChampion,
    isUserSponsor,
}) => {
    return (
        <ItemUser
            squadMember={squadMember}
            isUserChampion={isUserChampion}
            isUserSponsor={isUserSponsor}
            totalChampions={totalChampions}
            onRequestClose={onRequestClose}
        />
    )
}

SquadMember.propTypes = {
    squadMember: PropTypes.object,
    totalChampions: PropTypes.number,
    onRequestClose: PropTypes.func,
}

export default SquadMember
