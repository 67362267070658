import React from 'react';
import {DashboardItem, DashboardItemContainer, DashboardItemLabel, DiagramLegend} from "./Dashboard.styles";
import {I18n} from "react-i18nify";
import {getTooltip} from "../../Charts/ChartsOrgScan";

export const DragDropImage = () => {

    return <div id={"dashboardDrag"} style={{left: '-10000px', position: 'absolute', width: '350px'}}>
        <DashboardItemContainer zooming={ false }>
            <DashboardItem className="dashboard-item" zoom={false}>
                <DashboardItemLabel>
                    <b id={'dashboardDrag_title'}>OrgScan Graph</b>
                    <div style={{float: 'right'}}>
                        {getTooltip("", `"`)}
                    </div>
                </DashboardItemLabel>
                <DiagramLegend />
                <div className={"panel-body complete"}>
                    <div>{ I18n.t("dashboard.shadowCard.description") }</div>
                </div>
            </DashboardItem>
        </DashboardItemContainer>
    </div>
};

export const DragStartReplaceImage = (event, title) => {
    const titleDIV = document.querySelector('#dashboardDrag_title');
    titleDIV.innerHTML = I18n.t(title);
    event.dataTransfer.setDragImage(document.querySelector('#dashboardDrag'), 175, 200);
}