import React, {useEffect, useReducer, useState} from 'react'
import {I18n} from 'react-i18nify'
import Checkbox from '../../capture/signifier/Checkbox'
import NodeIcon from '../../strategyMap/components/NodeIcon'
import {getNodeDisplay, isExperiment, isGoal, isSF} from '../../strategyMap/utils'
import {useNode} from '../../strategyMap/hooks/useNode'
import SelectInput from '../../core/ui/form/SelectInput'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {
    Description,
    Modal,
    SearchContainer,
    SearchIcon,
    SearchInput,
    SelectFields,
    UserInformationAsList,
    UserItem,
    Users,
    UserTitle,
} from '../../core/ui/modal/Modal'
import {showPopupMessage} from '../../core/utils/notifications'

const SearchBox = (props) => {
    return (
        <SearchContainer>
            <SearchIcon />
            <SearchInput
                placeholder={I18n.t('dashboard.search')}
                onChange={(value) => props.onSetFilter(value.target.value)}
            />
        </SearchContainer>
    )
}

const NodeItem = (props) => {
    return (
        <UserItem>
            <Checkbox onClick={() => props.onChooseNode(props.node.id)} checked={props.checked} />
            <UserInformationAsList>
                <UserTitle>
                    <NodeIcon node={props.node} />
                    {getNodeDisplay(props.node)} {props.node.title}
                </UserTitle>
            </UserInformationAsList>
        </UserItem>
    )
}

const initialState = {filteredNodes: [], selectedNodes: [], nodes: []}

function reducer(state, [type, payload]) {
    switch (type) {
        case 'reset':
            return {
                filteredNodes: payload,
                selectedNodes: [],
                nodes: [],
            }
        case 'setFilteredNodes':
            return {
                ...state,
                filteredNodes: payload,
            }
        case 'setSelectedNodes':
            return {
                ...state,
                selectedNodes: payload,
            }
        case 'setNodes':
            return {
                ...state,
                nodes: payload,
                filteredNodes: payload,
            }
        default:
            return state
    }
}

const ConvertNode = (props) => {
    const {linkableNodes} = useNode()
    const {Actions} = useStrategyMapSlice()
    const [nodeType, setNodeType] = useState('')
    const currentNode = props.nodeMap[props.nodeId]

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        filteredNodes: linkableNodes,
    })
    const {filteredNodes, selectedNodes, nodes} = state
    const nodeAvailableTypes = [
        {label: 'NC', value: 'N'},
        {label: 'PSF', value: 'P'},
        {label: 'CSF', value: 'C'},
        {label: 'Experiment', value: 'E'},
    ]

    useEffect(() => {
        dispatch(['reset', linkableNodes])
        setNodeType('')
    }, [props.isOpen])

    useEffect(() => {
        if (nodeType) {
            const ns = linkableNodes.filter((node) => {
                if (nodeType === 'N') {
                    return isExperiment(node) || isSF(node)
                } else if (nodeType === 'E') {
                    return isSF(node)
                }
                return isGoal(node)
            })

            dispatch(['setNodes', ns])
            dispatch(['setSelectedNodes', []])
        }
    }, [nodeType])

    const handleChooseNode = (nodeId) => {
        if (selectedNodes.indexOf(nodeId) >= 0) {
            selectedNodes.splice(selectedNodes.indexOf(nodeId), 1)
        } else {
            selectedNodes.push(nodeId)
        }
        dispatch(['setSelectedNodes', selectedNodes])
    }

    const handleSetFilter = (searchStringFilter) => {
        searchStringFilter = searchStringFilter.toLowerCase()
        const filteredNodesList = nodes.filter(
            (node) =>
                (node.title && node.title.toLowerCase().includes(searchStringFilter)) ||
                node.description.toLowerCase().includes(searchStringFilter) ||
                node.sid.toString().includes(searchStringFilter),
        )

        dispatch(['setFilteredNodes', filteredNodesList])
    }

    const handleClose = () => {
        props.onRequestClose()
        dispatch(['reset', linkableNodes])
    }

    const handleSave = () => {
        handleClose()
        showPopupMessage({
            title: I18n.t('strategyMap.messages.warning'),
            message: I18n.t('sidebar.convertNode.warningMessage'),
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.messages.yes'),
            onConfirmCallback: () => {
                Actions.convertNode({
                    parents: selectedNodes,
                    nodeType,
                    nodeId: currentNode.id,
                })
                handleClose()
            },
            onCancelCallback: () => {},
        })
    }

    return (
        <Modal 
            isOpen={props.isOpen}
            title={I18n.t('sidebar.convertNode.dialog.title')}
            footer={{
                confirmText: I18n.t('sidebar.convertNode.dialog.buttons.add'),
                onConfirm: handleSave,
                onCancel: handleClose
            }}
        >
            <div>
                <SelectInput
                    value={nodeType}
                    label={I18n.t('sidebar.convertNode.input.nodeType')}
                    options={nodeAvailableTypes.filter((n) => n.value !== currentNode.type)}
                    onChange={(e) => setNodeType(e.value)}
                />
            </div>
            <Description>{I18n.t('sidebar.convertNode.dialog.description')}</Description>
            <SelectFields>
                <SearchBox onSetFilter={handleSetFilter} />
                <Users>
                    {!nodeType && <p>{I18n.t('sidebar.convertNode.dialog.nodelistHelp')}</p>}
                    {nodeType &&
                        filteredNodes.map((node) => (
                            <NodeItem
                                key={node.id}
                                node={node}
                                checked={selectedNodes.indexOf(node.id) >= 0}
                                onChooseNode={handleChooseNode}
                            />
                        ))}
                </Users>
            </SelectFields>
        </Modal>
    )
}

export default ConvertNode
