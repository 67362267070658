import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {values} from 'ramda'


import {getTranslatedDemographicsLabel, ORDERED_DEMOGRAPHICS_TYPE} from '../../../core/utils/demographics'
import {useDemographicsSlice} from '../../../core/hooks/useDemographicsSlice'
import {useUserSlice} from '../../../strategyMap/hooks/useUserSlice'
import {DemographicsTitle} from './Demographics.styles'
import UserDemographicsList, {activeDemographics} from './UserDemographicsList'
import { InfoLabel } from '../Shared.styles'
import signifiers from '../../signifier'
import {isLoggedIn} from '../../../core/utils/auth'


const UserDemographics = (props) => {
    const {Actions, ...demographics} = useDemographicsSlice()
    const userSlice = useUserSlice()
    const [isUserDemographicsOpen, setIsUserDemographicsOpen] = useState(false)
    const [selectAbleDemographics, setSelectAbleDemographics] = useState([])
    const [selectedDemographics, setSelectedDemographics] = useState(false)
    if (!isLoggedIn()) {
        return null
    }

    useEffect(() => {
        if ((demographics.city || demographics.department || demographics.country || demographics.role || demographics.years)) {
            setSelectAbleDemographics(activeDemographics(demographics))
        }
    }, [demographics.city, demographics.department, demographics.country, demographics.role, demographics.years])

    useEffect(() => {
        props.handleChange && props.handleChange(props.fieldName, selectedDemographics)
    }, [selectedDemographics])

    useEffect(() => {
        const defaultChoice = {}

        const selected = {};
        if (userSlice.demographics) {
            Object.keys(userSlice.demographics).forEach((demographicType) => {
                if (demographicType !== 'user'){
                    defaultChoice[demographicType] = userSlice.demographics[demographicType]
                    if(selectAbleDemographics[demographicType] && selectAbleDemographics[demographicType].length === 1){
                        defaultChoice[demographicType] = selectAbleDemographics[demographicType][0]['pk']
                    }
                }
            })

            Object.keys(userSlice.demographics).forEach(demo => selected[demo] = defaultChoice[demo])
        }
        const numberOfSelectedDemographics = values(selected).filter(v => v !== null).length - 1
        const numberOfSelectableDemographics = values(selectAbleDemographics).filter(v => v !== null).length
        setIsUserDemographicsOpen(_.isEmpty(selected) || numberOfSelectedDemographics !== numberOfSelectableDemographics)
        setSelectedDemographics(selected);

    }, [userSlice.demographics, selectAbleDemographics])

    useEffect(() => {
        if (!demographics.city) {
            Actions.fetchDemographics()
        }
        if (!userSlice.pk) {
            userSlice.Actions.getUser()
        }
    }, []);

    if (_.isEmpty(selectAbleDemographics)) return null

    return (
        <div className="demographics">
            <DemographicsTitle>{I18n.t('capture.Personal Data')}</DemographicsTitle>
            {isUserDemographicsOpen ?
                <div className="demographics-body" data-testid="Demographics-body-with-editing">
                    <InfoLabel>{I18n.t('capture.Panel.Topic.DemographicsUncollapsedInfo')}</InfoLabel>
                    {!_.isEmpty(selectAbleDemographics) &&
                        ORDERED_DEMOGRAPHICS_TYPE.filter((demographicType) =>
                            selectAbleDemographics.hasOwnProperty(demographicType) && !!selectAbleDemographics[demographicType],
                        ).map((demographicType, key) => {
                            const data = {
                                field_name: demographicType,
                                is_vertical: true,
                                required: true,
                                signifier: 'SingleSelect',
                                header: I18n.t(
                                    'capture.Panel.Topic.' + demographicType + '-header',
                                ),
                                choices: selectAbleDemographics[demographicType].map((item) => ({
                                    value: item.pk,
                                    label: getTranslatedDemographicsLabel(item)
                                })),
                            }
                            return (
                                <div key={key}>
                                    {signifiers[data.signifier](data, {
                                        onChoiceSelect: (choice, fieldName) => {
                                            setSelectedDemographics({...selectedDemographics, [fieldName]: choice.value})
                                        },
                                        value: selectedDemographics[demographicType],
                                        onError: props.handleError,
                                        shouldValidate: props.shouldValidate,
                                    })}
                                </div>
                            )
                        })}
                </div>
                : <UserDemographicsList
                    selectableDemographics={selectAbleDemographics}
                    selectedDemographics={selectedDemographics}
                    onEditClick={() => setIsUserDemographicsOpen(true)}/>
            }
        </div>
    )
}


UserDemographics.propTypes = {
    handleChange: PropTypes.func,
    shouldValidate: PropTypes.bool,
    onError: PropTypes.func,
    fieldName: PropTypes.string,
}


export default UserDemographics
