import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css'
import '../node_modules/react-notifications/dist/react-notifications.css'
import '../node_modules/react-confirm-alert/src/react-confirm-alert.css'
import '../node_modules/react-tabs/style/react-tabs.scss'
import 'react-quill/dist/quill.snow.css'
import Raven from 'raven-js'
import {getTenantName} from './core/utils/network'
import version from './git_commit_version'
import commitDate from './git_commit_date'
import buildDate from './git_build_date'
import store from './redux/store'
import App from './App'
import {strategyMapSocket} from './core/contexts/SM_WebsocketContext'
import HTML5Backend from 'react-dnd-html5-backend'
import history from './core/utils/history'
import {showBrowserAlert} from './core/utils/browser'
import {authSocket} from "./core/contexts/AuthWebsocketContext";
import TagManager from 'react-gtm-module';

if( module.hot )
    module.hot.accept();

function sentryFilter(data) {
    const filtered_status = [401, 403, 409];
    let isFilteredStatus =
        data.error && data.error.response && filtered_status.includes(data.error.response.status)
    isFilteredStatus |=
        data.extra && data.extra.response && filtered_status.includes(data.extra.response.status)
    return !isFilteredStatus
}

function serverEnvironment() {
    const host = getTenantName(true)
    switch (host) {
        case 'organic-agility':
            return 'production'
        case 'palimpalim':
            return 'staging'
        default:
            return 'local'
    }
}

if (process.env.NODE_ENV === 'production') {
    if(
        window.location.hostname.split(".").length < 3 || 
        window.location.hostname.split(".")[0].toLowerCase() === "www"
    ) {
        window.location.href = 'https://www.organic-agility.com/'
        throw new Error('No client present')
    }

    const environment_sentry = 'frontent (react ' + React.version + ') - '
    Raven.config('https://e5c6a55b1620437e8ef34b78454b1ae8@o472833.ingest.sentry.io/5507017', {
        shouldSendCallback: sentryFilter,
        ignoreErrors: ['Invalid Token', /Request failed with status code 40[13]/, /ChunkLoadError/],
        environment: getTenantName(true).startsWith('localhost')
            ? environment_sentry + 'local testing'
            : environment_sentry + serverEnvironment()
    }).install();

    Raven.setExtraContext({
        release: version.version,
        tags: {
            tenant: getTenantName(),
            git_commit: version.version,
            git_commit_date: commitDate.commit_date,
            git_build_date: buildDate.build_date
        }
    })
}

showBrowserAlert()

const isAssessmentReport = window.location.pathname.match(/^(?=.*leadership-assessment)(?!.*All).*/) !== null;
const isLeadershipAssessmentInstance =  getTenantName() === 'leadershipassessment';

if( isLeadershipAssessmentInstance &&  !isAssessmentReport ) {
    TagManager.initialize({
        gtmId: 'GTM-NC75M3Z'
    });
}

ReactDOM.render(
    <App
        store={store}
        history={history}
        HTML5Backend={HTML5Backend}
        strategyMapSocket={strategyMapSocket}
        authSocket={authSocket}
    />,
    document.getElementById('root')
)
