import {invertObj} from 'ramda'
import {BLUE, GREEN, MAGENTA, ORANGE, YELLOW} from '../core/ui/_colors'

export const ITEM_TYPES = {
    NODE: 'node',
    LIST: 'list',
}
export const TIME_AREAS = {
    CONFIRMED: 'confirmed',
    VALIDATING: 'validating',
    POTENTIAL: 'potential',
}

export const SIDEBAR_MODE = {
    EDIT: 'edit',
    CREATE: 'create',
}

export const NODE_TYPES = {
    PSF: 'P',
    CSF: 'C',
    GOAL: 'G',
    NC: 'N',
    EXPERIMENT: 'E',
}

export const NODE_TYPES_TO_RESOURCE_TYPES = {
    NC: 'NCNode',
    SF: 'SFNode',
    PSF: 'SFNode',
    CSF: 'SFNode',
    GOAL: 'GoalNode',
    EXPERIMENT: 'ExperimentNode',
}

export const RESOURCE_TYPES_TO_NODE_TYPES = invertObj(NODE_TYPES_TO_RESOURCE_TYPES)

export const NODE_ACTION_KEYS = {
    P: {
        action_key: 'SF',
        title: 'Potential Success Factor',
        icon_key: 'psf',
        resourcetype: NODE_TYPES_TO_RESOURCE_TYPES.PSF,
    },
    C: {
        action_key: 'SF',
        title: 'Confirmed Success Factor',
        icon_key: 'csf',
        resourcetype: NODE_TYPES_TO_RESOURCE_TYPES.CSF,
    },
    E: {
        action_key: 'Experiment',
        title: 'Experiment',
        icon_key: 'exp',
        resourcetype: NODE_TYPES_TO_RESOURCE_TYPES.EXPERIMENT,
    },
    G: {
        action_key: 'Goal',
        title: 'Goal',
        icon_key: 'goal',
        resourcetype: NODE_TYPES_TO_RESOURCE_TYPES.GOAL,
    },
    N: {
        action_key: 'NC',
        title: 'Necessary Condition',
        icon_key: 'nc',
        resourcetype: NODE_TYPES_TO_RESOURCE_TYPES.NC,
    },
}
export const NODE_TYPES_TO_EXTENDED_NAMES = {
    P: 'PSF',
    C: 'CSF',
    G: 'Goal',
    N: 'NC',
    E: 'Experiment',
}
export const NODE_TYPES_TO_FULL_NODE_TYPES = {
    P: 'Potential Success Factor',
    C: 'Confirmed Success Factor',
    G: 'Goal',
    N: 'Necessary ConditionC',
    E: 'Experiment',
}

export const NODE_TYPES_TO_NODE_COLOR_MAP = {
    P: YELLOW,
    C: ORANGE,
    G: GREEN,
    N: MAGENTA,
    E: BLUE,
}

export const NODE_STATES = {
    N: {
        fulfilled: 'fulfilled',
        unfulfilled: 'unfulfilled',
    },
    E: {
        created: 'created',
        started: 'started',
        finished: 'finished',
        failed: 'failed',
        successful: 'successful',
    },
}

export const EXPERIMENT_SHOWN_AFTER_ENDING_IN_DAYS = 14