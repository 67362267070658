import styled from 'styled-components'
import {BLACK, BLACK_GREY, BRIGHT_GREY, GREY, TRANSPARENT, WHITE} from '../../core/ui/_colors'
import {ButtonTertiary} from '../../core/components/Buttons'

export const InspectFrame = styled.div`
    position: relative;
    width: 380px;
    padding: 20px;
    display: inline-block;
    vertical-align: top;
    background-color: ${WHITE};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
`

export const InspectHeader = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 8px;
    float: left;
`

export const InspectHeaderHelp = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 8px;
`

export const InspectDescription = styled.div`
    margin-bottom: 13px;
`

export const InspectImageCenter = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
`

export const InspectButtonClear = styled(ButtonTertiary)`
    padding-top: 5px;
    padding-bottom: 11px;
    float: right;
`

export const InspectDetailIndex = styled.div`
    padding-top: 13px;
    padding-bottom: 13px;
    width: 46px;
    color: ${BLACK_GREY};
    font-size: 16px;
    text-align: right;
    margin-right: 10px;
    float: left;
    clear: left;
`

export const InspectDetailTitle = styled.div`
    padding-top: 13px;
    padding-bottom: 13px;
    color: ${BLACK_GREY};
    font-size: 16px;
    float: left;
    display: flex;
    align-items: flex-end;
    width: 200px;
`

export const InspectDetailLink = styled.div`
    padding-top: 13px;
    padding-left: 40px;
    float: left;
    display: flex;
    align-items: flex-end;
`

export const InspectGraphFrame = styled.div`
    margin-right: 10px;
    padding-top: 20px;
    width: 100%;
    height: auto;
    background-color: ${WHITE};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
`

export const InspectGraphChartWrapper = styled.div`
    width: 500px;
    margin-left: auto;
    margin-right: auto;

    .modebar-group {
        background-color: ${TRANSPARENT} !important;
        .modebar-btn {
        	&.active {
				.icon {
					color: ${BLACK} !important;
					path {
						fill: ${BLACK} !important;
					}
				}
			} 
            .icon {
                color: ${GREY} !important;
                path {
                    fill: ${GREY} !important;
                }
            }
        }
    }
`

export const InspectGraphsChartStyle = styled.div`
    margin-right: ${(props) => (props.even ? '0' : '20px')};
    font-size: 70%;
    width: calc( 50%  - 10px );
    float: left;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 9px 0 21px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${WHITE};
`

export const InspectGraphsChartTitle = styled.h3`
    margin-top: 10px;
    border-bottom: 2px solid ${BRIGHT_GREY};
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    padding-bottom: 15px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
`
export const InspectGraphsChartContainer = styled.div`
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    min-height: 335px;
    .chart-label {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        cursor: default;
    }
    .recharts-wrapper {
        width: 100% !important;
        height: 100% !important;
        min-width: 250px;
        .recharts-surface {
            width: 100% !important;
            height: 300px;
        }
    }
`
