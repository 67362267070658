import {useContext} from 'react'
import NodeContext from '../contexts/node'

export const useNode = () => {
    const state = useContext(NodeContext);
    if( ! state ){
        throw new Error("Can't use useNode when no current open node is set");
    }
    return state;
}
