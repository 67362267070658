import React, {useEffect, useState} from 'react'
import Avatar from '../../core/components/Avatar'
import {I18n} from 'react-i18nify'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import NoUsers from './NoUsers'
import {DARK_GREY, WHITE} from '../../core/ui/_colors'

export const sortSquadMembersByFullname = (a, b) => {
    return sortUsersByUserFullname(a.user, b.user)
}

export const sortUsersByUserFullname = (a, b) => {
    const userA = a.full_name.toUpperCase()
    const userB = b.full_name.toUpperCase()

    let comparison = 0
    if (userA > userB) {
        comparison = 1
    } else if (userA < userB) {
        comparison = -1
    }
    return comparison
}

const SquadOverlayTrigger = (props) => {
    const [acceptedSquadMembers, setAcceptedSquadMembers] = useState([])

    useEffect(() => {
        setAcceptedSquadMembers(
            [...props.node.squad]
                .sort(sortSquadMembersByFullname)
                .filter((member) => member.is_accepted),
        )
    }, [props.node.squad])

    if (acceptedSquadMembers.length > 0)
        return acceptedSquadMembers.map(squadMember =>
            <OverlayTrigger
                key={squadMember.id}
                placement={"top"}
                delay={400}
                overlay={
                    <Tooltip
                        id={`popover-squad-member-${squadMember.id}`}
                        style={{
                            background: WHITE,
                            color: DARK_GREY,
                            borderRadius: 0,
                            opacity: 1
                        }}
                    >
                        {squadMember.user.full_name}
                    </Tooltip>
                }
            >
                { Avatar({
                        colorCode: squadMember.user.color_code,
                            text: squadMember.user.initials,
                            profilePicture: squadMember.user.profile_picture,
                            pull: "left",
                            dataTestid: `squad-member-${squadMember.user.pk}` })
                }
            </OverlayTrigger>
        )

    return <NoUsers text={I18n.t('strategyMap.nodes.noMembersYet')} />
}

export default SquadOverlayTrigger
