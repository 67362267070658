import Point from "./point.js";
import Triangle from "./triangle.js";
import Trapezoid from "./trapezoid.js";

class Triad extends Triangle {
    /**
     * This represents an OrgScan triad in the 2D plane. A Triad is defined
     * by a large equilateral triangle and 4 smaller equilateral triangles with
     * the same size. The smaller triangles are placed in the corners of the
     * larger triangle, and one is in the center of the larger triangle. The
     * triangle in the center has vertices in the midpoints of the sides of the
     * other smaller triangles, leaving the space for 3 trpezoids.
     */
    static DEFAULT_WEIGHT = 0.33;
    static SIDE_SIZE = 1;
    static HEIGHT = Math.sqrt(3) / 2 * Triad.SIDE_SIZE;
    static SMALL_SIDE_SIZE = 2 / 5 * Triad.SIDE_SIZE;
    static SMALL_HEIGHT = Math.sqrt(3) / 2 * Triad.SMALL_SIDE_SIZE;

    constructor() {
        super(new Point(0, 0), new Point(Triad.SIDE_SIZE, 0), new Point(Triad.SIDE_SIZE / 2, Triad.HEIGHT), "Triad");
        this.Left = new Triangle(
            new Point(0, 0),
            new Point(Triad.SMALL_SIDE_SIZE, 0),
            new Point(Triad.SMALL_SIDE_SIZE / 2, Triad.SMALL_HEIGHT),
            "Left"
        );
        this.Right = new Triangle(
            new Point(3 / 5 * Triad.SIDE_SIZE, 0),
            new Point(Triad.SIDE_SIZE, 0),
            new Point(4 / 5 * Triad.SIDE_SIZE, Triad.SMALL_HEIGHT),
            "Right"
        );
        this.Top = new Triangle(
            new Point(1 / 2 * Triad.SIDE_SIZE - 1 / 2 * Triad.SMALL_SIDE_SIZE, 3 / 5 * Triad.HEIGHT),
            new Point(1 / 2 * Triad.SIDE_SIZE + 1 / 2 * Triad.SMALL_SIDE_SIZE, 3 / 5 * Triad.HEIGHT),
            new Point(1 / 2 * Triad.SIDE_SIZE, Triad.HEIGHT),
            "Top"
        );
        this.Center = new Triangle(
            Point.midpoint(this.Left.v2, this.Left.v3),
            Point.midpoint(this.Right.v1, this.Right.v3),
            Point.midpoint(this.Top.v1, this.Top.v2),
            "Center"
        );
        this.LeftCenterRight = new Trapezoid(
            this.Center.v1,
            this.Center.v2,
            this.Left.v2,
            this.Right.v1,
            "LeftCenterRight"
        );
        this.LeftCenterTop = new Trapezoid(
            this.Center.v1,
            this.Center.v3,
            this.Left.v3,
            this.Top.v1,
            "LeftCenterTop"
        );
        this.RightCenterTop = new Trapezoid(
            this.Center.v2,
            this.Center.v3,
            this.Right.v3,
            this.Top.v2,
            "RightCenterTop"
        );
        this.shapes = [
            this.Center, this.Left, this.Right, this.Top, // First the center and the 3 corners
            this.LeftCenterRight, this.LeftCenterTop, this.RightCenterTop // Then the 3 trapezoids
        ];
    }

    findContainingShape(point) {
        if (this.containsPoint(point)) {
            for (const shape of this.shapes) {
                if (shape.containsPoint(point)) {
                    return shape.name;
                }
            }
        }
    }
}

export default Triad;