import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {DARK_GREY, LIGHT_GREY} from '../../core/ui/_colors'
import {Span} from '../../core/ui/generic'

const CHECKBOX_SIZE = 25;

const CheckboxIcon = styled.i`
    font-size: 22px;
    line-height: ${CHECKBOX_SIZE}px;
    padding: 0;

    ${(props) =>
        props.disabled &&
        css`
            color: ${LIGHT_GREY};
        `}
    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
    }
`
const Checkbox_ = styled.div`
    height: ${CHECKBOX_SIZE}px;
    width: ${CHECKBOX_SIZE}px;
    min-width: ${CHECKBOX_SIZE}px;
    border: ${(props) => (props.disabled ? `1px solid ${LIGHT_GREY}` : `1px solid ${DARK_GREY}`)};
`
const Container = styled.div`
    display: flex;
    align-items: center;
`

const Span_ = styled(Span)`
    color: ${DARK_GREY};
    margin-left: 10px;
    line-height: ${CHECKBOX_SIZE}px;
    @media only screen and (min-width: 320px) {
        width: 85%;
    }
`

const Checkbox = (props) => {
    const label = props.label ? <Span_ dangerouslySetInnerHTML={{__html: props.label}} /> : '';

    const [clicked, setClicked] = useState(props.checked);

    useEffect( () => setClicked(props.checked), [props.checked]);

    const click = () => {
        if (props.disabled) {
            return;
        }
        props.onClick( !clicked );
        setClicked( !clicked );
    }

    return (
        <Container>
            <Checkbox_
                onClick={click}
                role="button"
                disabled={props.disabled}
                data-testid={`checkbox-button${props.testId ? '-' + props.testId : ''}`}
            >
                {clicked && (
                    <CheckboxIcon
                        data-testid={`check-icon${props.testId ? '-' + props.testId : ''}`}
                        className="material-icons"
                        disabled={props.disabled}
                    >
                        check
                    </CheckboxIcon>
                )}
            </Checkbox_>
            {label}
        </Container>
    )
}

Checkbox.propTypes = {
    disabled: PropTypes.bool, // effect on hover
    checked: PropTypes.bool, // effect on hover
    label: PropTypes.string, // effect on hover
}

Checkbox.defaultProps = {
    disabled: false,
    checked: false,
    label: undefined,
}

export default Checkbox
