import {createSlice} from 'redux-starter-kit';
import _ from 'lodash';
import * as ErrorUtils from '../../core/utils/error';
import {NODE_TYPES, NODE_TYPES_TO_RESOURCE_TYPES, SIDEBAR_MODE} from '../const';
import {isSF} from '../utils';
import {map, mergeAll, values} from 'ramda';
import {I18n} from 'react-i18nify';
import {showPopupMessage} from '../../core/utils/notifications';
import {joinTables} from '../../core/utils/array';
import { windowOpen } from '../../core/utils/navigation';

const getCurrentState = (state) => {
  try {
    return JSON.parse(JSON.stringify(state));
  } catch (e) {
    return null;
  }
};

const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isFetchingUsers: false,
    isSfLoadCalled: false,
    isSaving: false,
    isFetchingChildren: false,
    errors: ErrorUtils.getApiErrors(action.payload),
})
const fetchingHandler = (state) => ({...state, isFetching: true})
const savingHandler = (state) => ({...state, isSaving: true})

const buildNodes = (state, payload) => {
    const nodes = _.sortBy(payload, (o) => o.priority || 9999)
    const nodeMap = state.nodeMap
    nodes.forEach((node) => {
        if (!nodeMap[node.id]) {
            nodeMap[node.id] = node
        }

        if (node.resourcetype === NODE_TYPES_TO_RESOURCE_TYPES.SF) {
            state.isFetchingSfs = false
        } else if (node.resourcetype === NODE_TYPES_TO_RESOURCE_TYPES.GOAL) {
            state.isFetchingGoals = false
        }
    })
    state.nodeMap = nodeMap
    state.isFetching =
        state.isFetchingGoals || state.isFetchingChildren || state.isFetchingSfs || false

    state.clickedNodes.forEach((nodeId) => {
        if (!state.nodeMap[nodeId]) {
            state.clickedNodes.delete(nodeId)
        }
    })
}

const mergeIntoNodeMap = (nodes, nodeMap) => {
    const newNodeMaps = map((newNode) => {
        return {[newNode.id]: newNode}
    }, nodes)
    return {...nodeMap, ...mergeAll(newNodeMaps)}
}

export const strategyMapInitialState = {
    currentExperimentCanvas: null,
    nodeMap: {},
    users: [],
    squadMembers: [],
    currentOpenNodeId: null,
    showHiddenExperiment: false,
    experimentCanvasVisible: false,
    isFetchingUsers: false,
    isFetchingSquadMembers: false,
    isFetchingActivities: false,
    isFetching: false,
    isFetchingNode: false,
    isFetchingSfs: false,
    isFetchingGoals: false,
    goal: {},
    goalNodes: [],
    strategicIterations: [],
    isSaving: false,
    errors: {},
    nodeActivity: {},
    showModalSquad: false,
    pendingNode: {},
    clickedNodes: new Set(),
    activities: [],
    allowedPriorities: [],
    sidebarMode: SIDEBAR_MODE.EDIT,
    lastInteractedNode: null,
    popoverTarget: {},
    runningExperiments: [],
    experiments: [],
}

export const strategyMapSlice = createSlice({
    slice: 'strategyMap',
    initialState: strategyMapInitialState,
    reducers: {
        fetchNode: fetchingHandler,
        fetchNodeSuccess: (state, action) => {
            state.isFetching = state.isFetchingGoals || state.isFetchingSfs || false;
            if (action.payload)
                state.nodeMap[action.payload.id] = action.payload;
        },
        fetchNodes: fetchingHandler,
        setIsFetching: (state, action) => {
            state.isFetching = action.payload
        },
        fetchNodesSuccess: (state, action) => {
            state.isFetching = state.isFetchingGoals || state.isFetchingSfs || false
            buildNodes(state, action.payload)
        },
        fetchSfs: (state) => ({
            ...state,
            isFetching: true,
            isFetchingSfs: true,
            isSfLoadCalled: true,
        }),
        fetchSfsSilently: (state) => ({
            ...state,
            isSfLoadCalled: true,
            isFetchingSfs: true
        }),
        fetchSfsSuccess: (state, action) => {
            state.isFetchingSfs = false
            state.isFetching = state.isFetchingGoals || state.isFetchingSfs || false
            buildNodes(state, action.payload)
        },
        clearNodes: (state) => ({...state, isFetching: true}),
        generateDecisions: (state) => ({...state, isFetching: true}),
        generateSMNodes: (state) => ({...state, isFetching: true}),
        importSMNodes: (state) => ({...state, isFetching: true}),
        exportSM: (state) => ({...state, isFetching: true}),
        exportSMSuccess: (state) => ({
            ...state,
            isFetching: false,
        }),
        clearSMSuccess: (state, action) => ({
            ...state,
            nodeMap: {[action.payload.id]: action.payload},
            isFetching: false,
        }),
        fetchStrategicIterations: (state, action) => {
        },
        fetchStrategicIterationsSuccess: (state, action) => {
            state.strategicIterations = action.payload
        },
        addStrategicIteration: (state, action) => {
        },
        addStrategicIterationSuccess: (state, action) => {
            state.strategicIterations = [...state.strategicIterations, action.payload]
        },
        updateStrategicIteration: (state, action) => {
        },
        updateStrategicIterationSuccess: (state, action) => {
            state.strategicIterations = joinTables("id", state.strategicIterations, [action.payload])
        },
        deleteStrategicIteration: (state, action) => {
        },
        deleteStrategicIterationSuccess: (state, action) => {
            state.strategicIterations = state.strategicIterations.filter(si => si.id !== action.payload)
        },
        fetchChildren: (state) => ({...state, isFetchingChildren: true}),
        fetchChildrenSuccess: (state, action) => {
            state.isFetchingChildren = false
            buildNodes(state, action.payload)
        },
        fetchChildrenSilently: (state) => ({...state}),
        fetchChildrenSilentlySuccess: (state, action) => {
            buildNodes(state, action.payload)
        },
        fetchAllExperiments: fetchingHandler,
        fetchAllExperimentsSuccess: (state, action) => {
            state.isFetching = false;
            state.experiments = action.payload.data;
            state.runningExperiments = [...action.payload.data.filter(experiment => experiment.status === 'started')];
        },
        getNode: (state) => {
            state.isFetchingNode = true
        },
        setShowModalSquad: (state, action) => {
            state.showModalSquad = action.payload
        },
        getNodeSuccess: (state, action) => {
            state.isFetchingNode = false;
            state.nodeMap[action.payload.id] = action.payload;
        },
        fetchActivities: (state) => {
            state.isFetchingActivities = true
        },
        fetchActivitiesSuccess: (state, action) => {
            state.isFetchingActivities = false
            state.activities = action.payload
        },
        deactivateUser: (state) => {
            state.isSaving = true
        },
        deactivateUserSuccess: (state, action) => {
            const deactivatedUser = action.payload
            state.users = state.users.map((user) =>
                user.pk === deactivatedUser.pk ? deactivatedUser : user,
            )
        },
        setExperimentCanvasVisible: (state, action) => ({
            ...state,
            experimentCanvasVisible: action.payload,
        }),
        setHiddenNodesVisibility: (state) => {
            state.showHiddenExperiment = !state.showHiddenExperiment
        },
        addSponsor: (state) => {
            state.isSaving = true
        },
        addSponsorSuccess: (state, {payload}) => {
            state.isSaving = false
            state.nodeMap[payload.id] = payload
            state.goal = payload
        },
        switchSponsor: (state) => {
            state.isSaving = true
        },
        switchSponsorSuccess: (state, {payload}) => {
            state.isSaving = false
            state.nodeMap[payload.id] = payload
            state.goal = payload
        },
        deleteSponsor: (state) => {
            state.isSaving = true
        },
        deleteSponsorSuccess: (state, {payload}) => {
            state.isSaving = false
            state.nodeMap[payload.id] = payload
            state.goal = payload
        },
        deleteExperimentSuccessCondition: (state) => ({
            ...state,
            isSaving: true,
        }),
        deleteExperimentFailureCondition: (state) => ({
            ...state,
            isSaving: true,
        }),
        createTarget: (state) => ({
            ...state,
            isSaving: true,
        }),
        createTargetSuccess: (state, action) => {
            state.isSaving = true
            state.nodeMap[action.payload.sf].targets.push(action.payload)
        },
        updateTarget: (state) => ({
            ...state,
            isSaving: true,
        }),
        updateTargetSuccess: (state, action) => {
            state.isSaving = true
            const index = _.findIndex(state.nodeMap[action.payload.sf].targets, {
                id: action.payload. id,
            })
            state.nodeMap[action.payload.sf].targets[index] = action.payload
        },
        deleteTarget: (state) => ({
            ...state,
            isSaving: true,
        }),
        deleteTargetSuccess: (state, action) => {
            state.isSaving = true
            state.nodeMap[action.payload.sf].targets = state.nodeMap[
                action.payload.sf
            ].targets.filter((target) => target.id !== action.payload.id)
        },
        deleteDesiredOutcome: (state) => ({
            ...state,
            isSaving: true,
        }),
        logNodeActivity: (state, action) => {
            return {
                ...state,
                nodeActivity: {
                    ...state.nodeActivity,
                    [action.payload.node.id]: action.payload,
                },
            }
        },
        clearNodeActivity: (state) => {
            return {
                ...state,
                nodeActivity: {},
            }
        },
        fetchAllowedPriorities: (state) => state,
        fetchAllowedPrioritiesSuccess: (state, action) => {
            state.allowedPriorities = action.payload
        },
        fetchActiveUsersSuccess: (state, action) => {
            state.isFetchingUsers = false
            state.users = action.payload
        },
        fetchActiveUsers: (state) => {
            state.isFetchingUsers = true
        },
        addNode: (state) => ({
            ...state,
            isFetching: true
        }),
        openNode: (state) => {},
        fetchingDone: (state) => ({
            ...state,
            isFetchingGoals: false,
            isFetching: false
        }),
        fetchGoals: (state) => ({
            ...state,
            isFetching: true,
            isFetchingGoals: true,
        }),
        fetchGoalsSuccess: (state, action) => {

            const firstGoal = parseInt( action.payload[0].id );
            let goalID = parseInt( localStorage.getItem('lastOpenedMap') || firstGoal );

            if( ! action.payload.find( a => a.id === goalID) )
                goalID = firstGoal;

            localStorage.setItem('lastOpenedMap', goalID);

            state.goal = goalID;
            state.experimentCanvasVisible = false;

            state.isFetchingGoals = false;
            state.goalNodes = action.payload;
        },
        getGoal: (state) => ({
            ...state,
            isFetching: true,
        }),
        getGoalSuccess: (state, action) => {
            const goalNodeId = action.payload;
            const goalNode = getCurrentState(state).goalNodes.find((goal) => goal.id === goalNodeId);

            state.nodeMap = {[goalNodeId]: goalNode};
            state.goal = goalNode;
            state.experimentCanvasVisible = false;
            state.isFetching = state.isFetchingGoals || state.isFetchingSfs || false;
        },
        createGoal: (state) => ({
            ...state,
            isFetching: true,
        }),
        createGoalSuccess: (state, action) => {
            const node = action.payload
            state.nodeMap = {[node.id]: node}
            state.goal = node
            state.isFetching = false
            state.experimentCanvasVisible = false
            state.sidebarMode = SIDEBAR_MODE.EDIT
            state.currentOpenNodeId = node.id
            state.lastInteractedNode = node.id
        },
        convertNode: (state) => ({
            ...state,
            isFetching: true,
        }),
        addNodeSuccess: (state, action) => {
            const node = action.payload;
            state.currentOpenNodeId = node.fromWebsocket ? state.currentOpenNodeId : node.id;
            node.parents &&
                node.parents.forEach((parentId) => {
                    if (state.nodeMap[parentId])
                        state.nodeMap[parentId].children.push(node.id);
                })
            state.nodeMap[node.id] = node;
            state.isFetching = false;
        },
        deleteExperimentSuccessConditionSuccess: (state) => ({
            ...state,
            isSaving: false,
        }),
        deleteExperimentFailureConditionSuccess: (state) => ({
            ...state,
            isSaving: false,
        }),
        deleteDesiredOutcomeSuccess: (state) => ({
            ...state,
            isSaving: false,
        }),
        updatePriority: (state) => ({
            ...state,
            isSaving: true,
        }),
        updateNodeMapUserData: (state, action) => {
            const [userData, nodeMap] = [action.payload, state.nodeMap]
            const updateNodeUserData = (node) => {
                let nodeToUpdate = node
                if (nodeToUpdate.champions && nodeToUpdate.champions.includes(userData.pk)) {
                    const updatedChampionsData = map(
                        (champion) => (champion.pk === userData.pk ? userData : champion),
                        nodeToUpdate.champions_data,
                    )
                    nodeToUpdate = {
                        ...nodeToUpdate,
                        ...{champions_data: updatedChampionsData},
                    }
                }
                if (node.squad) {
                    const updatedSquadData = map(
                        (squad) =>
                            squad.user.pk === userData.pk ? {...squad, ...{user: userData}} : squad,
                        nodeToUpdate.squad,
                    )
                    nodeToUpdate = {...nodeToUpdate, ...{squad: updatedSquadData}}
                }
                if (node.sponsors) {
                    const updatedSponsorData = map(
                        (sponsor) =>
                            sponsor.user.pk === userData.pk
                                ? {...sponsor, ...{user: userData}}
                                : sponsor,
                        nodeToUpdate.sponsors,
                    )
                    nodeToUpdate = {
                        ...nodeToUpdate,
                        ...{sponsors: updatedSponsorData},
                    }
                }
                if (node.experiment_team_members) {
                    const updatedExperimentTeamsData = map(
                        (experimentTeam) =>
                            experimentTeam.user.pk === userData.pk
                                ? {...experimentTeam, ...{user: userData}}
                                : experimentTeam,
                        nodeToUpdate.experiment_team_members,
                    )
                    nodeToUpdate = {
                        ...nodeToUpdate,
                        ...{experiment_team_members: updatedExperimentTeamsData},
                    }
                }
                return nodeToUpdate
            }
            state.nodeMap = map(updateNodeUserData, nodeMap)
        },
        updatePrioritySuccess: (state, action) => {
            state.isSaving = false
            action.payload.forEach((nodeId, index) => {
                state.nodeMap[nodeId].priority = index + 1
            })
        },
        terminateExperiment: (state) => state,
        applyToExperiment: (state) => state,
        leaveExperiment: (state) => state,
        approveVolunteerRequest: (state) => state,
        declineVolunteerRequest: (state) => state,
        deleteNode: (state) => ({
            ...state,
            isFetching: true,
        }),
        deleteGoal: (state, action) => ({
            ...state,
            goalNodes: state.goalNodes.filter((g) => g.id !== action.payload.id),
            isFetching: true,
        }),
        deleteAllChildNodes: (state) => ({
            ...state,
            isFetching: true,
        }),
        deleteAllChildNodesSuccess: (state) => ({
            ...state,
            isFetching: false,
        }),
        deleteNodeSuccess: (state, action) => {
            const node = action.payload
            state.isFetching = false

            const nodeMap = {...state.nodeMap}
            delete nodeMap[node.id]
            node.parents.forEach((parentId) => {
                nodeMap[parentId].children = nodeMap[parentId].children.filter(
                    (childId) => childId !== node.id,
                )
            })
            state.nodeMap = nodeMap
        },
        archiveNode: (state, action) => {
            state.isFetching = true
        },
        archiveChildNodes: (state, action) => {
            state.isFetching = true
        },
        unarchiveNodes: (state, action) => {
            state.isFetching = true
        },
        updateNodesSuccess: (state, action) => {
            state.isFetching = false
            state.nodeMap = mergeIntoNodeMap(action.payload, state.nodeMap)
        },
        updatePendingNode: (state, action) => ({
            ...state,
            pendingNode: {...state.pendingNode, ...action.payload},
        }),
        updateNode: (state, action) => {
            state.isSaving = true
            if (action.payload.addParents) {
                action.payload.addParents.forEach((parent) => {
                    state.nodeMap[parent] && state.nodeMap[parent].children.push(action.payload.id)
                })
            }
            if (action.payload.removeParent) {
                const children = state.nodeMap[action.payload.removeParent].children.filter(
                    (id) => id !== action.payload.id,
                )
                state.nodeMap[action.payload.removeParent].children = children
            }
            if (action.payload.removeNodeFromOtherGoal) {
                const children = state.nodeMap[action.payload.removeNodeFromOtherGoal].children.filter(
                    (id) => id !== action.payload.id,
                )
                state.nodeMap[action.payload.removeNodeFromOtherGoal].children = children
            }
        },
        sendSharedLink: () => {},
        updatePeriodOfInactivity: (state, action) => {
            const {payload} = action
            const sfNode = state.nodeMap[payload.pk]
            state.nodeMap[payload.pk] = {
                ...sfNode,
                period_of_inactivity: payload.period_of_inactivity,
                pk: payload.pk,
            }
        },
        updateNodeSuccess: (state, action) => {
            state.isSaving = false
            state.nodeMap[action.payload.id] = action.payload
            if (action.payload.type === NODE_TYPES.GOAL) {
                state.goal = action.payload
            }
            if (action.payload.type === NODE_TYPES.EXPERIMENT) {
                action.payload.parents.forEach((parent) => {
                    state.nodeMap[parent].has_running_experiment =
                        action.payload.is_started && !action.payload.is_finished
                    state.nodeMap[parent].has_successful_experiment =
                        action.payload.is_finished && action.payload.successful
                })
            }
        },
        showNodeCreationSidebar: (state, action) => {
            return {
                ...state,
                pendingNode: {
                    ...action.payload,
                    type: action.payload.sf_type || action.payload.type,
                    description: action.payload.description,
                    title: action.payload.title,
                    parents: [action.payload.parentNode || state.currentOpenNodeId],
                },
                currentExperimentCanvas: null,
                experimentCanvasVisible: false,
                sidebarMode: SIDEBAR_MODE.CREATE,
                currentOpenNodeId: null,
            }
        },
        showNodeEditingSidebar: (state, action) => ({
            ...state,
            pendingNode: {},
            currentExperimentCanvas:
                action.payload.resourcetype === NODE_TYPES_TO_RESOURCE_TYPES.EXPERIMENT
                    ? action.payload.resourcetype
                    : null,
            experimentCanvasVisible: false,
            sidebarMode: SIDEBAR_MODE.EDIT,
            currentOpenNodeId: action.payload.id,
            lastInteractedNode: action.payload.id,
        }),
        onHideSidebar: (state) => ({
            ...state,
            pendingNode: {},
            currentExperimentCanvas: null,
            sidebarMode: null,
            currentOpenNodeId: null,
            lastInteractedNode: null
        }),
        collapseAllNodes: (state) => {
            state.lastInteractedNode = null
            state.clickedNodes = new Set()
        },
        setClickedNode: (state, action) => {
            const {id} = action.payload

            if (state.clickedNodes.has(id)) {
                state.clickedNodes.delete(id)
            } else {
                state.clickedNodes.add(id)
            }

            if(state.clickedNodes.size > 0)
                state.lastInteractedNode = id
            else
                state.lastInteractedNode = null
        },
        linkToNode: (state, action) => {
            const {id} = action.payload
            state.lastInteractedNode = id
        },
        linkOtherGoalNodeToNode: (state, action) => {
            const {parentID, childID} = action.payload;
            state.nodeMap[parentID].children.push( childID );
            buildNodes(state, values(state.nodeMap))
        },
        moveNode: (state, action) => {
            const {lastX, nextX, timeArea, nodeParentId, nodeId, clickedNodesToChildren} =
                action.payload

            if (
                nodeParentId &&
                nodeId &&
                !isSF(state.nodeMap[nodeId]) &&
                clickedNodesToChildren[nodeParentId]
            ) {
                state.nodeMap[nodeId].time_area = timeArea
            } else if (lastX !== nextX && nodeId) {
                state.nodeMap[nodeId].time_area = timeArea
            }
            buildNodes(state, values(state.nodeMap))
        },
        removePrimaryParent: savingHandler,
        removePrimaryParentSuccess: (state) => ({...state, isSaving: false}),
        setPopoverTarget: (state, action) => {
            state.popoverTarget = {[action.payload.key]: action.payload.target}
        },
        closePopover: (state) => {
            state.popoverTarget = {}
        },
        fetchNodesFailure: errorHandler,
        inviteUserToSquad: (state) => {
            state.isSaving = true
        },
        inviteUserToSquadSuccess: (state, action) => {
            state.squadMembers.push({
                ...action.payload,
                user: _.find(state.users, {pk: action.payload.user}),
            })
            state.isSaving = false
        },
        removeSquadMember: (state) => {
            state.isSaving = true
        },
        removeSquadMemberSuccess: (state, action) => {
            state.squadMembers = state.squadMembers.filter(
                (squadMember) => squadMember.id !== action.payload,
            )
            state.isSaving = false
        },
        acceptUserToSquad: (state) => {
            state.isSaving = true
        },
        acceptUserToSquadSuccess: (state, action) => {
            state.isSaving = false
            const squadMemberIndex = _.findIndex(state.squadMembers, {
                id: action.payload.id,
            })
            const acceptedUserIsInSquad = squadMemberIndex > -1
            if (acceptedUserIsInSquad) {
                state.squadMembers[squadMemberIndex] = {
                    ...action.payload,
                    user: _.find(state.users, {pk: action.payload.user}),
                }
            }
        },
        fetchSquadMembers: (state) => {
            state.isFetchingSquadMembers = true
        },
        fetchSquadMembersSuccess: (state, action) => {
            state.squadMembers = action.payload
            state.isFetchingSquadMembers = false
        },
        createTrelloBoard: (state) => {
            state.isFetching = true
        },
        createTrelloBoardSuccess: (state, action) => {
            state.isFetching = false
            if (action.payload.is_ok) {
                const {id, url, description, tool, nodeId} = action.payload
                const nodeMap = {...state.nodeMap}
                const nodeIntegrations = nodeMap[nodeId].node_integrations || []
                nodeIntegrations.push({id, url, description, tool})
                nodeMap[nodeId].node_integrations = nodeIntegrations
                state.nodeMap = nodeMap
                windowOpen(url, '_blank')
            } else {
                if (action.payload.status === 401) {
                    localStorage.removeItem('trelloAccessToken')
                }

                const warning = {
                    title: I18n.t('strategyMap.messages.trelloConnError'),
                    message: action.payload.msg,
                    confirmLabel: I18n.t('strategyMap.messages.ok'),
                    onConfirmCallback: () => {},
                }
                showPopupMessage(warning)
            }
        },
        removeIntegrationLink: (state) => {
            state.isSaving = true
        },
        removeIntegrationLinkSuccess: (state, action) => {
            const [nodeId, integrationId] = action.payload
            const nodeMap = {...state.nodeMap}
            nodeMap[nodeId].node_integrations = nodeMap[nodeId].node_integrations.filter(
                (integration) => integration.id !== integrationId,
            )
            state.nodeMap = nodeMap
            state.isSaving = false
        },
        addNodeIntegration: fetchingHandler,
        addNodeIntegrationSuccess: (state, action) => {
            state.isFetching = false
            const nodeIntegration = action.payload
            const nodeMap = {...state.nodeMap}
            nodeMap[nodeIntegration.node].node_integrations.push(nodeIntegration)
            state.nodeMap = nodeMap

            if (nodeIntegration.tool === 'slack') {
                const warning = {
                    title: I18n.t('strategyMap.nodes.actionRequired'),
                    message: I18n.t('strategyMap.nodes.inviteOrganicAgilityAppDescription'),
                    confirmLabel: I18n.t('strategyMap.nodes.goToTheChannel'),
                    onConfirmCallback: () => windowOpen(nodeIntegration.url)
                }
                showPopupMessage(warning)
            }
        },
        connectSlackChannel: fetchingHandler,
        connectSlackChannelSuccess: (state, action) => {
            state.isFetching = false
            state.nodeMap[action.payload.id] = action.payload
        },
        importStrategyMapFromMiro: (state) => ({...state, isFetching: true}),
        requestFailure: errorHandler,
    },
})
