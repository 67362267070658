import React, {useEffect} from 'react'
import {I18n} from 'react-i18nify';
import styled from "styled-components";

const NoData = styled.div`
    padding-top: 200px;
    padding-bottom: 200px;
`

export const LineChart = (props) => {

    const setRange = (props) => {
        if( props && Object.keys(props).includes('x') )
            return {range: [props.x.min, props.x.max], fixedrange: true}
        return {rangemode: 'tozero', fixedrange: true}
    }

    const isEmpty = props.data.length === 1 && props.data[0].length === 0;

    useEffect(() => {

        if( isEmpty )
            return;

        if (document.getElementById(props.testId) && props.data && props.data.length) {
            const layout = {
                height: props.coordinate.height || '100%',
                width: props.coordinate.width || '700',
                margin: {
                    l: 300,
                    r: 0,
                    t: 0,
                    b: 100
                },
                xaxis: setRange(props.range),
                yaxis: {fixedrange: true, showticklabels: true, dtick: 1,tickwidth: 1},
                hovermode: false
            }
            const defaultSettings = {
                showTips: false,
                displayModeBar: false,
            }
            
            const plotly = require('plotly.js')
            
            plotly.newPlot(
                props.testId,
                props.data.map((trace) => ({
                    x: trace.map((a) => a.x),
                    y: trace.map((b) => b.y + ( typeof b.y === 'string' || b.y instanceof String ? "   " : "")),
                    type: 'line',
                    name: trace[0].name,
                    yaxis: {type: 'category'},
                    line: {
                        color: trace[0].color,
                    },
                })),
                layout,
                defaultSettings,
            )
        }
    }, [props.testId, props.data])

    return isEmpty ? 
            <NoData id={props.testId} data-testid={props.testId}>{I18n.t("charts.NoData")}</NoData> :
            <div id={props.testId} data-testid={props.testId} />
}
