import styled from 'styled-components'

export const Card = styled.div`
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    margin: 10px;
    min-height: 50px;
    padding: 10px;
    width: 100%;
`

export const CardEditor = styled.div`
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    margin: 10px;
    min-height: 50px;
    width: 100%;
    padding: 10px;
    .RichTextEditor__root___2QXK- {
        border: 0 !important;
    }
    .ql-toolbar {
        border: 0px !important;
        background: #fafafa !important;
    }
    .ql-container {
        border: 0px !important;
        min-height: 150px;
    }
    .ql-editor {
        min-height: 150px;
    }
    .ql-blank::before {
        font-style: normal;
    }
    .rich-text-editor {
        box-shadow: none !important;
    }
`

export const CanvasContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: scroll;
`

export const InfoText = styled.div`
    padding: 10px;
    width: 100%;
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 0.64px;
    text-align: center;
    color: #3d7fb0;
`
