import {all, call, put, takeEvery} from 'redux-saga/effects'
import Api from '../api'
import {analysisSlice} from './slices'
import settings from '../../config/settings'
import {consoleError} from '../../core/utils/error'
import { windowOpen } from '../../core/utils/navigation'

function* fetchClientStatisticsSaga() {
    try {
        const response = yield call(Api.fetchClientStatistics)
        yield put(analysisSlice.actions.fetchClientStatisticsSuccess(response.data))
    } catch (error) {
        consoleError(error)
    }
}

function* saveImageSaga(action) {
    try {
        yield call(Api.saveImage, action.payload)
        yield put(analysisSlice.actions.saveImageSuccess())
    } catch (error) {
        consoleError(error)
    }
}

function* saveReportAnswers(action) {
    try {
        yield call(Api.saveReportAnswers, action.payload)
        yield put(analysisSlice.actions.saveReportAnswersSuccess(true))
    } catch (error) {
        consoleError(error)
        yield put(analysisSlice.actions.requestFailure(error))
    }
}

function* generateReport(action) {
    try {
        yield call(Api.generateReport, action.payload)
        yield put(analysisSlice.actions.generateReportSuccess())
        windowOpen(
            `${window.location.origin}${settings.apiBaseURL}/sreport/generate-report/${action.payload}/?token=${localStorage.token}&download_generated=true`
        )
    } catch (error) {
        consoleError(error)
        yield put(analysisSlice.actions.requestFailure(error))
    }
}

function* getReportAnswers(action) {
    try {
        const response = yield call(Api.getReportAnswers, action.payload)
        yield put(analysisSlice.actions.getReportAnswersSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(analysisSlice.actions.requestFailure(error))
    }
}

export default function* flow() {
    yield all([
        takeEvery(analysisSlice.actions.fetchClientStatistics, fetchClientStatisticsSaga),
        takeEvery(analysisSlice.actions.saveImage, saveImageSaga),
        takeEvery(analysisSlice.actions.saveReportAnswers, saveReportAnswers),
        takeEvery(analysisSlice.actions.generateReport, generateReport),
        takeEvery(analysisSlice.actions.getReportAnswers, getReportAnswers),
    ])
}
