import React from 'react';
import {I18n} from 'react-i18nify';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {DashboardItem, DashboardItemContainer, DashboardExperimentTitle, DiagramLegend} from './Dashboard.styles';
import {PAGES} from '../../core/const';
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice';
import {TimeElapsedDisplay} from '../../core/components/TimeElapsedDisplay';
import Charts from '../../Charts/Charts';
import {ChartType} from '../../Charts/ChartsConfig';
import {NODE_STATES} from '../../strategyMap/const';
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import styled from "styled-components";
import {SettingIcon} from "./DashboardChart";
import { SIDEBARS } from '../../core/redux/slices';


const ExperimentChart = ({experiment, flip}) => {

    const core = useCoreSlice();
    const strategyMap = useStrategyMapSlice();
    const isEditMode = core.sidebarOpen === SIDEBARS.Dashboard;

    const goToExperiment = (goal, nodeId) => {
        core.Actions.gotoPage(PAGES.StrategyMap);
        strategyMap.Actions.openNode({goal: goal, id: nodeId});
    }

    if (experiment === undefined)
        return null;

    const labelStateExperimentFromFinished =
        [NODE_STATES.E.finished, NODE_STATES.E.successful, NODE_STATES.E.failed].includes( experiment.status )
        && I18n.t(`strategyMap.experiment.${experiment.status}`);

    const legendRightContent = 'align-right col-xs-' + labelStateExperimentFromFinished ? 6 : 3

    return (
        <DashboardItemContainer key={experiment.id}>
            <DashboardItem className="dashboard-item-experiment">
                <div style={{position: 'absolute', right: 15, top: 15}}>
                    { flip && isEditMode && <SettingIcon onClick={flip} /> }
                </div>
                <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-${experiment.id}`} style={{opacity: 1}}>
                            <b>{experiment.title || I18n.t("strategyMap.experiment.untitled")}</b>
                        </Tooltip>
                    }
                >
                    <DashboardExperimentTitle
                        onClick={() => goToExperiment(experiment.goal, experiment.id)}
                    >
                        {experiment.title || I18n.t("strategyMap.experiment.untitled")}
                    </DashboardExperimentTitle>
                </OverlayTrigger>
                <DiagramLegend>
                    <div className={'col-xs-6'}>
                        {"EXP #" + experiment.id}
                    </div>
                    <div className={legendRightContent}>
                        <TimeElapsedDisplay
                            duration={experiment.duration}
                            startDate={experiment.startDate}
                            status={experiment.status}
                        />
                    </div>
                </DiagramLegend>
                <div className="panel-body experiment-body"
                     style={{textAlign: 'center', display: "block", marginLeft: "auto", marginRight: "auto"}}>
                    <FinalStatus status={experiment.status} />
                    <Charts Type={ChartType.ExperimentActivity} data={[experiment]} />
                </div>
            </DashboardItem>
        </DashboardItemContainer>
    )
}

const FinalStatus = ({status}) => {

    if( ! ['successful', 'failed', 'finished'].includes( status) )
        return null;

    const Container = styled.div`
        width: calc(100% - 70px);
        height: 110px;
        position: absolute;
    `;
    const Stamp = styled.div`
        display: inline-flex;
        marginLeft: auto;
        marginRight: auto;
        transform: ${ props => "rotate(" + (props.isSuccessful ? '-20' : props.isFailed ? '+20' : '0') + "deg)"};

        padding: 8px;
        padding-right: 15px;
        padding-left: 15px;
        border-width: 5px;
        border-style: solid;
        border-radius: 20px;
        borderColor: ${props => props.isSuccessful ? 'green' : props.isFailed ? 'red' : 'gray' };
        fontSize: 20px;
        opacity: 20%;
        color: ${props => props.isSuccessful ? 'green' : props.isFailed ? 'red' : "gray"};
    `;

    const isSuccessful = status === 'successful';
    const isFailed = status === 'failed';

    return <Container>
        <Stamp isSuccessful={isSuccessful} isFailed={isFailed}>
            { I18n.t("strategyMap.experiment." + status ) }
            <i className="material-icons pull-right" style={{paddingLeft: 10}}>{ isSuccessful ? "thumb_up" : isFailed ? "thumb_down" : "cancel" }</i>
        </Stamp>
    </Container>;
}

export default ExperimentChart;