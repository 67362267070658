import Point from "./point.js";

class Shape {
    /**
     * This represents a shape in the 2D plane.
     * @param {String} name The name of the shape
     */
    constructor(name = "Shape") {
        if (this.constructor === Shape) {
            // Throw an error if an instance of the Shape class is created
            throw new Error("Cannot instantiate an object of the Shape class");
        }
        this.name = name;
    }

    containsPoint(P) {
        /**
         * Method to check if a point is inside the shape.
         * @param {Point} P The point to check
         */
        throw new Error("containsPoint(P) must be implemented by subclass");
    }

    get area() {
        /**
         * Method to calculate the area of the shape.
         */
        throw new Error("area must be implemented by subclass");
    }

    get perimeter() {
        /**
         * Method to calculate the perimeter of the shape.
         */
        throw new Error("perimeter must be implemented by subclass");
    }
}

export default Shape;