import {barSchema, cultureSchemaLabel} from './ChartColorSchema'
import {ChartColumns, ChartType, SubTypes} from './ChartsConfig'
import * as Color from './ChartColor'
import {OrderingAll} from './StackedBar/agileBarConfig'
import {
    CHARTS_CARTESIAN, CHARTS_DEMOGRAPHICS, CHARTS_MULTIPLE_CHOICE,
    CHARTS_STATISTICS, CHARTS_TRIANGLES, CHARTS_HeadCount
} from "./ChartsOrgScan";

const timeTakenOptions = Object.keys(barSchema.period);
const ownerOptions = Object.keys(barSchema.decisionOwner);
const coloringBarHighlighting = (colorschema, id) => ({
    ...colorschema.confirmed,
    validating: {
        ...colorschema.validating,
        [id]: Color.getBarColors(Color.ColorsTypes.blue),
    },
});

const REPORT_CHARTS_CARTESIAN = CHARTS_CARTESIAN.map (e => ({...e, size: 500}) );
const REPORT_CHARTS_STATISTICS = CHARTS_STATISTICS.map(e => ({...e, size: 1000}) );
const REPORT_CHARTS_STATISTICS_TABLES = [
    {
        title: 'charts.Topic.Statistics.emotionByQuestion.CompromiseRole',
        Type: ChartType.HeatMapCrossProduct,
        subTypes: [[SubTypes.bar.decisionOwner, SubTypes.bar.role], SubTypes.special.compromise],
        columns: Object.keys(ChartColumns.special.compromise),
        colorSchema: Color.ColorsTypes.t6,
        size: 500,
        ordering: OrderingAll,
        testId: 'table-crossproduct-owner-Role-compromise'
    },
    {
        title: 'charts.Topic.Statistics.emotionByQuestion.CompromiseProblemType',
        Type: ChartType.HeatMapCrossProduct,
        subTypes: [
            [SubTypes.bar.type, SubTypes.triangle.T3, SubTypes.triangle.T4, SubTypes.triangle.T5],
            SubTypes.special.compromise,
        ],
        columns: Object.keys(ChartColumns.special.compromise),
        colorSchema: Color.ColorsTypes.t3,
        size: 500,
        ordering: OrderingAll,
        testId: 'table-crossproduct-type-triangle-compromise'
    },
    {
        title: 'charts.Topic.Statistics.emotionByQuestion.RoleProblemType',
        Type: ChartType.HeatMapCrossProduct,
        subTypes: [
            [SubTypes.bar.type, SubTypes.triangle.T3, SubTypes.triangle.T4, SubTypes.triangle.T5],
            SubTypes.bar.decisionOwner,
        ],
        columns: Object.keys(barSchema.decisionOwner),
        colorSchema: Color.ColorsTypes.t4,
        size: 500,
        ordering: OrderingAll,
        testId: 'table-crossproduct-owner-MCQ+triangle'
    },
    {
        title: 'charts.Topic.Statistics.emotionByQuestion.TimetakenProblemType',
        Type: ChartType.HeatMapCrossProduct,
        subTypes: [
            [SubTypes.bar.type, SubTypes.triangle.T3, SubTypes.triangle.T4, SubTypes.triangle.T5],
            SubTypes.bar.period,
        ],
        columns: Object.keys(barSchema.period),
        colorSchema: Color.ColorsTypes.t5,
        size: 500,
        ordering: OrderingAll,
        testId: 'table-crossproduct-timetaken-type-triangle'
    },
    {
        title: 'charts.Topic.Statistics.emotionByQuestion.TimeTakenFrequency',
        Type: ChartType.HeatMapCrossProduct,
        subTypes: [[SubTypes.bar.frequency], SubTypes.bar.period],
        columns: Object.keys(barSchema.period),
        colorSchema: Color.ColorsTypes.t1,
        size: 500,
        ordering: OrderingAll,
        testId: 'table-crossproduct-timetaken-frequency'
    }
];
const REPORT_CHARTS_MULTIPLE_CHOICE = [
    {
        title: 'charts.Topic.Bar.mood',
        colorSchema: barSchema.reduceColor.mood,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.mood,
        testId: 'bar-mood'
    },
    {
        title: 'charts.Topic.Bar.decision_owner',
        colorSchema: barSchema.reduceColor.decision_owner,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.decisionOwner,
        testId: 'bar-owner'
    },
    {
        title: 'charts.Topic.Bar.period',
        colorSchema: barSchema.reduceColor.period,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.period,
        testId: 'bar-timetaken'
    },
    {
        title: 'charts.Topic.Bar.decision_owner',
        colorSchema: barSchema.reduceColor.decision_owner,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.decisionOwner,
        testId: 'bar-decision-owner'
    },
    {
        title: 'charts.Topic.Bar.frequency',
        colorSchema: barSchema.reduceColor.frequency,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.frequency,
        testId: 'bar-frequency'
    },
    {
        title: 'charts.Topic.Bar.type',
        colorSchema: barSchema.reduceColor.type,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.type,
        testId: 'bar-strategic'
    }
];
const REPORT_CHARTS_CROSSPRODUCT = [
    {
        title: 'charts.Topic.Bar.owner-period',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.decisionOwner, SubTypes.bar.period],
        testId: 'bar-owner-vs-timetaken',
    },
    {
        title: 'charts.Topic.Bar.communication-vs-emote',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.communicationMethod, SubTypes.bar.mood],
        testId: 'bar-communication-vs-emote',
    },
    {
        title: 'charts.Topic.Bar.owner-vs-type',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.decisionOwner, SubTypes.bar.type],
        testId: 'bar-owner-vs-type',
    },
    {
        title: 'charts.Topic.Bar.typeVSfrequency',
        Type: ChartType.CrossProduct,
        subTypes: [SubTypes.bar.frequency, SubTypes.bar.type],
        colorSchema: barSchema.type,
        legend: true,
        testId: 'bar-frequency-vs-type',
    },
    {
        title: 'charts.Topic.Bar.yearsMood',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.year, SubTypes.bar.mood],
        testId: 'bar-year-vs-mood',
    },
    {
        title: 'charts.Topic.Bar.roleMood',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.role, SubTypes.bar.mood],
        testId: 'bar-role-vs-mood',
    },
    {
        title: 'charts.Topic.Bar.departmentMood',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.department, SubTypes.bar.mood],
        testId: 'bar-department-vs-mood',
    },
    {
        title: 'charts.Topic.Bar.timetakenMood',
        Type: ChartType.CrossProduct,
        legend: true,
        subTypes: [SubTypes.bar.period, SubTypes.bar.mood],
        testId: 'bar-timetaken-vs-mood',
    },
];
const REPORT_CHARTS_PIE = [
    {
        title: 'charts.Topic.PieChart.years',
        Type: ChartType.PieChart,
        subType: SubTypes.bar.year,
        testId: 'piechart-years'
    },
    {
        title: 'charts.Topic.PieChart.period',
        Type: ChartType.PieChart,
        subType: SubTypes.bar.period,
        testId: 'piechart-timetaken'
    }
];
const REPORT_CHARTS_POSITIVE_NEGATIVE_MULTIPLE_CHOICE = mood =>
    CHARTS_MULTIPLE_CHOICE
        .filter( e => e.Type === ChartType.BarVertical )
        .filter( e => e.subType !== SubTypes.bar.mood )
        .map( e => (
            {
                ...e,
                title: e.title + "-" + mood,
                tooltip: e.tooltip + "-" + mood,
                colorSchema: barSchema.reduceColor.moods[mood],
                filter: {key: SubTypes.bar.mood, value: mood},
                testId: e.testId + "-" + mood
            }
        ));
const REPORT_CHARTS_POSITIVE_NEGATIVE_CARTESIAN = mood =>
    CHARTS_CARTESIAN
        .map( e => (
            {
                ...e,
                title: "charts.Topic.Cartesian." + e.testId + "-" + mood,
                colorSchema: barSchema.reduceColor.moods[mood],
                filter: {key: SubTypes.bar.mood, value: mood},
                testId: e.testId + "-" + mood,
                size: 500
            }
        ));
const REPORT_CHARTS_POSITIVE_NEGATIVE = [
    ...['positive', 'negative'].flatMap(mood => REPORT_CHARTS_POSITIVE_NEGATIVE_MULTIPLE_CHOICE( mood ) ),
    ...['positive', 'negative'].flatMap(mood => REPORT_CHARTS_POSITIVE_NEGATIVE_CARTESIAN( mood ) ),
    ...['positive', 'negative'].flatMap((mood) =>
        Object.keys(SubTypes.triangle).flatMap( triangleID => ({
            title: 'charts.Topic.Triangle.' + SubTypes.triangle[triangleID] + '-' + mood,
            Type: ChartType.Triangle,
            subType: SubTypes.triangle[triangleID],
            filter: {key: SubTypes.bar.mood, value: mood},
            testId: 'triangle-' + triangleID + '-' + mood
        })))
];

export const REPORT_CHARTS = [
    {
        title: 'charts.Topic.Cartesian.All',
        tooltip: 'dashboard.tooltip.All',
        colorSchema: cultureSchemaLabel.reduceColor.orientation,
        optionalStyle: {confirmed: 'justBorder'},
        Type: ChartType.HeatMapReactangle,
        subTypes: [
            SubTypes.culture.leaderType,
            SubTypes.culture.orientation,
            SubTypes.culture.effectiveness,
            SubTypes.culture.valueDrivers,
        ],
        testId: 'culture-all',
        size: 500,
    },
    ...REPORT_CHARTS_CARTESIAN,
    ...CHARTS_TRIANGLES,
    ...REPORT_CHARTS_MULTIPLE_CHOICE,
    ...REPORT_CHARTS_CROSSPRODUCT,
    ...REPORT_CHARTS_POSITIVE_NEGATIVE,
    ...REPORT_CHARTS_STATISTICS,
    ...REPORT_CHARTS_STATISTICS_TABLES,
    ...REPORT_CHARTS_PIE,
    ...CHARTS_DEMOGRAPHICS,
    ...CHARTS_HeadCount.map( a => ({...a,legend: true})),

    // filtered by ...
    ...timeTakenOptions.map( filter => ({
        title: 'charts.Topic.Bar.owner-filtered-' + filter,
        Type: ChartType.BarHorizontal,
        legend: true,
        subType: SubTypes.bar.decisionOwner,
        filter: {key: SubTypes.bar.period, value: filter},
        testId: 'bar-owner-filtered-' + filter,
    })),
    ...timeTakenOptions.map( (timetaken, id) =>
        ({
            title: 'charts.Topic.Bar.period-selectbar-' + (id + 1),
            colorSchema: coloringBarHighlighting(barSchema.reduceColor.period, timetaken),
            Type: ChartType.BarVertical,
            subType: SubTypes.bar.period,
            size: 600,
            testId: 'bar-timetaken-selectbar-' + timetaken
        })
    ),
    ...ownerOptions.map( owner => ({
        title: 'charts.Topic.Bar.decisionSpeedPerRole.' + owner,
        Type: ChartType.BarHorizontal,
        legend: true,
        subType: SubTypes.bar.period,
        filter: {key: SubTypes.bar.decisionOwner, value: owner},
        size: 480,
        testId: 'bar-timetaken-filtered-owner-' + owner
    })),
    ...ownerOptions.flatMap(owner => ({
        title: 'charts.Topic.Triangle.T4owner.' + owner,
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T4,
        filter: {key: SubTypes.bar.decisionOwner, value: owner},
        testId: 'triangle-T4-filtered-owner-' + owner
    })),
    ...timeTakenOptions.flatMap(timetaken => ({
        title: 'charts.Topic.Triangle.T4timeTaken.' + timetaken,
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T4,
        filter: {key: SubTypes.bar.period, value: timetaken},
        testId: 'triangle-T4-filtered-timetaken-' + timetaken
    })),
    ...timeTakenOptions.flatMap( timetaken => ({
        title: 'charts.Topic.Triangle.T5timeTaken.' + timetaken,
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T5,
        filter: {key: SubTypes.bar.period, value: timetaken},
        testId: 'triangle-T5-filtered-timetaken-' + timetaken
    })),
    ...timeTakenOptions.flatMap(timetaken => ({
        title: 'charts.Topic.Triangle.T6timeTaken.' + timetaken,
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T6,
        filter: {key: SubTypes.bar.period, value: timetaken},
        testId: 'triangle-T6-filtered-timetaken-' + timetaken
    })),
    ...['tactical', 'strategic'].flatMap(type =>
        Object.keys(SubTypes.triangle).map( triangleID => ({
            title: 'charts.Topic.Triangle.' + SubTypes.triangle[triangleID] + type,
            Type: ChartType.Triangle,
            subType: SubTypes.triangle[triangleID],
            filter: {key: SubTypes.bar.type, value: type},
            testId: 'triangle-' + triangleID + '-' + type
        }))
    ),
    ...timeTakenOptions.map( filter => ({
        title: 'charts.Topic.Cartesian.LeaderTypeTimeTaken.' + filter,
        tooltip: 'dashboard.tooltip.leadership',
        colorSchema: cultureSchemaLabel.reduceColor.leaderType,
        optionalStyle: {confirmed: 'justBorder'},
        Type: ChartType.HeatMapReactangle,
        subType: SubTypes.culture.leaderType,
        filter: {key: SubTypes.bar.period, value: filter},
        size: 500,
        testId: 'culture-leader-type-filtered-timetaken-' + filter
    }))
];
