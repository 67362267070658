import {Description, Modal, SelectFields, Users} from "../../core/ui/modal/Modal";
import {I18n} from "react-i18nify";
import React, {useEffect, useState} from "react";
import {NodeItem} from "./ModalAddParent";
import {useStrategyMapSlice} from "../../strategyMap/hooks/useStrategyMapSlice";
import Api from "../../strategyMap/api";
import { LoaderContainer } from "../../strategyMap/components/Modal/ShareItems";
import { BarLoader } from "react-spinners";
import { SearchBox } from "../../strategyMap/components/Modal/SquadModal";

export const ModalAddGlobalSharedNode = ({isOpen, nodeID, handleClose}) => {

    if( ! isOpen )
        return <></>;

    const {Actions, goalNodes, goal} = useStrategyMapSlice();
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [sharableNodes, setShareAbleNodes] = useState([]);
    const [filteredSharableNodes, setFilteredSharableNodes] = useState([]);
    const [isFetchingNodes, setIsFetchingNodes] = useState(false);

    const handleChooseNode = nodeID => {
        setSelectedNodes( [nodeID] );
    };

    const handleSubmit = () => {

        const currentNode = sharableNodes.find( a => a.id === selectedNodes[0] );
        Actions.updateNode( {...currentNode, parents: [...currentNode.parents, nodeID]} );
        Actions.linkOtherGoalNodeToNode( {parentID: nodeID, childID: currentNode.id})
        handleClose();
    }

    const handleSetFilter = (searchStringFilter) => {
        searchStringFilter = searchStringFilter.toLowerCase();
        const filteredNodesList = sharableNodes.filter(
            (node) =>
                (node.title && node.title.toLowerCase().includes(searchStringFilter)) ||
                node.description.toLowerCase().includes(searchStringFilter) ||
                node.sid.toString().includes(searchStringFilter)
        );

        setFilteredSharableNodes(filteredNodesList);
    }

    useEffect( () => {
        setIsFetchingNodes( true );
        new Api().fetchOtherNodes(goal.id)
            .then( result => {
                setShareAbleNodes( result.data );
                setFilteredSharableNodes( result.data );
            }).finally( () => {
                setIsFetchingNodes( false );
        });
    }, [goal, goalNodes]);

    return (
        <Modal 
            isOpen={isOpen}
            title={I18n.t('sidebar.addParent.dialog.title')}
            footer={{
                confirmText: I18n.t('sidebar.addParent.dialog.buttons.add'),
                onConfirm: handleSubmit,
                onCancel: handleClose
            }}
        >
            <Description>{I18n.t('sidebar.addParent.dialog.description')}</Description>
            <SelectFields>
                <SearchBox onSetFilter={ handleSetFilter } />
                <Users>
                    {
                        filteredSharableNodes.map( node => (
                            <NodeItem
                                key={node.id}
                                node={node}
                                checked={selectedNodes.includes(node.id)}
                                onChooseNode={handleChooseNode}
                            />
                        ))
                    }
                    
                    { isFetchingNodes && 
                        <LoaderContainer>
                            <BarLoader />
                        </LoaderContainer>
                    }
                    {!isFetchingNodes && sharableNodes.length === 0 && <div>{I18n.t('strategyMap.nodes.noNodesToShare')}</div>}
                </Users>
            </SelectFields>
        </Modal>
    )
}