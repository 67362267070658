import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";
import {ChoiceBox, ChoiceRow} from "../Filters.styles";
import React from "react";
import {I18n} from "react-i18nify";

const getExperimentlabel = exp =>
    I18n.t('dashboard.Filter.experiment') + '#' + exp.id +
    (exp.title !== ''  ? ' - ' + exp.title : '');

const ExperimentItems = ({demographicType, item}) => {
    const {filters, Actions} = useDashboardSlice()
    const currentData = filters[demographicType] || []
    const checked = currentData.some( i => i.id === item.id )

    const handleItemSelect = () => {
        const dataFilter = checked
            ? currentData.filter( i => item.id !== i.id )
            : [item]

        Actions.setFilters({filterType: demographicType, data: dataFilter})
    }

    return (
        <ChoiceRow data-testid={"checkbox-container"} onClick={handleItemSelect}>
            <ChoiceBox role="button">
                {checked && <i className="material-icons" data-testid={"checkbox-checked"}>check</i>}
            </ChoiceBox>
            <span>{ getExperimentlabel(item) }</span>
        </ChoiceRow>
    )
}

export default ExperimentItems;