import React, {useEffect} from 'react'
import StrategyMap from './StrategyMap'
import CommonPage from '../../core/components/CommonPage'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {getNodeLineage} from '../utils'
import {reverse} from 'ramda'
import {useHistory} from '../../core/hooks/useHistory'
import FeaturePermission from '../../core/components/FeaturePermission'
import ImageStrategyMap from "../../img/Apps/StrategyMap.png";

const StrategyMapPage = (props) => {
    const {Actions, nodeMap, goal} = useStrategyMapSlice();
    const {goalId, nodeId} = props.computedMatch.params;
    const {history} = useHistory();

    useEffect(() => { 
        if (goalId && nodeId) {
            const node = nodeMap[nodeId];  
            if( node ) {
                const lineage = getNodeLineage(node, nodeMap, []);
                reverse(lineage).forEach((parent) => Actions.setClickedNode(nodeMap[parent]));
                Actions.setClickedNode(node);
                Actions.showNodeEditingSidebar(node);
                history.push('/strategy-map');
            }
        }
    }, [nodeMap]);

    useEffect(() => {
        if( goalId && nodeId ) {
            localStorage.setItem('lastOpenedMap', goalId);
        }
        if( Object.keys(nodeMap).length <= 1 )
            Actions.getGoal({id: localStorage.getItem('lastOpenedMap')});
        if( Object.keys(goal).length === 0 )
            Actions.fetchGoals();
    }, []);

    return (
        <FeaturePermission name="strategymap">
            <CommonPage pageIcon={ImageStrategyMap} pageHeading={'ORGANIC agility – Strategy Map'} render={() => <StrategyMap />} />
        </FeaturePermission>
    )
}

export default StrategyMapPage
