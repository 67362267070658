import React, {useState} from 'react'
import {I18n} from 'react-i18nify'
import {HeadingMaterialIcons} from '../../core/ui/HeadingWithAction'
import {isMobile} from 'react-device-detect'
import styled from 'styled-components'
import {BLACK_GREY, PALE_GREY, SHADOW_COLOUR, WHITE} from '../../core/ui/_colors'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useClickOutside} from "../../core/hooks/useClickOutside"


export const DropdownContainer = styled.div`
    position: relative;
    float: right;
    line-height: 0;
`

const DropdownToggle = styled.button`
    background-color: ${(props) => (props.isOpen ? PALE_GREY : WHITE)};
    border: 0;
    outline: none;
    padding: 0;
    &:hover {
        background-color: ${PALE_GREY};
    }
`

export const DropdownList = styled.ul`
    background-color: ${WHITE};
    box-shadow: 0px 2px 6px 0px ${SHADOW_COLOUR};
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    list-style: none;
    margin: 0;
    min-width: 160px;
    padding: 5px 0;
    position: absolute;
    right: ${(props) => props.left && '0'};
    z-index: 1000;
`

export const DropDownItem = styled.li`
    cursor: pointer;
    &:hover {
        background-color: ${PALE_GREY};
    }
`

const DropdownLink = styled.a`
    color: ${BLACK_GREY};
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 3px 20px;
    white-space: nowrap;
    &:hover {
        background-color: ${PALE_GREY};
        color: ${BLACK_GREY};
        text-decoration: none;
    }
`

const MenuDivider = styled.hr`
    height: 1px;
    margin: 9px 0;
`

export const MENU_ITEM_LINK = 'link'
export const MENU_ITEM_DIVIDER = 'divider'

const DropdownMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useClickOutside(() => setIsOpen(false))

    const handleToggleMenu = (e) => {
        e.stopPropagation()
        if (props.activeMenuButtons.length) setIsOpen(!isOpen)
    }

    const handleItemClick = (e, button) => {
        e.stopPropagation()
        button.actionHandler && button.actionHandler()
        setIsOpen(false)
    }

    return (
        <DropdownContainer ref={dropdownRef}>
            <DropdownToggle
                isOpen={isOpen}
                onClick={handleToggleMenu}
            >
                <HeadingMaterialIcons data-testid={props.testId} className="material-icons">{props.icon}</HeadingMaterialIcons>
            </DropdownToggle>
            <DropdownList isOpen={isOpen} {...props}>
                {props.activeMenuButtons.map( (button, i) => {
                    const title = button.title || I18n.t('strategyMap.nodes.' + (button.action || ''))
                    if (button.type === MENU_ITEM_LINK) {
                        if (button.copyToClipboard) {
                            return (
                                <DropDownItem eventKey={i} key={i}>
                                    <CopyToClipboard text={button.value}>
                                        <DropdownLink
                                            data-testid={button.testId || title}
                                            onClick={(e) => handleItemClick(e, button)}
                                        >
                                            {title}
                                        </DropdownLink>
                                    </CopyToClipboard>
                                </DropDownItem>
                            )
                        }
                        return (
                            <DropDownItem eventKey={i} key={i}>
                                <DropdownLink
                                    data-testid={button.testId || title}
                                    onClick={(e) => handleItemClick(e, button)}
                                >
                                    {title}
                                </DropdownLink>
                            </DropDownItem>
                        )
                    } else if (!isMobile) {
                        return (
                            <DropDownItem key={i}>
                                <MenuDivider />
                            </DropDownItem>
                        )
                    } else return null
                })}
            </DropdownList>
        </DropdownContainer>
    )
}

export default DropdownMenu
