import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import _ from 'lodash'
import { ContentGrid, SendBtnContainer } from './Capture.styles'
import {ButtonPrimary} from '../core/components/Buttons'
import signifiers from './signifier'
import {useCaptureSlice} from './hooks/useCaptureSlice'
import {useUserSlice} from '../strategyMap/hooks/useUserSlice'
import {FullScreenLoading} from '../core/components/Loading'
import ThankYou from './signifier/ThankYou'
import {displayToastMessage, showPopupMessage} from '../core/utils/notifications'
import { LABELS_TO_CHANGE, QUESTION_TYPES } from './const'
import {Panel, PanelGroup} from '../core/components/BootstrapWrapper'
import Checkbox from './signifier/Checkbox'
import {useHistory} from "../core/hooks/useHistory";
import {Alert} from 'react-bootstrap'
import {Header} from "../core/ui/modal/Modal";
import Footer from "../core/ui/modal/Footer";
import Modal from "../core/components/Modal";
import {InputWrapper} from "./signifier/Shared.styles";
import { useCoreSlice } from '../core/hooks/useCoreSlice'
import SelectWrapper from '../core/components/SelectWrapper'
import ErrorOutput from './signifier/ErrorOutput'
import { validateValue } from '../core/utils/form'
import PriceComparisonTable from './PriceComparisonTable'

const InvitePeopleBlock = ({onClick, checked, userId, onSelectTeam, selectedTeam, shouldValidate, onError}) => {
    const {teams} = useCoreSlice();
    const [options, setOptions] = useState([]);
    const [validationErrors, setValidationErrors] = useState(null);

    const teamOptions = teams
        .filter(team => !team.disabled && team.users.includes(userId))
        .map(team => ({ label: team.name, value: team }));

    const validateInput = () => {
        const [valid, errs] = validateValue('required', selectedTeam ? selectedTeam.label : "")
        
        if( ! valid && teamOptions.length > 0 ){
            setValidationErrors(errs);
            onError();
            return false;
        }
        return true;
    };
    
    useEffect(() => {
        setOptions(teamOptions);
        if( teamOptions.length > 0 ){
            if(localStorage.getItem('leadershipAssessmentSelectedTeam')) {
                onSelectTeam(teamOptions.find(team => team.value.id === parseInt(localStorage.getItem('leadershipAssessmentSelectedTeam'))));
            } else {
                onSelectTeam(teamOptions[0]);
            }
        } else {
            onSelectTeam(null);
        }
    }, [teams]);

    useEffect(() => {
        if( shouldValidate && checked )
            validateInput();
    }, [shouldValidate]);

    return (<>
            <Panel className={'card-team'}>
                <Checkbox
                    onClick={(isChecked) => {
                        onClick(isChecked)
                        setValidationErrors(null)
                    }}
                    checked={checked}
                    label={I18n.t('capture.inviteOtherPeople.action')}
                />
                <br/>
                <SelectWrapper
                    onChange={(e) => {
                        onSelectTeam(e)
                    }}
                    options={options}
                    value={selectedTeam}
                />
                <ErrorOutput errors={validationErrors} />
                <br/>
                {I18n.t('capture.inviteOtherPeople.description')}
            </Panel>
        </>)
}

const ShowPreviewsDescription = ({header, text}) =>
    <>
        {text && 
            <Panel default header={header || I18n.t("capture.inviteOtherPeople.previewsExplanation")} >
                <InputWrapper>{ text }</InputWrapper>
            </Panel>
        }
    </>

const Capture = () => {
    const initialCapture = {hide_description: false, allow_marketing_contact: false}
    const [inviteTeam, setInviteTeam] = useState(true)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [capture, setCapture] = useState(initialCapture)
    const [shouldValidate, setShouldValidate] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const {Actions, isSavingDecisions, isSavingDecisionsSuccess, captureQuestion} = useCaptureSlice()
    const user = useUserSlice()
    const {Actions: CoreActions} = useCoreSlice()
    const {history} = useHistory()
    const [wrongInitialWarning, setWrongInitialWarning] = useState(false);
    const [showExtraEMailModal, setShowExtraEMailModal] = useState(false);
    const [extraEMailText, setExtraEMailText] = useState("");

    const isLeadershipAssessment = captureQuestion.question_type === QUESTION_TYPES.leadershipAssessment
    const isReactionSurvey = captureQuestion.parent_capture && captureQuestion.parent_capture.key

    const ReactionSurveyExtraInformation = isReactionSurvey && {
        title: captureQuestion.parent_capture.title,
    }

    if(isReactionSurvey && !user.isUserLoggedIn) {
        history.push({
            pathname: '/login',
            state: {
                from: {
                    pathname: history.location.pathname,
                }
            }
        })
    }

    if( !wrongInitialWarning && history.location.pathname.split('/').filter( a => a.length).length >= 4 && ! isReactionSurvey ){
        setWrongInitialWarning(true);
        showPopupMessage({title: I18n.t("leadership.messages.wrongParentID.title"), message: I18n.t("leadership.messages.wrongParentID.message")});
    }

    const sendDataToBackend = () => {
        if (isLeadershipAssessment) {
            const captureObj = {
                data: capture,
                question_key: captureQuestion.key
            }

            if ( ! isReactionSurvey ) {
                captureObj.invite_team = inviteTeam;
                captureObj.selected_team = selectedTeam && selectedTeam.value.id;
                captureObj.extra_email_text = extraEMailText;
            } 
            else {
                captureObj.selected_team = captureQuestion.parent_capture.selected_team;
                captureObj.parent_capture = captureQuestion.parent_capture.key;
            }
            
            Actions.saveCapture(captureObj);
        } else {
            Actions.postDecisions({
                ...capture,
                experiments: user.in_experiment_team
                    ? user.in_experiment_team.map((experiment_team) => experiment_team.id)
                    : [],
            });
        }

        if (capture.demographics) {
            const decisionDemographics = _.cloneDeep(capture.demographics)
            decisionDemographics['user'] = user.pk
            if (user.allow_store_demographics) {
                Actions.updateUserProfileDemographics(decisionDemographics)
            }
        }
    }

    const handleChange = (fieldName, value) => {
        setHasError(false)
        setCapture({...capture, [fieldName]: value})
    }

    const handleError = () => setHasError(true);

    useEffect(() => {
        if( isSubmitted ){
            if( ! hasError ){
                if( user.isUserLoggedIn && ! isReactionSurvey && inviteTeam && isLeadershipAssessment )
                    setShowExtraEMailModal(true);
                else
                    sendDataToBackend();
            } else {
                window.scrollTo(0, 0);
                displayToastMessage('error', I18n.t('capture.Form.Status.Error.Text'));
            }
            setIsSubmitted(false);
            setShouldValidate(false);
            setExtraEMailText( null );
        }
    }, [isSubmitted])

    useEffect(() => {
        if( user.isUserLoggedIn )
            CoreActions.fetchTeams()
    } , []);
    
    useEffect( () => {
        if( shouldValidate )
            setTimeout(() => setIsSubmitted(true), 500)
    }, [shouldValidate]);

    const showInvitePeopleBlock = user.isUserLoggedIn && ! isReactionSurvey && isLeadershipAssessment;

    const showModalExtraEMailText = () =>
        <Modal isOpen={showExtraEMailModal}>
            <Header>{I18n.t('capture.inviteOtherPeople.extraEMailText')}</Header>
            <InputWrapper style={{width: 500}}>
                <textarea maxLength={250} onChange={({target}) => setExtraEMailText(target.value)} />
            </InputWrapper>
            <Footer
                confirmText={I18n.t("app.buttons.confirm")}
                onConfirm={sendDataToBackend}

                cancelText={I18n.t("app.buttons.cancel")}
                onCancel={() => setShowExtraEMailModal(false)}
            />
        </Modal>;

    const modifyQuestionIfNeeded = (question) => {
        if (isLeadershipAssessment && isReactionSurvey) {
            if(question.field_name === "triangles") {
                const triangles = question.triangles.map(triangle => {
                    if(LABELS_TO_CHANGE[triangle.header]) {
                        triangle.header = LABELS_TO_CHANGE[triangle.header]
                    }
                    if(LABELS_TO_CHANGE[triangle.labels.top]) {
                        triangle.labels.top = LABELS_TO_CHANGE[triangle.labels.top]
                    }
                    if(LABELS_TO_CHANGE[triangle.labels.left]) {
                        triangle.labels.left = LABELS_TO_CHANGE[triangle.labels.left]
                    }
                    if(LABELS_TO_CHANGE[triangle.labels.right]) {
                        triangle.labels.right = LABELS_TO_CHANGE[triangle.labels.right]
                    }
                    return triangle
                })
                
                return {...question, triangles}
            }
        }
        
        return question
    }

    const filterQuestions = (question) => {
        return !question.notAuthenticated || !user.isUserLoggedIn
    }

    const getDefaultValue = (question) => {
        const titleValue = isReactionSurvey &&
            question.header ===
                'leadership.Report.Assessment title' &&
            ReactionSurveyExtraInformation.title
        return titleValue || ""
    }

    return (
        <ContentGrid id="capture">
            <FullScreenLoading loading={shouldValidate || isSavingDecisions} />
            {isSavingDecisionsSuccess && (
                <ThankYou
                    captureTitle={capture.title}
                    resetCapture={() => {
                        setCapture(initialCapture)
                        Actions.resetIsSavingDecisionsSuccess()
                    }}
                />
            )}
            {!isSavingDecisionsSuccess && (
                <React.Fragment>
                    <PanelGroup>
                        {user.isUserLoggedIn && <Alert variant="info" className="fade in">{
                            isLeadershipAssessment ? I18n.t('leadership.AnonymousClaim') : I18n.t('capture.AnonymousClaim')
                            }</Alert>}
                        {!user.isUserLoggedIn && <>
                            <ShowPreviewsDescription 
                                header={I18n.t('capture.HOWDoesAgile42sFreeLeadershipAssessmentWorkTitle')} 
                                text={I18n.t('capture.HOWDoesAgile42sFreeLeadershipAssessmentWorkDescription')} />
                            <PriceComparisonTable showDescription={true} />
                        </>}
                        {captureQuestion.parent_capture && <ShowPreviewsDescription text={captureQuestion.parent_capture.extra_email_text} />}

                        {captureQuestion &&
                            captureQuestion.question_data.filter(filterQuestions).map((question, i) =>
                                <div key={i} className={"card-"+i}>
                                    {signifiers[question.signifier](modifyQuestionIfNeeded(question), {
                                        onError: handleError,
                                        handleChange: handleChange,
                                        shouldValidate: shouldValidate,
                                        readOnly:
                                            isReactionSurvey &&
                                            question.header ===
                                                'leadership.Report.Assessment title',
                                        defaultValue: getDefaultValue(question),
                                    })}
                                </div>
                        )}
                        {showInvitePeopleBlock && (
                            <InvitePeopleBlock 
                                checked={inviteTeam} 
                                onClick={setInviteTeam} 
                                userId={user.pk}
                                selectedTeam={selectedTeam}
                                shouldValidate={shouldValidate}
                                onError={handleError}
                                onSelectTeam={(selTeam) => {
                                    setSelectedTeam(selTeam)
                                    setHasError(false)
                                    if(selTeam && selTeam.value) {
                                        localStorage.setItem('leadershipAssessmentSelectedTeam', selTeam.value.id);
                                    }
                                }} 
                            />
                        )}
                    </PanelGroup>

                    { showModalExtraEMailText() }
                    
                    { captureQuestion && (
                        <SendBtnContainer>
                            <ButtonPrimary
                                data-testid={'capture-send'}
                                onClick={() => setShouldValidate(true) }
                            >
                                {I18n.t('capture.Form.Send')}
                            </ButtonPrimary>
                        </SendBtnContainer>
                    )}
                </React.Fragment>
            )}
        </ContentGrid>
    )
}

export default Capture
