export const DashboardLocalStorage = {
    key: "dashboard-moving-key",
    section: "dashboard-moving-section",
    position: {
        start: "dashboard-moving-position-start",
        ends: "dashboard-moving-position-ends"
    }
};

export const MODAL_ITEMS = {
    GOALS: "strategyMapGoal",
    TEAMS: "teams",
    PSF: "psf",
    DEPARTMENTS: "departments"
}

export const DashboardWidgets = {
    StrategyMap: [
        {
            name: "Strategy map insights",
            description: "",
            filter: {"goal": null},
            modalItems: "GOALS"
        },
        {
            name: "PSF overview",
            description: "",
            filter: {"psf": null},
            modalItems: "PSF"
        }
    ],
    LeadershipAssessment: [
        {
            name: "leadership.Report.ArchetypeChartHeader",
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: "leadership.Panel.Topic.Autonomy vs Control",
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: 'leadership.Triangle.Topic.My expectations of the team were to',
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: 'leadership.Triangle.Topic.My responsibility as leader was to',
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: 'leadership.Triangle.Topic.People chose what to do based on',
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: 'leadership.Triangle.Topic.To succeed I knew I needed to',
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: 'leadership.Triangle.Topic.To motivate my people to perform, I',
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        },
        {
            name: 'leadership.Triangle.Topic.People felt comfortable because',
            description: "",
            filter: {"team": "all"},
            modalItems: "TEAMS"
        }
    ],
    OrgScan: [
        {
            name: "charts.Topic.CircleProgress.CapturedDecisions",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: true
        },
        {
            name: "charts.Topic.CircleProgress.DatasetAffinity",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: true
        },
        {
            name: "charts.Topic.Bar.departmentLimitedLicense",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: true
        },
        {
            name: "capture.Dimension.Options.leader-type",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name: "capture.Dimension.Options.orientation",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name: "capture.Dimension.Options.effectiveness",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name: "capture.Dimension.Options.value-drivers",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name: "charts.Topic.Bar.period",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name:"charts.Topic.Bar.frequency",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name:"charts.Topic.Bar.decision_owner",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name:"charts.Topic.Bar.typeVSfrequency",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name:"charts.Topic.Bar.department",
            description: "",
            filter: {"department": "all"},
            modalItems: "DEPARTMENTS",
            isOrgScanLite: false
        },
        {
            name:"charts.Experiments",
            description: "",
            filter: {"goal": null},
            modalItems: "GOALS",
            isOrgScanLite: false
        }
    ]
}