import React from 'react';
import SetupPage from "./SetupPage";
import PageHeader from "../core/components/pageHeader";
import {I18n} from "react-i18nify";
import {FullScreenLoading} from "../core/components/Loading";
import {MainNavbar} from "../core/components/Navbar/Navbar.styles";
import {LanguageContainer} from "../Login/Login.styles";
import Translation from "../core/components/Translation";
import {ButtonSecondary} from "../core/components/Buttons";
import {CenterContainer} from "../analysis/reports/Reports.styles";
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {NotificationContainer} from 'react-notifications';
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";


const LoginLogout = () => {
    const {authSuccess, Actions} = useUserSlice();

    if( authSuccess )
        return <ButtonSecondary onClick={() => Actions.logout()}>Logout</ButtonSecondary>;
    return <ButtonSecondary onClick={() => window.location.href = "/login"}>Login</ButtonSecondary>;
}

const SetupWrapper = (props) => {

    const {isSaving} = useCoreSlice();

    return <CenterContainer style={{position: 'relative'}}>
                <PageHeader title={I18n.t('setup.Instance Setup')}/>
                <FullScreenLoading loading={isSaving}/>
                <MainNavbar>
                    <LoginLogout />
                    <LanguageContainer data-testid={'language-select-container'} className="language" style={{top: 0}}>
                        <Translation hideTitle staticLanguage/>
                    </LanguageContainer>
                </MainNavbar>
                <SetupPage props={props} />
                <NotificationContainer />
        </CenterContainer>;
}

export default SetupWrapper;