import React from 'react'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import { isFreeUsage } from '../utils/navigation'
import {useCoreSlice} from "../hooks/useCoreSlice";
import DashboardMessageCentered from '../../dashboard/components/DashboardMessageCentered'

export const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const FeaturePermission = (props) => {
    const {features, noActiveSubscriptions} = useCoreSlice();

    if( noActiveSubscriptions ){
        return (
            <Container>
                <DashboardMessageCentered 
                    message={I18n.t('dashboard.messages.noActiveSubscriptions')} />
            </Container>
        )
    }
    const featureEnabled = isFeatureEnabled( features, props.name );

    if( ! features )
        return null;

    if( isFreeUsage() || ! props.name || props.name === '' )
        return <>{props.children}</>;

    if( ! featureEnabled ){
        return (
            <Container>
                <DashboardMessageCentered message={I18n.t('dashboard.messages.featureDisabled')} />
            </Container>
        )
    }
    
    return <>{props.children}</>;
}

export const isFeatureEnabled = (features, featureNames) => {
    const featureEnabled = Array.isArray( featureNames ) ? !! featureNames.some( a => features[a] ) : features[featureNames];
    return featureEnabled;
}

export default FeaturePermission;
