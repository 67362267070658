import {useEffect, useRef, useState} from 'react'

export default function useHover() {
    const [isHovered, setIsHovered] = useState(false)
    const ref = useRef(null)
    const element = ref.current

    useEffect(() => {
        if (element) element.style.cursor = isHovered ? 'pointer' : 'default'
        return () => {
            if (element) element.style.cursor = 'default'
        }
    }, [isHovered, element])

    useEffect(() => {
        if (element) {
            const handleMouseOver = () => setIsHovered(true)
            const handleMouseOut = () => setIsHovered(false)

            element.addEventListener('mouseover', handleMouseOver)
            element.addEventListener('mouseout', handleMouseOut)
            return () => {
                element.removeEventListener('mouseover', handleMouseOver)
                element.removeEventListener('mouseout', handleMouseOut)
            }
        }
    }, [element])
    return [ref, isHovered]
}
