import * as blobUtil from "blob-util";

const  createCredentialDefaultArgs = (param) => ({
    publicKey: {
        rp: {
            name: "Organic Agility App",
            id: window.location.host.split(':')[0]
        },

        user: {
            id: new Uint8Array(16),
            name: param.email,
            displayName: param.username
        },

        pubKeyCredParams: [{
            type: "public-key",
            alg: -7
        }],

        attestation: "direct",
        timeout: 60000,
        challenge: new Uint8Array(16)
    }
});

const getCredentialDefaultArgs = {
    publicKey: {
        timeout: 60000,
        challenge: new Uint8Array(16)
    },
};

const u2F = () => {

    const pairDongle = async (data) => {
        let returnValue;
        await navigator.credentials.create(createCredentialDefaultArgs({email: data.email, username: data.name}))
            .then(async cred => {
                await blobUtil.blobToBinaryString(new Blob([cred.rawId], { type: 'text/plain;charset=UTF-8'}))
                    .then(userID => returnValue = {userBlob: userID, token: cred.id})
            })

        return returnValue;
    };


    const validate = async (userID) => {

        const UserIDBlob = blobUtil.binaryStringToBlob(userID);
        let token;

        await UserIDBlob.arrayBuffer().then(async id => {
            const idList = [{
                id: id,
                type: "public-key"
            }];
            getCredentialDefaultArgs.publicKey.allowCredentials = idList;
            await navigator.credentials.get(getCredentialDefaultArgs)
                .then(assertion => token = assertion.id);
        });

        return token;
    };

    return ({
        validate: validate,
        pairDongle: pairDongle
    })
}

export default u2F;