import React from 'react'
import {I18n} from 'react-i18nify'
import {Div} from '../../../core/ui/Div'
import {P} from '../../../core/ui/generic'
import {LIGHT_GREY} from '../../../core/ui/_colors'
import Moment from "react-moment";

const Version = (props) => {
    const {title, description} = props.version.data

    return (
        <Div marginTop={'20px'}>
            <P borderBottom={`1px solid ${LIGHT_GREY}`}>
                <b>{props.version.user}</b> {I18n.t('strategyMap.messages.createdVersion')}
            </P>
            {title && <Div fontSize={'16px'} dangerouslySetInnerHTML={{__html: title}} />}
            {description && (
                <Div fontSize={'16px'} dangerouslySetInnerHTML={{__html: description}} />
            )}
            <Moment locale={I18n._localeKey} fromNow>{ props.version.date_created }</Moment>
        </Div>
    )
}

export default Version
