import React, {useContext, useEffect, useState} from 'react'
import _ from 'lodash'

import {useTypingTimeout} from '../../strategyMap/hooks/useTypingTimeout'
import OtherUserIsEditing from '../ui/OtherUserIsEditing'
import SM_WebsocketContext from '../contexts/SM_WebsocketContext'
import styled, {css} from 'styled-components'
import {BLUE, DARK_GREY, PALE_GREY} from '../ui/_colors'

const ClickToEditInputSidebar = styled.div`
    cursor: pointer;
    height: auto;
    margin-bottom: 5px;
    word-break: break-word;
    ${(props) =>
        props.isReadOnly &&
        css`
            margin-bottom: 0px !important;
        `}
`

const ViewText = styled.div`
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;
    padding: 10px;
    word-break: break-word;
    &:hover {
        background-color: ${PALE_GREY};
    }
`

const EditOnClickTextarea = styled.textarea`
    border: solid 1px ${DARK_GREY};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;
    outline: none;
    padding: 10px !important;
    resize: none;
    width: 100%;
    ${(props) =>
        props.otherUserIsEditing &&
        css`
            border-color: ${BLUE};
            border-style: solid;
            border-width: thick;
        `}
`

const ClickToEditInput = (props) => {
    const _handleTextChange = _.debounce((newText) => props.onTextChange(newText), 150)
    const [value, setValue] = useState('')
    const [isInputHidden, setIsInputHidden] = useState(true)
    const [handleOnchangeTimeout, typingTimeout] = useTypingTimeout()

    const socket = useContext(SM_WebsocketContext)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {
        if (!typingTimeout) 
            setValue(props.value)
    }, [props.value])

    const handleTextChange = (e) => {
        const v = e.target.value
        setValue(v)
        if (props.node && props.node.id)
            socket.send({
                type: 'node_update_field',
                field: 'title',
                nodeId: props.node.id,
            })
        handleOnchangeTimeout(() => _handleTextChange(v))
        fixTextareaHeight(e)
    }

    const handleClickToEdit = () => {
        if (!props.isReadOnly) 
            setIsInputHidden(!isInputHidden)
    }

    const fixTextareaHeight = (e) => {
        const extraHeightToPreventScroll = 5
        e.target.style.height = 'inherit'
        e.target.style.height = `${e.target.scrollHeight + extraHeightToPreventScroll}px`
    }

    const handleKeyDown = (e) => {
        const keyCodeEnter = 13
        fixTextareaHeight(e)
        if (e.keyCode === keyCodeEnter) 
            setIsInputHidden(!isInputHidden)
    }

    const otherUserIsEditingTitle = !!(props.nodeActivity && props.nodeActivity.field === 'title')
    return (
        <ClickToEditInputSidebar isReadOnly={props.isReadOnly}>
            {!isInputHidden ? (
                <OtherUserIsEditing
                    otherUserIsEditing={otherUserIsEditingTitle}
                    user={props.nodeActivity && props.nodeActivity.user}
                >
                    <EditOnClickTextarea
                        otherUserIsEditing={otherUserIsEditingTitle}
                        type="text"
                        id="sidebar-title-input"
                        maxLength="100"
                        rows="1"
                        defaultValue={value}
                        disabled={props.nodeActivity && props.nodeActivity.field === 'title'}
                        data-testid="click-to-edit-input"
                        placeholder={props.placeholderText}
                        onChange={handleTextChange}
                        autoFocus
                        onFocus={fixTextareaHeight}
                        onBlur={() => setIsInputHidden(!isInputHidden)}
                        onKeyDown={handleKeyDown}
                    />
                </OtherUserIsEditing>
            ) : (
                <ViewText
                    data-testid={`opened-node-title-${props.node.id}`}
                    onClick={handleClickToEdit}
                >
                    {value || props.placeholderText}
                </ViewText>
            )}
        </ClickToEditInputSidebar>
    )
}

export default ClickToEditInput
