import React, {useEffect, useState} from 'react'
import {clamp, indexOf} from 'ramda'
import ICUp from '../../strategyMap/components/nodeIcons/ICUp'
import ICTop from '../../strategyMap/components/nodeIcons/ICTop'
import ICBottom from '../../strategyMap/components/nodeIcons/ICBottom'
import ICDown from '../../strategyMap/components/nodeIcons/ICDown'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {BaseItem, Head, HeadItem, HeadItemCover, Input, PrioritiesWrapper} from './Priority.styles'

const Priority = (props) => {
    const {node} = props
    const {sfPerTimeArea, Actions} = useStrategyMapSlice()
    const confirmed_nodes = sfPerTimeArea[0] || []
    const currentIndex = indexOf(node && node.id, confirmed_nodes)
    const [value, setValue] = useState(currentIndex + 1)
    const [inputValue, setInputValue] = useState(value)
    const [inputEnabled, setInputEnabled] = useState(false)
    const total = confirmed_nodes.length

    useEffect(() => {
        setValue(currentIndex + 1)
    }, [currentIndex])

    const changePriority = (previous, next) => {
        const item = confirmed_nodes.splice(previous - 1, 1)[0]
        confirmed_nodes.splice(next - 1, 0, item)
        Actions.updatePriority({nodes: confirmed_nodes, id: node.id})
        setInputEnabled(false)
        setInputValue(next)
    }

    const increasePriority = () => {
        const currentValue = value - 1 < 0 ? 0 : value - 1
        changePriority(value, currentValue)
        setValue(currentValue)
    }

    const lowestPriority = () => {
        const currentValue = total
        changePriority(value, currentValue)
        setValue(currentValue)
    }

    const highestPriority = () => {
        const currentValue = 1
        changePriority(value, currentValue)
        setValue(currentValue)
    }

    const decreasePriority = () => {
        const currentValue = value + 1 >= total ? total : value + 1
        changePriority(value, currentValue)
        setValue(currentValue)
    }

    const setPriority = () => {
        changePriority(value, inputValue)
    }

    const changeInput = (e) => {
        // https://ramdajs.com/docs/#clamp
        const currentValue = clamp(1, total, e.target.value)
        setInputValue(currentValue)
    }

    return (
        <PrioritiesWrapper>
            <Head>
                <HeadItem onClick={highestPriority}>
                    <ICTop />
                </HeadItem>
                <HeadItem onClick={increasePriority}>
                    <ICUp />
                </HeadItem>
                <HeadItemCover>
                    {' '}
                    <HeadItem onClick={() => setInputEnabled(true)}>
                        {!inputEnabled ? (
                            <strong>{value}</strong>
                        ) : (
                            <Input
                                type={'number'}
                                value={inputValue}
                                max={total}
                                min={1}
                                onChange={changeInput}
                                onBlur={setPriority}
                            />
                        )}
                        <BaseItem>
                            <span>/ {total}</span>
                        </BaseItem>
                    </HeadItem>
                </HeadItemCover>
                <HeadItem onClick={decreasePriority}>
                    <ICDown />
                </HeadItem>
                <HeadItem onClick={lowestPriority}>
                    <ICBottom />
                </HeadItem>
            </Head>
        </PrioritiesWrapper>
    )
}

export default Priority
