import React from 'react'
import InspectDetails from './InspectDetails'
import InspectGraphs from './InspectGraphs'
import InspectGraph from './InspectGraph'
import {ChartType} from '../../Charts/ChartsConfig'
import {
    decisionsFiltered
} from '../../dashboard/components/DecisionFilter/Dashboard.functions'
import { Col, Row } from 'react-bootstrap'
import {useLanguageSlice} from "../../core/hooks/useLanguageSlice";
import InspectSearch from "./InspectSearch";

export const Inspect = () => {
    const {current} = useLanguageSlice();

    return (
        <>
            <InspectSearch />
            <Row style={{paddingRight: 30}}>
                <Col md={8} sm={8} lg={8}>
                    <InspectGraph language={current} />

                    <InspectGraphs language={current} />
                </Col>
                <Col md={4} sm={4} lg={4}>
                    <InspectDetails language={current} />
                </Col>
            </Row>
        </>
    )
}

export const getFilteredDecisionsByPoints = (dataPoints, selectedData, filter) => {
    const decisionsByDemographics = decisionsFiltered(dataPoints, filter);

    if (Object.keys(selectedData).length <= 0) return decisionsByDemographics

    const points = decisionsByDemographics.filter((point) => {
        switch (selectedData.diagramType) {
            case ChartType.HeatMapReactangle:
                return (
                    point.cultural_profile && point.cultural_profile.some(
                        (profile) => profile.name === selectedData.diagramName,
                    ).length !== 0
                )
            case ChartType.Triangle:
                return (
                    point.triangles && point.triangles.some((profile) => profile.triangle === selectedData.diagramName)
                        .length !== 0
                )
        }
    })

    return points.filter( value => selectedData.decisionIDs.includes(value.pk))
}
