import React, {useContext, useEffect} from 'react'
import {I18n} from 'react-i18nify'
import {useSelector} from 'react-redux'
import {getRemainingCaptureDays} from '../../strategyMap/utils'
import AuthWebsocketContext from '../contexts/AuthWebsocketContext'
import {useDemographicsSlice} from '../hooks/useDemographicsSlice'
import {useHistory} from '../hooks/useHistory'
import {DEFAULT_LANGUAGE, LANGUAGE_CODE, LANGUAGES} from '../utils/language'
import Navbar from './Navbar/Navbar'
import { isFreeUsage } from '../utils/navigation'
import {useCoreSlice} from "../hooks/useCoreSlice";
import { MainContainer } from './BootstrapWrapper'
import {useLanguageSlice} from "../hooks/useLanguageSlice";
import Sidebar from "../../sidebars/Sidebar";

export const loadTranslations = () => {
    I18n.setTranslations(LANGUAGES);

    I18n.setHandleMissingTranslation( key => {
        const fallbackLanguage = LANGUAGE_CODE[DEFAULT_LANGUAGE];
        let fallbackTranslation = I18n._translations[fallbackLanguage];

        if( ! key )
            return key;

        key.split('.').forEach((keyPart) => {
            fallbackTranslation = fallbackTranslation[keyPart] || keyPart;
        });

        return fallbackTranslation;
    });
}

export const getPageParam = (props, param) => {
    const match = props.match || props.computedMatch || null;
    
    return match && match.params && match.params[param] || null;
}

const CommonPage = (props) => {

    const {current, Actions} = useLanguageSlice();
    useEffect(() => {
        const languageCode = localStorage.getItem('language');
        if( languageCode && current !== languageCode )
            Actions.setLanguage( languageCode );
    }, [current]);

    if( isFreeUsage() )
        return (<>
            <Navbar subpage={props.currentPage || props.subpage} pageHeading={props.pageHeading} />
            {props.render()}
        </>)

    const {client} = useSelector((state) => state.core)
    const {fetchDemographics} = useDemographicsSlice().Actions
    const authSocket = useContext(AuthWebsocketContext)
    const {isCapturePage, isStrategyMapPage} = useHistory()
    const {history} = useHistory()
    const {isSubscriptionActive} = useCoreSlice()

    useEffect(() => {
        authSocket.connect();
        fetchDemographics();
    }, [])

    useEffect(() => {
        const days = getRemainingCaptureDays(client)
        const isCapturePageExpired = isCapturePage && days <= 0
        if (
            (isStrategyMapPage || isCapturePageExpired) && 
            client.name && 
            !isSubscriptionActive
        ) {
            history.push('/')
        }
    }, [client])
    
    if (isSubscriptionActive === undefined )
        return null;

    return (
        <MainContainer fluid>
            <Navbar subpage={props.currentPage || props.subpage} pageHeading={props.pageHeading} pageIcon={props.pageIcon} />
            {props.render()}
            <Sidebar />
        </MainContainer>
    )
}

export default CommonPage
