import React, {useEffect, useState} from "react";
import {FormControl, FormGroup, FormLabel, FormText} from "react-bootstrap";
import {I18n} from "react-i18nify";
import {CenterContainer, SpaceBetweenContainer} from "../capture/signifier/Shared.styles";
import {ButtonPrimary} from "../core/components/Buttons";
import {LogoImg, Title} from "../Login/Login.styles";
import organicAgilityLogo from "./../img/organic_agility_logo.png";
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";
import SelectWrapper from "../core/components/SelectWrapper";
import {countries} from "../core/utils/language";
import {CalenderInputIcon, DateInput} from "../core/ui/DateInput";
import styled from "styled-components";
import { Panel } from "../core/components/BootstrapWrapper";

const _FormGroup = styled(FormGroup)`
    margin: 15px 0;
`

const SubdomainSuffix = styled.div`
    width: 185px;
    font-size: 16px;
    text-overflow: ellipsis;
`

const SuccessInformation = (newClient) => newClient.id
        ?   <>
                <p>{I18n.t('setup.Your app was created successfully')}</p>
                <p>{I18n.t('setup.Check your email for login credentials')}</p>
                <ButtonPrimary onClick={() => {
                    window.location.href = `//${newClient.domain_url}`
                }}>{I18n.t('setup.Visit your app')}</ButtonPrimary>
            </>
        : <></>;

const SubDomainInput = ({tag, label, hint, required, errors, onChange, value}) =>
    <_FormGroup>
        <FormLabel htmlFor={tag}>{ label }</FormLabel>
        <SpaceBetweenContainer style={{alignItems: 'center'}}>
            <FormControl type="text"
                         required={required}
                         pattern="[A-Za-z0-9-]+"
                         autoComplete={'off'}
                         id={"domain_url"}
                         value={ value || '' }
                         style={{width: 200, borderColor: errors && errors[tag] && 'red'}}
                         onChange={ onChange }
            />
            <SubdomainSuffix>
                .{ window.location.host.replace('public.', '').split(":")[0] }
            </SubdomainSuffix>
        </SpaceBetweenContainer>
        {hint && <FormText>{hint}</FormText>}
        { errors && errors[tag] &&
            <div>
                <p style={{color: 'red'}}>{errors[tag][0]}</p>
            </div>
        }
    </_FormGroup>;

const InputElement = ({tag, label, errors, required, onChange, value, permission}) =>
    permission === undefined || permission
    ? <_FormGroup>
            <FormLabel
                htmlFor={tag}>{ label }</FormLabel>
            <FormControl
                type="text"
                autoComplete={'off'}
                required={required}
                id={ tag }
                value={ value || '' }
                style={{borderColor: errors && errors.name &&  'red'}}
                onChange={ onChange }
            />
            { errors  && errors[tag] &&
                <div>
                    <p style={{color: 'red'}}>{errors.name[0]}</p>
                </div>
            }
        </_FormGroup>
    : <></>;
const DropDownElement = ({tag, label, errors, required, onChange, permission, list}) =>
    permission === undefined || permission
    ? <_FormGroup>
            <FormLabel htmlFor={tag}>{ label }</FormLabel>

            <SelectWrapper
                onChange={ onChange }
                options={ list }
                required={required}
            />
            { errors  && errors[tag] &&
                <div>
                    <p style={{color: 'red'}}>{errors.name[0]}</p>
                </div>
            }
        </_FormGroup>
    : <></>;
const DatePicker = ({tag, label, errors,required, onChange, permission}) => {

    const [focused, setFocused] = useState(false);
    const [deadline, setDeadline] = useState(false);

    return (
        permission === undefined || permission
            ? <_FormGroup>
                <FormLabel htmlFor={tag}>{label}</FormLabel>

                <DateInput
                    deadline={ deadline }
                    onDateChange={ a => {
                        if (a) {
                            onChange( a.format("YYYY-MM-DD") );
                            setDeadline( a );
                        }
                    }}
                    customInputIcon={<CalenderInputIcon/>}
                    onFocusChange={({focused}) => setFocused(focused)}
                    focused={focused}
                    required={required}
                />
                {errors && errors[tag] &&
                <div>
                    <p style={{color: 'red'}}>{errors.name[0]}</p>
                </div>
                }
            </_FormGroup>
            : <></>);
};

const SetupPage = () => {
    const {errors, newClient, Actions, subscriptions} = useCoreSlice();
    const {authSuccess} = useUserSlice();

    const [state, setState] = useState({});

    useEffect( () => {
        if( authSuccess && subscriptions.length <= 0 )
            Actions.fetchSubScriptions();
    }, [subscriptions]);

    const fields = () => [
        {
            tag: 'domain_url',
            label: I18n.t('setup.Subdomain'),
            hint: "[your-company-name].organic-agility.app (only numbers, letters and dash allowed)",
            permission: true,
            required: true
        },
        {
            tag: 'name',
            label: I18n.t('setup.Company Name'),
            permission: true,
            required: true
        },
        {
            tag: 'instance_token',
            label: I18n.t('setup.Instance Token'),
            permission: ! authSuccess
        },
        {
            tag: 'divider',
            render: <><hr /><Title>{I18n.t('setup.Administrator Account')}</Title></>,
            permission: true
        },
        {
            tag: 'client_email',
            label: I18n.t('setup.Email'),
            permission: true,
            required: true
        },
        {
            tag: 'client_firstname',
            label: I18n.t('setup.First Name'),
            permission: true,
            required: true
        },
        {
            tag: 'client_lastname',
            label: I18n.t('setup.Last Name'),
            permission: true,
            required: true
        },
        {
            tag: 'divider',
            render: <><hr /><Title>{I18n.t('setup.Instance Specific')}</Title></>,
            permission: authSuccess
        },
        {
            tag: 'enddate',
            label: I18n.t('setup.End date'),
            permission: authSuccess,
            required: false
        },
        {
            tag: 'Country',
            label: I18n.t('setup.Country'),
            permission: authSuccess,
            default: 'de',
            required: true,
            options: countries().map( country => ({label: country.name, value: country.code}))
        },
        {
            tag: 'License',
            label: I18n.t('setup.License'),
            permission: authSuccess,
            required: true,
            options: subscriptions.map( plan => ({label: plan.title, value: plan.title}))
        },
    ];

    const onChange = (e, tag) =>
        {
            setState({...state, [tag]: e.target.value });
            errors && Actions.resetErrors();
        };
    const onChangeDate = (e, tag) =>
        {
            setState({...state, [tag]: e });
            errors && Actions.resetErrors();
        };
    const onChangeList = (e, tag) =>
        {
            setState({...state, [tag]: e.value});
            errors && Actions.resetErrors();
        };
    const onCreate = (e) =>
        {
            e.preventDefault()
            const domain_url = window.location.host.replace('public', state.domain_url).split(":")[0];
            Actions.createClient({...state, domain_url});
        }

    return (
        <CenterContainer style={{marginTop: 42}}>
            <LogoImg
                src={organicAgilityLogo}
                alt="agile42 logo"
            />

            <SuccessInformation newClient={ newClient } />

            { ! newClient.id &&
                <Panel style={{marginTop: 40}}>
                    <Title>ORGANIC agility Setup</Title>
                    <form style={{width: 400}} onSubmit={ onCreate }>
                        {
                            fields()
                                .filter( element => element.permission )
                                .map( element => {

                                    switch( element.tag ){
                                        case 'divider':
                                            return element.render;
                                        case 'startdate':
                                        case 'enddate':
                                            return <DatePicker
                                                tag={ element.tag }
                                                label={ element.label }
                                                required={ element.required }
                                                errors={ errors }
                                                value={ state[element.tag] }
                                                onChange={ e => onChangeDate( e, element.tag ) }
                                            />
                                        case 'domain_url':
                                            return <SubDomainInput
                                                tag={ element.tag }
                                                label={ element.label }
                                                required={ element.required }
                                                hint={element.hint}
                                                errors={ errors }
                                                value={ state[element.tag] }
                                                onChange={ e => onChange( e, element.tag ) }
                                            />
                                        case 'Country':
                                        case 'License':
                                            return <DropDownElement
                                                tag={ element.tag }
                                                label={ element.label }
                                                required={ element.required }
                                                errors={ errors }
                                                value={ state[element.tag] }
                                                onChange={ e => onChangeList( e, element.tag ) }
                                                list={ element.options }
                                            />
                                        default:
                                            return <InputElement
                                                tag={ element.tag }
                                                label={ element.label }
                                                required={ element.required }
                                                errors={ errors }
                                                value={ state[element.tag] }
                                                onChange={ e => onChange( e, element.tag ) }
                                            />
                                    }
                                })
                        }

                        <div style={{marginTop:'40px'}}>{I18n.t('setup.New instance provision could take couple of minutes')}</div>
                        <ButtonPrimary fullWidth>{ I18n.t('setup.Create') }</ButtonPrimary>
                    </form>
                </Panel>}
        </CenterContainer>)
}

export default SetupPage;
