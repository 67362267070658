import React from 'react'
import PropTypes from 'prop-types'
import {I18n} from 'react-i18nify'
import HeadingWithAction from '../../Components/HeadingWithAction'
import Avatar from '../../../core/components/Avatar'
import {RejectButton} from '../../../strategyMap/components/AcceptRejectButtons'
import {useUserSlice} from '../../../strategyMap/hooks/useUserSlice'
import {useNode} from '../../../strategyMap/hooks/useNode'
import {ExperimentTeamContainer, TeamListMember} from './Experiment.styles'
import {RESTRICTIONS} from "../../../core/const";

const TeamList = (props) => {
    const user = useUserSlice()
    const {isUserChampion, isEditMode} = useNode()
    const isUserAbleToReject =
        isUserChampion && isEditMode && !props.isFinished && RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role] )

    const convertToDateString = (date1, date2) => {
        const d1 = date1 && date1 !== '' ? new Date(date1).toLocaleDateString('en-GB') : ''
        const d2 = date2 && date2 !== '' ? ' - ' + new Date(date2).toLocaleDateString('en-GB') : ''
        return d1 + d2
    }

    const getFullName = (user) => {
        return user.first_name !== '' || user.last_name !== ''
            ? `${user.first_name} ${user.last_name}`
            : user.email
    }

    return (
        <div className="experiment-team" data-testid={'experiment-team'}>
            <HeadingWithAction title={I18n.t('strategyMap.experiment.experimentTeam')} />
            {props.experimentTeam && props.experimentTeam.length ? (
                props.experimentTeam.map((member) => {
                    return (
                        <ExperimentTeamContainer key={member.id} data-testid={'team-item'}>
                            <Avatar pull="left" 
                                    colorCode={member.user.color_code}
                                    text={member.user.initials}
                                    profilePicture={member.user.profile_picture} 
                                />
                            <TeamListMember>
                                <div>{getFullName(member.user)}</div>
                                <div className="date">
                                    {convertToDateString(member.date_joined, member.date_left)}
                                </div>
                            </TeamListMember>
                            {isUserAbleToReject &&
                                (member.date_left == null || member.date_left === '') && (
                                    <RejectButton
                                        rejectRequest={() => props.removeTeamMember(member.id)}
                                    />
                                )}
                        </ExperimentTeamContainer>
                    )
                })
            ) : (
                <span className="no-member" data-testid={'team-no-member'}>
                    No team members yet.
                </span>
            )}
        </div>
    )
}

TeamList.propTypes = {
    experimentTeam: PropTypes.array,
    isFinished: PropTypes.bool,
    removeTeamMember: PropTypes.func,
}

TeamList.defaultProps = {}

export default TeamList
