import {createSlice} from 'redux-starter-kit'
import {javascriptize} from '../../core/utils/object'
import {errorHandler} from '../../core/redux/slices'

export const AnalysisInitialState = {
    activeCapturingUsersCount: 0,
    departments: [],
    answers: [],
    affinity: 0,
    disabledUsersCount: 0,
    enabledUsersCount: 0,
    isSaving: false,
    isSavingReportAnswers: false,
    isFetching: false,
    waitReportDownload: false,
    reportAnswersSaved: false,
    endDate: null,
    licenseType: null,
    neverLoggedInUsersCount: null,
    startDate: null,
    totalDecisionCount: 0,
    wholeCompanyPeopleCount: 0,
    userCountPerTenure: [],
}

export const analysisSlice = createSlice({
    slice: 'analysis',
    initialState: AnalysisInitialState,
    reducers: {
        fetchClientStatistics: (state) => state,
        fetchClientStatisticsSuccess: (state, action) => {
            const {payload} = action
            state.activeCapturingUsersCount = payload['actively_capturing_users']
            state.affinity = payload['affinity']
            state.departments = javascriptize(payload['info_per_department'])
            state.disabledUsersCount = payload['disabled_user']
            state.enabledUsersCount = payload['disabled_user']
            state.endDate = payload['end_date']
            state.neverLoggedInUsersCount = payload['never_logged_in_users']
            state.startDate = payload['start_date']
            state.totalDecisionCount = payload['total_decision_count']
            state.wholeCompanyPeopleCount = payload['whole_company_people_count']
            state.userCountPerTenure = payload['info_per_tenure']
        },
        saveImage: (state) => state,
        saveImageSuccess: (state) => state,
        saveReportAnswers: (state) => {
            state.isSavingReportAnswers = true
        },
        saveReportAnswersSuccess: (state, action) => {
            state.reportAnswersSaved = action.payload
            state.isSavingReportAnswers = false
        },
        generateReport: (state) => {
            state.isSaving = true
        },
        generateReportSuccess: (state) => {
            state.isSaving = false
            state.waitReportDownload = true
        },
        resetWaitReportDownload: (state) => {
            state.waitReportDownload = false
        },
        getReportAnswers: (state) => {
            state.isFetching = true
        },
        getReportAnswersSuccess: (state, action) => {
            state.isFetching = false
            state.answers = action.payload
        },
        requestFailure: errorHandler,
    },
})
