import styled, {createGlobalStyle} from 'styled-components'
import Modal_ from 'react-modal'
import {BLACK_GREY, LIGHT_GREY, WHITE, MID_GREY} from '../_colors'
import React from 'react'
import {H2, Input} from '../generic'
import {Div} from '../Div'
import Footer from './Footer'

const ModalStyle = createGlobalStyle`
  .ReactModal__Overlay--after-open {
    z-index: 99999;
    background-color: rgba(255, 255, 255, 0.95);
  }

  .react-confirm-alert-body {
    h1 {
      margin-top: 7px !important;
      margin-bottom: 17px !important;
      font-weight: 700 !important;
      color: ${BLACK_GREY}
    }
  }

  .ModalDialog_Sidebar {
    display: block;
    width: 420px;
    z-index: 999;
    position: absolute;
    transform: translate(-50%, -50%) !important;
    top: 50%;
    left: 50%;
    background: ${WHITE};
    overflow: auto;
    outline: none;
    padding: 21px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  }

`

export const Header = styled(H2)`
    margin-top: 7px;
    font-size: 20px;
    margin-bottom: 17px;
    font-weight: 700;
`

export const Description = styled(Div)`
    margin-bottom: 30px;
    font-size: 16px;
`

export const SelectFields = styled(Div)`
    margin-bottom: 15px;
    height: auto;
    border: solid 1px #dfe1e6;
`

export const SearchInput = styled(Input)`
    padding-left: 5px;
    border: none;
    min-width: 240px;

    &:focus {
        border: none;
        outline: none !important;
    }
`

export const SearchContainer = styled(Div)`
    display: inline-flex;
    border-bottom: 1px solid ${LIGHT_GREY};
    height: 44px;
    padding: 5px 12px 5px 12px;
    width: 100%;
`

const Span_ = styled.span`
    margin-top: auto;
    margin-bottom: auto;
    height: 24px;
    color: ${MID_GREY};
`

export const Users = styled(Div)`
    width: 97%;
    padding: 0px 10px 10px 10px;
    height: 221px;
    overflow-y: auto;
`

export const UserItem = styled(Div)`
    height: 44px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
`

export const UserInformationAsList = styled(Div)`
    margin-left: 20px;
    line-height: 44px;
    display: flex;
    align-items: center;
    width: 100%;
`

export const UserTitle = styled(Div)`
    font-weight: 700;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const SearchIcon = (props) => <Span_ className="material-icons-outlined">search</Span_>

export const Modal = (props) => {
    return (
        <Modal_
            isOpen={props.isOpen}
            style={props.style || {}}
            ariaHideApp={false}
            className={props.className === null ? '' : 'ModalDialog_Sidebar'}
        >
            <ModalStyle over={true} />
            {props.title && <Header>{props.title}</Header>}
            {props.children}
            {props.footer && 
                <Footer
                    confirmText={props.footer.confirmText || false}
                    onConfirm={props.footer.onConfirm || false}
                    cancelText={props.footer.cancelText || false}
                    onCancel={props.footer.onCancel || false}
                />}
        </Modal_>
    )
}
