import axios from "axios";
import { consoleError } from "../../../core/utils/error";
import {getRequestHeader} from "../../../core/utils/network";
import {OrgScanData} from "./OrgScan";
import {StrategyMapData} from "./StrategyMap";

export const MIRO_CLIENT_ID = "3458764542703308951";

export const MIRO_TYPES = {
    frame: "frame",
    notes: "sticky_note",
    text: "text",
    shape: "shape",
    appCard: "app_card",
    card: "card",
    document: "document",
    embed: "embed",
    image: "image"
};

export const MIRO_IMPORT_TYPES = {
    OrgScan: "OrgScan",
    StrategyMap: "StrategyMap"
};

export default class Miro {

    _token = "";
    _boards = undefined;

    _getHeader = () => ({headers: {
        "authorization": "Bearer " + this._token,
        "Content-Type": "application/json"
    }});
    
    constructor( token ) {
        this._token = token;
    }

    login = async () => {
        const response = await axios.get('https://api.miro.com/v2/boards?limit=50&sort=last_modified', this._getHeader());

        if( response.status === 200 ){
            this._boards = response.data;
            return true;
        } else {
            this._boards = undefined;
            return false;
        }
    }

    getAuthenticationCodeURL = () =>
        `https://miro.com/oauth/authorize?response_type=code&client_id=${MIRO_CLIENT_ID}&redirect_uri=${window.location.origin}`;
    
    getAuthenticationTokenAxios = (client_id, code) =>
        axios.post('/api/miro/token', {
                    "client_id": client_id,
                    "code": code
        }, getRequestHeader());

    getBoards = () => this._boards.data;

    board = (id) => new miroBoard( id, this._getHeader() );
}

class miroBoard {

    boardID;
    _header;
    _items = [];
    _connectors = [];

    constructor(id, header) {
        this._header = header;
        this.boardID = id;
    }

    load = async () => {

        await Promise
            .all( [MIRO_TYPES.frame, MIRO_TYPES.notes, MIRO_TYPES.shape, MIRO_TYPES.card].map(type => this._loadByTypeWithPagination(type)) )
            .then( response => this._items = response );

        await this._loadByConnectionsWithPagination().then( response => this._connectors = response );

        this._items = this._items.flat();
    }

    _loadByTypeWithPagination = async (type) => {
        let cursor = "";
        const response = [];
        do {
            const pagination = cursor ? "&cursor=" + cursor : "";
            const responseNotes = await axios.get(`https://api.miro.com/v2/boards/${this.boardID}/items?limit=50&type=${type}${pagination}`, this._header);
            responseNotes.data.data.forEach(a => response.push(a));
            cursor = responseNotes.data.cursor;
        } while (cursor);

        return response;
    }

    _loadByConnectionsWithPagination = async () => {
        let cursor = "";
        const response = [];
        do {
            const pagination = cursor ? "&cursor=" + cursor : "";
            const responseNotes = await axios.get(`https://api.miro.com/v2/boards/${this.boardID}/connectors?limit=50${pagination}`, this._header);
            responseNotes.data.data.forEach(a => response.push(a));
            cursor = responseNotes.data.cursor;
        } while (cursor);

        return response;
    }

    getFrames = () => this._items.filter( a => a.type === MIRO_TYPES.frame );

    getNotes  = () => this._items.filter( a => a.type === MIRO_TYPES.notes );

    frame = async (id) => this._items.filter( a => a.parent && a.parent.id === id );

    createFrame = async (title) => {
        await axios
            .post(`https://api.miro.com/v2/boards/${this.boardID}/frames`,
                {data: {
                        title: title,
                        format: "custom",
                        type: "freeform"
                    }
                }, this._header)
            .then( a => {
                this.load();
            } )
            .catch( a => consoleError( a ));
    }

    OrgScan = () => new OrgScanData(this._items);
    
    StrategyMap = () => new StrategyMapData(this._items, this._connectors);
}