import React, {useEffect, useRef, useState} from 'react'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {I18n} from 'react-i18nify'
import {toPng} from 'html-to-image'
import download from 'downloadjs'
import Charts from '../../Charts/Charts'
import {ChartType} from '../../Charts/ChartsConfig'
import {BarLoading} from '../../core/components/Loading'
import JSZip from 'jszip'
import {useAnalysisSlice} from '../hooks/useAnalysisSlice'
import Raven from 'raven-js'
import {getFilteredDecisionsByPoints} from '../inspect/Inspect'
import {showPopupMessage} from '../../core/utils/notifications'
import { PrintContainer, GraphChartWrapper } from './Reports.styles'
import {decisionsFiltered} from "../../dashboard/components/DecisionFilter/Dashboard.functions";
import {RESTRICTIONS} from "../../core/const";

let zip = null
let imageZip = null
const MIN_DECISION_LIMIT = 100

const checkCharts = () => {
    const plotChart = document.getElementsByClassName('plotly-container')
    const reactChart = document.getElementsByClassName('recharts-wrapper')
    return plotChart || reactChart
}

const IMAGE_ZOOM_FACTOR = 3;

export const collectLowDemographics = decision => {

    const stats = {
        limitsDecisionCountBy: RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY,
        department: {},
        role: {},
        filteredByCount: {}
    };
    const demographicsToShrink = Object.keys( stats.limitsDecisionCountBy );

    decision
        .forEach( p =>
            demographicsToShrink.forEach( demographic => {
                if (p.demographics !== null && p.demographics[demographic]) {
                    if( ! stats[demographic][p.demographics[demographic]] )
                        stats[demographic][p.demographics[demographic]] = 1;
                    else
                        stats[demographic][p.demographics[demographic]]++;
                }
            })
        );

    demographicsToShrink.forEach( demographic => {
        stats.filteredByCount[demographic] =
            [...new Set(Object.values( stats[demographic] ) )]
                .sort( (a,b) => a < b )
                .filter( (a,index) => index < RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY[demographic] )
                .flatMap( sortedValidCounts => Object.keys( stats[demographic] ).filter( b => stats[demographic][b] === sortedValidCounts ) )
                .filter( (a,index) => index < RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY[demographic] )
                .map( a => parseInt(a, 10) );
    });

    return decision.map( p => ({
        ...p,
        demographics: {
            ...p.demographics,
            department: stats.filteredByCount.department.includes(p.demographics.department) ? p.demographics.department : -2,
            role: stats.filteredByCount.role.includes(p.demographics.role) ? p.demographics.role : -2
        }
    }));
}


export const GenerateHighResImage = (props) => {
    const {decisions, filters, selectedPoints} = useDashboardSlice();
    const [loadNextChart, setLoadNextChart] = useState(false);
    const chart = props.selectedChart[props.currentImageIndex] || null;
    const filteredPoints = Object.keys(selectedPoints).length > 0
        ? getFilteredDecisionsByPoints(decisions, selectedPoints, filters)
        : decisionsFiltered(decisions, filters);
    const dataPoints = {dataShown: collectLowDemographics( filteredPoints ), dataCompare: []};
    const totalImages = props.selectedChart.length - 1;
    const {Actions} = useAnalysisSlice();
    const printRef = useRef(null);
    let generateImagesWhenChartsPresent = null;

    const generateImages = async () => {
        if( ! checkCharts() || printRef.current === null )
            return;

        const printOptions = {
            quality: 1,
            skipAutoScale: false,
            canvasHeight: printRef.current.clientHeight * IMAGE_ZOOM_FACTOR,
            canvasWidth: printRef.current.clientWidth * IMAGE_ZOOM_FACTOR 
        };

        const dataUrl = await toPng( printRef.current, printOptions );
        const imgData = dataUrl.replace('data:image/png;base64,', '');

        Actions.saveImage({
            file: imgData,
            name: props.selectedChart[props.currentImageIndex].testId
        });

        if( ! props.isReportGeneration )
            imageZip.file(`${props.selectedChart[props.currentImageIndex].testId}.png`, imgData, { base64: true });

        if( props.currentImageIndex < totalImages )
            setLoadNextChart(true);
        else {
            props.setProgressBarStatus( I18n.t('analysis.highResImage.zippingImages') );
            if( ! props.isReportGeneration ){
                zip.generateAsync({type: 'blob'})
                    .then(function (content) {
                        props.setProgressBarStatus(
                            I18n.t('analysis.highResImage.downloadingZipFile')
                        );
                        download(content, 'high_res_images.zip');
                        props.setCurrentImageIndex(null);
                        props.imageGenerationsCompleted();
                        clearImageTimeout();
                    })
                    .catch(Raven.captureException);
            } else {
                props.setCurrentImageIndex(null);
                props.imageGenerationsCompleted();
                clearImageTimeout();
            }
        }
    }

    const clearImageTimeout = () => {
        if (generateImagesWhenChartsPresent!== null) {
            clearTimeout(generateImagesWhenChartsPresent);
            generateImagesWhenChartsPresent = null;
        }
    }

    const setImageTimeout = () => {
        generateImagesWhenChartsPresent = setTimeout(() => {
            if( checkCharts() )
                generateImages();
        }, 1000);
    }

    const initImageProcessing = () => {
        zip = new JSZip()
        imageZip = zip.folder('images')
        setLoadNextChart(true)
        props.setProgressBarStatus(I18n.t('analysis.highResImage.generatingImages'))
    }

    useEffect(() => {
        if( loadNextChart && (props.currentImageIndex < totalImages || !props.currentImageIndex) ) {
            props.setCurrentImageIndex( props.currentImageIndex === null ? 0 : props.currentImageIndex + 1 );
        }
    }, [loadNextChart]);

    useEffect(() => {
        if (props.currentImageIndex === null)
            return;
        setLoadNextChart(false);
        setImageTimeout();
    }, [props.currentImageIndex] );

    useEffect(() => {
        if (props.startProcessingImage) {
            if(dataPoints.dataShown.length < MIN_DECISION_LIMIT){
                showPopupMessage({
                    title: I18n.t('strategyMap.messages.warning'),
                    message: I18n.t('analysis.highResImage.lowAmountOfDecisionsWarning'),
                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                    confirmLabel: I18n.t('strategyMap.messages.yes'),
                    onConfirmCallback: () => {
                        initImageProcessing()
                    },
                    onCancelCallback: () => {
                        props.cancelGeneration();
                    },
                })
            } else
                initImageProcessing();
        } else
            clearImageTimeout();
    }, [props.startProcessingImage] );

    if( ! props.startProcessingImage || ! chart )
        return null;

    const calculateWith = size => {
        switch( chart.Type ){
            case ChartType.BarVertical:
            case ChartType.BarHorizontal:
                return 4.*size/3;
            default:
                return size || 400;
        }
    }

    return (
        <>
            {chart && (
                <GraphChartWrapper>
                    {loadNextChart ? (
                        <BarLoading />
                    ) : (
                        <PrintContainer ref={printRef}>
                            <Charts
                                {...chart}
                                testId={chart.testId + '_report'}
                                dataObject={dataPoints}
                                isCompared={false}
                                language={I18n._localeKey}
                                coordinate={{
                                    height: chart.size ? chart.size : 400,
                                    width: calculateWith(chart.size)
                                }}
                            />
                        </PrintContainer>
                    )}
                </GraphChartWrapper>
            )}
        </>
    )
}
