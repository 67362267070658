import React from "react";
import header_image from "../../img/Topbar_dashboard_icon.svg";
import {I18n} from "react-i18nify";

const DashboardTitle = () => {

    const config = {
        fontSizeBig: 28,
        imageSizeBig: 65,
    }

    const Image = ({src}) =>
        <div style={{float: 'right', marginLeft: 16, marginTop: 0, marginRight: -18}}>
            <img height={ config.imageSizeBig } src={src} />
        </div>;

    const BannerText = ({text}) =>
        <div style={{fontSize: config.fontSizeBig, fontStyle: "italic", marginTop: -4, float: 'left', fontWeight: 'bold'}}
                    dangerouslySetInnerHTML={{__html: text }} />

    return <div style={{marginBottom: 30, flex: 1, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', top: -5}}>
            <div style={{width: 'fit-content'}}>
                <Image src={header_image} />
                <BannerText text={I18n.t("dashboard.BannerText")} />
            </div>
        </div>
}

export default DashboardTitle;