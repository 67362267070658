import React from 'react'
import {curry} from 'ramda'
import Raven from 'raven-js'
import {consoleError} from '../core/utils/error'

function withSentryErrorHandler(FallbackComponent, Component) {
    class WithSentryErrorHandler extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                hasError: false,
                error: null,
                errorInfo: null,
            }
        }

        componentDidCatch(error, info) {
            this.setState({hasError: true, error, errorInfo: info})

            consoleError('Javascript Error:', error.message, error.stack)

            Raven.captureException(error, {
                extra: {
                    'error message': '' + error.message,
                    'error stack': '' + error.stack,
                },
            })
        }

        render() {
            if (this.state.hasError) {
                const {error, errorInfo} = this.state
                return <FallbackComponent {...this.props} error={error} errorInfo={errorInfo} />
            }

            return <Component {...this.props} />
        }
    }
    WithSentryErrorHandler.displayName = `withSentryErrorHandler(${Component.displayName})`
    return WithSentryErrorHandler
}

export default curry(withSentryErrorHandler)
