import {useEffect, useMemo, useState} from 'react'
import * as Y from 'yjs';
import {WebsocketProvider} from 'y-websocket';
import {useQuill} from 'react-quilljs';
import QuillCursors from 'quill-cursors';
import {QuillBinding} from 'y-quill';
import 'quill/dist/quill.snow.css'; // Add css for snow theme

// https://gitter.im/Yjs/community

const editToolbarItems = {
    cursors: true,
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
        [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
        ['link']
    ]
};

const getWebsocketUrl = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const host = window.location.hostname;
    const [serverPort, directory] =
        process.env.NODE_ENV === 'development' ? [1234, ''] : [443, '/ws-collab'];

    return protocol + host + ':' + serverPort + directory;
};

export const useCollaborativeQuill = (
    dataID,
    roomName,
    placeholder,
    onTextChange,
    isReadOnly,
    text
) => {
    const {quill, Quill, quillRef} = useQuill({modules: editToolbarItems, placeholder});
    const collaborativeDocument = useMemo(() => new Y.Doc(), []);

    const collaborativeText = useMemo(() => collaborativeDocument.getText('quill' + dataID), []);

    const serverUrl = useMemo(() => getWebsocketUrl(), []);
    const isCollaborative = !! dataID;

    const provider = useMemo(
        () =>
            isCollaborative
                ? new WebsocketProvider(serverUrl, roomName, collaborativeDocument)
                : null
        , [roomName]
    );
    const websocket = ( provider?.ws) || null;
    const [isLoading, setIsLoading] = useState(true);

    const saveQuill = (eventType) => {
        if(eventType === 'user' )
            onTextChange( quill.container.querySelector('.ql-editor').innerHTML )
    }

    if( Quill && ! quill )
        Quill.register('modules/cursors', QuillCursors);

    useEffect(() => {
        return () => {
            provider?.destroy();
        }
    }, []);

    useEffect(() => {
        if( quill ){
            if( isReadOnly ){
                quill.disable();
            } else if( ! isCollaborative ){
                setIsLoading(false);
                quill.clipboard.dangerouslyPasteHTML(text || '');
            }
        }
    }, [quill, isReadOnly]);

    useEffect(() => {
        if( isCollaborative && quill ){
            provider.on('sync', () => {
                setIsLoading(false);
                const collaborativeDataEntries = collaborativeDocument.store.clients.size;
                // there are initially no entries (eg when we create a new node), so we need to seed the yjs server
                // with one entry to get the ball rolling
                if( collaborativeDataEntries === 0 ){
                    // for some reason, if we send this immediately, yjs server will cancel it a delete operation
                    // this is a bit of a hack, but I couldnt find a better way
                    setTimeout(() => quill.clipboard.dangerouslyPasteHTML(text || ''), 100);
                }
            })
        }
    }, [provider, quill]);

    useEffect(() => {
        const connectionFailed = ! websocket && provider && provider.wsUnsuccessfulReconnects > 0;
        if( connectionFailed && isLoading ){
            setIsLoading(false);
            quill.clipboard.dangerouslyPasteHTML(text || '');
        }
    }, [websocket]);

    // bugfix - reenable link click
    const openLink = url => window.open(url,'_blank');
    const tooltip = document.querySelector(".ql-tooltip > .ql-preview");
    if( tooltip )
        if( tooltip.textContent && tooltip.href && tooltip.textContent )
            tooltip.addEventListener('click', () => openLink(tooltip.href));

    useEffect(() => {
        let timeoutID;
        if( quill && ! isLoading ){
            quill.on('text-change', (currentDelta, prevDelta, eventType) => {
                if (timeoutID)
                    clearTimeout(timeoutID);
                timeoutID = setTimeout(() => {
                    clearTimeout(timeoutID);
                    saveQuill(eventType);
                }, 1000);
            });
            if( isCollaborative ){
                new QuillBinding(collaborativeText, quill, provider.awareness);
            }
        }
    }, [provider, quill, isLoading]);

    return [provider, quillRef, isLoading];
}
