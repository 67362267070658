export const DecisionAnswers = {
    mood: ['positive', 'neutral', 'negative', 'notsure'],
    frequency: ['rarely', 'nowandthen', 'often', 'allthetime', 'notsure'],
    speed: ['rightaway', 'hour', 'day', 'week', 'month', 'forever', 'notsure'],
    owner: ['me', 'colleague', 'team', 'manager', 'external', 'groupofpeople', 'notsure'],
    type: ['strategic', 'tactical', 'notsure'],
    triangles: [6, 5, 4, 3, 2, 1].flatMap(
            triangleId => {
                if(triangleId === 3 || triangleId === 6) {
                    return ["top", "bottomLeft", "bottomRight", "left", "right", "bottom", "center"].map( corner => 'charts.Corner.Triangle.'+triangleId+'.'+corner)
                }
                return ["top", "bottomLeft", "bottomRight", "center"].map( corner => 'charts.Corner.Triangle.'+triangleId+'.'+corner)
            }
            ),
}

export const OrderingAll =
    [DecisionAnswers.mood, DecisionAnswers.frequency, DecisionAnswers.speed, DecisionAnswers.owner, DecisionAnswers.type, DecisionAnswers.triangles]
        .flat();
