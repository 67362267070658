import React from 'react'
import Charts from '../../Charts/Charts'
import {ChartType} from '../../Charts/ChartsConfig'
import {getTooltip} from '../../Charts/ChartsOrgScan'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {I18n} from 'react-i18nify'
import {getFilteredDecisionsByPoints} from './Inspect'
import {DashboardItemLabel} from '../../dashboard/components/Dashboard.styles'
import {InspectGraphsChartContainer, InspectGraphsChartStyle, InspectGraphsChartTitle,} from './Inspect.styles'
import {REPORT_CHARTS} from "../../Charts/ChartsOrgScanReport";

const EXTRA_CHARTS = [
    'bar-mood',
    'bar-period-transpose',
    'bar-frequency',
    'bar-owner',
    'bar-department',
    'bar-year',
    'bar-city',
    'bar-country',
    'bar-type',
    'bar-role'
];

export const INSPECT_CHARTS = REPORT_CHARTS.filter(
    charts =>
        [ChartType.HeatMapReactangle, ChartType.Triangle].includes( charts.Type )
        || EXTRA_CHARTS.includes( charts.testId )
)

const InspectGraphs = () => {
    const {decisionsSearch, selectedPoints, filters} = useDashboardSlice()

    const filtered = getFilteredDecisionsByPoints(decisionsSearch, selectedPoints, filters)
    const dataPoints = {dataShown: filtered, dataCompare: []}

    const showHelpText = (key, tooltip) => {
        if (tooltip)
            return (
                <DashboardItemLabel>
                    <div style={{float: 'right'}}>
                        {tooltip && getTooltip(I18n.t(tooltip), `${key}-tip`)}
                    </div>
                </DashboardItemLabel>
            )
    }

    const showCharts = () => {
        return INSPECT_CHARTS.map((chart, i) => (
            <InspectGraphsChartStyle width={'360px'} even={i % 2} key={`${chart.testId}-${i}`}>
                <InspectGraphsChartTitle>
                    <p style={{float: 'left'}}>{I18n.t(chart.title)}</p>
                    {showHelpText(`${chart.testId}-_inspect`, chart.tooltip)}
                </InspectGraphsChartTitle>
                <InspectGraphsChartContainer>
                    <Charts
                        {...chart}
                        testId={`${chart.testId}-_inspect`}
                        dataObject={dataPoints}
                        isCompared={false}
                        language={I18n._localeKey}
                        coordinate={{height: 250, width: 250}}
                    />
                </InspectGraphsChartContainer>
            </InspectGraphsChartStyle>
        ))
    }

    return <>{showCharts()}</>
}

export default React.memo(InspectGraphs)
