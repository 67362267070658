import {I18n} from 'react-i18nify'
import {barSchema, barSchemaBlue, cultureSchema, moodColor} from './ChartColorSchema'
import {ChartColumns, ChartType, SubTypes, TriangleConfig} from './ChartsConfig'
import * as Color from './ChartColor'
import {DecisionAnswers} from './StackedBar/agileBarConfig'
import {TRANSLATIONS_MAP} from '../core/utils/language';
import Triad from './calculationsUtils/triad'
import Point from './calculationsUtils/point'

export const externalFiltering = (decisions, { filterKey, filterValue } = {}) => {
    if (filterKey && decisions.length >= 1) {
        return decisions.filter( decision => decision[filterKey] === filterValue )
    }
    return decisions
}

export const getDemographicsAndDepartmentStats = (departments, wholeCompanyPeopleCount, maxDecisionCount) => {
    if (typeof departments !== 'object' || !Number.isFinite(wholeCompanyPeopleCount)) {
        throw new Error('Invalid input');
    }

    const DEFAULT_MAX_DECISION_COUNT = 200;
    const demographics = {};
    const departmentStat = {};

    Object.values(departments)
        .forEach( (department) => {
            const {decisionDepartmentCount, decisionsThreshold, peopleCount, name} = department;
            demographics[department] = decisionsThreshold;
            let idealTotal = Math.round(
                (peopleCount * (maxDecisionCount || DEFAULT_MAX_DECISION_COUNT)) /
                    wholeCompanyPeopleCount
            );
            if (idealTotal <= decisionDepartmentCount + 20) {
                idealTotal = decisionDepartmentCount + 20;
            }
            departmentStat[name] = Math.round((decisionDepartmentCount / idealTotal) * 100);
    });

    return { demographics, departmentStat };
}

export const getPoints = (data, isCompared, type, subType, subTypes, testId, demographics) => {

    switch (type) {
        case ChartType.PieChart:
            return grouping(data[0], subType, demographics);

        case ChartType.HeatMapCrossProduct:
            return getMatrix(data[0], subTypes, testId, demographics);

        case ChartType.Statistics:
            return getStatistics(data[0], subType);

        case ChartType.Triangle:
            return [
                getHeatMapData(data[0], 'triangles')[subType],
                getHeatMapData(data[1], 'triangles')[subType]
            ];

        case ChartType.HeatMapReactangle:
            if (subTypes) {
                const heatMapData = getHeatMapData(data[0], 'cultural_profile')
                return subTypes.map((type) => heatMapData[type]);
            } else {
                return [
                    getHeatMapData(data[0], 'cultural_profile')[subType],
                    getHeatMapData(data[1], 'cultural_profile')[subType]
                ];
            }

        case ChartType.CrossProduct:
            return [
                getCrossProductOnQuestion(data[0], subTypes, demographics)
            ];

        case ChartType.BarStacked:
        case ChartType.BarStackedHorizontal:
            return [
                getQuestionGroupCount(data[0], demographics, testId)
            ];
        case ChartType.GroupedBar:
            return [
                getCrossProductOnQuestionForGrouped(data[0], subTypes, demographics)
            ];

        case ChartType.BarVertical:
        case ChartType.BarHorizontal:
        case ChartType.BarHorizontalCustom:
        case ChartType.CircleProgress:
            if (isCompared) {
                return [
                    getQuestionGroupCount(data[0], demographics, testId)
                        .filter( (item) => item.name === subType ),
                    getQuestionGroupCount(data[1], demographics, testId)
                        .filter( (item) => item.name === subType )
                ];
            } else {
                return [
                    getQuestionGroupCount(data[0], demographics, testId)
                        .filter( (item) => item.name === subType ),
                ];
            }

        default:
            return [{x: 0, y: 0}];
    }
}

const grouping = (data, subtype, demographics) =>
    data.reduce((acc, curr) => {
        const value = isDemographics(subtype)
            ? getDemographicsName(curr, subtype, demographics)
            : curr[subtype]
        const increment = acc[value] ? acc[value] + 1 : 1
        return {...acc, [value]: increment}
    }, {})

const _matrixCommulation = (matrix, rowValue, columnValue) => {
    if (!matrix[rowValue]) matrix[rowValue] = {}
    matrix[rowValue][columnValue] = matrix[rowValue][columnValue]
        ? matrix[rowValue][columnValue] + 1
        : 1
    return matrix
}

const getNumberOfWeeksWithData = (data) => {
    const extractDates = data.map(a => a.date);
    const distinctDates = [...new Set(extractDates)];
    const datesOrder = distinctDates.sort((a, b) => new Date(a) - new Date(b));
    const startDateObj = new Date(datesOrder[0] || '1970-01-01');
    const endDateObj = new Date(datesOrder[datesOrder.length-1] || new Date());
    
    const datesBetweenStartDateAndEndDate = datesOrder.filter(date => {
        const dateObj = new Date(date);
        return dateObj >= startDateObj && dateObj <= endDateObj;
    });

    const getFirstDateOfTheWeekByDateFormated = (date) => {
        const dateObj = new Date(date);
        const dayOfWeek = dateObj.getDay();
        const firstDayOfWeek = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - dayOfWeek);
        return firstDayOfWeek.toISOString().split('T')[0];
    }
    const firstDayOfWeekFormated = getFirstDateOfTheWeekByDateFormated(datesBetweenStartDateAndEndDate[0]);
    
    const clusterDatesByWeek = datesBetweenStartDateAndEndDate.reduce((acc, curr) => {
        const week = Math.floor((new Date(curr) - new Date(firstDayOfWeekFormated)) / (1000 * 60 * 60 * 24 * 7));
        if (!acc[week]) acc[week] = [];
        acc[week].push(curr);
        return acc;
    }, {});
    
    return Object.keys(clusterDatesByWeek).length || 0;
}

const getWeights = (coordinates) => {
    const triad = new Triad();
    const point = new Point(coordinates.x, coordinates.y);
    return triad.getWeights(point);
}

const getAverageByNumberOfWeeks = (transformedData, numberOfWeeksWithData) => {
    Object.keys(transformedData).map((row) => {
        Object.keys(transformedData[row]).map((column) => {
            transformedData[row][column] = +(transformedData[row][column] / numberOfWeeksWithData).toFixed(2)
        })
    });
}

const getMatrix = (data, subTypes, testId, demographics) => {
    const excludeAnswers = ['notsure'];

    const [rows, columnCategory] = subTypes;

    const matrix = {}

    data.forEach((decision) => {
        rows.forEach((row) => {
            let columnValue = null
            let rowValue = null
            const triangleRow = decision.triangles.find((triangle) => triangle.triangle.toString() === row)
            if ((triangleRow && triangleRow.not_applicable) || decision[row] === 'notsure') {
                return
            }
            if (Object.values(SubTypes.triangle).includes(row)) {

                const intermediateValues = [3, 6].includes(row)

                const [topPropertyWeight, rightPropertyWeight, leftPropertyWeight] = getWeights(triangleRow);
                
                const triangleCorner = getInfluencedByXZY(
                    topPropertyWeight,
                    leftPropertyWeight,
                    rightPropertyWeight,
                    intermediateValues
                )
                rowValue = 'T' + row + ' ' + I18n.t('charts.Corner.Triangle.' + row + '.' + triangleCorner)
                columnValue = decision[columnCategory]
            }

            if (isDemographics(row)) {
                rowValue = getDemographicsName(decision, row, demographics)
                if (!demographics[row] || demographics[row].length === 0) {
                    return
                }
            }

            rowValue = rowValue || decision[row]

            if (SubTypes.special.compromise === columnCategory) {
                rowValue = rowValue || I18n.t('charts.answers.' + decision[row])

                // remind you that this compromise is a muliplier, means that the sum is not equal to amount of decisions
                const triangleID = 6;
                const triangle = decision.triangles.filter((triangle) => triangle.triangle === triangleID);

                const invertNormalisedPositionFromRectangleToEquilateralTriangle = (position) => {
                    const posYPercentage = Math.trunc(Triad.HEIGHT * position.y * 100) / 100;
                    
                    return {
                        x: position.x,
                        y: posYPercentage - (posYPercentage * .02),
                    }
                }

                if (triangle.length && !triangle[0].not_applicable) {

                    const triad = new Triad();
                    const invertedNormalisedPosition = invertNormalisedPositionFromRectangleToEquilateralTriangle(triangle[0]);
                    
                    const point = new Point(invertedNormalisedPosition.x, invertedNormalisedPosition.y);
                    const pointPosition = triad.findContainingShape(point);
                    
                    switch (pointPosition) {
                        case "Left":
                            columnValue = ChartColumns.special.compromise.resources_vs_time
                            break;
                        case "Right":
                            columnValue = ChartColumns.special.compromise.relationship_vs_resource
                            break;
                        case "Top":
                            columnValue = ChartColumns.special.compromise.relationship_vs_time
                            break;
                        case "Center":
                            columnValue = ChartColumns.special.compromise.trade_of_all_three
                            break;
                        case "LeftCenterRight":
                            columnValue = ChartColumns.special.compromise.resources
                            break;
                        case "LeftCenterTop":
                            columnValue = ChartColumns.special.compromise.time
                            break;
                        case "RightCenterTop":
                            columnValue = ChartColumns.special.compromise.relationship
                            break;
                    }
                }
            } else {
                columnValue = decision[columnCategory]

                if (excludeAnswers.includes(rowValue) || excludeAnswers.includes(columnValue)) {
                    return
                }
            }

            if (rowValue && columnValue) {
                _matrixCommulation(matrix, rowValue, columnValue)
            }
        })
    })
    
    if (testId === 'table-crossproduct-timetaken-frequency_report' || testId === 'table-crossproduct-timetaken-frequency') {
        const numberOfWeeksWithData = getNumberOfWeeksWithData(data);
        getAverageByNumberOfWeeks(matrix, numberOfWeeksWithData);
    }
    return matrix
}

export const getStatistics = (data, subType) => {
    switch (subType) {
        case SubTypes.statistics.trianglesAverage:
            return getStatisticsAVG(data, subType)
        case SubTypes.statistics.stonesAverage:
            const statistic = {};
            data.map(decision => {
                const mood = decision.mood;
                decision.cultural_profile.forEach(diagram => {
                    const type = I18n.t("charts.Topic.Cartesian." + diagram.name);
                    if (!statistic[mood]) statistic[mood] = {}
                    if (!statistic[mood][type + '-X']) statistic[mood][type + '-X'] = []
                    if (!statistic[mood]) statistic[mood] = {}
                    if (!statistic[mood][type + '-Y']) statistic[mood][type + '-Y'] = []

                    statistic[mood][type + '-X'].push(diagram.x)
                    statistic[mood][type + '-Y'].push(diagram.y)
                })
            })
            const finalData = Object.keys(statistic).map(mood =>
                Object.keys(statistic[mood]).map(type => {
                    return {
                        y: type,
                        x: statistic[mood][type].reduce((acc, cur) => cur + acc, 0) / statistic[mood][type].length * 200 - 100,
                        name: I18n.t('charts.Answers.' + mood),
                        color: moodColor[mood]
                    }
                })
            );
            const orderDiagram = () => [
                I18n.t("charts.Topic.Cartesian.LeaderType"),
                I18n.t("charts.Topic.Cartesian.Orientation"),
                I18n.t("charts.Topic.Cartesian.Effectiveness"),
                I18n.t("charts.Topic.Cartesian.ValueDrivers")
            ];
            const orderDiagramWithAxis = orderDiagram()
                .flatMap( correctOrder =>
                    ["-X", "-Y"]
                        .flatMap( extension => correctOrder + extension ) );

            return finalData
                .map( mood => mood.sort( (a,b) => orderDiagramWithAxis.indexOf(b.y) - orderDiagramWithAxis.indexOf(a.y) ) );

        case SubTypes.statistics.emotionByQuestion:
            return getStatisticsQuestions(data, subType)
        case SubTypes.statistics.mostInfluencedValues:
            return [getStatisticsMostInfluenced(data).map(e => ({...e, y: I18n.t(e.y)}))]
        default:
            return null
    }
}
export const getStatisticsMostInfluenced = (decisions) => {

    const countingValues = splittingByEmote(decisions)
    const importance = importanceCalculation(countingValues)
    return sortByValue(importance).map(e => ({y: e.name, x: e.factor}))
}

const splittingByEmote = (decisions) => {

    const moodOptions = ["positive", "negative"];

    const result = {};

    decisions
        .filter(decision => moodOptions.includes(decision.mood))
        .forEach(decision => {

            const mood = decision.mood;

            decision.cultural_profile.forEach(cart => {
                const partX = cart.x > .5 ? "right" : "left";
                const partY = cart.y > .5 ? "up" : "down";
                if (!result["charts.Topic.Cartesian." + cart.name]) {
                    result["charts.Topic.Cartesian." + cart.name] = {
                        positive: {left_up: 0, left_down: 0, right_up: 0, right_down: 0},
                        negative: {left_up: 0, left_down: 0, right_up: 0, right_down: 0}
                    }
                }
                result["charts.Topic.Cartesian." + cart.name][mood][partX + "_" + partY] += 1;
            })

            decision.triangles.forEach(triangle => {

                if (!result["charts.Topic.Triangle." + triangle.triangle])
                    result["charts.Topic.Triangle." + triangle.triangle] = {
                        positive: {left_property_weight: 0, top_property_weight: 0, right_property_weight: 0},
                        negative: {left_property_weight: 0, top_property_weight: 0, right_property_weight: 0}
                    }
                const part = ["left_property_weight", "top_property_weight", "right_property_weight"]
                    .reduce((acc, curr) => {
                        const weights = getWeights(triangle);
                        const weightsObj = {
                            left_property_weight: weights[2],
                            top_property_weight: weights[0],
                            right_property_weight: weights[1]
                        }
                        
                        return weightsObj[acc] > weightsObj[curr]
                                ? acc
                                : curr
                    }
                        , "left_property_weight")
                result["charts.Topic.Triangle." + triangle.triangle][mood][part] += 1
            });

            ['frequency', 'decision_owner', 'type', 'period'].forEach(question => {

                if (!result["charts.Topic.Bar." + question])
                    result["charts.Topic.Bar." + question] = {
                        positive: {},
                        negative: {}
                    }
                if (!result["charts.Topic.Bar." + question][mood][decision[question]])
                    result["charts.Topic.Bar." + question][mood][decision[question]] = 0
                result["charts.Topic.Bar." + question][mood][decision[question]] += 1
            })
        })

    return result;
}

const normalizeNumberArray = (arr) => arr.map(e => e !== "" && !isNaN(e) ? e : 0)

const importanceCalculation = (grouped) => {

    const resultSector = {};

    Object.keys(grouped).forEach(tag => {

        Object.keys(grouped[tag]).forEach(mood => {

            if (!resultSector[tag]) resultSector[tag] = {}
            if (!resultSector[tag][mood]) resultSector[tag][mood] = {}

            let moodValues = Object.values(Object.values(grouped[tag][mood]))
            moodValues = normalizeNumberArray(moodValues);

            const peak = Math.max(...moodValues)
            const sum = moodValues.reduce((acc, curr) => acc + curr, 0)
            resultSector[tag][mood] = sum === 0 ? 0 : peak / sum * 100
        })

        resultSector[tag] = Math.max(...Object.values(resultSector[tag]))
    });

    return resultSector;
}

const sortByValue = (json) => {

    const json2array = Object.keys(json)
        .map(tag => ({name: tag, factor: json[tag]}))
        .sort((a, b) => a.factor - b.factor)

    return json2array
}
export const getStatisticsQuestions = (data) => {
    const result = {}
    const excludeQuestions = [
        'pk',
        'demographics',
        'triangles',
        'cultural_profile',
        'date',
        'experiments',
        'title',
        'description',
        'mood',
    ]
    const excludeAnswers = ['notsure']

    data.forEach((decision) => {
        const validQuestions = Object.keys(decision).filter(
            (tag) => !excludeQuestions.includes(tag),
        )

        validQuestions.forEach((tag) => {
            if (!excludeAnswers.includes(decision[tag]) && decision[tag].length) {
                const rowName = I18n.t('charts.Answers.' + decision[tag])

                if (result[rowName] === undefined) result[rowName] = {}
                const moodI18n = I18n.t('charts.Answers.' + decision.mood)
                result[rowName][moodI18n] = result[rowName][moodI18n]
                    ? result[rowName][moodI18n] + 1
                    : 1
            }
        })
    })

    return result
}

export const getStatisticsAVG = (data, subType) => {
    const result = []

    data.forEach((decision) => {
        decision[subType].forEach((decisionValues) => {
            if (subType && SubTypes.statistics.trianglesAverage && !decisionValues.not_applicable) {
                const [topPropertyWeight, rightPropertyWeight, leftPropertyWeight] = getWeights(decisionValues);
                result.push({
                    mood: decision.mood,
                    name: 'T' + decisionValues.triangle + ' ' + I18n.t(
                        'charts.Corner.Triangle.' +
                        decisionValues.triangle +
                        '.' +
                        getInfluencedByXZY(
                            topPropertyWeight,
                            leftPropertyWeight,
                            rightPropertyWeight,
                        ),
                    ),
                })
            }
        })
    })

    const tags = [...new Set(result.map((a) => a.name))].sort((a,b) => b.localeCompare(a))

    return DecisionAnswers.mood
        .map((mood) => {
            return tags.map((tag) => {
                const count = result.filter(
                    (a) => a.mood === mood.toLocaleLowerCase() && a.name === tag,
                ).length
                return {
                    y: tag,
                    x: count || 0,
                    name: I18n.t('charts.Answers.' + mood),
                    color: moodColor[mood],
                }
            })
        })
        .filter((a) => a.length)
}


export const getInfluencedByXY = (x, y) => (y - 0.5 + (x - 0.5) > 0.5 ? 'X' : 'Y')
export const getInfluencedByXZY = (top, bottomLeft, bottomRight, intermediateValues = false) => {
    const {Corners} = TriangleConfig
    const maxValue = Math.max(top, bottomLeft, bottomRight)

    if (intermediateValues) {
        if (top < 25 && bottomLeft < 25) {
            return Corners.bottomRight
        } else if (top < 25 && bottomRight < 25) {
            return Corners.bottomLeft
        } else if (bottomLeft < 25 && bottomRight < 25) {
            return Corners.top
        } else if (top < 25) {
            return Corners.bottom
        } else if (bottomLeft < 25) {
            return Corners.right
        } else if (bottomRight < 25) {
            return Corners.left
        } else{
            return Corners.center
        }
    } else {
        if (maxValue < 55) {
            return Corners.center
        }
        switch (maxValue) {
            case top:
                return Corners.top
            case bottomLeft:
                return Corners.bottomLeft
            case bottomRight:
                return Corners.bottomRight
        }
    }
}

export const isDemographics = (tag) =>
    ['department', 'country', 'city', 'years', 'role'].includes(tag);

const getCrossProductOnQuestion = (data, [questionOne, questionTwo], demographics) => {
    const result = [];

    data.forEach( ( decision ) => {
        if( decision.demographics ){
            const translatedAnswerOne = isDemographics(questionOne)
                ? getDemographicsName(decision, questionOne, demographics)
                : I18n.t('charts.Answers.' + decision[questionOne])
            const translatedAnswerTwo = isDemographics(questionTwo)
                ? getDemographicsName(decision, questionTwo, demographics)
                : I18n.t('charts.Answers.' + decision[questionTwo])

            const noAnswerOneResults = ! result.some((item) => item.name === translatedAnswerOne);
            if (noAnswerOneResults)
                result.push({name: translatedAnswerOne});

            const questionOneEntry = result.filter((item) => item.name === translatedAnswerOne)[0];

            if (questionOneEntry[translatedAnswerTwo] === undefined) {
                questionOneEntry[translatedAnswerTwo] = 1;
            } else {
                questionOneEntry[translatedAnswerTwo] += 1;
            }
        }
    });

    return result;
}
const getCrossProductOnQuestionForGrouped = (data, [questionOne, questionTwo], demographics) => {
    const result = [];

    data.forEach( ( decision ) => {
        if( decision.demographics ){
            const translatedAnswerOne = isDemographics(questionOne)
                ? getDemographicsName(decision, questionOne, demographics)
                : I18n.t('charts.Answers.' + decision[questionOne])

            const noAnswerOneResults = ! result.some((item) => item.name === translatedAnswerOne);
            if (noAnswerOneResults)
                result.push({name: translatedAnswerOne});

            const questionOneEntry = result.filter((item) => item.name === translatedAnswerOne)[0];

            if (questionOneEntry[questionOne] === undefined) {
                questionOneEntry[questionOne] = 1;
            } else {
                questionOneEntry[questionOne] += 1;
            }
        }
    });

    if( questionTwo === SubTypes.bar.headcount && demographics && demographics.department){

        demographics.department.forEach( dep => {
            const department =  dep[TRANSLATIONS_MAP[I18n._localeKey]] || dep.name;
            const r = result.filter( entry => department === entry.name );
            if( r.length === 1 )
                r[0]["headcount"] = dep.people_count || 0;
            else {
                result.push({name: department, "department": 0, headcount: dep.people_count || 0});
            }
        })
    }

    return result;
}

export const getQuestionGroupCount = (decisions, demographics, testId) => {
    const questions = Object.values(SubTypes.bar)

    const answerGroupCounts = []
    questions.forEach((question) => {
        answerGroupCounts[question] = {}
    })

    decisions.forEach((decision) => {
        questions.forEach((question) => {
            const tag = isDemographics(question)
                ? getDemographicsName(decision, question, demographics)
                : decision[question]

            if (answerGroupCounts[question][tag] === undefined) {
                answerGroupCounts[question][tag] = 0
            }

            answerGroupCounts[question][tag] += 1
        })
    })

    if (testId === 'bar-timetaken_report') {
        const numberOfWeeksWithData = getNumberOfWeeksWithData(decisions);
        getAverageByNumberOfWeeks(answerGroupCounts, numberOfWeeksWithData);
    }

    return transformForStackedBarDiagram(answerGroupCounts)
}

export const transformForStackedBarDiagram = (questionGroupCount) => {
    const stackbarTransformed = []

    Object.keys(questionGroupCount).forEach((question, idx) => {
        const translatedQuestion = I18n.t('charts.Answers.' + question)

        stackbarTransformed.push({name: translatedQuestion})

        Object.keys(questionGroupCount[question]).forEach((item) => {
            const translatedAnswer = item.endsWith('...') ? item : I18n.t('charts.Answers.' + item)

            stackbarTransformed[idx][translatedAnswer] = questionGroupCount[question][item]
        })
    })

    return stackbarTransformed
}

export const getHeatMapData = (points, type) => {
    const heatMaps = [];
    points.forEach( decisions => {
        const decisionDate = decisions.date;
        const decisionID = decisions.pk;
        decisions[type].forEach((decision) => {
            const isTriangle = type === 'triangles'
            const index = isTriangle ? decision.triangle : decision.name
            if (heatMaps[index] === undefined) {
                heatMaps[index] = []
            }
            decision.date = decisionDate;
            decision.mood = decisions.mood;
            decision.decisionID = decisionID;
            if(!isTriangle || !decision.not_applicable) {
                heatMaps[index].push(decision)
            }
        })
    })
    return heatMaps
}

export const getDiagramColor = (subType) => {
    switch (subType) {
        case SubTypes.triangle.T1:
            return Color.ColorsTypes.t1
        case SubTypes.triangle.T2:
            return Color.ColorsTypes.t2
        case SubTypes.triangle.T3:
            return Color.ColorsTypes.t3
        case SubTypes.triangle.T4:
            return Color.ColorsTypes.t4
        case SubTypes.triangle.T5:
            return Color.ColorsTypes.t5
        case SubTypes.triangle.T6:
            return Color.ColorsTypes.t6

        case SubTypes.culture.leaderType:
            return cultureSchema.leaderType
        case SubTypes.culture.effectiveness:
            return cultureSchema.effectiveness
        case SubTypes.culture.orientation:
            return cultureSchema.orientation
        case SubTypes.culture.valueDrivers:
            return cultureSchema.valueDrivers
        default:
            throw Error('unknown subTypes:', this.props.subType)
    }
}

export const getStackBarColorSchema = (types) => {
    if (types && types.length === 2) {
        if (types[1] === SubTypes.bar.frequency) {
            const colorSchema = Object.assign({}, barSchemaBlue)
            colorSchema['often'] = barSchema.frequency.often
            colorSchema['rarely'] = barSchema.frequency.rarely
            colorSchema['nowandthen'] = barSchema.frequency.nowandthen
            colorSchema['allthetime'] = barSchema.frequency.allthetime
            return colorSchema
        } else {
            return undefined
        }
    }
}

const getDiagramsLabel = (corners, type, subType) => {
    const diagramLabel = {};

    corners.forEach((corner) => {
        diagramLabel[corner] = I18n.t(`charts.Corner.${type}.${subType}.${corner}`)
    });
    return diagramLabel;
}

export const getCultureDiagramsLabel = (subType) => {
    const CORNERS = [
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
    ];

    return getDiagramsLabel(CORNERS, 'Culture', subType);
}
export const getTriangleDiagramsLabel = (subType) => {
    const CORNERS = [
        TriangleConfig.Corners.top,
        TriangleConfig.Corners.bottomLeft,
        TriangleConfig.Corners.bottomRight,
    ];

    return getDiagramsLabel(CORNERS, 'Triangle', subType);
}

const getDemographicsName = (decision, subtype, demographics) => {
    const section =
        demographics &&
        decision.demographics &&
        demographics[subtype] &&
        demographics[subtype]
            .filter( demo => demo.pk === decision.demographics[subtype] );

    if( subtype === 'department' && decision.demographics && decision.demographics[subtype] === -2 )
        return I18n.t("charts.Grouping.lowDecisions.onDepartments");
    if( subtype === 'role' && decision.demographics && decision.demographics[subtype] === -2 )
        return I18n.t("charts.Grouping.lowDecisions.onRoles");

    return section && section[0] ? section[0][TRANSLATIONS_MAP[I18n._localeKey]] || section[0].name : section;
}
