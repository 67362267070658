import React from 'react'
import styled from 'styled-components'

const OuterCircle = styled.div`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    border: solid 1px #95989a;
`

const InnerCircle = styled.div`
    width: 60%;
    height: 60%;
    border-radius: 50%;
    position: relative;
    top: 20%;
    left: 20%;
    background-color: #444f57;
`

const RadioButton = (props) => {
    return (
        <OuterCircle onClick={props.onClick} role="button">
            {props.enabled && <InnerCircle />}
        </OuterCircle>
    )
}

export default RadioButton
