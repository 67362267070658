import styled from 'styled-components';
import {LIGHT_GREY, PALE_YELLOW, WHITE} from '../core/ui/_colors';

export const NotificationItem = styled.div`
    border-bottom: 1px solid ${LIGHT_GREY};
    background-color: ${(p) => (p.isRead ? WHITE : PALE_YELLOW)};
    margin-left: 0;
    margin-right: 0;
`

export const CheckboxContainer = styled.div`
    padding-top: 13px;
    padding-right: 25px;
`

export const NotificationNavTools = styled.div`
    padding: 7px 15px;
    border-bottom: 1px solid ${LIGHT_GREY};
    display: flex;
    align-items: center;
    width: 100%;
`