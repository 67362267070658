import React from 'react'
import DraggableNode from './DraggableNode'
import {useSelector} from 'react-redux'
import {getTree, NODE} from './Node'
import {CurvedLine} from './CurvedLine'
import {useWindowSize} from '../../core/hooks/useWindowSize'
import {isMobile} from 'react-device-detect'
import {isGoal, isUserAbleToEditNode} from '../utils'
import {NODE_HEIGHT} from './Node.styles'
import {useUserSlice} from '../hooks/useUserSlice'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";

const TIMEAREA_TO_ID = {
    confirmed: 0,
    validating: 1,
    potential: 2,
}

const renderLine = (parent, child, nodeBlocks, selectedPath, sidebarOpen) => {
    if (isGoal(parent)) return

    const sidebarWidth = 319
    const columnWidth = (window.innerWidth - (sidebarOpen && !isMobile ? sidebarWidth : 1)) / 3
    const paddingY = 65
    const marginTopGroup = 120
    const rowHeight = NODE_HEIGHT + paddingY

    let previewsGapRows = null

    for (let i = nodeBlocks.length - 1; i < nodeBlocks.length; i--) {
        if (previewsGapRows === null || nodeBlocks[i].ids.includes(child.id)) {
            previewsGapRows = 0
        } else {
            if (nodeBlocks[i].ids.includes(parent.id)) {
                break
            }

            previewsGapRows += nodeBlocks[i].size * rowHeight + marginTopGroup - paddingY
        }
    }

    const parentPosition = {
        x: columnWidth / 2 + TIMEAREA_TO_ID[parent.time_area] * columnWidth,
        y: 0,
    }
    const childPosition = {
        x: columnWidth / 2 + TIMEAREA_TO_ID[child.time_area] * columnWidth,
        y: child.y * rowHeight + previewsGapRows + marginTopGroup,
    }
    const translatePosition = {
        x: child.x * -columnWidth,
        y: -previewsGapRows - marginTopGroup,
    }

    return (
        <CurvedLine
            key={parent.id + child.id}
            child={childPosition}
            parent={parentPosition}
            translatePosition={translatePosition}
            height={childPosition.y + previewsGapRows}
            isOpacity={selectedPath && !(selectedPath && selectedPath.includes(child.id))}
        />
    )
}

const Nodes = (props) => {
    const {x, nodes, nodeMap, childNode, nodeParentId, isOpen, nodesPerTimeArea} = props
    const {currentOpenNodeId} = useSelector((state) => state.strategyMap)
    const user = useUserSlice()
    const {sidebarOpen} = useCoreSlice();

    const draggableNodes = []
    const links = []
    useWindowSize()

    const selectedPath =
        currentOpenNodeId &&
        getTree(currentOpenNodeId, nodeMap, NODE.parents)
            .concat(getTree(currentOpenNodeId, nodeMap, NODE.children), [currentOpenNodeId])
            .flat(Infinity)

    nodes.forEach((nodeId, i) => {
        if (nodeMap[nodeId]) {
            const userAbleToEditNode = isUserAbleToEditNode(user, nodeMap[nodeId], nodeMap)
            draggableNodes.push(
                <DraggableNode
                    x={x}
                    y={i}
                    key={nodeId}
                    isOpen={isOpen}
                    nodeId={nodeId}
                    nodesPerTimeArea={nodesPerTimeArea}
                    timeArea={props.timeArea}
                    nodesLength={nodes.length}
                    userAbleToEditNode={userAbleToEditNode}
                    nodeParentId={nodeParentId}
                    node={nodeMap[nodeId]}
                    color={childNode ? 'green' : 'red'}
                    style={{backgroundColor: 'red'}}
                />,
            )

            if (nodeParentId && nodeId) {
                links.push({
                    parent: nodeMap[nodeParentId],
                    child: {...nodeMap[nodeId], x: x, y: i},
                })
            }
        }
    })

    return (
        <div>
            <div>{draggableNodes}</div>
            {links.map((link) =>
                renderLine(
                    link.parent,
                    link.child,
                    props.nodeBlocks,
                    selectedPath,
                    sidebarOpen
                )
            )}
        </div>
    )
}

export default Nodes
