import React, {useState} from "react";
import {isMobile} from "react-device-detect";
import I18n from "react-i18nify/build/lib/I18n";
import styled from "styled-components";
import {TOURS} from "./Stories";
import {BLACK_GREY, DARK_GREY, PALE_GREY} from "../core/ui/_colors";
import {DropdownList} from "../sidebars/Components/DropdownMenu";
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {useHistory} from "../core/hooks/useHistory";
import {MainNavIcon, NavItemBox} from "../core/components/Navbar/Navbar.styles";
import {NavItemIntern} from "../core/components/BootstrapWrapper";
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";
import OutsideClickHandler from 'react-outside-click-handler';

const DropdownListBox = styled(DropdownList)`
    padding-top: 0;
    padding-bottom: 0;
    margin-left: ${ props => props.left ? props.left : '-90px'};
    margin-right: 60px;
`;

const DropDownItem = styled.div`
    padding-top: ${props => props.menu ? '16px' : '8px'};
    padding-bottom: ${props => props.menu ? '16px' : '8px'};
    width: 220px;
    padding-right: 10px;
    padding-left: 10px;
    word-wrap: break-word;
    line-height: 16px;
    &:hover {
        background-color: ${PALE_GREY};
        color: ${BLACK_GREY} !important;
        * {
            color: ${BLACK_GREY} !important;
        }
    };
    color: ${DARK_GREY};
    opacity: ${ props => (props.selected || props.menu) ? '100%' : '80%'}
    border: ${props => props.menu ? '1px solid '+PALE_GREY : '1px solid transparent'};
`;

const TutorialsIcon = () => {

    const [open, setOpen] = useState(false);
    const user = useUserSlice();
    const {Actions: CoreActions} = useCoreSlice();
    const {currentPage} = useHistory();

    const openMenu = (e) => {
        if(!open)
            CoreActions.closeSidebar();
        setOpen(!open);
    }
    const closeMenu = (e) => {
        if(open) {
            CoreActions.closeSidebar();
            setOpen(false);
        }
    }

    const availableTours = TOURS(user).filter( tour => tour.required ).filter( tour => tour.page === currentPage);

    return (
        <NavItemIntern>
            <OutsideClickHandler onOutsideClick={closeMenu}>
                <NavItemBox
                    onClick={() => openMenu()}
                >
                    <MainNavIcon className="material-icons-outlined">help_outline</MainNavIcon>
                        <DropdownListBox isOpen={open} left={isMobile}>
                        {
                            availableTours
                                .map( (tour,key) => (
                                    <DropDownItem key={key} menu={true} onClick={() => CoreActions.startHelpTour(tour.tour)}>
                                        {I18n.t(tour.title)}
                                    </DropDownItem>))
                        }
                        {
                            ! availableTours.length &&
                            <DropDownItem menu={true}>
                                {I18n.t("stories.help.noTours")}
                            </DropDownItem>
                        }
                    </DropdownListBox>
                </NavItemBox>
            </OutsideClickHandler>
        </NavItemIntern>
    )
};

export default TutorialsIcon;