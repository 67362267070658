import React from 'react'
import Select from 'react-select'
import { DARK_GREY } from '../ui/_colors'

const minimalistStyles = {
    control: (base) => ({
        ...base,
        border: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: () => ({
        color: DARK_GREY
    }),
    dropdownIndicator: base => ({
        ...base,
        color: DARK_GREY,
        "&:hover": {
            color: DARK_GREY
        }
    }),
    singleValue: () => ({
        color: DARK_GREY,
        fontWeight: 'bold',
        fontSize: '20px',
        maxWidth: "95%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
}
// WARNING: Avoid using it inside a form.
const SelectWrapper = (props) => {
    const selectProps = {
        ...props
    }
    if (props.minimalistView) {
        selectProps.styles = minimalistStyles
    }
    return <form autoComplete="off">
        <Select
            {...selectProps}
        />
    </form>
}

export default SelectWrapper