import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {LIGHT_GREY, WHITE} from '../../core/ui/_colors'
import {getNonArchivedNodes, isExperiment, isNC, isUserAbleToEditNode} from '../utils'
import MiniChildStackTooltip from './MiniChildStackTooltip'
import AddNodeFromNodeCard from './AddNodeFromNodeCard'
import {useUserSlice} from '../hooks/useUserSlice'

const MiniChildBar = styled.div`
    border-top: 1px solid ${LIGHT_GREY};
    background-color: ${WHITE};
    min-height: 33px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 9px 12px;
`

const LeftMenu = styled.div`
    float: left;
    margin-right: 12px;
`

const MiniNodeStackList = ({node, nodeChildren, openChildrenNode}) => {
    const {nodeMap, showHiddenExperiment} = useSelector((state) => state.strategyMap)
    const nodes = nodeChildren.map((childId) => nodeMap[childId] || {})
    const ncNodes = nodes.filter((node) => isNC(node))
    const expNodes = nodes.filter(
        (node) => isExperiment(node) && (showHiddenExperiment || !node.is_finished),
    )
        
    return (
        <div className="row">
            {!!ncNodes.length && (
                <MiniChildStackTooltip
                    isExperiment={false}
                    openChildrenNode={openChildrenNode}
                    nodes={ncNodes}
                    node={node}
                />
            )}
            {!!expNodes.length && (
                <MiniChildStackTooltip
                    isExperiment={true}
                    openChildrenNode={openChildrenNode}
                    nodes={expNodes}
                    node={node}
                />
            )}
        </div>
    )
}

const MiniNodeChildren = (props) => {
    const {nodeMap} = useSelector((state) => state.strategyMap)
    const currentActiveUser = useUserSlice()
    const isUserAbleToEdit = isUserAbleToEditNode(currentActiveUser, props.node, nodeMap)

    return (
        <MiniChildBar data-testid={'mini-nodes-bar'}>
            <LeftMenu>{isUserAbleToEdit && <AddNodeFromNodeCard node={props.node} />}</LeftMenu>
            {props.show && <MiniNodeStackList {...props} nodeChildren={getNonArchivedNodes(props.node.children, nodeMap)} />}
        </MiniChildBar>
    )
}

export default MiniNodeChildren
