import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {DARK_GREY, WHITE} from '../../core/ui/_colors'

const AcceptRejectButtonsDiv = styled.div`
    float: right;
`

const AcceptRejectDiv = styled.div`
    display: inline-block;
    margin-left: 4px;
`

const AcceptRejectButton = styled.div`
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.color};
    border: none;
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    &:hover {
        svg {
            transform: scale(1.3);
        }
        box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.3);
    }
    svg {
        outline: none;
        transition: transform 0.15s linear;
    }
`

const AcceptRejectButtons = (props) => {
    return (
        <AcceptRejectButtonsDiv>
            <AcceptButton acceptRequest={props.acceptRequest} />
            <RejectButton rejectRequest={props.rejectRequest} />
        </AcceptRejectButtonsDiv>
    )
}

AcceptRejectButtons.propTypes = {
    acceptRequest: PropTypes.func,
    rejectRequest: PropTypes.func,
}

export const RejectButton = (props) => {
    return (
        <AcceptRejectDiv>
            <AcceptRejectButton
                onClick={props.rejectRequest}
                color={WHITE}
                data-testid={'reject-squad-member-button'}
            >
                <span style={{color: DARK_GREY}} className="material-icons-outlined">close</span>
            </AcceptRejectButton>
        </AcceptRejectDiv>
    )
}

RejectButton.propTypes = {
    rejectRequest: PropTypes.func,
}

export const AcceptButton = (props) => {
    return (
        <AcceptRejectDiv>
            <AcceptRejectButton
                onClick={props.acceptRequest}
                color={DARK_GREY}
                data-testid={'accept-squad-member-button'}
            >
                <span style={{color: WHITE}} className="material-icons-outlined">check</span>
            </AcceptRejectButton>
        </AcceptRejectDiv>
    )
}

AcceptButton.propTypes = {
    acceptRequest: PropTypes.func,
}

export default AcceptRejectButtons
