import {I18n} from 'react-i18nify'
import {isFalsy} from 'ramda-adjunct'
import {DEFAULT_LANGUAGE, TRANSLATIONS_MAP} from './language'

const latinize = require('latinize')

const DEMOGRAPHICS_DEFAULT_LABEL = 'name'

export const ORDERED_DEMOGRAPHICS_TYPE = [
    'country',
    'city',
    'department',
    'role',
    'years'
]

export const orderDemographicsAlphabetically = (choices) => {
    const currentLanguage = TRANSLATIONS_MAP[I18n._localeKey]
    const newChoices = choices ? [...choices] : []
    return newChoices.sort(function (a, b) {
        let sortedLabels
        if (!isFalsy(a[currentLanguage]) && !isFalsy(b[currentLanguage])) {
            sortedLabels = a[currentLanguage]
                .toLowerCase()
                .localeCompare(b[currentLanguage].toLowerCase())
        } else if (!isFalsy(a[DEFAULT_LANGUAGE]) && !isFalsy(b[DEFAULT_LANGUAGE])) {
            sortedLabels = a[DEFAULT_LANGUAGE].toLowerCase().localeCompare(
                b[DEFAULT_LANGUAGE].toLowerCase(),
            )
        } else {
            sortedLabels = a[DEMOGRAPHICS_DEFAULT_LABEL].toLowerCase().localeCompare(
                b[DEMOGRAPHICS_DEFAULT_LABEL].toLowerCase(),
            )
        }
        return sortedLabels
    })
}

export const getTranslatedDemographicsLabel = (demographic) => {
    const currentLanguage = TRANSLATIONS_MAP[I18n._localeKey]
    let translatedLabel
    if (!demographic) return null

    if (demographic[currentLanguage] !== null) {
        translatedLabel = demographic[currentLanguage]
    } else if (demographic[DEFAULT_LANGUAGE] !== null) {
        translatedLabel = demographic[DEFAULT_LANGUAGE]
    } else {
        translatedLabel = demographic[DEMOGRAPHICS_DEFAULT_LABEL]
    }
    return translatedLabel
}

export const demographicsSortingFunction = (choiceA, choiceB) => {
    const choiceNameA = latinize(getTranslatedDemographicsLabel(choiceA).toUpperCase())
    const choiceNameB = latinize(getTranslatedDemographicsLabel(choiceB).toUpperCase())
    if (choiceNameA < choiceNameB) {
        return -1
    }
    if (choiceNameA > choiceNameB) {
        return 1
    }

    return 0
}

export const sortDemographicsChoicesAlphabetically = (demographics) => {
    Object.keys(demographics)
        .filter((demographicType) => demographicType !== 'years')
        .forEach((demographicType) => {
            demographics[demographicType] &&
                demographics[demographicType].sort(demographicsSortingFunction)
        })
    return demographics
}