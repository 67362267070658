import {FilterCloseButtonContainer} from "../Filters.styles";
import React from "react";

const FilterCloseButton = ({clickHandler}) =>
    <FilterCloseButtonContainer onClick={clickHandler}>
        <div className={'col'}>
            <i className="material-icons pull-right" aria-hidden="true" role="button">clear</i>
        </div>
    </FilterCloseButtonContainer>;

export default FilterCloseButton;