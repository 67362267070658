import React from 'react';
import {NavbarBrand} from "../../BootstrapWrapper";
import {NavItemBox} from "../Navbar.styles";
import DropDownMenu, {ALIGN_DROP_DOWN, DropDownMenuOptionType} from "../../DropDownMenu";
import {PAGES} from "../../../const";
import {useCoreSlice} from "../../../hooks/useCoreSlice";
import {I18n} from "react-i18nify";
import {showPopupMessage} from "../../../utils/notifications";
import {PermissionAppWarning} from "../../../../dashboard/components/Header";

import ImageLeaderShip from "../../../../img/Apps/Leadership.png";
import ImageOrgScan from "../../../../img/Apps/Orgscan.png";
import ImageStrategyMap from "../../../../img/Apps/StrategyMap.png";
import { useUserSlice } from '../../../../strategyMap/hooks/useUserSlice';

const APPS = (Actions, isSubscriptionActive, features, user) => [
    {
        title: I18n.t("app.changeToApp"),
        key: "ChangeTo",
        type: DropDownMenuOptionType.Header
    },
    {
        title: "Home",
        key: "Home",
        user: user,
        type: DropDownMenuOptionType.AvatarAndText,
        onClick: () => Actions.gotoPage(PAGES.Dashboard),
        datatestid: "go-to-home",
        permissions: true
    },
    {
        title: "StrategyMap",
        key: "StrategyMap",
        iconImage: ImageStrategyMap,
        type: DropDownMenuOptionType.IconAndText,
        onClick: () => Actions.gotoPage(PAGES.StrategyMap),
        datatestid: "go-to-strategy-map",
        permissions: features.strategymap && isSubscriptionActive
    },
    {
        title: "OrgScan",
        key: "OrgScan",
        iconImage: ImageOrgScan,
        type: DropDownMenuOptionType.IconAndText,
        onClick: () => {
            localStorage.setItem('dashboardPage', PAGES.OrgScan.Dashboard)
            Actions.gotoPage(PAGES.OrgScan.Dashboard)
        },
        permissions: features.dashboard
    },
    {
        title: "Leadership Assessment",
        key: "Leadership Assessment",
        iconImage: ImageLeaderShip,
        type: DropDownMenuOptionType.IconAndText,
        onClick: () => {
            localStorage.setItem('dashboardPage', PAGES.LeaderShip.Dashboard)
            Actions.gotoPage(PAGES.LeaderShip.Dashboard)
        },
        permissions: features["leadership.full"] || features["leadership.team"]
    }
];

const AppSwitch = () => {

    const {isSubscriptionActive, features, Actions} = useCoreSlice();
    const user = useUserSlice();

    const apps = APPS(Actions, isSubscriptionActive, features, user);

    return <NavbarBrand>
        <NavItemBox
            data-testid={'dashboard-switch-navbar'}
        >
            <DropDownMenu
                datatestid={"app-switch"}
                centerInParent={true}
                alignDropDown={ALIGN_DROP_DOWN.LEFT}
                materialIconName={"apps"}
                options={ apps }
                onChange={ e => {
                    const app = apps.find( apps => apps.key === e );
                    if( app.permissions )
                        app.onClick();
                    else
                        showPopupMessage( PermissionAppWarning() );
                } }
            />
        </NavItemBox>
    </NavbarBrand>;
};

export default AppSwitch;