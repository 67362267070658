import {useSelector} from "react-redux";
import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";
import React, {useState} from "react";
import {getTranslatedDemographicsLabel} from "../../../core/utils/demographics";
import {I18n} from "react-i18nify";
import ExperimentItems from "../Items/ExperimentItems";
import {barSchema} from "../../../Charts/ChartColorSchema";
import DefaultItems from "../Items/DefaultItems";
import {
    ChoiceContentContainer,
    FilterContentContainer,
    SearchContainer,
    SelectContainer,
    SelectContainerItem
} from "../Filters.styles";
import PeriodItems from "../Items/PeriodItems";
import DemographicItems from "../Items/DemographicItems";
import {getComparableExperiments} from "../Filters";

const FilterContent = ({demographicType, anchorRight}) => {
    const demographics = useSelector((state) => state.demographics);
    const {filters, Actions} = useDashboardSlice();
    const [searchTerm, setSearchTerm] = useState('');
    const currentData = filters[demographicType] || [];

    const isPeriods = demographicType === 'periods';
    const isExperiments = demographicType === 'experiment';
    const isTypes = demographicType === 'type';
    const isOwner = demographicType === 'decision_owner';

    const showSearchAndMultipleSelect = ! isPeriods && ! isExperiments

    const getContents = () => {
        const contents = demographics[demographicType] || []
        if (searchTerm) {
            return contents.filter((choice) =>
                getTranslatedDemographicsLabel(choice)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
        }
        return contents;
    }

    const getExperrimentsItems = () => {
        const comparable_experiments = getComparableExperiments();
        if( comparable_experiments.length === 0 )
            return <div>{ I18n.t('dashboard.noComparableExp') }</div>
        return comparable_experiments.map( exp =>
            <ExperimentItems key={exp.id} item={exp} demographicType={demographicType} />)
    }

    const getTypes = () => Object.keys(barSchema.type).map( e =>
        <DefaultItems key={e} item={e} demographicType={demographicType} labelFunction={e => I18n.t("charts.Answers." + e )} /> );
    const getOwners = () => Object.keys(barSchema.decisionOwner).map( e =>
        <DefaultItems key={e} item={e} demographicType={demographicType} labelFunction={e => I18n.t("charts.Answers." + e )} /> );

    return (
        <FilterContentContainer anchorRight={anchorRight}>
            {showSearchAndMultipleSelect && (
                <SearchContainer>
                    <span className="material-icons-outlined">search</span>
                    <input
                        className="search-input"
                        data-testid={"search-input-filter"}
                        placeholder={I18n.t('dashboard.search')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </SearchContainer>
            )}
            {showSearchAndMultipleSelect && (
                <SelectContainer>
                    <SelectContainerItem
                        dimmed={getContents().length === 0}
                        onClick={() => {
                            if (getContents().length > 0) {
                                Actions.setFilters({
                                    filterType: demographicType,
                                    data: demographics[demographicType],
                                })
                            }
                        }}
                    >
                        {I18n.t('dashboard.selectAll')}
                    </SelectContainerItem>
                    <SelectContainerItem
                        dimmed={currentData.length === 0}
                        onClick={() =>
                            currentData.length > 0 &&
                            Actions.clearFilters({filterType: demographicType})
                        }
                    >
                        {I18n.t('dashboard.selectNone')}
                    </SelectContainerItem>
                </SelectContainer>
            )}

            <ChoiceContentContainer>
                { isPeriods && <PeriodItems demographicType={demographicType} /> }
                { isExperiments && getExperrimentsItems() }
                { isTypes && getTypes() }
                { isOwner && getOwners() }

                {showSearchAndMultipleSelect && (
                    getContents().map((item) => (
                        <DemographicItems key={item.pk} item={item} demographicType={demographicType} />
                    ))
                )}
            </ChoiceContentContainer>
        </FilterContentContainer>
    )
}

export default FilterContent;