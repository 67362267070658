import React from 'react'
import styled from 'styled-components'
import {getNodeState} from '../utils'
import {NODE_ACTION_KEYS, NODE_TYPES} from '../const'
import goalIcon from "../../img/icons/ic-node-goal.svg"
import csfIcon from "../../img/icons/ic-node-csf.svg"
import psfIcon from "../../img/icons/ic-node-psf.svg"
import expCreatedIcon from "../../img/icons/ic-node-exp-created.svg"
import expFailedIcon from "../../img/icons/ic-node-exp-failed.svg"
import expFinishedIcon from "../../img/icons/ic-node-exp-finished.svg"
import expStartedIcon from "../../img/icons/ic-node-exp-started.svg"
import expSuccessfulIcon from "../../img/icons/ic-node-exp-successful.svg"
import ncFulfilledIcon from "../../img/icons/ic-node-nc-fulfilled.svg"
import ncUnfulfilledIcon from "../../img/icons/ic-node-nc-unfulfilled.svg"


const icons = {
    'csf': csfIcon,
    'psf': psfIcon,
    'exp-created': expCreatedIcon,
    'goal': goalIcon,
    'exp-failed': expFailedIcon,
    'exp-finished': expFinishedIcon,
    'exp-started': expStartedIcon,
    'exp-successful': expSuccessfulIcon,
    'nc-fulfilled': ncFulfilledIcon,
    'nc-unfulfilled': ncUnfulfilledIcon,
}

const IMG = styled.img`
    width: 52px;
    height: 19px;
    margin-left: -16px;
`

const getIconSrc = (node) => {
    const nodeType = node.type || node.sf_type
    const nameImgNode = `${NODE_ACTION_KEYS[nodeType || NODE_TYPES.GOAL].icon_key}`
    const nodeState = getNodeState(node)
    const nodeIconKey = nodeState ? `${nameImgNode}-${nodeState}` : nameImgNode

    return icons[nodeIconKey]
}

const NodeIcon = (props) => {
    return (
        <IMG
            style={{opacity: props.alpha}}
            alt="Heading icon"
            src={getIconSrc(props.node)}
            {...props}
        />
    )
}

export default NodeIcon

const IMGHeader = styled.img`
    margin-left: -15px;
    height: 28px;
    position: absolute;
    top: 24px;
`

export const NodeIconHeader = (props) => {
    return <IMGHeader alt="Heading icon" src={getIconSrc(props.node)} />
}
