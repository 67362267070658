import settings from '../config/settings'
import axios from 'axios'
import {getRequestHeader, getRequestHeaderUserBlob, getSessionRequestHeaderWithoutToken} from '../core/utils/network'

export default class Api {
   
    static getUser() {
        return axios.get(`${settings.apiBaseURL}/users/me/`, getRequestHeader())
    }

    static fetchUsers(params) {
        return axios.get(`${settings.apiBaseURL}/users/`, {
            ...getRequestHeader(),
            params: {...params, all: true},
        })
    }

    static deactivateUsers(data) {
        return axios.post(`${settings.apiBaseURL}/users/deactivate/`, data, getRequestHeader())
    }

    static inviteUser(data) {
        return axios.post(`${settings.apiBaseURL}/users/invite/`, data, getRequestHeader())
    }

    static bulkCreateUsers(data) {
        return axios.post(`${settings.apiBaseURL}/users/bulk-create/`, data, getRequestHeader())
    }

    static saveProfilePic(data) {
        return axios.post(`${settings.apiBaseURL}/users/save-profile-pic/`, data, getRequestHeader())
    }

    static activateUsers(data) {
        return axios.post(`${settings.apiBaseURL}/users/activate/`, data, getRequestHeader())
    }

    static makeUserStrategist(data) {
        return axios.post(`${settings.apiBaseURL}/users/make-strategist/`, data, getRequestHeader())
    }

    static revokeUserStrategist(data) {
        return axios.post(`${settings.apiBaseURL}/users/revoke-strategist/`, data, getRequestHeader())
    }

    static makeUserAdmin(data) {
        return axios.post(`${settings.apiBaseURL}/users/make-admin/`, data, getRequestHeader())
    }

    static revokeUserAdmin(data) {
        return axios.post(`${settings.apiBaseURL}/users/revoke-admin/`, data, getRequestHeader())
    }

    static makeUserAdminLeadership(data) {
        return axios.post(`${settings.apiBaseURL}/users/make-admin-leadership/`, data, getRequestHeader())
    }

    static revokeUserAdminLeadership(data) {
        return axios.post(`${settings.apiBaseURL}/users/revoke-admin-leadership/`, data, getRequestHeader())
    }

    static makeUserAnalyst(data) {
        return axios.post(`${settings.apiBaseURL}/users/make-analyst/`, data, getRequestHeader())
    }

    static revokeUserAnalyst(data) {
        return axios.post(`${settings.apiBaseURL}/users/revoke-analyst/`, data, getRequestHeader())
    }

    static resendInvitation(data) {
        return axios.post(`${settings.apiBaseURL}/users/resend-invitation/`, data, getRequestHeader())
    }

    static fetchToken = ({email, password}) =>
        axios.post(
            `${settings.apiBaseURL}/auth/token/login/`,
            {
                email: email,
                password: password
            },
            getSessionRequestHeaderWithoutToken()
        );

    static fetchU2FUserBlob = token =>
        axios.get(`${settings.apiBaseURL}/users/u2f-token-blob/`,
            getRequestHeaderUserBlob(token));

    static checkU2FToken = (token, u2f) =>
        axios.post(`${settings.apiBaseURL}/users/u2f-token-validate/`, {token: u2f},
            getRequestHeaderUserBlob(token));

    static storeU2FCredentials = ({token, userBlob}) =>
        axios.post(`${settings.apiBaseURL}/users/u2f-token-store/`,{token: token, userBlob: userBlob},
            getRequestHeader());

    static removeU2FCredentials = () =>
        axios.post(`${settings.apiBaseURL}/users/u2f-token-remove/`,{},
            getRequestHeader());

    static removeToken = () =>
        axios.post(`${settings.apiBaseURL}/auth/token/logout`, null, getRequestHeader());

    static resetPassword = (email) =>
        axios.post(
            `${settings.apiBaseURL}/auth/users/reset_password/`,
            email,
            getSessionRequestHeaderWithoutToken()
        );

    static resetPasswordConfirm = (data) =>
        axios.post(
            `${settings.apiBaseURL}/auth/users/reset_password_confirm/`,
            data,
            getSessionRequestHeaderWithoutToken()
        );

    static getSecurityPolicy = () =>
        axios.get( `${settings.apiBaseURL}/security-policy/`, getRequestHeader() );
}
