import React from 'react'
import styled from 'styled-components'
import DroppableBoard from './DroppableBoard'
import { TIME_AREAS } from '../const'
import {Z_INDICES} from '../../core/const'

const Container = styled.div`
    position: fixed;
    display: flex;
    width: calc(100vw - ${(props) => props.sidebarWidth}px);
    height: 100vh;
    z-index: ${Z_INDICES.Node};
`

const BoardSections = ({sidebarWidth}) => {
    const styleBase = {
        height: '100vh',
        width: 'calc(33.3333%)',
        backgroundImage: 'linear-gradient(rgb(125, 125, 125) 50%, rgba(255,255,255,0) 10%)',
        backgroundPosition: 'right',
        backgroundSize: '1px 12px',
        backgroundRepeat: 'repeat-y',
    }

    return (
        <Container sidebarWidth={sidebarWidth}>
            <DroppableBoard
                x={0}
                timeArea={TIME_AREAS.CONFIRMED}
                style={styleBase}
            />
            <DroppableBoard 
                x={1} 
                timeArea={TIME_AREAS.VALIDATING} 
                style={{height: '100vh'}} 
            />
            <DroppableBoard
                x={2}
                timeArea={TIME_AREAS.POTENTIAL}
                style={{...styleBase, backgroundPosition: 'left'}}
            />
        </Container>
    )
}

export default BoardSections
