import React, {useEffect, useState} from 'react'
import ReactSlider from 'react-slider'
import styled from 'styled-components'
import {createErrorClass} from '../../core/utils/html'
import {I18n} from 'react-i18nify'
import {SpaceBetweenContainer} from './Shared.styles'
import ErrorOutput from './ErrorOutput'
import {Panel} from "../../core/components/BootstrapWrapper";
import {pointIcons} from "./triangle/TriangleComponent";
import SliderBackground from "./../../img/Capture/slider_line.png";
import { WHITE } from '../../core/ui/_colors'


const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

const StyledThumb = styled.div`
    top: -30px;
    height: 50px;
    line-height: 25px;
    width: 50px;
    text-align: center;
    background-size: cover;
    background-color: transparent;
    color: ${WHITE};
    cursor: grab;
    color: transparent;
    border: none !important;
    outline: 0;
    margin-left: -3px
    *:focus {
        outline: 0;
        outline: none;
    }
`;

const StyledTrack = styled.div`
    top: 0;
    padding-top: 20px;
    bottom: 0;
    position: relative;
    img {
        width: 100%;
    }
`;

const Thumb = (props, state) => <StyledThumb {...props}><img src={pointIcons.blue} /></StyledThumb>;

const Slider = (props) => {
    const [value, setValue] = useState('')
    const [validationErrors, setValidationErrors] = useState(null)

    const validateInput = () => {
        if (props.required && value.length === 0) {
            setValidationErrors({required: I18n.t("capture.Select.This section is required")})
            props.onError && props.onError(props.fieldName)
        } else {
            setValidationErrors(null)
        }
    }

    useEffect(() => {
        if(props.shouldValidate)
            validateInput()
    }, [props.shouldValidate])

    return (
        <Panel default className={createErrorClass(props.fieldName, props.errors || validationErrors, !props.errors)} header={props.header}>
            <SpaceBetweenContainer>
                <p>{props.leftLabel}</p>
                <p>{props.rightLabel}</p>
            </SpaceBetweenContainer>
            <StyledTrack><img src={SliderBackground} /></StyledTrack>
            <StyledSlider
                defaultValue={[50]}
                renderThumb={Thumb}
                onAfterChange={(v) => {
                    setValue(v)
                    props.onChange && props.onChange(v)
                    setValidationErrors(null)
                }}
            />
            <ErrorOutput errors={props.errors || validationErrors}/>
        </Panel>
    )
}

export default Slider
