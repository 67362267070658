import React from 'react'
import {getDescriptionPlaceholder, getPendingNode, getValidChildTypesForParent} from '../utils'
import DropdownMenu, {MENU_ITEM_LINK} from '../../sidebars/Components/DropdownMenu'
import NodeIcon from './NodeIcon'
import {NODE_TYPES} from '../const'
import {I18n} from 'react-i18nify'
import {showPopupMessage} from '../../core/utils/notifications'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {useUserSlice} from '../hooks/useUserSlice'

const AddNodeFromNodeCard = ({node}) => {
    const {Actions, nodeMap} = useStrategyMapSlice();
    const user = useUserSlice();
    const validChildTypesForParent = getValidChildTypesForParent(node, nodeMap, user);
    const activeMenuButtons = validChildTypesForParent.map( nodeItem => ({
            action: 'add' + nodeItem.type + 'Node',
            active: true,
            testId: nodeItem.type + "-button",
            title: (
                <>
                    <NodeIcon node={{type: NODE_TYPES[nodeItem.type]}} /> {nodeItem.type}
                </>
            ),
            type: MENU_ITEM_LINK,
            actionHandler: () => {
                if (nodeItem.isDisabled) {
                    const warning = {
                        message: nodeItem.message,
                        confirmLabel: I18n.t('strategyMap.messages.ok')
                    }
                    showPopupMessage(warning)
                } else {
                    Actions.showNodeCreationSidebar(
                        getPendingNode(
                            NODE_TYPES[nodeItem.type],
                            getDescriptionPlaceholder(NODE_TYPES[nodeItem.type]),
                            node.id
                        )
                    )
                }
            }
        })
    );

    return <DropdownMenu testId={node.type + node.id} activeMenuButtons={activeMenuButtons} icon={'add'} top />
}

export default AddNodeFromNodeCard
