import {createQueryParams, getRequestHeader} from '../core/utils/network';
import axios from 'axios';
import settings from "../config/settings.json";

export default class Api {
    static fetchTriangleTypes = () => axios.get(`${settings.apiBaseURL}/triangle-types/`, getRequestHeader())
    static getCaptureQuestions = key => {
        const params = (key.parentID) ? `?parent=${key.parentID}` : ``;
        return axios.get(`${settings.apiBaseURL}/capture-questions/${key.questionID}/` + params);
    }
    static postDecisions = decision => axios.post(`${settings.apiBaseURL}/decisions/`, decision, getRequestHeader())
    static saveCapture = data => axios.post(`${settings.apiBaseURL}/captures/`, data, getRequestHeader());
    static getCapture = id => axios.get(`${settings.apiBaseURL}/captures/${id}/`, getRequestHeader())
    static getCaptures = payload => {
        const params = createQueryParams( payload );
        return axios.get(`${settings.apiBaseURL}/captures/${params}`, getRequestHeader())
    }
    static getCaptureStats = payload => {
        const params = createQueryParams( {parent_capture: '', ...payload} );
        return axios.get(`${settings.apiBaseURL}/captures/stats/${params}`, getRequestHeader());
    }
    static updateUserProfileDemographics = demographics =>
        axios.post(`${settings.apiBaseURL}/user-demographics/`, demographics, getRequestHeader());

    static importDecisionsFromMiro = data =>
        axios.post(`${settings.apiBaseURL}/decisions/import-from-miro/`, data, getRequestHeader());
}
