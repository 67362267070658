import {createSlice} from 'redux-starter-kit'
import * as ErrorUtils from '../../core/utils/error'

const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isSavingDecisions: false,
    errors: ErrorUtils.getApiErrors(action.payload),
})
const fetchingHandler = (state) => ({
    ...state,
    isFetching: true,
    errors: undefined,
})
const savingDecisionsHandler = (state) => ({
    ...state,
    isSavingDecisions: true,
    isSavingDecisionsSuccess: false,
    errors: undefined,
})
const savingUserProfileHandler = (state) => ({
    ...state,
    isSavingUserProfile: true,
    isSavingUserProfileSuccess: false,
    errors: undefined,
})

export const captureSlice = createSlice({
    slice: `capture`,
    initialState: {
        isSavingDecisions: false,
        isSavingDecisionsSuccess: false,
        isSavingUserProfile: false,
        isSavingUserProfileSuccess: false,
        isFetching: false,
        triangleTypes: [],
        errors: undefined,
        captureQuestion: null,
        leaderShipAssessmentSurvey: [],
        captureStats: {}
    },
    reducers: {
        fetchTriangleTypes: fetchingHandler,
        fetchTriangleTypesSuccess: (state, action) => ({
            ...state,
            isFetching: false,
            triangleTypes: [...action.payload.data],
        }),
        getCaptureQuestions: (state) => ({...state, isFetching: true}),
        getCaptureQuestionsSuccess: (state, action) => ({
            ...state,
            isFetching: false,
            captureQuestion: action.payload,
        }),

        postDecisions: savingDecisionsHandler,
        postDecisionsSuccess: (state) => ({
            ...state,
            isSavingDecisions: false,
            isSavingDecisionsSuccess: true,
        }),
        saveCapture: savingDecisionsHandler,
        saveCaptureSuccess: (state) => ({
            ...state,
            isSavingDecisions: false,
        }),
        getCapture: (state) => ({...state, isFetching: true}),
        getCaptureSuccess: (state, action) => ({
            ...state,
            isFetching: false,
            ...action.payload,
        }),
        getCaptureStats: (state) => ({...state}),
        getCaptureStatsSuccess: (state, action) => ({
            ...state,
            captureStats: action.payload,
        }),
        getCaptures: (state) => ({...state, isFetching: true}),
        getCapturesSuccess: (state, action) => ({
            ...state,
            isFetching: false,
            leaderShipAssessmentSurvey: action.payload,
        }),
        resetIsSavingDecisionsSuccess: (state) => {
            state.isSavingDecisionsSuccess = false
        },
        updateUserProfileDemographics: savingUserProfileHandler,
        updateUserProfileDemographicsSuccess: (state) => ({
            ...state,
            isSavingUserProfile: false,
            isSavingUserProfileSuccess: true,
        }),
        importDecisionsFromMiro: (state) => ({...state, isFetching: true}),
        requestFailure: errorHandler,
    },
})
