import React from 'react'
import {I18n} from 'react-i18nify'
import HeadingWithAction from '../../sidebars/Components/HeadingWithAction'
import ItemUser from './ItemUser'
import {useUserSlice} from '../hooks/useUserSlice'

const SquadRequest = ({node}) => {
    const currentActiveUser = useUserSlice()

    const squadRequest =
        node.pending_squad.find((squadMember) => squadMember.user.pk === currentActiveUser.pk) || {}

    if (!squadRequest.id) return null

    return (
        <div>
            <HeadingWithAction title={I18n.t('strategyMap.nodes.improvementSquadRequest')} />
            <div>
                <ItemUser squadMember={squadRequest} isMyOwnRequest={true} />
            </div>
        </div>
    )
}

export default SquadRequest
