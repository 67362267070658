import React from 'react'
import {I18n} from 'react-i18nify'
import {Pie, PieChart} from 'recharts'
import {trafficLightColor} from '../ChartColorSchema'
import { Div } from '../../core/ui/Div'
import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
export const Value = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: ${p => p.zoom ? '100px' : p.noData  ? '25px' : '50px'};
    display: flex;
    justify-content: center;
    align-items: center;
`

const PieChartsProgress = (props) => {
    const maxValue = props.maxValue
    let value = props.data.x
    if (value > maxValue) {
        value = maxValue
    } else if (value < 0) {
        value = 0
    }

    const data = [
        {name: 'Group A', value: value},
    ]

    const getPrimaryColor = (value) => {
        const keys = Object.keys(props.colorScheme).filter((limit) => value <= limit)
        return props.colorScheme[keys[0]]
    }

    const chartSize = 300

    return (
        <Container data-testid={props.testId}>
            <Value noData={props.noData} zoom={props.zoom}>
                {`${props.data.x}${props.data.unit}`}
            </Value>
            <PieChart width={chartSize} height={chartSize}>
                <Pie 
                    data={data} 
                    dataKey="value" 
                    nameKey="name" 
                    cx={145}
                    cy={150}
                    startAngle={230} 
                    endAngle={-50} 
                    innerRadius={110} 
                    outerRadius={140} 
                    fill={trafficLightColor.yellow} 
                />
                <Pie 
                    data={data} 
                    dataKey="value" 
                    nameKey="name" 
                    cx={145}
                    cy={150}
                    startAngle={230} 
                    endAngle={270 - (value * 360 / maxValue) } 
                    innerRadius={100} 
                    outerRadius={150} 
                    fill={getPrimaryColor(value)} 
                />
            </PieChart>
            {!props.noData && <Div width="100%">
                <Div float="left">{I18n.t('charts.Limits.low')}</Div>
                <Div float="right">{I18n.t('charts.Limits.high')}</Div>
            </Div>}
        </Container>
    )
}

export default PieChartsProgress
