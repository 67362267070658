import {
    InspectChartStyle,
    InspectGraphChartWrapper,
    InspectGraphSelectBoxWrapper,
    HelperText
} from './Reports.styles'

import React, {useState} from 'react'
import Charts from '../../Charts/Charts'
import {ChartType} from '../../Charts/ChartsConfig'
import {DASHBOARD_CHARTS} from '../../Charts/ChartsOrgScan'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {I18n} from 'react-i18nify'
import {selectBoxStyling} from '../Analysis'
import { Div } from '../../core/ui/Div'
import SelectWrapper from '../../core/components/SelectWrapper'
import {decisionsFiltered} from "../../dashboard/components/DecisionFilter/Dashboard.functions";

export const InspectGraph = () => {
    const charts = DASHBOARD_CHARTS.filter((charts) => charts.Type === ChartType.Statistics)

    const {decisions, filters, Actions} = useDashboardSlice()
    const [fetching, setFetching] = useState(false)
    const [currentDiagram, setCurrentDiagram] = useState({
        value: charts[0].title,
        label: I18n.t(charts[0].title),
    })

    const dataPoints = {
        dataShown: decisionsFiltered(decisions, filters),
        dataCompare: [],
    }

    if (decisions.length === 0 && fetching === false) {
        setFetching(true)
        Actions.fetchDecisions()
    }

    const chart_options = charts.map((chart) => ({
        label: I18n.t(chart.title),
        value: chart.title,
    }))
    const showSelectBox = () => (
        <InspectGraphSelectBoxWrapper data-testid={'statistic-select'} id={'statistic-select'}>
            <SelectWrapper
                data-testid={'statistic-select-2'}
                styles={selectBoxStyling}
                onChange={(e) => {
                    setCurrentDiagram(e)
                }}
                defaultValue={currentDiagram}
                options={chart_options}
            />
        </InspectGraphSelectBoxWrapper>
    )

    const showChart = (currentDiagram) => {
        const inspectChart = charts.filter((chart) => chart.title === currentDiagram.value)

        return inspectChart.map((chart, key) => (
            <InspectChartStyle key={key}>
                <InspectGraphChartWrapper>
                    <Charts
                        {...chart}
                        testid={chart.testId + '_inspecting'}
                        dataObject={dataPoints}
                        isCompared={false}
                        language={I18n._localeKey}
                        inspectMode={true}
                        coordinate={{height: 'auto', width: 1100}}
                    />
                </InspectGraphChartWrapper>
                <HelperText>{I18n.t("charts.Topic.Statistics.helpText."+chart.subType)}</HelperText>
            </InspectChartStyle>
        ))
    }

    return (
        <Div margin={'20px'}>
            {showSelectBox()}
            {showChart(currentDiagram)}
        </Div>
    )
}
