import React from 'react'
import {FormControl, FormGroup, FormLabel} from 'react-bootstrap'
import {ErrorBorderStyles, ErrorMessageStyles} from './Error.styles'

const TextInput = (props) => {
    return (
        <FormGroup controlId="controlName">
            <FormLabel>{props.label}</FormLabel>
            {props.errors && <ErrorMessageStyles>{props.errorMessage}</ErrorMessageStyles>}
            <ErrorBorderStyles errors={props.errors}>
                <FormControl
                    required
                    data-testid={props.testId}
                    type={'text'}
                    placeholder={props.placeholder || props.label}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={props.disabled || false}
                    maxlength={props.maxLength || 255}
                />
            </ErrorBorderStyles>
        </FormGroup>
    )
}

export default TextInput
