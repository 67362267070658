import {MAGENTA} from '../_colors'
import styled from 'styled-components'

export const ErrorBorderStyles = styled.div`
    border-color: ${MAGENTA};
    border-style: ${(p) => (p.errors ? 'solid' : 'none')};
    border-radius: 4px;
`

export const ErrorMessageStyles = styled.div`
    color: grey;
    font-size: 15px;
`
