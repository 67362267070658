import {createSlice} from 'redux-starter-kit'
import * as ErrorUtils from '../utils/error'
import {sortDemographicsChoicesAlphabetically} from '../utils/demographics'
import {DEFAULT_LANGUAGE, LANGUAGE_CODE, LANGUAGE_TO_LABELS, TRANSLATIONS_MAP} from '../utils/language'
import history from "../utils/history";
import {PAGES} from "../const";
import {joinTables} from "../utils/array"
import {I18n} from "react-i18nify";
import {DashboardLocalStorage, DashboardWidgets} from "../../dashboard/components/CustomizeChartsDashboardConst";
import _ from 'lodash';

export const SIDEBARS = {
    UserProfile: "UserProfile",
    Dashboard: "Dashboard",
    Notification: "Notification",
    Node: "Node",
    Admin: "Admin",
    None: false
}

export const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isSaving: false,
    errors: ErrorUtils.getApiErrors(action.payload),
    errorCode: ErrorUtils.getApiErrorCode(action.payload)
})

export const fetchingHandler = (state) => ({...state, isFetching: true})

export const iconVisible = createSlice({
    initialState: {
        notifications: true,
        userProfile: true,
        help: false,
    },
    reducers: {
        setNotificationsIconVisible: (state, action) => ({
            ...state,
            notifications: action.payload,
        }),
        setUserProfileIconVisible: (state, action) => ({
            ...state,
            userProfile: action.payload,
        }),
        setHelpIconVisible: (state, action) => ({...state, help: action.payload}),
    },
})

export const languageSlice = createSlice({
    initialState: {
        current: DEFAULT_LANGUAGE,
        available: [DEFAULT_LANGUAGE],
        loaded: false
    },
    reducers: {
        requestFailure: errorHandler,
        setLanguage: (state, action) => {
            const languageCode = action.payload.length === 2 ? action.payload : LANGUAGE_CODE[action.payload];
            if( languageCode && languageCode !== state.current ) {
                localStorage.setItem('language', languageCode);
                I18n.setLocale(languageCode);
                state.current = TRANSLATIONS_MAP[languageCode];
            }
        },
        fetchLanguages: fetchingHandler,
        fetchLanguagesSuccess: (state, action) => {
            const {languages} = action.payload;
            const availableLanguages = Object.values(languages)
                .filter((language) => language.isAvailable)
                .map((language) => {
                    return language.name
                });
            state.available = availableLanguages;
            state.loaded = true;
        },
        fetchLanguagesStaticSuccess: (state) => {
            state.isFetching = false;
            state.available = Object.keys(LANGUAGE_TO_LABELS);
            state.loaded = true;
        },
        updateLanguage: () => {},
    },
})

export const demographicsSlice = createSlice({
    initialState: {
        loading: true,
        city: null,
        country: null,
        department: null,
        role: null,
        years: null,
    },
    reducers: {
        updateDemographicsTenant: () => {},
        updateDemographicsTenantSuccess: (state, action) => {},
        updateDemographicsTenantFailure: (state, action) => {},
        fetchDemographics: () => {},
        fetchDemographicsSuccess: (state, action) => {
            const {city, country, department, role, years} = action.payload
            state.loading = false
            state.city = city
            state.country = country
            state.department = department
            state.role = role
            state.years = years
        },
        sortDemographicsAlphabetically: (state) => {
            const {city, country, department, role, years} = state
            const newState = sortDemographicsChoicesAlphabetically({
                city,
                country,
                department,
                role,
                years,
            })
            state.loading = false
            state.city = newState.city
            state.country = newState.country
            state.department = newState.department
            state.role = newState.role
            state.years = newState.years
        },
    },
})

export const coreSlice = createSlice({
    slice: 'core',
    initialState: {
        git: null,
        pageReloadPromptVisible: false,
        teams: [],
        error: {},
        isFetching: false,
        isSaving: false,
        client: {},
        newClient: {},
        subPageHeading: '',
        sidebarOpen: false,
        helpTour: false,
        subscriptions: [],
        userEngagementLastEmail: null,
        miroParam: undefined,

        dashboardWidgetsVisible: [],
        dashboardWidgetsOrder: ["StrategyMap", "LeadershipAssessment", "OrgScan"],
    },
    reducers: {
        dashboardWidgetDraggingEnds: (state, action) => {

            const key = localStorage.getItem(DashboardLocalStorage.key);
            const section = localStorage.getItem(DashboardLocalStorage.section);
            const position = {
                start: parseInt(localStorage.getItem(DashboardLocalStorage.position.start)),
                ends: parseInt(localStorage.getItem(DashboardLocalStorage.position.ends))
            };
            const sectionWidgets = section ? DashboardWidgets[section] : [];
            
            const [
                dashboardWidgetsVisibleForSection, 
                dashboardWidgetsVisibleForOtherSection
            ] = _.partition(state.dashboardWidgetsVisible, (dashboardWidget, key) => sectionWidgets.map(sw => sw.name).includes(dashboardWidget.name));
            
            if( !! key && !! Number.isInteger( position.start )  && !! Number.isInteger( position.ends ) ){
                if( position.ends === -1 ){
                    state.dashboardWidgetsVisible = dashboardWidgetsVisibleForSection.filter( (a,i) => i !== position.start);
                } else {

                    let items = [...dashboardWidgetsVisibleForSection];

                    if( position.start === -1 ){
                        const item = Object.keys(DashboardWidgets).map( a => DashboardWidgets[a] ).flat().filter( a => a.name === key)[0];
                        if( item.name === "charts.Experiments") {
                            item.filter.goal = undefined;
                            state.dashboardWidgetsVisible = dashboardWidgetsVisibleForOtherSection.concat([...dashboardWidgetsVisibleForSection, item ]);
                        } else
                            state.dashboardWidgetsVisible = dashboardWidgetsVisibleForOtherSection.concat([
                                items.filter( (a,i) => i < position.ends),
                                [item],
                                items.filter( (a,i) => i >= position.ends)].flat());
                    } else {
                        const item = dashboardWidgetsVisibleForSection[position.start];
                        items = items.filter((a, i) => i !== position.start);
                        const offset = -1;
                        const split = [
                            items.filter((a, i) => i <= position.ends + offset),
                            [item],
                            items.filter((a, i) => i > position.ends + offset)];

                        state.dashboardWidgetsVisible = dashboardWidgetsVisibleForOtherSection.concat(split.flat());
                    }
                }
            }
            localStorage.setItem(DashboardLocalStorage.key, null);
            localStorage.setItem(DashboardLocalStorage.section, null);
            localStorage.setItem(DashboardLocalStorage.position.start, null);
            localStorage.setItem(DashboardLocalStorage.position.ends, null);
        },
        updateDashboardWidgets: (state, action) => {
            state.dashboardWidgetsVisible = action.payload ? action.payload : [];
        },
        updateDashboard: () => {},

        updateDashboardOrder: (state, action) => {
            state.dashboardWidgetsOrder = action.payload && action.payload.length && action.payload || ["StrategyMap", "OrgScan", "LeadershipAssessment"];
        },
        upgradeLeadership: () => {},
        downgradeLeadership: () => {},
        fetchSubScriptions: () => {},
        fetchSubScriptionsSuccess: (state, action) => { state.subscriptions = action.payload},
        fetchTeams: fetchingHandler,
        showStrategyMapNode: (state,action) => {},
        fetchTeamsSuccess: (state, action) => {
            state.isFetching = false
            state.teams = action.payload
        },        
        createTeam: fetchingHandler,
        createTeamSuccess: (state, action) => {
            state.isFetching = false
            state.teams  = [...state.teams, action.payload]
        },
        updateTeam: (state, action) => {
        },
        updateTeamSuccess: (state, action) => {
            state.teams = joinTables("id", state.teams, [action.payload])
        },
        deleteTeam: (state, action) => {
        },
        deleteTeamSuccess: (state, action) => {
            state.teams = state.teams.filter(si => si.id !== action.payload)
        },
        fetchGit: (state, action) => {},
        fetchGitSuccess: (state, action) => {
            state.isFetching = false
            const {git_commit_version, git_commit_date, git_build_date} = action.payload
            state.git = {
                latestCommitVersion: git_commit_version,
                latestCommitDate: git_commit_date,
                latestBuildDate: git_build_date
            }
        },
        runCommand: fetchingHandler,
        runCommandSuccess: (state, action) => {
            state.isFetching = false
        },
        sendUserEngagementEmail: fetchingHandler,
        sendUserEngagementEmailSuccess: (state, action) => {
            state.isFetching = false
        },
        getUserEngagementLastEmail: fetchingHandler,
        getUserEngagementLastEmailSuccess: (state, action) => {
            state.isFetching = false
            const {message_body, date} = action.payload
            state.userEngagementLastEmail = {
                messageBody: message_body,
                date: date,
            }
        },
        showPageReloadPrompt: (state) => {
            state.pageReloadPromptVisible = true
        },
        hidePageReloadPrompt: (state) => {
            state.pageReloadPromptVisible = false
        },

        startHelpTour: (state, tour) => {
            state.helpTour = tour.payload
        },
        toggleSidebarVisibility: (state, sidebar) => {
            state.sidebarOpen = sidebar.payload === state.sidebarOpen ? SIDEBARS.None : sidebar.payload
        },
        toggleSidebarVisibilityForce: (state, sidebar) => {
            state.sidebarOpen = sidebar.payload
        },
        closeSidebar: (state) => {
            state.sidebarOpen = SIDEBARS.None
        },
        getClient: (state) => {
            return {...state, isFetching: true}
        },
        getClientSuccess: (state, action) => {
            state.client = action.payload
            state.isFetching = false
        },
        createClient: (state) => {
            state.isSaving = true
        },
        createClientSuccess: (state, action) => {
            state.newClient = action.payload
            state.isSaving = false
        },
        updateClient: (state) => {
            state.isSaving = true
        },
        updateClientSuccess: (state, action) => {
            state.updatedClient = action.payload
            state.isSaving = false
        },
        gotoPage: (state, action) => {
            const url =
                [PAGES.Dashboard, undefined].includes(action.payload)
                    ? ''
                    : action.payload;

            history.push(`/${url}`);
            return {...state, sidebarOpen: SIDEBARS.None}
        },
        setPageHeader: (state, action) => {
            return {...state, subPageHeading: action.payload}
        },
        setMiroParam: (state, action) => {
            return {...state, miroParam: {...state.miroParam, ...action.payload}};
        },
        setClient: (state, action) => {
            state.client = action.payload
        },
        resetErrors: (state) => {
            state.errors = null
        },
        requestFailure: errorHandler,
    },
})
