import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";
import {choiceHasDecision} from "../../../dashboard/components/DecisionFilter/Dashboard.functions";
import {DURATION_OPTIONS} from "../../../dashboard/components/Dashboard";
import {ChoiceBox, ChoiceRow} from "../Filters.styles";
import React from "react";

const DefaultItems = ({ demographicType, item, labelFunction }) => {
    const {filters, Actions, decisions} = useDashboardSlice();
    const currentData = filters[demographicType] || [];
    const checked = currentData.some( i => i === item );

    const disabled = ! choiceHasDecision(
        [demographicType],
        item,
        decisions,
        filters.duration || DURATION_OPTIONS.ALL
    );

    const handleItemSelect = () => {
        const dataFilter = checked
            ? currentData.filter( i => item !== i )
            : [...currentData, item];

        Actions.setFilters({filterType: demographicType, data: dataFilter})
    }

    return (
        <ChoiceRow disabled={disabled} data-testid={"checkbox-container"} onClick={ () => !disabled && handleItemSelect() }>
            <ChoiceBox role="button" disabled={disabled}>
                {checked && <i className="material-icons" data-testid={"checkbox-checked"}>check</i>}
            </ChoiceBox>
            <span>{ labelFunction ? labelFunction(item) : item }</span>
        </ChoiceRow>
    )
}

export default DefaultItems;