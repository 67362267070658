import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";
import {useSelector} from "react-redux";
import {
    Badge,
    FilterButtonContainer,
    FilterButtonContainerWrapper,
    FilterButtonIcon,
    FilterButtonLabel
} from "../Filters.styles";
import ClickOutside from "@tntd/react-click-outside";
import React from "react";
import FilterContent from "./FilterContent";

const FilterButton = ({
    label,
    margin,
    demographicType,
    anchorRight,
    clickHandler,
    dropSide,
    showBadge,
}) => {
    const {filters, Actions} = useDashboardSlice();
    const filterToggle = useSelector(state => state.dashboard.filterToggle );
    const isPeriods = demographicType === 'periods';

    const currentData = filters[demographicType] || []
    const showFilterContent = () => Actions.setFilterToggle({[demographicType]: true})
    const hideFilterContent = () => Actions.setFilterToggle({[demographicType]: false})

    return (
        <FilterButtonContainerWrapper>
            <FilterButtonContainer
                margin={margin}
                onClick={() => (clickHandler ? clickHandler() : showFilterContent(demographicType))}
            >
                <FilterButtonLabel>{label}</FilterButtonLabel>
                { ! isPeriods && showBadge && currentData.length > 0 && (
                    <Badge>{currentData.length}</Badge>
                )}
                { isPeriods && (currentData.from || currentData.to) && (
                    <Badge>{currentData.from && currentData.to ? 2 : 1}</Badge>
                )}
                <FilterButtonIcon dropSide={dropSide}>
                    <span className='material-icons-outlined'>expand_more</span>
                </FilterButtonIcon>
            </FilterButtonContainer>

            {filterToggle[demographicType] && (
                <ClickOutside
                    onClickOutside={ () => hideFilterContent(demographicType) }
                >
                    <FilterContent demographicType={demographicType} anchorRight={anchorRight} />
                </ClickOutside>
            )}
        </FilterButtonContainerWrapper>
    )
}

export default FilterButton;