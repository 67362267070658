import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import DashboardPage from './dashboard/components/DashboardPage';
import Raven from 'raven-js';
import AnalysisPage from './analysis/AnalysisPage';
import BillingPage from './billings/BillingPage';
import StrategyMapPage from './strategyMap/components/StrategyMapPage';
import Login from './Login/Login';
import CapturePage from './capture/CapturePage';
import LeadershipAssessmentPage from './capture/LeadershipAssessmentPage';
import SetupWrapper from "./setup/SetupWrapper";
import { NotFound } from './FallbackComponent';
import DashboardPageLeadership from "./dashboard/components/DashboardPageLeaderShip";
import DashboardOrgScan from "./dashboard/components/DashboardOrgScan";

const AuthenticatedRoute = (props) => {
    const AuthenticatedComponent = props.component;

    return (
        <Route
            render={() => {
                return localStorage.getItem('token') ? (
                    <AuthenticatedComponent {...props} />
                ) : (
                    <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                )
            }}
        />
    )
}

export const routes = (
    <Switch>
        <AuthenticatedRoute exact path="/" component={DashboardPage} raven={Raven} />
        <AuthenticatedRoute exact path="/orgscan" component={DashboardOrgScan} raven={Raven} />
        <AuthenticatedRoute exact path="/orgscan/analysis" component={AnalysisPage} raven={Raven} />
        <AuthenticatedRoute exact path="/leadership-assessment" component={DashboardPageLeadership} raven={Raven} />
        <AuthenticatedRoute exact path="/billings" component={BillingPage} raven={Raven} />
        <AuthenticatedRoute
            exact
            path="/strategy-map/:goalId?/:nodeId?"
            component={StrategyMapPage}
            raven={Raven}
        />
        <Route exact path="/orgscan/capture/:id/:parentId" component={CapturePage} raven={Raven} />
        <Route exact path="/orgscan/capture/:id" component={CapturePage} raven={Raven} />
        <AuthenticatedRoute exact path="/orgscan/capture" component={CapturePage} raven={Raven} />

        <AuthenticatedRoute exact path="/leadership-assessment/analysis" component={AnalysisPage} raven={Raven} />
        <Route exact path="/leadership-assessment/capture/:id" component={CapturePage} raven={Raven} />
        <Route exact path="/leadership-assessment/capture/:id/:parentId" component={CapturePage} raven={Raven} />
        <Route exact path="/selfassessment/" component={CapturePage} raven={Raven} />
        <Route exact path="/leadership-assessment/:id" component={LeadershipAssessmentPage} raven={Raven} />
        <Route
            exact
            path="/password/reset/confirm/:uid/:token/"
            render={(props) => (
                <Login
                    showLogin={false}
                    raven={Raven}
                    showPasswordForgot={false}
                    showPasswordReset={true}
                    {...props}
                />
            )}
        />
        <Route
            exact
            path="/password/create/:uid/:token/"
            render={(props) => (
                <Login
                    showLogin={false}
                    raven={Raven}
                    showPasswordForgot={false}
                    showPasswordReset={true}
                    firstAccess={true}
                    {...props}
                />
            )}
        />
        <Route exact path="/login/" component={Login} raven={Raven} />
        <Route>
            <NotFound />
        </Route>
    </Switch>
)

export const public_routes = (
    <Switch>
        <Route exact path="/" component={SetupWrapper} raven={Raven} />
        <Route exact path="/login/" component={Login} raven={Raven} />
        <Route exact path="/leadership-assessment/:id" component={LeadershipAssessmentPage} raven={Raven} />
        <Route>
            <NotFound />
        </Route>
    </Switch>
)

export const AuthenticatedRouter = props =>
    <Router history={props.history}>{props.children}</Router>
