import React from 'react'
import styled from 'styled-components'
import {I18n} from 'react-i18nify'
import SelectWrapper from '../core/components/SelectWrapper'
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {RESTRICTIONS} from "../core/const";
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
`

const SelectedUsers = styled.div`
    opacity: 0.6;
`

export const ActionInput = ({onActionClick, selectedUsersNumber, totalUsersNumber}) => {
    const {features} = useCoreSlice();
    const user = useUserSlice();

    const actions = [
        {
            value: 'deactivate',
            label: I18n.t('billing.Label.Disable'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_USERS
        }, {
            value: 'reActivate',
            label: I18n.t('billing.Label.Enable'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_USERS
        }, {
            value: 'resendInvitation',
            label: I18n.t('billing.Label.ResendInvitation'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_USERS
        }, {
            value: 'makeStrategist',
            label: I18n.t('billing.Label.MakeStrategist'),
            visible: features["strategymap"],
            users: RESTRICTIONS.Settings.CAN_MANAGE_USERS
        }, {
            value: 'revokeStrategist',
            label: I18n.t('billing.Label.RevokeStrategist'),
            visible: features["strategymap"],
            users: RESTRICTIONS.Settings.CAN_MANAGE_USERS
        }, {
            value: 'makeAdmin',
            label: I18n.t('billing.Label.MakeAdmin'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_ADMIN
        }, {
            value: 'revokeAdmin',
            label: I18n.t('billing.Label.RevokeAdmin'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_ADMIN
        }, {
            value: 'makeAdminLeadership',
            label: I18n.t('billing.Label.MakeAdminLeadership'),
            visible: features["leadership.full"],
            users: RESTRICTIONS.Settings.CAN_MANAGE_LEADERSHIP
        }, {
            value: 'revokeAdminLeadership',
            label: I18n.t('billing.Label.RevokeAdminLeadership'),
            visible: features["leadership.full"],
            users: RESTRICTIONS.Settings.CAN_MANAGE_LEADERSHIP
        }, {
            value: 'makeAnalyst',
            label: I18n.t('billing.Label.MakeAnalyst'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_ANALYST
        }, {
            value: 'revokeAnalyst',
            label: I18n.t('billing.Label.RevokeAnalyst'),
            visible: true,
            users: RESTRICTIONS.Settings.CAN_MANAGE_ANALYST
        }
    ]
        .filter( a => a.visible )
        .filter( a => a.users.some( b => user[b] ) );

    return (
        <Flex>
            <Flex alignItems={'center'}>
                <SelectWrapper
                    menuPortalTarget={ document.body }
                    searchable={ false }
                    isDisabled={ ! selectedUsersNumber }
                    styles={{ input: () => ( { width: 150 } ) }}
                    options={ actions }
                    value={{
                        value: '',
                        label: I18n.t('billing.Label.SelectAnAction')
                    }}
                    onChange={ onActionClick }
                    clearable={ false }
                />
            </Flex>
            <SelectedUsers>
                {I18n.t('billing.Label.SelectedUserDesc')
                    .replace('{selectedUsersNumber}', selectedUsersNumber)
                    .replace('{totalUsersNumber}', totalUsersNumber)}
            </SelectedUsers>
        </Flex>
    )
}

export default ActionInput
