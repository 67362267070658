import React from 'react'
import {useSelector} from 'react-redux'
import Board from './Board'
import {getNonArchivedNodes, getTimeAreaFromIndex, renderNodes} from '../utils'
import {fieldsToSelectors} from '../redux/selectors'


const nodeBlocks = []

const BoardRows = ({nodesPerTimeArea, nodeId, nodeParentId}) => {
    const {nodeMap} = useSelector((state) => state.strategyMap)
    const clickedNodesToChildren = useSelector(fieldsToSelectors['clickedNodesToChildren'])

    nodeBlocks.push({
        size: nodesPerTimeArea.reduce((acc, val) => (val.length > acc ? val.length : acc), 0),
        ids: nodesPerTimeArea.flat(Infinity),
    })

    return (
        <>
            <div
                style={{
                    marginTop: '60px',
                    display: 'flex',
                    flexDirection: 'row',
                }} /* flex is Safari fix */
            >
                {nodesPerTimeArea.map((nodes, i) => (
                    <Board
                        key={i}
                        nodes={getNonArchivedNodes(nodes, nodeMap)}
                        isOpen={nodeId}
                        nodesPerTimeArea={nodesPerTimeArea}
                        nodeParentId={nodeParentId}
                        x={i}
                        timeArea={getTimeAreaFromIndex(i)}
                        nodeBlocks={nodeBlocks}
                    />
                ))}
            </div>
            {nodeId && (
                <div>
                    {renderNodes(
                        clickedNodesToChildren[nodeId],
                        clickedNodesToChildren,
                        nodeMap,
                        nodeId,
                    )}
                </div>
            )}
        </>
    )
}

export default BoardRows
