import React, {useEffect, useState} from 'react';
import { FormLabel } from 'react-bootstrap';
import {I18n} from 'react-i18nify';
import {connect} from 'react-redux';
import {useLanguageSlice} from '../hooks/useLanguageSlice';
import {LANGUAGE_CODE, LANGUAGE_TO_LABELS, TRANSLATIONS_MAP} from '../utils/language';
import {A42FormGroup} from "./BootstrapWrapper";
import SelectWrapper from './SelectWrapper'

const Translation = (props) => {
    const {Actions, current, available} = useLanguageSlice();
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        Actions.fetchLanguages({staticLanguage: props.staticLanguage});
        const languageCode = localStorage.getItem('language');
        if( languageCode && LANGUAGE_CODE[current] != languageCode )
            Actions.updateLanguage( TRANSLATIONS_MAP[languageCode] );
    }, []);

    const handleLanguageChange = selected => {
        Actions.updateLanguage( selected.value );
        setChanged( true );
    }
    
    if( available <= 1 )
        return <div></div>

    return (
        <A42FormGroup>
            {props.children}
            {!props.hideTitle && <FormLabel>{I18n.t('profile.PlaceHolder.Language')}</FormLabel>}
            <SelectWrapper
                searchable={false}
                style={{textTransform: 'capitalize'}}
                name="form-field-name"
                options={available.map((language) => {
                    return {
                        value: language,
                        label: LANGUAGE_TO_LABELS[language],
                    }
                })}
                onChange={handleLanguageChange}
                value={{
                    value: current,
                    label: LANGUAGE_TO_LABELS[current],
                }}
                className="language-list"
                clearable={false}
            />
            {!props.hideTitle && <>{changed ? (
                <span className="setting-info">{I18n.t('profile.Label.LanguageChangedInfo')}</span>
            ) : (
                <span className="changed-info">{I18n.t('profile.Label.LanguageSettingsInfo')}</span>
            )}</>}
        </A42FormGroup>
    )
}

const mapStateToProps = (state) => ({
    currentLanguage: state.language.current,
})
const ConnectedTranslation = connect(mapStateToProps)(Translation)

export default ConnectedTranslation
