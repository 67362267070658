import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {I18n} from 'react-i18nify'
import {getSquadMember} from '../../../strategyMap/utils'
import {useUserSlice} from '../../../strategyMap/hooks/useUserSlice'
import {ButtonSecondary} from '../../../core/components/Buttons'

const VolunteerCondition = (props) => {
    const {nodeMap} = useSelector((state) => state.strategyMap)
    const user = useUserSlice()

    let infoText
    let button
    let canNotVolunteerSquadMemberMessage

    const squadMembers = getSquadMember(props.node, nodeMap)
    const isUserInSquad = useMemo(() =>
        squadMembers.some(
            (member) => {
                const isUserMember = member.user.pk === user.pk
                if (isUserMember && !member.is_accepted) {
                    canNotVolunteerSquadMemberMessage = I18n.t(
                        'strategyMap.messages.youHaveAPendingInvitationToJoinTheImprovementSquad',
                    )
                        .replace('XSF_ID', nodeMap[member.sf].id)
                        .replace('XSF_TITLE', nodeMap[member.sf].title)
                } else {
                    canNotVolunteerSquadMemberMessage = I18n.t(
                        'strategyMap.messages.youAreAlreadyInTheImprovementSquad',
                    )
                        .replace('XSF_ID', nodeMap[member.sf].id)
                        .replace('XSF_TITLE', nodeMap[member.sf].title)
                }
                return isUserMember
            },
            [squadMembers, user],
        ),
    )

    const experimentTeamId = props.currentExperimentTeam && props.currentExperimentTeam.id
    if (props.canRequestVolunteer && !isUserInSquad) {
        button = (
            <ButtonSecondary
                fullWidth
                className="signInBtn"
                data-testid={'volunteer-signup-button'}
                onClick={props.signInAsVolunteer}
            >
                {I18n.t('strategyMap.messages.signInAsAVolunteer')}
            </ButtonSecondary>
        )
        if (props.isPendingInOtherRequest) {
            infoText = (
                <p className="awaiting" data-testid={'volunteer-application-waiting'}>
                    {I18n.t(
                        'strategyMap.messages.youAreAlreadyAwaitingApprovalInAnotherExperiment',
                    )}
                </p>
            )
        } else if (props.isParticipatingInOtherExperiment) {
            infoText = (
                <p className="participating" data-testid={'volunteer-already-participating'}>
                    {I18n.t('strategyMap.messages.youAreAlreadyParticipatingInAnotherExperiment')}
                </p>
            )
        }
    } else if (isUserInSquad) {
        infoText = (
            <p className="awaiting" data-testid={'cannot-volunteer-squad-member'}>
                {canNotVolunteerSquadMemberMessage}
            </p>
        )
    } else if (props.isPendingRequest) {
        infoText = (
            <p className="awaiting" data-testid={'volunteer-application-waiting'}>
                {I18n.t('strategyMap.messages.yourApplicationIsAwaitingApproval')}
            </p>
        )
        button = (
            <ButtonSecondary
                fullWidth
                className="leaveBtn"
                data-testid={'volunteer-leave-button'}
                onClick={() => {
                    props.leaveExperiment(experimentTeamId)
                }}
            >
                {I18n.t('strategyMap.messages.cancelRequest')}
            </ButtonSecondary>
        )
    } else if (props.isParticipatingInExperiment) {
        button = (
            <ButtonSecondary
                fullWidth
                className="leaveBtn"
                data-testid={'volunteer-leave-button'}
                onClick={() => {
                    props.leaveExperiment(experimentTeamId)
                }}
            >
                {I18n.t('strategyMap.messages.leaveTheExperiment')}
            </ButtonSecondary>
        )
    }

    return (
        <div data-testid={'volunteer-condition'}>
            {infoText}
            {button}
        </div>
    )
}

export default VolunteerCondition
