import React from 'react';
import styled from "styled-components";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const ProgressBarContainer = styled.div`
    width: 100vw;
`;
const ProgressBarStyle = styled.div`
    height: 7px;
    width: ${props => props.value + '%'};
    background-color: ${props => props.color};
    border: 0;
    margin:0;
    padding:0;
`;

const ProgressBar = ({value, tooltiptext, color}) => {
    if( value < 0 )
        return null;
    
    return (
        <OverlayTrigger
            placement={'bottom'}
            overlay={
                <Tooltip id={`tooltip-progress`} style={{zIndex: 3000, opacity: 1}}>
                    <b>{tooltiptext}</b>
                </Tooltip>
            }
        >
            <ProgressBarContainer>
                <ProgressBarStyle value={value} color={color}/>
            </ProgressBarContainer>
        </OverlayTrigger>
    )   
}

export default ProgressBar;