import React from 'react'
import styled from 'styled-components'
import {
    BLACK_GREY,
    BRIGHT_GREY,
    DARK_GREY,
    GREEN,
    LIGHT_GREY, 
    MAGENTA,
    PALE_GREY,
    TRANSPARENT,
    WHITE
} from './../ui/_colors'

const BaseButton = styled.button`
    margin: ${(props) => (props.fullWidth || props.noSideMrg ? '16px 0' : '16px')};
    width: ${(props) => (props.width || props.fullWidth ? '100%' : 'auto')};
    height: 44px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding-left: 22px;
    padding-right: 22px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${BRIGHT_GREY};

    border-style: hidden;
    border-width: 0;
    :hover {
        cursor: pointer !important;
    }
    * {
        color: inherit;
        text-decoration: inherit;
        font-weight: inherit;
        font-size: inherit;
        :hover {
            color: inherit;
            font-size: inherit;
            text-decoration: none;
            cursor: inherit !important;
        }
    }
`

export const ButtonPrimary = styled(BaseButton)`
    color: ${WHITE}
    background-color: ${DARK_GREY};
    opacity: ${(props) => (props.disabled ? '.3' : 1)};
    border-style: ${(props) => (props.disabled ? 'solid' : 'hidden')};
    border-color: ${(props) => (props.disabled ? DARK_GREY : WHITE)};
    border-width: ${(props) => (props.disabled ? '2px' : '0px')};
    font-size: 16px;
    :hover {
        border-style: ${(props) => (props.disabled ? 'solid' : 'hidden')};
        border-color: ${(props) => (props.disabled ? DARK_GREY : WHITE)};
        border-width: ${(props) => (props.disabled ? '2px' : '0px')};
   }
   ${p => p.fullWidth && 'width: 100%;'}
`
export const ButtonSecondary = styled(BaseButton)`
    color: ${(props) => (props.disabled ? DARK_GREY : DARK_GREY)}
    background-color: transparent;
    background-color: ${(props) => (props.disabled ? TRANSPARENT : BRIGHT_GREY)};
    border-style : ${(props) => (props.disabled ? 'solid' : 'hidden')};
    border-color: ${(props) => (props.disabled ? DARK_GREY : TRANSPARENT)};
    border-width: ${(props) => (props.disabled ? '2px' : '0px')};
    opacity: ${(props) => (props.disabled ? '.3' : 1)};
    font-size: 16px;
    :hover {
        background-color: ${(props) => (props.disabled ? TRANSPARENT : PALE_GREY)};
        border-style: ${(props) => (props.disabled ? 'solid' : 'hidden')};
        border-color: ${(props) => (props.disabled ? DARK_GREY : WHITE)};
        border-width: ${(props) => (props.disabled ? '2px' : '0px')};
   }
`

export const ButtonTertiary = styled.button`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: 0.64px;
    text-align: right;
    padding: 8px;
    color: ${DARK_GREY};
    opacity: ${(props) => (props.disabled ? '.3' : 1)};
    background-color: ${TRANSPARENT};
    border-style: hidden;
    border-color: ${TRANSPARENT};
    border-width: 0px;
    :hover {
        color: ${(props) => (props.disabled ? BLACK_GREY : DARK_GREY)}
        background-color: ${(props) => (props.disabled ? TRANSPARENT : PALE_GREY)}
    }
`

const _I = styled.i`
    font-size: 24px;
    color: ${(props) => (props.withBackground ? WHITE : BLACK_GREY)};
    background-color: ${(props) => (props.withBackground ? DARK_GREY : TRANSPARENT)};
    line-height: 24px;
    margin-left: 3px;
    float: right;
    display: ${({displayBlock}) => (displayBlock ? 'block' : 'none')};
    &:hover {
        color: ${({disabled}) => (disabled ? LIGHT_GREY : BLACK_GREY)};
        ${({disabled}) => !disabled && `background: ${PALE_GREY}`};
        text-decoration: none;
        font-weight: 500;
        ${({disabled}) => !disabled && 'cursor:pointer'};
    }
`

export const IconButton = ({onClick, icon, testid = false, displayBlock = true, disabled = false, withBackground = false}) => (
    <_I
        onClick={() => !disabled && onClick()}
        className="material-icons pull-right show-hover"
        aria-hidden="true"
        role="button"
        displayBlock={displayBlock}
        data-testid={testid}
        disabled={disabled}
        withBackground={withBackground}
    >
        {icon}
    </_I>
);

export const ActiveButton = styled.button`
    border: ${ props => "2px solid " + (props.active ? GREEN : MAGENTA)};
    border-radius: 10px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: bold;
    color: ${ props => props.active ? GREEN : MAGENTA};
    font-size: 13px;
    cursor: pointer;
    background-color: transparent;
`;
