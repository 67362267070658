import React from 'react';
import CommonPage from '../../core/components/CommonPage';
import FeaturePermission from '../../core/components/FeaturePermission';

import ImageIcon from "../../img/Apps/Leadership.png";
import {DashboardLeadershipSurveyWrapper} from "./DashboardLeadershipSurveyWrapper";

const DashboardPageLeadership = () => {

    return <FeaturePermission name="dashboard">
                <CommonPage
                    pageIcon={ImageIcon}
                    pageHeading={"ORGANIC agility – Leadership Assessment"}
                    render={() => <DashboardLeadershipSurveyWrapper />}
                />
            </FeaturePermission>;
}

export default DashboardPageLeadership;
