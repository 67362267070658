import React, {useEffect, useState} from 'react'
import DesiredOutcomeEdit from './DesiredOutcomeEdit'
import {RippleLoading} from '../Loading'
import {useStrategyMapSlice} from '../../../strategyMap/hooks/useStrategyMapSlice'
import {SIDEBAR_MODE} from '../../../strategyMap/const'
import {DesiredOutcomeListGroup, DesiredOutcomesContainer} from './DesiredOutcome.styles'

const DesiredOutcomes = (props) => {
    const {isSaving, sidebarMode} = useStrategyMapSlice()
    const isCreateMode = sidebarMode === SIDEBAR_MODE.CREATE
    const [addNewDesiredOutcomeElementKey, setAddNewDesiredOutcomeElementKey] = useState(props.desiredOutcomes.length)
    const [showLoader, setShowLoader] = useState(false)

    const showAddDesiredOutcome = () => props.showAddDesiredOutcome && !props.isReadOnly && !showLoader
    const handleDesiredOutcomeChange = (data) => {
        props.onDesiredOutcomeChange(data)
        if (!isCreateMode) {
            setShowLoader(true)
        }
    }

    useEffect(() => {
        if (!isSaving) {
            setShowLoader(false)
        }
    }, [isSaving])

    return (
        <DesiredOutcomesContainer className={props.id}>
            <RippleLoading
                css={{position: 'absolute', top: 'calc(50% - 32px)'}}
                loading={showLoader}
            />
            <DesiredOutcomeListGroup className={'clearfix ' + (showLoader ? 'disabled':'')}>
                {props.desiredOutcomes.map((desiredOutcome, key) => (
                    <DesiredOutcomeEdit
                        key={desiredOutcome.text + key}
                        position={key}
                        desiredOutcome={desiredOutcome}
                        done={desiredOutcome.done}
                        onDesiredOutcomeChange={handleDesiredOutcomeChange}
                        isReadOnly={props.isReadOnly}
                    />
                ))}
                {showAddDesiredOutcome() && (
                    <DesiredOutcomeEdit
                        onDesiredOutcomeChange={(data) => {
                            handleDesiredOutcomeChange(data)
                            setAddNewDesiredOutcomeElementKey(addNewDesiredOutcomeElementKey + 1)
                        }}
                        key={addNewDesiredOutcomeElementKey}
                        done={false}
                        placeholder={props.placeholder}
                    />
                )}
            </DesiredOutcomeListGroup>
        </DesiredOutcomesContainer>
    )
}

export default DesiredOutcomes
