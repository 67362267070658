import PropTypes from 'prop-types'
import React, {useCallback} from 'react'
import HeadingWithDropdown, {HeaderTitle, HeadingMaterialIcons, HeadingMeta,} from '../../core/ui/HeadingWithAction'
import DropdownMenu from './DropdownMenu'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";

const HeadingWithActionAndDropdown = (props) => {

    const {Actions} = useCoreSlice();
    const closeSidebars = useCallback(() => Actions.closeSidebar(), [])

    return (
        <HeadingWithDropdown>
            <HeaderTitle>
                {props.title}
                <HeadingMeta>
                    {props.showAction && props.icon && (
                        <HeadingMaterialIcons
                            onClick={closeSidebars}
                            className="material-icons pull-right"
                            aria-hidden="true"
                            role="button"
                        >
                            {props.icon}
                        </HeadingMaterialIcons>
                    )}
                    {props.showAction && !!props.dropdown.length && (
                        <DropdownMenu
                            activeMenuButtons={props.dropdown}
                            testId={props.testId}
                            icon={'more_vert'}
                            left
                        />
                    )}
                </HeadingMeta>
            </HeaderTitle>
        </HeadingWithDropdown>
    )
}

HeadingWithActionAndDropdown.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    showAction: PropTypes.bool,
    dropdown: PropTypes.array,
    image: PropTypes.string,
    className: PropTypes.string,
}

HeadingWithActionAndDropdown.defaultProps = {
    dropdown: [],
    showAction: true,
    className: '',
}

export default HeadingWithActionAndDropdown
