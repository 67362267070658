import React from 'react'
import SelectWrapper from '../../../core/components/SelectWrapper'
import {useStrategyMapSlice} from '../../../strategyMap/hooks/useStrategyMapSlice'

export const StrategyMapSelect = () => {
    const {Actions, goalNodes, nodeMap} = useStrategyMapSlice()

    const onChangeMap = (g) => Actions.getGoal({id: g.value})

    const getTitle = (g) => {
        if (g) {
            const node = nodeMap[g.id] || g
            return `Goal #${node.id} ${node.title || ''}`
        }
        return ''
    }

    const options = goalNodes.map((g) => ({
        value: g.id,
        label: getTitle(g),
    }))

    let defaultValue = null
    if (options.length > 0) {
        const lastOpenedMap = localStorage.getItem('lastOpenedMap');
        defaultValue = lastOpenedMap
            ? options.find((o) => o.value === parseInt(lastOpenedMap))
            : options[0];
    }
    
    return (
        <SelectWrapper
            minimalistView
            onChange={onChangeMap}
            defaultValue={defaultValue}
            options={options}
        />
    )
}

export default StrategyMapSelect
