import React from 'react'

import styled from 'styled-components'
import {dec2hex, getBarColors, hslToRgb, rgbToHsl} from '../ChartColor'
import {I18n} from 'react-i18nify'
import {DecisionAnswers} from '../StackedBar/agileBarConfig'
import {truncateLabel} from '../../core/utils/string'

const Center = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`

const Column = styled.div`
    float: left;
    width: ${(props) => props.width || '80px'}
    min-height: 10px;
    padding-right: 5px;
    text-transform: capitalize;
`
const ColumnNumber = styled(Column)`
    color: ${(props) => (props.isHeader ? 'black' : 'white')};
    text-align: ${(props) => (props.isHeader ? 'left' : 'right')};
    background-color: ${(props) => props.heat || 'white'};
    transform: ${(props) =>
        props.isHuge ? 'rotate(-90deg)' : props.isHeader ? 'rotate(-45deg)' : 'rotate(0)'};
    height: ${(props) => (props.isHuge ? '100px' : props.isHeader ? '50px' : 'auto')};
    padding-top: ${(props) => (props.isHuge ? '30px' : '0')}
    padding-right: 10px;
    margin-right: ${props => props.separate ? '5px!important' : '0'};
`
const Row = styled.div`
    clear: both;
    font-size: 12px;
    padding-top: ${props => props.separate ? '5px' : '0'};
`
const RowHeader = styled(Row)`
    font-weight: bold;
    text-align: center;
`

const TableChartColumns = (props) =>
    props.columns.map((column, i) => {
        const value = props.columnsValues[column]
        const heat = colorScale(value ? (100 * value) / props.maxValue : 0, props.colorSchema)

        return (
            <ColumnNumber key={i} isHeader={props.isHeader} heat={heat} separate={column === 'day' && props.colorSchema === 't1'}>
                {props.columnsValues[column] || 0}
            </ColumnNumber>
        )
    })

const TableChartRow = (props) => (
    <Row separate={props.separate}>
        <Column width={'170px'}>{truncateLabel(I18n.t('charts.Answers.' + props.tag), 23)}</Column>
        <TableChartColumns
            columns={props.columns}
            columnsValues={props.columnsValues}
            maxValue={props.maxValue}
            colorSchema={props.colorSchema}
        />
    </Row>
)

const TableHeader = (props) => {
    const bigColumnTitle = Math.max(...props.columns.map((section) => section.length)) > 15

    return (
        <RowHeader>
            <Column width={'170px'}> </Column>
            {props.columns.map((column, i) => (
                <ColumnNumber key={i} isHeader={true} isHuge={bigColumnTitle}>
                    {I18n.t('charts.Answers.' + column)}
                </ColumnNumber>
            ))}
        </RowHeader>
    )
}

const colorScale = (value, color) => {
    const baseColor = getBarColors(color)
    const [hue, saturation] = rgbToHsl(
        parseInt(baseColor.substr(1, 2), 16),
        parseInt(baseColor.substr(3, 2), 16),
        parseInt(baseColor.substr(5, 2), 16),
    )
    const lightness = (75 - 0.5 * value)/100
    const [red, green, blue] = hslToRgb(hue, saturation, lightness)

    return '#' + dec2hex(red) + dec2hex(green) + dec2hex(blue)
}

export const orderMatrix = (points, order) => {
    const orderedPoints = {}

    // order points
    order.forEach( label => {
        const point = Object.keys(points).find(point => point === getPrefixForLabel(label) + I18n.t(label))
        if(point && points[point])
            orderedPoints[getPrefixForLabel(label) + I18n.t(label)] = points[point]
    })

    Object.keys(points).forEach((point) => {
        const unsortedPoint = order.find(
            (label) => label === point,
        )
        if (unsortedPoint === undefined) {
            orderedPoints[point] = points[point]
        }
    })
    return orderedPoints
}

const extractTriangleIdFromLabel = (label) => parseInt(label.match(/(\d+)/)[0])
const getPrefixForLabel = (label) => label.includes("charts.Corner.Triangle.") ? "T"+extractTriangleIdFromLabel(label)+" " : ''

export const TableChart = (props) => {
    const _collectValues = (data) =>
        Object.keys(data).map((key) => Math.max(..._collectValueSecondLevel(data, key)))

    const _collectValueSecondLevel = (data, key) =>
        Object.keys(data[key]).flatMap((subkey) =>
            Number(data[key][subkey]) === data[key][subkey] ? data[key][subkey] : 0,
        )

    const maxValue = Math.max(..._collectValues(props.data));

    const orderedData = props.ordering ? orderMatrix(props.data, props.ordering) : props.data
    const triangleIndex = (question) => {
        let triangleId = false;
        DecisionAnswers.triangles.forEach( triangle => {
            if( I18n.t(triangle) === question )
                triangleId = extractTriangleIdFromLabel(triangle);
        })
        return triangleId;
    }
    let latestTriangleId = false;
    return (
        <Center data-testid={props.testId}>
            <div>
                <TableHeader columns={props.columns} />
                {
                    Object.keys(orderedData).map( (question, i) => {
                        const triangleId = triangleIndex(question)
                        let addSeparation = false;
                        if( triangleId && latestTriangleId !== triangleId) {
                            addSeparation = true;
                            latestTriangleId = triangleId;
                        }
                        if( [
                                I18n.t("charts.Answers.often"),
                                I18n.t("charts.Answers.rightaway"),
                                I18n.t("charts.Answers.often"),
                                I18n.t("charts.Answers.noformalconversation")].includes(question) )
                            addSeparation = true;

                        return (
                            <TableChartRow
                                key={i}
                                tag={question}
                                columns={props.columns}
                                columnsValues={props.data[question]}
                                colorSchema={props.colorSchema}
                                maxValue={maxValue}
                                separate={addSeparation}
                            />
                        )
                    })
                }
            </div>
        </Center>
    )
}
