import React from 'react'
import {I18n} from 'react-i18nify'
import { ButtonPrimary } from '../../core/components/Buttons';
import { DashboardMessage } from './Dashboard.styles';
import { Div } from '../../core/ui/Div';

const DashboardMessageCentered = ({allowCaptureAccess, gotoPage, message}) => {
    return (
        <Div textAlign="center">
            <DashboardMessage>
                {message || ""}
            </DashboardMessage>
            {allowCaptureAccess && (
                <ButtonPrimary onClick={gotoPage}>
                    {I18n.t('dashboard.Button.documentFirstDecision')}
                </ButtonPrimary>
            )}
        </Div>
    )
}

export default DashboardMessageCentered;