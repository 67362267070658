import styled, {createGlobalStyle, css} from 'styled-components'
import {BLACK_GREY, BRIGHT_GREY, DARK_GREY, PALE_GREY} from './_colors'

const reactConfirmAlert = css`
    #react-confirm-alert {
        .react-confirm-alert-overlay {
            z-index: 1000;
            .react-confirm-alert {
                padding: 30px 24px 20px 24px;
                width: 394px;
                h1 {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                .react-confirm-alert-button-group {
                    button {
                        min-height: 40px;
                        min-width: 80px;
                        border-radius: 0;
                        font-size: 16px;
                        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
                        font-weight: bold;
                        letter-spacing: 1.5px;
                        background-color: ${DARK_GREY};
                        &:first-child {
                            background-color: ${BRIGHT_GREY};
                            color: ${DARK_GREY};
                        }
                    }
                }
            }
        }
    }
`

const GlobalStyle = createGlobalStyle`
	html {
	  overflow: auto;
	}
	
	body {
		background-color: ${PALE_GREY};
	}
	
	input.form-control {
		font-size: 16px;
		border-radius: 0;
		color: ${BLACK_GREY};
	}
	
	label.control-label {
		font-weight: normal;
	}
	
	${reactConfirmAlert}
`

export default GlobalStyle


export const Container = styled.div`
    margin-top: 90px;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`
