import styled from 'styled-components'
import {BLACK_GREY, PALE_GREY} from './_colors'

const HeadingWithDropdown = styled.div`
    position: relative;
`
export default HeadingWithDropdown

export const HeaderTitle = styled.h3`
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2;
    font-weight: bold;
    padding-left: ${(p) => (p.paddingLeft ? '30px' : '0')};
`

export const HeadingMeta = styled.div`
    position: absolute;
    right: 0;
    top: 20px;
`

export const HeadingMaterialIcons = styled.i`
    font-size: 24px;
    color: ${BLACK_GREY};
    line-height: 24px;
    &:hover {
        background-color: ${PALE_GREY};
    }
`
