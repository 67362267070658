import {all, fork} from 'redux-saga/effects'
import strategyMapFlow from '../strategyMap/redux/sagas'
import commentFlow from '../strategyMap/redux/comment.sagas'
import dashboardFlow from '../dashboard/redux/sagas'
import notificationFlow from '../strategyMap/redux/notification.sagas'
import authFlow from '../auth/redux/sagas'
import captureFlow from '../capture/redux/sagas'
import coreFlow from '../core/redux/sagas'
import integrationFlow from '../integrations/redux/sagas'
import analysisFlow from '../analysis/redux/sagas'

export default function* root() {
    yield all([
        fork(strategyMapFlow),
        fork(captureFlow),
        fork(commentFlow),
        fork(notificationFlow),
        fork(authFlow),
        fork(dashboardFlow),
        fork(coreFlow),
        fork(integrationFlow),
        fork(analysisFlow),
    ])
}
