import {useEffect, useState} from 'react'

const SCREEN_SIZES = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile',
}

const MAX_DEVICE_WIDTH = {
    MOBILE: 576,
    TABLET: 992,
}

// : ?{width: ?number, height: ?number}
export const useWindowSize = () => {
    const isClient = typeof window === 'object'
    if (!isClient) {
        return null
    }

    const getDimensions = () => {
        return {
            width: isClient ? window.innerWidth : null,
            height: isClient ? window.innerHeight : null,
            screen_width: getScreenWidth(),
        }
    }

    const getScreenWidth = () => {
        const width = window.innerWidth
        let screenSize

        if (width < MAX_DEVICE_WIDTH.MOBILE) {
            screenSize = SCREEN_SIZES.MOBILE
        } else if (width < MAX_DEVICE_WIDTH.TABLET) {
            screenSize = SCREEN_SIZES.TABLET
        } else {
            screenSize = SCREEN_SIZES.DESKTOP
        }

        return screenSize
    }

    const [useWindowSize, setWindowSize] = useState(getDimensions)

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return useWindowSize
}
