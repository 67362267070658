import React, {useEffect, useState} from 'react'
import {Table} from 'react-bootstrap'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import Checkbox from '../capture/signifier/Checkbox'
import DropDownMenu, {DropDownMenuOptionType} from '../core/components/DropDownMenu'
import Modal from '../core/components/Modal'
import SelectWrapper from '../core/components/SelectWrapper'
import {useDemographicsSlice} from '../core/hooks/useDemographicsSlice'
import Footer from '../core/ui/modal/Footer'
import {LIGHT_GREY, WHITE} from '../core/ui/_colors'

const InputField = styled.input`
    border: 2px solid ${LIGHT_GREY};
    border-radius: 6px;
    padding: 6px;
    margin-left: 16px;
    margin-right: 16px;
    width: 50px;
    text-align: right;
`

const ActionButtonNewLine = styled.span`
    cursor: pointer;
    left: 0;
    bottom: -34px;
    position: relative;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${WHITE};
`
const ActionButton = styled.span`
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? '60%' : '100%')};
    vertical-align: middle;
    padding: 0;
`
const CheckerTh = styled.th`
    width: 30px;
`
const CheckerTd = styled.td`
    width: 30px;
    margin-left: 0px;
    vertical-align: middle !important;
`
const SPAN = styled.span`
    padding-left: 65px;
    vertical-align: center;
`
const TR = styled.tr`
    padding: 0;
    margin: 0;
    padding-top: 50px;
`
const UnitContainer = styled.div`
    width: 200px;
    display: inline-block;
    margin: 6px;
`

const units = {month: 'month', year: 'year'}
const unitsMap = {months: 'month', years: 'year', month: 'month', year: 'year'}

const Unit = ({index, changeValue, tenure, tenures}) => (
    <UnitContainer>
        <SelectWrapper
            searchable={false}
            options={Object.values(units).map((unit) => {
                return {
                    value: unit,
                    label: I18n.t(`billing.demographics.years.${unit}s`),
                }
            })}
            onChange={(option) => {
                if (option.value === units.month) {
                    if (
                        tenures[index - 1] &&
                        tenures[index - 1].unit === units.month &&
                        tenures[index - 1].end === 12
                    ) {
                        return
                    }
                }
                changeValue(
                    index,
                    option.value === units.month &&
                        tenure.end > 12 &&
                        tenures[index - 1].unit === units.month
                        ? 12
                        : null,
                    option.value,
                )
            }}
            value={{
                value: tenure.unit,
                label: I18n.t(`billing.demographics.years.${tenure.unit}s`),
            }}
            isDisabled={tenure.archived}
            clearable={false}
        />
    </UnitContainer>
)

const RowItem = ({index, tenure, tenures, changeValue, insertRow}) => {
    const isLastRow = tenures.length === index + 1 && tenures.length > 1
    if (isLastRow && !tenure.archived) {
        return (
            <SPAN>
                {I18n.t('billing.demographics.years.more than')} {tenure.end}{' '}
                {I18n.t('billing.demographics.years.' + tenure.unit)}
            </SPAN>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
            }}
        >
            {!tenure.archived && (
                <ActionButtonNewLine className="material-icons" onClick={() => insertRow(index)}>
                    add{' '}
                </ActionButtonNewLine>
            )}

            {index === 0 && !tenure.archived ? (
                <span style={{width: '100px'}}>
                    {I18n.t('billing.demographics.years.less than')}
                </span>
            ) : (
                <span style={{width: '100px'}}>
                    {I18n.t('billing.demographics.years.between')} {tenure.start || 0} -
                </span>
            )}
            <InputField
                type="number"
                value={tenure.end}
                disabled={tenure.archived}
                min={1}
                onChange={(event) => {
                    if (tenure.unit === units.month && event.target.value > 12) return
                    changeValue(index, event.target.value)
                }}
            />
            <Unit index={index} tenure={tenure} changeValue={changeValue} tenures={tenures} />
        </div>
    )
}

const CheckBoxOrDropDown = ({i, isArchived, markEntry, disabled, checked, activateTenure}) => {
    if (isArchived)
        return (
            <DropDownMenu
                materialIconName={'arrow_drop_down'}
                options={[
                    {
                        title: I18n.t('billing.demographics.actions.activate'),
                        key: 'activate',
                        type: DropDownMenuOptionType.PrimaryButton,
                    },
                ]}
                onChange={() => activateTenure(i)}
            />
        )
    return <Checkbox onClick={() => markEntry(i)} disabled={disabled} checked={checked} />
}

function YearDialog({demographics, onClose}) {
    const [selectedEntries, setSelectedEntries] = useState([])
    const [isOpen, setIsOpen] = useState(true)
    const {Actions} = useDemographicsSlice()
    const [activeTenures, setActiveTenures] = useState([])
    const [archivedTenures, setArchivedTenures] = useState([])
    const [tenures, setTenures] = useState(
        demographics
            ? demographics
                  .map((demo) => {
                      return {
                          end: parseInt(demo.end, 0) || demo.start,
                          unit: demo.range_base_period,
                          start: parseInt(demo.start, 0),
                          archived: demo.archived,
                          position: demo.position,
                      }
                  })
                  .sort((a, b) => a.position - b.position)
            : [],
    )

    useEffect(() => {
        if (!activeTenures.length) {
            setActiveTenures(tenures.filter((t) => !t.archived))
            setArchivedTenures(tenures.filter((t) => t.archived))
        }
    }, [tenures])

    const normalizeTenure = (activeTenures) => {
        for (let i = 1; i < activeTenures.length; i++) {
            const prevTenure = activeTenures[i - 1]
            const tenure = activeTenures[i]
            tenure.start = prevTenure.end
            if (prevTenure.unit === units.month) {
                if (prevTenure.end === 12) {
                    tenure.unit = units.year
                    tenure.start = 1
                    tenure.end = 2
                } else {
                    if (tenure.unit === units.year) {
                        tenure.unit = units.month
                        if (tenure.end > 12) {
                            tenure.end = 12
                        }
                    }
                }
            }
            if (prevTenure.unit === units.year && tenure.unit === units.month) {
                tenure.unit = units.year
            }
            if (tenure.end <= tenure.start) {
                const diff = tenure.start - tenure.end + 1
                tenure.end += diff
            }
            activeTenures[i] = tenure
        }

        activeTenures[activeTenures.length - 1].end = activeTenures[activeTenures.length - 1].start
        setActiveTenures(activeTenures)
    }

    const changeValue = (index, value, unit) => {
        const mod = [...activeTenures]
        if (value) mod[index].end = parseInt(value, 10)
        if (unit) {
            mod[index].unit = unit
        }
        normalizeTenure(mod)
    }

    const insertRowNew = () => {
        setTenures([
            {
                start: 0,
                end: 1,
                unit: units.year,
                archived: false,
            },
            {
                start: 1,
                end: 2,
                unit: units.year,
                archived: false,
            },
        ])
    }

    const insertRow = (afterIndex) => {
        const mod = [...activeTenures]
        mod.splice(afterIndex + 1, 0, {
            start: parseInt(mod[afterIndex].end, 10),
            end: parseInt(mod[afterIndex].end, 10) + 1,
            unit: mod[afterIndex].unit,
            archived: false,
        })
        normalizeTenure(mod)
        setTenures(mod)
        setSelectedEntries([])
    }

    const markEntry = (index) => {
        if (index === undefined) {
            if (activeTenures.length === selectedEntries.length) {
                setSelectedEntries([])
            } else {
                setSelectedEntries(activeTenures.map((_, i) => i))
            }
        } else {
            if (selectedEntries.includes(index))
                setSelectedEntries(selectedEntries.filter((i) => i !== index))
            else setSelectedEntries([...selectedEntries, index])
        }
    }

    const activateTenure = (index) => {
        const tenure = {...archivedTenures[index], archived: false}
        const mod = [...activeTenures, tenure]
        normalizeTenure(
            mod.sort((a, b) => {
                let aValue = a.end
                let bValue = b.end
                if (a.unit === units.month) {
                    aValue = aValue / 12
                }
                if (b.unit === units.month) {
                    bValue = bValue / 12
                }
                return aValue - bValue
            }),
        )
        setArchivedTenures(archivedTenures.filter((_, i) => i !== index))
    }

    const deleteEntries = () => {
        if (selectedEntries.length === 0) return
        const selectedItems = activeTenures.filter((_, i) => selectedEntries.includes(i))
        setActiveTenures(activeTenures.filter((_, i) => !selectedEntries.includes(i)))
        setSelectedEntries([])
        setArchivedTenures([
            ...archivedTenures,
            ...selectedItems.map((t) => ({...t, archived: true})),
        ])
    }

    const save = () => {
        const data = activeTenures.map((tenure, i) => {
            let rangeType = 'between'
            if (i === 0) {
                rangeType = 'less than'
                tenure.start = tenure.end
            }

            if (i === tenures.length - 1) {
                rangeType = 'more than'
                tenure.start = tenure.end
            }

            return {
                ...tenure,
                position: i,
                range_type: rangeType,
                range_base_period: unitsMap[tenure.unit],
            }
        })
        Actions.updateDemographicsTenant({data: data, detail: 'years'})
    }

    return (
        <Modal
            isOpen={isOpen}
            title={I18n.t('billing.demographics.setupTenure')}
            footerContent={
                <Footer
                    confirmText={I18n.t('app.buttons.confirm')}
                    onConfirm={() => {
                        setIsOpen(false)
                        save()
                        onClose()
                    }}
                    onCancel={() => {
                        setIsOpen(false)
                        onClose()
                    }}
                />
            }
        >
            <Table>
                <thead>
                    <tr>
                        <CheckerTh>
                            <CheckBoxOrDropDown
                                markEntry={markEntry}
                                checked={selectedEntries.length === tenures.length}
                            />
                        </CheckerTh>
                        <th style={{verticalAlign: 'initial'}}>
                            <ActionButton
                                className="material-icons"
                                onClick={() => tenures.length === 0 && insertRowNew()}
                                disabled={tenures.length > 0}
                            >
                                add
                            </ActionButton>
                            <ActionButton
                                className="material-icons"
                                disabled={selectedEntries.length === 0}
                                onClick={deleteEntries}
                            >
                                remove
                            </ActionButton>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {activeTenures.map((tenure, i) => (
                        <TR key={i}>
                            <CheckerTd>
                                <CheckBoxOrDropDown
                                    activateTenure={activateTenure}
                                    i={i}
                                    markEntry={markEntry}
                                    isArchived={tenure.archived}
                                    checked={selectedEntries.includes(i)}
                                />
                            </CheckerTd>
                            <td key={i} style={{verticalAlign: 'inherit'}}>
                                <RowItem
                                    index={i}
                                    tenure={tenure}
                                    tenures={activeTenures}
                                    changeValue={changeValue}
                                    insertRow={insertRow}
                                />
                            </td>
                        </TR>
                    ))}
                    {archivedTenures.map((tenure, i) => (
                        <TR key={i}>
                            <CheckerTd>
                                <CheckBoxOrDropDown
                                    activateTenure={activateTenure}
                                    i={i}
                                    markEntry={markEntry}
                                    isArchived={tenure.archived}
                                />
                            </CheckerTd>
                            <td key={i} style={{verticalAlign: 'inherit'}}>
                                <RowItem
                                    index={i}
                                    tenure={tenure}
                                    tenures={archivedTenures}
                                    changeValue={changeValue}
                                    insertRow={insertRow}
                                />
                            </td>
                        </TR>
                    ))}
                </tbody>
            </Table>
        </Modal>
    )
}

export default YearDialog
