import {I18n} from 'react-i18nify'
import React from 'react'
import {NODE_TYPES, NODE_TYPES_TO_EXTENDED_NAMES, RESOURCE_TYPES_TO_NODE_TYPES} from '../../strategyMap/const'
import {isMobile} from 'react-device-detect'
import {NodeIconHeader} from '../../strategyMap/components/NodeIcon'
import DropdownMenu, {MENU_ITEM_DIVIDER, MENU_ITEM_LINK} from './DropdownMenu'
import HeadingWithDropdown, {HeaderTitle, HeadingMaterialIcons, HeadingMeta} from '../../core/ui/HeadingWithAction'
import {getNodeLink, getPendingNode} from '../../strategyMap/utils'
import settings from '../../config/settings'
import {useNode} from '../../strategyMap/hooks/useNode'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";

const Help = (props) => {
    return (
        <HeadingMaterialIcons
            onClick={props.onClickHelp}
            role="button"
            className="material-icons pull-left"
            aria-hidden="true"
        >
            help_outline
        </HeadingMaterialIcons>
    )
}

export const ContextMenuEvents = {
    onExperimentCanvasVisible: "onExperimentCanvasVisible",
    onShowNodeCreationSidebar: "onShowNodeCreationSidebar",
    onNodeArchive: "onNodeArchive",
    onNodeUnarchive: "onNodeUnarchive",
    onArchiveAllChildNodes: "onArchiveAllChildNodes",
    onNodeUpdate: "onNodeUpdate",
    onNodeDelete: "onNodeDelete",
    onDeleteAllChildNodes: "onDeleteAllChildNodes",
    onNodeShareOtherGoal: "onNodeShareOtherGoal",
    onShowModalSharedItem: "onShowModalSharedItem",
    onShowModalConvertNode: "onShowModalConvertNode",
    onConnectToTrello: "onConnectToTrello",
    onConnectToSlack: "onConnectToSlack",
    onPrint: "onPrint"

}

const HeadingWithActionDropdownMenu = ({
    showAction = false,
    node = {fulfilled: false},
    icon = '',
    onSidebarClose = null,
    testId,
    onEvent,
    nodeActivity,
    onClickHelp,
    showHelpButton = null,
}) => {
    const {
        hasTrelloIntegration,
        hasSlackIntegration,
        hasChildren,
        isReadOnly,
        isUserChampion,
        isUserSponsor,
        isUserSquadMember,
        isUserInExperimentTeam,
        isGoal,
    } = useNode()
    const {client} = useCoreSlice();

    const allowDelete = (node) => {
        if (nodeActivity[node.id]) {
            return false;
        }

        if (node.children) {
            return node.children.filter((node) => !allowDelete(node)).length === 0;
        }

        return true
    }

    const returnDropdownMenuButtons = () => {
        const nodeIsNCFullfilled = node.type === NODE_TYPES.NC && !node.fulfilled
        const nodePSFwithSuccesExperiementAndUserIsOwner =
            node.type === NODE_TYPES.PSF && node.has_successful_experiment && isUserChampion
        const showIntegrationAction =
            (NODE_TYPES.GOAL === node.type && isUserSponsor) ||
            ([NODE_TYPES.CSF, NODE_TYPES.PSF].includes(node.type) &&
                (isUserSponsor || isUserSquadMember))
        const showIntegrationTrello =
            [NODE_TYPES.CSF, NODE_TYPES.PSF].includes(node.type) &&
            (isUserSponsor || isUserSquadMember) &&
            !hasTrelloIntegration &&
            client.trello_access_token
        const showSlackIntegration = showIntegrationAction && !hasSlackIntegration && client.slack_access_token;

        const showDelete =
            !isReadOnly &&
            (node.type === NODE_TYPES.CSF ||
                node.type === NODE_TYPES.NC ||
                (node.type === NODE_TYPES.EXPERIMENT && isUserChampion && !node.is_started) ||
                (node.type === NODE_TYPES.PSF && !node.has_running_experiment)) &&
            !isMobile &&
            allowDelete(node)

        const showDeleteChildren = showDelete && hasChildren

        const showArchive = !isReadOnly && !node.is_archived &&
            (node.type === NODE_TYPES.CSF ||
                node.type === NODE_TYPES.NC ||
                (node.type === NODE_TYPES.EXPERIMENT && isUserChampion && !node.is_started) ||
                (node.type === NODE_TYPES.PSF && !node.has_running_experiment)) &&
            !isMobile;

        const showArchiveChildren = showArchive && hasChildren

        const isValidating = node.time_area === "validating";
        const showUnarchive = !isReadOnly && node.is_archived;
        const menuButtons = [
            {
                action: 'openExperimentCanvas',
                type: MENU_ITEM_LINK,
                actionHandler: () => onEvent( ContextMenuEvents.onExperimentCanvasVisible, true ),
                active: node.type === NODE_TYPES.EXPERIMENT,
            },
            {
                type: MENU_ITEM_DIVIDER,
                active: node.type === NODE_TYPES.EXPERIMENT,
            },
            {
                action: 'addPSF',
                actionHandler: () => onEvent(
                    ContextMenuEvents.onShowNodeCreationSidebar,
                    getPendingNode(
                            NODE_TYPES.PSF,
                            I18n.t('strategyMap.nodes.descriptionPlaceholderPSF')
                        ) ),
                type: MENU_ITEM_LINK,
                active: !isReadOnly && isGoal && !isMobile,
            },
            {
                action: 'addCSF',
                actionHandler: () => onEvent(
                    ContextMenuEvents.onShowNodeCreationSidebar,
                    getPendingNode(
                            NODE_TYPES.CSF,
                            I18n.t('strategyMap.nodes.descriptionPlaceholderCSF')
                        )
                ),
                type: MENU_ITEM_LINK,
                active: !isReadOnly && isGoal && !isMobile,
            },
            {
                action: 'addNC',
                type: MENU_ITEM_LINK,
                actionHandler: () => onEvent(
                    ContextMenuEvents.onShowNodeCreationSidebar,
                    getPendingNode(
                            NODE_TYPES.NC,
                            I18n.t('strategyMap.nodes.descriptionPlaceholderNC')
                        )
                ),
                active:
                    !isReadOnly &&
                    (node.type === NODE_TYPES.PSF ||
                        node.type === NODE_TYPES.CSF ||
                        (node.type === NODE_TYPES.NC && !node.fulfilled) ||
                        (node.type === NODE_TYPES.EXPERIMENT &&
                            !node.is_finished &&
                            (isUserChampion || isUserInExperimentTeam || isUserSquadMember))) &&
                    !isMobile,
            },
            {
                action: 'addEXPERIMENT',
                actionHandler: () => onEvent(
                    ContextMenuEvents.onShowNodeCreationSidebar,
                    getPendingNode(NODE_TYPES.EXPERIMENT, '')
                ),
                type: MENU_ITEM_LINK,
                active: !isReadOnly && node.type === NODE_TYPES.PSF && isUserChampion && !isMobile,
            },
            {
                type: MENU_ITEM_DIVIDER,
                active:
                    !isReadOnly &&
                    ((node.type === NODE_TYPES.EXPERIMENT &&
                        !node.is_finished &&
                        (isUserChampion || isUserInExperimentTeam)) ||
                        [NODE_TYPES.PSF, NODE_TYPES.CSF, NODE_TYPES.GOAL].includes(node.type)),
            },
            {
                action: 'convertToPSF',
                actionHandler: () => {
                    const updatedNode = {
                        ...node,
                        sf_type: 'P'
                    };
                    onEvent( ContextMenuEvents.onNodeUpdate, updatedNode );
                },
                type: MENU_ITEM_LINK,
                active: !isReadOnly && isUserChampion && isValidating && node.type === NODE_TYPES.CSF,
            },
            {
                action: 'convertToCSF',
                actionHandler: () => {
                    const updatedNode = {
                        ...node,
                        sf_type: 'C'
                    };
                    onEvent( ContextMenuEvents.onNodeUpdate, updatedNode );
                },
                type: MENU_ITEM_LINK,
                active: !isReadOnly && isUserChampion && isValidating && node.type === NODE_TYPES.PSF,
            },
            {
                action: 'markFulfilled',
                actionHandler: () => {
                    const updatedNode = {
                        ...node,
                        fulfilled: !node.fulfilled,
                        fulfillable: !node.fulfillable
                    };
                    onEvent( ContextMenuEvents.onNodeUpdate, updatedNode );
                },
                type: MENU_ITEM_LINK,
                active: !isReadOnly && node.type === NODE_TYPES.NC && node.fulfillable,
            },
            {
                action: 'markSuccessful',
                actionHandler: () => {
                    const updatedNode = {
                        ...node,
                        successful: true
                    };
                    onEvent( ContextMenuEvents.onNodeUpdate, updatedNode );
                },
                type: MENU_ITEM_LINK,
                active:
                    !isReadOnly &&
                    isUserChampion &&
                    node.is_finished &&
                    !node.successful &&
                    !node.failed
            },
            {
                action: 'markFailed',
                actionHandler: () => {
                    const updatedNode = {
                        ...node,
                        failed: true
                    };
                    onEvent( ContextMenuEvents.onNodeUpdate, updatedNode );
                },
                type: MENU_ITEM_LINK,
                active:
                    !isReadOnly &&
                    isUserChampion &&
                    node.is_finished &&
                    !node.successful &&
                    !node.failed
            },
            {
                action: 'undoHideOnStrategyMap',
                actionHandler: () => onEvent( ContextMenuEvents.onNodeUpdate, {...node, hidden: false} ),
                type: MENU_ITEM_LINK,
                active:
                    !isReadOnly &&
                    node.is_finished &&
                    (node.successful || node.failed) &&
                    node.hidden
            },
            {
                type: MENU_ITEM_DIVIDER,
                active:
                    !isReadOnly &&
                    (node.type === NODE_TYPES.NC ||
                        nodeIsNCFullfilled ||
                        nodePSFwithSuccesExperiementAndUserIsOwner)
            },
            {
                action: 'showModalSharedItem',
                actionHandler: () => onEvent( ContextMenuEvents.onShowModalSharedItem, true ),
                type: MENU_ITEM_LINK,
                active: true
            },
            {
                action: 'showModalConvertNode',
                actionHandler: () => onEvent( ContextMenuEvents.onShowModalConvertNode, true ),
                type: MENU_ITEM_LINK,
                active: !isGoal && isUserChampion
            },
            {
                action: 'copyLink',
                copyToClipboard: true,
                value: getNodeLink(node.id),
                type: MENU_ITEM_LINK,
                active: true
            },
            {
                type: MENU_ITEM_DIVIDER,
                active: true
            },
            {
                action: 'connectToTrello',
                actionHandler: () => onEvent( ContextMenuEvents.onConnectToTrello, {}),
                type: MENU_ITEM_LINK,
                active: showIntegrationTrello
            },
            {
                action: 'connectSlackChannel',
                actionHandler: () => onEvent( ContextMenuEvents.onConnectToSlack, {}),
                type: MENU_ITEM_LINK,
                active: settings.enableSlackIntegration && showSlackIntegration
            },
            {
                type: MENU_ITEM_DIVIDER,
                active: showSlackIntegration || showIntegrationTrello
            },
            {
                action: 'print',
                actionHandler: () => onEvent( ContextMenuEvents.onPrint, {}),
                type: MENU_ITEM_LINK,
                active: true
            },
            {
                type: MENU_ITEM_DIVIDER,
                active: showDelete
            },
            {
                action: 'delete',
                testId: 'sidebar-delete-button',
                actionHandler: () => onEvent( ContextMenuEvents.onNodeDelete, {}),
                type: MENU_ITEM_LINK,
                active: showDelete
            },
            {
                action: 'deleteAllChildNodes',
                testId: 'sidebar-delete-all-button',
                actionHandler: () => onEvent( ContextMenuEvents.onDeleteAllChildNodes, {}),
                type: MENU_ITEM_LINK,
                active: showDeleteChildren
            },
            {
                action: 'archive',
                actionHandler: () => onEvent( ContextMenuEvents.onNodeArchive, {}),
                type: MENU_ITEM_LINK,
                active: showArchive
            },
            {
                action: 'archiveAllChildNodes',
                actionHandler: () => onEvent( ContextMenuEvents.onArchiveAllChildNodes, {}),
                type: MENU_ITEM_LINK,
                active: showArchiveChildren
            },
            {
                action: 'unarchive',
                actionHandler: () => onEvent( ContextMenuEvents.onNodeUnarchive, node.id),
                type: MENU_ITEM_LINK,
                active: showUnarchive
            },
        ]

        return menuButtons.filter((button) => button.active)
    }

    const returnTitle = () => {
        let title
        if (node.resourcetype) {
            const type = node.sf_type || NODE_TYPES[RESOURCE_TYPES_TO_NODE_TYPES[node.resourcetype]]

            const defaultTitle = I18n.t(
                'strategyMap.nodes.types.' + NODE_TYPES_TO_EXTENDED_NAMES[type],
            )
            title = node.number ? defaultTitle + ' #' + node.number : defaultTitle
        } else {
            title = I18n.t('strategyMap.createGoal')
        }
        return title
    }

    let action
    let activeMenuButtons

    if (showAction && icon) {
        action = (
            <HeadingMaterialIcons
                onClick={onSidebarClose}
                className="material-icons pull-right"
                aria-hidden="true"
                role="button"
                data-testid={'close-button'}
            >
                {icon}
            </HeadingMaterialIcons>
        )
        activeMenuButtons = returnDropdownMenuButtons()
    }

    return (
        <HeadingWithDropdown>
            <NodeIconHeader node={node} />
            <HeaderTitle paddingLeft>
                {returnTitle()} #{node.sid}
                <HeadingMeta>
                    {showAction && showHelpButton && <Help onClickHelp={onClickHelp} />}
                    {action}
                    {activeMenuButtons && activeMenuButtons.length && (
                        <DropdownMenu
                            activeMenuButtons={activeMenuButtons}
                            icon={'more_vert'}
                            left
                            testId={testId}
                        />
                    )}
                </HeadingMeta>
            </HeaderTitle>
        </HeadingWithDropdown>
    )
}

export default HeadingWithActionDropdownMenu
