import React from 'react'
import {Helmet} from 'react-helmet'
import {consoleError} from './../utils/error'

class HelmetErrorBoundary extends React.Component {
    // catch recursion max depth errors
    constructor(props) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo) {
        consoleError(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return null
        }

        return this.props.children
    }
}

const PageHeader = ({title}) => {
    return (
        <HelmetErrorBoundary>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
            </Helmet>
        </HelmetErrorBoundary>
    )
}

export default PageHeader
