import React from "react"
import toggleFinishedExperimentsIcon from "../../../img/icons/ic-exp.svg"
import {useStrategyMapSlice} from "../../../strategyMap/hooks/useStrategyMapSlice"
import {I18n} from "react-i18nify"
import AnnotatedNavItem from "./AnnotatedNavItem"


const ToggleFinishedExperiments = () => {
    const {showHiddenExperiment, Actions} = useStrategyMapSlice()

    return (<AnnotatedNavItem
        title={showHiddenExperiment ? I18n.t('strategyMap.toolbar.hideHiddenExp'): I18n.t('strategyMap.toolbar.showHiddenExp')}
        icon={toggleFinishedExperimentsIcon}
        onClick={Actions.setHiddenNodesVisibility}/>)
}


export default ToggleFinishedExperiments