import React, {useState, useEffect} from 'react'
import {I18n} from 'react-i18nify'
import SmartList from './Components/SmartList'
import DesiredOutcomes from '../core/components/DesiredOutcomes/DesiredOutcomes'
import HeadingWithAction from './Components/HeadingWithAction'
import Sponsor from '../strategyMap/components/Sponsor'
import {useStrategyMapSlice} from '../strategyMap/hooks/useStrategyMapSlice'
import StrategicIterations from "../strategyMap/components/StrategicIterations"
import {useNode} from '../strategyMap/hooks/useNode'
import {FormControl} from "react-bootstrap";
import { useTypingTimeout } from '../strategyMap/hooks/useTypingTimeout'
import SelectWrapper from '../core/components/SelectWrapper'

const Goal = (props) => {
    const {Actions, strategicIterations} = useStrategyMapSlice()
    const [showAddDesiredOutcome, setShowAddDesiredOutcome] = useState(false)
    const {isUserSponsor} = useNode()

    const handleSmartList = (name) => {
        const updatedNode = {...props.node, [name]: !props.node[name]}
        if (!props.isEditMode) {
            Actions.updatePendingNode(updatedNode)

            return
        }

        props.isEditMode && Actions.updateNode(updatedNode)
    }

    const handleDesiredOutcome = (action) => {
        const desiredOutcome = action.desiredOutcome
        const type = action.type
        const position = action.position

        const updatedNode = {...props.node}
        if (type === 'DELETE') {
            updatedNode.desired_outcomes.splice(position, 1)
        } else if (type === 'ADD') {
            if (!updatedNode.desired_outcomes) {
                updatedNode.desired_outcomes = []
            }
            updatedNode.desired_outcomes.push(desiredOutcome)
        } else {
            updatedNode.desired_outcomes[position] = {
                ...desiredOutcome,
                id: action.oldDesiredOutcome.id,
            }
        }

        if (props.node.measurable && updatedNode.desired_outcomes.length === 0) {
            // if no desired outcomes added remove ticked measurable
            updatedNode.measurable = false
        }

        if (!props.isEditMode) {
            Actions.updatePendingNode(updatedNode)
            return
        }

        if (type === 'DELETE') {
            Actions.deleteDesiredOutcome(action.desiredOutcome.id)
        } else {
            Actions.updateNode(updatedNode)
        }
    }

    const toggleEmptyDesiredOutcome = () => setShowAddDesiredOutcome(!showAddDesiredOutcome)

    const cadenceOptions =
        Array.from(Array(6).keys())
            .map( month => {
                return {value: month+1, label: (month+1) + " " + I18n.t("app.time/date.months")}
            })

    const [handleOnchangeTimeout, typingTimeout] = useTypingTimeout()
    
    const [mapName, setMapName] = useState('')

    useEffect(() => {
        setMapName(props.node.map_name)
    }, [props.node.map_name])
    

    const handleMapNameChange = (e) => {
        const v = e.target.value
        setMapName(v)
        
        handleOnchangeTimeout(() => {
            Actions.updateNode({...props.node, map_name: v})
        })
    }

    return (
        <div className={'goal'}>

            <HeadingWithAction title={I18n.t('strategyMap.nodes.mapName')} />
            <FormControl
                disabled={!props.isEditMode || !isUserSponsor}
                type="text"
                value={mapName}
                placeholder={I18n.t('strategyMap.nodes.mapName')}
                onChange={handleMapNameChange}
            />

            {props.isEditMode && (isUserSponsor || props.node.cadence) && <>
                <HeadingWithAction title={I18n.t('strategyMap.nodes.cadence')} />
                <SelectWrapper
                    data-testid="cadence-select"
                    searchable={false}
                    style={{textTransform: 'capitalize'}}
                    name="form-field-name"
                    options={cadenceOptions}
                    onChange={ cadence => Actions.updateNode({...props.node, cadence: cadence.value})}
                    value={cadenceOptions.filter( cadence => cadence.value === props.node.cadence)}
                    clearable={false}
                />
            </>}

            {props.isEditMode && (isUserSponsor || strategicIterations.length>0) && <>
                <HeadingWithAction 
                    title={I18n.t('strategyMap.nodes.strategicIterations')} 
                    showHint={true}
                    hintMessage={I18n.t('strategyMap.nodes.strategicIterationsHintMessage')}
                />
                <StrategicIterations />
            </>}

            <HeadingWithAction title={I18n.t('strategyMap.nodes.sponsors')} />
            <Sponsor node={props.node} isAbleToEdit={props.isAbleToEdit} />

            <HeadingWithAction
                title={I18n.t('strategyMap.nodes.desiredOutcomes')}
                icon={showAddDesiredOutcome ? 'remove' : 'add'}
                showAction={!props.isReadOnly}
                onClick={toggleEmptyDesiredOutcome}
            />
            <DesiredOutcomes
                id={'desired_outcomes'}
                desiredOutcomes={props.node.desired_outcomes}
                onDesiredOutcomeChange={handleDesiredOutcome}
                isReadOnly={props.isReadOnly}
                showAddDesiredOutcome={showAddDesiredOutcome}
                placeholder={I18n.t('strategyMap.nodes.addDesiredOutcome')}
            />
            <HeadingWithAction 
                title={I18n.t('strategyMap.nodes.label')} 
                showHint={true}
                hintMessage={I18n.t('strategyMap.nodes.goalLabelsHintMessage')}
            />
            <SmartList
                goal={props.node}
                handleSmartToggle={handleSmartList}
                desiredOutcomesEnabled={props.node.desired_outcomes && props.node.desired_outcomes.length > 0}
                isReadOnly={props.isReadOnly}
            />
        </div>
    )
}

export default Goal
