import {includes, toLower, useWith} from 'ramda'

export const includesAny = useWith(includes, [toLower, toLower])

export const snakeToCamel = (str) =>
    str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

export const truncateLabelsFromArray = (labelsArray, strLength) => {
    return labelsArray.map((label) => {
        if (!label || label.toLowerCase().includes('undefined')) label = 'Other'
        return truncateLabel(label, strLength)
    })
}

export const truncateLabel = (label, length) => {
    const LABEL_LENGTH = length || 30
    if(!label) return 'n/a'
    return label.length <= LABEL_LENGTH ? label : label.slice(0, LABEL_LENGTH) + '…'
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const removeTags = (str) => {
    if ((str===null) || (str===''))
        return false;
    
    return str.toString().replace( /(<([^>]+)>)/ig, ' ').trim();
}

export const decodeEntity = (str) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = str;
    return textarea.value;
}