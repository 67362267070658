import React, {useEffect} from 'react'
import settings from '../config/settings.json'
import {useCoreSlice} from "../core/hooks/useCoreSlice";

const SlackAuth = () => {
    const {client} = useCoreSlice()
    useEffect(() => {
        const scopes = [
            'channels:read',
            'channels:join',
            'groups:read',
            'channels:manage',
            'chat:write',
            'team:read',
            'users:read',
            'users:read.email',
        ]
        const data = {tenantID: client.id}
        const redirect_uri = `redirect_uri=${window.location.protocol}//public.${window.location.host.replace(/^[^.]+\./g, '')}/api/slack/authorization`
        const scope = `scope=${scopes.join(',')}`
        const clientId = `client_id=${settings.slackClientId}`
        const state = `state=${JSON.stringify(data)}`
        const auth_url = `https://slack.com/oauth/v2/authorize?${scope}&${clientId}&${state}&${redirect_uri}`
        if (!client.slack_access_token) {
            window.location.href = auth_url
        }
    }, [])
    return null
}

export default SlackAuth
