import {MIRO_TYPES} from "./Miro";
import {NODE_TYPES, NODE_TYPES_TO_EXTENDED_NAMES, TIME_AREAS} from "../../../strategyMap/const";
import { decodeEntity, removeTags } from "../../../core/utils/string";
import moment from "moment";

export const MIRO_VERSIONS = {
    V1: "V1",
    DEFAULT: "V1"
}

const StrategyMapColorByType = {
    GOAL: "#8fd14f",
    PSF: "#fac710",
    CSF: "#ff9400",
    NC: "#f24726",
    EXPERIMENT: "#2d9bf0"
}

export class StrategyMapData {
    data;
    connectors;
    validFrames;
    dataSets;
    _version;
    nodes = {};
    errors = [];

    constructor(data, connectors) {
        this.data = data ? data : [];
        this.connectors = connectors
            ? connectors
                .filter( a => a.startItem && a.endItem )
                .map( a => ({start: a.startItem.id, end: a.endItem.id} ) )
            : [];
        this.setVersion( MIRO_VERSIONS.DEFAULT );
    }
    setVersion = (version) => {
        this._version = version;
        return this;
    }
    getVersion = () => this._version;

    _getTypeByColor = color => {
        switch (color) {
            case StrategyMapColorByType.GOAL:
                return NODE_TYPES.GOAL;
            case StrategyMapColorByType.PSF:
                return NODE_TYPES.PSF;
            case StrategyMapColorByType.CSF:
                return NODE_TYPES.CSF;
            case StrategyMapColorByType.NC:
                return NODE_TYPES.NC;
            case StrategyMapColorByType.EXPERIMENT:
                return NODE_TYPES.EXPERIMENT;
            default:
                return "";
        }
    }
    _getColumnByID = (frameID) => {
        const miroColumn = this.validFrames.filter( frame => frame.id === frameID )[0].data.title;
        
        switch (miroColumn) {
            case "Past":
                return TIME_AREAS.CONFIRMED;
            case "Present":
                return TIME_AREAS.VALIDATING;
            case "Future":
                return TIME_AREAS.POTENTIAL;
            default:
                return "";
        }
    }

    getNodeTitle = (node) => removeTags(decodeEntity(node.data.title))


    buildNodeObject = (node, parent) => {
        const type = this._getTypeByColor( node.style.cardTheme );
        const children = this.connectors.filter( a => a.start === node.id ).map( a => a.end );
        const childrenNodes = children.map( child => this.buildNodeObject( this.dataSets.filter( a => a.id === child )[0], node ) );
        const nodeTitle = this.getNodeTitle(node);

        this.checkForErrors(node, parent);

        return {
            "type": type,
            "sf_type": type,
            "title": type === NODE_TYPES.EXPERIMENT ? nodeTitle : '', 
            "description": nodeTitle,
            "time_area": this._getColumnByID( node.parent.id ),
            "deadline": node.data.dueDate ? moment(node.data.dueDate).format('YYYY-MM-DD') : null,
            "children": childrenNodes,
        }
    }

    getGoalNode = () => {
        const goalNode = this.dataSets.filter( a => a.style.cardTheme === StrategyMapColorByType.GOAL )[0];
        if ( !goalNode ) {
            this.errors.push( "No Goal node found" );
        }
        return goalNode;
    }

    checkForOrphanNodes = () => {
        const connectedNodesIds = this.connectors.flatMap(obj => ([obj.start, obj.end]));
        const connectedNodesUniqueIds = [...new Set(connectedNodesIds)];
        const ophans = this.dataSets.filter( n => !connectedNodesUniqueIds.includes(n.id));

        ophans.forEach( node => {
            const nodeType = this._getTypeByColor( node.style.cardTheme );
            const nodeTitle = this.getNodeTitle(node);
            if ( nodeType !== NODE_TYPES.GOAL )
                this.errors.push( `Node "${nodeTitle}" is not connected to any other node` );
        });
    }
        
    buildTree = () => {
        this.validFrames = this.data
            .filter( a => a.type === MIRO_TYPES.frame )
            .filter( a => ["Past", "Present", "Future"].includes( a.data.title ) );

        this.dataSets = this.data
                .filter( a => a.parent && a.type === MIRO_TYPES.card )
                .filter( a => this.validFrames.map( b => b.id).includes( a.parent.id ) );
        this.connectors = this.connectors.filter( con => this.dataSets.map( a => a.id ).includes( con.start ) );
        
        this.checkForOrphanNodes();
        const goalNode = this.getGoalNode();
        if( goalNode )
            this.nodes = this.buildNodeObject( goalNode, false );
    };

    checkForErrors = (node, parent) => {
        const nodeType = this._getTypeByColor(node.style.cardTheme);
        const nodeTimeArea = this._getColumnByID(node.parent.id);
        const nodeTitle = this.getNodeTitle(node);
        const parentType = parent ? this._getTypeByColor(parent.style.cardTheme) : null;

        const getExtendedNodeName = (nodeType) => 
            NODE_TYPES_TO_EXTENDED_NAMES[nodeType] || nodeType;
      
        const checkParentType = (expectedType) => {
          if (parentType !== expectedType) {
            this.errors.push(`${getExtendedNodeName(nodeType)} "${nodeTitle}" should have a ${expectedType} as a parent`);
          }
        };
      
        const checkNodeTimeArea = (allowedAreas, errorMessage) => {
          if (!allowedAreas.includes(nodeTimeArea)) {
            this.errors.push(`${getExtendedNodeName(nodeType)} "${nodeTitle}" should be in ${allowedAreas.join(' or ')} column. ${errorMessage}`);
          }
        };
      
        switch (nodeType) {
          case NODE_TYPES.GOAL:
            if (parentType !== null) {
              this.errors.push(`${getExtendedNodeName(nodeType)} "${nodeTitle}" should not have a parent`);
            }
            checkNodeTimeArea([TIME_AREAS.POTENTIAL], '');
            break;
          case NODE_TYPES.PSF:
            checkParentType(NODE_TYPES.GOAL);
            checkNodeTimeArea([TIME_AREAS.VALIDATING, TIME_AREAS.POTENTIAL], '');
            break;
          case NODE_TYPES.CSF:
            checkParentType(NODE_TYPES.GOAL);
            checkNodeTimeArea([TIME_AREAS.CONFIRMED, TIME_AREAS.VALIDATING], '');
            break;
          case NODE_TYPES.NC:
            if (parentType === NODE_TYPES.GOAL) {
              this.errors.push(`${getExtendedNodeName(nodeType)} "${nodeTitle}" should not have a Goal as a parent`);
            }
            break;
          case NODE_TYPES.EXPERIMENT:
            checkParentType(NODE_TYPES.PSF);
            checkNodeTimeArea([TIME_AREAS.VALIDATING, TIME_AREAS.POTENTIAL], '');
            break;
          default:
            break;
        }
    }

    validate = () => {
        this.buildTree();
    }
}