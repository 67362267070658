import React from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import progress2nd from "../../img/icons/ic-progress-2-nd-period.svg"
import progress1st from "../../img/icons/ic-progress-1-st-period.svg"
import {A42Popover} from "../../core/components/BootstrapWrapper";

const STILL_ACTIVE_THRESHOLD = 15
const INACTIVE_THRESHOLD = 30

export const IMG = styled.img`
    width: 26px;
    height: 25px;
    margin: 0 2px;
`

const InactivityWarning = ({period_of_inactivity}) => {
    if (period_of_inactivity < STILL_ACTIVE_THRESHOLD) return null

    const RedWarning = (
        <IMG src={progress2nd} alt="progress" />
    )
    const YellowWarning = (
        <IMG src={progress1st} alt="progress" />
    )

    const Warning =
        STILL_ACTIVE_THRESHOLD <= period_of_inactivity && period_of_inactivity < INACTIVE_THRESHOLD
            ? YellowWarning
            : period_of_inactivity >= INACTIVE_THRESHOLD
            ? RedWarning
            : null

    return (
        <>
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <A42Popover placement={"top"} delay={400}>
                        {I18n.t('strategyMap.messages.noProgressForTheLastXDays').replace(
                            'INACTIVITY_PERIOD_DAYS',
                            period_of_inactivity,
                        )}
                    </A42Popover>
                }
            >
                {Warning}
            </OverlayTrigger>
        </>
    )
}

export default InactivityWarning
