import React, {useMemo} from 'react'
import {I18n} from 'react-i18nify'
import moment from 'moment'
import {NODE_STATES} from '../../strategyMap/const'

export const TimeElapsedDisplay = ({duration, startDate, status}) => {
    const timeElapsedDisplay = useMemo(() => {
        const durationDisplay = moment
            .duration(duration, 'weeks')
            .locale(I18n._localeKey)
            .humanize()
        const currentTimeElapsedDisplay = `${moment
            .duration(moment().diff(startDate))
            .locale(I18n._localeKey)
            .humanize()} / ${durationDisplay}`

        switch (status) {
            case NODE_STATES.E.started:
                return currentTimeElapsedDisplay
            case NODE_STATES.E.finished:
            case NODE_STATES.E.successful:
            case NODE_STATES.E.failed:
                return durationDisplay
            default:
                return ''
        }
    }, [duration, startDate, status])

    return <div>{timeElapsedDisplay}</div>
}
