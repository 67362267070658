import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {PALE_GREY, WHITE} from "../../core/ui/_colors"
import {useStrategyMapSlice} from "../hooks/useStrategyMapSlice"
import {I18n} from "react-i18nify"
import {Modal} from "../../core/ui/modal/Modal"
import TextInput from "../../core/ui/form/TextInput"
import moment from "moment"
import {CalenderInputIcon} from "../../core/ui/DateInput"
import {SideBarContainerWrapper, Title} from "./Node.styles"
import Checkbox from "../../capture/signifier/Checkbox"
import {IconButton} from "../../core/components/Buttons"
import {useNode} from "../hooks/useNode"
import {append, filter, find} from 'ramda'
import {showPopupMessage} from "../../core/utils/notifications"
import {P} from "../../core/ui/generic"
import {Alert, FormGroup, FormLabel} from 'react-bootstrap'
import SelectWrapper from "../../core/components/SelectWrapper"
import { DateRangePicker } from "react-dates"

export const TargetsContainer = styled.div`
    width: 300px;
    min-height: 100px;
    max-height: 250px;
    overflow: scroll;
    padding: 10px;
    position: absolute;
    background: ${WHITE};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    display: ${(p) => (p.open ? 'block' : 'none')};
    transition: display 0.5s;
    z-index: 9999;
    top: 40px;
    left: 0;
`

export const Container = styled.div`
    width: 100%;
    padding: 4px;
    display: flex;
    position: relative;
    align-items: center;
    ${(p) => (p.clickable ? 'cursor: pointer;' : '')}
    transition: background .5s;

    &:hover {
        background: ${PALE_GREY};

        .show-hover {
            display: block;
        }
    }
`

const _FormGroup = styled(FormGroup)`
    margin: 15px 0;

    .DateRangePicker, .DateRangePickerInput {
        width: 100%;
    }
`

const AddStrategicIterationModal = (props) => {
    const {isOpen, onClose, strategicIteration, value} = props
    const {Actions, goal, targets} = useStrategyMapSlice()
    const {isUserSponsor} = useNode()
    const [title, setTitle] = useState("")
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment(moment()).add(goal.cadence, 'M'))
    const [focusedInput, setFocusedInput] = useState(null)
    const [selectedTargets, setSelectedTargets] = useState([])
    const [isEditingTargets, setIsEditingTargets] = useState(false)
    const [validateFormError, setValidateFormError] = useState(false)

    useEffect(() => {
        if (strategicIteration) {
            setTitle(strategicIteration.title)
            setStartDate(moment(strategicIteration.start_date))
            setEndDate(moment(strategicIteration.end_date))
            setSelectedTargets(targets.filter(target => strategicIteration.targets.includes(target.id)))
        }
    }, [strategicIteration])

    useEffect(() => {
        if (strategicIteration) {
            setSelectedTargets(targets.filter(target => strategicIteration.targets.includes(target.id)))
        }
    }, [targets])

    useEffect(() => {
        if (startDate) {
            setEndDate(moment(startDate).add(goal.cadence, 'M'))
        }
    }, [goal.cadence])

    const closeAndResetModal = () => {
        setTitle("")
        setStartDate(moment())
        setEndDate(moment(moment()).add(goal.cadence, 'M'))
        setSelectedTargets([])
        setIsEditingTargets(false)
        setValidateFormError(false)
        onClose()
    }

    const handleTargetClick = (target) => {
        setIsEditingTargets(true)
        const selectedTarget = find(t => t.id === target.value, targets)
        setSelectedTargets(append(selectedTarget, selectedTargets))
    }

    const getConfirmText = () => {
        if (isUserSponsor) {
            return strategicIteration ? 
                    I18n.t('strategyMap.messages.save') : 
                    I18n.t('sidebar.addParent.dialog.buttons.add')
        }
        return false
    }
    
    const targetOptions = targets.filter(target => !find(t => t.id === target.id, selectedTargets) && !target.is_done)
        .map(target => {
            return {
                value: target.id,
                label: target.title
            }
        })

    const customStyles = {
        content: {
            overflow: 'inherit'
        }
    }

    return (
        <Modal 
            isOpen={isOpen} 
            style={customStyles}
            title={strategicIteration ? I18n.t('strategyMap.nodes.editStrategicIteration') : I18n.t('strategyMap.nodes.newStrategicIteration')}
            footer={{
                confirmText: getConfirmText(),
                cancelText: isUserSponsor ? I18n.t('strategyMap.messages.cancel') : I18n.t('strategyMap.messages.close'),
                onConfirm: () => {
                    if (isUserSponsor) {
                        if(title && startDate && endDate && selectedTargets.length > 0) {
                            setValidateFormError(false)
                            const payload = {
                                title,
                                start_date: moment(startDate).format('YYYY-MM-DD'),
                                end_date: moment(endDate).format('YYYY-MM-DD'),
                                targets: selectedTargets.map(t => t.id),
                                goal_id: goal.id
                            }
                            strategicIteration ? 
                                Actions.updateStrategicIteration({...payload, id: strategicIteration.id}) : 
                                Actions.addStrategicIteration(payload)

                            closeAndResetModal()
                        } else {
                            setValidateFormError(true)
                        }
                    }
                },
                onCancel: closeAndResetModal
            }}
        >
            {validateFormError && (
                <Alert variant="danger" className="fade in">
                    {I18n.t('strategyMap.nodes.strategicIterationValidationError')}
                </Alert>
            )}
            <_FormGroup>
                <FormLabel>{I18n.t('strategyMap.nodes.startDate')}/{I18n.t('strategyMap.nodes.endDate')}*</FormLabel>
                
                <DateRangePicker
                    displayFormat={'DD/MM/YYYY'}
                    startDate={startDate}
                    startDateId="startDate"
                    endDate={endDate}
                    endDateId="endDate"
                    isOutsideRange={() => false}
                    onDatesChange={({ startDate, endDate }) => {
                        setStartDate(moment(startDate))
                        setEndDate(moment(endDate))
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => {
                        if(focusedInput === "endDate") {
                            setEndDate(moment(startDate).add(goal.cadence, 'M'))
                        }
                        setFocusedInput(focusedInput)
                    }}
                    customInputIcon={<CalenderInputIcon/>}
                />
                
            </_FormGroup>
            <_FormGroup>
                <TextInput
                    label={I18n.t('strategyMap.nodes.title') + '*'}
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    disabled={!isUserSponsor}
                />
            </_FormGroup>
            <_FormGroup>
                {!targets.length && <P color={'red'}>{I18n.t('strategyMap.nodes.noTargetsStrategicIterationWarning')}</P>}
                <FormLabel>{I18n.t('strategyMap.nodes.addTarget')}*</FormLabel>
                {selectedTargets.map((target) => (
                    <SideBarContainerWrapper key={target.id} clickable style={{marginBottom: "10px"}}>
                        <Checkbox
                            onClick={() => {
                                if(isUserSponsor && !isEditingTargets) {
                                    Actions.updateTarget({...target, is_done: !target.is_done})
                                }
                            }}
                            disabled={!isUserSponsor || isEditingTargets}
                            checked={target.is_done}
                        />
                        <Title
                            style={{marginLeft: 10}}
                        >
                            {target.title}
                        </Title>
                        {isUserSponsor && <IconButton
                            displayBlock={false}
                            onClick={() => {
                                setSelectedTargets(filter(t => t.id !== target.id, selectedTargets))
                            }}
                            icon="clear"
                        />}
                    </SideBarContainerWrapper>
                ))}
                {isUserSponsor && <SelectWrapper
                    searchable={false}
                    options={targetOptions}
                    onChange={handleTargetClick}
                    value={value}
                    clearable={false}
                />}
            </_FormGroup>
        </Modal>
    )
}

const StrategicIterations = (props) => {
    const {strategicIterations, Actions} = useStrategyMapSlice()
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedStrategicIteration, setSelectedStrategicIteration] = useState(null)
    const {isUserSponsor} = useNode()

    const handleStrategicIterationDelete = (iterationId) => {
        const warning = {
            title: I18n.t('strategyMap.messages.warning'),
            message: I18n.t('strategyMap.messages.deleteStrategicIterationWarning'),
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.messages.delete'),
            onConfirmCallback: () => Actions.deleteStrategicIteration(iterationId),
            onCancelCallback: () => {
            },
        }
        showPopupMessage(warning)
    }

    return (
        <>
            {strategicIterations.map((iteration) => {
                return (
                    <SideBarContainerWrapper key={iteration.id} clickable>
                        <Title onClick={() => {
                            setModalOpen(!modalOpen)
                            setSelectedStrategicIteration(iteration)
                            }}>
                            {iteration.title}
                        </Title>
                        {isUserSponsor && <IconButton
                            displayBlock={false}
                            onClick={() => {
                                handleStrategicIterationDelete(iteration.id)
                            }}
                            icon="clear"
                        />}
                    </SideBarContainerWrapper>
                )
            })}
            {isUserSponsor && <Container clickable>
                <Title onClick={() => setModalOpen(!modalOpen)}>
                    {I18n.t("strategyMap.nodes.addStrategicIteration")}
                </Title>
            </Container>}
            <AddStrategicIterationModal isOpen={modalOpen} 
                strategicIteration={selectedStrategicIteration} 
                onClose={() => {
                    setModalOpen(false)
                    setSelectedStrategicIteration(null)
                }}/>
        </>
    )
}

export default StrategicIterations