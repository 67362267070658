import React, {useState} from 'react'
import {I18n} from 'react-i18nify'
import {Col} from 'react-bootstrap'
import DesiredOutcomes from '../../core/components/DesiredOutcomes/DesiredOutcomes'
import HeadingWithAction from '../../sidebars/Components/HeadingWithAction'
import {CanvasContainer, Card, CardEditor} from '../../core/ui/ui'
import styled from 'styled-components'
import ClickToEditInput from '../../core/components/ClickToEditInput'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import YQuill from '../../core/components/YQuill'
import {getTenantName} from '../../core/utils/network'
import {useNode} from '../hooks/useNode'

const Canvas = styled.div`
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    z-index: 999;
    background: white;
    padding: 10px;
`

const PlaceholderText = styled.p`
    color: rgba(0, 0, 0, 0.6);
`

const ExperimentCanvasTitle = styled.div`
    padding: 10px 5px 0 25px;
    height: 70px;
`

const ExperimentCanvas = () => {
    const {Actions, nodeActivity} = useStrategyMapSlice()
    const [showNewSuccessCondition, setShowNewSuccessCondition] = useState(true)
    const [showNewFailureCondition, setShowNewFailureCondition] = useState(true)
    const {node, isReadOnly, isEditMode} = useNode()

    const setExperimentCanvasField = (text, fieldName) => {
        if (isEditMode) {
            Actions.updateNode({
                id: node.id,
                resourcetype: node.resourcetype,
                [fieldName]: text,
            })
        } else {
            Actions.updatePendingNode({
                [fieldName]: text,
            })
        }
    }

    const toggleAddSuccessCondition = () => {
        setShowNewSuccessCondition(!showNewSuccessCondition)
    }

    const toggleAddFailureCondition = () => {
        setShowNewFailureCondition(!showNewFailureCondition)
    }

    const handleDesiredOutcomeChange = (action, property) => {
        const desiredOutcome = action.desiredOutcome
        const type = action.type
        const position = action.position
        if (type === 'DELETE') {
            node[property].splice(position, 1)
        } else if (type === 'ADD') {
            if (!node[property]) {
                node[property] = []
            }
            node[property].push(desiredOutcome)
        } else {
            node[property][position] = {...desiredOutcome, id: action.oldDesiredOutcome.id}
        }

        if (isEditMode) {
            if (type === 'DELETE') {
                if (property === 'experiment_success_conditions') {
                    Actions.deleteExperimentSuccessCondition(action.desiredOutcome.id)
                } else {
                    Actions.deleteExperimentFailureCondition(action.desiredOutcome.id)
                }
            } else {
                Actions.updateNode({...node})
            }
        } else {
            Actions.updatePendingNode({[property]: node[property]})
        }
    }

    return (
        <Canvas>
            <CanvasContainer>
                <ExperimentCanvasTitle>
                    <ClickToEditInput
                        node={node}
                        text={node.title}
                        onTextChange={(text) => {
                            setExperimentCanvasField(text, 'title')
                        }}
                        placeholderText={I18n.t('strategyMap.experiment.expTitlePlaceholder')}
                        isReadOnly={isReadOnly}
                        value={node.title}
                        nodeActivity={nodeActivity[node.id]}
                    />
                </ExperimentCanvasTitle>
                <Col md={4}>
                    <CardEditor>
                        <HeadingWithAction
                            title={I18n.t('strategyMap.experimentCanvas.learningObjectives')}
                        />
                        <YQuill
                            nodeId={node.id}
                            key={'learning_objectives' + node.id}
                            text={node.learning_objectives}
                            handleTextChange={(text) => {
                                setExperimentCanvasField(text, 'learning_objectives')
                            }}
                            roomName={getTenantName() + 'learning_objectives' + node.id}
                            isReadOnly={isReadOnly}
                            placeholder={I18n.t(
                                'strategyMap.experimentCanvas.learningObjectivesPlaceholder',
                            )}
                        />
                    </CardEditor>
                    <CardEditor>
                        <HeadingWithAction
                            title={I18n.t('strategyMap.experimentCanvas.contentBackground')}
                        />
                        <YQuill
                            nodeId={node.id}
                            key={'content_background' + node.id}
                            text={node.content_background}
                            handleTextChange={(text) => {
                                setExperimentCanvasField(text, 'content_background')
                            }}
                            roomName={getTenantName() + 'content_background' + node.id}
                            isReadOnly={isReadOnly}
                            placeholder={I18n.t(
                                'strategyMap.experimentCanvas.contentBackgroundPlaceholder',
                            )}
                        />
                    </CardEditor>
                    <CardEditor>
                        <HeadingWithAction title={I18n.t('strategyMap.nodes.description')} />
                        <YQuill
                            nodeId={node.id}
                            key={'description' + node.id}
                            text={node.description}
                            handleTextChange={(text) => {
                                setExperimentCanvasField(text, 'description')
                            }}
                            roomName={getTenantName() + 'description' + node.id}
                            isReadOnly={isReadOnly}
                            placeholder={I18n.t(
                                'strategyMap.experimentCanvas.descriptionPlaceholder',
                            )}
                        />
                    </CardEditor>
                </Col>
                <Col md={4}>
                    <Card>
                        <HeadingWithAction
                            title={I18n.t('strategyMap.experimentCanvas.successConditions')}
                            showAction={!isReadOnly}
                            onClick={toggleAddSuccessCondition}
                        />
                        {node.experiment_success_conditions &&
                            node.experiment_success_conditions.length === 0 && (
                                <PlaceholderText className="placeholder success_conditions">
                                    {I18n.t(
                                        'strategyMap.experimentCanvas.successConditionsPlaceholder',
                                    )}
                                </PlaceholderText>
                            )}
                        <DesiredOutcomes
                            id={'experiment_success_conditions'}
                            desiredOutcomes={node.experiment_success_conditions || []}
                            onDesiredOutcomeChange={(action) => {
                                handleDesiredOutcomeChange(action, 'experiment_success_conditions')
                            }}
                            showAddDesiredOutcome={showNewSuccessCondition}
                            isReadOnly={isReadOnly}
                            showAddDesiredOutcomeIfEmpty={false}
                            placeholder="Add Success Condition"
                        />
                    </Card>
                    <CardEditor>
                        <HeadingWithAction
                            title={I18n.t('strategyMap.experimentCanvas.amplifyingActions')}
                        />
                        <YQuill
                            nodeId={node.id}
                            key={'amplifying_actions' + node.id}
                            text={node.amplifying_actions}
                            handleTextChange={(text) => {
                                setExperimentCanvasField(text, 'amplifying_actions')
                            }}
                            roomName={getTenantName() + 'amplifying_actions' + node.id}
                            isReadOnly={isReadOnly}
                            placeholder={I18n.t(
                                'strategyMap.experimentCanvas.amplifyingActionsPlaceholder',
                            )}
                        />
                    </CardEditor>
                </Col>
                <Col md={4}>
                    <Card>
                        <HeadingWithAction
                            title={I18n.t('strategyMap.experimentCanvas.failureConditions')}
                            showAction={!isReadOnly}
                            onClick={toggleAddFailureCondition}
                        />
                        {node.experiment_failure_conditions &&
                            node.experiment_failure_conditions.length === 0 && (
                                <PlaceholderText className="placeholder failure_conditions">
                                    {I18n.t(
                                        'strategyMap.experimentCanvas.failureConditionsPlaceholder',
                                    )}
                                </PlaceholderText>
                            )}
                        <DesiredOutcomes
                            id={'experiment_failure_conditions'}
                            desiredOutcomes={node.experiment_failure_conditions || []}
                            onDesiredOutcomeChange={(action) => {
                                handleDesiredOutcomeChange(action, 'experiment_failure_conditions')
                            }}
                            showAddDesiredOutcome={showNewFailureCondition}
                            isReadOnly={isReadOnly}
                            showAddDesiredOutcomeIfEmpty={false}
                            placeholder="Add Failure Condition"
                        />
                    </Card>

                    <CardEditor>
                        <HeadingWithAction
                            title={I18n.t('strategyMap.experimentCanvas.dampeningActions')}
                        />
                        <YQuill
                            nodeId={node.id}
                            key={'dampening_actions' + node.id}
                            text={node.dampening_actions}
                            handleTextChange={(text) => {
                                setExperimentCanvasField(text, 'dampening_actions')
                            }}
                            roomName={getTenantName() + 'dampening_actions' + node.id}
                            isReadOnly={isReadOnly}
                            placeholder={I18n.t(
                                'strategyMap.experimentCanvas.dampeningActionsPlaceholder',
                            )}
                        />
                    </CardEditor>
                </Col>
            </CanvasContainer>
        </Canvas>
    )
}

export default ExperimentCanvas
