import React, { useEffect } from 'react'
import SelectWrapper from '../../core/components/SelectWrapper';
import { useCoreSlice } from '../../core/hooks/useCoreSlice';
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';
import {RESTRICTIONS} from "../../core/const";

export const TeamSelector = (props) => {
    const {teams, Actions: CoreActions} = useCoreSlice();
    const user = useUserSlice();

    const teamsAvailable = teams
        .filter( t => RESTRICTIONS.Leadership.RULES.CAN_SEE_ALL_TEAMS.some(role => user[role] ) || user.teams.includes( t.id ) )
        .filter( t => !t.disabled )
        .map(t => ({value: t.id, label: "Team: " + t.name}));
    
    let selectOptions = []
    
    if( RESTRICTIONS.Leadership.RULES.CAN_SEE_ALL_TEAMS.some(role => user[role] ) ){
        selectOptions.push({value: '', label: "All teams"});
        selectOptions.push({value: null, label: "No team"});
    }
    
    selectOptions = [...selectOptions, ...teamsAvailable];
    
    useEffect(() => {
        CoreActions.fetchTeams();
    }, [])

    if (teamsAvailable.length === 0)
        return null;

    return <SelectWrapper
        minimalistView
        onChange={ props.onChange }
        defaultValue={ selectOptions.length ? selectOptions[0] : null }
        options={ selectOptions }
    />
}