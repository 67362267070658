import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'

import {useAnalysisSlice} from '../hooks/useAnalysisSlice'
import {countBy, path, pipe, prop, sortBy, toPairs, values, zip} from 'ramda'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {useSelector} from 'react-redux'
import PieChart from '../../Charts/Piechart/PieChart'
import OverviewGraph from './OverviewGraph'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {BRIGHT_GREY} from '../../core/ui/_colors'
import {tenureColor} from '../../Charts/ChartColorSchema'
import {truncateLabel} from '../../core/utils/string'
import {
    decisionsFiltered
} from '../../dashboard/components/DecisionFilter/Dashboard.functions'
import { Panel } from '../../core/components/BootstrapWrapper'

const Heading = styled.div`
    font-size: 24px;
    font-weight: bold;
`

const Table = styled.table`
    width: 50%;
    margin-top: 10px;
`

const Td = styled.td`
    border-top: 1px solid ${BRIGHT_GREY};
    text-align: right;
`

const _Col = styled(Col)`
    overflow-y: auto;
`

const useTranslatedLabels = (departments, decisionsPerTenure, userCountPerTenure) => {
    const currentLanguage = useSelector((state) => state.language)['current']

    const fullDepartments = useSelector((state) => state.demographics)['department']
    const fullTenures = useSelector((state) => state.demographics)['years']

    const getFullDepartment = (name) =>
        fullDepartments && fullDepartments.find((dp) => dp.name === name)
    const getFullTenureFromPk = (pk) =>
        fullTenures && fullTenures.find((tn) => tn.pk === parseInt(pk))
    const getFullTenureFromName = (name) =>
        fullTenures && fullTenures.find((tn) => tn.name === name)

    const departmentLabels = toPairs(departments).map(([pk, {name}]) => {
        return `${prop(currentLanguage, getFullDepartment(name))}`
    })
    const tenureLabels = decisionsPerTenure.map(([pk, count]) => {
        return `${prop(currentLanguage, getFullTenureFromPk(pk))}`
    })
    const userCountPerTenureLabels = userCountPerTenure.map(({years__name, count}) => {
        const fullTenure = getFullTenureFromName(years__name)
        return `${prop(currentLanguage, fullTenure)}`
    })
    return {departmentLabels, tenureLabels, userCountPerTenureLabels}
}

export const getColorScheme = (size) => {
    const colorSchemePie = []
    const tenureColorArr = Object.values(tenureColor)
    let tenureColorIndex = 0
    for (let i = 0; i < size; i++) {
        colorSchemePie.push(tenureColorArr[tenureColorIndex])
        tenureColorIndex++
        if (tenureColorIndex >= size) {
            tenureColorIndex = 0
        }
    }
    return colorSchemePie
}

const Overview = () => {
    const {
        departments,
        activeCapturingUsersCount,
        affinity,
        endDate,
        licenseType,
        neverLoggedInUsersCount,
        startDate,
        totalDecisionCount,
        wholeCompanyPeopleCount,
        userCountPerTenure,
    } = useAnalysisSlice()
    const {decisions, filters} = useDashboardSlice()
    const getDecisionTenure = path(['demographics', 'years'])
    const [decisionsPerTenure, setDecisionsPerTenure] = useState([])
    const [userCountPerTenureFiltered, setUserCountPerTenureFiltered] = useState(userCountPerTenure)
    const [filteredDepartments, setFilteredDepartments] = useState(departments)

    const {departmentLabels, tenureLabels, userCountPerTenureLabels} = useTranslatedLabels(
        filteredDepartments,
        decisionsPerTenure,
        userCountPerTenureFiltered,
    );

    const removeLabelNumeration = (label) => label.replace(/[\d][)][\s]/, '')

    const arrayIsEmpty = (arr) => arr.length === 0 || arr.every((item) => item === 0)

    const decisionsPerDepartment =
        toPairs(filteredDepartments).map(
            ([key, val]) => val['decisionDepartmentCount']
        );

    const numberCapturedDecisions = toPairs(filteredDepartments).map(
        ([key, val]) => val['peopleCapturedCount'],
    )
    const tenureDistribution = decisionsPerTenure.map(([pk, count]) => count)
    const tenureUserDistribution = userCountPerTenureFiltered.map(({count}) => count)
    const headcountPerDepartment = toPairs(filteredDepartments).map(
        ([key, val]) => val['peopleCount'],
    )

    const getFilteredDepartemnt = () => {
        const data = {}
        let selectedIds = Object.keys(departments)
        if (filters.department && filters.department.length > 0) {
            selectedIds = filters.department.map((a) => a.pk)
        }
        selectedIds.forEach((id) => {
            data[id] = {...departments[id], decisionDepartmentCount: 0}
        })
        const filteredDecisions = decisionsFiltered(decisions, filters)
        filteredDecisions.forEach((decision) => {
            if (decision.demographics && data[decision.demographics.department]) {
                data[decision.demographics.department].decisionDepartmentCount += 1
            }
        })
        setFilteredDepartments(data)
    }

    useEffect(() => {
        setUserCountPerTenureFiltered(userCountPerTenure)
        setFilteredDepartments(departments)
    }, [userCountPerTenure, departments])

    useEffect(() => {
        if (filters.years && filters.years.length > 0) {
            const yearsFilters = filters.years.map((a) => a.name)
            const yearsFiltered = userCountPerTenure.filter((tenure) =>
                yearsFilters.includes(tenure.years__name),
            )
            setUserCountPerTenureFiltered(yearsFiltered)
        } else {
            setUserCountPerTenureFiltered(userCountPerTenure)
        }
        setDecisionsPerTenure(
            pipe(
                countBy,
                toPairs,
                sortBy(prop(0)),
            )(getDecisionTenure, decisionsFiltered(decisions, filters))
        )
        getFilteredDepartemnt()
    }, [decisions, filters])

    return (
        <>
            <Panel>
                <Row>
                    <OverviewGraph />
                </Row>
            </Panel>
            <Panel data-testid={'overview-graphs'}>
                <Row style={{marginBottom: 20}}>
                    <Col sm="6" md="6" lg="6">
                        <Heading>{I18n.t('analysis.overview.orgscanData')}</Heading>

                        <Table>
                            <tbody>
                            {
                                [
                                    { tag: I18n.t('analysis.overview.affinity'), value: affinity },
                                    { tag: I18n.t('analysis.overview.peopleCompanyCount'), value: wholeCompanyPeopleCount },
                                    { tag: I18n.t('analysis.overview.totalDecisionCount'), value: totalDecisionCount },
                                    { tag: I18n.t('analysis.overview.startDate'), value: startDate },
                                    { tag: I18n.t('analysis.overview.endDate'), value: endDate },
                                    { tag: I18n.t('analysis.overview.neverLoggedInCount'), value: neverLoggedInUsersCount },
                                    { tag: I18n.t('analysis.overview.activelyCapturingUsersCount'), value: activeCapturingUsersCount },
                                    { tag: I18n.t('analysis.overview.licenseType'), value: licenseType },
                                ].map( (entry,id) =>
                                    <tr key={id} >
                                        <td>{ entry.tag }</td>
                                        <td style={{textAlign: "right", marginLeft: 20}}>{ entry.value }</td>
                                    </tr>)
                            }
                            </tbody>
                        </Table>
                    </Col>
                    <Col sm="6" md="6" lg="6">
                        <Heading>{I18n.t('analysis.overview.departments')}</Heading>

                        <Table>
                            <thead>
                                <tr style={{paddingLeft: 10}}>
                                    <th>#</th>
                                    <th style={{paddingLeft: 10}}>{I18n.t('analysis.overview.decisions')}</th>
                                    <th style={{paddingLeft: 10}}>{I18n.t('analysis.overview.people')}</th>
                                    <th style={{paddingLeft: 10}}>{I18n.t('analysis.overview.ideal')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {zip(departmentLabels, values(filteredDepartments)).map(
                                    ([label, dep], key) =>
                                        <tr key={key}>
                                            <th scope="col">
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={
                                                        <Tooltip id={`tooltip-${key}`} style={{opacity: 1}}>
                                                            <b>
                                                                {removeLabelNumeration(label)}
                                                            </b>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>
                                                        { truncateLabel( removeLabelNumeration( label ), 10 ) }
                                                    </span>
                                                </OverlayTrigger>
                                            </th>
                                            <Td>{dep['decisionDepartmentCount']}</Td>
                                            <Td>{dep['peopleCount']}</Td>
                                            <Td>
                                                {Math.round(
                                                    dep['decisionDepartmentCountIdeal'],
                                                )}
                                            </Td>
                                        </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    {!arrayIsEmpty(decisionsPerDepartment) && (
                        <_Col sm="6" md="6" lg="6">
                            <Heading>{I18n.t('analysis.overview.decisionsPerDepartment')}</Heading>
                            <PieChart
                                testId={'pie-chart-decisionsPerDepartment'}
                                colorScheme={getColorScheme(decisionsPerDepartment.length)}
                                labels={departmentLabels}
                                values={decisionsPerDepartment}
                            />
                        </_Col>
                    )}
                    {!arrayIsEmpty(headcountPerDepartment) && (
                        <_Col sm="6" md="6" lg="6">
                            <Heading>{I18n.t('analysis.overview.headcountPerDepartment')}</Heading>
                            <PieChart
                                testId={'pie-chart-headcountPerDepartment'}
                                colorScheme={getColorScheme(headcountPerDepartment.length)}
                                labels={departmentLabels}
                                values={headcountPerDepartment}
                            />
                        </_Col>
                    )}
                    {!arrayIsEmpty(numberCapturedDecisions) && (
                        <_Col sm="6" md="6" lg="6">
                            <Heading>{I18n.t('analysis.overview.numberCapturedDecision')}</Heading>
                            <PieChart
                                testId={'pie-chart-numberCapturedDecision'}
                                colorScheme={getColorScheme(numberCapturedDecisions.length)}
                                labels={departmentLabels}
                                values={numberCapturedDecisions}
                            />
                        </_Col>
                    )}
                    {!arrayIsEmpty(tenureDistribution) && (
                        <_Col sm="6" md="6" lg="6">
                            <Heading>{I18n.t('analysis.overview.tenureDistribution')}</Heading>
                            <PieChart
                                testId={'pie-chart-tenureDistribution'}
                                colorScheme={getColorScheme(tenureDistribution.length)}
                                labels={tenureLabels}
                                values={tenureDistribution}
                            />
                        </_Col>
                    )}
                    {!arrayIsEmpty(tenureUserDistribution) && (
                        <_Col sm="6" md="6" lg="6">
                            <Heading>{I18n.t('analysis.overview.tenureUserDistribution')}</Heading>
                            <PieChart
                                testId={'pie-chart-tenureUserDistribution'}
                                colorScheme={getColorScheme(tenureUserDistribution.length)}
                                labels={userCountPerTenureLabels}
                                values={tenureUserDistribution}
                            />
                        </_Col>
                    )}
                </Row>
            </Panel>
        </>
    )
}

export default React.memo(Overview)
