import styled from 'styled-components'
import {Col} from 'react-bootstrap'
import {LIGHT_GREY, PALE_GREY} from "../../../core/ui/_colors";

export const TriangleContainerStyled = styled(Col)`
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (min-width: 992px) {
        padding-right: 20px;
        &:nth-child(2n + 1) {
            padding-left: 0;
        }
        &:nth-child(2n + 2) {
            padding-right: 0;
        }
    }

    @media (min-width: 1200px) {
        padding-right: ${p => p.fullWidth ? ' 0 !important': '20px !important'};
        &:nth-child(3n + 3) {
            padding-right: 0 !important;
        }
        &:nth-child(3n + 1) {
            padding-left: 0 !important;
        }
    }
    
`

export const TriangleBox = styled.div`
    height: 480px !important;
    position: relative;
    padding: 0 !important;
    &.field-error {
        height: 500px !important;
    }
    box-shadow: rgb(0 0 0 / 30%) 0px 3px 10px;
    background-color: white;
`
export const Header = styled.div`
    color: ${(p) => p.titleColour};
    width: 100%;
    padding: 20px 15px 10px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    min-height: 105px;
`
export const Body = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
`
export const Footer = styled.div`
    bottom: 0;
    width: 100%;
    position: absolute;
    padding-right: 15px;
    padding-left: 15px;
	align-items: center;
    display: flex;

    height: 59px;
    border-top: 1px solid ${LIGHT_GREY};
    margin-bottom: 0;
    background-color: ${PALE_GREY};
`

export const InfoTriangles = styled.div`
    width: 100%;
    padding: 5%;
    font-size: 16px;
    line-height: 24px;
    margin-top: 60px;
    margin-bottom: 10px;

    @media (min-width: 576px) {
        padding: 0;
        margin: 30px auto;
    }
    @media (min-width: 992px) {
        margin-bottom: 15px;
    }
    @media (min-width: 1200px) {
        width: 720px;
        margin: 30px 0 15px;
    }
`

export const Triangle = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;
    margin-bottom: 35px;
    &.disabled {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
`

export const TriangleImage = styled.div`
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 194px;
    width: 220px;
    position: relative;
    margin: auto;
`

export const BottomLabel = styled.div`
    height: auto;
    margin: 0 auto 20px auto;
`

const BS = styled.div`
    margin-top: 10px;
    width: calc( 50% - 3px);
`

export const BSLeft = styled(BS)`
    float: left;
    text-align: left;
    padding-right: 10px;
`

export const BSRight = styled(BS)`
    float: right;
    text-align: right;
    padding-left: 10px;
`

export const DraggableItem = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    background-size: 100%;
    background: no-repeat center;
    opacity: 0.7;
    &.dragged {
        opacity: 1;
    }
    &.not-app {
        transform: translate(124px, 145px);
        opacity: 0.7;
    }
    &:hover {
        cursor: pointer;
    }
    .position {
        display: none;
    }
`
