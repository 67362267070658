import {useEffect, useState} from 'react'

export const usePrevious = (nextState) => {
    const [states, setStates] = useState([null, null])
    const [previousState, currentState] = states

    useEffect(() => {
        setStates([currentState, nextState])
    }, [nextState])

    return previousState
}
