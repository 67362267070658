import {all, call, put, takeEvery} from 'redux-saga/effects';

import Api from '../api';
import Raven from 'raven-js';
import {userSlice} from '../../auth/redux/user.slices';
import { coreSlice } from '../../core/redux/slices';
import history from '../../core/utils/history';
import {strategyMapSlice} from '../../strategyMap/redux/slices';
import {I18n} from 'react-i18nify';
import * as ErrorUtils from '../../core/utils/error';
import {consoleError} from '../../core/utils/error';
import {setPassword, updateUserProfile} from '../../sidebars/UserProfileContainer/UserProfileContainer.actions';
import {displayToastMessage} from '../../core/utils/notifications';
import u2F from "../u2F";
import {isPublicInstance} from "../../core/utils/network";
import { httpFailStatusCodes } from '../../core/components/InitialData';

function* getUser() {

    if( isPublicInstance() )
        yield put(userSlice.actions.getUserSuccess({}));
    else
        try {
            const response = yield call(Api.getUser);
            const user = response.data;

            yield put(strategyMapSlice.actions.updateNodeMapUserData(user));
            yield put(userSlice.actions.getUserSuccess(user));

            yield put(coreSlice.actions.updateDashboardWidgets(user.dashboard));
            yield put(coreSlice.actions.updateDashboardOrder(user.dashboardOrder));
        } catch (error) {
            consoleError(error);
            yield put(userSlice.actions.requestFailure(error));
    }
}

function* updateUserSaga(action) {
    try {
        const response = yield call(updateUserProfile, action.payload)
        yield put(userSlice.actions.updateUserSuccess(response.data))
        yield put(userSlice.actions.getUser())
        displayToastMessage('success', I18n.t('profile.ResultFlashMessage.ProfileOK'))
    } catch (error) {
        consoleError(error)
        if (error.response && error.response.status !== 400) {
            Raven.captureException('updateUser', {error})
        }
    }
}

function* setPasswordSaga(action) {
    try {
        const response = yield call(setPassword, action.payload)
        yield put(userSlice.actions.setPasswordSuccess(response.data))
        displayToastMessage('success', I18n.t('profile.ResultFlashMessage.PasswordOK'))
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.requestFailure(error))
        if (error.response && error.response.status !== 400) {
            Raven.captureException('setPassword', {error})
        }
    }
}





function* updateUsersSaga(action) {
    let successMessage = ''
    let errorMessage = ''
    let apiCall = null

    switch (action.payload.updateType) {
        case 'deactivateUsers':
            successMessage = I18n.t('billing.Warning.UserDeactivateSuccess');
            errorMessage = I18n.t('billing.Warning.UserDeactivateError');
            apiCall = Api.deactivateUsers;
            break;
        case 'activateUsers':
            successMessage = I18n.t('billing.Warning.UserActivateSuccess');
            errorMessage = I18n.t('billing.Warning.UserActivateError');
            apiCall = Api.activateUsers;
            break;
        case 'resendInvitation':
            successMessage = I18n.t('billing.Warning.ResendInviteSuccess');
            apiCall = Api.resendInvitation;
            break;
        case 'makeUserStrategist':
            successMessage = I18n.t('billing.Warning.MakeUserStrategistSuccess');
            errorMessage = I18n.t('billing.Warning.MakeUserStrategistError');
            apiCall = Api.makeUserStrategist;
            break;
        case 'revokeUserStrategist':
            successMessage = I18n.t('billing.Warning.RevokeUserStrategistSuccess');
            errorMessage = I18n.t('billing.Warning.RevokeUserStrategistError');
            apiCall = Api.revokeUserStrategist;
            break;
        case 'makeUserAdmin':
            successMessage = I18n.t('billing.Warning.MakeUserAdminSuccess');
            errorMessage = I18n.t('billing.Warning.MakeUserAdminError');
            apiCall = Api.makeUserAdmin;
            break;
        case 'revokeUserAdmin':
            successMessage = I18n.t('billing.Warning.RevokeUserAdminSuccess');
            errorMessage = I18n.t('billing.Warning.RevokeUserAdminError');
            apiCall = Api.revokeUserAdmin;
            break;
        case 'makeUserAdminLeadership':
            successMessage = I18n.t('billing.Warning.MakeUserAdminSuccess');
            errorMessage = I18n.t('billing.Warning.MakeUserAdminError');
            apiCall = Api.makeUserAdminLeadership;
            break;
        case 'revokeUserAdminLeadership':
            successMessage = I18n.t('billing.Warning.RevokeUserAdminSuccess');
            errorMessage = I18n.t('billing.Warning.RevokeUserAdminError');
            apiCall = Api.revokeUserAdminLeadership;
            break;
        case 'makeUserAnalyst':
            successMessage = I18n.t('billing.Warning.MakeUserAnalystSuccess');
            errorMessage = I18n.t('billing.Warning.MakeUserAnalystError');
            apiCall = Api.makeUserAnalyst;
            break;
        case 'revokeUserAnalyst':
            successMessage = I18n.t('billing.Warning.RevokeUserAnalystSuccess');
            errorMessage = I18n.t('billing.Warning.RevokeUserAnalystError');
            apiCall = Api.revokeUserAnalyst;
            break;
    }

    try {
        yield call(apiCall, action.payload)
        yield put(userSlice.actions.updateUsersSuccess())
        yield put(userSlice.actions.fetchUsers())
        displayToastMessage('success', successMessage)
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.requestFailure(error))
        displayToastMessage('error', errorMessage)
    }
}

function* inviteUser(action) {
    try {
        yield call(Api.inviteUser, action.payload)
        yield put(userSlice.actions.inviteUserSuccess())
        yield put(userSlice.actions.fetchUsers())
        displayToastMessage('success', I18n.t('billing.Warning.UserCreateSuccess'))
    } catch (error) {
        consoleError(error)
        const resp = yield put(userSlice.actions.requestFailure(error))
        displayToastMessage(
            'error',
            Object.values(resp.payload.response.data).join(','),
            I18n.t('billing.Warning.UserCreateError'),
        )
    }
}

function* bulkCreateUsers(action) {
    try {
        const resp = yield call(Api.bulkCreateUsers, action.payload)
        yield put(userSlice.actions.bulkCreateUsersSuccess())
        yield put(userSlice.actions.fetchUsers())
        displayToastMessage('success', resp.data.message)
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.requestFailure(error))
    }
}

function* saveProfilePic(action) {
    try {
        const resp = yield call(Api.saveProfilePic, action.payload)
        yield put(userSlice.actions.saveProfilePicSuccess())
        yield put(userSlice.actions.getUser())
        displayToastMessage('success', resp.data.message)
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.requestFailure(error))
    }
}

function* fetchUsers(action) {
    try {
        const response = yield call(Api.fetchUsers, action.payload)
        yield put(userSlice.actions.fetchUsersSuccess(response))
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.requestFailure(error))
    }
}

function* logout() {
    try {
        yield call( Api.removeToken );
        yield localStorage.removeItem( 'token' );
        yield put( coreSlice.actions.closeSidebar() );
        history.push( '/login' );
    } catch( error ){
        consoleError( error );
        if( error.response && ! httpFailStatusCodes.includes( error.response ) )
            Raven.captureException( error );
    }
}

function* resetPasswordSaga(action) {
    try {
        const response = yield call(Api.resetPassword, action.payload)
        if (response.status === 204) {
            yield put(userSlice.actions.resetPasswordSuccess())
        }
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.requestFailure(error))
        if (error.response && error.response.status !== 400) {
            Raven.captureException('resetPassword', {error})
        }
    }
}

function* resetPasswordConfirmSaga(action) {
    try {
        const response = yield call(Api.resetPasswordConfirm, action.payload)
        yield put(userSlice.actions.resetPasswordConfirmSuccess(response))
    } catch (error) {
        consoleError(error)
        yield put(userSlice.actions.resetPasswordConfirmFailure(error))
        if (error.response && error.response.status !== 400) {
            Raven.captureException('resetPasswordConfirm', {error})
        }
    }
}

function* removeU2F(){
    try{
        yield call(Api.removeU2FCredentials);
        yield put(userSlice.actions.removeU2FSuccess())
        displayToastMessage('success', I18n.t('app.u2f.action.removedSuccess'));
    } catch (error) {
        consoleError(error);
        displayToastMessage('error', I18n.t('app.u2f.action.removedFailure'));
        if (error.response && error.response.status !== 400) {
            Raven.captureException('removeU2F', {error});
        }
    }
}
function* storeU2FCredentials(action){
    try{
        const secondAuthentication = u2F();
        const pairData = yield call(secondAuthentication.pairDongle, action.payload);

        yield call(Api.storeU2FCredentials, pairData);
        yield put(userSlice.actions.storeU2FCredentialsSuccess());
        displayToastMessage('success', I18n.t('app.u2f.action.addingSuccess'));
    } catch (error) {
        consoleError(error)
        displayToastMessage('error', I18n.t('app.u2f.action.addingFailure'));
        if (error.response && error.response.status !== 400) {
            Raven.captureException('removeU2F', {error});
        }
    }
}

function* fetchTokenSaga(action) {
    try {
        const tokenResponse = yield call( Api.fetchToken, action.payload );

        const userBlob = yield call( Api.fetchU2FUserBlob, tokenResponse.data.auth_token );
        let valid;
        if (userBlob.status === 200 && userBlob.data.length) {
            const secondAuthentication = u2F();
            const token = yield call( secondAuthentication.validate, userBlob.data);

            const validCheck = yield call(Api.checkU2FToken, tokenResponse.data.auth_token, token);
            if( validCheck.status === 200 && ! validCheck.data) {
                yield put(userSlice.actions.requestFailure("u2F failed.. wrong key"));
            } else {
                localStorage.setItem('token', tokenResponse.data.auth_token);
                valid = true;
            }
        } else {
            localStorage.setItem('token', tokenResponse.data.auth_token);
            valid = true;
        }

        if( valid ){
            yield put( userSlice.actions.fetchTokenSuccess( tokenResponse.data ) );
            if( ! isPublicInstance() ) {
                const securityPolicyResponse = yield call(Api.getSecurityPolicy);
                if (securityPolicyResponse && securityPolicyResponse.data)
                    displayToastMessage('warning', securityPolicyResponse.data.message, '', 15000);
            }
        }
    } catch (error) {
        consoleError(error);
        yield put(userSlice.actions.requestFailure(error));
    }
}

function* checkAuthentication(action) {
    const error = action.payload
    if (error) {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            yield put(userSlice.actions.logout())
            history.push('/login')
        } else {
            if (error.response && error.response.status !== 400) {
                Raven.captureException(error)
            }
            const info = ErrorUtils.getErrorMessages(error)
            if (info.length > 0 && ! [404,400].includes( error.response.status ) ) {
                displayToastMessage('error', info.join('\n'))
            }
        }
    }
}

export default function* flow() {
    yield all([
        takeEvery(userSlice.actions.fetchUsers, fetchUsers),
        takeEvery(userSlice.actions.resetPassword, resetPasswordSaga),
        takeEvery(userSlice.actions.resetPasswordConfirm, resetPasswordConfirmSaga),
        takeEvery(userSlice.actions.updateUser, updateUserSaga),
        takeEvery(userSlice.actions.fetchToken, fetchTokenSaga),
        takeEvery(userSlice.actions.setPassword, setPasswordSaga),
        takeEvery(userSlice.actions.getUser, getUser),
        takeEvery(userSlice.actions.updateUsers, updateUsersSaga),
        takeEvery(userSlice.actions.inviteUser, inviteUser),
        takeEvery(userSlice.actions.bulkCreateUsers, bulkCreateUsers),
        takeEvery(userSlice.actions.saveProfilePic, saveProfilePic),
        takeEvery(userSlice.actions.logout, logout),
        takeEvery(userSlice.actions.removeU2F, removeU2F),
        takeEvery(userSlice.actions.storeU2FCredentials, storeU2FCredentials),
        takeEvery('*', checkAuthentication),
    ])
}
