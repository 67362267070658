import {useEffect, useMemo} from 'react'
import {debounce} from 'lodash'

export function useDebouncedCallback(callback, wait) {
    const debouncedChangeHandler = useMemo(
        () => {
            return debounce(callback, wait)
        }
        , [])
    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel()
        }
    }, [])

    return debouncedChangeHandler
}
