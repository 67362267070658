import React from 'react'
import {I18n} from 'react-i18nify'
import { Panel } from '../core/components/BootstrapWrapper'
import { RESTRICTIONS, LEADERSHIP_ASSESSMENT_PRICES } from '../core/const'
import { _Table } from './Capture.styles'

export const isFeatureEnabled = (feature) => feature ? "✅" : "";

const PriceComparisonTable = (props) => {
    const comparisons = [
        {
            label: I18n.t('capture.compareTable.feature_4'), 
            free: true, team: true, full: true 
        },
        {
            label: I18n.t('capture.compareTable.feature_5'), 
            free: true, team: true, full: true 
        },
        {
            label: I18n.t('capture.compareTable.feature_6'), 
            free: true, team: false, full: false 
        },
        {
            label: I18n.t('capture.compareTable.feature_7'), 
            free: false, team: true, full: true 
        },
        {
            label: I18n.t('capture.compareTable.feature_8'), 
            free: false, team: true, full: true 
        },
        {
            label: I18n.t('capture.compareTable.feature_9'), 
            free: false, team: true, full: true 
        },
        {
            label: I18n.t('capture.compareTable.feature_10')
                    .replace(':max_teams:', RESTRICTIONS.Leadership.LEADERSHIP_STYLE_COMPARISON),
            free: false, team: false, full: true 
        },
        {
            label: I18n.t('capture.compareTable.feature_11'), 
            free: false, team: false, full: true 
        }
    ];

    return (<Panel>
            <_Table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{I18n.t('capture.compareTable.FreeLeadershipAssessmentLite')}</th>
                        <th>{I18n.t('capture.compareTable.TeamVersion')
                                .replace(':max_users:', RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.TEAM_VERSION)
                                }</th>
                        <th>{I18n.t('capture.compareTable.FullVersion')
                                .replace(':max_users:', RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.TEAM_VERSION * RESTRICTIONS.Leadership.MAX_TEAMS_ALLOWED.FULL_VERSION)
                                .replace(':max_teams:', RESTRICTIONS.Leadership.MAX_TEAMS_ALLOWED.FULL_VERSION)
                                }</th>
                    </tr>
                </thead>
                <tbody>
                    {comparisons.map((comparison, key) => (<tr key={key}>
                        <td>{comparison.label}</td>
                        <td>{isFeatureEnabled(comparison.free)}</td>
                        <td>{isFeatureEnabled(comparison.team)}</td>
                        <td>{isFeatureEnabled(comparison.full)}</td>
                    </tr>))}
                    <tr>
                        <td><b>{I18n.t('capture.compareTable.Pricing')}</b></td>
                        <td><b>{I18n.t('capture.compareTable.FREE')}</b></td>
                        <td><b>{I18n.t('capture.compareTable.VersionPrice')
                                .replace(':currency:', LEADERSHIP_ASSESSMENT_PRICES.currency)
                                .replace(':price:', LEADERSHIP_ASSESSMENT_PRICES.team)
                                }</b></td>
                        <td><b>{I18n.t('capture.compareTable.VersionPrice')
                                .replace(':currency:', LEADERSHIP_ASSESSMENT_PRICES.currency)
                                .replace(':price:', LEADERSHIP_ASSESSMENT_PRICES.full)
                                }</b></td>
                    </tr>
                </tbody>
            </_Table>
            {props.showDescription && <>
                <br />
                <p dangerouslySetInnerHTML={{__html: I18n.t('capture.compareTable.FreeVersionBannerDescription')}} />
                <ul>
                    <li>{I18n.t('capture.compareTable.FreeVersionBannerDescriptionList1')
                            .replace(':users_total:', RESTRICTIONS.Leadership.MAX_TEAM_MEMBERS_ALLOWED.TEAM_VERSION * RESTRICTIONS.Leadership.MAX_TEAMS_ALLOWED.FULL_VERSION)
                            }</li>
                </ul>
            </>}
        </Panel>)
}

export default PriceComparisonTable