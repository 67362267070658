import React from 'react'
import styled from 'styled-components'
import {PALE_GREY, WHITE} from '../../core/ui/_colors'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const BarItem = styled.div`
    width: 100%;
    display: flex;
    height: 30px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
`

const Bars = styled.div`
    flex: 1;
    display: flex;
    height: 30px;
`

const Bar = styled.div`
    width: ${p => p.width}%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${p => p.color || PALE_GREY} !important;
    -webkit-print-color-adjust: exact;
    height: 100%;
    span {
        color: ${WHITE};
    }
`

const Label = styled.div`
    font-size: 16px;
    padding: 10px 0;
    width: 100px;
    margin-right: 20px;
`

const StackedBars = (props) => {
    const getTotal = (item) => Object
                        .values(item)
                        .reduce(
                            (total, value) => typeof value === 'number' ? total + value : total
                            , 0);
    
    const getPercentage = (item, value) => {
        const total = getTotal(item);
        return (item[value]/total) * 100;
    }

    return (
        <Container data-testid={props.testId || 'stackbar'}>
            {props.data.map((item, i) => (
                <BarItem key={i}>
                <Label>{item.label}</Label>
                <Bars>
                    {Object.keys(item).map((value, k) => {
                        if (value === "label") return null;

                        return (
                            <Bar 
                                key={k} 
                                color={props.colors[value]} 
                                width={getPercentage(item, value)}
                            >
                                {props.percentageData && <span>{props.percentageData[value]}%</span>}
                            </Bar>
                        );
                    })}
                </Bars>
            </BarItem>
            ))}
        </Container>
    )
}
export  default StackedBars