import React, {useEffect} from 'react'
import styled from 'styled-components'
import NodeIcon from '../../strategyMap/components/NodeIcon'
import {getNodeDisplay, isExperiment} from '../../strategyMap/utils'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {useActions} from '../../core/hooks/useActions'
import {strategyMapSlice} from '../../strategyMap/redux/slices'
import {coreSlice} from '../../core/redux/slices'
import {PALE_GREY} from '../../core/ui/_colors'
import {PAGES} from '../../core/const'
import {useHistory} from '../../core/hooks/useHistory'
import {IconButton} from '../../core/components/Buttons'
import { Div } from '../../core/ui/Div'

export const LinkedToListStyled = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0;
`

const LinkToItem = styled.div`
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: ${({autoHeight}) => (autoHeight ? 'auto' : '30px')};
    padding: 3px 0;
    &:hover {
        background: ${PALE_GREY};
        .show-hover {
            display: block;
        }
    }
`

const NodeLabel = styled.div`
    width: ${({withRemoveIcon}) => (withRemoveIcon ? '260px' : '100%')};
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
        background-color: ${PALE_GREY};
    }
`
const NodeIconStyled = styled(NodeIcon)`
    width: 40px;
    margin-left: -10px;
`

export const NodeLinkItem = ({node, removeItem, removable, goals}) => {
    const Actions = useActions(strategyMapSlice.actions)
    const CoreAction = useActions(coreSlice.actions)
    const {isStrategyMapPage} = useHistory()

    const openNodeOnSidebar = (nodeLinkedOnSidebar) => {
        Actions.showNodeEditingSidebar({id: nodeLinkedOnSidebar.id})
        Actions.linkToNode(nodeLinkedOnSidebar)

        if (!isStrategyMapPage) {
            CoreAction.gotoPage(PAGES.StrategyMap)
        }
    }

    return (
        node && (
            <li key={node.id}>
                <LinkToItem autoHeight={goals && goals.length > 0}>
                    <NodeLabel withRemoveIcon={false} onClick={() => openNodeOnSidebar(node)}>
                        {goals && goals.length > 0 && (
                            <>
                                <NodeIconStyled node={goals[0]} />
                                {getNodeDisplay(goals[0])} {goals[0].title}
                            </>
                        )}
                        <Div marginLeft={goals && goals.length > 0 ? "20px": "0"}>
                            <NodeIconStyled node={node} />
                            {getNodeDisplay(node)} {node.title}
                        </Div>
                    </NodeLabel>
                    {removable && (
                        <IconButton
                            displayBlock={false}
                            onClick={() => removeItem(node.id)}
                            icon="clear"
                        />
                    )}
                </LinkToItem>
            </li>
        )
    )
}

const LinkedToList = ({nodes, removeItem, removable}) => {
    const {Actions, nodeMap, showHiddenExperiment, goal} = useStrategyMapSlice()

    useEffect( () => {
        nodes.forEach( node => {
            if (node && !nodeMap[node])
                Actions.fetchNode(node);
        });
    }, [nodes]);

    return (
        <LinkedToListStyled>
            {Object.keys(nodeMap).length > 1 &&
                nodes
                    .filter( nodeID => nodeMap[nodeID]?.goals?.length === 1 && nodeMap[nodeID]?.goals[0] === goal.id || nodeMap[nodeID]?.is_archived )
                    .filter( nodeID => ! isExperiment( nodeMap[nodeID]) || ! nodeMap[nodeID].is_finished || nodeMap[nodeID].is_finished && showHiddenExperiment )
                    .map( nodeID  =>
                        <NodeLinkItem
                            key={nodeID}
                            node={nodeMap[nodeID]}
                            removeItem={removeItem}
                            removable={removable}
                        />
                    )
                }
        </LinkedToListStyled>
    )
}

export default LinkedToList