import React from 'react'
import {getTree, NODE} from '../components/Node'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {SIDEBAR_MODE} from '../const'
import {useUserSlice} from '../hooks/useUserSlice'
import {
    checkIfUserIsInExperimentTeam,
    getChampions,
    isCSF,
    isExperiment,
    isGoal,
    isNC,
    isNodeReadOnly,
    isPSF,
    isSF,
    isUserAbleToEditNode,
    isUserAChampion,
    isUserASponsor as isUserASponsorFn,
    isUserASquadMember,
    isUserChampionOrSquadMember as isUserChampionOrSquadMemberFn,
    nodeHasIntegration,
} from '../utils'
import NodeContext from '../contexts/node'
import {equals, isEmpty, map, pipe, prop, propOr, reject, uniq, unnest} from 'ramda'
import {RESTRICTIONS} from "../../core/const";

export const getSiblings = (nodeId, nodeMap) => {
    return pipe(
        prop(nodeId),
        propOr([], 'parents'),
        map((parent) => nodeMap[parent]),
        map(propOr([], 'children')),
        unnest,
        uniq,
        reject(equals(nodeId)),
    )(nodeMap)
}

const getUnrelatedNodes = (nodeMap, nodeId) => {
    return Object.values(nodeMap).filter((node) => {
        const parents = getTree(node.id, nodeMap, NODE.parents)
        const children = getTree(node.id, nodeMap, NODE.children)
        const siblings = getSiblings(node.id, nodeMap)
        const relatedNodes = [node.id].concat(parents, children, siblings)

        return !relatedNodes.includes(nodeId)
    })
}

const getLinkableNodes = (nodeMap, nodeId) => {
    const children = [...getTree(nodeId, nodeMap, NODE.children), nodeId]
    return Object.values(nodeMap).filter((node) => {
        return !children.includes(node.id)
    })
}

const NodeProvider = React.memo((props) => {
    const {nodeMap, currentOpenNodeId, sidebarMode, pendingNode} = useStrategyMapSlice()
    const user = useUserSlice()
    const node = currentOpenNodeId ? nodeMap[currentOpenNodeId] : pendingNode

    if (!currentOpenNodeId && isEmpty(pendingNode)) {
        return <NodeContext.Provider value={null}>{props.children}</NodeContext.Provider>
    }

    const isEditMode = sidebarMode !== SIDEBAR_MODE.CREATE
    const unrelatedNodes = node ? getUnrelatedNodes(nodeMap, node.id) : []
    const linkableNodes = node ? getLinkableNodes(nodeMap, node.id) : []
    const isUserChampionOrSquadMember = node
        ? isUserChampionOrSquadMemberFn(user, node, nodeMap)
        : true
    const isUserChampion = node ? isUserAChampion(user, node, nodeMap) : false
    const isUserSponsor = node ? isUserASponsorFn(user, node, nodeMap) : false
    const isUserSquadMember = node ? isUserASquadMember(user, node, nodeMap) : false
    const isUserAbleToEdit = !! RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role] );
    const champions = node ? getChampions(node, nodeMap) : []
    const isUserAbleToDeleteComment = isUserAbleToEdit && (isUserChampion || isUserSponsor)
    const isUserInExperimentTeam = node ? checkIfUserIsInExperimentTeam(user, node.id) : false

    const _isGoal = node ? isGoal(node) : false
    const _isSF = node ? isSF(node) : false
    const _isPSF = node ? isPSF(node) : false
    const _isCSF = node ? isCSF(node) : false
    const _isNC = node ? isNC(node) : false
    const _isExperiment = node ? isExperiment(node) : false
    const isNcOrExp = _isNC || _isExperiment
    const isFinishedExp = _isExperiment && node.is_finished
    const isEditable = node && isUserAbleToEditNode(user, node, nodeMap) && isEditMode
    const isReadOnly = node && isNodeReadOnly(user, node, nodeMap)

    const canEditGoal = isGoal && isUserSponsor
    const canEditSF = isSF && (isUserSponsor || isUserSquadMember)

    const hasChildren = node && node.children && node.children.length > 0
    const hasParents = node && node.parents && node.parents.length > 0

    const hasTrelloIntegration = nodeHasIntegration(currentOpenNodeId, nodeMap, 'trello')
    const hasSlackIntegration = nodeHasIntegration(currentOpenNodeId, nodeMap, 'slack')

    const state = {
        node,
        champions,
        currentOpenNodeId,
        isUserChampionOrSquadMember,
        isUserSquadMember,
        isUserChampion,
        isUserSponsor,
        isUserAbleToEdit,
        isUserAbleToDeleteComment,
        isUserInExperimentTeam,
        isReadOnly,
        unrelatedNodes,
        linkableNodes,
        isGoal: _isGoal,
        isSF: _isSF,
        isPSF: _isPSF,
        isCSF: _isCSF,
        isNC: _isNC,
        isExperiment: _isExperiment,
        isFinishedExp,
        isNcOrExp,
        isEditMode,
        isEditable,
        canEditGoal,
        canEditSF,
        hasChildren,
        hasParents,
        hasSlackIntegration,
        hasTrelloIntegration,
    }

    return <NodeContext.Provider value={state}>{props.children}</NodeContext.Provider>
})

export default NodeProvider
