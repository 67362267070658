import React from 'react'
import {I18n} from 'react-i18nify'
import PropTypes from 'prop-types'
import {MAGENTA} from '../ui/_colors'

const FormError = (props) => {
    // dont remove: needed for rerendering when language has been changed
    const try2translate = (text) => {
        if (I18n) {
            if (Array.isArray(text)) {
                const finalTranslated = []
                text.map((item) => {
                    finalTranslated.push(translate(item))
                    return true
                })
                return finalTranslated
            } else {
                return translate(text)
            }
        } else {
            return text
        }
    }

    const translate = (text) => {
        const translated = I18n.t('global.' + text.replace('.', ''))
        if (translated === text.replace('.', '')) {
            return text
        } else {
            return translated
        }
    }

    return (
        <div className="form-errors">
            {Object.keys(props.errors).map((fieldName, i) => {
                if (fieldName === 'newPassword') {
                    const passwordErrors = props.errors[fieldName]
                    return passwordErrors.map((err, j) => <p key={j}>{try2translate(err)}</p>)
                }
                return (
                    <p key={i} style={{color: MAGENTA}}>
                        {try2translate(props.errors[fieldName])}
                    </p>
                )
            })}
        </div>
    )
}

FormError.propTypes = {
    errors: PropTypes.object,
}

FormError.defaultProps = {
    errors: {},
}

export default FormError
