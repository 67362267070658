import React, {useEffect, useState} from 'react';
import {BLUE, MAGENTA, PURPLE} from "../../core/ui/_colors";
import {I18n} from "react-i18nify";
import _ from 'lodash';
import { ExtraHint, TextField } from './Reports.styles';

const formatKeys = ["\\textbf{","\\textit{","\\underline{"];

const defaultEscapes = {
    "\\": "\\textbackslash{}",
    "#": "\\#",
    "$": "\\$",
    "%": "\\%",
    "&": "\\&",
    "^": "\\textasciicircum{}",
    "_": "\\_",
    "~~": "\\textasciitilde{}\\textasciitilde{}",
    "\u2013": "\\--",
    "\u2014": "\\---",
    "  ": "~~",
    "\t": "\\qquad{}",

    "\\textbf{": "\\textbf{",
    "\\textit{": "\\textit{",
    "\\underline{": "\\underline{",
    "\n": "\\newline{}",
    "\r\n": "\\newline{}",
    // "{": "\\{",
    // "}": "\\}",
};

const replaceAll = (source, findings, replace) => {
    try{
        return source.replaceAll(findings, replace)
    }catch(e){
        return source
    }
}

const _convert2Latex = (json, text) => {
    let hintMessage = null;

    if( text && text.length ) {
        let _text = text;

        const hasLatexCommand = formatKeys.some(formatCommand => _text.includes(formatCommand) );
        if( hasLatexCommand )
            hintMessage = I18n.t("analysis.messages.LatexCommand")
        else
            Object.keys(json)
                .forEach(letters => {
                    if( letters !== "\\" ) {
                        _text = replaceAll(_text, letters, json[letters]);
                    } else {
                        const keywords = Object.keys(json).filter( e => e !== "\\").filter( e => e.length > 1 && e.charAt(0) === "\\");
                        let startPointer = 0;
                        while( _text.indexOf(letters, startPointer) !== -1 && startPointer <= _text.length) {
                            startPointer = _text.indexOf(letters, startPointer);
                            if (!keywords.some(e => e === _text.substring(startPointer, e.length)))
                                _text = [_text.slice(0, startPointer + 1), "textbackslash{}", _text.slice(startPointer + 1)].join('')
                            startPointer += 1;
                        }
                    }
                });

        return [_text, hintMessage];
    } else
        return ["", hintMessage];
}
const convert2Latex = (text) => _convert2Latex( defaultEscapes, text );

const convertFromLatex = (text) => {
    const _defaultEscapes = _.invert(defaultEscapes);
    return _convert2Latex( _defaultEscapes, text );
}

const ShowHint = ({hint, text}) => {
    const allHints = [];

    const charLengthLeftColor = (count) => {
        if( count < 0 )
            return MAGENTA;
        if( count < 50 )
            return PURPLE;
        return BLUE;
    }

    if( hint ) {
        if (hint.charlength) {
            const charLeft = hint.charlength - text.length;
            allHints.push({
                text: I18n.t("app.lettersLeft").replace("%s", charLeft),
                color: charLengthLeftColor(charLeft)
            });
        }

        return <>{allHints.map((hint, idx) => <ExtraHint key={idx} color={hint.color}>{hint.text}</ExtraHint>)}</>;
    }
    return null;
}

const LatexTextBox = ({defaultValue, rows, placeholder, onChange, hint, testID, disabled}) => {

    const [value, setValue] = useState( convertFromLatex( defaultValue ) );

    useEffect(() => {
        setValue( convertFromLatex( defaultValue ) );
    }, [defaultValue])

    const _onChange = (targetValue) => {
        const _value = targetValue.target.value;
        const hintMessage = convert2Latex( _value )[1];
        setValue( [_value, hintMessage] )
    };

    const hasLatexCommands = !! value[1];

    return (
        <>
            <TextField
                disabled={disabled}
                data-testid={testID}
                hasLatexCommands={ hasLatexCommands }
                rows={ rows || 8 }
                placeholder={ placeholder }
                value={value[0]}
                onChange={ _onChange }
                onBlur={ e => onChange && onChange( convert2Latex( e.target.value )[0] ) }
            />
            <ShowHint hint={hint} text={value[0]} />
            <p style={{float: "left"}}>{ value[1] }</p>
        </>
    )
}

export default LatexTextBox;