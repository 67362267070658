export const ColorsTypes = {
    green: 'green',
    blue: 'blue',
    red: 'red',
    yellow: 'yellow',
    black: 'black',
    orange: 'orange',
    pink: 'pink',
    aqua: 'aqua',
    violet: 'violet',
    violetGreen: 'violetGreen',
    lightGray: 'lightGray',
    midGray: 'midGray',

    darkGreen: 'darkGreen',
    darkBlue: 'darkBlue',
    darkYellow: 'darkYellow',
    darkOrange: 'darkOrange',
    darkGray: 'darkGray',

    primaryGreen: 'primaryGreen',
    primaryBlue: 'primaryBlue',
    primaryRed: 'primaryRed',

    t1: 't1',
    t2: 't2',
    t3: 't3',
    t4: 't4',
    t5: 't5',
    t6: 't6',

    dataPointPositive: 'dataPointPositive',
    dataPointNegative: 'dataPointNegative',
    dataPointNeutral: 'dataPointNeutral',
}

export const getBarColors = function (colorName) {
    switch (colorName) {
        case ColorsTypes.green:
            return '#aed053'
        case ColorsTypes.blue:
            return '#2e80b0'
        case ColorsTypes.orange:
            return '#dd7031'
        case ColorsTypes.red:
            return '#FF6822'
        case ColorsTypes.black:
            return '#000000'
        case ColorsTypes.yellow:
            return '#f7d83f'
        case ColorsTypes.lightGray:
            return '#C1C1C1'
        case ColorsTypes.midGray:
            return '#7D7D7D'
        case ColorsTypes.violet:
            return '#BD7AAE'
        case ColorsTypes.violetGreen:
            return '#A2CAB5'

        case ColorsTypes.t1:
            return '#FCCF68'
        case ColorsTypes.t2:
            return '#FF702E'
        case ColorsTypes.t3:
            return '#6C70C0'
        case ColorsTypes.t4:
            return '#97C5E8'
        case ColorsTypes.t5:
            return '#A3CD39'
        case ColorsTypes.t6:
            return '#DAA0CD'

        case ColorsTypes.darkGreen:
            return '#49662D'
        case ColorsTypes.darkBlue:
            return '#2A5E84'
        case ColorsTypes.darkOrange:
            return '#dd7031'
        case ColorsTypes.darkYellow:
            return '#B0B000'
        case ColorsTypes.darkGray:
            return '#5F5F5F'

        case ColorsTypes.primaryGreen:
            return '#00FF00'
        case ColorsTypes.primaryBlue:
            return '#0000FF'
        case ColorsTypes.primaryRed:
            return '#FF0000'

        case ColorsTypes.dataPointPositive:
            return "#009E73"
        case ColorsTypes.dataPointNegative:
            return "#C0003C"
        case ColorsTypes.dataPointNeutral:
            return "#76ABCB"

        default:
            return '#000000'
    }
}

const subtractLight = function (color, amount) {
    const cc = parseInt(color, 16) - amount
    let c = cc < 0 ? 0 : cc
    c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
    return c
}
const darken = (color, amount) => {
    color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color
    amount = parseInt((255 * amount) / 100)
    return (color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(
        color.substring(2, 4),
        amount,
    )}${subtractLight(color.substring(4, 6), amount)}`)
}
// for density chart
export const getDotColor = function (colorName) {
    switch (colorName) {
        case ColorsTypes.green:
            return darken(getBarColors(ColorsTypes.green), 20)
        case ColorsTypes.blue:
            return darken(getBarColors(ColorsTypes.blue), 20)
        case ColorsTypes.orange:
            return darken(getBarColors(ColorsTypes.orange), 20)
        case ColorsTypes.red:
            return darken(getBarColors(ColorsTypes.red), 20)
        case ColorsTypes.yellow:
            return darken(getBarColors(ColorsTypes.yellow), 20)

        case ColorsTypes.black:
        default:
            return '#000000'
    }
}

// for density chart
export const getGradientSettings = function (
    gradientColor,
    alphaValue_min = 0.2,
    alphaValue_max = 0.6,
) {
    const alphaValue_avg = (alphaValue_min + alphaValue_max) / 2

    switch (gradientColor) {
        case ColorsTypes.green:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(174,208,83,' + alphaValue_min + ')'],
                [0.5, 'rgba(174,208,83,' + alphaValue_min + ')'],
                [0.51, 'rgba(174,208,83,' + alphaValue_avg + ')'],
                [0.6, 'rgba(174,208,83,' + alphaValue_avg + ')'],
                [0.61, 'rgba(174,208,83,' + alphaValue_max + ')'],
                [0.7, 'rgba(174,208,83,' + alphaValue_max + ')'],
                [1, 'rgba(174,208,83,' + alphaValue_max + ')']
            ];

        case ColorsTypes.violet:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(255,255,255,' + alphaValue_min + ')'],
                [0.5, 'rgb(189, 122, 174,' + alphaValue_min + ')'],
                [0.51, 'rgb(189, 122, 174,' + alphaValue_avg + ')'],
                [0.6, 'rgb(189, 122, 174,' + alphaValue_avg + ')'],
                [0.61, 'rgb(189, 122, 174,' + alphaValue_max + ')'],
                [0.7, 'rgb(189, 122, 174,' + alphaValue_max + ')'],
                [1, 'rgb(189, 122, 174,' + alphaValue_max + ')']
            ];

        case ColorsTypes.blue:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(255,255,255,' + alphaValue_min + ')'],
                [0.5, 'rgba(62,138,182,' + alphaValue_min + ')'],
                [0.51, 'rgba(62,138,182,' + alphaValue_avg + ')'],
                [0.6, 'rgba(62,138,182,' + alphaValue_avg + ')'],
                [0.61, 'rgba(62,138,182,' + alphaValue_max + ')'],
                [0.7, 'rgba(62,138,182,' + alphaValue_max + ')'],
                [1, 'rgba(62,138,182,' + alphaValue_max + ')']
            ];

        case ColorsTypes.red:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(255,255,255,' + alphaValue_min + ')'],
                [0.5, 'rgba(255,104,34,' + alphaValue_min + ')'],
                [0.51, 'rgba(255,104,34,' + alphaValue_avg + ')'],
                [0.6, 'rgba(255,104,34,' + alphaValue_avg + ')'],
                [0.61, 'rgba(255,104,34,' + alphaValue_max + ')'],
                [0.7, 'rgba(255,104,34,' + alphaValue_max + ')'],
                [1, 'rgba(255,104,34,' + alphaValue_max + ')']
            ];

        case ColorsTypes.black:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.19, 'rgba(255,255,255,0)'],
                [0.8, 'rgba(0,0,0,0)'],
                [1, 'rgba(0,0,0,0)']
            ];

        case ColorsTypes.yellow:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(247,216,63,' + alphaValue_min + ')'],
                [0.5, 'rgba(247,216,63,' + alphaValue_min + ')'],
                [0.51, 'rgba(247,216,63,' + alphaValue_avg + ')'],
                [0.6, 'rgba(247,216,63,' + alphaValue_avg + ')'],
                [0.61, 'rgba(247,216,63,' + alphaValue_max + ')'],
                [1, 'rgba(247,216,63,' + alphaValue_max + ')']
            ];

        case ColorsTypes.darkYellow:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(132,118,47,' + alphaValue_min + ')'],
                [0.5, 'rgba(132,118,47,' + alphaValue_min + ')'],
                [0.51, 'rgba(132,118,47,' + alphaValue_avg + ')'],
                [0.6, 'rgba(132,118,47,' + alphaValue_avg + ')'],
                [0.61, 'rgba(132,118,47,' + alphaValue_max + ')'],
                [1, 'rgba(132,118,47,' + alphaValue_max + ')']
            ];

        case ColorsTypes.orange:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(221,112,49,' + alphaValue_min + ')'],
                [0.5, 'rgba(221,112,49,' + alphaValue_min + ')'],
                [0.51, 'rgba(221,112,49,' + alphaValue_avg + ')'],
                [0.6, 'rgba(221,112,49,' + alphaValue_avg + ')'],
                [0.61, 'rgba(221,112,49,' + alphaValue_max + ')'],
                [1, 'rgba(221,112,49,' + alphaValue_max + ')']
            ];

        case ColorsTypes.t1:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(252,207,104,' + alphaValue_min + ')'],
                [0.5, 'rgba(252,207,104,' + alphaValue_min + ')'],
                [0.51, 'rgba(252,207,104,' + alphaValue_avg + ')'],
                [0.6, 'rgba(252,207,104,' + alphaValue_avg + ')'],
                [0.61, 'rgba(252,207,104,' + alphaValue_max + ')'],
                [1, 'rgba(252,207,104,' + alphaValue_max + ')']
            ];

        case ColorsTypes.t2:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(255,112,46,' + alphaValue_min + ')'],
                [0.5, 'rgba(255,112,46,' + alphaValue_min + ')'],
                [0.51, 'rgba(255,112,46,' + alphaValue_avg + ')'],
                [0.6, 'rgba(255,112,46,' + alphaValue_avg + ')'],
                [0.61, 'rgba(255,112,46,' + alphaValue_max + ')'],
                [1, 'rgba(255,112,46,' + alphaValue_max + ')']
            ];

        case ColorsTypes.t3:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(181,145,173,' + alphaValue_min + ')'],
                [0.5, 'rgba(181,145,173,' + alphaValue_min + ')'],
                [0.51, 'rgba(181,145,173,' + alphaValue_avg + ')'],
                [0.6, 'rgba(181,145,173,' + alphaValue_avg + ')'],
                [0.61, 'rgba(181,145,173,' + alphaValue_max + ')'],
                [1, 'rgba(181,145,173,' + alphaValue_max + ')']
            ];

        case ColorsTypes.t4:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(151,197,232,' + alphaValue_min + ')'],
                [0.5, 'rgba(151,197,232,' + alphaValue_min + ')'],
                [0.51, 'rgba(151,197,232,' + alphaValue_avg + ')'],
                [0.6, 'rgba(151,197,232,' + alphaValue_avg + ')'],
                [0.61, 'rgba(151,197,232,' + alphaValue_max + ')'],
                [1, 'rgba(151,197,232,' + alphaValue_max + ')']
            ];

        case ColorsTypes.t5:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(163,205,57,' + alphaValue_min + ')'],
                [0.5, 'rgba(163,205,57,' + alphaValue_min + ')'],
                [0.51, 'rgba(163,205,57,' + alphaValue_avg + ')'],
                [0.6, 'rgba(163,205,57,' + alphaValue_avg + ')'],
                [0.61, 'rgba(163,205,57,' + alphaValue_max + ')'],
                [1, 'rgba(163,205,57,' + alphaValue_max + ')']
            ];

        case ColorsTypes.t6:
            return [
                [0, 'rgba(255,255,255,0)'],
                [0.1, 'rgba(218,160,205,' + alphaValue_min + ')'],
                [0.5, 'rgba(218,160,205,' + alphaValue_min + ')'],
                [0.51, 'rgba(218,160,205,' + alphaValue_avg + ')'],
                [0.6, 'rgba(218,160,205,' + alphaValue_avg + ')'],
                [0.61, 'rgba(218,160,205,' + alphaValue_max + ')'],
                [1, 'rgba(218,160,205,' + alphaValue_max + ')']
            ];

        default:
            new Error('unknown gradient color');
    }
}

export const compareDataForColoring = (a, b) => {
    if (b !== undefined) {
        const weekA = typeof a === 'object' ? a.success - a.failure : a
        const weekB = b.success - b.failure
        return weekA === weekB
    }
}

export const dec2hex = (value, digits) => {
    if (!digits) digits = 2
    let hex = value.toString(16)
    while (hex.length < digits) hex = '0' + hex
    return hex
}

export const rgbToHsl = (red, green, blue) => {
    red /= 255
    green /= 255
    blue /= 255
    const max = Math.max(red, green, blue)
    const min = Math.min(red, green, blue)
    let h
    let s
    const l = (max + min) / 2

    if (max == min) {
        h = s = 0 // achromatic
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case red:
                h = (green - blue) / d + (green < blue ? 6 : 0)
                break
            case green:
                h = (blue - red) / d + 2
                break
            case blue:
                h = (red - green) / d + 4
                break
        }
        h /= 6
    }
    return [h, s, l]
}

export const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1
    if (t > 1) t -= 1
    if (t < 1 / 6) return p + (q - p) * 6 * t
    if (t < 1 / 2) return q
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
    return p
}

export const hslToRgb = (h, s, l) => {
    let red
    let green
    let blue

    if (s == 0) {
        red = green = blue = l // achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        red = hue2rgb(p, q, h + 1 / 3)
        green = hue2rgb(p, q, h)
        blue = hue2rgb(p, q, h - 1 / 3)
    }

    return [Math.round(red * 255), Math.round(green * 255), Math.round(blue * 255)]
}

export const moodColorMap = {
    positive: getBarColors(ColorsTypes.dataPointPositive),
    negative: getBarColors(ColorsTypes.dataPointNegative),
    neutral: getBarColors(ColorsTypes.dataPointNeutral),
    notsure: getBarColors(ColorsTypes.darkBlue),
    own: getBarColors(ColorsTypes.primaryBlue)
}

export const moodSymbolMap = {
    positive: 'square',
    negative: 'diamond',
    neutral: 'circle',
    notsure: 'circle',
    own: 'circle-dot'
}