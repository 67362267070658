import React from 'react';
import {I18n} from 'react-i18nify';
import {
    LegendLabel,
    LegendSquare
} from './Dashboard.styles';
import {getChartsLegend, SPECIAL_CHART_LABELS} from './Dashboard';
import {moodColorMap} from "../../Charts/ChartColor";

export const LegendItem = ({name, optionalStyle}) =>
    <div key={name} style={{display: 'inline-block'}}>
        <LegendSquare style={optionalStyle} />
        <LegendLabel>{I18n.t(name)}</LegendLabel>
    </div>;

const getLegendEntry = (name, color, optionalStyle) => {
    let style = {};
    switch (optionalStyle) {
        case 'justBorder':
            style = {
                borderColor: color,
                borderWidth: '1px',
                borderStyle: 'solid'
            };
            break;
        default:
            style = {backgroundColor: color};
            break;
    }

    return <LegendItem key={name} name={I18n.t(name)} optionalStyle={style} />;
};

const getLegendKey = (key, appliedFilters) => {
    const isExperimentFilterActive = appliedFilters && appliedFilters.experiment.length > 0;
    if (isExperimentFilterActive && key === 'confirmed') {
        key = 'rest';
    } else if (isExperimentFilterActive && key === 'validating') {
        key = 'experiment';
    } else {
        key = getChartsLegend(appliedFilters && appliedFilters.duration, key);
    }
    return key;
};

export const CulturalChartEmoteLegends = () =>
    <div>
        <LegendItem name={I18n.t("charts.Answers.positive")} stlye={{float: 'left'}} optionalStyle={{backgroundColor: moodColorMap.positive}} />
        <LegendItem name={I18n.t("charts.Answers.negative")} stlye={{float: 'left'}} optionalStyle={{backgroundColor: moodColorMap.negative, transform: "rotate(45deg)"}} />
        <LegendItem name={I18n.t("charts.Answers.neutral")} stlye={{float: 'left'}} optionalStyle={{backgroundColor: moodColorMap.neutral, borderRadius: "10px"}} />
        <LegendItem name={I18n.t("charts.Answers.notsure")} stlye={{float: 'left'}} optionalStyle={{backgroundColor: moodColorMap.notsure, borderRadius: "10px"}} />
    </div>

export const MeEmoteLegends = () =>
    <div>
        <LegendItem name={I18n.t("charts.Answers.Me")} stlye={{float: 'left'}} optionalStyle={{backgroundColor: moodColorMap.own, borderRadius: "10px"}} />
    </div>

const ChartLegends = ({colorSchema, optionalStyle, isCompared, appliedFilters}) => {
    if( !! colorSchema ){
        const chartLegend = Object.keys(colorSchema)
            .filter((key) => {
                return isCompared || key !== 'confirmed';
            })
            .map((key) => {
                const style = optionalStyle ? optionalStyle[key] : undefined;

                switch (typeof colorSchema[key]) {
                    case 'string':
                        if (SPECIAL_CHART_LABELS.includes(key)) {
                            return getLegendEntry(
                                `dashboard.Legend.${key}`,
                                colorSchema[key],
                                style
                            );
                        }
                        return getLegendEntry( getLegendKey(key), colorSchema[key], style );
                    default:
                        return getLegendEntry(
                            getLegendKey(key, appliedFilters),
                            colorSchema[key]['default'],
                            style
                        );
                }
            });

        return <>{chartLegend}</>;
    } else {
        return <></>;
    }
};

export default ChartLegends;
