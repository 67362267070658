import React, {useEffect, useState} from 'react'
import Api from '../../capture/api';
import { LEADERSHIP_IMAGES } from '../../capture/LeadershipAssessmentReport';
import { CenterContainer, SpaceBetweenContainer } from '../../capture/signifier/Shared.styles';
import { useCoreSlice } from '../../core/hooks/useCoreSlice';
import { capitalizeFirstLetter } from '../../core/utils/string';
import ArchetypeStat from '../../dashboard/components/ArchetypeStat';
import { orderedStats } from '../../dashboard/components/DashboardLeaderShipSurvey';
import { Panel } from '../../core/components/BootstrapWrapper';
import { I18n } from 'react-i18nify';
import _ from 'lodash';
import { SelectItemWidgetModal } from '../../dashboard/components/CustomizeChartsDashboard';
import { SIDEBARS } from '../../core/redux/slices';

export const DashboardArchetypes = () => {
    const {dashboardWidgetsVisible, Actions, sidebarOpen, teams} = useCoreSlice();
    const [widgets, setWidgets] = useState([]);
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;
    const [clickedWidget, setClickedWidget] = useState(false);
    const widgetName = "leadership.Report.ArchetypeChartHeader";

    useEffect(() => {
        const archetypeWidgets = dashboardWidgetsVisible ? 
                                        dashboardWidgetsVisible.filter((w) => w.name === widgetName) :
                                        [];
        setWidgets(archetypeWidgets);
    }, [dashboardWidgetsVisible]);

    useEffect(() => {
        Actions.fetchTeams()
    }, []);

    
    const handleDeleteTeamWidget = (dashboardWidget) => {
        Actions.updateDashboard(
            dashboardWidgetsVisible
                .filter((w) => ! _.isEqual(w, dashboardWidget) )
        );
    };

    return <>
        {widgets.map((w) => {
            const mainPanelActions = [];
            
            if (isEditMode) {
                mainPanelActions.push(
                    {
                        icon: 'delete',
                        onClick: () => handleDeleteTeamWidget(w)
                    }
                );
                mainPanelActions.push(
                    {
                        icon: 'settings',
                        onClick: () => setClickedWidget(w)
                    }
                );
            }

            const getTeamName = (teamId) => teams.filter(t => t.id === teamId)[0]?.name

            return (<Panel key={w.filter.team} header={getTeamName(w.filter.team)} actions={mainPanelActions}>
                <SelectItemWidgetModal
                    clickedWidget={clickedWidget}
                    closeModal={() => setClickedWidget(false)}
                    selectedItemId={w.filter.team}
                />
                <TeamArchetype team={{id: w.filter.team}} />
            </Panel>) 
        })}
    </>
}

const TeamArchetype = ({team}) => {
    const [stats, setStats] = useState({});

    const getCaptureStats = (data) => Api.getCaptureStats(data)
                                        .then((response) => setStats(response.data));

    useEffect(() => {
        const statsQuery = {}
        if(team && team.id) {
            statsQuery.selected_team = team.id
        }
        getCaptureStats(statsQuery)
    }, []);

    return (
        <>
            { ! _.isEmpty(stats) && <h3>{team.name}</h3>}
            <CenterContainer>
                <SpaceBetweenContainer>
                    {orderedStats.map( key => (
                        <ArchetypeStat
                            key={key}
                            title={capitalizeFirstLetter(key)}
                            data={stats[key]}
                            total={stats.total}
                            icon={LEADERSHIP_IMAGES[key]}
                            showWithBars={ true }
                        />)
                    )}
                </SpaceBetweenContainer>
            </CenterContainer>
        </>
    )
}

const ArchetypeStatsOverview = () => {
    const {teams, Actions: CoreActions} = useCoreSlice();
    const activeTeams = teams.filter( t => !t.disabled )

    useEffect(() => {
        CoreActions.fetchTeams()
    }, []);
        
    return <Panel>
        {
            activeTeams.map((t, k) => 
                <TeamArchetype key={k} team={t} />
            )
        }
        {activeTeams.length === 0 && <div>{I18n.t('analysis.messages.noTeamsCreated')}</div>}
    </Panel>
}

export default ArchetypeStatsOverview