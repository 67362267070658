import React, {useState} from 'react';
import {I18n} from "react-i18nify";
import Charts from "../../Charts/Charts";
import {ChartType, SubTypes} from "../../Charts/ChartsConfig";
import {InspectGraphSelectBoxWrapper} from "../reports/Reports.styles";
import SelectWrapper from "../../core/components/SelectWrapper";
import {selectBoxStyling} from "../Analysis";
import {InspectGraphChartWrapper} from "../inspect/Inspect.styles";
import {subtypes} from "../../dashboard/components/DashboardLeaderShipSurvey";
import {useDashboardSlice} from "../../dashboard/hooks/useDashboardSlice";
import { Div } from '../../core/ui/Div';

const Graph = ({surveys}) => {

    const { Actions } = useDashboardSlice();

    const surveyOptions = Object.values(SubTypes.triangle)
                            .map( a => ({label: I18n.t(subtypes()[a].title), value: a}));
    
    const [currentDiagram, setCurrentDiagram] = useState(surveyOptions[0]);

    return (
        <Div paddingBottom="50px">
            <InspectGraphSelectBoxWrapper>
                <SelectWrapper
                    styles={selectBoxStyling}
                    onChange={ e => {
                        Actions.setSelectedPoints({});
                        setCurrentDiagram(e);
                    }}
                    defaultValue={currentDiagram}
                    options={surveyOptions}
                />
            </InspectGraphSelectBoxWrapper>
            <Div margin="50px 50px 18px 50px" className={'dashboard-body row'}>
                {
                    surveys.length &&
                    Object.values(SubTypes.triangle)
                        .filter( a => a === currentDiagram.value )
                        .map( (triangle,key) =>(
                            <InspectGraphChartWrapper key={key}>
                                <Charts
                                    {...{
                                        title: subtypes()[triangle].title,
                                        Type: ChartType.Triangle,
                                        subType: triangle,
                                        subTypes: subtypes()[triangle],
                                    }}
                                    dataObject={{dataShown: surveys, dataCompare: []}}
                                    inspectMode={true}
                                    isCompared={false}
                                    language={I18n._localeKey}
                                    coordinate={{height: 500, width: 500}}
                                />
                            </InspectGraphChartWrapper>
                        )
                    )
                }
            </Div>
        </Div>
    )
}

export default Graph;