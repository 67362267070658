import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import SelectWrapper from '../../core/components/SelectWrapper'
import {useCaptureSlice} from '../../capture/hooks/useCaptureSlice'
import {CenterContainer, SpaceBetweenContainer} from '../../capture/signifier/Shared.styles'
import {Flex} from '../../core/ui/Flex'
import {ChartType, SubTypes as SubTypesCharts} from '../../Charts/ChartsConfig'
import ArchetypeStat from './ArchetypeStat'
import {Title, Filter, DashboardTiles} from './Dashboard.styles'
import DashboardChart from './DashboardChart';
import moment from "moment";
import { LEADERSHIP_IMAGES } from '../../capture/LeadershipAssessmentReport'
import DurationFilterComponent from "./DashboardFilters/DurationFilterComponent";
import {Div} from "../../core/ui/Div";
import {DURATION_OPTIONS} from "./Dashboard";
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice'
import Api from '../../capture/api'
import { LEADERSHIP_CAPTURE_SURVEY } from '../../capture/const'
import { Link } from 'react-router-dom'
import {SwitchWithLabel} from '../../core/components/Switch';
import {GREY} from "../../core/ui/_colors";
import DashboardMessageCentered from './DashboardMessageCentered'
import {RESTRICTIONS} from "../../core/const";
import _ from 'lodash'

export const subtypes = () => ({
    1: {
        title: 'leadership.Triangle.Topic.My expectations of the team were to',
        top: I18n.t('leadership.Triangle.Corner.meet my standards'),
        bottomLeft: I18n.t('leadership.Triangle.Corner.be willing to be supported by me'),
        bottomRight: I18n.t('leadership.Triangle.Corner.inspire the team to challenge their boundaries'),
    },
    2: {
        title: 'leadership.Triangle.Topic.My responsibility as leader was to',
        top: I18n.t('leadership.Triangle.Corner.decide'),
        bottomLeft: I18n.t('leadership.Triangle.Corner.organise'),
        bottomRight: I18n.t('leadership.Triangle.Corner.support'),
    },
    3: {
        title: 'leadership.Triangle.Topic.People chose what to do based on',
        top: I18n.t('leadership.Triangle.Corner.a shared purpose'),
        bottomLeft: I18n.t('leadership.Triangle.Corner.targets assigned to individuals'),
        bottomRight: I18n.t('leadership.Triangle.Corner.the awareness of the bigger picture'),
    },
    4: {
        title: 'leadership.Triangle.Topic.To succeed I knew I needed to',
        top: I18n.t('leadership.Triangle.Corner.facilitate cooperation'),
        bottomLeft: I18n.t('leadership.Triangle.Corner.encourage autonomy'),
        bottomRight: I18n.t('leadership.Triangle.Corner.provide specific instructions'),
    },
    5: {
        title: 'leadership.Triangle.Topic.To motivate my people to perform, I',
        top: I18n.t('leadership.Triangle.Corner.offered individual incentives'),
        bottomLeft: I18n.t('leadership.Triangle.Corner.built a sense of shared responsibility'),
        bottomRight: I18n.t('leadership.Triangle.Corner.helped them see their strategic impact'),
    },
    6: {
        title: 'leadership.Triangle.Topic.People felt comfortable because',
        top: I18n.t('leadership.Triangle.Corner.I was taking responsibility'),
        bottomLeft: I18n.t('leadership.Triangle.Corner.everyone trusted each other'),
        bottomRight: I18n.t('leadership.Triangle.Corner.they believed they could handle it'),
    },
})

const ArrowControlAnatomy = () =>
    <SpaceBetweenContainer style={{alignItems: 'center', paddingRight: 50, paddingLeft: 50, color: GREY, marginTop: 10}}>

        <table width={"100%"}>
            <tbody>
                <tr>
                    <td width={80} style={{textAlign: "center"}}>{ I18n.t("leadership.Panel.Options.Control") }</td>
                    <td width={5} style={{margin: 0, padding: 0}}>
                        <i className={"material-icons notranslate"} style={{paddingTop: 6, marginRight: -20, fontSize: 22}}>west</i>
                    </td>
                    <td style={{margin: 0, padding: 0, paddingTop: 1}}>
                        <div style={{width: "100%", height: "3px", backgroundColor: GREY}}></div>
                    </td>
                    <td width={5} style={{margin: 0, padding: 0}}>
                        <i className={"material-icons notranslate"} style={{paddingTop: 6, marginLeft: -16, fontSize: 22}}>east</i>
                    </td>
                    <td width={80} style={{textAlign: "center"}}>{ I18n.t("leadership.Panel.Options.Autonomy") }</td>
                </tr>
            </tbody>
        </table>
    </SpaceBetweenContainer>

const checkIfAssessmentIsAnswered = (entry, leaderShipAssessmentSurvey, user) => leaderShipAssessmentSurvey && user && entry  &&
                                                    entry.user === user.pk || 
                                                    leaderShipAssessmentSurvey.find(answer => answer.user === user.pk && answer.parent_capture === entry.key);

export const listEntryFormat = (entry, leaderShipAssessmentSurvey, user) => {
    if( ! entry )
        return I18n.t("dashboard.Stories.All");
    
    const isOwnAssessment = entry && user && entry.user === user.pk;
    
    const isAssessmentAnswered = checkIfAssessmentIsAnswered(entry, leaderShipAssessmentSurvey, user)

    const date = entry.created
    const title = entry.data.title

    const formatedDate = moment(date).locale(I18n._localeKey).format('LL');

    return formatedDate + " - " + title + (isOwnAssessment ? ` (${I18n.t("dashboard.Stories.You")})` : "") + (!isAssessmentAnswered ? ` ❗` : "");
}

export const decisionsFilteredByDuration = (leaderShipAssessmentSurvey, duration) => {
    const SurveyTimeRageFilterIDs = leaderShipAssessmentSurvey.length
            ? [...leaderShipAssessmentSurvey]
                .filter( a => ! a.parent_capture )
                .filter( a => moment( a.created ).add( duration, 'days' ).diff( moment(), 'days' ) >= 0 )
                .map( a => a.key )
            : [];

   return [...leaderShipAssessmentSurvey]
            .filter( a => SurveyTimeRageFilterIDs.includes( a.key ) || a.parent_capture && SurveyTimeRageFilterIDs.includes( a.parent_capture) );
}

export const orderedStats = ['expert', 'coordinator', 'peer', 'coach', 'strategist'];

export const ArchetypeStatsDashboardWidget = ({teamId}) => {
    const [stats, setStats] = useState({});
    const defaultParams = { selected_team: teamId };
    const getCaptureStats = (data) => Api.getCaptureStats(data)
                                        .then((response) => setStats(response.data));

    useEffect( () => {
        getCaptureStats(defaultParams);
    }, [] );


    return <ArchetypeStatsWrapper stats={stats} />;
}

const ArchetypeStatsWrapper = ({stats}) => {
    if (!stats || _.isEmpty(stats))
        return null;
    
    return (
        <CenterContainer>
            <SpaceBetweenContainer style={{alignItems: 'flex-start'}}>
                {
                    orderedStats.map( key => (
                        <ArchetypeStat
                            key={key}
                            title={ I18n.t("leadership.Report." + key) }
                            data={stats[key]}
                            total={stats.total}
                            icon={LEADERSHIP_IMAGES[key]}
                            showWithBars={ true }
                        />))
                }
            </SpaceBetweenContainer>
            <ArrowControlAnatomy />
        </CenterContainer>
    )
}

export const DashboardLeaderShipSurvey = ({display, selectedTeam}) => {
    
    const {leaderShipAssessmentSurvey} = useCaptureSlice();
    const [stats, setStats] = useState({});
    const [duration, setDuration] = useState(DURATION_OPTIONS.ALL);
    const user = useUserSlice();
    const [zoom, setZoom] = useState(null);

    const TeamSurveys =
        leaderShipAssessmentSurvey
            .filter( a => selectedTeam === "" || a.selected_team === selectedTeam )
            .map( a => a.key );

    const SurveyTimeRageFilter = decisionsFilteredByDuration( leaderShipAssessmentSurvey.filter( a => TeamSurveys.includes( a.key ) || TeamSurveys.includes( a.parent_capture ) ), duration );
    
    const surveyOptions =
        [...SurveyTimeRageFilter]
            .filter( a => ! a.parent_capture )
            .filter( a => a.data.title )
            .filter( t => RESTRICTIONS.Leadership.RULES.CAN_SEE_ALL_TEAMS.some(role => user[role] ) || user.teams.includes( t.selected_team ) )
            .map( a => ({label: listEntryFormat(a, leaderShipAssessmentSurvey, user), value: a.key, date: a.created}))
            .sort( (a,b) => moment(b.date).diff(a.date) );

    let SURVEYS = [];
    if( ["everything", "split-all"].includes(display) ){
        const defaultAll = {value: 'All', label: listEntryFormat(), date: new Date()};
        SURVEYS = [ ...[defaultAll]];
    }
    SURVEYS = [...SURVEYS, ...surveyOptions];
    
    const getCaptureStats = (data) => Api.getCaptureStats(data)
                                        .then((response) => setStats(response.data));
    
    const [currentSurveyValue, setCurrentSurveyValueValue] = useState(SURVEYS.length > 0 && SURVEYS[0].value);
    
    const isCurrentSurveyValueAll = currentSurveyValue === "All";

    const [highlightOwnPerception, setHighlightOwnPerception] = useState(false)

    const modifyMoods = (data, isOwnPerception) => {
        const obj = {...data}
        if (isOwnPerception && data.mood && highlightOwnPerception )
            obj.mood = 'own'
        return obj
    }

    const SURVEY =
        [...SurveyTimeRageFilter]
            .filter( a =>
                    isCurrentSurveyValueAll
                    || a.key === currentSurveyValue
                    || a.parent_capture === currentSurveyValue )
            .flatMap(a => ({...modifyMoods(a.data, a.user === user.pk), key: a.key}));

    const getCaptureStatsQuery = (parentCapture, created) => {
        const statsQuery = { 
            parent_capture: parentCapture === "All" ? '': parentCapture
        }
        if (selectedTeam) {
            statsQuery.selected_team = selectedTeam
        }
        if (created) {
            statsQuery.created = created
        }

        return statsQuery
    }

    const changeSurvey = (newSurvey) => {
        if (newSurvey) {
            setCurrentSurveyValueValue(newSurvey.value);
            
            getCaptureStats(getCaptureStatsQuery(newSurvey.value));
        }
    };

    useEffect( () => {}, [I18n._localeKey] );

    useEffect( () => {
        getCaptureStats(getCaptureStatsQuery(currentSurveyValue));
    }, [] );

    useEffect( () => {
        getCaptureStats(getCaptureStatsQuery(currentSurveyValue));
    }, [selectedTeam])

    const getDashboardTitle = () => {
        switch (display) {
            case "everything":
                return I18n.t('dashboard.Dashboard');
            case "split-all":
                return I18n.t('dashboard.Duration.All');
            case "split-details":
                return I18n.t('dashboard.SelectedStories');
            default:
                return I18n.t('dashboard.Dashboard');
        }
    }

    const changeTimeRange = e => {
        getCaptureStats( getCaptureStatsQuery(currentSurveyValue, moment().subtract( e.duration, "days" ).format("YYYY-MM-DD")));
        setDuration( e.duration );
    }

    const formatDate = (date) => {
        const d = new Date(date)
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }

    const getMedianValue = (key) => {
        const assessments = leaderShipAssessmentSurvey
            .filter( value => value.parent_capture === key || value.key === key );

        const summation = assessments.reduce( (total, curr) => total + curr.data.autonomy_vs_control, 0 );
        return summation / assessments.length;
    }

    const SURVEY_SLIDER_FORMATED = SurveyTimeRageFilter
                                    .filter( value => value.parent_capture === null)
                                    .filter( a =>
                                        isCurrentSurveyValueAll
                                        || a.key === currentSurveyValue
                                        || a.parent_capture === currentSurveyValue )
                                    .map( value => ({
                                        name: formatDate(value.created), 
                                        autonomyVsControl: getMedianValue(value.key) - 50
                                    }));

    const charts = Object.values(SubTypesCharts.triangle).map( triangle => ({
        chartData: {
            title: subtypes()[triangle].title,
            Type: ChartType.Triangle,
            subType: triangle,
            subTypes: subtypes()[triangle]
        },
        dataShown: SURVEY
    }) )

    if(isCurrentSurveyValueAll) {
        charts.push({
            chartData: {
                title: I18n.t("leadership.Panel.Topic.Autonomy vs Control"),
                Type: ChartType.AreaChart
            },
            dataShown: SURVEY_SLIDER_FORMATED
        })
    }

    return (
        <div data-testid={'dashboard-container'}>
            <div className={'dashboard-header'}>
                <Div textAlign="center">
                    <Title>{getDashboardTitle()}</Title>
                    <Flex justifyContent={'space-between'}>
                        <Filter>
                            <Div>
                                <DurationFilterComponent
                                    appliedFilters={{duration: duration}}
                                    handleFiltersChange={changeTimeRange}
                                />
                            </Div>
                            {
                                ["split-details", "everything"].includes( display ) &&
                                <SelectWrapper
                                    minimalistView
                                    onChange={changeSurvey}
                                    defaultValue={ SURVEYS.length > 0 ? SURVEYS[0] : "" }
                                    options={SURVEYS}
                                />
                            }
                            <Div marginLeft="8px">
                                <SwitchWithLabel label={I18n.t('dashboard.highlightMyOwnPerceptions')} defaultValue={highlightOwnPerception} onChange={() => setHighlightOwnPerception(!highlightOwnPerception)} />
                            </Div>
                        </Filter>
                    </Flex>
                </Div>
            </div>
            {!isCurrentSurveyValueAll && !checkIfAssessmentIsAnswered(leaderShipAssessmentSurvey.find(a => a.key === currentSurveyValue), leaderShipAssessmentSurvey, user) && (
                <div className={'dashboard-body row'}>
                    <Link to={"/capture/" + LEADERSHIP_CAPTURE_SURVEY + "/" + currentSurveyValue}
                        style={
                            {
                                marginLeft: '15px', 
                                fontWeight: 'bold',
                                textDecoration: 'none'
                            }
                        }>
                    ❗ {I18n.t('dashboard.messages.assessmentNotAnswered')}
                    </Link>
                </div>
            )}
            
            <div className={'dashboard-body row'}>
                <ArchetypeStatsWrapper stats={stats} />
            </div>
            
            
            <DashboardTiles className="row">
                {
                    SURVEY.length > 0 &&
                    charts
                        .filter( (chart, index) => ! Number.isInteger(zoom) || zoom === index )
                        .map( (chart, index) =>
                        <div  key={index} className={'col col-sm-12 col-md-6 col-lg-4'} >
                            <DashboardChart
                                noConfig={true}
                                chartData={chart.chartData}
                                hasExperiments={false}
                                isDashboardLite={false}
                                isCompared={false}
                                dataObject={{dataShown: chart.dataShown, dataCompare: []}}
                                keyIndex={index}
                                key={index}
                                onlyOwnDecisions={highlightOwnPerception}
                                zoom={ Number.isInteger(zoom) }
                                clickZoom={() => setZoom( Number.isInteger(zoom) ? null : index )}
                            />
                        </div>
                    )
                }
                {
                    SURVEY.length === 0 && <div className={'dashboard-body row'}>
                        <DashboardMessageCentered
                            message={I18n.t( 'dashboard.messages.noDecisionsWereDocumentedWithinLast2Weeks' )} />
                    </div>
                }
            </DashboardTiles>
        </div>
    )
}
