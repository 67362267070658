import React from 'react'
import {useCoreSlice} from '../hooks/useCoreSlice'
import styled from 'styled-components'
import {Flex} from './Flex'
import {Z_INDICES} from '../const'
import useHover from '../hooks/useHover'
import {I18n} from 'react-i18nify'
import { DARK_GREY } from './_colors'
import { IconButton } from '../components/Buttons'

export const Toast = styled.div`
    width: 356px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    text-align: left;
    white-space: pre-wrap;
    position: fixed;
    top: 92px;
    display: block;
    z-index: ${Z_INDICES.PageReloadPrompt};
    padding: 16px 16.7px 21px 23px;
`

export const Text = styled.div`
    width: 286px;
    line-height: 1.29;
`

const RefreshButton = styled(Flex)`
    width: 132px;
    height: 19px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: ${DARK_GREY};
    cursor: pointer;
    margin-top: 8px;
`

const Reload = () => {
    const [hoverRef] = useHover(true)
    const handleClick = () => window.location.reload()

    return (
        <RefreshButton ref={hoverRef} onClick={handleClick} >
            <span className="material-icons-outlined">refresh</span>
            {I18n.t('app.Reload')}
        </RefreshButton>
    )
}

const PageReloadPrompt = () => {
    const {pageReloadPromptVisible, Actions} = useCoreSlice()

    return pageReloadPromptVisible ?
            (<Toast>
                <Flex>
                    <Text>{I18n.t('app.ReloadPagePopupMessage')}</Text>
                    <IconButton onClick={Actions.hidePageReloadPrompt} icon="clear" />
                </Flex>
                <Reload />
            </Toast>) 
            : null
}

export default PageReloadPrompt