import React from 'react'
import {ErrorContainer, ErrorList} from './Shared.styles'

const ErrorOutput = ({errors}) => {
    if(!errors) return null

    return (
        <ErrorContainer data-testid="error-output">
            {errors && (
                <ErrorList>
                    {Object.keys(errors).map((errorKey) => (
                        <li key={errorKey}><i className="fas fa-ban" /> {errors[errorKey]}</li>
                    ))}
                </ErrorList>
            )}
        </ErrorContainer>
    )
}

export default ErrorOutput
