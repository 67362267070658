import React, {useRef} from 'react'
import IdleTimer from "react-idle-timer";
import api from "../api";
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';

const SoftLogout = (props) => {

    const {isUserLoggedIn} = useUserSlice();

    return isUserLoggedIn ? 
        <SoftLogoutTimer {...props} /> :
        props.children;
}

const SoftLogoutTimer = (props) => {

    const Milliseconds2Minutes = 1000 * 60;
    const settings = {
        idleDetectingMinutes: 50,
        actionSendRequestDelay: 3
    }

    let lastActionTimer = new Date().getTime();
    const logoutTimer = useRef(null)

    const isAuthorizedPage = () => {
        const path = window.location.pathname.split('/');
        return !( path.length >= 2 && ['login', 'password'].includes(path[1]) )
    }

    const updateBackendLogoutTimer = () => {
        api.getClient();
        lastActionTimer = new Date().getTime();
    }

    const softKickOutCheck = () => {
        if( ! isAuthorizedPage() )
            return;

        localStorage.removeItem('token');
        window.location.href = '/login?softlogout';
    }
    
    const onActive = () => {
        if ( isAuthorizedPage() && logoutTimer === null )
            updateBackendLogoutTimer();
    }
    const onAction = () => {
        if( isAuthorizedPage() && 
            new Date().getTime() - lastActionTimer > Milliseconds2Minutes * settings.actionSendRequestDelay )
            updateBackendLogoutTimer()
    }

    return (
        <IdleTimer
            timeout={Milliseconds2Minutes * settings.idleDetectingMinutes}
            onActive={onActive}
            onAction={onAction}
            onIdle={softKickOutCheck}
            debounce={250}>
            {props.children}
        </IdleTimer>
    )
}

export default SoftLogout
