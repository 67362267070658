import React from 'react'
import NodeInput from './sidebar/NodeInput'
import {SideBar} from '../../core/ui/Sidebar.styles'

const NodeSidebar = props =>
    <SideBar data-testid="node-sidebar" id="node-sidebar" className="show-sidebar">
        <NodeInput {...props} />
    </SideBar>;

export default NodeSidebar;
