import _ from "lodash";
import moment from "moment";

export const transformFilterDemographisToPKList = (appliedFilters) => {
    const appliedFiltersListObject = {}

    Object.keys(appliedFilters)
        .forEach( demographicType => {
            if( appliedFilters[demographicType].length )
                appliedFiltersListObject[demographicType] = appliedFilters[demographicType].map( choice => choice.pk );
        })
    return appliedFiltersListObject
};

const getDefaultFilters = (duration) => ({
    department: [],
    role: [],
    years: [],
    experiment: [],
    type: [],
    decision_owner: [],
    duration: duration
});

export const buildEmptyAppliedFilters = (demographics, duration) => {

    const emptyFilters = getDefaultFilters(duration);

    if ( ! _.isEmpty(demographics) ) {

        Object.keys(demographics)
            .forEach( demographicType => emptyFilters[demographicType] = [] );

        emptyFilters['experiment'] = [];
        emptyFilters['duration'] = duration;
    }

    return emptyFilters;
}

export const getLastDate = (daysAgo = false) => {
    let currentDate = moment()
    if( daysAgo )
        currentDate = currentDate.add( -daysAgo, "days");

    return (
        currentDate.year() +
        '-' +
        `${currentDate.month() + 1}`.padStart(2, '0') +
        '-' +
        `${currentDate.date()}`.padStart(2, '0')
    )
}