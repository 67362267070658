import React, {useState} from 'react'
import styled from 'styled-components'
import UserWithAvatar from './UserWithAvatar'

export const Container = styled.div`
    width: 300px;
    min-height: 100px;
    max-height: 250px;
    overflow: scroll;
    padding: 10px;
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    display: ${(p) => (p.open ? 'block' : 'none')};
    transition: display 0.5s;
    z-index: 9999;
    top: 40px;
    left: 0;
`

const SearchInput = styled.input`
    padding-left: 5px;
    line-height: 30px;
    width: 100%;
`

const UserListPopover = ({users, open, handleItemClick, testId}) => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <Container open={open} data-testid={testId || 'user-list-popover'}>
            <SearchInput
                onChange={(e) => {
                    setSearchTerm(e.target.value)
                }}
            />
            {users
                .filter((user) => user.full_name.toLowerCase().includes(searchTerm.toLowerCase()))
                .sort((a, b) =>
                    a.full_name.toLocaleUpperCase().localeCompare(b.full_name.toLocaleUpperCase()),
                )
                .map((user) => (
                    <UserWithAvatar
                        key={user.pk}
                        testId={`popover-user-avatar-${user.pk}`}
                        user={user}
                        showAvatar
                        onClick={handleItemClick}
                    />
                ))}
        </Container>
    )
}

export default UserListPopover
