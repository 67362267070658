import React, {useEffect, useState} from 'react';
import { Col, Table, Row } from 'react-bootstrap';
import { I18n } from 'react-i18nify';
import { useCaptureSlice } from '../../capture/hooks/useCaptureSlice';
import Checkbox from '../../capture/signifier/Checkbox';
import { Panel } from '../../core/components/BootstrapWrapper';
import { RESTRICTIONS } from '../../core/const';
import { useCheckList } from '../../core/hooks/useCheckList';
import { useCoreSlice } from '../../core/hooks/useCoreSlice';
import { DURATION_OPTIONS } from '../../dashboard/components/Dashboard';
import { Title } from '../../dashboard/components/DashboardFilters/DashboardFiltersContainer.styles';
import DurationFilterComponent from '../../dashboard/components/DashboardFilters/DurationFilterComponent';
import { decisionsFilteredByDuration } from '../../dashboard/components/DashboardLeaderShipSurvey';
import DashboardMessageCentered from '../../dashboard/components/DashboardMessageCentered';
import Graphs from "./Graphs";

const TeamGraphDisplay = ({team, duration}) => {
    const { Actions, leaderShipAssessmentSurvey, isFetching } = useCaptureSlice();

    useEffect(() => {
        Actions.getCaptures();
    }, []);

    const surveys = decisionsFilteredByDuration( leaderShipAssessmentSurvey.filter( a => a.selected_team === team.id), duration )
                        .flatMap(a => ({...a.data, pk: a.key, parent_capture: a.parent_capture, created: a.created}));

    return (<>
        {(!! surveys.length && !isFetching) && <Graphs surveys={surveys} showAutonomyVsContolChart={true} />}
        {(! surveys.length && !isFetching) && <DashboardMessageCentered message={I18n.t('dashboard.messages.noDecisionsWereDocumentedYet')} />}
    </>)
}

const TeamsOverview = () => {
    const {teams, Actions: CoreActions} = useCoreSlice();
    const [selectedTeams, setSelectedTeams] = useState([]);
    const {checkedItems, handleCheckboxClick} = useCheckList( teams, setSelectedTeams );
    const [duration, setDuration] = useState( DURATION_OPTIONS.ALL );

    useEffect(() => {
        if(!teams)
            CoreActions.fetchTeams();
    }, []);

    const threeCols = 4;
    const twoCols = 6;
    const rowsNumber = selectedTeams.length === RESTRICTIONS.Analysis.MAX_TEAMS_ALLOWED_FOR_COMPARISON ? threeCols : twoCols;

    return (
        <Panel>
            <Table>
                <tbody data-testid={"ubody"}>
                {teams
                    .filter( t => !t.disabled )
                    .map((team, i) => 
                        <tr key={'teamrow-'+i} data-testid={"teamrow"}>
                            <td>
                                <Checkbox
                                    testId={"teamrow-"+team.id}
                                    onClick={(_) => handleCheckboxClick(i)}
                                    checked={!!checkedItems[i]}
                                    disabled={selectedTeams.length >= RESTRICTIONS.Analysis.MAX_TEAMS_ALLOWED_FOR_COMPARISON && !checkedItems[i]}
                                />
                            </td>
                            <td>
                                {team.name}
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

            <Row>
                <Col md={3} sm={3} lg={3}>
                    <DurationFilterComponent
                        appliedFilters={{duration: duration}}
                        handleFiltersChange={ (e) => setDuration( e.duration ) }
                    />
                </Col>
            </Row>

            <Row>{
                selectedTeams.length === 0 && <DashboardMessageCentered
                                                message={I18n.t('analysis.messages.SelectTeamsToCompare').replace('{s}', RESTRICTIONS.Analysis.MAX_TEAMS_ALLOWED_FOR_COMPARISON)} />
            }</Row>

            <Row style={{marginTop: 30}}>{ selectedTeams.map( (team,index) =>
                <Col key={index} md={rowsNumber} sm={rowsNumber} lg={rowsNumber}><Title style={{paddingLeft: 0}}>{team.name}</Title></Col> )
            }</Row>

            <Row style={{paddingTop: 15}}>{
                selectedTeams
                    .map( (team) =>
                        <Col key={team.id} md={rowsNumber} sm={rowsNumber} lg={rowsNumber}>
                            <TeamGraphDisplay team={team} duration={duration} />
                        </Col>
                )
            }</Row>
        </Panel>
    )
}

export default TeamsOverview;