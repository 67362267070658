import React from 'react'
import {I18n} from 'react-i18nify'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import DemographicsTable from "./DemographicsTable"
import { Panel } from '../core/components/BootstrapWrapper'
import { HelperText } from '../analysis/reports/Reports.styles'

export const H3 = styled.h3`
    font-size: 20px;
    font-weight: bold;
    margin-top: 55px;
`

const Demographics = () => {

    const demographics = useSelector((state) => state.demographics);

    return (
        <Panel>
            <HelperText>{I18n.t("billing.demographics.hint")}</HelperText>

            <H3>{I18n.t("billing.demographics.country")}</H3>
            <DemographicsTable demographics={demographics.country} type={'country'} />

            <H3>{I18n.t("billing.demographics.city")}</H3>
            <DemographicsTable demographics={demographics.city}  type={'city'} />

            <H3>{I18n.t("billing.demographics.department")}</H3>
            <DemographicsTable demographics={demographics.department} type={'department'} />

            <H3>{I18n.t("billing.demographics.year")}</H3>
            <DemographicsTable demographics={demographics.years} type={'year'} />

            <H3>{I18n.t("billing.demographics.role")}</H3>
            <DemographicsTable demographics={demographics.role} type={'role'} />
        </Panel>
    )
}

export default Demographics
