import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {I18n} from 'react-i18nify'

import {ColorsTypes, compareDataForColoring, getBarColors} from '../ChartColor'
import {Area, ComposedChart, Line, ReferenceDot, ReferenceLine, XAxis} from 'recharts'
import {NODE_STATES} from '../../strategyMap/const'
import {Div} from '../../core/ui/Div'

const Div_ = styled(Div)`
    .recharts-wrapper {
        width: 100% !important;

        svg {
            width: 100% !important;
        }
    }
`

const ExperimentActivity = (props) => {
    const htmlid = 'splitColor_' + Math.round(Math.random() * 1000000)

    const [dataChart, setDataChart] = useState()
    const [gradientOffset, setGradientOffset] = useState()
    const [interimRefPoint, setInterimRefPoint] = useState()

    useEffect(() => {
        const data = generateActivityData(props.data)
        setDataChart(data)
        setGradientOffset(pipeOnStatus(props.data.status, getGradientOffset(data)) || 0)
        setInterimRefPoint(pipeOnStatus(props.data.status, getInterimRefPoint(props.data)))
    }, [props])

    const pipeOnStatus = (status, value) => {
        return status !== NODE_STATES.E.created ? value : undefined
    }

    const getInterimRefPoint = (data) => {
        return data && data.savedData.length - 1
    }

    const generateActivityData = (data) => {
        data.savedData.length === 0 && data.savedData.push({week: 0, success: 0, failure: 0})

        if (data && data.status !== NODE_STATES.E.created) {
            const activityData = []
            switch (data.status) {
                case NODE_STATES.E.started:
                    addingPastData(activityData, data.savedData)
                    addingCurrentData(activityData, data.currentData)
                    addingFutureData(activityData, data.savedData.length, data.duration)
                    break
                case NODE_STATES.E.finished:
                case NODE_STATES.E.successful:
                case NODE_STATES.E.failed:
                    addingPastData(activityData, data.savedData)
                    break
                default:
                    break
            }
            return activityData
        }
    }

    const addingPastData = (activityData, pastData) => {
        if (pastData.length > 0) {
            pastData.forEach((entry) => {
                activityData.push({
                    name: 'confirmed',
                    current: entry.success - entry.failure,
                    activity: entry.success - entry.failure,
                    data: entry.success - entry.failure,
                })
            })
        }
    }

    const addingCurrentData = (activityData, currentData) => {
        activityData.push({
            name: 'current',
            current: currentData,
            activity: null,
            data: currentData,
        })
    }

    const addingFutureData = (activityData, weeksPassed, duration) => {
        if (duration > weeksPassed) {
            const diff = duration - weeksPassed

            const emptyData = {
                name: 'next',
                current: null,
                activity: null,
                data: 0,
            }
            for (let i = 1; i < diff; i++) {
                activityData.push(emptyData)
            }
        }
    }

    // Decides the color of the graphs
    const checkDataForColoring = (data) => {
        if (data.savedData.length > 1) {
            let isEqual = true
            for (let i = 0; i < data.savedData.length - 1; i++) {
                isEqual = compareDataForColoring(data.savedData[i], data.savedData[i + 1])
                if (!isEqual) return false
            }
            return isEqual
        } else if (data.savedData.length === 1) {
            return compareDataForColoring(data.currentData, data.savedData[0])
        } else {
            return false
        }
    }

    const decideColor = (data) => {
        if (data.status === NODE_STATES.E.created) return false

        const isEqual = checkDataForColoring(data)
        if (isEqual) {
            const diff = data.savedData[0].success - data.savedData[0].failure
            return diff >= 0 ? getBarColors(ColorsTypes.green) : getBarColors(ColorsTypes.red)
        }

        return undefined
    }

    const getGradientOffset = (data) => {
        if (data !== undefined) {
            const dataMax = Math.max(...data.map((i) => i.data))
            const dataMin = Math.min(...data.map((i) => i.data))

            if (dataMin >= 0) {
                return 1
            } else {
                return (1 * dataMax) / (dataMax + Math.abs(dataMin))
            }
        }
    }

    if (props.data) {
        switch (props.data.status) {
            case NODE_STATES.E.created:
                return (
                    <div className="status-info">
                        {I18n.t('strategyMap.messages.notStartedInfo')}
                    </div>
                )

            case NODE_STATES.E.finished:
            case NODE_STATES.E.successful:
            case NODE_STATES.E.failed:
                return (
                    <div data-testid={props.testId}>
                        <ComposedChart
                            className={'ExperimentChart'}
                            width={500}
                            height={100}
                            data={dataChart}
                        >
                            <defs>
                                <linearGradient id={htmlid} x1="0" y1="0" x2="0" y2="1">
                                    <stop
                                        offset={gradientOffset}
                                        stopColor={getBarColors(ColorsTypes.green)}
                                        stopOpacity={1}
                                    />
                                    <stop
                                        offset={gradientOffset}
                                        stopColor={getBarColors(ColorsTypes.red)}
                                        stopOpacity={1}
                                    />
                                </linearGradient>
                            </defs>
                            <ReferenceLine y={0} stroke="#adafb3" /> {/* baseline */}
                            {/* necessary for alignment on left and right */}
                            <XAxis scale="point" />
                            <Area
                                type="linear"
                                dataKey="activity"
                                strokeWidth={3}
                                stroke={'url(#' + htmlid + ')'}
                                fillOpacity={0.2}
                                fill={'url(#' + htmlid + ')'}
                            />
                            {/* Week Points*/}
                            {dataChart &&
                                dataChart
                                    .filter((i) => i.name === 'confirmed')
                                    .map((dot, index) => {
                                        return (
                                            <ReferenceDot
                                                key={index}
                                                x={index}
                                                y={dot.activity}
                                                r={5}
                                                fill={
                                                    dot.activity >= 0
                                                        ? getBarColors(ColorsTypes.green)
                                                        : getBarColors(ColorsTypes.red)
                                                }
                                                stroke="none"
                                                isFront={true}
                                            />
                                        )
                                    })}
                        </ComposedChart>
                    </div>
                )

            case NODE_STATES.E.started:
            default:
                const noSavedData = interimRefPoint === -1
                const manuelColor = decideColor(props.data)

                return (
                    <Div_ data-testid={props.testId}>
                        <ComposedChart width={500} height={100} data={dataChart}>
                            <defs>
                                <linearGradient id={htmlid} x1="0" y1="0" x2="0" y2="1">
                                    <stop
                                        offset={gradientOffset}
                                        stopColor={getBarColors(ColorsTypes.green)}
                                        stopOpacity={1}
                                    />
                                    <stop
                                        offset={gradientOffset}
                                        stopColor={getBarColors(ColorsTypes.red)}
                                        stopOpacity={1}
                                    />
                                </linearGradient>
                            </defs>

                            {/* baseline */}
                            <ReferenceLine y={0} stroke="#adafb3" strokeWidth={2} />

                            {/* necessary for alignment on left and right */}
                            <XAxis scale="point" />

                            <Line
                                type="linear"
                                dataKey="current"
                                stroke={manuelColor ? manuelColor : 'url(#' + htmlid + ')'}
                                strokeDasharray="5, 5"
                                strokeWidth={2}
                            />

                            {/* Current Week Point*/}
                            {dataChart &&
                                dataChart
                                    .filter((i) => i.name === 'current')
                                    .map((dot) => {
                                        return (
                                            <ReferenceDot
                                                key={dot.name}
                                                x={interimRefPoint + 1}
                                                y={dot.current}
                                                r={5}
                                                fill={
                                                    dot.current >= 0
                                                        ? getBarColors(ColorsTypes.green)
                                                        : getBarColors(ColorsTypes.red)
                                                }
                                                stroke={
                                                    dot.current >= 0
                                                        ? getBarColors(ColorsTypes.green)
                                                        : getBarColors(ColorsTypes.red)
                                                }
                                                strokeWidth={2}
                                                isFront={true}
                                            />
                                        )
                                    })}

                            {/* white dot on 0,0 point*/}
                            {noSavedData && props.data.currentData !== 0 && (
                                <ReferenceDot
                                    x={0}
                                    y={0}
                                    r={6}
                                    fill="#ffffff"
                                    strokeWidth={2}
                                    stroke="#ffffff"
                                    isFront={true}
                                />
                            )}

                            {noSavedData && props.data.currentData !== 0 && (
                                <ReferenceLine
                                    x={0}
                                    stroke={
                                        props.data.currentData >= 0
                                            ? getBarColors(ColorsTypes.green)
                                            : getBarColors(ColorsTypes.red)
                                    }
                                    strokeDasharray="5, 5"
                                    strokeWidth={2}
                                    isFront={false}
                                />
                            )}

                            {!noSavedData && (
                                <Line
                                    type="linear"
                                    dataKey="activity"
                                    stroke={manuelColor ? manuelColor : 'url(#' + htmlid + ')'}
                                    strokeWidth={3}
                                />
                            )}

                            {!noSavedData && (
                                <Area
                                    type="linear"
                                    dataKey="activity"
                                    strokeWidth={2}
                                    stroke={'url(#' + htmlid + ')'}
                                    fillOpacity={0.2}
                                    fill={'url(#' + htmlid + ')'}
                                />
                            )}

                            {!noSavedData && (
                                <ReferenceLine
                                    x={interimRefPoint}
                                    stroke="#a5ce3d"
                                    strokeDasharray="5, 5"
                                />
                            )}

                            {/* Week Points*/}
                            {!noSavedData &&
                                dataChart &&
                                dataChart
                                    .filter((i) => i.name === 'confirmed')
                                    .map((dot, index) => {
                                        return (
                                            <ReferenceDot
                                                key={index}
                                                x={index}
                                                y={dot.activity}
                                                r={5}
                                                fill={
                                                    dot.activity >= 0
                                                        ? getBarColors(ColorsTypes.green)
                                                        : getBarColors(ColorsTypes.red)
                                                }
                                                stroke="none"
                                                isFront={true}
                                            />
                                        )
                                    })}
                        </ComposedChart>
                    </Div_>
                )
        }
    } else {
        return ''
    }
}

ExperimentActivity.propTypes = {
    data: PropTypes.object,
}

export default ExperimentActivity
