import settings from '../config/settings'
import axios from 'axios'
import {getRequestHeader} from '../core/utils/network'

export default class Api {
    static fetchNotifications() {
        return axios.get(`${settings.apiBaseURL}/notifications/`, getRequestHeader())
    }

    static markNotificationAsRead(notifications) {
        return axios.post(
            `${settings.apiBaseURL}/notifications/read/`,
            {notifications},
            getRequestHeader(),
        )
    }

    static markNotificationAsDeleted(notifications) {
        return axios.post(
            `${settings.apiBaseURL}/notifications/delete/`,
            {notifications},
            getRequestHeader(),
        )
    }
}
