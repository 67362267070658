import React from 'react'
import {Col, Row} from 'react-bootstrap'
import styled from 'styled-components'
import {I18n} from 'react-i18nify'
import { MID_GREY, PALE_GREY } from '../../core/ui/_colors'

const Header = styled.div`
    position: fixed;
    top: 10px;
    padding-top: 55px;
    z-index: 280;
    width: calc(100% - ${(props) => props.sidebarWidth}px);
    background-color: ${PALE_GREY};
`

const SectionHeaderContainer = styled.div`
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    color: ${MID_GREY};
    font-size: 12px;
`

const BoardHeader = ({sidebarWidth}) => (
    <Header sidebarWidth={sidebarWidth}>
        <Row>
            <Col sm={4} className={'col-xs-4'}>
                <SectionHeaderContainer>
                    {I18n.t('strategyMap.timeAreas.confirmed')}
                </SectionHeaderContainer>
            </Col>
            <Col sm={4} className={'col-xs-4'}>
                <SectionHeaderContainer>
                    {I18n.t('strategyMap.timeAreas.validating')}
                </SectionHeaderContainer>
            </Col>
            <Col sm={4} className={'col-xs-4'}>
                <SectionHeaderContainer>
                    {I18n.t('strategyMap.timeAreas.potential')}
                </SectionHeaderContainer>
            </Col>
        </Row>
    </Header>
)

export default BoardHeader
