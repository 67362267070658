import {NotificationManager} from 'react-notifications'
import {confirmAlert} from 'react-confirm-alert'
import React from 'react'

export const displayToastMessage = (type, message, title = '', autoHideTime = 8000) => {
    NotificationManager.listNotify = []
    switch (type) {
        case 'error':
            NotificationManager.error(message, title, autoHideTime)
            break
        case 'success':
            NotificationManager.success(message, title, autoHideTime)
            break
        case 'info':
            NotificationManager.info(message, title, autoHideTime)
            break
        case 'warning':
            NotificationManager.warning(message, title, autoHideTime)
            break
        default:
            break
    }
}

export const showPopupMessage = (params) => {
    const buttons = [
        {
            label: params.confirmLabel ? params.confirmLabel : 'Confirm',
            onClick: () => {
                params.onConfirmCallback && params.onConfirmCallback()
            },
        },
    ]
    if (params.onCancelCallback) {
        buttons.push({
            label: params.cancelLabel ? params.cancelLabel : 'Cancel',
            onClick: () => {
                params.onCancelCallback()
            },
        })
    }

    const getMessage = () => {
        if (params.message) {
            if (typeof params.message === 'string' || params.message instanceof String) {
                return params.message.split('\n ').map( text => <>{ text }<br /></>)
            }
            return params.message
        }
        return false
    }

    confirmAlert({
        title: params.title,
        message: getMessage(),
        buttons,
        childrenElement: params.childrenElement
    })
}