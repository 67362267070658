import {trafficLightColor} from '../ChartColorSchema'
import styled from 'styled-components';
import {ProgressBar} from 'react-bootstrap'

const barMaxWidth = 187

export const HorizontalBarContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flow-root ! important;
    font-size: 13px;
`
export const BarContainer = styled.div`
    float: left;
    width: calc( 100% - 120px );
`
export const ProgressBarCustom = styled(ProgressBar)`
    background-color: ${trafficLightColor.yellow};
    border-radius: 0px;
    margin: 0;
    .progress-bar{
        background-color: ${trafficLightColor.gray};
    }
`
export const ItemLegend = styled.div`
    display: flex;
    align-items: center;
`
export const BarLegend = styled.div`
    width: 10px;
    height: 10px;
    margin: 0 10px;
    background-color: ${({color}) => (color ? color : trafficLightColor.gray)};
`

export const DepartmentName = styled.div`
    text-align: right;
    float: right;
    width: 107px;
    display: table;
`

export const InnerLabel = styled.div`
    display: table-cell;
    vertical-align: middle;
`
