import React, {useEffect, useMemo, useState} from 'react'
import {I18n} from 'react-i18nify'
import {ButtonPrimary, ButtonSecondary} from '../../core/components/Buttons'
import Modal from '../../core/components/Modal'
import {GenerateHighResImage, collectLowDemographics} from './GenerateHighResImage'
import {REPORT_CHARTS} from '../../Charts/ChartsOrgScanReport'
import {SingleDatePicker} from 'react-dates'
import {useAnalysisSlice} from '../hooks/useAnalysisSlice'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {BarLoading} from '../../core/components/Loading'
import moment from 'moment'
import {
    ModalFooterContainer,
    ModalFooterLeft,
    ModalFooterRight,
} from '../../core/components/Modal.styles'
import {ProgressBar} from 'react-bootstrap'
import {Div} from '../../core/ui/Div'
import {getStatisticsMostInfluenced} from '../../Charts/ChartCalculations'
import LatexTextBox from './LatexTextBox'
import LatexItemizeBox from './LatexItemizeBox'
import {
    QuestionAnswerContainer,
    QuestionAnswerTitle,
    LoadingContainer,
    ChartsModal,
    CenterContainer,
    Image,
    QuestionContainer,
    ReportWelcomeContent,
    ImageWrapper, QuestionComment
} from './Reports.styles'
import {RESTRICTIONS} from "../../core/const";
import {ChartType} from "../../Charts/ChartsConfig";
import {getLanguageHuman} from "../../core/utils/language";
import {useLanguageSlice} from "../../core/hooks/useLanguageSlice";

const ReportWelcome = () =>
        <CenterContainer>
            <ReportWelcomeContent>
                <h2>{I18n.t('analysis.download.report.welcomeTitle')}</h2>
                <p>{I18n.t('analysis.download.report.welcomeDescription')}</p>
                <h5>{I18n.t('analysis.download.report.keepInMindTitle')}</h5>
                <ul>
                    <li>{I18n.t('analysis.download.report.keepInMind1')}</li>
                    <li>{I18n.t('analysis.download.report.keepInMind2')}</li>
                    <li><b>{ I18n.t("analysis.download.report.keepInMind3").replace(/%department-max%/g, RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY.department - 1 ) }</b></li>
                    <li><b>{ I18n.t("analysis.download.report.keepInMind4").replace(/%role-max%/g, RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY.role - 1 ) }</b></li>
                </ul>
                <p>{I18n.t('analysis.download.report.timeWarning')}</p>
            </ReportWelcomeContent>
        </CenterContainer>;

const UnsupportedLanguage = ({languageChanged}) => {
    const languages = Object.keys(REPORTS).map(lang => ({word: getLanguageHuman(I18n._localeKey, lang), code: lang}) );
    const languagesString = languages.map( lang => lang.word).join( I18n.t("app.concat.or") );
    return <CenterContainer>
        <ReportWelcomeContent>
            <h2>{I18n.t('analysis.download.report.welcomeTitle')}</h2>
            <h5>{I18n.t('analysis.download.report.notSupportedLanguage') + languagesString + "."}</h5>
            <p>{I18n.t('analysis.download.report.supportedLanguages')}</p>
            <ul>
                { languages.map( lang => <li key={lang.code}><a style={{cursor: 'pointer'}} onClick={() => languageChanged(lang.code)}>{ lang.word }</a></li>) }
            </ul>
        </ReportWelcomeContent>
    </CenterContainer>
};

const REPORTS = {
    'en': {
        'default': 'English',
    },
    'de': {
        'default': 'German'
    }
}

const QuestionType = {
    textLine: 'textLine',
    textBox: 'textbox',
    number: 'number',
    date: 'date',
    year: 'year',
    bulletPoints: 'bulletPoints'
}

const Questions = [
    {
        tag: 'INPUT-Company',
        question: 'report.questions.companyName',
        answer: '',
        placeholder: 'report.questions.companyNamePlaceholder',
        type: QuestionType.textLine,
    },
    {
        tag: 'INPUT-Coach',
        question: 'report.questions.coaches',
        answer: '',
        placeholder: 'report.questions.coachesPlaceholder',
        type: QuestionType.textBox,
    },
    {
        tag: 'INPUT-START',
        question: 'report.questions.startDate',
        answer: '',
        placeholder: '',
        comment: 'report.questions.DateComment',
        type: QuestionType.textLine,
    },
    {
        tag: 'INPUT-END',
        question: 'report.questions.endDate',
        answer: '',
        placeholder: '',
        comment: 'report.questions.DateComment',
        type: QuestionType.textLine,
    },
    {
        tag: 'INPUT-YEAR',
        question: 'report.questions.year',
        answer: '',
        placeholder: '',
        type: QuestionType.year,
    },
    {
        tag: 'INPUT-Annotation 01',
        question: 'report.questions.Annotation #1',
        answer: '',
        placeholder: '',
        images: ['piechart-years'],
        type: QuestionType.textBox,
        hint: {
            charlength: 120,
        },
    },
    {
        tag: 'INPUT-Annotation 02',
        question: 'report.questions.Annotation #2',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-department-vs-mood'],
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 03',
        question: 'report.questions.Annotation #3',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-strategic'],
        hint: {
            charlength: 200,
        },
    },
    {
        tag: 'INPUT-Annotation 04',
        question: 'report.questions.Annotation #4',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-frequency', 'bar-frequency-vs-type'],
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 05',
        question: 'report.questions.Annotation #5',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['triangle-T3'],
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 06',
        question: 'report.questions.Annotation #6',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-year-vs-mood'],
        hint: {
            charlength: 100,
        },
    },
    {
        tag: 'INPUT-Annotation 07',
        question: 'report.questions.Annotation #7',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-department-vs-mood'],
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 08',
        question: 'report.questions.Annotation #8',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-role-vs-mood'],
        hint: {
            charlength: 200,
        },
    },
    {
        tag: 'INPUT-Annotation 09',
        question: 'report.questions.Annotation #9',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['bar-owner'],
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 10',
        question: 'report.questions.Annotation #10',
        answer: '',
        placeholder: '',
        images: ['triangle-T4'],
        type: QuestionType.textBox,
        hint: {
            charlength: 200,
        },
    },
    {
        tag: 'INPUT-Annotation 11',
        question: 'report.questions.Annotation #11',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['triangle-T5'],
        hint: {
            charlength: 300,
        },
    },
    {
        tag: 'INPUT-Annotation 12',
        question: 'report.questions.Annotation #12',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['culture-all'],
        hint: {
            charlength: 300,
        },
    },
    {
        tag: 'INPUT-Annotation 13',
        question: 'report.questions.Annotation #13',
        answer: '',
        placeholder: '',
        images: [
            'triangle-T2-positive',
            'triangle-T2-negative',
            'triangle-T3-positive',
            'triangle-T3-negative',
        ],
        type: QuestionType.textBox,
        hint: {
            charlength: 100,
        },
    },
    {
        tag: 'INPUT-Annotation 14',
        question: 'report.questions.Annotation #14',
        answer: '',
        placeholder: '',
        images: ['table-crossproduct-owner-MCQ+triangle'],
        type: QuestionType.textBox,
        hint: {
            charlength: 300,
        },
    },
    {
        tag: 'INPUT-Annotation 15',
        question: 'report.questions.Annotation #15',
        answer: '',
        placeholder: '',
        type: QuestionType.textBox,
        images: ['table-crossproduct-timetaken-type-triangle'],
        hint: {
            charlength: 200,
        },
    },
    {
        tag: 'INPUT-Annotation 17',
        question: 'report.questions.Annotation #17',
        answer: '',
        placeholder: '',
        images: [
            'bar-timetaken-filtered-owner-colleague',
            'bar-timetaken-filtered-owner-external',
            'bar-timetaken-filtered-owner-groupofpeople',
            'bar-timetaken-filtered-owner-manager',
            'bar-timetaken-filtered-owner-me',
            'bar-timetaken-filtered-owner-notsure',
            'bar-timetaken-filtered-owner-team',
        ],
        type: QuestionType.textBox,
        hint: {
            charlength: 200,
        },
    },
    {
        tag: 'INPUT-Annotation 24',
        question: 'report.questions.Annotation #17B',
        answer: '',
        placeholder: '',
        images: [],
        type: QuestionType.textBox,
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 18',
        question: 'report.questions.Annotation #18',
        answer: '',
        placeholder: '',
        images: [
            'triangle-T2-strategic',
            'triangle-T2-tactical',
            'triangle-T5-strategic',
            'triangle-T5-tactical',
            'triangle-T4-strategic',
            'triangle-T4-tactical',
        ],
        type: QuestionType.textBox,
        hint: {
            charlength: 100,
        },
    },
    {
        tag: 'INPUT-BULLETS-POSITIVE',
        question: 'report.questions.PositiveEmotions',
        answer: '',
        placeholder: 'report.questions.EmotionsPlaceholder',
        images: ['culture-orientation-positive', 'triangle-T4-positive', 'triangle-T5-positive'],
        type: QuestionType.bulletPoints,
        hint: {
            charlength: 100,
        },
    },
    {
        tag: 'INPUT-BULLETS-NEGATIVE',
        question: 'report.questions.NegativeEmotions',
        answer: '',
        placeholder: 'report.questions.EmotionsPlaceholder',
        images: ['culture-orientation-negative', 'triangle-T4-negative', 'triangle-T5-negative'],
        type: QuestionType.bulletPoints,
        hint: {
            charlength: 100,
        },
    },
    {
        tag: 'INPUT-Annotation 19',
        question: 'report.questions.Annotation #19',
        answer: '',
        placeholder: '',
        images: ['table-crossproduct-type-triangle-compromise'],
        type: QuestionType.textBox,
        hint: {
            charlength: 1024,
        },
    },
    {
        tag: 'INPUT-Annotation 20',
        question: 'report.questions.Annotation #20',
        answer: '',
        placeholder: '',
        images: ['table-crossproduct-owner-MCQ+triangle'],
        type: QuestionType.textBox,
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 21',
        question: 'report.questions.Annotation #21',
        answer: '',
        placeholder: '',
        images: ['bar-communication-vs-emote'],
        type: QuestionType.textBox,
        hint: {
            charlength: 400,
        },
    },
    {
        tag: 'INPUT-Annotation 22',
        question: 'report.questions.Annotation #22',
        answer: '',
        placeholder: '',
        images: [
            'culture-leader-type-filtered-timetaken-rightaway',
            'bar-timetaken-selectbar-rightaway',
            'bar-owner-filtered-rightaway',
        ],
        type: QuestionType.textBox,
        hint: {
            charlength: 100,
        },
    },
    {
        tag: 'INPUT-Annotation 23',
        question: 'report.questions.Annotation #23',
        answer: '',
        placeholder: '',
        images: [
            'culture-leader-type-filtered-timetaken-hour',
            'bar-timetaken-selectbar-hour'
        ],
        type: QuestionType.textBox,
        hint: {
            charlength: 100,
        },
    },
]

const getMostInfluencedDiagrams = (decisions) => {
    const top3Raw = getStatisticsMostInfluenced(decisions)
        .reverse()
        .filter((a, i) => i < 3)
        .map((entry) => entry.y)

    const top3 = {positive: [], negative: []}

    top3Raw.forEach((diagram) => {
        const diagramName = diagram.split('.').reverse()[0]
        let prefix = ''
        switch (diagramName) {
            case 'leader-type':
            case 'orientation':
            case 'effectiveness':
            case 'value-drivers':
                prefix = 'culture-' + diagramName
                break
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
                prefix = 'triangle-T' + diagramName
                break
            case 'type':
            case 'frequency':
            case 'period':
            case 'bar':
                prefix = 'bar-' + diagramName
                break
            case 'decision_owner':
                prefix = 'bar-owner'
                break
            default:
                prefix = diagramName
        }

        top3.positive.push(prefix + '-positive')
        top3.negative.push(prefix + '-negative')
    })

    return top3
}

const WaitReportInfo = () => {
    const {Actions} = useAnalysisSlice()

    useEffect(() => {
        setTimeout(() => {
            Actions.resetWaitReportDownload();
        }, 5000)
    }, [])

    return (
        <LoadingContainer>
            <p>{I18n.t('analysis.download.report.reportDownloadInfo')}</p>
        </LoadingContainer>
    )
}


const MODAL_PAGES = {
    'unsupportedLanguage': 'unsupportedLanguage',
    'welcome': 'welcome',
    'questions': 'questions',
    'generation': 'generation'
}

const GenerateReportModal = () => {
    const [open, setOpen] = useState(false)
    const [startProcessingImage, setStartProcessingImage] = useState(false)
    const [reportAnswers, setReportAnswers] = useState({})
    const {Actions, ...analysis} = useAnalysisSlice()
    const {decisions, Actions: DecisionActions} = useDashboardSlice()
    const [progressBarStatus, setProgressBarStatus] = useState('')
    const [currentImageIndex, setCurrentImageIndex] = useState(null)
    const dataPoints = {dataShown: collectLowDemographics(decisions), dataCompare: []};
    const {Actions: LanguageActions} = useLanguageSlice();
    const [version, setVersion] = useState();

    const supportedLanguages = Object.keys(REPORTS);
    const [page, setPage] = useState( supportedLanguages.includes(I18n._localeKey) || ! I18n._localeKey ? MODAL_PAGES.welcome : MODAL_PAGES.unsupportedLanguage );

    const CHARTS_FOR_REPORT =[
        ...REPORT_CHARTS,
        ...REPORT_CHARTS
            .filter( chart => [ChartType.Triangle,ChartType.HeatMapReactangle,ChartType.BarVertical,ChartType.BarHorizontal].includes(chart.Type))
            .map( chart => ({...chart, size: 300, testId: chart.testId+'--small'}))
        ].flat();

    const reportChartMap = useMemo(() => {
        const data = {}

        CHARTS_FOR_REPORT.forEach((item) => {
            data[item.testId] = item
        })
        return data
    }, [REPORT_CHARTS])

    const totalImages = CHARTS_FOR_REPORT.length - 1;

    const imageGenerationsCompleted = () => {
        setStartProcessingImage(false);
        Actions.generateReport( REPORTS[I18n._localeKey].default );
    }

    const handleInputChange = (fieldName, value) => {
        const changedData = reportAnswers
        changedData[fieldName] = value
        setReportAnswers(changedData)

        const data = Object.keys(changedData).map((item) => ({
            label: item,
            value: changedData[item],
        }))

        Object.keys(mostInfluenced).forEach((mood) =>
            mostInfluenced[mood].forEach((diagram, i) =>
                data.push({
                    label: 'CHART-MOST-INFLUENCED-VALUES-' + mood + '-' + i,
                    value: diagram,
                }),
            ),
        )

        Actions.saveReportAnswers(data);
        if( fieldName === "DRAFTMODE-ENABLE" ) {
            setVersion(value);
        }
    }

    const mostInfluenced = getMostInfluencedDiagrams(decisions)

    const onModalClose = () => {
        setStartProcessingImage(false);
        setOpen(false);
        setPage( MODAL_PAGES.welcome );
    }

    useEffect(() => {
        Actions.getReportAnswers();
        setPage( supportedLanguages.includes(I18n._localeKey) || ! I18n._localeKey ? MODAL_PAGES.welcome : MODAL_PAGES.unsupportedLanguage );
        if( page === MODAL_PAGES.unsupportedLanguage )
            setOpen(false);
    }, [I18n._localeKey]);

    useEffect(() => {
        if (analysis.answers && analysis.answers.length > 0) {
            const data = {}
            analysis.answers.forEach((answer) => {
                data[answer.label] = answer.value
            })
            setReportAnswers(data)
        }
        setVersion(analysis.answers["DRAFTMODE-ENABLE"])
    }, [analysis.answers])

    const getQuestionDisplay = (question) => {
        const annotation = question.question.split(' ');
        const title = annotation.length === 2
            ? I18n.t( annotation[0] + ' ' + annotation[1] )
            : I18n.t( question.question );
        switch (question.type) {
            case QuestionType.textBox:
                return (
                    <QuestionAnswerContainer>
                        <QuestionAnswerTitle>{ title }</QuestionAnswerTitle>
                        <LatexTextBox
                            testID={question.tag}
                            placeholder={I18n.t(question.placeholder)}
                            defaultValue={I18n.t(reportAnswers[question.tag] || '')}
                            hint={question.hint}
                            onChange={(value) => handleInputChange(question.tag, value)}
                        />
                    </QuestionAnswerContainer>
                )
            case QuestionType.textLine:
                return (
                    <QuestionAnswerContainer>
                        <QuestionAnswerTitle>{ title }</QuestionAnswerTitle>
                        <QuestionComment>{ I18n.t(question.comment) }</QuestionComment>
                        <LatexTextBox
                            testID={question.tag}
                            rows={1}
                            placeholder={I18n.t(question.placeholder)}
                            defaultValue={I18n.t(reportAnswers[question.tag] || '')}
                            hint={question.hint}
                            onChange={(value) => handleInputChange(question.tag, value)}
                        />
                    </QuestionAnswerContainer>
                )
            case QuestionType.year:
                return (
                    <QuestionAnswerContainer>
                        <QuestionAnswerTitle>{ title }</QuestionAnswerTitle>
                        <LatexTextBox
                            testID={question.tag}
                            placeholder={I18n.t(question.placeholder)}
                            defaultValue={I18n.t(reportAnswers[question.tag] || '')}
                            hint={question.hint}
                            onChange={(value) => handleInputChange(question.tag, value)}
                        />
                    </QuestionAnswerContainer>
                )
            case QuestionType.date:
                return (
                    <QuestionAnswerContainer>
                        <QuestionAnswerTitle>{ title }</QuestionAnswerTitle>
                        <SingleDatePicker
                            date={moment(reportAnswers[question.tag])}
                            id={question.tag}
                            onFocusChange={() => true}
                            onDateChange={(date) => handleInputChange(question.tag, date)}
                        />
                    </QuestionAnswerContainer>
                )
            case QuestionType.bulletPoints:
                return (
                    <QuestionAnswerContainer>
                        <QuestionAnswerTitle>{ title }</QuestionAnswerTitle>
                        <LatexItemizeBox
                            placeholder={I18n.t(question.placeholder)}
                            defaultValue={I18n.t(reportAnswers[question.tag] || '')}
                            onChange={(value) => handleInputChange(question.tag, value)}
                            hint={question.hint}
                        />
                    </QuestionAnswerContainer>
                )
            default:
                return null
        }
    }

    const getChart = (question, imageIndex, size) => {
        if (!question.image && !question.images) return null
        let chart = null
        if (imageIndex > -1 && question.images) {
            chart = reportChartMap[question.images[imageIndex]]
        } else {
            chart = reportChartMap[question.image]
        }
        if (!chart) return null

        return (
            <div style={{width: size || chart.size, margin: 10}}>
                <ChartsModal
                    testId={chart.testId + '_report_preview'}
                    Type={chart.Type}
                    subType={chart.subType && chart.subType}
                    subTypes={chart.subTypes && chart.subTypes}
                    columns={chart.columns}
                    colorSchema={chart.colorSchema}
                    dataObject={dataPoints}
                    filter={chart.filter}
                    isCompared={false}
                    language={I18n._localeKey}
                    legend={chart.legend}
                    coordinate={{height: size || chart.size || size, width: size || chart.size}}
                />
            </div>
        )
    }

    const imageScaling = (type) => {
        switch (type) {
            case 'triangle':
                return {scale: 0.7, width: 415}
            case 'culture':
                return {scale: 0.7, width: 415, height: 520}
            case 'piechart':
                return {scale: 1, width: 415}
            case 'table':
                return {scale: 1, width: 850}
            case 'bar':
                return {scale: 0.65, width: 450, marginLeft: '-40px'}
            default:
                return {scale: 0.7, width: 415}
        }
    }

    const QuestionComponent = useMemo(() => {
        const questions = Questions.map((question) => {
            if (question.tag === 'INPUT-BULLETS-POSITIVE')
                return {...question, images: mostInfluenced.positive}
            if (question.tag === 'INPUT-BULLETS-NEGATIVE')
                return {...question, images: mostInfluenced.negative}
            return question
        })

        return questions.map((question, idx) => (
            <QuestionContainer key={idx}>
                {getQuestionDisplay(question)}

                {question.images &&
                    question.images.map((tag, i) => {
                        if (!reportChartMap[tag]) return null

                        const adjust = imageScaling(tag.split('-')[0])

                        return (
                            <ImageWrapper
                                width={adjust.width + 'px'}
                                key={idx}
                                height={adjust.height ? adjust.height + 'px' : 'auto'}
                                marginLeft={adjust.marginLeft}
                            >
                                <Image key={i} scale={adjust.scale}>
                                    {getChart(question, i, adjust.width)}
                                </Image>
                            </ImageWrapper>
                        )
                    })}
            </QuestionContainer>
        ))
    }, [Questions, reportAnswers, version]);

    useEffect( () => {
        if( page === MODAL_PAGES.generation && analysis.waitReportDownload ) {
            setPage(MODAL_PAGES.welcome);
            setOpen(false);
        }
    }, [analysis.waitReportDownload]);

    const _Modal = page => {
        const elements = {
            title: '',
            helpText: '',
            body: <></>,
            footerContent: <></>
        }
        switch(page){
            case MODAL_PAGES.unsupportedLanguage:
                elements.body = <UnsupportedLanguage languageChanged={(langCode) => LanguageActions.setLanguage( langCode )} />;
                elements.footerContent =
                        <ModalFooterContainer>
                            <ModalFooterLeft />
                            <ModalFooterRight>
                                <ButtonSecondary type="button" onClick={ onModalClose }>
                                    {I18n.t('analysis.buttons.cancel')}
                                </ButtonSecondary>
                            </ModalFooterRight>
                        </ModalFooterContainer>
                break;
            case MODAL_PAGES.welcome:
                elements.body = <ReportWelcome />;
                elements.footerContent =
                        <ModalFooterContainer>
                            <ModalFooterLeft />
                            <ModalFooterRight>
                                <ButtonSecondary type="button" onClick={ onModalClose }>
                                    {I18n.t('analysis.buttons.cancel')}
                                </ButtonSecondary>
                                <ButtonPrimary
                                    data-testid={'btn_NextGenerate'}
                                    disabled={analysis.isSavingReportAnswers}
                                    onClick={ () => setPage( MODAL_PAGES.questions ) }
                                >{ I18n.t('analysis.buttons.Next') }
                                </ButtonPrimary>
                            </ModalFooterRight>
                        </ModalFooterContainer>
                break;
            case MODAL_PAGES.questions:
                elements.title = I18n.t('analysis.dialog.topic.ReportGeneration');
                elements.body = QuestionComponent;
                elements.footerContent = <ModalFooterContainer>
                            <ModalFooterLeft />
                            <ModalFooterRight>
                                <ButtonSecondary type="button" onClick={onModalClose}>
                                    {I18n.t('analysis.buttons.cancel')}
                                </ButtonSecondary>
                                <ButtonPrimary
                                    data-testid={'btn_NextGenerate'}
                                    disabled={analysis.isSavingReportAnswers}
                                    onClick={() => {
                                        setStartProcessingImage(true);
                                        setPage( MODAL_PAGES.generation );
                                    } }
                                >{ I18n.t('analysis.buttons.GenerateReport') }
                                </ButtonPrimary>
                            </ModalFooterRight>
                        </ModalFooterContainer>
                break;
            case MODAL_PAGES.generation:
                elements.title = I18n.t('analysis.highResImage.generatingImages');
                elements.footerContent =
                    <ModalFooterContainer>
                            <Div marginBottom={'-20px'} width={'100%'}>
                                <ProgressBar
                                    now={(currentImageIndex / totalImages) * 100}
                                    label={progressBarStatus}
                                />
                            </Div>
                            <ModalFooterLeft />
                            <ModalFooterRight>
                                <ButtonSecondary type="button" onClick={onModalClose}>
                                    {I18n.t('analysis.buttons.cancel')}
                                </ButtonSecondary>
                            </ModalFooterRight>
                        </ModalFooterContainer>;
                elements.body = <>
                    { analysis.isSaving && (
                        <LoadingContainer>
                            <BarLoading />
                        </LoadingContainer>
                    ) }
                    <GenerateHighResImage
                        isReportGeneration
                        startProcessingImage={startProcessingImage}
                        cancelGeneration={() => setStartProcessingImage(false)}
                        imageGenerationsCompleted={imageGenerationsCompleted}
                        setProgressBarStatus={setProgressBarStatus}
                        setCurrentImageIndex={setCurrentImageIndex}
                        currentImageIndex={currentImageIndex}
                        selectedChart={ CHARTS_FOR_REPORT }
                    />
                    { analysis.waitReportDownload && <WaitReportInfo /> }
                </>
        }

        return <Modal
            onClose={onModalClose}
            closeOnOverlayClick={false}
            isOpen={open}

            title={ elements.title }
            helpText={ elements.helpText }
            footerContent={ elements.footerContent }
        >
            { elements.body }
        </Modal>
    }

    return <>
        { open && _Modal( page ) }
        <ButtonSecondary
            data-testid={'btnGenerateReport'}
            onClick={() => {
                DecisionActions.setSelectedPoints({})
                setOpen(true)
                setStartProcessingImage(false)
            }}
        >
            {I18n.t('analysis.buttons.GenerateReport')}
        </ButtonSecondary>
    </>;
}

export default React.memo(GenerateReportModal)
