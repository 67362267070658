import React, {useState} from 'react'
import {I18n} from 'react-i18nify'
import {ButtonSecondary} from '../../core/components/Buttons'
import Modal from '../../core/components/Modal'
import {GenerateHighResImage} from './GenerateHighResImage'
import {ModalFooterContainer, ModalFooterRight,} from '../../core/components/Modal.styles'
import {ProgressBar} from 'react-bootstrap'
import {Div} from '../../core/ui/Div'
import {INSPECT_CHARTS} from '../inspect/InspectGraphs'

const GenerateHighResImageInspectModal = () => {
    const [open, setOpen] = useState(false)
    const [startProcessingImage, setStartProcessingImage] = useState(false)
    const [progressBarStatus, setProgressBarStatus] = useState('')
    const [currentImageIndex, setCurrentImageIndex] = useState(null)

    const resetState = () => {
        setOpen(false)
        setStartProcessingImage(false)
        setProgressBarStatus('')
        setCurrentImageIndex(null)
    }

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={open}
                onClose={resetState}
                title={I18n.t('analysis.highResImage.generatingImages')}
                footerContent={
                    <ModalFooterContainer>
                        {startProcessingImage && (
                            <Div marginBottom={'-20px'} width={'100%'}>
                                <ProgressBar
                                    now={( (currentImageIndex + 1) / INSPECT_CHARTS.length) * 100}
                                    label={progressBarStatus}
                                />
                            </Div>
                        )}
                        <ModalFooterRight>
                            <ButtonSecondary type="button" onClick={resetState}>
                                {I18n.t('analysis.buttons.cancel')}
                            </ButtonSecondary>
                        </ModalFooterRight>
                    </ModalFooterContainer>
                }
            >
                <GenerateHighResImage
                    startProcessingImage={startProcessingImage}
                    cancelGeneration={() => setStartProcessingImage(false)}
                    imageGenerationsCompleted={resetState}
                    setProgressBarStatus={setProgressBarStatus}
                    setCurrentImageIndex={setCurrentImageIndex}
                    currentImageIndex={currentImageIndex}
                    selectedChart={INSPECT_CHARTS}
                />
            </Modal>
            <ButtonSecondary
                onClick={() => {
                    setOpen(true)
                    setStartProcessingImage(true)
                }}
            >
                {I18n.t('analysis.buttons.GenerateImages')}
            </ButtonSecondary>
        </>
    )
}

export default React.memo(GenerateHighResImageInspectModal)
