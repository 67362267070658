import React from 'react'
import {I18n} from 'react-i18nify'
import {
    DashboardItem,
    DashboardItemContainer,
    DashboardItemLabel, DiagramLegend
} from './Dashboard.styles'
import Charts from '../../Charts/Charts'
import {getTooltip} from '../../Charts/ChartsOrgScan'
import ChartLegends, {CulturalChartEmoteLegends, MeEmoteLegends} from "./DashboardChartLegend";
import { SIDEBARS } from '../../core/redux/slices'
import { useCoreSlice } from '../../core/hooks/useCoreSlice'

export const ZoomIcon = ({zoom, setZoom}) =>
    <div style={{float: 'right'}}>
        <i className="material-icons pull-right" aria-hidden="true" role="button" onClick={setZoom}>{ zoom ? "zoom_out" : "zoom_in" }</i>
    </div>;
export const SettingIcon = ({onClick}) =>
    <div style={{float: 'right'}}>
        <i className="material-icons pull-right" aria-hidden="true" role="button" onClick={onClick}>settings</i>
    </div>;
export const DeleteIcon = ({onClick}) =>
<div style={{float: 'right'}}>
    <i className="material-icons pull-right" aria-hidden="true" role="button" onClick={onClick}>delete</i>
</div>;

const DashboardChart = (props) => {
    const {title, colorSchema, optionalStyle, tooltip, testId} = props.chartData;
    const {client, sidebarOpen} = useCoreSlice();
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;

    const isZoomAbleDiagram = testId && testId.substring(0,7) === "culture" || title && title.substring(0,10) === "leadership";
    const isBigScreen = window.screen.width >= 1170 && window.screen.height >= 940;

    const chart = (zoom) =>
         <DashboardItemContainer zooming={ zoom }>
            <DashboardItem className="dashboard-item" zoom={ zoom }>
                <DashboardItemLabel>
                    {I18n.t(title)}
                    <div style={{float: 'right'}}>
                        {tooltip && getTooltip(I18n.t(tooltip), `${props.keyIndex}-tip`)}
                    </div>
                    { ! props.noConfig && isEditMode && <SettingIcon onClick={props.flip} /> }
                    { ! props.noConfig && isEditMode && <DeleteIcon onClick={props.delete} /> }
                    {
                        isBigScreen && ! props.isDashboardLite
                        && <ZoomIcon zoom={zoom} setZoom={props.clickZoom} />
                    }
                </DashboardItemLabel>
                <>{ props.optinalText ? props.optinalText === 'all' ? null : I18n.t("dashboard.Filters.for").replace("%s", props.optinalText) : null }</>
                <DiagramLegend>
                    <ChartLegends
                        colorSchema={colorSchema}
                        optionalStyle={optionalStyle}
                        isCompared={props.isCompared}
                        appliedFilters={props.appliedFilters} />
                    { isZoomAbleDiagram && ( props.onlyOwnDecisions ? <MeEmoteLegends /> : <CulturalChartEmoteLegends /> ) }
                </DiagramLegend>
                <div className={"panel-body " + (props.isDashboardLite ? "lite" : "complete")}>
                   <Charts
                        {...props.chartData}
                        width={zoom ? '100%' : props.chartData.width}
                        height={zoom ? '772' : props.chartData.height}
                        dataObject={props.dataObject}
                        isCompared={props.isCompared}
                        language={I18n._localeKey}
                        maxDecisionCount={props.isDashboardLite && client.max_decision_count}
                        client={client}
                        zoom={props.zoom}
                        coordinate={zoom ? {height: 600, width: 600} : undefined}
                    />
                </div>
            </DashboardItem>
        </DashboardItemContainer>

    return <>
        { chart(props.zoom) }
    </>
}

export default React.memo(DashboardChart)
