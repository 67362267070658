import React from "react";
import {I18n} from "react-i18nify";
import {PAGES} from "../../core/const";
import {Context} from "../Stories";

export const userSettings = (props) => ({

    page: PAGES.OrgScan.Dashboard,
    steps: [
            {
                selector: '.user',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.user.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.user.content")}</Context>
                </div>
            },
            {
                selector: '.user-profile-sidebar',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.user-sidebar.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.user-sidebar.content")}</Context>
                </div>,
                action: () => props.sidebar.openUserProfileSidebar()
            },
        ]});

export const systemSettings = (props) => ({

    page: PAGES.OrgScan.Dashboard,
    steps: [
        {
            selector: '.user',
            content: <div>
                <h4>{I18n.t("stories.dashboard.intro.steps.user.header")}</h4>
                <Context>{I18n.t("stories.dashboard.intro.steps.user.content")}</Context>
            </div>,
            action: () => props.sidebar.openUserProfileSidebar()
        },
        {
            selector: '.user-profile-sidebar',
            content: <div>
                <h4>{I18n.t("stories.dashboard.intro.steps.user-sidebar.header")}</h4>
                <Context>{I18n.t("stories.dashboard.intro.steps.user-sidebar.content")}</Context>
            </div>
        },
        {
            selector: '.btn-management',
            content: <div>
                <h4>{I18n.t('stories.dashboard.system-settings.steps.user-sidebar-button.header')}</h4>
                <Context>{I18n.t("stories.dashboard.system-settings.steps.user-sidebar-button.content")}</Context>
            </div>,
            action: () => props.sidebar.openUserProfileSidebar()
        },
        {
            selector: '.usersManagement',
            content: <div>
                <h4>{I18n.t("stories.dashboard.system-settings.steps.user-management-tab-user.header")}</h4>
                <Context>{I18n.t("stories.dashboard.system-settings.steps.user-management-tab-user.content")}</Context>
            </div>,
            action: () => {
                setTimeout( () => {
                    props.sidebar.openUserProfileSidebar();
                    document.getElementById("btn-management") && document.getElementById("btn-management").click();
                    document.getElementById("usersManagement") && document.getElementById("usersManagement").click();
                    props.sidebar.closeSidebar();
                }, 1000);
            }
        },
        {
            selector: '.tab-content-billing',
            content: <div>
                <h4>{I18n.t("stories.dashboard.system-settings.steps.user-management-tab-user-done.header")}</h4>
                <Context>{I18n.t("stories.dashboard.system-settings.steps.user-management-tab-user-done.content")}</Context>
            </div>
        }
        ]});

export const intro = (props) => ({

    page: PAGES.OrgScan.Dashboard,
    steps: [
            {
                content: <div>
                    <h3>{I18n.t("stories.dashboard.intro.steps.welcome.header")}</h3>
                    <Context>{I18n.t("stories.dashboard.intro.steps.welcome.content")}</Context>
                </div>,
            },
            {
                content: <div>
                    <h3>{I18n.t("stories.dashboard.intro.steps.nav.header")}</h3>
                    <Context>{I18n.t("stories.dashboard.intro.steps.nav.content")}</Context>
                </div>,
            },
            {
                selector: '.strategy-map',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.nav-strategymap.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.nav-strategymap.content")}</Context>
                </div>,
            },
            {
                selector: '.capture',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.nav-capture.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.nav-capture.content")}</Context>
                </div>,
            },
            {
                selector: '.analysis-tool',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.nav-analysis.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.nav-analysis.content")}</Context>
                </div>
            },
            {
                content: <div>
                    <h3>{I18n.t("stories.dashboard.intro.steps.dashboard.header")}</h3>
                    <Context>{I18n.t("stories.dashboard.intro.steps.dashboard.content")}</Context>
                </div>,
            },
            {
                selector: '.dashboard-decision-count',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.dashboard-decision-count.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.dashboard-decision-count.content")}</Context>
                </div>,
                required: ! (props.client && props.isDashboardFull)
            },
            {
                selector: '.filters',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.dashboard-filter.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.dashboard-filter.content")}</Context>
                </div>,
                required: ! (props.client && props.isDashboardFull)
            },
            {
                selector: '.dashboard-body',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.dashboard-diagrams.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.dashboard-diagrams.content")}</Context>
                </div>,
                required: ! (props.client && props.isDashboardFull)
            },
            {
                selector: '.dashboard-body',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.dashboard-lite-diagrams.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.dashboard-lite-diagrams.content")}</Context>
                </div>,
                required: props.client && props.isDashboardFull
            },
            {
                selector: '.notifications',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.notifications.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.notifications.content")}</Context>
                </div>,
                required: props.client && props.client.feature.strategymap
            },
            {
                selector: '.user',
                content: <div>
                    <h4>{I18n.t("stories.dashboard.intro.steps.user.header")}</h4>
                    <Context>{I18n.t("stories.dashboard.intro.steps.user.content")}</Context>
                </div>
            }
    ]});
