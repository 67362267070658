import React from 'react'

const ICDown = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="ic_down"
            width="11.485"
            height="13.535"
            viewBox="0 0 11.485 13.535"
        >
            <g id="Gruppe_328" data-name="Gruppe 328" transform="rotate(90 345.768 -300.818)">
                <path
                    id="Rechteck_446"
                    d="M0 0h2v8H0z"
                    className="cls-1"
                    data-name="Rechteck 446"
                    transform="rotate(-135 336.541 -113.069)"
                    fill={'#444f57'}
                />
                <path
                    id="Rechteck_447"
                    d="M0 0h2v8H0z"
                    className="cls-1"
                    data-name="Rechteck 447"
                    transform="rotate(-45 368.628 -770.862)"
                    fill={'#444f57'}
                />
                <path
                    id="Rechteck_19750"
                    d="M0 0h2v11H0z"
                    className="cls-1"
                    data-name="Rechteck 19750"
                    transform="rotate(-90 343.373 -303.213)"
                    fill={'#444f57'}
                />
            </g>
        </svg>
    )
}
export default ICDown
