import axios from 'axios'
import settings from '../config/settings.json'
import {getRequestHeader, getSimpleRequestHeader} from "./utils/network";

export default class Api {
    static fetchSubScriptions(){
        return axios.get( '/clients/subscription-plans/', getRequestHeader() );
    }
    static getClient() {
        return axios.get(`${settings.apiBaseURL}/client-details/`, getRequestHeader());
    }
    static createClient(data) {
        return axios.post(`${settings.apiBaseURL}/clients/setup/`, data,  {...getRequestHeader(), timeout: 30000});
    }
    static updateClient(data) {
        return axios.post(`${settings.apiBaseURL}/client/update/`, data,  getRequestHeader());
    }
    static fetchDemographics() {
        return axios.get(`${settings.apiBaseURL}/demographics/`, getRequestHeader());
    }
    static fetchTeams() {
        return axios.get(`${settings.apiBaseURL}/teams/`, getRequestHeader());
    }
    static createTeam(payload) {
        return axios.post(`${settings.apiBaseURL}/teams/`, payload, getRequestHeader());
    }
    static updateTeam(payload) {
        return axios.patch(`${settings.apiBaseURL}/teams/${payload.id}/`, payload, getRequestHeader());
    }
    static deleteTeam(id) {
        return axios.delete(`${settings.apiBaseURL}/teams/${id}/`, getRequestHeader());
    }
    static updateDemographicsTenant(payload, detail) {
        return axios.post(`${settings.apiBaseURL}/tenant-demographics/${detail}/`, {payload}, getRequestHeader());
    }
    static fetchLanguages() { 
        return axios.get(`${settings.apiBaseURL}/languages/`, getRequestHeader());
    }
    static updateLanguage(language) {
        return axios.post(`${settings.apiBaseURL}/languages/`, {language}, getRequestHeader());
    }
    static fetchGit() {
        return axios.get(`${settings.apiBaseURL}/git/`, getSimpleRequestHeader());
    }
    static upgradeLeadership() {
        return axios.post(`${settings.apiBaseURL}/upgrade-version/leadership/`, {}, getRequestHeader())
    }
    static downgradeLeadership() {
        return axios.post(`${settings.apiBaseURL}/downgrade-version/leadership/`, {}, getRequestHeader())
    }
    static runCommand(command) {
        return axios.get(`${settings.apiBaseURL}/run-command/${command}/`, getSimpleRequestHeader());
    }
    static sendUserEngagementEmail(payload) {
        return axios.post(`${settings.apiBaseURL}/user-engagement/send-email/`, payload, getRequestHeader());
    }
    static getUserEngagementLastEmail() {
        return axios.get(`${settings.apiBaseURL}/user-engagement/get-last-email-sent/`, getRequestHeader());
    }
}