import PropTypes from 'prop-types'
import React from 'react'
import {I18n} from 'react-i18nify'
import {DURATION_OPTIONS, getDurationOptionsLabels} from '../Dashboard'
import {assoc} from 'ramda'
import SelectWrapper from '../../../core/components/SelectWrapper'
import { ExperimentCompareInfo } from './DashboardFiltersContainer.styles'

export const DurationFilterComponent = props => {

    const changeDurationOption = duration =>
        props.handleFiltersChange( assoc( 'duration', duration.value, props.appliedFilters ) );

    if( props.appliedFilters.experiment && props.appliedFilters.experiment.length > 0 )
        return <ExperimentCompareInfo>{ I18n.t('dashboard.compareInfo') }</ExperimentCompareInfo>;
    
    const options = Object.values(DURATION_OPTIONS)
        .filter( duration => duration !== DURATION_OPTIONS.NOW)
        .map( duration => ({
            value: duration,
            label: I18n.t(getDurationOptionsLabels(duration)),
        }));

    return (
        <SelectWrapper
            minimalistView
            onChange={changeDurationOption}
            defaultValue={ options.find( option => option.value === props.appliedFilters.duration) }
            options={options}
        />
    );
}

DurationFilterComponent.propTypes = {
    appliedFilters: PropTypes.object,
    handleFiltersChange: PropTypes.func,
}

DurationFilterComponent.defaultProps = {}

export default DurationFilterComponent