import React, {useEffect, useMemo, useRef} from 'react'
import {Col} from 'react-bootstrap'
import NodeIcon from './NodeIcon'
import {NODE_TYPES, NODE_TYPES_TO_NODE_COLOR_MAP} from '../const'
import {isMobile, isTablet} from 'react-device-detect'
import {move} from 'ramda'
import {
    getAbsolutePosition,
    getChampions,
    getNodeDisplay,
    getNonArchivedNodes,
    getNonHiddenNodes,
    handleDrop,
    isGoal,
    isNC,
    isSF,
} from '../utils'
import MiniNodeChildren from './MiniNodeChildren'
import {
    ChampionAvatar,
    CommentsBadge,
    Container,
    HalfCircleBottom,
    InnerBody,
    NodeHeader,
    NodeText,
    NodeTitle,
    Placeholder,
    ToggleContainer,
    TopBar,
} from './Node.styles'
import {useDrop} from 'react-dnd'
import { ITEM_TYPES, TIME_AREAS } from '../const'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import InactivityWarning from './InactivityWarning'
import {DARK_GREY} from '../../core/ui/_colors'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import {SIDEBARS} from "../../core/redux/slices";

export const NODE = {
    parents: 'parents',
    children: 'children',
}

export const getTree = (nodeId, nodeMap, treeDirection) => {
    if( ! nodeMap[nodeId] ) {
        return [];
    }

    return getTreeCascade(nodeId, nodeMap, treeDirection);
}
const getTreeCascade = (nodeId, nodeMap, treeDirection) => {
    const field = nodeMap[nodeId] && nodeMap[nodeId][treeDirection];
    const fieldTree =
        field?.filter(a => !!a).reduce(
            (fieldTree, nodeItem) =>
                fieldTree.concat([nodeItem], getTree(nodeItem, nodeMap, treeDirection) || []),
            []
        );
    return fieldTree || [];
}

export const scrollToView = (targetElement) => {
    if (!targetElement) return

    const marginTop = 100
    const timeout = 250
    const absolutePosition = getAbsolutePosition(targetElement)
    const offsetTop = absolutePosition.top - marginTop

    setTimeout(() => window.scrollTo(0, offsetTop), timeout)
}

const checkIsHighlightedNode = (currentOpenNodeId, nodeId, nodeMap) => {
    const highlightedNodes = [currentOpenNodeId]
        .concat(
            getTree(currentOpenNodeId, nodeMap, NODE.parents),
            getTree(currentOpenNodeId, nodeMap, NODE.children),
        )
        .flat(Infinity)
    return currentOpenNodeId === null || highlightedNodes.includes(nodeId)
}

const isNodeFocused = (nodeId, lastInteractedNode) => lastInteractedNode === nodeId

const NodeToggleButton = ({toggleChildren, nodeHasBeenClicked}) => {

    const icon = nodeHasBeenClicked ? 'expand_less' : 'expand_more';

    return <ToggleContainer
        className="toggle-nodes"
        onClick={toggleChildren}
    >
        <span className='material-icons'>{ icon }</span>
    </ToggleContainer>
}

const Node = ({nodeId, isDragging, isDraggable, timeArea, nodesLength, x, y}) => {
    const {
        Actions,
        clickedNodesToChildren,
        nodeMap,
        currentOpenNodeId,
        lastInteractedNode,
        sfPerTimeArea,
        showHiddenExperiment
    } = useStrategyMapSlice()
    const {sidebarOpen} = useCoreSlice();
    const nodeRef = useRef(null)
    const canOpenChildNodesRef = useRef(true)
    const node = nodeMap[nodeId] || {}
    const isHighlightedNode = useMemo(
        () => checkIsHighlightedNode(currentOpenNodeId, nodeId, nodeMap),
        [currentOpenNodeId, nodeMap],
    )
    const isFocussed = useMemo(
        () => isNodeFocused(nodeId, lastInteractedNode),
        [lastInteractedNode],
    )
    const [_, drop] = useDrop({
        accept: ITEM_TYPES.NODE,
        drop(_props, monitor) {
            const item = monitor.getItem()
            if (x !== item.x) {
                handleDrop(
                    monitor,
                    Actions,
                    x,
                    nodesLength,
                    timeArea,
                    nodeMap,
                    clickedNodesToChildren,
                )
            } else {
                if (item.y !== y && timeArea === TIME_AREAS.CONFIRMED) {
                    const confirmNodes = move(item.y, y, sfPerTimeArea[0])
                    Actions.updatePriority({nodes: confirmNodes, id: node.id})
                }
            }
        },
    })

    useEffect(() => {
        canOpenChildNodesRef.current = true
        const aNodeIsOpen = currentOpenNodeId != null
        if (aNodeIsOpen && !isHighlightedNode) {
            canOpenChildNodesRef.current = false
        }
    }, [currentOpenNodeId])

    useEffect(() => {
        if (nodeId === lastInteractedNode) {
            scrollToView(nodeRef.current.parentNode)
        }
    }, [nodeRef.current, lastInteractedNode])

    const toggleChildren = () => {
        if (node.children.length > 0 && canOpenChildNodesRef.current) {
            Actions.setClickedNode(node)
            if (!nodeMap[node.children[0]] && !isGoal(node)) {
                Actions.fetchChildrenSilently(node.id)
            }
            
        }
    }

    const openNode = () => {
        if ( sidebarOpen !== SIDEBARS.Node || currentOpenNodeId !== nodeId) {
            if (node.children && node.children.length !== 0) {
                if (!nodeMap[node.children[0]] && !isGoal(node)) {
                    Actions.fetchChildrenSilently(node.id)
                }
            }

            Actions.showNodeEditingSidebar(node)

            if (!clickedNodesToChildren[node.id]) {
                toggleChildren()
            }
        }
    }

    const closeTooltip = () => Actions.closePopover()

    const nodeHasBeenClicked = !!clickedNodesToChildren[node.id]
    const champions = getChampions(node, nodeMap)
    const showToggleSign = node.children && 
            getNonArchivedNodes(node.children, nodeMap).length > 0 && 
            (showHiddenExperiment || getNonHiddenNodes(node.children, nodeMap).length > 0 && !showHiddenExperiment ) && 
            node.type !== NODE_TYPES.GOAL
    const filter = currentOpenNodeId === nodeId ? '0px 8px 24px' : '0px 2px 6px'

    const notFullFilledAlphaValue =
        (node.type === NODE_TYPES.NC && !node.fulfilled) ||
        (node.type === NODE_TYPES.EXPERIMENT && !node.is_finished)
            ? 0.5
            : 1

    const colWrapper = {
        padding: '0 2px'
    }

    if (!isMobile || isTablet) {
        colWrapper.justifyContent = 'center'
        colWrapper.display = 'flex'
    }
    
    return (
        <Col sm={12} xs={12} style={colWrapper}>
            <Container
                id={isDragging ? node.id : null}
                dragging={ isDragging ? 1 : 0 }
                lg={10}
                md={10}
                sm={10}
                onClick={closeTooltip}
            >
                <div
                    ref={nodeRef}
                    style={{
                        filter: isHighlightedNode
                            ? `drop-shadow(${filter} rgba(0, 0, 0, 0.16))`
                            : '',
                        opacity: isHighlightedNode ? 1 : 0.3,
                        outline: isFocussed ? `3px solid ${DARK_GREY}` : '',
                    }}
                >
                    <div ref={drop}>
                        {isDragging ? (
                            <Placeholder />
                        ) : (
                            <InnerBody
                                onClick={openNode}
                                nodeId={node.id}
                                isDraggable={isDraggable}
                            >
                                <TopBar
                                    alpha={notFullFilledAlphaValue}
                                    fill={NODE_TYPES_TO_NODE_COLOR_MAP[node.type]}
                                />
                                <NodeHeader>
                                    <NodeIcon alpha={notFullFilledAlphaValue} node={node} />
                                    {getNodeDisplay(node)}
                                    <div style={{float: 'right'}}>
                                        {!isMobile && isSF(node) && (
                                            <InactivityWarning
                                                period_of_inactivity={node.period_of_inactivity}
                                            />
                                        )}
                                        {!isMobile &&
                                            !isNC(node) &&
                                            champions.map((champion) => (
                                                <ChampionAvatar
                                                    key={node.id + '-' + champion.pk}
                                                    pull={'right'}
                                                    colorCode={champion.color_code || 0}
                                                    text={champion.initials}
                                                    profilePicture={champion.profile_picture} 
                                                />
                                            ))}
                                        {!isMobile && !!node.comments.length && (
                                            <CommentsBadge>{node.comments.length}</CommentsBadge>
                                        )}
                                    </div>
                                </NodeHeader>
                                <NodeTitle>{node.title}</NodeTitle>
                                <NodeText
                                    dangerouslySetInnerHTML={{__html: node.description}}
                                    hasTitle={node.title && node.title.length}
                                />
                            </InnerBody>
                        )}
                        { ! isDragging &&
                            <MiniNodeChildren
                                show={ ! isMobile && showToggleSign }
                                node={node}
                                openChildrenNode={toggleChildren}
                            />
                        }
                        {showToggleSign && (
                            <NodeToggleButton
                                toggleChildren={toggleChildren}
                                nodeHasBeenClicked={nodeHasBeenClicked}
                            />
                        )}
                        {showToggleSign && isFocussed && <HalfCircleBottom />}
                    </div>
                </div>
            </Container>
        </Col>
    )
}

export default Node
