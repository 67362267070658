import React from 'react';
import {Panel} from "../core/components/BootstrapWrapper";
import {RESTRICTIONS, ROLES} from "../core/const";
import {_Table} from "../capture/Capture.styles";
import {isFeatureEnabled} from "../capture/PriceComparisonTable";
import {I18n} from "react-i18nify";

const orderingRoles = (roles) => {

    const usedRoles = [...new Set(Object.values( roles ).flat()) ];

    const defaultOrder = [
        ROLES.strategyMap.experimentMember,
        ROLES.strategyMap.squadMember,
        ROLES.strategyMap.champion,
        ROLES.strategyMap.sponsor,
        ROLES.analyst,
        ROLES.reporter,
        ROLES.strategist,
        ROLES.adminLeadership,
        ROLES.admin,
        ROLES.a42User
    ];

    const ordered = defaultOrder.reduce( (acc,cur) => usedRoles.includes(cur) ? [acc, cur].flat() : acc, []);
    usedRoles
        .filter( role => ! defaultOrder.includes( role ) )
        .forEach( role => ordered.push( role ))

    return ordered;
}

const SectionOverview = ({title, Rules}) => {

    const Roles = orderingRoles( Rules );
    const Permissions = Object.keys( Rules );


    const translations = {
        "is_admin": "billing.Label.Roles.IsAdmin",
        "is_admin_for_leadership": "billing.Label.Roles.IsAdminLeadership",
        "is_a42": "billing.Label.Roles.IsA42",
        "is_analyst": "billing.Label.Roles.IsAnalyst",
        "is_reporter": "billing.Label.Roles.IsReporter",
        "is_strategist": "billing.Label.Roles.IsStrategist",

        "squadMember": "billing.Label.Roles.IsSquadMember",
        "isUserChampion": "billing.Label.Roles.IsChampion",
        "isUserSponsor": "billing.Label.Roles.IsSponsor"
    };


    return <>
        <Panel header={ title }>
            <_Table>
                <thead>
                    <tr>
                        <th />
                        {
                            Roles
                                .map((role, idx) => <th key={idx}>{ translations[role] ? I18n.t( translations[role] ) : role }</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        Permissions
                            .map( (permission,idx) =>
                                <tr key={idx} style={{backgroundColor: idx%2 ? 'lightgray': 'transparent'}}>{
                                    [permission, ...Roles]
                                        .map( (column, idx) =>
                                            <td key={idx}>{ idx === 0 ? I18n.t( "billing.Label.Permissions." + column ) : isFeatureEnabled( Rules[permission].includes(column) ) }</td>
                                        )
                                }</tr>
                            )
                    }
                </tbody>
            </_Table>
        </Panel>
    </>
}

const RolesOverview = () => {

    const Sections = [
        {title: "Configuration", rules: RESTRICTIONS.Settings},
        {title: "OrgScan", rules: RESTRICTIONS.OrgScan.RULES},
        {title: "StrategyMap", rules: RESTRICTIONS.StrategyMap.RULES},
        {title: "StrategyMap (internal rules)", rules: RESTRICTIONS.StrategyMap.RULES_APP},
        {title: "Leadership", rules: RESTRICTIONS.Leadership.RULES}
    ];

    return Sections
        .map( (section,idx) =>
            <SectionOverview key={idx} title={section.title} Rules={section.rules} />
    );
};

export default RolesOverview;