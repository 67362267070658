import React, {useState} from 'react';
import {MainNavIcon, NavItemBox} from "./Navbar.styles";
import {DropdownContainer, DropdownList} from "../../../sidebars/Components/DropdownMenu";
import styled from 'styled-components';
import I18n from "react-i18nify/build/lib/I18n";
import {BLACK_GREY, DARK_GREY, MID_GREY, PALE_GREY} from "../../ui/_colors";
import ClickOutside from '@tntd/react-click-outside';
import {useStrategyMapSlice} from "../../../strategyMap/hooks/useStrategyMapSlice";
import Footer from "../../ui/modal/Footer";
import Modal from "../Modal";
import {isMobile} from "react-device-detect";
import {useCoreSlice} from "../../hooks/useCoreSlice";
import {NavItemIntern} from "../BootstrapWrapper";

const DropdownListBox = styled(DropdownList)`
    padding-top: 0;
    padding-bottom: 0;
    margin-left: ${ props => props.left ? props.left : '-60px'};
`
const DropDownItem = styled.div`
    padding-top: ${props => props.menu ? '16px' : '8px'};
    padding-bottom: ${props => props.menu ? '16px' : '8px'};
    padding-left: 16px;
    width: 220px;
    word-wrap: break-word;
    line-height: 16px;
    &:hover {
        background-color: ${PALE_GREY};
        color: ${BLACK_GREY} !important;
        * {
            color: ${BLACK_GREY} !important;
        }
    };
    color: ${DARK_GREY};
    opacity: ${ props => (props.selected || props.menu) ? '100%' : '80%'}
    border: ${props => props.menu ? '1px solid '+PALE_GREY : '1px solid transparent'};
`;
const TextItem = styled.div`
    text-align: left;
    width: cal(100% - 30px);
    display: flex;
    padding-right: 8px;
`;

export const DropdownContainerBox = styled(DropdownContainer)`
    margin-top: 56px;
`
const Symbol = styled(MainNavIcon)`
    margin-top: 16px;
`
const Icon = styled.i`
    margin-top: -4px;
    padding-right: 8px;
    color: ${MID_GREY};
    width: 40px;
`

const ModalDeleteGoal = ({isOpen, close}) => {

    const {goal, Actions} = useStrategyMapSlice();

    return <Modal
        isOpen={isOpen}
        title={I18n.t('strategyMap.messages.deleteGoalTitle').replace("#1", goal.id)}
        footerContent={
            <Footer
                confirmText={I18n.t('app.buttons.confirm')}
                onConfirm={() => {
                    Actions.deleteGoal({id: goal.id});
                    close();
                }}
                onCancel={() => close()}
            />
        }
    >
        <div style={{height: "100px"}}>{ I18n.t("strategyMap.messages.deleteGoalText") }</div>
    </Modal>
}

const DropDownMenu = ({open, setOpen, openDeleteDialog}) => {

    const {Actions, goalNodes, goal} = useStrategyMapSlice();

    const selectGoal = (id) => {
        Actions.getGoal({id: id});
        setOpen(false);
    }
    const createGoal = () => {
        setOpen(false);
        Actions.createGoal();
    }
    const deleteGoal = () => {
        setOpen(false);
        openDeleteDialog(goalNodes.filter( g => g.id !== goal.id )[0]);
    }

    const currentGoal = goal.id;

    return <DropdownContainerBox>
            <DropdownListBox isOpen={open} left={isMobile}>
                <DropDownItem data-testid={'create-goal-navbar'} menu={true} onClick={createGoal}>
                    <Icon className={'material-icons pull-left'}>add</Icon><TextItem>{I18n.t("strategyMap.createGoal")}</TextItem>
                </DropDownItem>
                { goalNodes.length > 1 && <DropDownItem data-testid={'delete-goal-navbar'} menu={true} onClick={deleteGoal}>
                    <Icon className={'material-icons pull-left'}>delete</Icon><TextItem>{I18n.t("strategyMap.deleteCurrentGoal")}</TextItem>
                </DropDownItem> }
                {
                    goalNodes.map( goalItem =>
                        <DropDownItem
                            data-testid={'switch-goal-'+goalItem.id}
                            menu={false}
                            key={goalItem.id}
                            onClick={() => selectGoal(goalItem.id)}
                        >
                            <Icon className={'material-icons pull-left'}>
                                {currentGoal === goalItem.id ? 'checked' : 'nothing'}
                            </Icon>
                            <TextItem>{goalItem.map_name || goalItem.title || I18n.t("strategyMap.unknownGoal").replace("#1", goalItem.id)}</TextItem>
                        </DropDownItem>)
                }
            </DropdownListBox>
        </DropdownContainerBox>
}

const StrategyMapSwitch = () => {

    const {Actions} = useStrategyMapSlice();
    const [open, setOpen] = useState(false);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const {Actions: CoreActions} = useCoreSlice();

    const openMenu = (e) => {
        if( ! showDeleteMessage ) {
            if( ! open ) {
                CoreActions.closeSidebar();
                Actions.fetchGoals({action: "forced"});
            }
            setOpen(!open);
        }
    }
    const closeMenu = (e) =>
        setOpen(false);

    return <NavItemIntern onClick={openMenu} data-testid={'goal-switch-navbar'}>
            <ClickOutside onClickOutside={closeMenu}>
                <NavItemBox>
                    <Symbol className="material-icons-outlined">auto_awesome_motion</Symbol>
                    <DropDownMenu open={open} setOpen={setOpen} openDeleteDialog={() => setShowDeleteMessage(true)} />
                </NavItemBox>
                <ModalDeleteGoal 
                    isOpen={showDeleteMessage} 
                    close={() => {
                        setShowDeleteMessage(false);
                        closeMenu();
                    }} 
                />
            </ClickOutside>
    </NavItemIntern>
}

export default StrategyMapSwitch;