import 'quill-mention'

import React, {useState} from 'react'
import ReactQuill from 'react-quill'
import classNames from 'classnames'
import styled from 'styled-components'
import {BRIGHT_GREY, PALE_GREY} from '../ui/_colors'
import {TOOLBAR_CONFIG} from '../const'
import Api from '../../strategyMap/api'
import {any, propEq} from 'ramda'

const Container = styled.div`
    .ql-mention-list-item {
        background: ${BRIGHT_GREY};
        padding: 10px;
        cursor: pointer;
        list-style: none;
        &:hover {
            background: ${PALE_GREY};
        }
    }
    .selected {
        background: ${PALE_GREY};
    }
    .ql-mention-list {
        padding-inline-start: 10px;
    }
    .mention {
        background: ${PALE_GREY};
        padding: 5px;
        border-radius: 30px;
    }
    ${(p) => (p.overLimit ? 'border: 1px solid red;' : '')}
`

const suggestUsers = async (searchTerm) => {
    const response = await Api.fetchActiveUsers()
    const users = response.data.map((user) => ({
        id: user.pk,
        value: user.full_name,
    }))
    return users.filter((person) => person.value.toLowerCase().includes(searchTerm.toLowerCase()))
}

const mentionedUsers = []

const modules = {
    ...TOOLBAR_CONFIG.modules,
    mention: {
        ...TOOLBAR_CONFIG.modules.mention,
        source: async (searchTerm, renderList) => {
            const matchedUsers = await suggestUsers(searchTerm)
            renderList(matchedUsers)
        },
        counter: {
            container: '#counter',
            unit: 'word',
        },
        onSelect: (item, insertItem) => {
            if (!any(propEq('id', item.id))(mentionedUsers)) {
                mentionedUsers.push({id: item.id, value: item.value})
            }
            insertItem(item)
        },
    },
}

const RTEditor = (props) => {
    const [text, setText] = useState(props.text || '')

    const checkCharacterCount = (event) => {
        if (text.length > props.characterLimit && event.key !== 'Backspace') {
            event.preventDefault()
        }
    }

    const handleTextChange = (inputText) => {
        if (props.text === inputText) return

        setText(inputText)
        props.onTextChange(inputText)
        if (mentionedUsers.length) {
            props.onMentionsChange && props.onMentionsChange(mentionedUsers)
        }
    }

    const editorClass = classNames({
        'rich-text-editor': true,
        'has-error': props.showValidationError,
    })

    return (
        <Container overLimit={props.characterLimit && text.length > props.characterLimit}>
            <ReactQuill
                onChange={handleTextChange}
                onKeyDown={props.characterLimit && checkCharacterCount}
                placeholder={props.placeholderText}
                className={editorClass}
                modules={props.hideToolbar ? {toolbar: false} : modules}
                formats={props.hideToolbar ? {} : TOOLBAR_CONFIG.formats}
                value={text}
                readOnly={props.isReadOnly}
            />
        </Container>
    )
}

export default RTEditor
