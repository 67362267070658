import React, {forwardRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import NodeIcon from '../NodeIcon'
import {getDescendants, getNodeChildrenByTimeArea, getNodeDisplay} from '../../utils'
import {NodeHeader, NodeTitle} from '../Node.styles'
import {I18n} from 'react-i18nify'
import {useStrategyMapSlice} from '../../hooks/useStrategyMapSlice'
import {concat} from 'ramda'
import {NODE_TYPES_TO_NODE_COLOR_MAP} from '../../const'
import {BLUE, BRIGHT_GREY, MID_GREY} from '../../../core/ui/_colors'

export const modes = {
    SINGLE_NODE_LARGE: 'SINGLE_NODE_LARGE',
    NODE_AND_CHILDREN_SMALL: 'NODE_AND_CHILDREN_SMALL',
    NODE_AND_CHILDREN_LARGE: 'NODE_AND_CHILDREN_LARGE',
    NODE_AND_DESCENDANTS_LARGE: 'NODE_AND_DESCENDANTS_LARGE',
    // WHOLE_STRATEGY_MAP: 'WHOLE_STRATEGY_MAP',
}

const {NODE_AND_CHILDREN_LARGE, NODE_AND_DESCENDANTS_LARGE, NODE_AND_CHILDREN_SMALL} = modes

const Container = styled.div`
    width: 100%;
    padding: 20px;
`
const ParentRow = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    justify-content: center;
`
const TimeAreaContainer = styled.div`
    width: 100%;
    display: flex;
`
const TimeArea = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 10px;
    min-height: 40px;
    ${(p) => (!p.noBorder ? 'border-right: 1px solid '+BRIGHT_GREY+';' : '')}
`
const TimeAreaHeader = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
`
const NodeText = styled.div`
    font-size: ${(p) => p.fontSize}px;
`
const TimeAreaText = styled.span`
    color: ${(p) => p.fontColour || 'black'} !important;
    font-size: ${(p) => p.fontSize}px;
    float: right;
`
const ContainerNode = styled.div`
    padding-bottom: 30px;
`
const NodeCard = styled.div`
    width: 240px;
    margin: 4px;
    padding: 4px;
    border: ${(p) => p.borderSize}px solid ${(p) => p.borderColour};
    border-radius: 6px;
`
const NodeIconStyled = styled(NodeIcon)`
    height: ${({isSmallMode}) => (isSmallMode ? '20px' : '40px')};
`
const NodeTitleStyled = styled(NodeTitle)`
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
`
const ParentNodeCard = styled(NodeCard)`
    width: 240px;
    padding: ${({paddingSize}) => paddingSize};
`
const ChildNodeCard = styled(NodeCard)`
    width: 95%;
    padding: ${({paddingSize}) => paddingSize};
`

const LandscapeOrientation = () => (
    <style type="text/css">{'@media print{@page {size: landscape, margin: 40px}}'}</style>
)

const PageBreak = () => (
    <PageBreakMedia>
        <div className="pagebreak"></div>
    </PageBreakMedia>
)

const PageBreakMedia = styled.div`
    @media print {
        .pagebreak {
            page-break-before: always;
            color: ${BLUE};
        }
    }
`

const LargeNodesDisplay = ({nodes, isSmallMode}) => {
    return (
        <>
            {nodes.map((node) => {
                return (
                    <ContainerNode key={node.id}>
                        <ParentRow>
                            <ChildNodeCard
                                borderColour={NODE_TYPES_TO_NODE_COLOR_MAP[node.type]}
                                borderSize={isSmallMode ? 1 : 5}
                                paddingSize={isSmallMode ? '10px 20px' : '10px 30px'}
                            >
                                <NodeHeader fontSize={isSmallMode ? 16 : 34}>
                                    <NodeIconStyled node={node} isSmallMode={isSmallMode} />
                                    {getNodeDisplay(node)}
                                    <TimeAreaText
                                        fontColour={MID_GREY}
                                        fontSize={isSmallMode ? 16 : 28}
                                    >
                                        {I18n.t(`strategyMap.timeAreas.${node.time_area}`)}
                                    </TimeAreaText>
                                </NodeHeader>
                                <NodeTitleStyled fontSize={isSmallMode ? 18 : 38}>
                                    {node.title}
                                </NodeTitleStyled>
                                <NodeText
                                    fontSize={isSmallMode ? 14 : 34}
                                    dangerouslySetInnerHTML={{__html: node.description}}
                                />
                            </ChildNodeCard>
                        </ParentRow>
                        {!isSmallMode && <PageBreak />}
                    </ContainerNode>
                )
            })}
        </>
    )
}

const NodesTimeAreaDisplay = ({node, nodeMap}) => {
    return (
        <>
            <TimeAreaContainer style={{borderBottom: '1px solid ' + BRIGHT_GREY}}>
                <TimeArea noBorder>
                    <TimeAreaHeader>{I18n.t('strategyMap.timeAreas.confirmed')}</TimeAreaHeader>
                </TimeArea>
                <TimeArea noBorder>
                    <TimeAreaHeader>{I18n.t('strategyMap.timeAreas.validating')}</TimeAreaHeader>
                </TimeArea>
                <TimeArea noBorder>
                    <TimeAreaHeader>{I18n.t('strategyMap.timeAreas.potential')}</TimeAreaHeader>
                </TimeArea>
            </TimeAreaContainer>
            <TimeAreaContainer>
                {getNodeChildrenByTimeArea(node.children || [], nodeMap).map((nodeIds, index) => {
                    return (
                        <TimeArea key={index} noBorder={index === 2}>
                            {nodeIds.map((nodeId) => {
                                const childNode = nodeMap[nodeId]
                                return (
                                    <ChildNodeCard
                                        key={nodeId}
                                        borderColour={NODE_TYPES_TO_NODE_COLOR_MAP[childNode.type]}
                                        borderSize={1}
                                    >
                                        <NodeHeader>
                                            <NodeIcon node={childNode} />
                                            {getNodeDisplay(childNode)}
                                        </NodeHeader>
                                        <NodeTitleStyled>{childNode.title}</NodeTitleStyled>
                                        <NodeText
                                            dangerouslySetInnerHTML={{
                                                __html: childNode.description,
                                            }}
                                        />
                                    </ChildNodeCard>
                                )
                            })}
                        </TimeArea>
                    )
                })}
            </TimeAreaContainer>
        </>
    )
}

const NodePrint = (props, ref) => {
    const {nodeMap, currentOpenNodeId} = useStrategyMapSlice()
    const [descendants, setDescendants] = useState([])

    const isSmallMode = props.mode === NODE_AND_CHILDREN_SMALL
    const node = nodeMap[currentOpenNodeId]

    useEffect(() => {
        const descendantsIDs = getDescendants(nodeMap, currentOpenNodeId)
        setDescendants(descendantsIDs.map((id) => nodeMap[id]))
    }, [nodeMap, currentOpenNodeId])

    const children = node.children.map((n) => nodeMap[n])
    const largeNodes =
        props.mode === NODE_AND_DESCENDANTS_LARGE
            ? concat([node], descendants)
            : props.mode === NODE_AND_CHILDREN_LARGE
            ? concat([node], children)
            : [node]

    return (
        <Container clickable ref={ref}>
            <LandscapeOrientation />

            {node.parents && (
                <ParentRow>
                    {node.parents.map((parentId) => {
                        const parentNode = nodeMap[parentId]
                        return (
                            <ParentNodeCard
                                key={parentId}
                                borderColour={NODE_TYPES_TO_NODE_COLOR_MAP[parentNode.type]}
                                borderSize={isSmallMode ? 1 : 5}
                                paddingSize={isSmallMode ? '4px' : '5px 10px'}
                            >
                                <NodeHeader fontSize={isSmallMode ? 16 : 20}>
                                    <NodeIcon
                                        node={parentNode}
                                        size={isSmallMode ? '19px' : '30px'}
                                    />
                                    {getNodeDisplay(parentNode)}
                                    <TimeAreaText
                                        fontColour={MID_GREY}
                                        fontSize={isSmallMode ? 16 : 20}
                                    >
                                        {I18n.t(`strategyMap.timeAreas.${parentNode.time_area}`)}
                                    </TimeAreaText>
                                </NodeHeader>
                                <NodeTitleStyled>{parentNode.title}</NodeTitleStyled>
                            </ParentNodeCard>
                        )
                    })}
                </ParentRow>
            )}

            <LargeNodesDisplay nodes={largeNodes} isSmallMode={isSmallMode} />

            {isSmallMode && <NodesTimeAreaDisplay node={node} nodeMap={nodeMap} />}
        </Container>
    )
}

export default forwardRef(NodePrint)
