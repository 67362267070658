import React from 'react';
import A42Tabs from '../../core/components/Tabs';
import { Container } from '../../core/ui/_global';
import ArchetypeStatsOverview from './ArchetypesOverview';
import TeamsOverview from './TeamsOverview';
import Inspect from './Inspect';
import {I18n} from 'react-i18nify'
import { useUserSlice } from '../../strategyMap/hooks/useUserSlice';
import {RESTRICTIONS} from "../../core/const";
import { useCoreSlice } from '../../core/hooks/useCoreSlice';

const LeadershipAssessmentAnalysis = () => {
    const user = useUserSlice()
    const { features } = useCoreSlice()
    const isNotTeamSubscription = !features["leadership.team"]

    return (
        <Container className="container" data-testid="billing-page">
            <A42Tabs
                tabs={[
                    {
                        key: 'inspect',
                        title: I18n.t('analysis.tab.inspect'),
                        content: <Inspect />
                    },
                    {
                        key: 'archetypes',
                        title: I18n.t('analysis.tab.teamsArchetypes'),
                        visible: RESTRICTIONS.Leadership.RULES.CAN_ANALYSE_DATA_COMPARISON.some(role => user[role] ) && isNotTeamSubscription,
                        content: <ArchetypeStatsOverview />
                    },
                    {
                        key: 'compareTeams',
                        title: I18n.t('analysis.tab.teamsOverview'),
                        visible: RESTRICTIONS.Leadership.RULES.CAN_ANALYSE_DATA_COMPARISON.some(role => user[role] ) && isNotTeamSubscription,
                        content: <TeamsOverview />
                    }
                ]}
            />
        </Container>
    )
}

export default LeadershipAssessmentAnalysis;