import styled from 'styled-components'
import {ListGroup, ListGroupItem} from 'react-bootstrap'
import { GREY } from '../../ui/_colors'

export const DesiredOutcomesContainer = styled.div`
    position: relative;
    overflow-y: auto;
`

export const DesiredOutcomeListGroup = styled(ListGroup)`
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        background: ${GREY};
    }
`

export const DesiredOutcomeWrapper = styled.div`
    position: relative;
    cursor: 'pointer';
`

export const DesiredOutcomeText = styled.div`
    width: calc(100% - 30px);
`

export const DesiredOutcomeCont = styled.div`
    display: inline-flex;
    float: left;
    width: 100%;

    .click-to-edit-input-component {
        float: left;
        width: 80%;
    }
    .checkbox-wrapper {
        float: left;
    }
`

export const ListGroupItemStyled = styled(ListGroupItem)`
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;

    input {
        margin: 0 10px;
        width: 100%;
        line-height: 20px;
        float: none;
        font-weight: normal;
    }
    span {
        font-size: 16px;
        display: inline-block;
    }
`

export const ListItemIcon = styled.i`
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 25px;
    padding: 0;
    top: 0;
`
