import {integrationsSlice} from './slices'
import {all, call, put, takeLeading} from 'redux-saga/effects'
import Api from '../api'
import {coreSlice} from '../../core/redux/slices'
import {consoleError} from '../../core/utils/error'

function* disconnectSlack() {
    try {
        const response = yield call(Api.disconnectSlack)
        yield put(integrationsSlice.actions.disconnectSlackSuccess(response.data))
        yield put(coreSlice.actions.getClient())
    } catch (error) {
        consoleError(error)
        yield put(integrationsSlice.actions.requestFailure(error))
    }
}

function* disconnectTrello() {
    try {
        const response = yield call(Api.disconnectTrello)
        yield put(integrationsSlice.actions.disconnectTrelloSuccess(response.data))
        yield put(coreSlice.actions.getClient())
    } catch (error) {
        consoleError(error)
        yield put(integrationsSlice.actions.requestFailure(error))
    }
}

function* connectTrello(action) {
    try {
        const response = yield call(Api.connectTrello, action.payload)
        yield put(integrationsSlice.actions.connectTrelloSuccess(response.data))
        yield put(coreSlice.actions.getClient())
    } catch (error) {
        consoleError(error)
        yield put(integrationsSlice.actions.requestFailure(error))
    }
}

function* fetchTrelloWorkspaces() {
    try {
        const response = yield call(Api.trelloWorkspaces)
        yield put(integrationsSlice.actions.fetchTrelloWorkspacesSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(integrationsSlice.actions.requestFailure(error))
    }
}

export default function* flow() {
    yield all([
        takeLeading(integrationsSlice.actions.disconnectSlack, disconnectSlack),
        takeLeading(integrationsSlice.actions.disconnectTrello, disconnectTrello),
        takeLeading(integrationsSlice.actions.connectTrello, connectTrello),
        takeLeading(integrationsSlice.actions.fetchTrelloWorkspaces, fetchTrelloWorkspaces),
    ])
}
