// less abstract version of ramdas chain added for clarity
// can be thought of as a fancier version of ramdas call

import {curry} from 'ramda'
import {isFunction} from 'ramda-adjunct'

export const callBoth = curry((fn1, fn2, target) => {
    if (!isFunction(fn1) || !isFunction(fn2)) {
        throw new TypeError('callBoth only takes functions')
    }
    // applies 2 functions one after the other to an object and combines the result in a triangular flow
    // target  fn2(  fn1(target)  , target  )
    //  \     /
    //   fn1(target)

    const temp = fn1(target) // create temporary internal variable with 1st function
    return fn2(temp, target) // feed temp variable and object to the second function

    // useful for when you want to do 2 things at once to an object and combine the results
    // without cluttering your code with some awkward temporary variable that may not be that meaningful by itself

    // EXAMPLE
    // compare
    // const mirror = list => {
    //    const headOfList = head(list)
    //    return append(headOfList, list)
    // }
    // to
    // const mirror = callBoth( head, append )

    // list  append(  head(list)  , list  )
    //  \     /
    //   head(list)
})
