import React from 'react'
import CommonPage from '../core/components/CommonPage'
import Billing from './Billing'
import {I18n} from 'react-i18nify'
import FeaturePermission from '../core/components/FeaturePermission';

const BillingPage = () => {
    return (
        <FeaturePermission name="account">
            <CommonPage pageHeading={I18n.t('billing.PageTitle')} render={() => <Billing />} />
        </FeaturePermission>
    )
}

export default BillingPage
