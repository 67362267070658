import {createSlice} from 'redux-starter-kit'
import * as ErrorUtils from '../../core/utils/error'

const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isFetchingAuth: false,
    isFetchingUsers: false,
    isSaving: false,
    errors: ErrorUtils.getApiErrors(action.payload),
})
const fetchingHandler = (state) => ({...state, isFetching: true})

export const userSlice = createSlice({
    slice: 'USER',
    initialState: {
        authError: '',
        authSuccess: false,
        users: [],
        isFetching: false,
        isFetchingUsers: false,
        isSaving: false,
        userSaved: false,
        loginAttempts: 0,
        passwordReset: null,
        passwordResetError: null,
        passwordResetConfirmResponse: null,
        passwordResetConfirmError: null,
        passwordSaved: null,
        passwordErrors: null,
        errors: null,
        isUserLoggedIn: false,
        dashboard: []
    },
    reducers: {
        removeU2F: state => ({...state}),
        removeU2FSuccess: state => ({...state, is_dongle_user: false}),
        storeU2FCredentials: state => ({...state}),
        storeU2FCredentialsSuccess: state => ({...state, is_dongle_user: true}),
        getUser: fetchingHandler,
        logout: (state) => ({
            ...state,
            isUserLoggedIn: false,
            authSuccess: false
        }),
        getUserSuccess: (state, action) => ({
                ...state,
                isFetching: false,
                ...action.payload,
                isUserLoggedIn: !!action.payload.email,
                errors: null
        }),
        updateUser: state => ({...state, userSaved: false}),
        updateUserSuccess: (state) => ({...state, userSaved: true}),
        setPassword: (state) => ({...state, passwordSaved: false}),
        setPasswordSuccess: (state) => ({...state, passwordSaved: true}),
        removeFromExperimentTeam: (state) => ({
            ...state,
            in_experiment_team: null,
            can_join_experiment: true,
        }),
        fetchUsers: (state) => ({...state, isFetchingUsers: true}),
        fetchUsersSuccess: (state, action) => ({
            ...state,
            isFetchingUsers: false,
            users: action.payload.data,
        }),
        
        updateUsers: (state) => ({...state, isSaving: true}),
        updateUsersSuccess: (state) => ({...state, isSaving: false}),
        fetchToken: (state) => ({...state, isFetching: false}),
        fetchTokenSuccess: (state) => ({
            ...state,
            isFetching: false,
            errors: null,
            authSuccess: true,
            loginAttempts: 0
        }),
        resetPasswordConfirm: () => {},
        resetPasswordConfirmSuccess: (state, action) => ({
            ...state,
            passwordResetConfirmResponse: action.payload,
            passwordResetConfirmError: null
        }),
        resetPasswordConfirmFailure: (state, action) => ({
            ...state,
            passwordResetConfirmResponse: null,
            passwordResetConfirmError: action.payload
        }),
        resetPassword: (state) => ({...state, isSaving: false}),
        resetPasswordSuccess: (state) => ({
            ...state,
            passwordReset: true,
            isSaving: false,
            errors: null,
        }),
        resetErrors: (state) => ({...state, errors: null}),
        inviteUser: (state) => ({...state, isSaving: true}),
        inviteUserSuccess: (state) => ({...state, isSaving: false}),
        bulkCreateUsers: (state) => ({...state, isSaving: true}),
        bulkCreateUsersSuccess: (state) => ({...state, isSaving: false}),
        saveProfilePic: (state) => ({...state, isSaving: true}),
        saveProfilePicSuccess: (state) => ({...state, isSaving: false}),
        addToExperimentTeam: (state, action) => ({
            ...state,
            in_experiment_team: action.payload,
            can_join_experiment: false
        }),
        declineFromExperimentTeam: (state) => ({
            ...state,
            in_experiment_team: null,
            can_join_experiment: false
        }),
        requestFailure: errorHandler,
        setAuthSuccess: (state, action) => ({...state, authSuccess: action.payload}),
        setLoginAttempts: (state, action) => ({...state, loginAttempts: action.payload}),
    },
})
