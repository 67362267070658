import {all, call, put, takeLeading} from 'redux-saga/effects'
import Api from '../comment.api'
import {commentSlice} from './comment.slices'
import {strategyMapSlice} from './slices'
import {consoleError} from '../../core/utils/error'

function* fetchComments(actions) {
    const {nodeId} = actions.payload

    try {
        const response = yield call(Api.fetchComments, nodeId)

        yield put(commentSlice.actions.fetchCommentsSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(commentSlice.actions.requestFailure(error))
    }
}

function* addComment(actions) {
    const {comment} = actions.payload

    try {
        const response = yield call(Api.addComment, comment)
        yield put(commentSlice.actions.addCommentSuccess(response.data))
        yield put(strategyMapSlice.actions.getNode(response.data.node))
    } catch (error) {
        consoleError(error)
        yield put(commentSlice.actions.requestFailure(error))
    }
}

function* vote(actions) {
    const {commentId, data} = actions.payload

    try {
        const response = yield call(Api.vote, commentId, data)
        yield put(commentSlice.actions.voteSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(commentSlice.actions.requestFailure(error))
    }
}

function* resolve(actions) {
    const {commentId, nodeId} = actions.payload
    try {
        yield call(Api.resolve, commentId)
        yield put(commentSlice.actions.resolveSuccess(commentId))
        yield put(strategyMapSlice.actions.getNode(nodeId))
    } catch (error) {
        consoleError(error)
        yield put(commentSlice.actions.requestFailure(error))
    }
}

export default function* flow() {
    yield all([
        takeLeading(commentSlice.actions.fetchComments, fetchComments),
        takeLeading(commentSlice.actions.addComment, addComment),
        takeLeading(commentSlice.actions.vote, vote),
        takeLeading(commentSlice.actions.resolve, resolve),
    ])
}
