import styled, {css} from 'styled-components'
import {BaseMixin} from './BaseMixin'

const justifyContent = (jc) =>
    css`
        justify-content: ${jc};
    `
const alignItems = (ai) =>
    css`
        align-items: ${ai};
    `
const flexDirection = (fd) =>
    css`
        flex-direction: ${fd};
    `

export const Div = styled.div`
    ${BaseMixin}
    ${(props) => props.justifyContent && justifyContent(props.justifyContent)}
    ${(props) => props.alignItems && alignItems(props.alignItems)}
    ${(props) => props.flexDirection && flexDirection(props.flexDirection)}
`
