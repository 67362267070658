import LinkedToList from "../Components/LinkedToList";
import {I18n} from "react-i18nify";
import React from "react";
import { Div } from "../../core/ui/Div";


const Experiments = ({isDashboardFull, experiments}) =>
    <Div padding="15px">
        {isDashboardFull && (
            <Div padding="10px 0">
                {experiments.length ? (
                    <LinkedToList
                        nodes={experiments}
                        removable={false}
                    />
                ) : (
                    <p>
                        {I18n.t('profile.noExperimentParticipationInfoText')}
                    </p>
                )}
            </Div>
        )}
    </Div>

export default Experiments;