import React from 'react'
import {HelpText, ModalBackground, ModalBody, ModalContent, ModalFooter, ModalHeader, Title,} from './Modal.styles'

const Modal = ({onClose, isOpen, children, title, helpText, footerContent, closeOnOverlayClick}) => {
    const closeOnModalBackgroundClick = closeOnOverlayClick === undefined ? true : closeOnOverlayClick;
    return <>
            {isOpen && (
                <ModalBackground
                    onClick={(e) => {
                        if (closeOnModalBackgroundClick) {
                            const bg = document.getElementById('modal-body');
                            if( bg.contains(e.target) )
                                return
                            onClose && onClose();
                        }
                    }}
                >
                    <ModalBody id="modal-body">
                        {
                            (title || helpText) && (
                                <ModalHeader>
                                    {title && <Title>{title}</Title>}
                                    {helpText && <HelpText>{helpText}</HelpText>}
                                </ModalHeader>
                            )
                        }
                        <ModalContent>
                            <div>{ children }</div>
                        </ModalContent>
                        { footerContent && <ModalFooter>{ footerContent }</ModalFooter> }
                    </ModalBody>
                </ModalBackground>
            )}
        </>
};

export default Modal;
