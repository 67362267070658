import React from 'react'
import {FormGroup, FormLabel} from 'react-bootstrap'
import SelectWrapper from '../../components/SelectWrapper'
import {ErrorBorderStyles} from './Error.styles'

const customStyles = {
    menuList: (provided) => ({
        ...provided,
        maxHeight: 150,
    }),
}

const SelectInput = (props) => {
    return (
        <FormGroup controlId="controlName">
            <FormLabel>{props.label}</FormLabel>
            <ErrorBorderStyles errors={props.errors}>
                <SelectWrapper
                    searchable={false}
                    styles={customStyles}
                    name="form-field-name"
                    options={props.options}
                    onChange={props.onChange}
                    value={props.value}
                    clearable={false}
                />
            </ErrorBorderStyles>
        </FormGroup>
    )
}

export default SelectInput
