import {all, call, put, select, takeEvery, takeLeading} from 'redux-saga/effects'
import Api from '../api'
import {strategyMapSlice} from './slices'
import {userSlice} from '../../auth/redux/user.slices'
import {createAPISaga} from '../../core/redux/sagas'
import {I18n} from 'react-i18nify'
import Raven from 'raven-js'
import {consoleError} from '../../core/utils/error'
import {displayToastMessage} from '../../core/utils/notifications'
import {downloadData} from '../utils'
import {coreSlice, SIDEBARS} from '../../core/redux/slices'
import {PAGES} from "../../core/const";

export function* fetchNodes() {
    try {
        const response = yield call(Api.fetchNodes)
        yield put(strategyMapSlice.actions.fetchNodesSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}
export function* fetchNode(action) {
    try {
        const response = yield call(Api.fetchNode, action.payload)
        yield put(strategyMapSlice.actions.fetchNodeSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* fetchSfs(action) {
    try {
        const response = yield call(Api.fetchSfs, action.payload);
        yield put(strategyMapSlice.actions.fetchSfsSuccess(response.data))
        for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].children.length === 0) continue
            yield put(strategyMapSlice.actions.fetchChildrenSilently(response.data[i].id))
        }
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* clearNodes(action) {
    try {
        const response = yield call(Api.clearNodes, action.payload)
        yield put(strategyMapSlice.actions.clearSMSuccess(response.data))
        displayToastMessage('success', 'Nodes cleared successfully')
        setTimeout(() => window.location.reload(), 5000)
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* generateDecisions(action) {
    try {
        yield call(Api.generateDecisions, action.payload)
        yield put(strategyMapSlice.actions.setIsFetching(false))
        displayToastMessage('success', 'Decisions generated successfully')
        setTimeout(() => window.location.reload(), 5000)
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* generateSMNodes() {
    try {
        yield call(Api.generateSMNodes)
        yield put(strategyMapSlice.actions.fetchGoals())
        displayToastMessage('success', 'Strategy Map created successfully')
        setTimeout(() => window.location.reload(), 5000)
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* importSMNodes(action) {
    try {
        yield call(Api.importSMNodes, action.payload)
        yield put(strategyMapSlice.actions.fetchGoals())
        displayToastMessage('success', 'Strategy Map imported successfully')
        setTimeout(() => window.location.reload(), 5000)
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* exportSM(action) {
    try {
        const response = yield call(Api.exportSM, action.payload)
        const today = new Date()
        const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        downloadData(response.data, `template_sm_${date}.oasm`)
        yield put(strategyMapSlice.actions.exportSMSuccess())
        displayToastMessage('success', 'Strategy Map exported successfully')
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* fetchChildren(action) {
    try {
        if (action.payload) {
            const response = yield call(Api.fetchChildren, action.payload)
            yield put(strategyMapSlice.actions.fetchChildrenSuccess(response.data))
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].children.length === 0) continue
                yield put(strategyMapSlice.actions.fetchChildrenSilently(response.data[i].id))
            }
        }
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* fetchChildrenSilently(action) {
    try {
        const response = yield call(Api.fetchChildren, action.payload)
        yield put(strategyMapSlice.actions.fetchChildrenSilentlySuccess(response.data))
        for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].children.length === 0) continue
            yield put(strategyMapSlice.actions.fetchChildrenSilently(response.data[i].id))
        }
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}


function* getGoal(action) {
    const goalNodeId = action.payload ? action.payload.id : null;
    const lastGoal = localStorage.getItem('lastOpenedMap');

    try {
        if( lastGoal !== goalNodeId ) {
            localStorage.setItem('lastOpenedMap', goalNodeId);
            yield put(strategyMapSlice.actions.getGoalSuccess(goalNodeId));
        }
        else yield put(strategyMapSlice.actions.fetchingDone());

        if(window.location.pathname.includes('strategy-map') && goalNodeId !== null) {
            yield put(strategyMapSlice.actions.fetchSfs({goalId: goalNodeId}));
            yield put(strategyMapSlice.actions.fetchStrategicIterations(goalNodeId));
        }

    } catch (error) {
        consoleError(error);
        yield put(strategyMapSlice.actions.requestFailure(error));
    }
}

export function* fetchGoals(action) {
    const reduxState = yield select();
    try {
        if( reduxState.strategyMap.goalNodes.length === 0 || action?.payload?.action === "forced" ) {
            const response = yield call(Api.fetchGoals);
            yield put(strategyMapSlice.actions.fetchGoalsSuccess(response.data));

            const goalID = parseInt(localStorage.getItem('lastOpenedMap'));
            const goalNodeIDs = response.data.reduce( (acc,cur) => {acc.push(cur.id); return acc}, []);
            if( goalID && goalNodeIDs.includes(goalID) )
                yield put(strategyMapSlice.actions.getGoal({id: goalID}));
            else {
                yield put(strategyMapSlice.actions.getGoal({id: goalNodeIDs[0]}));
            }
        }
        else
            yield put(strategyMapSlice.actions.fetchingDone());
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}


function* fetchStrategicIterationsSaga(action) {
    try {
        const response = yield call(Api.fetchStrategicIterations, action.payload)
        yield put(strategyMapSlice.actions.fetchStrategicIterationsSuccess(response.data))
    } catch (error) {
        yield put(strategyMapSlice.actions.requestFailure(error))
        Raven.captureException("fetchStrategicIterations", {error})
    }
}

function* addStrategicIterationSaga(action) {
    try {
        const response = yield call(Api.addStrategicIteration, action.payload)
        yield put(strategyMapSlice.actions.addStrategicIterationSuccess(response.data))
    } catch (error) {
        yield put(strategyMapSlice.actions.requestFailure(error))
        Raven.captureException("addStrategicIteration", {error})
    }
}

function* updateStrategicIterationSaga(action) {
    try {
        const response = yield call(Api.updateStrategicIteration, action.payload)
        yield put(strategyMapSlice.actions.updateStrategicIterationSuccess(response.data))
    } catch (error) {
        yield put(strategyMapSlice.actions.requestFailure(error))
        Raven.captureException("updateStrategicIteration", {error})
    }
}

function* deleteStrategicIterationSaga(action) {
    try {
        yield call(Api.deleteStrategicIteration, action.payload)
        yield put(strategyMapSlice.actions.deleteStrategicIterationSuccess(action.payload))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export function* getNode(action) {
    try {
        const response = yield call(Api.getNode, action.payload)
        yield put(strategyMapSlice.actions.getNodeSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* fetchActivities(action) {
    const id = action.payload
    try {
        const response = yield call(Api.fetchActivities, id)
        yield put(strategyMapSlice.actions.fetchActivitiesSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* fetchAllowedPriorities(action) {
    const {goalPk} = action.payload
    try {
        const response = yield call(Api.fetchAllowedPriorities, goalPk)
        yield put(strategyMapSlice.actions.fetchAllowedPrioritiesSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* fetchActiveUsers() {
    try {
        const response = yield call(Api.fetchActiveUsers)
        yield put(strategyMapSlice.actions.fetchActiveUsersSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* deactivateUser(action) {
    const user = action.payload
    try {
        const response = yield call(Api.deactivateUser, user)
        yield put(strategyMapSlice.actions.deactivateUserSuccess(response.data))
        yield put(strategyMapSlice.actions.fetchActiveUsers())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* updateNode(action) {
    const node = action.payload

    try {
        const response = yield call(Api.updateNode, node)
        yield put(strategyMapSlice.actions.updateNodeSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* updatePriority(action) {
    try {
        yield put(strategyMapSlice.actions.updatePrioritySuccess(action.payload.nodes))
        yield call(Api.updatePriority, action.payload)
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* deleteNode(action) {
    const node = action.payload

    try {
        yield put(coreSlice.actions.closeSidebar())
        yield call(Api.deleteNode, node)
        yield put(strategyMapSlice.actions.deleteNodeSuccess(node))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}
function* deleteGoal(action) {

    const node = action.payload;

    try {
        yield call( Api.deleteNode, node );
        if( localStorage.getItem('lastOpenedMap') === node.id ) {
            localStorage.removeItem('lastOpenedMap');
            yield put(coreSlice.actions.closeSidebar());
        }
        yield put( strategyMapSlice.actions.fetchGoals({action: "forced"}) );
    } catch (error) {
        consoleError( error );
        yield put( strategyMapSlice.actions.requestFailure( error ) );
    }
}

function* deleteAllChildNodes(action) {
    const node = action.payload

    try {
        yield call(Api.deleteAllChildNodes, node)
        yield put(coreSlice.actions.closeSidebar())
        yield put(strategyMapSlice.actions.deleteAllChildNodesSuccess(node))
        window.location.reload()
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* archiveNodeSaga(action) {
    const nodeID = action.payload
    try {
        const {data} = yield call(Api.archiveNode, nodeID)
        yield put(coreSlice.actions.closeSidebar())
        yield put(strategyMapSlice.actions.updateNodesSuccess(data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* archiveChildNodesSaga(action) {
    const nodeID = action.payload
    try {
        const {data} = yield call(Api.archiveChildNodes, nodeID)
        yield put(coreSlice.actions.closeSidebar())
        yield put(strategyMapSlice.actions.updateNodesSuccess(data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* unarchiveNodesSaga(action) {
    const nodeIDs = action.payload
    try {
        const response = yield call(Api.unarchiveNodes, nodeIDs)
        yield put(strategyMapSlice.actions.updateNodesSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.requestFailure(error))
    }
}

function* deleteExperimentSuccessCondition(action) {
    try {
        yield call(Api.deleteExperimentSuccessCondition, action.payload)
        yield put(strategyMapSlice.actions.deleteExperimentSuccessConditionSuccess())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* deleteExperimentFailureCondition(action) {
    try {
        yield call(Api.deleteExperimentFailureCondition, action.payload)
        yield put(strategyMapSlice.actions.deleteExperimentFailureConditionSuccess())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* deleteDesiredOutcome(action) {
    try {
        yield call(Api.deleteDesiredOutcome, action.payload)
        yield put(strategyMapSlice.actions.deleteDesiredOutcomeSuccess())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* removePrimaryParent(action) {
    const {pk} = action.payload

    try {
        yield call(Api.removePrimaryParent, {pk})
        yield put(strategyMapSlice.actions.removePrimaryParentSuccess())
        yield put(strategyMapSlice.actions.fetchNodes())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* addNode(action) {
    const node = action.payload;

    try {
        const response = yield call(Api.addNode, node);
        yield put(strategyMapSlice.actions.addNodeSuccess(response.data.node));
        yield put(strategyMapSlice.actions.showNodeEditingSidebar(response.data.node));
        if (response.data.error_status === 'ERROR_CONNECTING_WITH_TRELLO') {
            displayToastMessage(
                'error',
                I18n.t('strategyMap.messages.itSeemsToBeAProblemWithYourConnectionWithTrello'),
                I18n.t('strategyMap.messages.trelloConnError')
            );
        }
    } catch (error) {
        consoleError(error);
        yield put(strategyMapSlice.actions.requestFailure(error));
    }
}

function* createGoal() {
    try {
        const response = yield call(Api.createGoal)
        localStorage.setItem('lastOpenedMap', response.data.id)
        yield put(strategyMapSlice.actions.createGoalSuccess(response.data))
        yield put(coreSlice.actions.toggleSidebarVisibility(SIDEBARS.Node))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* openNode(action) {
    yield put( coreSlice.actions.closeSidebar() );
    yield put( strategyMapSlice.actions.getGoal( {id: action.payload.goal || action.payload.id} ) );
    yield put( strategyMapSlice.actions.fetchSfs( {goalId: action.payload.goal || action.payload.id} ) );
    yield put( coreSlice.actions.gotoPage( PAGES.StrategyMap ) );
    yield put( strategyMapSlice.actions.showNodeEditingSidebar( {id: action.payload.id} ) );
}

function* addSponsor(action) {
    try {
        const response = yield call(Api.addSponsor, action.payload)
        yield put(strategyMapSlice.actions.addSponsorSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* switchSponsor(action) {
    try {
        const response = yield call(Api.switchSponsor, action.payload)
        yield put(strategyMapSlice.actions.switchSponsorSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* deleteSponsor(action) {
    try {
        const response = yield call(Api.deleteSponsor, action.payload)
        yield put(strategyMapSlice.actions.deleteSponsorSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* terminateExperiment(action) {
    const {experimentId} = action.payload

    try {
        const response = yield call(Api.terminateExperiment, experimentId)
        yield put(strategyMapSlice.actions.updateNodeSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* applyToExperiment(action) {
    try {
        const {userId, nodeId} = action.payload
        yield call(Api.applyToExperiment, userId, nodeId)
        yield put(strategyMapSlice.actions.getNode(nodeId))
        yield put(userSlice.actions.getUser())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* leaveExperiment(action) {
    try {
        const response = yield call(Api.leaveExperiment, action.payload)
        yield put(strategyMapSlice.actions.getNode(response.data.experiment))
        yield put(userSlice.actions.removeFromExperimentTeam())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* approveVolunteerRequest(action) {
    try {
        const response = yield call(Api.approveVolunteerRequest, action.payload)
        yield put(strategyMapSlice.actions.getNode(response.data.experiment))
        yield put(userSlice.actions.addToExperimentTeam(response.data.experiment))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* declineVolunteerRequest(action) {
    try {
        const response = yield call(Api.declineVolunteerRequest, action.payload)
        yield put(strategyMapSlice.actions.getNode(response.data.experiment))
        yield put(userSlice.actions.declineFromExperimentTeam())
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* inviteUserToSquad(action) {
    const {userId, nodeId} = action.payload
    try {
        const response = yield call(Api.inviteUserToSquad, userId, nodeId)
        yield put(strategyMapSlice.actions.getNode(nodeId))
        yield put(strategyMapSlice.actions.inviteUserToSquadSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* acceptUserToSquad(action) {
    try {
        const response = yield call(Api.acceptUserToSquad, action.payload.id)
        yield put(strategyMapSlice.actions.acceptUserToSquadSuccess(response.data))
        yield put(strategyMapSlice.actions.getNode(action.payload.sf))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* fetchSquadMembers(action) {
    const {nodeId} = action.payload
    try {
        const response = yield call(Api.fetchSquadMembers, nodeId)
        yield put(strategyMapSlice.actions.fetchSquadMembersSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* removeSquadMember(action) {
    try {
        yield call(Api.removeSquadMember, action.payload.id)

        yield put(strategyMapSlice.actions.removeSquadMemberSuccess(action.payload.id))
        yield put(strategyMapSlice.actions.getNode(action.payload.sf))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* fetchAllExperiments(action) {
    try{
        let responses = [];
        for( const goal of action.payload){
            const response = yield call(Api.fetchAllExperiments, goal);
            responses = [...responses, ...response.data];
        };

        yield put(strategyMapSlice.actions.fetchAllExperimentsSuccess({data: responses}));

    } catch (error){
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

export const createTrelloBoard = createAPISaga(
    Api.createTrelloBoard,
    strategyMapSlice.actions.createTrelloBoardSuccess,
    strategyMapSlice.actions.requestFailure,
    'createTrelloBoard',
)

function* connectSlackChannel(action) {
    try {
        const response = yield call(Api.connectSlackChannel, action.payload)
        yield put(strategyMapSlice.actions.connectSlackChannelSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* removeIntegrationLink(action) {
    try {
        yield call(Api.removeIntegrationLink, action.payload)
        yield put(strategyMapSlice.actions.removeIntegrationLinkSuccess(action.payload))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* addNodeIntegrationSaga(action) {
    try {
        const response = yield call(Api.addNodeIntegration, action.payload)
        yield put(strategyMapSlice.actions.addNodeIntegrationSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* sendSharedLinkSaga(action) {
    try {
        yield call(Api.sendSharedLink, action.payload)
        displayToastMessage(
            'success',
            I18n.t('sidebar.sharing.dialog.response.onSuccess'),
            I18n.t('sidebar.sharing.dialog.response.subject'),
        )
    } catch (error) {
        consoleError(error)
        displayToastMessage(
            'error',
            I18n.t('sidebar.sharing.dialog.response.onFailure'),
            I18n.t('sidebar.sharing.dialog.response.subject'),
        )
        Raven.captureException(error)
    }
}

function* convertNodeSaga(action) {
    try {
        yield call(Api.convertNode, action.payload)
        window.location.reload()
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* createTarget(action) {
    try {
        const response = yield call(Api.createTarget, action.payload)
        yield put(strategyMapSlice.actions.createTargetSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* updateTarget(action) {
    try {
        const response = yield call(Api.updateTarget, action.payload)
        yield put(strategyMapSlice.actions.updateTargetSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* deleteTarget(action) {
    try {
        yield call(Api.deleteTarget, action.payload)
        yield put(strategyMapSlice.actions.deleteTargetSuccess(action.payload))
    } catch (error) {
        consoleError(error)
        yield put(strategyMapSlice.actions.requestFailure(error))
    }
}

function* setExperimentCanvasVisible(action) {
    if (action.payload) yield put(coreSlice.actions.closeSidebar())
    else yield put(coreSlice.actions.toggleSidebarVisibility(SIDEBARS.Node))
}

function* showNodeCreationSidebar(action) {
    yield put(coreSlice.actions.toggleSidebarVisibilityForce(SIDEBARS.Node))
}

function* showNodeEditingSidebar(action) {
    yield put(coreSlice.actions.toggleSidebarVisibilityForce(SIDEBARS.Node))
}

export function* importStrategyMapFromMiro(action) {
    try {
        yield call(Api.importStrategyMapFromMiro, action.payload)
        displayToastMessage('success', I18n.t('sidebar.demoTools.miro.miroBoardImportedSuccessfully'))
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
        displayToastMessage('error', I18n.t('sidebar.demoTools.miro.errorImportingMiroBoard'))
    }
}

export default function* flow() {
    yield all([
        takeLeading(strategyMapSlice.actions.showNodeEditingSidebar, showNodeEditingSidebar),
        takeLeading(strategyMapSlice.actions.showNodeCreationSidebar, showNodeCreationSidebar),
        takeLeading(
            strategyMapSlice.actions.setExperimentCanvasVisible,
            setExperimentCanvasVisible,
        ),
        takeLeading(strategyMapSlice.actions.fetchNode, fetchNode),
        takeLeading(strategyMapSlice.actions.fetchNodes, fetchNodes),
        takeLeading(strategyMapSlice.actions.fetchSfs, fetchSfs),
        takeLeading(strategyMapSlice.actions.clearNodes, clearNodes),
        takeLeading(strategyMapSlice.actions.generateDecisions, generateDecisions),
        takeLeading(strategyMapSlice.actions.generateSMNodes, generateSMNodes),
        takeLeading(strategyMapSlice.actions.importSMNodes, importSMNodes),
        takeLeading(strategyMapSlice.actions.fetchSfsSilently, fetchSfs),
        takeEvery(strategyMapSlice.actions.fetchChildren, fetchChildren),
        takeEvery(strategyMapSlice.actions.fetchChildrenSilently, fetchChildrenSilently),
        takeLeading(strategyMapSlice.actions.fetchGoals, fetchGoals),
        takeLeading(strategyMapSlice.actions.getNode, getNode),
        takeLeading(strategyMapSlice.actions.fetchActivities, fetchActivities),
        takeLeading(strategyMapSlice.actions.fetchAllowedPriorities, fetchAllowedPriorities),
        takeLeading(strategyMapSlice.actions.fetchActiveUsers, fetchActiveUsers),
        takeLeading(strategyMapSlice.actions.deactivateUser, deactivateUser),
        takeLeading(strategyMapSlice.actions.updateNode.type, updateNode),
        takeLeading(strategyMapSlice.actions.updatePriority.type, updatePriority),
        takeLeading(strategyMapSlice.actions.deleteNode, deleteNode),
        takeLeading(strategyMapSlice.actions.deleteGoal, deleteGoal),
        takeLeading(strategyMapSlice.actions.deleteAllChildNodes, deleteAllChildNodes),
        takeLeading(strategyMapSlice.actions.archiveNode, archiveNodeSaga),
        takeLeading(strategyMapSlice.actions.archiveChildNodes, archiveChildNodesSaga),
        takeLeading(strategyMapSlice.actions.unarchiveNodes, unarchiveNodesSaga),
        takeLeading(
            strategyMapSlice.actions.deleteExperimentFailureCondition,
            deleteExperimentFailureCondition,
        ),
        takeLeading(
            strategyMapSlice.actions.deleteExperimentSuccessCondition,
            deleteExperimentSuccessCondition,
        ),
        takeLeading(strategyMapSlice.actions.deleteDesiredOutcome, deleteDesiredOutcome),
        takeLeading(strategyMapSlice.actions.removePrimaryParent, removePrimaryParent),
        takeLeading(strategyMapSlice.actions.addNode, addNode),
        takeLeading(strategyMapSlice.actions.createGoal, createGoal),
        takeLeading(strategyMapSlice.actions.getGoal, getGoal),
        takeLeading(strategyMapSlice.actions.addSponsor, addSponsor),
        takeLeading(strategyMapSlice.actions.switchSponsor, switchSponsor),
        takeLeading(strategyMapSlice.actions.deleteSponsor, deleteSponsor),
        takeLeading(strategyMapSlice.actions.terminateExperiment, terminateExperiment),
        takeLeading(strategyMapSlice.actions.leaveExperiment, leaveExperiment),
        takeLeading(strategyMapSlice.actions.approveVolunteerRequest, approveVolunteerRequest),
        takeLeading(strategyMapSlice.actions.declineVolunteerRequest, declineVolunteerRequest),
        takeLeading(strategyMapSlice.actions.updatePriority.type, updatePriority),
        takeLeading(strategyMapSlice.actions.applyToExperiment, applyToExperiment),
        takeLeading(strategyMapSlice.actions.inviteUserToSquad, inviteUserToSquad),
        takeLeading(strategyMapSlice.actions.acceptUserToSquad, acceptUserToSquad),
        takeLeading(strategyMapSlice.actions.fetchSquadMembers, fetchSquadMembers),
        takeLeading(strategyMapSlice.actions.removeSquadMember, removeSquadMember),
        takeLeading(strategyMapSlice.actions.fetchAllExperiments, fetchAllExperiments),
        takeLeading(strategyMapSlice.actions.removeIntegrationLink, removeIntegrationLink),
        takeLeading(strategyMapSlice.actions.createTrelloBoard, createTrelloBoard),
        takeLeading(strategyMapSlice.actions.connectSlackChannel, connectSlackChannel),
        takeLeading(strategyMapSlice.actions.convertNode, convertNodeSaga),
        takeLeading(strategyMapSlice.actions.sendSharedLink, sendSharedLinkSaga),
        takeLeading(strategyMapSlice.actions.addNodeIntegration, addNodeIntegrationSaga),
        takeLeading(strategyMapSlice.actions.exportSM, exportSM),
        takeLeading(strategyMapSlice.actions.createTarget, createTarget),
        takeLeading(strategyMapSlice.actions.updateTarget, updateTarget),
        takeLeading(strategyMapSlice.actions.deleteTarget, deleteTarget),
        takeLeading(strategyMapSlice.actions.addStrategicIteration, addStrategicIterationSaga),
        takeLeading(strategyMapSlice.actions.updateStrategicIteration, updateStrategicIterationSaga),
        takeLeading(strategyMapSlice.actions.fetchStrategicIterations, fetchStrategicIterationsSaga),
        takeLeading(strategyMapSlice.actions.deleteStrategicIteration, deleteStrategicIterationSaga),
        takeLeading(strategyMapSlice.actions.openNode, openNode),
        takeLeading(strategyMapSlice.actions.importStrategyMapFromMiro, importStrategyMapFromMiro),
    ])
}
