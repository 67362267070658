import React from 'react';
import styled from "styled-components";
import {LIGHT_GREY} from '../../../../ui/_colors';
import ProgressBar from "../../NavbarProgress";

const CadenceProgressBarStyle = styled.div`
    display: table;
    border-top: 1px solid ${LIGHT_GREY};
    border-bottom: 0px;
    overflow: hidden;
`;

const CadenceProgressBars = ({values}) =>
    <CadenceProgressBarStyle>
        {
            values.map( (value,id) => <ProgressBar key={id} value={value.value} tooltiptext={value.description} color={value.color}/> )
        }
    </CadenceProgressBarStyle>

export default CadenceProgressBars;