import styled from 'styled-components'

export const DemographicsTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 0 10px;

    @media (min-width: 576px) {
        margin-left: 0px;
    }

    @media (min-width: 992px) {
        padding: 0;
    }
`

export const UserDemographics = styled.div`
    width: 100%;
    padding: 2% 0;
    display: block;
    float: left;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        padding: 2% 2%;
    }
`

export const DemographicRow = styled.div`
    width: 100%;
    margin-bottom: 26px;
    .tag {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px !important;
    }
    .value {
        font-size: 16px;
    }

    @media (min-width: 576px) {
        float: left;
        width: 33%;
        margin-bottom: 20px;
    }
    @media (min-width: 768px) {
        width: auto;
        margin-right: 60px;
    }
`

export const DemographicsInfo = styled.div`
    @media (min-width: 576px) {
        width: 100%;
        height: 26px;
        margin: 0;
    }
`
