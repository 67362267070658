import React, {useEffect, useState} from 'react'
import RTEditor from './RTEditor'
import styled from 'styled-components'
import {GREY, MID_GREY} from '../ui/_colors'

const Container = styled.div`
    margin-bottom: 10px;
    .rich-text-editor {
        height: ${(p) => (p.height ? p.height + 'px' : 'auto')};
    }
    .ql-editor {
        min-height: ${(p) => (p.minHeight ? p.minHeight + 'px !important' : '200px')};
    }
    .ql-snow .ql-tooltip {
        left: 5px !important;
    }
    .ql-snow .ql-tooltip input[type='text'] {
        width: 140px;
    }
`

const TextArea = styled.textarea`
    width: 100%;
    border: 1px solid ${GREY};
    border-radius: 0;
    color: ${MID_GREY};
    font-size: 16px;
    height: 40px;
    padding: 8px;
`

const TextAreaAutoSwitch = ({
    text,
    placeholder,
    characterLimit,
    onChange,
    onClick,
    hideToolbar,
    shrink,
    minHeight,
    onMentionsChange,
}) => {
    const [value, setValue] = useState(text || '')

    useEffect(() => {
        setValue(text)
    }, [text])

    return (
        <Container minHeight={minHeight}>
            {shrink && hideToolbar ? (
                <TextArea
                    onClick={() => {
                        onClick && onClick()
                    }}
                    rows={2}
                    placeholder={placeholder}
                />
            ) : (
                <RTEditor
                    text={value}
                    placeholderText={placeholder}
                    characterLimit={characterLimit}
                    onMentionsChange={(mentions) => {
                        onMentionsChange && onMentionsChange(mentions)
                    }}
                    onTextChange={(inputText) => {
                        setValue(inputText)
                        onChange(inputText)
                    }}
                    hideToolbar={hideToolbar}
                />
            )}
        </Container>
    )
}

export default TextAreaAutoSwitch
