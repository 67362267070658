import { useContext } from 'react'
import historyContext from '../contexts/historyContext'
import {PAGES} from "../const";

export const useHistory = () => {
    const history = useContext( historyContext );
    const currentPageURL = history.location.pathname.substr(1);

    let current = {};

    if (currentPageURL.includes(PAGES.StrategyMap))
        current = {isStrategyMapPage: true, currentPage: PAGES.StrategyMap};
    else if (currentPageURL.includes(PAGES.OrgScan.Capture))
        current = {isOrgscanCapturePage: true, isCapturePage: true, currentPage: currentPageURL};
    else if (currentPageURL.includes(PAGES.LeaderShip.Capture) || currentPageURL.includes(PAGES.LeaderShip.SelfAssessment))
        current = {isLeadershipCapturePage: true, isCapturePage: true, currentPage: currentPageURL};
    else if (currentPageURL.includes(PAGES.Billing))
        current = {isBillingPage: true, currentPage: PAGES.Billing};
    else if (currentPageURL.includes(PAGES.OrgScan.Analysis))
        current = {isOrgscanAnalysisToolPage: true, currentPage: currentPageURL};
    else if (currentPageURL.includes(PAGES.LeaderShip.Analysis))
        current = {isLeadershipAnalysisToolPage: true, currentPage: currentPageURL};
    else if (currentPageURL.includes(PAGES.Dashboard) || currentPageURL === "")
        current = {isDashboardPage: true, isMainDashboardPage: true, currentPage: PAGES.Dashboard};
    else if (currentPageURL.includes(PAGES.OrgScan.Dashboard))
        current = {isDashboardPage: true, isOrgscanDashboardPage: true, currentPage: currentPageURL};
    else if (currentPageURL.includes(PAGES.LeaderShip.Dashboard))
        current = {isDashboardPage: true, isLeadershipDashboardPage: true, currentPage: currentPageURL};
    else
        current = {currentPage: "others"};

    const isLeaderShip = currentPageURL && ( Object.values( PAGES.LeaderShip ).includes( currentPageURL ) || currentPageURL.startsWith( PAGES.LeaderShip.Capture ) );
    const isLeaderShipReport = currentPageURL && currentPageURL.startsWith( PAGES.LeadershipAssessment );

    return {
        history, 
        ...{
            isStrategyMapPage: false, 
            isCapturePage: false, 
            isOrgscanCapturePage: false,
            isLeadershipCapturePage: false,
            isBillingPage: false, 
            isOrgscanAnalysisToolPage: false,
            isLeadershipAnalysisToolPage: false,
            isDashboardPage: false,
            isMainDashboardPage: false, 
            isOrgscanDashboardPage: false,
            isLeadershipDashboardPage: false,
            isLeaderShip: isLeaderShip,
            isLeaderShipReport: isLeaderShipReport
        }, 
        ...current
    };
}
