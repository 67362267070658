import * as DateCalculation from '../../../core/utils/date'
import _ from 'lodash'
import {getLastDate, transformFilterDemographisToPKList} from "./utils";

export const DURATION_PARAMETER = {'NOW': 'NOW', 'BEFORE': 'BEFORE'};
export class ObjectDecisions {

    decision = [];
    filter = {};


    constructor(decisions, filters){
        this.decisions = _.cloneDeep( decisions );
        this.filters = _.cloneDeep( filters );
    }

    filterByTimeRange = () => {

        if( this.filters ){

            if( this.filter.from || this.filter.to ) {
                const FROM = this.filters.from || null;
                const TO = this.filters.to || null;

                this.decisions =
                    this.decisions
                        .filter(decision => !FROM || decision.date >= FROM)
                        .filter(decision => !TO || decision.date <= TO);
            }


            if( this.filters.periods ) {
                const FROM = this.filters.periods && this.filters.periods.from || null;
                const TO = this.filters.periods && this.filters.periods.to || null;

                this.decisions =
                    this.decisions
                        .filter(decision => !FROM || decision.date >= FROM)
                        .filter(decision => !TO || decision.date <= TO);
            }
        }


        return this;
    };

    filterByDuration = startPoint => {

        const calculateOffset = startPoint => {
            switch (startPoint) {
                case DURATION_PARAMETER.NOW:
                    return {from: this.filters.duration, to: 0};
                case DURATION_PARAMETER.BEFORE:
                    return {from: 2 * this.filters.duration, to: this.filters.duration};
            }
        }

        if( this.filters.duration ){

            const offset = calculateOffset( startPoint );
            const FROM = getLastDate( offset.from );
            const TO = getLastDate( offset.to );

            this.decisions =
                this.decisions
                    .filter(decision => !FROM || decision.date >= FROM)
                    .filter(decision => !TO || decision.date <= TO);
        }
        return this;
    };

    filterByDemographics = () => {

        const demographicTypes =
            Object.keys(this.filters)
                .filter( demographicType => ['city', 'country', 'department', 'role', 'years'].includes( demographicType ) )
                .filter( demographicType => this.filters[demographicType].length );

        if ( demographicTypes.length ) {

            const appliedFiltersListObject = transformFilterDemographisToPKList(this.filters);

            this.decisions =
                this.decisions
                    .filter( decision =>
                        Object.keys(appliedFiltersListObject)
                            .every( filterKey => 
                                appliedFiltersListObject && appliedFiltersListObject[filterKey]
                                                                .includes( decision.demographics && decision.demographics[filterKey] )
                            )
                    );
        }

        return this;
    }

    filterByExperiments = () => {

        if( this.filters.experiments )
            this.decisions =
                this.decisions
                    .filter( decision => decision.experiments.includes( this.filters.experiment.map( exp => exp.id ) ) );

        return this;
    }

    filterByExcludeExperiments = () => {

        if( this.filters.experiments )
            this.decisions =
                this.decisions
                    .filter( decision => ! decision.experiments.includes( this.filters.experiment.map( exp => exp.id ) ) );

        return this;
    }

    filterByDataSet = () => {

        let filteredDecisions = this.decisions;

        Object.keys(this.filters)
            .filter( filterKey => ['decision_owner', 'type'].includes( filterKey ) )
            .filter( filterKey => this.filters[filterKey].length )
            .forEach( filterKey =>
                filteredDecisions = filteredDecisions.filter( e => this.filters[filterKey].includes( e[filterKey] ) ) );

        this.decisions = filteredDecisions;

        return this;
    }

    newInstance = () => new ObjectDecisions( this.decisions, this.filters );
}


export const decisionsFiltered = ( decisions, filter ) =>
    decisionsFilteredRaw( decisions, filter ).decisions;

const decisionsFilteredRaw = ( decisions, filter ) =>
    new ObjectDecisions( decisions, filter )
        .filterByTimeRange()
        .filterByDemographics()
        .filterByDataSet();

export const filterDecisions = (decisions, appliedFilters) => {

    const decisionAfterFilter = decisionsFilteredRaw( decisions, appliedFilters );

    if( appliedFilters.experiment && appliedFilters.experiment.length > 0 )

        return {
            dataShown: decisionAfterFilter
                .filterByDuration( DURATION_PARAMETER.NOW )
                .newInstance()
                .filterByExperiments()
                .decisions,
            dataCompare: decisionAfterFilter
                .filterByDuration( DURATION_PARAMETER.NOW )
                .newInstance()
                .filterByExcludeExperiments()
                .decisions,
        }

    return {
        dataShown: decisionAfterFilter.newInstance().filterByDuration( DURATION_PARAMETER.NOW ).decisions,
        dataCompare: decisionAfterFilter.newInstance().filterByDuration( DURATION_PARAMETER.BEFORE ).decisions
    }
}

export const choiceHasDecision = (demographicType, demographicPk, decisions, duration) => {
    const endDate = DateCalculation.dateStringToInteger(getLastDate());
    const filter = {
        from: DateCalculation.dateAddDays(endDate, -duration),
        to: endDate
    };

    const filteredDecisionsWithPeriod = new ObjectDecisions( decisions, filter ).filterByTimeRange().decisions;

    const decision = filteredDecisionsWithPeriod.find( decision => {
        const dataset = demographicType.reduce( (cur,acc) => cur && cur[acc] ? cur[acc] : undefined, decision );
        return dataset === demographicPk;
    });

    return decision !== undefined;
}
