import styled from 'styled-components'
import { WHITE } from '../ui/_colors'

export const ModalBackground = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
`

export const ModalBody = styled.div`
    min-width: 200px;
    min-height: 200px;
    max-height: calc(100vh - 100px);
    max-width: 1000px;
    background: ${WHITE};
    overflow: hidden;
    padding-top: 28px;
    padding: 20px;
    display: flex;
    flex-direction: column;
`

export const ModalContent = styled.div`
    width: 100%;
    min-height: 200px;
    max-height: calc(100% - 200px);
    overflow-y: auto;
    flex: 5;
`

export const ModalHeader = styled.div`
    width: 100%;
    flex: 1;
`

export const ModalFooter = styled.div`
    width: 100%;
    min-height: 80px;
    flex: 1;
`

export const Title = styled.h2`
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.25;
`

export const HelpText = styled.div`
    margin: 24px 0;
    font-size: 16px;
`

export const ModalFooterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    flex-wrap: wrap;
`

export const ModalFooterLeft = styled.div`
    flex: 1;
    min-width: 300px;
`

export const ModalFooterRight = styled.div`
    flex: 3;
    min-width: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
