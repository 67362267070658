import {useEffect, useState} from 'react'

export const useReplace = (dep1, dep2) => {
    const [replaced, setReplaced] = useState(dep1)

    useEffect(() => {
        setReplaced(dep1)
    }, [dep1])

    useEffect(() => {
        setReplaced(dep2)
    }, [dep2])

    return replaced
}
