import styled from 'styled-components'
import {LIGHT_GREY, MID_GREY} from '../../core/ui/_colors'

export const InputWrapper = styled.div`
    width: 100%;
    input,
    textarea {
        width: 100%;
        padding: 10px;
        border: 2px solid ${LIGHT_GREY};

        &:focus {
            outline: none;
        }
        &::placeholder {
            font-size: 14px;
            color: ${MID_GREY};
            line-height: 28px;
        }
    }
    textarea {
        min-height: 160px;
    }
    input {
        max-height: 42px;
        margin-bottom: 5px;
    }

    @media (min-width: 992px) {
        input,
        textarea {
            &::placeholder {
                font-size: 16px;
            }
        }
        input {
            height: auto;
        }
    }
`

export const ErrorContainer = styled.div`
    color: red;
    width: 100%;
    padding: 10px 0;
`

export const ErrorList = styled.ul`
    list-style: none;
    padding-left: 0;
`

export const CenterContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 64px
    padding-bottom: 32px
`

export const PageCenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 200px);
`

export const EvenlyContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`

export const SpaceBetweenContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`

export const InfoLabel = styled.div`
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: ${p => p.center ? 'center' : 'left'};
    margin: 20px auto;
`