import React, {useEffect, useState} from 'react'
import {useActions} from '../core/hooks/useActions'
import {I18n} from 'react-i18nify'
import {ButtonSecondary} from '../core/components/Buttons'
import SlackAuth from './SlackAuth'
import {FullScreenLoading} from '../core/components/Loading'
import TrelloAuth from './TrelloAuth'
import {integrationsSlice} from '../integrations/redux/slices'
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import { Panel } from '../core/components/BootstrapWrapper'
import DashboardMessageCentered from '../dashboard/components/DashboardMessageCentered'

const Integrations = () => {
    const {client, features, isFetching, Actions: CoreActions} = useCoreSlice();
    const {disconnectSlack, disconnectTrello, connectTrello} = useActions(integrationsSlice.actions);
    const [performSlackAuth, setPerformSlackAuth] = useState(false);
    const [performTrelloAuth, setPerformTrelloAuth] = useState(false);

    useEffect(() => {
        if (!client.name)
            CoreActions.getClient();
    }, [])

    return (
        <div>
            {!client.slack_access_token && performSlackAuth && <SlackAuth />}
            {!client.trello_access_token && performTrelloAuth && (
                <TrelloAuth
                    open={performTrelloAuth}
                    onClose={(accessToken) => {
                        setPerformTrelloAuth(false)
                        if (accessToken) connectTrello(accessToken)
                    }}
                />
            )}
            <FullScreenLoading loading={isFetching} />
            {features['integration.slack'] && <Panel header={I18n.t('billing.Label.Slack')}>
                <p>
                    {!client.slack_access_token
                        ? I18n.t('billing.Label.SlackIntegrationDescription')
                        : I18n.t('billing.Label.SlackIntegrationConnectedDescription')}
                </p>
                {!client.slack_access_token && (
                    <ButtonSecondary
                        noSideMrg
                        onClick={() => setPerformSlackAuth(true)}
                    >
                        {I18n.t('billing.Label.ConnectSlack')}
                    </ButtonSecondary>
                )}
                {client.slack_access_token && (
                    <ButtonSecondary 
                        noSideMrg 
                        onClick={() => disconnectSlack()}
                    >
                        {I18n.t('billing.Label.DisconnectSlack')}
                    </ButtonSecondary>
                )}
            </Panel>}
            {features['integration.trello'] && <Panel header={I18n.t('billing.Label.Trello')}>
                <p>
                    {!client.trello_access_token
                        ? I18n.t('billing.Label.TrelloIntegrationDescription')
                        : I18n.t('billing.Label.TrelloIntegrationConnectedDescription')}
                </p>
                {!client.trello_access_token && (
                    <ButtonSecondary
                        noSideMrg
                        onClick={() => setPerformTrelloAuth(true)}
                    >
                        {I18n.t('billing.Label.ConnectTrello')}
                    </ButtonSecondary>
                )}
                {client.trello_access_token && (
                    <ButtonSecondary 
                        noSideMrg 
                        onClick={() => disconnectTrello()}>
                        {I18n.t('billing.Label.DisconnectTrello')}
                    </ButtonSecondary>
                )}
            </Panel>}
            {!features['integration.slack'] && !features['integration.trello'] && 
                <Panel>
                    <DashboardMessageCentered message={I18n.t('dashboard.messages.featureDisabled')} />
                </Panel>
            }
        </div>
    )
}

export default Integrations
