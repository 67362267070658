import styled, {css} from 'styled-components'
import {LIGHT_GREY, WHITE} from '../../core/ui/_colors'

export const CornerLabel = styled.div`
    word-break: break-word;
    padding: 0 8px;
`
const mask = css`
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
`
const maskInspect = css`
    clip-path: polygon(50% 0,50% 0,100% 100%,0 100%);
    -webkit-clip-path: polygon(50% 0,50% 0,100% 100%,0 100%);
`
export const HeatMapChartWrapper = styled.div`
	position: relative; 
	overflow: hidden;
`
export const ChartMaskOutside = styled.div`
    ${({visible}) => visible && `position: relative`};
    ${({visible, inspectMode}) => visible && inspectMode && `top: -2px`};
    ${({visible, inspectMode}) => visible && inspectMode && `right: -3px`};
    ${({visible, width}) => visible && `width: ${width}px`};
    ${({visible, height}) => visible && `height: ${(height * Math.sqrt(3)) / 2}px`};
    ${({visible}) => visible && `background: ${LIGHT_GREY}`};
    ${({visible, inspectMode}) => visible && (inspectMode ? maskInspect: mask)};
    margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	margin-bottom: 15px;
`
export const ChartMaskInside = styled.div`
    ${({visible}) => visible && `position: absolute`};
    ${({visible}) => visible && `width: calc(100% - 6px)`};
    ${({visible}) => visible && `height: calc(100% - 4px)`};
    ${({visible}) => visible && `background: ${WHITE}`};
    ${({visible}) => visible && `top: 2px`};
    ${({visible}) => visible && `left: 3px`};
    ${({visible}) => visible && `right: 3px`};
    ${({visible}) => visible && `bottom: 2px`};
    ${({visible, inspectMode}) => visible && (inspectMode ? maskInspect: mask)};
    .cursor-pointer {
        ${({inspectMode}) => !inspectMode && 'cursor: default!important;' }
    }
`
export const ToolItem = styled.img`
    height: 16px;
    cursor: default;
    margin-left: 10px; 
    ${({filter}) => filter && `filter: opacity(35%)`};
`