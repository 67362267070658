import React, {useEffect} from 'react';
import {useUniqueId} from '../../core/hooks/useUniqueId';
import {truncateLabelsFromArray} from '../../core/utils/string';

const raw2percentageArray = raw => {
    const sum = raw.reduce( (acc,cur) => acc + Math.abs(cur), 0 );
    return raw.map( value => Math.round(100. * value/sum ) );
}

const PieChart = (props) => {
    const id = useUniqueId();

    useEffect(() => {
        const plotly = require('plotly.js');
        const settings = props.settings || {
            showTips: false,
            displayModeBar: false
        };

        plotly.newPlot(
            id,
            [
                {
                    values: raw2percentageArray( props.values ),
                    hoverinfo: 'label+percent',

                    labels: truncateLabelsFromArray( props.labels ),
                    type: 'pie',
                    marker: { colors: props.colorScheme }
                }
            ],
            {
                height: props.height || 400,
                width: props.width || 500
            },
            settings
        )
    }, [ props.values ])
    return <div id={ id } data-testid={ props.testId || 'piechart' } />
}

export default React.memo(PieChart);
