import {combineReducers} from 'redux'
import {strategyMapSlice} from '../strategyMap/redux/slices'
import {connectRouter} from 'connected-react-router'
import {coreSlice, demographicsSlice, iconVisible, languageSlice} from '../core/redux/slices'
import {userSlice} from '../auth/redux/user.slices'
import {commentSlice} from '../strategyMap/redux/comment.slices'
import {notificationSlice} from '../strategyMap/redux/notification.slices'
import {dashboardSlice} from '../dashboard/redux/slices'
import {captureSlice} from '../capture/redux/slices'
import {integrationsSlice} from '../integrations/redux/slices'
import {analysisSlice} from '../analysis/redux/slices'

export default (history) =>
    combineReducers({
        capture: captureSlice.reducer,
        dashboard: dashboardSlice.reducer,
        demographics: demographicsSlice.reducer,
        notifications: notificationSlice.reducer,
        iconVisible: iconVisible.reducer,
        language: languageSlice.reducer,
        user: userSlice.reducer,
        comment: commentSlice.reducer,
        core: coreSlice.reducer,
        strategyMap: strategyMapSlice.reducer,
        router: connectRouter(history),
        integrations: integrationsSlice.reducer,
        analysis: analysisSlice.reducer,
    })
