import React, {useEffect, useRef} from 'react'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import {useReactToPrint} from 'react-to-print'
import {ContentGrid} from './Capture.styles'
import {useCaptureSlice} from './hooks/useCaptureSlice'
import {FullScreenLoading} from '../core/components/Loading'
import PieChart from '../Charts/Piechart/PieChart'
import {BLUE, GREEN, ORANGE, PALE_BLUE, PURPLE, YELLOW} from '../core/ui/_colors'
import StackedBars from './signifier/StackedBars'
import expert from '../img/Capture/expert.png'
import catalysing from '../img/Capture/catalysing.png'
import coach from '../img/Capture/coach.png'
import coaching from '../img/Capture/coaching.png'
import conducting from '../img/Capture/conducting.png'
import coordinator from '../img/Capture/coordinator.png'
import demanding from '../img/Capture/demanding.png'
import directing from '../img/Capture/directing.png'
import envisioning from '../img/Capture/envisioning.png'
import peer from '../img/Capture/peer.png'
import strategist from '../img/Capture/strategist.png'
import {Div} from '../core/ui/Div'
import PieChartsProgress from '../Charts/PieChartProgress/PieChartsProgress'
import {SubTypes} from '../Charts/ChartsConfig'
import {trafficLightColor} from '../Charts/ChartColorSchema'
import { CopyToClipboard } from '../core/components/CopyToClipboard'
import { Panel } from '../core/components/BootstrapWrapper'
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";
import upsellingBanner from '../img/upselling-banner.png'
import { Col, Row } from 'react-bootstrap'
import _ from 'lodash'

const Heading = styled.div`
    font-size: 24px;
    font-weight: bold;
`
const SubHeading = styled(Div)`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
`
const Image = styled.img`
    height: 100px;
    margin: 0 auto;
    display: block;
`
const Banner = styled.img`
    margin-bottom: 40px; 
    width: 100%;
    display: block;
`
const HideOnPrint = styled.div`
    @media print {
        display: none;
    }
`
const ReportContainer = styled.div`
    @media print {
        .col-3-print {
            width: 33.3%!important;
            float: left;
        }
    }
`

const BEHAVIOUR_COLORS = {
    demanding: GREEN,
    coaching: ORANGE,
    envisioning: YELLOW,
    directing: PALE_BLUE,
    conducting: PURPLE,
    catalysing: BLUE,
}

export const LEADERSHIP_IMAGES = {
    catalysing,
    coach,
    coaching,
    conducting,
    coordinator,
    demanding,
    directing,
    envisioning,
    expert,
    peer,
    strategist
}

const LeadershipAssessmentReport = (props) => {
    const {Actions, isFetching, behaviours, archetype, archetype_map, captured_data, link_to_360} = useCaptureSlice()
    const {isUserLoggedIn, pk} = useUserSlice();
    const printerRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => printerRef.current,
    })

    const getArchetypeData = () => {
        if (!behaviours || _.isEmpty(archetype_map)) return  []
        const  archetypeKeys = Object.keys(archetype);
        const data = []
        for (let i = 0;  i < 3; i++) {
            const row = {
                label:  archetypeKeys[i]
            }
            archetype_map[archetypeKeys[i]].forEach((behaviour) => {
                row[behaviour] = behaviours[behaviour]
            })
            data.push(row)
        }

        return data.map( entry => ({...entry, label: I18n.t("leadership.Report."+entry.label)}));
    }

    const getPercentage = (dataSet) => {
        if (!behaviours) return  []
        const  keys = Object.keys(dataSet);
        let total = 0;
        const data = {}
        keys.forEach((item) => {
            total += dataSet[item]
        })
        keys.forEach((item) => {
            data[item] = Math.round((dataSet[item] / total) * 100)
        })
        return data;
    }

    const getDominantArchetype = () => {
        if (!behaviours || _.isEmpty(archetype)) return  []
        const  archetypeKeys = Object.keys(archetype);
        const data = []
        for (let i = 0;  i < 3; i++) {
            data.push(archetypeKeys[i])
        }

        return data;
    }

    const getChosenBehaviour = () => {
        if (!captured_data) return

        const chosen_value = captured_data.autonomy_vs_control
        const sectionValue =  Math.floor(100/6)
        if (chosen_value <= sectionValue) return "directing"
        if (chosen_value > sectionValue && chosen_value <= sectionValue * 2) return "demanding"
        if (chosen_value > sectionValue * 2 && chosen_value <= sectionValue * 3) return "conducting"
        if (chosen_value > sectionValue * 3 && chosen_value <= sectionValue * 4) return "envisioning"
        if (chosen_value > sectionValue * 4 && chosen_value <= sectionValue * 5) return "coaching"
        if (chosen_value > sectionValue * 5) return "catalysing"
    }

    useEffect(() => {
        Actions.getCapture( props.id );
    }, [])

    const archtypePercent = getPercentage(archetype)
    const behaviourPercent = getPercentage(behaviours)
    const dominantArchetype = getDominantArchetype()
    const chosenBehaviour = getChosenBehaviour()
    const behaviourKeys = behaviours ? Object.keys(behaviours) : []
    const consistency = Math.floor(chosenBehaviour ? (behaviourPercent[chosenBehaviour] / behaviourPercent[behaviourKeys[0]]) * 100 : 0)

    const mainPanelActions = [{
        icon: 'print',
        onClick: handlePrint,
        title: I18n.t('leadership.Report.Print')
    }]

    const getBehaviourChunk = (start, end) => 
            <Row>
                {behaviourKeys.slice(start, end).map((behaviour, i) => (
                    <Col className='col-3-print' xs={12} md={12} lg={4} key={i}>
                        <SubHeading textAlign="center">{I18n.t(`leadership.Report.${behaviour}`)} ({behaviourPercent[behaviour]}%)</SubHeading>
                        <div>
                            <Image src={LEADERSHIP_IMAGES[behaviour]} />
                            <p>
                                {I18n.t(`leadership.Report.Behaviours.${behaviour}`)}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>

    const pieChartDefaultWidth = 500
    const pieChartReducedWidth = 355
    const pieChartWidth = window.screen.width > pieChartDefaultWidth ? pieChartDefaultWidth : pieChartReducedWidth
    
    return (
        <ContentGrid ref={printerRef} id="tester">
            <FullScreenLoading loading={isFetching}/>
            {behaviours && (
                <ReportContainer>
                    <Panel header={I18n.t('leadership.Report.Your Leadership Assessment')} actions={mainPanelActions}>
                        <Row>
                            <Col xs={12} md={12} lg={9}>
                                {isUserLoggedIn && link_to_360 !== "" && <>
                                    <HideOnPrint>
                                        <p>{I18n.t('leadership.Report.CopyToClipboardDescription')}</p>
                                    </HideOnPrint>
                                    <CopyToClipboard text={link_to_360} />
                                    <br />
                                </>}
                                
                                <p style={{marginBottom: 40}}>{I18n.t(`leadership.Report.ThankYouNote`)}</p>

                                {!isUserLoggedIn && <a href='mailto:office@agile42.com'><Banner src={upsellingBanner} /></a>}
                                
                                <SubHeading>{I18n.t(`leadership.Report.How to read this report`)}</SubHeading>
                                <p  style={{marginBottom: 40}}>{I18n.t(`leadership.Report.HowToReadIntro`)}</p>

                                { captured_data.count && <div style={{marginBottom: 16}}>{ I18n.t("leadership.StoryCount") }: <b>{ captured_data.count }</b></div> }
                                {
                                    captured_data.title &&
                                    <blockquote>
                                        <i>
                                            <SubHeading>{captured_data.title}</SubHeading>
                                            {pk === captured_data.owner_pk && <p>{captured_data.description}</p>}
                                        </i>
                                    </blockquote>
                                }
                            </Col>
                        </Row>
                    
                        <Row>
                            <Col xs={12} md={12} lg={6} data-testid="piechart">
                                <Heading style={{marginBottom: 20}}>{I18n.t('leadership.Report.behaviourChartHeader')}</Heading>
                                <p dangerouslySetInnerHTML={{ __html: I18n.t(`leadership.Report.HowToReadBehaviors`)}} />
                                <PieChart
                                    testId="behaviour-piechart"
                                    colorScheme={Object.keys(behaviours).map((name) => BEHAVIOUR_COLORS[name])}
                                    labels={Object.keys(behaviours).map( value => I18n.t("leadership.Report." + value))}
                                    values={Object.values(behaviours)}
                                    width={pieChartWidth}
                                />
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Heading style={{marginBottom: 20}}>{I18n.t('leadership.Report.ArchetypeChartHeader')}</Heading>
                                <p  dangerouslySetInnerHTML={{ __html: I18n.t(`leadership.Report.HowToReadArchetypes`)}}  style={{marginBottom: 80}}/>
                                <StackedBars data={getArchetypeData()} colors={BEHAVIOUR_COLORS} percentageData={getPercentage(behaviours)} testId="archetype-stachbar" />
                            </Col>
                        </Row>
                        
                    </Panel>
                    {dominantArchetype.length > 0 && <Panel header={I18n.t('leadership.Report.Your dominant Leader Archetypes')}>
                        <Row>
                            {dominantArchetype.map((arche) => (
                                <Col className='col-3-print' xs={12} md={12} lg={4} key={arche}>
                                    <SubHeading textAlign="center">{I18n.t(`leadership.Report.${arche}`)} ({archtypePercent[arche]}%)</SubHeading>
                                    <div>
                                        <Image src={LEADERSHIP_IMAGES[arche]} />
                                        <p dangerouslySetInnerHTML={{ __html: I18n.t(`leadership.Report.Archetype.${arche}`)}} />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Panel>}
                    {behaviourKeys.length > 0 && <Panel header={I18n.t('leadership.Report.Leadership Behaviors')}>
                        {getBehaviourChunk(0, 3)}
                        {getBehaviourChunk(3, 6)}
                    </Panel>}
                    <Panel>
                        <div style={{display: 'flex',  justifyContent: 'center'}}>
                            <div  style={{width: 320}}>
                                <PieChartsProgress
                                    testId={'consistency-gauge'}
                                    noData={false}
                                    data={{x: consistency, unit: '%'}}
                                    maxValue={100}
                                    subType={SubTypes.circleProgress.affinity}
                                    colorScheme={{
                                        70: trafficLightColor.yellow,
                                        100: trafficLightColor.green,
                                    }}
                                />
                            </div>
                        </div>
                        <Row>
                            <Col xs={0} md={0} lg={3}></Col>
                            <Col xs={12} md={12} lg={6} style={{textAlign: 'center'}}>
                                {consistency < 101 && <p><b>{I18n.t(`leadership.Report.ConsistencyInfo`).replace(":value:", 100 - consistency + '%')}</b></p>}
                                <p dangerouslySetInnerHTML={{ __html: I18n.t(`leadership.Report.HowToReadConsistency`)}} />
                            </Col>
                        </Row>
                    </Panel>
                </ReportContainer>
            )}
        </ContentGrid>
    )
}

export default LeadershipAssessmentReport
