import Point from "./point.js";
import Shape from "./shape.js";

class Triangle extends Shape {
    /**
     * This represent a triangle in the 2D plane. It is defined by three vertices.
     * @param {Point} v1 The first vertex of the triangle
     * @param {Point} v2 The second vertex of the triangle
     * @param {Point} v3 The third vertex of the triangle
     * @param {String} name The name of the triangle
     */
    constructor(v1, v2, v3, name = "Triangle") {
        super(name);
        this.v1 = v1;
        this.v2 = v2;
        this.v3 = v3;
    }

    get side1() {
        /**
         * Method to calculate the length of the first side of the triangle.
         */
        return Point.distance(this.v1, this.v2);
    }

    get side2() {
        /**
         * Method to calculate the length of the second side of the triangle.
         */
        return Point.distance(this.v2, this.v3);
    }

    get side3() {
        /**
         * Method to calculate the length of the third side of the triangle.
         */
        return Point.distance(this.v3, this.v1);
    }

    get perimeter() {
        /**
         * Method to calculate the perimeter of the triangle.
         */
        return this.side1 + this.side2 + this.side3;
    }

    get area() {
        /**
         * Method to calculate the area of the triangle. It works with all kinds of triangles.
         * @returns {Number} The area of the triangle
         */
        // Calculate the semi-perimeter of the triangle
        const s = this.perimeter / 2;

        // Calculate the area of the triangle using Heron's formula
        return Math.sqrt(s * (s - this.side1) * (s - this.side2) * (s - this.side3));
    }

    containsPoint(P) {
        // Calculate the areas of the sub-triangles and compare with the original triangle
        const area1 = new Triangle(P, this.v2, this.v3).area;
        const area2 = new Triangle(this.v1, P, this.v3).area;
        const area3 = new Triangle(this.v1, this.v2, P).area;
        const areaSum = area1 + area2 + area3;

        return Math.abs(this.area - areaSum) < 1e-10;
    }

    getWeights(P) {
        /**
         * Method to calculate the weights based on the distance of a point from each of the triangle's vertices.
         * @param {Point} P The point to calculate the weights for
         * @returns {Array} An array of three weights as relative percentages
         */
        const totalArea = this.area;
        const weight1 = new Triangle(P, this.v2, this.v3).area / totalArea;
        const weight2 = new Triangle(this.v1, P, this.v3).area / totalArea;
        const weight3 = new Triangle(this.v1, this.v2, P).area / totalArea;

        return [weight1, weight2, weight3];
    }
}

export default Triangle;