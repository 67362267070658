import moment from "moment";

const getPercentage = ( value, total ) => value / total * 100;

class Cadences {

    constructor(currentStrategicIteration, targets) {
        this.currentStrategicIteration = currentStrategicIteration;
        this.targets = targets;
    };

    isActive = () => this.currentStrategicIteration && this.targets.length;

    getTargetsPercentage = () =>
        this.targets.filter( target => target.is_done ).length * 100 / this.targets.length;


    getDaysLeftPercentage = () => {
        const daysDifference = this.getDaysDifferenceOnCurrentIteration( this.currentStrategicIteration );
        const currentIterationDays = daysDifference - this.getDaysLeftCountOnCurrentIteration( this.currentStrategicIteration );

        return getPercentage( currentIterationDays, daysDifference);
    }

    getDaysDifferenceOnCurrentIteration = () =>
        this.currentStrategicIteration && this.currentStrategicIteration.start_date && this.currentStrategicIteration.end_date
            ? moment( this.currentStrategicIteration.end_date ).diff( moment( this.currentStrategicIteration.start_date ), 'days')
            : 0;

    getCompletedTargetsCountOnCurrentIteration = () =>
        this.currentStrategicIteration && this.currentStrategicIteration.targets
            ? this.targets.filter(target => target.is_done && this.currentStrategicIteration.targets.includes(target.id)).length
            : 0;

    getTargetsCountOnCurrentIteration = () =>
        this.currentStrategicIteration && this.currentStrategicIteration.targets
            ? this.currentStrategicIteration.targets.length
            : 0;

    getDaysLeftCountOnCurrentIteration = () =>
        this.currentStrategicIteration && this.currentStrategicIteration.end_date
            ? moment( this.currentStrategicIteration.end_date ).diff( moment(), 'days' )
            : 0;
}

export default Cadences;