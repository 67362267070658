export const LIGHT_GREY = '#DFE1E6'
export const BLACK_GREY = '#262626'
export const MID_GREY = '#7D7D7D'
export const PALE_GREY = '#F2F2F2'
export const DARK_GREY = '#444F57'
export const BRIGHT_GREY = '#FAFAFA'
export const GREY = '#B1B1B1'
export const BLACK = '#000000'
export const WHITE = '#FFFFFF'
export const SHADOW_COLOUR = 'rgba(0, 0, 0, 0.16)'
export const TRANSPARENT = 'transparent'
export const OVERLAY_TRANSPARENT_COLOUR = 'rgba(255, 255, 255, 0.75)'

export const GREEN = '#A5CE3D'
export const BLUE = '#3D7FB0'
export const PALE_BLUE = '#82adcd'
export const YELLOW = '#F8D956'
export const PALE_YELLOW = '#fffcbe'
export const ORANGE = '#F8B356'
export const MAGENTA = '#EC4D4D'
export const PURPLE = '#b87e9e'

export const AVATAR_COLORS = {
    COLOR0: TRANSPARENT,
    COLOR1: GREEN,
    COLOR2: '#729B0A',
    COLOR3: '#D8FF70',
    COLOR4: BLUE,
    COLOR5: '#70B2E3',
    COLOR6: '#0A4C7D',
    COLOR7: ORANGE,
    COLOR8: '#C58023',
    COLOR9: '#FFE689',
    COLOR10: MAGENTA,
    COLOR11: '#FF8080',
    COLOR12: '#B91A1A',
    COLOR13: WHITE,
}
