import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {I18n} from 'react-i18nify'
import Charts from '../../../Charts/Charts'
import {ChartType} from '../../../Charts/ChartsConfig'
import {NODE_STATES} from '../../../strategyMap/const'
import {TimeElapsedDisplay} from '../../../core/components/TimeElapsedDisplay'
import {ExperimentSummary, ExpInfo} from './Experiment.styles'
import {ButtonPrimary} from '../../../core/components/Buttons'
import {showPopupMessage} from '../../../core/utils/notifications'

const ExperimentActivity = (props) => {
    const activityData = useMemo(() => {
        return [
            {
                currentData: props.currentData,
                duration: props.duration,
                status: props.status,
                savedData: props.activityData,
            },
        ]
    }, [props])

    const terminateExperiment = () => {
        showPopupMessage({
            title: I18n.t('strategyMap.messages.warning'),
            message: I18n.t('strategyMap.messages.warningTerminateExperiment'),
            cancelLabel: I18n.t('strategyMap.messages.no'),
            confirmLabel: I18n.t('strategyMap.messages.yes'),
            onConfirmCallback: () => props.terminateExperimentCallback(),
            onCancelCallback: () => {},
        })
    }

    const renderContent = () => {
        const {status} = props
        const labelStatus = I18n.t(`strategyMap.experiment.${status}`)

        if (status === NODE_STATES.E.created) {
            const stateLabel = props.deadlineSet ? 'enabled' : 'disabled'
            return (
                <ButtonPrimary
                    className={'startExp ' + stateLabel}
                    fullWidth
                    data-testid={'experiment-' + stateLabel + '-button'}
                    onClick={props.startExperimentCallback}
                    disabled={!props.deadlineSet}
                >
                    {I18n.t('strategyMap.experiment.startNow')}
                </ButtonPrimary>
            )
        } else {
            let button

            if (status === NODE_STATES.E.started && props.isUserAbleToEditNode) {
                button = (
                    <ButtonPrimary
                        fullWidth
                        className="terminateExp"
                        data-testid={'experiment-terminate-button'}
                        onClick={terminateExperiment}
                    >
                        {I18n.t('strategyMap.experiment.terminateExperiment')}
                    </ButtonPrimary>
                )
            } else if (status === NODE_STATES.E.finished && props.isChampion) {
                button = (
                    <>
                        <ButtonPrimary
                            className="success"
                            onClick={props.markSuccessfulCallback}
                            data-testid={'experiment-success-button'}
                        >
                            {I18n.t('strategyMap.experiment.successful')}
                        </ButtonPrimary>
                        <ButtonPrimary
                            className="fail"
                            data-testid={'experiment-fail-button'}
                            onClick={props.markFailedCallback}
                        >
                            {I18n.t('strategyMap.experiment.failed')}
                        </ButtonPrimary>
                    </>
                )
            }

            return (
                <div>
                    <ExpInfo className="row" data-testid={'started-experiment'}>
                        <div className="col col-xs-6 left">{labelStatus}</div>
                        <div className="col col-xs-6 right">
                            <TimeElapsedDisplay
                                duration={props.duration}
                                startDate={props.startDate}
                                status={props.status}
                            />
                        </div>
                    </ExpInfo>
                    <Charts Type={ChartType.ExperimentActivity} data={activityData} />
                    {button}
                </div>
            )
        }
    }

    if (props.isUserAbleToEditNode || props.status === NODE_STATES.E.started) {
        return (
            <ExperimentSummary data-testid={'experiment-summary'}>
                {renderContent()}
            </ExperimentSummary>
        )
    }

    return ''
}

ExperimentActivity.propTypes = {
    deadlineSet: PropTypes.bool,
    isChampion: PropTypes.bool,
    status: PropTypes.string,
    terminateExperimentCallback: PropTypes.func,
    startExperimentCallback: PropTypes.func,
    markFailedCallback: PropTypes.func,
    markSuccessfulCallback: PropTypes.func,
    activityData: PropTypes.array,
    duration: PropTypes.number,
    currentData: PropTypes.number,
}

ExperimentActivity.defaultProps = {}

export default ExperimentActivity
