import React, { useEffect, useState } from 'react';
import { useStrategyMapSlice } from '../../strategyMap/hooks/useStrategyMapSlice';
import { Panel } from '../../core/components/BootstrapWrapper';
import A42Tabs from '../../core/components/Tabs';
import { I18n } from 'react-i18nify';
import { NODE, getTree } from '../../strategyMap/components/Node';
import { NODE_TYPES, NODE_TYPES_TO_EXTENDED_NAMES, TIME_AREAS } from '../../strategyMap/const';
import styled from 'styled-components';
import Moment from 'react-moment';
import { Div } from '../../core/ui/Div';
import Api from '../../strategyMap/comment.api';
import { useCoreSlice } from '../../core/hooks/useCoreSlice';
import { SelectItemWidgetModal } from './CustomizeChartsDashboard';
import { SIDEBARS } from '../../core/redux/slices';
import _ from 'lodash';
import { NodeLink, goToNode } from './DashboardStrategyMap';

const CompletionIcon = styled.span`
    vertical-align: bottom;
    font-size: 20px !important;
`

const Comment = ({node}) => {
    const [comments, setComments] = useState([]);

    useEffect(() => {      
        if (comments.length === 0)  
            Api.fetchComments(node.id)
                .then( (response) => setComments( response.data));
    }, []);
    
    return <>
        <h4>{`#${node.id} ${node.title}`}</h4>
        {comments.length === 0 && <p>{I18n.t('dashboard.noCommentsInThisNode')}</p>}
        {comments.map((comment) => (<Div key={comment.id}>
            <b>{comment.user.full_name}</b> 
            <Div fontSize={'16px'} dangerouslySetInnerHTML={{__html: comment.text}} />
            <Moment locale={I18n._localeKey} fromNow>{ comment.timestamp }</Moment>
            <hr />
        </Div>))}
    </>
}

const PSFWidget = ({widget, handleDeleteWidget}) => {
    
    const {nodeMap, Actions} = useStrategyMapSlice();
    const {sidebarOpen} = useCoreSlice();
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;
    const [node, setNode] = useState(null);
    const [clickedWidget, setClickedWidget] = useState(false);
    const nodeId = widget.filter.psf;
    
    useEffect(() => {
        if (!nodeMap[nodeId]) {
            Actions.fetchNodes();
        }
    }, []);

    useEffect(() => {
        if (nodeMap[nodeId]) {
            setNode(nodeMap[nodeId])
        }
    }, [nodeMap]);

    if (!node)
        return null;

    const progressIndicator = (timeArea) => {
        switch (timeArea) {
            case TIME_AREAS.CONFIRMED:
                return <CompletionIcon className='material-symbols-outlined'>stroke_full</CompletionIcon>;
            case TIME_AREAS.POTENTIAL:
                return <CompletionIcon className='material-icons-outlined'>circle</CompletionIcon>;
            case TIME_AREAS.VALIDATING:
                return <CompletionIcon className='material-symbols-outlined'>stroke_partial</CompletionIcon>;
        }
       
        return null;
    }

    const ncChildren = getTree(node.id, nodeMap, NODE.children)
                        .filter( n => nodeMap[n] && nodeMap[n].type === NODE_TYPES.NC)
    const children = ncChildren.map( n => nodeMap[n]);
    const progress = children.map( nc => <p key={nc.id}>{progressIndicator(nc.time_area)} {NODE_TYPES_TO_EXTENDED_NAMES[nc.node_type]} #{nc.id} {nc.title}</p>);
    const nodeIdsForComments = [...ncChildren, node.id];
    const nodeTitle = `${NODE_TYPES_TO_EXTENDED_NAMES[node.type]} #${node.id} ${node.title}`;
    const panelHeader = <NodeLink onClick={(e) => goToNode(node.id, node.goal)}>{nodeTitle}</NodeLink>

    const mainPanelActions = [];

    if(isEditMode) {
        mainPanelActions.push(
            {
                icon: 'delete',
                onClick: () => handleDeleteWidget(widget)
            }
        );
        mainPanelActions.push(
            {
                icon: 'settings',
                onClick: () => setClickedWidget(widget)
            }
        );
    }

    return <Panel header={panelHeader} actions={mainPanelActions}>
            <SelectItemWidgetModal
                clickedWidget={clickedWidget}
                closeModal={() => setClickedWidget(false)}
                selectedItemId={nodeId}
            />
            <div dangerouslySetInnerHTML={{__html: node.description}}></div>
            <br />
            <A42Tabs 
                tabs={[
                    {
                        key: 'tab-progress',
                        title: I18n.t('strategyMap.nodes.Progress'),
                        content: <div>
                                    {progress}
                                    {progress.length === 0 && <p>{I18n.t('dashboard.noNCsForNode')} {nodeTitle}</p>}
                                </div>
                    },
                    {
                        key: 'tab-comments',
                        title: I18n.t('strategyMap.nodes.comments'),
                        content: <div>{nodeIdsForComments.map((nodeId) => <Comment key={nodeId} node={nodeMap[nodeId]} />)}</div>
                    }
                ]}
            />
            </Panel>
}

const PSFOverview = () => {
    const {dashboardWidgetsVisible, Actions: CoreActions} = useCoreSlice();
    const [widgets, setWidgets] = useState([]);
    const widgetName = "PSF overview";

    useEffect(() => {
        const PSFWidgets = dashboardWidgetsVisible ? 
                                        dashboardWidgetsVisible.filter((w) => w.name === widgetName) :
                                        [];
        setWidgets(PSFWidgets);
    }, [dashboardWidgetsVisible])

    const handleDeleteWidget = (dashboardWidget) => {
        CoreActions.updateDashboard(
            dashboardWidgetsVisible
                .filter((w) => ! _.isEqual(w, dashboardWidget) )
        );
    };

    return <>
        {widgets.map((w, idx) => <PSFWidget key={`${w.filter.psf}-${idx}`} widget={w} handleDeleteWidget={handleDeleteWidget} />)}
    </>
}
export default PSFOverview;