import React, {useState} from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {SideBarContainerWrapper, Title} from './Node.styles'
import Checkbox from '../../capture/signifier/Checkbox'
import {IconButton} from '../../core/components/Buttons'
import TargetModal from '../../sidebars/Components/TargetModal'

const Targets = (props) => {
    const {Actions} = useStrategyMapSlice()
    const [open, setOpen] = useState(false)
    const [selectedTarget, setSelectedTarget] = useState(null)
    const targets = props.node.targets || []

    const closeMdal = () => {
        setOpen(false)
        setSelectedTarget(null)
    }

    return (
        <div>
            {selectedTarget && (
                <TargetModal
                    isOpen={open}
                    onClose={closeMdal}
                    sf={props.node}
                    target={selectedTarget}
                />
            )}
            {!selectedTarget && open && (
                <TargetModal isOpen={open} onClose={closeMdal} sf={props.node} />
            )}
            {targets.map((target) => (
                <SideBarContainerWrapper key={target.id} clickable>
                    <Checkbox
                        onClick={() => {
                            Actions.updateTarget({...target, is_done: !target.is_done})
                        }}
                        disabled={!props.isAbleToEdit}
                        checked={target.is_done}
                    />
                    <Title
                        data-testid={'add-target-button'}
                        style={{marginLeft: 10}}
                        onClick={() => {
                            if(props.isAbleToEdit) {
                                setSelectedTarget(target)
                                setOpen(!open)
                            }
                        }}
                    >
                        {target.title}
                    </Title>
                    {(props.isAbleToEdit) && <IconButton
                        displayBlock={false}
                        onClick={() => Actions.deleteTarget(target)}
                        icon="clear"
                    />}
                </SideBarContainerWrapper>
            ))}
            {props.isAbleToEdit && (
                <SideBarContainerWrapper clickable>
                    <Title
                        onClick={() => {
                            setSelectedTarget(null)
                            setOpen(!open)
                        }}
                        data-testid={'add-target-button'}
                    >
                        {'+ Target'}
                    </Title>
                </SideBarContainerWrapper>
            )}
        </div>
    )
}

export default Targets
