import React, {useEffect, useState} from 'react'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {ButtonSecondary} from './Buttons'
import {I18n} from 'react-i18nify'
import {NODE_TYPES, NODE_TYPES_TO_EXTENDED_NAMES} from '../../strategyMap/const'
import HeadingWithActionAndDropdown from '../../sidebars/Components/HeadingWithActionAndDropdown'
import {SidebarHeader, SideBarParagraph, SideBarTitle, SideBar, SidebarContent} from '../ui/Sidebar.styles'
import {displayToastMessage, showPopupMessage} from '../utils/notifications'
import {SIDEBARS} from "../redux/slices";
import {useCoreSlice} from "../hooks/useCoreSlice";
import styled from "styled-components";
import SelectWrapper from './SelectWrapper'
import Miro from '../../integrations/components/MiroIntegration/Miro'
import {FullScreenLoading} from "./Loading";
import { Div } from '../ui/Div'

export const SideBarContent = styled.div`
    padding: 0 15px;
    height: 100%;
    overflow-y: auto;
`
const DemoTool = () => {
    const {Actions, goal, nodeMap, goalNodes} = useStrategyMapSlice();
    const {Actions: CoreActions, sidebarOpen} = useCoreSlice();
    const [isGeneratingGoal, setIsGeneratingGoal] = useState(false);
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [openMiroImport, setOpenMiroImport] = useState( false );

    const isSidebarOpen = sidebarOpen === SIDEBARS.Admin

    useEffect(() => {
        if (isGeneratingGoal) {
            setIsGeneratingGoal(false)
            Actions.getGoal({id: goalNodes[goalNodes.length - 1].id})
        }
    }, [goalNodes])

    const buildSM = (nodeId) => {
        if (nodeMap[nodeId] !== undefined) {
            const data = {
                title: nodeMap[nodeId].title,
                description: nodeMap[nodeId].description,
                time_area: nodeMap[nodeId].time_area,
                type: NODE_TYPES_TO_EXTENDED_NAMES[nodeMap[nodeId].type].toUpperCase(),
            }
            if ([NODE_TYPES.CSF, NODE_TYPES.PSF].includes(nodeMap[nodeId].type)) {
                data.sf_type = nodeMap[nodeId].type
            }

            if (nodeMap[nodeId].hasOwnProperty('children') && nodeMap[nodeId].children.length > 0) {
                data.children = nodeMap[nodeId].children.map((n) => buildSM(n))
            }

            return data
        }
    }

    const toolsOptions = () => [
        {
            title: I18n.t('sidebar.demoTools.decisions'),
            actions: [
                {
                    confirmMessage: I18n.t('sidebar.demoTools.miro.aboutToStartProcessContinue'),
                    action: () => {
                        setOpenMiroImport(true);
                        window.open(new Miro("").getAuthenticationCodeURL(), "_self");
                    },
                    btnLabel: I18n.t('sidebar.demoTools.miro.importFromMiro')
                },
                {
                    confirmMessage: I18n.t('sidebar.demoTools.generateDecisionsConfirmMessage'),
                    description: I18n.t('sidebar.demoTools.generateDecisionsDescription'),
                    action: () => Actions.generateDecisions("capture"),
                    btnLabel: I18n.t('sidebar.demoTools.generateDecisions')
                },
                {
                    confirmMessage: I18n.t('sidebar.demoTools.generateDecisionsConfirmMessage'),
                    description: I18n.t('sidebar.demoTools.generateDataForAssessmentDescription'),
                    action: () => Actions.generateDecisions("assessment"),
                    btnLabel: I18n.t('sidebar.demoTools.generateDataForAssessment')
                },
                {
                    confirmMessage: I18n.t('sidebar.demoTools.generateDecisionsConfirmMessage'),
                    description: "Generate decisions for marketing",
                    action: () => Actions.generateDecisions("marketing"),
                    btnLabel: "Decisions for marketing"
                }
            ]
        }, 
        {
            title: I18n.t('strategyMap.strategyMap'),
            actions: [
                {
                    description: I18n.t('sidebar.demoTools.clearStrategyMapDescription'),
                    confirmMessage: I18n.t('sidebar.demoTools.clearStrategyMapConfirmMessage'),
                    action: () => {
                        Actions.clearNodes({goal: goal.id})
                    },
                    btnLabel: I18n.t('sidebar.demoTools.clearStrategyMap')
                }, {
                    description: I18n.t('sidebar.demoTools.generateStrategyMapDescription'),
                    confirmMessage: I18n.t('sidebar.demoTools.generateStrategyMapConfirmMessage'),
                    action: () =>{
                        setIsGeneratingGoal(true)
                        Actions.generateSMNodes()
                    },
                    btnLabel: I18n.t('sidebar.demoTools.generateStrategyMap')
                }, {
                    description: I18n.t('sidebar.demoTools.importStrategyMapDescription'),
                    confirmMessage: I18n.t('sidebar.demoTools.importStrategyMapConfirmMessage'),
                    action: () => {
                        const onChangeInput = (event) => {
                            setIsGeneratingGoal(true)
                            const data = new FormData()
                            data.append('file', event.target.files[0])
                            Actions.importSMNodes(data)
                            displayToastMessage('success', I18n.t('sidebar.demoTools.strategyMapImportedSuccessfully'))
                            event.target.value = ''
                        }
                        const inputElem = document.createElement('input'); 
                        inputElem.setAttribute("type", "file");
                        inputElem.setAttribute("accept", "application/JSON,.oasm");
                        inputElem.addEventListener("change", onChangeInput)
                        inputElem.click()
                    },
                    btnLabel: I18n.t('sidebar.demoTools.importStrategyMap')
                }, {
                    confirmMessage: I18n.t('sidebar.demoTools.exportStrategyMapConfirmMessage'),
                    action: () => {
                        Actions.exportSM({goal: goal.id})
                    },
                    btnLabel: I18n.t('sidebar.demoTools.exportStrategyMap')
                }, {
                    confirmMessage: I18n.t('sidebar.demoTools.exportStrategyMapConfirmMessage'),
                    action: () => {
                        Actions.exportSM()
                    },
                    btnLabel: I18n.t('sidebar.demoTools.exportAllStrategyMap')
                }
            ]
        }
    ]

    const handleClick = (confirmMessage, action) => {
        if (confirmMessage && action) {
            showPopupMessage({
                title: I18n.t('strategyMap.messages.warning'),
                message: confirmMessage,
                cancelLabel: I18n.t('strategyMap.messages.cancel'),
                confirmLabel: I18n.t('strategyMap.messages.yes'),
                onConfirmCallback: () => {
                    CoreActions.closeSidebar()
                    action()
                },
                onCancelCallback: () => {},
            })
        }
    }
    
    const commands = [
        {value: 'email_orgscan_less_active_departments', label: "Email orgscan boost departments participation"}
    ]

    return (
        <>
            { openMiroImport && <FullScreenLoading loading={true}/> }
            
            {isSidebarOpen && (
                <SideBar
                    id="admin-sidebar"
                    className="show-sidebar"
                    data-testid={'admin-sidebar'}
                >
                    <SidebarHeader tabs={1}>
                        <HeadingWithActionAndDropdown
                            className="padding-bottom"
                            title={I18n.t('sidebar.demoTools.AdminTools')}
                            icon="clear"
                            showAction={true}
                        />
                    </SidebarHeader>
                    <SidebarContent>
                        <Div padding="0 15px">
                            {
                                toolsOptions()
                                    .map( (tool, index) => (
                                        <div key={index}>
                                            <SideBarTitle>{tool.title}</SideBarTitle>
                                            {
                                                tool.actions.map((ac, i) =>
                                                    <div key={i}>
                                                        {ac.description && (
                                                            <SideBarParagraph>
                                                                {ac.description}
                                                            </SideBarParagraph>
                                                        )}
                                                        <ButtonSecondary
                                                            fullWidth
                                                            key={index}
                                                            onClick={() =>
                                                                handleClick(
                                                                    ac.confirmMessage,
                                                                    ac.action,
                                                                )
                                                            }
                                                        >
                                                            {ac.btnLabel}
                                                        </ButtonSecondary>
                                                    </div>
                                            )
                                        }
                                    </div>
                            ))}
                            <div key="run-command" style={{marginBottom: 100}}>
                                <SideBarTitle>Run command</SideBarTitle>
                                <SelectWrapper
                                    options={commands}
                                    onChange={(command) => setSelectedCommand(command)}
                                />
                                <ButtonSecondary
                                    fullWidth
                                    disabled={selectedCommand === null}
                                    onClick={() => {
                                        CoreActions.runCommand(selectedCommand.value)
                                        setSelectedCommand(null)
                                    }}
                                >
                                    Run
                                </ButtonSecondary>
                            </div>
                        </Div>
                    </SidebarContent>
                </SideBar>
            )}
        </>
    )
}

export default DemoTool
