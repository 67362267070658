import React from 'react'
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Text} from 'recharts'
import {BLUE, MAGENTA, WHITE} from '../../core/ui/_colors'
import {I18n} from "react-i18nify";

const YAxisLabel = () =>
    <>
        <Text x={0} y={0} dx={50} dy={20} offset={0} angle={-90} textAnchor={'end'} >
            {I18n.t("leadership.Autonomy")}</Text>
        <Text x={0} y={0} dx={50} dy={360} offset={0} angle={-90}>
            {I18n.t("leadership.Control")}
        </Text>
    </>

const CustomizedDot = (props) => {
    const { cx, cy } = props;
    if (!cx || !cy)
        return null

    return (
        <circle 
            cx={cx - 10}
            cy={cy - 10}
            r={4}
            stroke={props.payload.moodColour}
            style={{opacity: "0.9"}}
            strokeWidth={2}
            fill={props.payload.moodColour} 
        />
    )
}

export const AutonomyVsControl = (props) => {

    return (
        <div data-testid={props.testId}>
            <AreaChart
                width={500}
                height={400}
                data={props.data.map( a => ({...a, zero: 0}))}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="background" x1="0" y1="0" x2="0" y2="1">
                      <stop offset={"0%"} stopColor={BLUE} stopOpacity={.3}/>
                      <stop offset={"20%"} stopColor={WHITE} stopOpacity={.7}/>
                      <stop offset={"80%"} stopColor={WHITE} stopOpacity={.7}/>
                      <stop offset={"100%"} stopColor={MAGENTA} stopOpacity={.2}/>
                    </linearGradient>
                    <linearGradient id="line" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={"white"} stopOpacity={.7}/>
                      <stop offset="99%" stopColor={"black"} stopOpacity={.5}/>
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" fill="url(#background)"/>
                <XAxis dataKey="name"/>
                <YAxis domain={[-50,50]} label={<YAxisLabel/> } tick={false} />
                <Area type="monotone" dataKey="autonomyVsControl" stroke={"black"} strokeWidth={1} fill={"transparent"}/>
                <Area type="monotone" dataKey="selectedDatapoint" dot={<CustomizedDot />} stroke="none" fill={"transparent"} />
                <Area type="monotone" dataKey="zero" stroke={"gray"} strokeWidth={1} fill={"transparent"}/>
            </AreaChart>
        </div>
    )
}