import {css} from 'styled-components'

const borderBottom = (bb) =>
    css`
        border-bottom: ${bb};
    `
const color = (c) =>
    css`
        color: ${c};
    `
const cursor = (c) =>
    css`
        cursor: ${c};
    `
const display = (d) =>
    css`
        display: ${d};
    `
const flexWrap = (fw) =>
    css`
        flex-wrap: ${fw};
    `
const float = (f) =>
    css`
        float: ${f};
    `
const fontSize = (fs) =>
    css`
        font-size: ${fs};
    `
const height = (h) =>
    css`
        height: ${h};
    `
const lineHeight = (lh) =>
    css`
        line-height: ${lh};
    `
const margin = (m) =>
    css`
        margin: ${m};
    `
const marginLeft = (ml) =>
    css`
        margin-left: ${ml};
    `
const marginTop = (mt) =>
    css`
        margin-top: ${mt};
    `
const marginBottom = (mb) =>
    css`
        margin-bottom: ${mb};
    `
const opacity = (o) =>
    css`
        opacity: ${o};
    `
const padding = (p) =>
    css`
        padding: ${p};
    `
const paddingLeft = (pl) =>
    css`
        padding-left: ${pl};
    `
const paddingRight = (pl) =>
    css`
        padding-right: ${pl};
    `
const paddingTop = (pt) =>
    css`
        padding-top: ${pt};
    `
const paddingBottom = (pt) =>
    css`
        padding-bottom: ${pt};
    `
const transform = (t) =>
    css`
        transform: ${t};
    `
const textDecoration = (td) =>
    css`
        text-decoration: ${td};
    `
const width = (w) =>
    css`
        width: ${w};
    `
const minWidth = (w) =>
    css`
        min-width: ${w};
    `
const textAlign = (ta) =>
    css`
        text-align: ${ta};
    `
const verticalAlign = (va) =>
    css`
        vertical-align: ${va};
    `
const position = (p) =>
    css`
        position: ${p};
    `
const top = (t) =>
    css`
        top: ${t};
    `
const bottom = (b) =>
    css`
        bottom: ${b};
    `
const left = (l) =>
    css`
        left: ${l};
    `
const right = (r) =>
    css`
        right: ${r};
    `
const overflow = (r) =>
    css`
        overflow: ${r};
    `

export const BaseMixin = css`
    ${(props) => props.borderBottom && borderBottom(props.borderBottom)}
    ${(props) => props.color && color(props.color)}
    ${(props) => props.cursor && cursor(props.cursor)}
    ${(props) => props.display && display(props.display)}
    ${(props) => props.flexWrap && flexWrap(props.flexWrap)}
    ${(props) => props.float && float(props.float)}
    ${(props) => props.fontSize && fontSize(props.fontSize)}
    ${(props) => props.height && height(props.height)}
    ${(props) => props.lineHeight && lineHeight(props.lineHeight)}
    ${(props) => props.margin && margin(props.margin)}
    ${(props) => props.marginLeft && marginLeft(props.marginLeft)}
    ${(props) => props.marginTop && marginTop(props.marginTop)}
    ${(props) => props.marginBottom && marginBottom(props.marginBottom)}
    ${(props) => props.opacity && opacity(props.opacity)}
    ${(props) => props.padding && padding(props.padding)}
    ${(props) => props.paddingLeft && paddingLeft(props.paddingLeft)}
    ${(props) => props.paddingRight && paddingRight(props.paddingRight)}
    ${(props) => props.paddingTop && paddingTop(props.paddingTop)}
    ${(props) => props.paddingBottom && paddingBottom(props.paddingBottom)}
    ${(props) => props.transform && transform(props.transform)}
    ${(props) => props.textDecoration && textDecoration(props.textDecoration)}
    ${(props) => props.width && width(props.width)}
    ${(props) => props.minWidth && minWidth(props.minWidth)}
    ${(props) => props.textAlign && textAlign(props.textAlign)}
    ${(props) => props.verticalAlign && verticalAlign(props.verticalAlign)}
    ${(props) => props.position && position(props.position)}
    ${(props) => props.top && top(props.top)}
    ${(props) => props.bottom && bottom(props.bottom)}
    ${(props) => props.left && left(props.left)}
    ${(props) => props.right && right(props.right)}
    ${(props) => props.overflow && overflow(props.overflow)}
`
