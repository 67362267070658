import PropTypes from 'prop-types'
import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {I18n} from 'react-i18nify'
import {DURATION_OPTIONS, getExperimentCompareLabels} from '../Dashboard'
import OutsideClickHandler from 'react-outside-click-handler'
import {Div} from '../../../core/ui/Div'
import {BLACK_GREY, DARK_GREY, LIGHT_GREY, WHITE} from '../../../core/ui/_colors'
import {Flex} from '../../../core/ui/Flex'
import {assoc} from 'ramda'

const CompareExperiments = styled(Div)`
    position: relative;
    float: right;
    width: 300px;
    @media only screen and (max-width: 992px) {
        height: 42px;
        width: 272px;
        float: left;
        margin-top: 5px;
    }
`

const CompareButton = styled(Div)`
    float: left;
    width: 42px;
    height: 42px;
    background-color: ${WHITE};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover {
        cursor: pointer;
    }
`
const CompareDurationButton = styled(Flex)`
    align-items: center;
    width: 230px;
    height: 42px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    background-color: ${WHITE};
    @media only screen and (max-width: 992px) {
        position: relative;
        float: left;
    }
`
const Label = styled(Div)`
    padding: 12px;
    color: ${BLACK_GREY};
    font-size: 16px;
    font-weight: normal;
    text-align: left;
`

const CompareDropdown = styled(Div)`
    position: absolute;
    top: -15px;
    left: 42px;
    width: 230px;
    padding: 16px 0px;
    background-color: ${WHITE};
    font-size: 16px;
    color: ${DARK_GREY};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.32);
    z-index: 999;
`

const Period = styled(Div)`
    padding-left: 20px;
    line-height: 36px;
    cursor: pointer;

    ${(props) =>
        props.selected &&
        css`
            background-color: ${LIGHT_GREY};
        `}
    &:hover {
        background-color: ${LIGHT_GREY};
    }
`

const Compare = styled(Div)`
    float: right;
    height: 42px;
    width: 300px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${WHITE};
    @media only screen and (max-width: 992px) {
        width: auto;
        float: left;
        margin-top: 5px;
    }
`
const DurationTime = styled(Div)`
    @media only screen and (max-width: 992px) {
        display: inline-block;
        float: left;
        height: 100%;
        cursor: pointer;
    }
`
const CompareImage = styled(Div)`
    float: left;
    margin-left: 5px;
    margin-top: 6px;
    width: 30px;
`

const SelectedDurationLabel = styled(Div)`
    float: right;
    padding: 10px;
    font-size: 14px;
    @media only screen and (min-width: 375px) {
        padding: 10px 5px;
    }
`

const CompareFilterComponent = (props) => {
    const [showCompareDropdown, setShowCompareDropdown] = useState(false)

    const changeDurationOption = (days) => {
        setShowCompareDropdown(false)
        props.handleFiltersChange(assoc('duration', days, props.appliedFilters))
    }

    const {isCompared, appliedFilters} = props

    const icon = isCompared ? "visibility_off" : "visibility"

    if( appliedFilters.experiment && appliedFilters.experiment.length > 0 ) {
        return (
            <CompareExperiments>
                <CompareButton onClick={props.clickCompareCallback}>
                    <span
                        className="material-icons-outlined"
                        style={{
                            width: '30px',
                            margin: '6px 0 0 6px'
                        }}
                    >{icon}</span>
                </CompareButton>
                <CompareDurationButton onClick={() => setShowCompareDropdown(!showCompareDropdown)}>
                    <Label className={'col col-xs-9 col-md-10 label'}>
                        {I18n.t(getExperimentCompareLabels(appliedFilters.duration))}
                    </Label>
                    <i className="material-icons" aria-hidden="true">
                        keyboard_arrow_right
                    </i>
                </CompareDurationButton>
                {showCompareDropdown && (
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setShowCompareDropdown(false)
                        }}
                    >
                        <CompareDropdown>
                            {Object.values(DURATION_OPTIONS)
                                .filter((value) => value !== DURATION_OPTIONS.ALL)
                                .map((duration, i) => {
                                    return (
                                        <Period
                                            key={i}
                                            selected={appliedFilters.duration === duration}
                                            className={
                                                appliedFilters.duration === duration
                                                    ? 'selected'
                                                    : ''
                                            }
                                            onClick={() => {
                                                changeDurationOption(duration)
                                            }}
                                        >
                                            {I18n.t(getExperimentCompareLabels(duration))}
                                        </Period>
                                    )
                                })}
                        </CompareDropdown>
                    </OutsideClickHandler>
                )}
            </CompareExperiments>
        )
    } else {
        const textKey = isCompared
            ? 'switchOffComparison'
            : window.innerWidth < 370
            ? 'previousPeriod'
            : 'compareWithPreviousPeriod'
        const compareLabel = I18n.t('dashboard.' + textKey)
        const hideCompare = appliedFilters.duration === DURATION_OPTIONS.ALL

        if (hideCompare && isCompared) {
            props.clickCompareCallback()
        }

        return (
            !hideCompare && (
                <Compare className={isCompared}>
                    <DurationTime
                        float={'left'}
                        cursor={'pointer'}
                        onClick={props.clickCompareCallback}
                    >
                        <CompareImage>
                            <span className="material-icons-outlined">{icon}</span>
                        </CompareImage>
                        <SelectedDurationLabel>{compareLabel}</SelectedDurationLabel>
                    </DurationTime>
                </Compare>
            )
        )
    }
}

CompareFilterComponent.propTypes = {
    appliedFilters: PropTypes.object,
    isCompared: PropTypes.bool,
    handleFiltersChange: PropTypes.func,
    clickCompareCallback: PropTypes.func,
}

CompareFilterComponent.defaultProps = {}

export default CompareFilterComponent
