import React, {useEffect, useState} from 'react';
import {DashboardTiles} from './Dashboard.styles';
import {DASHBOARD_CHARTS} from '../../Charts/ChartsOrgScan';
import DashboardChart from './DashboardChart';
import {useDemographicsSlice} from "../../core/hooks/useDemographicsSlice";
import {DashboardCard, DashboardCardConfig, TargetDiv} from "./CustomizeChartsDashboard";
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import {SIDEBARS} from "../../core/redux/slices";
import {DashboardLocalStorage, DashboardWidgets} from "./CustomizeChartsDashboardConst";
import ReactCardFlip from "react-card-flip";
import { ChartType, SubTypes as SubTypesCharts } from '../../Charts/ChartsConfig';
import {subtypes} from './DashboardLeaderShipSurvey'
import { useCaptureSlice } from '../../capture/hooks/useCaptureSlice';
import {DragDropImage, DragStartReplaceImage} from "./DragDrop";
import { useHistory } from '../../core/hooks/useHistory';
import _ from 'lodash';

const DashboardChartSection = (props) => {
    const {dashboardWidgetsVisible, Actions, sidebarOpen, teams} = useCoreSlice();
    const {leaderShipAssessmentSurvey, Actions: CaptureActions} = useCaptureSlice();
    const demographics = useDemographicsSlice();
    const [zoom, setZoom] = useState(null);
    const [flipped, setFlipped] = useState(null);
    const [dashboardWidgets, setDashboardWidgets] = useState([]);

    const {isOrgscanDashboardPage, isMainDashboardPage} = useHistory();
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;

    const setWidgetsForCurrentDashboard = () => {
        if(isOrgscanDashboardPage) {
            setDashboardWidgets(DashboardWidgets.OrgScan.filter((w) => w.isOrgScanLite === props.isDashboardLite));
        } else if(dashboardWidgetsVisible && isMainDashboardPage) {
            const sectionWidgets = props.section ? DashboardWidgets[props.section] : [];
            
            const filteredDashboardWidgets = dashboardWidgetsVisible
                                                .filter((dashboardWidget, key) => 
                                                    sectionWidgets.map(sw => sw.name).includes(dashboardWidget.name)  && (!Number.isInteger(zoom) || zoom === key)
                                                );
            
            setDashboardWidgets(filteredDashboardWidgets);
        }
    }

    useEffect(() => {
        setWidgetsForCurrentDashboard();

        CaptureActions.getCaptures();
    }, []);

    useEffect(() => {
        setWidgetsForCurrentDashboard();
    }, [dashboardWidgetsVisible]);

    const activeDepartments =
        demographics && demographics.department
        && demographics.department
            .filter(department => !department.archived)
            .map(department => department.pk);

    const dataObject =
        props.dataObject && activeDepartments
            ? Object.keys(props.dataObject)
                .reduce((acc, cur) => {
                    acc[cur] = props.dataObject[cur].filter(decision => activeDepartments.includes(decision?.demographics?.department));
                    return acc;
                }, {})
            : props.dataObject;

    const LEADERSHIP_ASSESSMENT_DASHBOARD_CHARTS = Object.values(SubTypesCharts.triangle).map( triangle => ({
        title: subtypes()[triangle].title,
        Type: ChartType.Triangle,
        subType: triangle,
        subTypes: subtypes()[triangle]
    }) )

    LEADERSHIP_ASSESSMENT_DASHBOARD_CHARTS.push({
        title: "leadership.Panel.Topic.Autonomy vs Control",
        Type: ChartType.AreaChart
    })

    const getFilterName = (dashboardWidget) => {
        if (dashboardWidget.filter.department)
            return demographics.department?.filter(d => d.pk === dashboardWidget.filter.department)[0]?.name;
        else if (dashboardWidget.filter.team)
            return teams?.filter(t => t.id === dashboardWidget.filter.team)[0]?.name;

        return "";
    }

    return (
        <>
            <DragDropImage />
            <DashboardTiles className="row" isEditing={isEditMode}>
                {
                    dashboardWidgets
                        .map((dashboardWidget, key) => {
                            const widget = [...DASHBOARD_CHARTS, ...LEADERSHIP_ASSESSMENT_DASHBOARD_CHARTS].find(widget => widget?.title === dashboardWidget.name);
                            if (!widget)
                                return null;

                            const chartData = {
                                title: widget.title,
                                tooltip: widget.tooltip,
                                colorSchema: widget.colorSchema,
                                Type: widget.Type,
                                testId: widget.testId
                            }

                            if (widget.subTypes)
                                chartData.subTypes = widget.subTypes;
                            else
                                chartData.subType = widget.subType;

                            const dataAFilteredByWidgets = {dataShown: [], dataCompare: []};

                            if(dashboardWidget.filter.hasOwnProperty('team')) {
                                chartData.subType = widget.subType;
                                const formatDate = (date) => {
                                    const d = new Date(date)
                                    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
                                }
                                const getMedianValue = (key) => {
                                    const assessments = leaderShipAssessmentSurvey
                                        .filter( value => value.parent_capture === key || value.key === key );

                                    const summation = assessments.reduce( (total, curr) => total + curr.data.autonomy_vs_control, 0 );
                                    return summation / assessments.length;
                                }
                                dataAFilteredByWidgets.dataShown =
                                    leaderShipAssessmentSurvey
                                        .filter( data =>
                                            Object.keys( dashboardWidget.filter )
                                                .filter( section => [0, 'all', null, data.selected_team].includes( dashboardWidget.filter[section] ) )
                                                .length
                                        )
                                        .map(data => {
                                            if(dashboardWidget.name === "leadership.Panel.Topic.Autonomy vs Control") {
                                                return ({
                                                    name: formatDate(data.created),
                                                    autonomyVsControl: getMedianValue(data.key) - 50
                                                })
                                            }
                                            return data.data
                                        });
                            } else {
                                dataAFilteredByWidgets.dataShown =
                                    dataObject.dataShown
                                        .filter( data =>
                                            Object.keys( dashboardWidget.filter )
                                                .filter( section => [0, 'all', null, data.demographics[section]].includes( dashboardWidget.filter[section] ) )
                                                .length
                                        );
                            }

                            return <TargetDiv 
                                        className={'col col-sm-12 col-md-6 col-lg-4'}
                                        meZooming={ Number.isInteger(zoom) }
                                        key={key}
                                        index={key}
                                        onDragStart={(e) => {
                                            localStorage.setItem(DashboardLocalStorage.position.start, key );
                                            localStorage.setItem(DashboardLocalStorage.key, dashboardWidget.name);
                                            localStorage.setItem(DashboardLocalStorage.section, props.section);
                                            DragStartReplaceImage(e, dashboardWidget.name);
                                        }}
                                        onDragEnter={(event) => {
                                            event.stopPropagation();
                                            localStorage.setItem(DashboardLocalStorage.position.ends, key);
                                        }}
                                        onDragEnd={() => Actions.updateDashboard()}
                                        isEditMode={isEditMode}
                                    >
                                    <ReactCardFlip
                                        isFlipped={flipped === key || Number.isInteger(flipped) && Number.isInteger(zoom) }
                                        flipSpeedBackToFront={2}
                                        flipSpeedFrontToBack={2}
                                    >
                                        <FlippedCard zooming={Number.isInteger(zoom)} meZooming={zoom===key} isVisible={flipped !== key}>
                                            <DashboardChart
                                                noConfig={isOrgscanDashboardPage}
                                                chartData={ chartData }
                                                key={key}
                                                keyIndex={key}
                                                optinalText={ getFilterName(dashboardWidget) }
                                                isDashboardLite={props.isDashboardLite}
                                                isCompared={props.isCompared}
                                                dataObject={ dataAFilteredByWidgets }
                                                columnSize={3}
                                                appliedFilters={props.appliedFilters}
                                                flip={() => setFlipped(key)}
                                                delete={() => {
                                                    Actions.updateDashboard(
                                                        dashboardWidgetsVisible
                                                            .filter((w) => ! _.isEqual(w, dashboardWidget) )
                                                    );
                                                    setWidgetsForCurrentDashboard();
                                                }}
                                                zoom={ Number.isInteger(zoom) }
                                                clickZoom={() => setZoom( Number.isInteger(zoom) ? null : key )}
                                            />
                                        </FlippedCard>
                                        {!isOrgscanDashboardPage &&
                                        <FlippedCard zooming={Number.isInteger(zoom)} meZooming={zoom===key} isVisible={flipped === key}>
                                                <DashboardCard
                                                    flip={ () => setFlipped(null) }
                                                    columnSize={3}
                                                    zoom={ Number.isInteger(zoom) }
                                                    zooming={ () => setZoom( Number.isInteger(zoom) ? null : key ) }
                                                    title={dashboardWidget.name}
                                                >
                                                    <DashboardCardConfig orderID={key} />
                                                </DashboardCard>
                                            </FlippedCard>}
                                    </ReactCardFlip>
                            </TargetDiv>
                        }
                    )
                }
            </DashboardTiles>
        </>
    )
}

const FlippedCard = props =>
    (props.isVisible && props.meZooming || !props.zooming) ? props.children : <></>;

export default React.memo(DashboardChartSection)
