import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import {isMobile} from 'react-device-detect'
import {useWindowSize} from '../../../core/hooks/useWindowSize'
import styled from 'styled-components'
import leaderTypeIcon from "../../../img/Capture/ic-leader-type@unknown.png"
import orientationIcon from "../../../img/Capture/ic-orientation@unknown.png"
import effectivenessIcon from "../../../img/Capture/ic-effectiveness@unknown.png"
import valueDriversIcon from "../../../img/Capture/ic-value-drivers@unknown.png"

const icons = {
    'leader-type': leaderTypeIcon,
    'orientation': orientationIcon,
    'effectiveness': effectivenessIcon,
    'value-drivers': valueDriversIcon
}

let diagramFixedPosition
let pointerW
let imgDimensions
let leftPosition
let xBias
let yBias

const DimensionImgDraggable = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    margin: auto;
    background-size: 40px 40px;
    z-index: 599;
    &:hover {
        cursor: pointer;
    }
    &.react-draggable-dragging {
        opacity: 1 !important;
    }
    &.react-draggable-dragged {
        &.true {
            opacity: 0.3;
        }
    }

    @media (min-width: 992px) {
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
    }
`

const DimensionImgPlaceholder = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5 !important;
    @media (min-width: 992px) {
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
    }
`

const Dimension = (props) => {
    const windowSize = useWindowSize()

    const [draggableInitialPosition, setDraggableInitialPosition] = useState(0)
    const [dragged, setDragged] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [panning, setPanning] = useState(false)
    const [position, setPosition] = useState({x: 0, y: 0})

    const initialLeftRatio = 6

    useEffect(() => {
        if (props.shouldResetPosition && !dragging) {
            resetDraggablePosition()
            setDragged(false)
        }
    }, [props])

    useEffect(() => {
        initialCalculations()
    }, [])

    const getDiagramDimensions = () => {
        if (document.getElementById('diagram')) {
            const diagram = document.getElementById('diagram')
            const pointer = document.getElementById(props.keyVal)
            imgDimensions = {
                height: diagram.clientHeight,
                width: diagram.clientWidth,
            }
            pointerW = pointer.clientWidth
            leftPosition = (imgDimensions.width * initialLeftRatio) / 100
            if (windowSize.width < 576) {
                diagramFixedPosition = imgDimensions.height * 0.2
                setDraggableInitialPosition((imgDimensions.width * props.bounds.left) / 100)
            } else {
                diagramFixedPosition = imgDimensions.width * 0.2
                setDraggableInitialPosition((imgDimensions.height * props.bounds.top) / 100)
            }
        }
    }

    const initialCalculations = () => {
        getDiagramDimensions()
        if (windowSize.width < 576) {
            xBias = 1
            yBias = 5
        } else if (575 < windowSize.width && windowSize.width < 768) {
            xBias = 1
            yBias = 5
        } else if (767 < windowSize.width && windowSize.width < 992) {
            xBias = 1
            yBias = 3
        } else if (991 < windowSize.width && windowSize.width < 1200) {
            xBias = 0
            yBias = 2
        } else {
            xBias = 1
            yBias = 2
        }
    }

    const round = (value) => {
        return Math.round(value * 100) / 100
    }

    const handlePreventTouchmoveWhenPanning = (event) => {
        if (panning) {
            event.preventDefault()
        }
    }

    const dragStartActions = () => {
        setPanning(true)
        if (isMobile) {
            handlePreventTouchmoveWhenPanning()
        }
        props.dragStartCallback(props.keyVal)
    }

    const resetDraggablePosition = () => {
        setPosition({x: 0, y: 0})
        setDragged(false)
    }

    const resetToInitial = () => {
        setPosition({x: 0, y: 0})
        setDragged(false)
    }

    const setPercentages = (xPercentage, yPercentage, name) => {
        props.onPositionChange(round(xPercentage), round(1 - yPercentage), name)
    }

    const onDragStop = (name) => {
        setPanning(false)
        let yPercentage
        let xPercentage
        if (windowSize.width < 576) {
            if (position.y < diagramFixedPosition) {
                resetToInitial()
                props.onPositionChange(0, 0, name)
            } else {
                const {x, y} = position
                yPercentage =
                    (y - diagramFixedPosition) / ((imgDimensions.height * 4) / 5 - pointerW + yBias)
                xPercentage =
                    Math.abs(x + draggableInitialPosition) /
                    (imgDimensions.width - pointerW + xBias) // 1px -> bias

                setDragged(true)
                setPercentages(xPercentage, yPercentage, name)
            }
        } else {
            if (position.x < diagramFixedPosition - leftPosition) {
                resetToInitial()
                props.onPositionChange(0, 0, name)
            } else {
                const {x, y} = position
                xPercentage =
                    (x - diagramFixedPosition + leftPosition) /
                    ((imgDimensions.width * 4) / 5 - pointerW + xBias) // 1px bias
                yPercentage =
                    Math.abs(y + draggableInitialPosition) /
                    (imgDimensions.height - pointerW + yBias)

                setDragged(true)
                setPercentages(xPercentage, yPercentage, name)
            }
        }
        setDragging(false)
    }

    const handleDrag = (e, ui) => {
        const {x, y} = position
        if (!dragging) setDragging(true)
        setPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY,
        })
    }

    let style
    if (windowSize.width > 575) {
        style = {
            top: props.bounds.top + '%',
            left: initialLeftRatio + '%',
            backgroundImage:
                'url("' +
                icons[props.keyVal] +
                '")',
        }
    } else {
        style = {
            top: 0,
            left: props.bounds.left + '%',
            backgroundImage:
                'url("' +
                icons[props.keyVal] +
                '")',
        }
    }

    return (
        <>
            <DimensionImgPlaceholder id={props.keyVal} style={style}></DimensionImgPlaceholder>
            <Draggable
                position={position}
                onDrag={handleDrag}
                bounds="parent"
                onStart={dragStartActions}
                onStop={() => onDragStop(props.keyVal)}
            >
                <DimensionImgDraggable
                    data-testid="Stone-draggable"
                    id={props.keyVal}
                    className={dragged}
                    style={{...style, zIndex: 50}}
                ></DimensionImgDraggable>
            </Draggable>
        </>
    )
}

Dimension.propTypes = {
    keyVal: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    bounds: PropTypes.object,
    onPositionChange: PropTypes.func,
    dragStartCallback: PropTypes.func,
    shouldResetPosition: PropTypes.bool,
}

export default Dimension
