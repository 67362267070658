import React, {useEffect, useState} from 'react'
import Raven from 'raven-js'

import HeadingWithActionDropdownMenu, {
    ContextMenuEvents
} from '../../../sidebars/Components/HeadingWithActionDropdownMenu'
import HeadingWithAction from '../../../sidebars/Components/HeadingWithAction'
import {NODE_TYPES, NODE_TYPES_TO_EXTENDED_NAMES} from '../../const'
import {I18n} from 'react-i18nify'
import ClickToEditInput from '../../../core/components/ClickToEditInput'
import ModalShareItems from '../Modal/ShareItems'
import ActivityContainer from './ActivityContainer'
import SF from '../../../sidebars/SF'
import Goal from '../../../sidebars/Goal'
import Experiment from '../../../sidebars/Experiment/Experiment'
import NC from '../../../sidebars/NC'
import CommentsContainer from '../CommentsContainer'
import {isMobile} from 'react-device-detect'
import {showPopupMessage} from '../../../core/utils/notifications'
import NodeLinks from '../../../sidebars/Components/NodeLinks';
import {
    getDescriptionPlaceholder,
    getNodeDisplay,
    getNodeLineage,
    getPendingNode,
    isCSF as isCSFNode,
    isExperiment as isExperimentNode,
    isNC as isNCNode,
    isPSF as isPSFNode
} from '../../utils'
import {useStrategyMapSlice} from '../../hooks/useStrategyMapSlice'
import YQuill from '../../../core/components/YQuill'
import {getTenantName} from '../../../core/utils/network'
import PrintPreDialog from '../PrintPreDialog'
import NodeIntegrations from '../../../sidebars/Components/NodeIntegrations'
import { TIME_AREAS } from '../../const'
import InfoModal from '../Modal/InfoModal'
import ConnectToTool from '../../../integrations/components/ConnectToTool'
import {useNode} from '../../hooks/useNode'
import ConvertNode from '../../../sidebars/Components/ConvertNode'
import {ButtonPrimary} from '../../../core/components/Buttons'
import {SidebarFooter, SidebarHeader, SidebarTabs} from '../../../core/ui/Sidebar.styles'
import {useConcurrentUsers} from "../../hooks/useConcurrentUsers"
import ConcurrentUsers from "../ConcurrentUsers"
import {map, prepend, prop, takeWhile} from "ramda"
import {useCoreSlice} from "../../../core/hooks/useCoreSlice";
import A42Tabs from "../../../core/components/Tabs";
import styled from "styled-components";
import {ModalAddGlobalSharedNode} from "../../../sidebars/Components/ModalAddGlobalSharedNode";
import OtherGoalsLinks from '../../../sidebars/Components/OtherGoalsLinks'

const CreateNodeContainer = styled.div`
    padding: 0px 14px 20px;
    display: flex;
    height: 100%;
    overflow-y: auto;
    flex-shrink: 1;
    flex-direction: column;
`
const MODAL_STATE = {
    ShareItem: 'ShareItem', 
    ShareItemFromOtherGoals: 'ShareItemFromOtherGoals', 
    ConvertNode: 'ConvertNode', 
    Info: 'Info', 
    Print: 'Print', 
    IntegrationTrello: 'Trello', 
    IntegrationSlack: 'Slack'
};

const NodeInput = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [resendSave, setResendSave] = useState(false)
    const {Actions: CoreActions} = useCoreSlice();
    const concurrentUsers= useConcurrentUsers();
    const {Actions, activities, nodeActivity, nodeMap, users} = useStrategyMapSlice();
    const {
        node,
        champions,
        isReadOnly,
        isEditable,
        isEditMode,
        isUserInExperimentTeam,
        isUserSquadMember,
        isUserChampion,
        isGoal,
        isSF,
        isPSF,
        isCSF,
        isExperiment,
        isNC,
    } = useNode();

    const closeSidebar = () => {
        CoreActions.closeSidebar()
        Actions.onHideSidebar()
    }

    const titlePlaceholder =
        node && isExperiment
            ? I18n.t('strategyMap.experiment.expTitlePlaceholder')
            : I18n.t('strategyMap.nodes.title')

    useEffect(() => {
        Actions.fetchActiveUsers()
        if( isEditMode && node ) {
            Actions.fetchActivities(node.id)
        }
    }, [])

    useEffect( () => {}, [node]);

    const onSidebarEvent = (event, data) => {

        const hasChildren = !!node.children.length;

        switch(event){
            case ContextMenuEvents.onNodeShareOtherGoal:
                setShowDialog( MODAL_STATE.ShareItemFromOtherGoals );
                break;

            case ContextMenuEvents.onNodeUpdate:
                Actions.updateNode( data );
                setShowDialog(null);
                break;

            case ContextMenuEvents.onNodeDelete:
                const warningNodeDelete = {
                    title: I18n.t('strategyMap.messages.warning'),
                    message: hasChildren
                        ? I18n.t('strategyMap.messages.nodeWithChildrenDeleteWarning')
                        : I18n.t('strategyMap.messages.nodeDeleteWarning'),
                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                    confirmLabel: I18n.t('strategyMap.messages.delete'),
                    onConfirmCallback: () => Actions.deleteNode(node),
                    onCancelCallback: () => {}
                }
                showPopupMessage(warningNodeDelete);
                break;

            case ContextMenuEvents.onNodeUnarchive:
                handleNodeUnArchive( data );
                break;

            case ContextMenuEvents.onDeleteAllChildNodes:
                const warningAllNodeDeletion = {
                    title: I18n.t('strategyMap.messages.warning'),
                    message: I18n.t('strategyMap.messages.nodeChildrenDeleteWarning'),
                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                    confirmLabel: I18n.t('strategyMap.messages.delete'),
                    onConfirmCallback: () => Actions.deleteAllChildNodes(node),
                    onCancelCallback: () => {},
                };
                showPopupMessage(warningAllNodeDeletion);
                break;

            case ContextMenuEvents.onNodeArchive:
                const warningNodeArchive = {
                    title: I18n.t('strategyMap.messages.warning'),
                    message: hasChildren
                        ? I18n.t('strategyMap.messages.nodeWithChildrenArchiveWarning')
                        : I18n.t('strategyMap.messages.nodeArchiveWarning'),
                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                    confirmLabel: I18n.t('strategyMap.nodes.archive'),
                    onConfirmCallback: () => Actions.archiveNode(node.id),
                    onCancelCallback: () => {}
                };
                showPopupMessage(warningNodeArchive);
                break;

            case ContextMenuEvents.onArchiveAllChildNodes:
                const warningArchiveAllChildNodes = {
                    title: I18n.t('strategyMap.messages.warning'),
                    message: I18n.t('strategyMap.messages.nodeChildrenArchiveWarning'),
                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                    confirmLabel: I18n.t('strategyMap.nodes.archive'),
                    onConfirmCallback: () => Actions.archiveChildNodes(node.id),
                    onCancelCallback: () => {}
                };
                showPopupMessage(warningArchiveAllChildNodes);
                break;

            case ContextMenuEvents.onPrint:
                setShowDialog( MODAL_STATE.Print);
                break;

            case ContextMenuEvents.onConnectToTrello:
                setShowDialog( MODAL_STATE.IntegrationTrello );
                break;

            case ContextMenuEvents.onConnectToSlack:
                setShowDialog( MODAL_STATE.IntegrationTrello );
                break;

            case ContextMenuEvents.onShowModalSharedItem:
                setShowDialog( MODAL_STATE.ShareItem );
                break;

            case ContextMenuEvents.onShowModalConvertNode:
                setShowDialog( MODAL_STATE.ConvertNode );
                break;
            
            case ContextMenuEvents.onShowNodeCreationSidebar:
                Actions.showNodeCreationSidebar( data )

        }
    }

    const handleNodeUnArchive = (nodeID) => {
        const node = nodeMap[nodeID]
        const ancestorNodeIDs = getNodeLineage(node, nodeMap)
        const ancestorNodes = map(n => nodeMap[n], ancestorNodeIDs)
        const archivedAncestorNodes = takeWhile(n => n.is_archived === true, ancestorNodes)
        const archivedAncestorNodeIDs = map(prop('id'), archivedAncestorNodes)
        const archivedNodeIDs = prepend(nodeID, archivedAncestorNodeIDs)

        const confirmDialogue = {
            title: I18n.t('strategyMap.messages.warning'),
            message: archivedNodeIDs.length > 1
                ? I18n.t('strategyMap.messages.unarchiveNodeWithParentsConfirm')
                : I18n.t('strategyMap.messages.unarchiveNodeConfirm'),
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.nodes.unarchive'),
            onConfirmCallback: () =>
                Actions.unarchiveNodes(archivedNodeIDs),
            onCancelCallback: () => {
            },
        }
        showPopupMessage(confirmDialogue)
    }

    const handleAddNode = () => Actions.addNode({...node});

    const handleConnectToTool = () => {
        setShowDialog(null);
        if (resendSave) {
            handleAddNode();
            setResendSave(false);
        }
    }

    const renderEditFooter = () => {
        if (isMobile || (isNC && node.fulfilled))
            return <div/>;

        const nodesArray = [];
        const nodeBaseObject = {
            type: String,
            isDisabled: Boolean,
            message: String
        };
        nodeBaseObject.isDisabled = false;

        switch( true ){
            case isGoal:
                nodesArray.push({...nodeBaseObject, type: 'CSF'});
                nodesArray.push({...nodeBaseObject, type: 'PSF'});
                break;

            case isNC:
            case isExperiment:
            case isCSF:
                const ncNode = {...nodeBaseObject, type: 'NC'};
                if (
                    (isExperiment &&
                        node.is_finished &&
                        !isUserChampion &&
                        !isUserInExperimentTeam &&
                        !isUserSquadMember) ||
                    (isNC && node.fulfilled)
                ) {
                    ncNode.isDisabled = true;
                    ncNode.message = I18n.t('strategyMap.messages.theExperimentHasEndedMessage');
                }
                nodesArray.push(ncNode);
                break;

            case isPSF:
                nodesArray.push({...nodeBaseObject, type: 'NC'});
                const experimentNode = {...nodeBaseObject, type: 'EXPERIMENT'};
                if( ! isUserChampion ){
                    experimentNode.isDisabled = true;
                    experimentNode.message = I18n.t('strategyMap.messages.doNotHaveHavePermissionsExperimentMsg');
                }
                nodesArray.push(experimentNode);
        }

        return (
            <SidebarFooter items={nodesArray.length}>
                {renderAddNodeButton(nodesArray)}
            </SidebarFooter>
        )
    }

    const renderAddNodeButton = (nodesArray) => {
        return nodesArray.map((nodeItem, i) => {
            const disabledClass = nodeItem.isDisabled ? ' disabled' : ''

            return (
                <ButtonPrimary
                    key={i}
                    className={'add' + NODE_TYPES[nodeItem.type] + disabledClass}
                    onClick={() => {
                        if (nodeItem.isDisabled) {
                            const warning = {
                                message: nodeItem.message,
                                confirmLabel: I18n.t('strategyMap.messages.ok'),
                            }
                            showPopupMessage(warning)
                        } else {
                            Actions.showNodeCreationSidebar(
                                getPendingNode(
                                    NODE_TYPES[nodeItem.type],
                                    getDescriptionPlaceholder(NODE_TYPES[nodeItem.type]),
                                ),
                            )
                        }
                    }}
                >
                    {I18n.t('strategyMap.nodes.add' + nodeItem.type)}
                </ButtonPrimary>
            )
        })
    }

    const renderNodeTitle = () => {
        return (
            <ClickToEditInput
                node={node}
                onTextChange={(value) => {
                    if (isEditMode) {
                        Actions.updateNode({
                            id: node.id,
                            title: value,
                            resourcetype: node.resourcetype,
                        })
                    } else {
                        Actions.updatePendingNode({title: value})
                    }
                }}
                placeholderText={titlePlaceholder}
                isReadOnly={isReadOnly}
                nodeActivity={nodeActivity[node.id]}
                value={node.title}
            />
        )
    }

    const renderNodeDescription = () =>
        <div>
            <HeadingWithAction title={I18n.t('strategyMap.nodes.description')}/>

            <YQuill
                key={getNodeDisplay(node)}
                nodeId={node.id}
                text={node.description}
                isReadOnly={isReadOnly}
                placeholder={I18n.t('strategyMap.nodes.descriptionPlaceholder')}
                handleTextChange={(text) => {
                    if (isEditMode) {
                        Actions.updateNode({
                            id: node.id,
                            resourcetype: node.resourcetype,
                            description: text,
                        })
                    } else {
                        Actions.updatePendingNode({
                            description: text,
                        })
                    }
                }}
                roomName={getTenantName() + 'description' + node.id}
            />
        </div>;

    const renderCommentContainer = () => {
        return (
            <div>
                <HeadingWithAction title={I18n.t('strategyMap.nodes.comments')}/>
                <CommentsContainer/>
            </div>
        )
    }

    const renderNodeContent = () => {
        if (!node) return null
        else if (isSF) {
            return <SF key={node.id} node={node} isEditMode={isEditMode} isReadOnly={isReadOnly}/>
        } else if (isGoal) {
            return (
                <Goal
                    key={node.id}
                    node={node}
                    isEditMode={isEditMode}
                    isAbleToEdit={isEditable}
                    isReadOnly={isReadOnly}
                />
            )
        } else if (isExperiment) {
            return (
                <Experiment
                    key={node.id}
                    node={node}
                    champions={champions}
                    isEditMode={isEditMode}
                />
            )
        } else if (isNC) {
            return <NC key={node.id} node={node} isEditMode={isEditMode} isReadOnly={isReadOnly}/>
        } else {
            return false
        }
    }


    const getTooltipDescriptionAndTitle = () => {

        switch( true ){
            case isGoal:
                return {
                    title: NODE_TYPES_TO_EXTENDED_NAMES[node.type],
                    description: I18n.t('strategyMap.nodeHelpMessages.goal') +"aaaa"
                };
            case isCSF:
                return {
                    title: NODE_TYPES_TO_EXTENDED_NAMES[node.type],
                    description: I18n.t('strategyMap.nodeHelpMessages.csf')
                };
            case isExperiment:
                return {
                    title: NODE_TYPES_TO_EXTENDED_NAMES[node.type],
                    description: I18n.t('strategyMap.nodeHelpMessages.experiment'),
                };
            case isPSF:
                return {
                    title: NODE_TYPES_TO_EXTENDED_NAMES[node.type],
                    description: I18n.t('strategyMap.nodeHelpMessages.psf'),
                };
            case isNC:
                const descriptionNC = node.parents.map((parentId) => {
                    const parent = nodeMap[parentId];

                    if (!parent) {
                        return [null, null]
                    } else if (isPSFNode(parent)) {
                        return [I18n.t('strategyMap.nodeHelpMessages.ncOfPsfTitle'), I18n.t('strategyMap.nodeHelpMessages.ncOfPsf')]
                    } else if (isExperimentNode(parent)) {
                        return [I18n.t('strategyMap.nodeHelpMessages.ncOfExperimentTitle'), I18n.t('strategyMap.nodeHelpMessages.ncOfExperiment')]
                    } else if (isNCNode(parent)) {
                        return [I18n.t('strategyMap.nodeHelpMessages.ncOfNcTitle'), I18n.t('strategyMap.nodeHelpMessages.ncOfNc')]
                    } else if (isCSFNode(parent) && parent.time_area === TIME_AREAS.VALIDATING) {
                        return [I18n.t('strategyMap.nodeHelpMessages.ncOfCsfInValidationTitle'), I18n.t('strategyMap.nodeHelpMessages.ncOfCsfInValidation')]
                    } else if (isCSFNode(parent) && parent.time_area === TIME_AREAS.CONFIRMED) {
                        return [I18n.t('strategyMap.nodeHelpMessages.ncOfCsfInConfirmedTitle'), I18n.t('strategyMap.nodeHelpMessages.ncOfCsfInConfirmed')]
                    }
                })

                return {
                    title: NODE_TYPES_TO_EXTENDED_NAMES[node.type],
                    description: descriptionNC
                }
        }

        return false
    }

    const helpPopupData = getTooltipDescriptionAndTitle();

    if( ! node )
        return <div />;

    const connectToolTitle = I18n.t('strategyMap.nodes.connectNodenameToTool')
                                .replace(':nodename:', getNodeDisplay(node) + ' ' + node.title)
                                .replace(':tool:', showDialog);

    return (
        <>
            <ConnectToTool tool={ showDialog } title={connectToolTitle} onClose={handleConnectToTool}/>
            <ModalShareItems
                isOpen={ showDialog === MODAL_STATE.ShareItem }
                onRequestClose={() => setShowDialog(null)}
                users={users}
                raven={Raven}
                source={{
                    nodeId: node && node.id,
                    nodeType: node && node.type,
                }}
            />
            <ModalAddGlobalSharedNode
                isOpen={ showDialog === MODAL_STATE.ShareItemFromOtherGoals }
                handleClose={() => setShowDialog(null)}
                nodeID={node.id}
                nodeMap={nodeMap}
            />
            {node?.id && (
                <ConvertNode
                    isOpen={ showDialog === MODAL_STATE.ConvertNode }
                    onRequestClose={() => setShowDialog(null)}
                    nodeId={node.id}
                    nodeMap={nodeMap}
                />
            )}
            <InfoModal
                isOpen={ showDialog === MODAL_STATE.Info }
                onRequestClose={() => setShowDialog(null)}
                title={helpPopupData && helpPopupData.title}
                description={helpPopupData && helpPopupData.description}
                isNC={isNC}
            />
            <PrintPreDialog
                isOpen={ showDialog === MODAL_STATE.Print }
                onClose={() => setShowDialog(null) }
            />

            <SidebarTabs style={{height: 'calc(100% - 40px)'}} >
                <SidebarHeader tabs={2}>
                    <HeadingWithActionDropdownMenu
                        node={node}
                        testId={'node-actions-menu'}
                        showHelpButton={helpPopupData}
                        onClickHelp={() => setShowDialog( MODAL_STATE.Info )}
                        nodeActivity={nodeActivity}

                        onEvent={onSidebarEvent}

                        showAction={isEditMode}
                        icon={isEditMode ? 'clear' : ''}
                        onSidebarClose={closeSidebar}
                        {...Actions}
                    />

                    <ConcurrentUsers users={concurrentUsers}/>
                    {node && renderNodeTitle()}
                </SidebarHeader>
                {
                    ! isEditMode ? (
                        <CreateNodeContainer>
                            {(!node || !isExperiment) && renderNodeDescription()}
                            {!!node && renderNodeContent()}
                        </CreateNodeContainer>
                    ) :
                        <A42Tabs animation className="tabContent" style={{paddingLeft: 15, paddingRight: 15}}
                            tabs={[
                                {
                                    key: "details",
                                    title: I18n.t('strategyMap.nodes.details'),
                                    content: <div>
                                        {(!node || !isExperiment) && renderNodeDescription()}
                                        {!!node && renderNodeContent()}
                                        {!!node && isEditMode && <NodeLinks sameMap={true} onNodeUnarchive={handleNodeUnArchive}/>}
                                        {!!node && isEditMode && ! isGoal && <OtherGoalsLinks onEvent={onSidebarEvent} />}
                                        {!!node && <NodeIntegrations/>}
                                        {renderCommentContainer()}
                                    </div>
                                },
                                {
                                    key: "activity",
                                    title: I18n.t('strategyMap.nodes.activity'),
                                    content: <ActivityContainer versions={activities}/>
                                }
                            ]}
                        />
                }
                {!isReadOnly && !!node && renderEditFooter()}
                {!isEditMode && (
                    <SidebarFooter items={2}>
                        <ButtonPrimary className="cancel" onClick={closeSidebar}>
                            {I18n.t('strategyMap.messages.cancel')}
                        </ButtonPrimary>
                        <ButtonPrimary
                            className="save"
                            id="saveButton"
                            data-testid="node-save-button"
                            onClick={handleAddNode}
                        >
                            {I18n.t('strategyMap.messages.save')}
                        </ButtonPrimary>
                    </SidebarFooter>
                )}
            </SidebarTabs>
        </>
    )
}

export default NodeInput
