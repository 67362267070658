import React, {useEffect, useReducer} from 'react'
import Checkbox from '../../../capture/signifier/Checkbox'
import {I18n} from 'react-i18nify'
import {BarLoader} from 'react-spinners'
import styled from 'styled-components'
import {renderUserItemInline} from '../../utils'
import {
    Description,
    Modal,
    SearchContainer,
    SearchIcon,
    SearchInput,
    SelectFields,
    UserItem,
    Users,
} from '../../../core/ui/modal/Modal'
import {useStrategyMapSlice} from '../../hooks/useStrategyMapSlice'

export const LoaderContainer = styled.div`
    position: relative;
    margin: 0 auto;
    top: 50%;
    width: 100px;
`

const SearchBox = (props) => {
    return (
        <SearchContainer>
            <SearchIcon />
            <SearchInput
                placeholder={I18n.t('dashboard.search')}
                onChange={(value) => props.onSetFilter(value.target.value)}
            />
        </SearchContainer>
    )
}

const UserItem_ = (props) => {
    return (
        <UserItem>
            <Checkbox onClick={() => props.onChooseUser(props.user.pk)} checked={props.checked} />
            {renderUserItemInline(true)(props.user, true)}
        </UserItem>
    )
}

const initialState = {loading: true, filteredUsers: [], selectedUsers: []}

function reducer(state, [type, payload]) {
    switch (type) {
        case 'reset':
            return {
                filteredUsers: payload,
                selectedUsers: [],
                loading: true,
            }
        case 'setLoading':
            return {
                ...state,
                loading: payload,
            }
        case 'setFilteredUsers':
            return {
                ...state,
                filteredUsers: payload,
            }
        case 'setSelectedUsers':
            return {
                ...state,
                selectedUsers: payload,
            }
        default:
            return state
    }
}

const ModalShareItems = (props) => {
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        filteredUsers: props.users,
    })
    const {sendSharedLink} = useStrategyMapSlice().Actions
    const {filteredUsers, selectedUsers, loading} = state

    useEffect(() => {
        dispatch(['setFilteredUsers', props.users])
        if (props.users.length > 0) {
            dispatch(['setLoading', false])
        }
    }, [props.users])

    const chooseUser = (userPK) => {
        if (selectedUsers.indexOf(userPK) >= 0) {
            selectedUsers.splice(selectedUsers.indexOf(userPK), 1)
        } else {
            selectedUsers.push(userPK)
        }

        dispatch(['setSelectedUsers', selectedUsers])
    }

    const handleSetFilter = (searchString) => {
        searchString = searchString.toLowerCase()
        const filteredUsersList = props.users.filter(
            (user) =>
                user.email.toLowerCase().includes(searchString) ||
                user.first_name.toLowerCase().includes(searchString) ||
                user.last_name.toLowerCase().includes(searchString) ||
                user.initials.toLowerCase().includes(searchString),
        )

        dispatch(['setFilteredUsers', filteredUsersList])
    }

    const handleClose = () => {
        if (selectedUsers.length > 0) {
            sendEmail()
        }

        props.onRequestClose()
        dispatch(['reset', props.users])
    }

    const sendEmail = () => {
        const postContent = props.source
        postContent['users'] = selectedUsers
        sendSharedLink(postContent)
        dispatch(['reset', props.users])
    }

    return (
        <Modal 
            isOpen={props.isOpen}
            title={I18n.t('sidebar.sharing.dialog.title')}
            footer={{
                confirmText: I18n.t('sidebar.sharing.dialog.buttons.send'),
                onConfirm: handleClose,
                onCancel: handleClose
            }}
        >
            <Description>{I18n.t('sidebar.sharing.dialog.description')}</Description>
            <SelectFields>
                <SearchBox onSetFilter={handleSetFilter} />
                <Users>
                    {filteredUsers.map((user) => (
                        <UserItem_
                            key={user.pk}
                            user={user}
                            checked={selectedUsers.indexOf(user.pk) >= 0}
                            onChooseUser={chooseUser}
                        />
                    ))}

                    {loading && (
                        <LoaderContainer>
                            <BarLoader />
                        </LoaderContainer>
                    )}
                </Users>
            </SelectFields>
        </Modal>
    )
}

export default ModalShareItems
