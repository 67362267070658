import React, {useState} from 'react'
import Charts from '../../Charts/Charts'
import {ChartType} from '../../Charts/ChartsConfig'
import {DASHBOARD_CHARTS} from '../../Charts/ChartsOrgScan'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {I18n} from 'react-i18nify'
import { selectBoxStyling } from '../Analysis'
import {InspectChartStyle, InspectGraphSelectBoxWrapper} from '../reports/Reports.styles'
import {InspectGraphChartWrapper, InspectGraphFrame} from './Inspect.styles'
import GenerateHighResImageInspectModal from '../reports/GenerateHighResImageInspectModal'
import {
    decisionsFiltered
} from '../../dashboard/components/DecisionFilter/Dashboard.functions'
import SelectWrapper from '../../core/components/SelectWrapper'

const InspectGraph = () => {
    const charts = DASHBOARD_CHARTS.filter(
        (charts) =>
            charts.Type === ChartType.HeatMapReactangle || charts.Type === ChartType.Triangle,
    )

    const {filters, Actions, decisionsSearch} = useDashboardSlice()

    const [currentDiagram, setCurrentDiagram] = useState({
        value: charts[0].title,
        label: I18n.t(charts[0].title)
    });

    const dataPoints = {
        dataShown: decisionsFiltered(decisionsSearch, filters),
        dataCompare: [],
    }

    const resetMarkPoints = () => Actions.setSelectedPoints({})

    const chart_options = charts.map((chart) => ({
        label: I18n.t(chart.title),
        value: chart.title,
    }))
    const showSelectBox = () => (
        <InspectGraphSelectBoxWrapper>
            <SelectWrapper
                styles={selectBoxStyling}
                onChange={(e) => {
                    resetMarkPoints()
                    setCurrentDiagram(e)
                }}
                defaultValue={currentDiagram}
                options={chart_options}
            />
        </InspectGraphSelectBoxWrapper>
    )




    const showChart = () => {
        const inspectChart = charts.filter((chart) => chart.title === currentDiagram.value);

        return inspectChart.map((chart, key) => (
            <InspectChartStyle key={key}>
                <InspectGraphChartWrapper>
                    <Charts
                        {...chart}
                        testId={chart.testId + '_inspecting'}
                        dataObject={dataPoints}
                        isCompared={false}
                        language={I18n._localeKey}
                        inspectMode={true}
                        coordinate={{height: 500, width: 500}}
                    />
                </InspectGraphChartWrapper>
            </InspectChartStyle>
        ))
    }

    return (
        <InspectGraphFrame>
            {showSelectBox()}
            {showChart()}
            <GenerateHighResImageInspectModal />
        </InspectGraphFrame>
    )
}

export default React.memo(InspectGraph)
