import {
    FilterButtonContainer,
    FilterButtonContainerWrapper,
    FilterButtonIcon,
    FilterButtonLabel
} from "../Filters.styles";
import React from "react";
import {I18n} from "react-i18nify";
import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";

const FilterButtonClear = ({label, margin, handleClick, hideIcon, inverse, testid}) =>
        <FilterButtonContainerWrapper style={{margin: '5px 5px 5px 0'}} data-testid={testid}>
            <FilterButtonContainer
                inverse={inverse}
                margin={margin}
                onClick={() => hideIcon && handleClick && handleClick()}
            >
                <FilterButtonLabel inverse={inverse}>{ label }</FilterButtonLabel>
                {!hideIcon && (
                    <FilterButtonIcon data-testid={"remove-demographic-filter"} onClick={handleClick}>
                        <i className="material-icons pull-right" aria-hidden="true" role="button">
                            clear
                        </i>
                    </FilterButtonIcon>
                )}
            </FilterButtonContainer>
        </FilterButtonContainerWrapper>;

export const FilterButtonClearAll = () => {

    const {Actions} = useDashboardSlice();

    return <FilterButtonClear
        label={ I18n.t("dashboard.Filters.clearAll") }
        hideIcon
        inverse
        handleClick={() => Actions.clearFilters()}
    />
}

export default FilterButtonClear;