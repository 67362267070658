import {useEffect, useRef, useState} from 'react'
import {filter} from 'ramda'
import {useStrategyMapSlice} from "./useStrategyMapSlice"
import {useUserSlice} from "./useUserSlice"
import socketClass from "../../core/components/Socket"
import {usePrevious} from "../../core/hooks/usePrevious"
import {useBeforeUnload} from "../../core/hooks/useBeforeUnload"


export const useConcurrentUsers = (g) => {

    const [concurrentUsers, setConcurrentUsers] = useState([])
    const {currentOpenNodeId, users} = useStrategyMapSlice()
    const currentOpenNodeIdRef = useRef()
    const previousOpenNodeId = usePrevious(currentOpenNodeId)
    useBeforeUnload(() => removeConcurrentUser(currentOpenNodeIdRef.current))

    const [connected, setConnected] = useState(false)
    const user = useUserSlice()
    const socket = useRef(new socketClass('ws-users')).current

    const updateConcurrentUsers = (data) => {
        const usersAllNodes = data['concurrentUsers']
        const otherUsersThisNode = usersAllNodes
            .filter(u => u.openNodePk === currentOpenNodeIdRef.current)
            .filter(u => u.userPk !== user.pk)
            .map(u => u.userPk)
        setConcurrentUsers(filter(u => otherUsersThisNode.includes(u.pk), users))
    }

    useEffect(() => {
        if (previousOpenNodeId) {
            removeConcurrentUser(previousOpenNodeId)
        }
    }, [previousOpenNodeId])

    useEffect(() => {
        currentOpenNodeIdRef.current = currentOpenNodeId
        if (connected) {
            addConcurrentUser(currentOpenNodeId)
        }
    }, [currentOpenNodeId, connected])


    useEffect(() => {
        user.Actions.fetchUsers()
        socket.setOnMessage(updateConcurrentUsers)
        socket.setOnOpen(() => setConnected(true))
        return () => {
            removeConcurrentUser(currentOpenNodeIdRef.current)
        }
    }, [])

    const addConcurrentUser = (nodeId) => {
        socket.send({
            type: 'add_concurrent_user',
            userPk: user.pk,
            openNodePk: nodeId
        })
    }

    const removeConcurrentUser = (nodeId) => {
        socket.send({
            type: 'remove_concurrent_user',
            userPk: user.pk,
            openNodePk: nodeId
        })
    }

    return concurrentUsers
}
