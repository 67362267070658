import {all, call, put, takeEvery, takeLeading} from 'redux-saga/effects'
import Api from '../notification.api'
import {notificationSlice} from './notification.slices'
import {strategyMapSlice} from './slices'
import {consoleError} from '../../core/utils/error'

function* fetchNotifications() {
    try {
        const response = yield call(Api.fetchNotifications)
        yield put(notificationSlice.actions.fetchNotificationsSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(notificationSlice.actions.requestFailure(error))
    }
}

function* markNotificationAsDeleted(action) {
    const {notifications} = action.payload

    try {
        yield call(Api.markNotificationAsDeleted, notifications)
        yield put(notificationSlice.actions.markNotificationAsDeletedSuccess(action.payload))
    } catch (error) {
        consoleError(error)
        yield put(notificationSlice.actions.requestFailure(error))
    }
}

function* markNotificationAsRead(action) {
    const {notifications} = action.payload

    try {
        const response = yield call(Api.markNotificationAsRead, notifications)
        yield put(notificationSlice.actions.markNotificationAsReadSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(notificationSlice.actions.requestFailure(error))
    }
}

export default function* flow() {
    yield all([
        takeLeading(notificationSlice.actions.markNotificationAsRead, markNotificationAsRead),
        takeLeading(notificationSlice.actions.markNotificationAsDeleted, markNotificationAsDeleted),
        takeEvery(notificationSlice.actions.fetchNotifications, fetchNotifications),
    ])
}
