import {useEffect, useState} from 'react'
import {update} from 'ramda'

export const useCheckList = (items, setSelectedItems) => {
    const [checkedItems, setCheckedItems] = useState(items.map((_) => false))
    const [allChecked, setAllChecked] = useState(false)

    useEffect(() => {
        setCheckedItems(items.map((_) => false))
    }, [items])

    useEffect(() => {
        if (allChecked && checkedItems.some((value) => !value)) {
            setAllChecked(false)
        }

        setSelectedItems(items.filter((item, i) => !!checkedItems[i]))
    }, [checkedItems])

    const handleCheckboxClick = (i) => {
        const isChecked = checkedItems[i]
        setCheckedItems(update(i, !isChecked, checkedItems))
    }

    const handleAllCheckBoxClick = () => {
        const newAllChecked = !allChecked
        setCheckedItems(checkedItems.map((_) => newAllChecked))
        setAllChecked(newAllChecked)
    }

    return {
        checkedItems,
        allChecked,
        handleCheckboxClick,
        handleAllCheckBoxClick,
    }
}
