import styled from 'styled-components'
import {OVERLAY_TRANSPARENT_COLOUR} from '../../core/ui/_colors'
import Avatar from '../../core/components/Avatar'

const SIZE = 100

export const PhotoPicker = styled.div`
    display: none;
    text-align: center;
    background: ${OVERLAY_TRANSPARENT_COLOUR};
    line-height: 116px;
    cursor: pointer;
    text-transform: uppercase;
    width: ${SIZE}px;
    height: ${SIZE}px;
    border-radius: 50%;
    font-size: 12px;
    position: absolute;
    top: 0px;
    left: calc(50% - 2.5px);
    margin-left: -${SIZE/2}px;
`

export const AvatarContainer = styled.div`
    text-align: center;
    position: relative;
    &:hover ${PhotoPicker} {
        display: block;
    }
`

export const AvatarMain = styled(Avatar)`
    margin-bottom: 30px;
    width: ${SIZE}px;
    height: ${SIZE}px;
    font-size: 30px;
    line-height: ${SIZE}px;
    text-align: center;
`