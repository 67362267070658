import {all, call, put, takeLeading} from 'redux-saga/effects'
import {I18n} from 'react-i18nify'
import Api from '../api'
import {captureSlice} from './slices'
import {dashboardSlice} from '../../dashboard/redux/slices'
import {consoleError} from '../../core/utils/error'
import {displayToastMessage} from '../../core/utils/notifications'
import { PAGES } from '../../core/const'
import history from '../../core/utils/history'

function* fetchTriangleTypes() {
    try {
        const response = yield call(Api.fetchTriangleTypes)
        yield put(captureSlice.actions.fetchTriangleTypesSuccess(response))
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

function* getCaptureQuestions(action) {
    try {
        const response = yield call(Api.getCaptureQuestions, action.payload)
        yield put(captureSlice.actions.getCaptureQuestionsSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

function* postDecisions(action) {
    try {
        const response = yield call(Api.postDecisions, action.payload)
        yield put(captureSlice.actions.postDecisionsSuccess())
        yield put(dashboardSlice.actions.pushDecisionToList(response))
        displayToastMessage(
            'success',
            I18n.t('capture.Form.Status.Ok.Text'),
            I18n.t('capture.Form.Status.Ok.Head'),
        )
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

function* saveCapture(action) {
    try {
        const response = yield call(Api.saveCapture, action.payload)
        yield put(captureSlice.actions.saveCaptureSuccess())
        displayToastMessage(
            'success',
            I18n.t('leadership.Form.Status.Ok.Text'),
            I18n.t('leadership.Form.Status.Ok.Head'),
        )
        window.location.href = `/${PAGES.LeaderShip.Report.replace("${id}", response.data.key)}`;
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

function* getCapture(action) {
    try {
        const response = yield call(Api.getCapture, action.payload)
        yield put(captureSlice.actions.getCaptureSuccess(response.data))
    } catch (error) {
        if (error.response && error.response.status === 400) {
            history.push( '/' );
        }
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}
function* getCaptures(action) {
    try {
        const response = yield call(Api.getCaptures, action.payload)
        yield put(captureSlice.actions.getCapturesSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

function* getCaptureStats(action) {
    try {
        const response = yield call(Api.getCaptureStats, action.payload)
        yield put(captureSlice.actions.getCaptureStatsSuccess(response.data))
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

function* updateUserProfileDemographics(action) {
    try {
        yield call(Api.updateUserProfileDemographics, action.payload)
        yield put(captureSlice.actions.updateUserProfileDemographicsSuccess())
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
    }
}

export function* importDecisionsFromMiro(action) {
    try {
        yield call(Api.importDecisionsFromMiro, action.payload)
        displayToastMessage('success', I18n.t('sidebar.demoTools.miro.miroBoardImportedSuccessfully'))
    } catch (error) {
        consoleError(error)
        yield put(captureSlice.actions.requestFailure(error))
        displayToastMessage('error', I18n.t('sidebar.demoTools.miro.errorImportingMiroBoard'))
    }
}

export default function* flow() {
    yield all([
        takeLeading(captureSlice.actions.fetchTriangleTypes, fetchTriangleTypes),
        takeLeading(captureSlice.actions.getCaptureQuestions, getCaptureQuestions),
        takeLeading(captureSlice.actions.postDecisions, postDecisions),
        takeLeading(captureSlice.actions.saveCapture, saveCapture),
        takeLeading(captureSlice.actions.getCapture, getCapture),
        takeLeading(captureSlice.actions.getCaptures, getCaptures),
        takeLeading(captureSlice.actions.getCaptureStats, getCaptureStats),
        takeLeading(captureSlice.actions.importDecisionsFromMiro, importDecisionsFromMiro),
        takeLeading(
            captureSlice.actions.updateUserProfileDemographics,
            updateUserProfileDemographics,
        ),
    ])
}
