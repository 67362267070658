import headBallImageCapture from '../../img/icons/ic-capture-decision.png'
import headBallImageAnalyzingTool from '../../img/icons/ic-analyzing-tool.png'
import headBallImageReportingTool from '../../img/icons/ic-reporting-tool.png'
import React from 'react'
import {I18n} from 'react-i18nify'
import {getRemainingCaptureDays} from '../../strategyMap/utils'
import {PAGES, RESTRICTIONS} from '../../core/const'
import {useUserSlice} from '../../strategyMap/hooks/useUserSlice'
import {DashboardNavigator, DashboardNavItem, NavImg, NavText} from './Dashboard.styles'
import {showPopupMessage} from '../../core/utils/notifications'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import { useHistory } from '../../core/hooks/useHistory'

export const PermissionAppWarning = () => ({
    title: I18n.t('strategyMap.messages.warning'),
    message: I18n.t('dashboard.messages.warning.navigationDisabled'),
    confirmLabel: I18n.t('strategyMap.messages.ok'),
});

const HeaderElement = ({text, imageData, page, permissionToEnter, columnClass, onClick}) => {

    return (
        <DashboardNavItem
            data-testid={`go-to-${page}`}
            onClick={() => {
                if (permissionToEnter) {
                    onClick(page)
                } else {
                    showPopupMessage(PermissionAppWarning())
                }
            }}
            className={`${columnClass} ${!permissionToEnter ? 'disabled' : ''}`}
        >
            { imageData && <NavImg alt={text} src={imageData} /> }
            <NavText>{I18n.t('dashboard.ButtonLabels.' + text)}</NavText>
        </DashboardNavItem>
    )
}

export const Header = () => {
    const {isSubscriptionActive, features, client, Actions} = useCoreSlice();
    const user = useUserSlice();
    const days = getRemainingCaptureDays(client);

    const {isLeadershipDashboardPage} = useHistory();

    const capturePage = isLeadershipDashboardPage ? PAGES.LeaderShip.Capture : PAGES.OrgScan.Capture;
    const analysisPage = isLeadershipDashboardPage ? PAGES.LeaderShip.Analysis : PAGES.OrgScan.Analysis;

    const canAccessCapture = () => {
        if( isLeadershipDashboardPage )
            return (features["leadership.full"] || features["leadership.team"]) && isSubscriptionActive;
        
        return features.capture && days > 0 && isSubscriptionActive;
    }

    const canAccessAnalysis = () => {
        if( ! isSubscriptionActive || ! features.analyze )
            return false;
      
        const isOrgScanRuleCanAnalyseData = RESTRICTIONS.OrgScan.RULES.CAN_ANALYSE_DATA.some(role => user[role]);
        const canAccessLeadershipAnalysis = isLeadershipDashboardPage && (features["leadership.full"] || features["leadership.team"]) && isOrgScanRuleCanAnalyseData;
        const canAccessCaptureAnalysis = !isLeadershipDashboardPage && features.capture && isOrgScanRuleCanAnalyseData;

        return canAccessLeadershipAnalysis || canAccessCaptureAnalysis;
    };

    return (
        <DashboardNavigator className={'row'}>
            { isLeadershipDashboardPage &&
                <HeaderElement
                    text="Report"
                    imageData={headBallImageReportingTool}
                    page={PAGES.LeaderShip.Report.replace("${id}", 'All')}
                    columnClass={'col-xs-4'}
                    onClick={Actions.gotoPage}
                    permissionToEnter={isSubscriptionActive}
                />
            }
            <HeaderElement
                text="Capture"
                imageData={headBallImageCapture}
                page={capturePage}
                columnClass={`col-xs-${isLeadershipDashboardPage ? '4' : '6'}`}
                onClick={Actions.gotoPage}
                permissionToEnter={canAccessCapture()}
            />
            <HeaderElement
                text={I18n.t('analysis.title')}
                imageData={headBallImageAnalyzingTool}
                page={analysisPage}
                columnClass={`col-xs-${isLeadershipDashboardPage ? '4' : '6'}`}
                onClick={Actions.gotoPage}
                permissionToEnter={canAccessAnalysis()}
            />
        </DashboardNavigator>
    )
}
