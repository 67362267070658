import React, {useEffect, useReducer} from 'react'

import {I18n} from 'react-i18nify'
import Checkbox from '../../capture/signifier/Checkbox'
import NodeIcon from '../../strategyMap/components/NodeIcon'
import {getNodeDisplay, isNC, isPSF} from '../../strategyMap/utils'
import {useNode} from '../../strategyMap/hooks/useNode'
import {
    Description,
    Modal,
    SearchContainer,
    SearchIcon,
    SearchInput,
    SelectFields,
    UserInformationAsList,
    UserItem,
    Users,
    UserTitle,
} from '../../core/ui/modal/Modal'

const SearchBox = (props) => {
    return (
        <SearchContainer>
            <SearchIcon />
            <SearchInput
                placeholder={I18n.t('dashboard.search')}
                onChange={(value) => props.onSetFilter(value.target.value)}
            />
        </SearchContainer>
    )
}

export const NodeItem = (props) => {
    return (
        <UserItem>
            <Checkbox onClick={() => props.onChooseNode(props.node.id)} checked={props.checked} />

            <UserInformationAsList>
                <UserTitle>
                    <NodeIcon node={props.node} />
                    {getNodeDisplay(props.node)} {props.node.title}
                </UserTitle>
            </UserInformationAsList>
        </UserItem>
    )
}

const initialState = {filteredNodes: [], selectedNodes: []}

function reducer(state, [type, payload]) {
    switch (type) {
        case 'reset':
            return {
                filteredNodes: payload,
                selectedNodes: [],
            }
        case 'setFilteredNodes':
            return {
                ...state,
                filteredNodes: payload,
            }
        case 'setSelectedNodes':
            return {
                ...state,
                selectedNodes: payload,
            }
        default:
            return state
    }
}

const ModalAddParent = (props) => {
    const {
        unrelatedNodes,
        isExperiment: isCurrentNodeExperiment,
        isNC: isCurrentNodeNC,
        isPSF: isCurrentNodePSF,
        isCSF: isCurrentNodeCSF
    } = useNode();

    const nodes = unrelatedNodes.filter(
        node =>
            (isCurrentNodeExperiment && isPSF(node)) ||
            isCurrentNodeNC ||
            isCurrentNodePSF ||
            (isCurrentNodeCSF && isNC(node))
    );
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        filteredNodes: nodes
    });
    const {filteredNodes, selectedNodes} = state;

    useEffect(() => {
        dispatch(['reset', nodes]);
    }, []);

    const handleChooseNode = (nodeId) => {
        if (selectedNodes.indexOf(nodeId) >= 0)
            selectedNodes.splice(selectedNodes.indexOf(nodeId), 1);
        else
            selectedNodes.push(nodeId);
        dispatch(['setSelectedNodes', selectedNodes]);
    }

    const handleSetFilter = (searchStringFilter) => {
        searchStringFilter = searchStringFilter.toLowerCase();
        const filteredNodesList = nodes.filter(
            (node) =>
                (node.title && node.title.toLowerCase().includes(searchStringFilter)) ||
                node.description.toLowerCase().includes(searchStringFilter) ||
                node.sid.toString().includes(searchStringFilter)
        );

        dispatch(['setFilteredNodes', filteredNodesList]);
    }

    const handleClose = () => {
        props.onRequestClose();
        dispatch(['reset', nodes]);
    }

    const handleSave = () => {
        if (selectedNodes.length > 0)
            props.onSaveParents(selectedNodes);
        handleClose();
    }

    return (
        <Modal 
            isOpen={props.isOpen}
            title={I18n.t('sidebar.addParent.dialog.title')}
            footer={{
                confirmText: I18n.t('sidebar.addParent.dialog.buttons.add'),
                onConfirm: handleSave,
                onCancel: handleClose
            }}
        >
            <Description>{I18n.t('sidebar.addParent.dialog.description')}</Description>
            <SelectFields>
                <SearchBox onSetFilter={ handleSetFilter } />
                <Users>
                    {
                        filteredNodes.map( node => (
                            <NodeItem
                                key={node.id}
                                node={node}
                                checked={selectedNodes.indexOf(node.id) >= 0}
                                onChooseNode={handleChooseNode}
                            />
                        ))
                    }
                </Users>
            </SelectFields>
        </Modal>
    )
}

export default ModalAddParent
