import React from "react"
import styled from 'styled-components'
import {Img} from "../../ui/generic"
import {Flex} from "../../ui/Flex"
import {NavItemIntern} from "../BootstrapWrapper";

const Title = styled.div`
    color: black;
    text-decoration: none;
    font-size: 7px;
`

const AnnotatedNavItem = (props) => {

    return <NavItemIntern
            onClick={props.onClick}>
            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
                <div>
                    <Img
                        alt={props.title}
                        className="icon"
                        src={props.icon}
                    />
                    <Title>
                        {props.title}
                    </Title>
                </div>
            </Flex>
        </NavItemIntern>
}


export default AnnotatedNavItem