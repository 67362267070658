import React from 'react'
import {I18n} from 'react-i18nify'
import Checkbox from '../../capture/signifier/Checkbox'
import styled from 'styled-components'
import {LIGHT_GREY} from '../../core/ui/_colors'

export const UL = styled.ul`
    width: 50%;
    float: left;
    padding-left: 0;
    list-style: none;

    li {
        margin-bottom: 15px;
    }
    li.disabled {
        color: ${LIGHT_GREY};
    }
`

const SmartList = ({isReadOnly, goal, desiredOutcomesEnabled, handleSmartToggle}) => {
    
    const handleToggle = (name) => {
        if (name === 'measurable' && !desiredOutcomesEnabled) {
            return
        }
        handleSmartToggle(name)
    }

    return (
        <div className="clearfix">
            <UL>
                <li className="clearfix">
                    <Checkbox
                        key="specificCheckbox"
                        label={I18n.t('strategyMap.nodes.specific')}
                        checked={goal.specific}
                        disabled={isReadOnly}
                        onClick={() => handleToggle('specific')}
                    />
                </li>
                <li className="clearfix">
                    <Checkbox
                        label={I18n.t('strategyMap.nodes.measurable')}
                        key="measurableCheckbox"
                        disabled={isReadOnly || !desiredOutcomesEnabled}
                        checked={goal.measurable}
                        onClick={() => handleToggle('measurable')}
                    />
                </li>
                <li className="clearfix">
                    <Checkbox
                        key="attainableCheckbox"
                        label={I18n.t('strategyMap.nodes.attainable')}
                        checked={goal.attainable}
                        disabled={isReadOnly}
                        onClick={() => handleToggle('attainable')}
                    />
                </li>
            </UL>
            <UL>
                <li className="clearfix">
                    <Checkbox
                        key="relevantCheckbox"
                        label={I18n.t('strategyMap.nodes.relevant')}
                        checked={goal.relevant}
                        disabled={isReadOnly}
                        onClick={() => handleToggle('relevant')}
                    />
                </li>
                <li className="clearfix">
                    <Checkbox
                        key="timedCheckbox"
                        label={I18n.t('strategyMap.nodes.timed')}
                        checked={goal.timed}
                        disabled={isReadOnly}
                        onClick={() => handleToggle('timed')}
                    />
                </li>
            </UL>
        </div>
    )
}

export default SmartList
