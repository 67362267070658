import {barSchema, cultureSchemaLabel, trafficLightColor} from './ChartColorSchema'
import {ChartType, SubTypes} from './ChartsConfig'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import React from 'react'
import styled from 'styled-components'
import {ColorsTypes} from './ChartColor'
import { DARK_GREY } from '../core/ui/_colors'

export const Icon = styled.i`
    font-size: 18px;
    cursor: pointer;
    color: ${DARK_GREY};
`

export const getTooltip = (tip, id) => (
    <OverlayTrigger
        key={id}
        placement={'top'}
        overlay={
            <Tooltip id={`tooltip-${'top'}`} style={{opacity: 1}}>
                <b>{tip}</b>
            </Tooltip>
        }
    >
        <Icon className="material-icons help">help</Icon>
    </OverlayTrigger>
);

const CHARTS_DASHBOARD_LIGHT = [
    {
        title: 'charts.Topic.CircleProgress.CapturedDecisions',
        tooltip: 'dashboard.tooltip.capturedDecision',
        Type: ChartType.CircleProgress,
        subType: SubTypes.circleProgress.capturedDecisions,
        testId: 'circle-progress-captured-decisions'
    },
    {
        title: 'charts.Topic.CircleProgress.DatasetAffinity',
        tooltip: 'dashboard.tooltip.affinity',
        Type: ChartType.CircleProgress,
        subType: SubTypes.circleProgress.affinity,
        testId: 'circle-progress-dataset-affinity'
    },
    {
        title: 'charts.Topic.Bar.departmentLimitedLicense',
        tooltip: 'dashboard.tooltip.decisionPerDepartment',
        Type: ChartType.BarHorizontalCustom,
        subType: SubTypes.bar.department,
        testId: 'bar-department-limited-license',
        legend: [
            {
                label: 'charts.Topic.Bar.legend.idealAmountOfDecisionsCaptured',
                color: trafficLightColor.gray
            },
            {
                label: 'charts.Topic.Bar.legend.decisionsCaptured',
                color: trafficLightColor.green
            }
        ]
    }
];
export const CHARTS_CARTESIAN = [
    {
        title: 'capture.Dimension.Options.leader-type',
        tooltip: 'dashboard.tooltip.leadership',
        colorSchema: cultureSchemaLabel.reduceColor.leaderType,
        optionalStyle: {confirmed: 'justBorder'},
        Type: ChartType.HeatMapReactangle,
        subType: SubTypes.culture.leaderType,
        testId: 'culture-leader-type'
    },
    {
        title: 'capture.Dimension.Options.orientation',
        tooltip: 'dashboard.tooltip.orientation',
        colorSchema: cultureSchemaLabel.reduceColor.orientation,
        optionalStyle: {confirmed: 'justBorder'},
        Type: ChartType.HeatMapReactangle,
        subType: SubTypes.culture.orientation,
        testId: 'culture-orientation'
    },
    {
        title: 'capture.Dimension.Options.effectiveness',
        tooltip: 'dashboard.tooltip.effectiveness',
        colorSchema: cultureSchemaLabel.reduceColor.effectiveness,
        optionalStyle: {confirmed: 'justBorder'},
        Type: ChartType.HeatMapReactangle,
        subType: SubTypes.culture.effectiveness,
        testId: 'culture-effectiveness'
    },
    {
        title: 'capture.Dimension.Options.value-drivers',
        tooltip: 'dashboard.tooltip.valueDrivers',
        colorSchema: cultureSchemaLabel.reduceColor.valueDrivers,
        optionalStyle: {confirmed: 'justBorder'},
        Type: ChartType.HeatMapReactangle,
        subType: SubTypes.culture.valueDrivers,
        testId: 'culture-value-drivers'
    }
];
export const CHARTS_TRIANGLES = [
    {
        title: 'charts.Topic.Triangle.1',
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T1,
        testId: 'triangle-T1'
    },
    {
        title: 'charts.Topic.Triangle.2',
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T2,
        testId: 'triangle-T2'
    },
    {
        title: 'charts.Topic.Triangle.3',
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T3,
        testId: 'triangle-T3'
    },
    {
        title: 'charts.Topic.Triangle.4',
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T4,
        testId: 'triangle-T4'
    },
    {
        title: 'charts.Topic.Triangle.5',
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T5,
        testId: 'triangle-T5'
    },
    {
        title: 'charts.Topic.Triangle.6',
        Type: ChartType.Triangle,
        subType: SubTypes.triangle.T6,
        testId: "triangle-T6"
    }
];
export const CHARTS_MULTIPLE_CHOICE = [
    {
        title: 'charts.Topic.Bar.period',
        tooltip: 'dashboard.tooltip.speed',
        colorSchema: barSchema.reduceColor.period,
        optionalStyle: undefined,
        Type: ChartType.BarHorizontal,
        subType: SubTypes.bar.period,
        testId: 'bar-period'
    },
    {
        title: 'charts.Topic.Bar.period',
        tooltip: 'dashboard.tooltip.speed',
        colorSchema: barSchema.reduceColor.period,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.period,
        testId: 'bar-period-transpose'
    },
    {
        title: 'charts.Topic.Bar.mood',
        tooltip: 'dashboard.tooltip.mood',
        colorSchema: barSchema.reduceColor.mood,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.mood,
        testId: 'bar-mood'
    },
    {
        title: 'charts.Topic.Bar.type',
        tooltip: 'dashboard.tooltip.type',
        colorSchema: barSchema.reduceColor.type,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.type,
        testId: 'bar-type'
    },
    {
        title: 'charts.Topic.Bar.frequency',
        tooltip: 'dashboard.tooltip.frequency',
        colorSchema: barSchema.reduceColor.frequency,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.frequency,
        testId: 'bar-frequency'
    },
    {
        title: 'charts.Topic.Bar.decision_owner',
        tooltip: 'dashboard.tooltip.decisionMakers',
        colorSchema: barSchema.reduceColor.decision_owner,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.decisionOwner,
        testId: 'bar-owner'
    }
];
export const CHARTS_DEMOGRAPHICS = [
    {
        title: 'charts.Topic.Bar.department',
        tooltip: 'dashboard.tooltip.department',
        colorSchema: barSchema.reduceColor.department,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.department,
        testId: 'bar-department'
    },
    {
        title: 'charts.Topic.Bar.year',
        tooltip: 'dashboard.tooltip.year',
        colorSchema: barSchema.reduceColor.year,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.year,
        testId: 'bar-year'
    },
    {
        title: 'charts.Topic.Bar.city',
        tooltip: 'dashboard.tooltip.city',
        colorSchema: barSchema.reduceColor.city,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.city,
        testId: 'bar-city'
    },
    {
        title: 'charts.Topic.Bar.country',
        tooltip: 'dashboard.tooltip.country',
        colorSchema: barSchema.reduceColor.country,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.country,
        testId: 'bar-country'
    },
    {
        title: 'charts.Topic.Bar.role',
        tooltip: 'dashboard.tooltip.role',
        colorSchema: barSchema.reduceColor.role,
        optionalStyle: undefined,
        Type: ChartType.BarVertical,
        subType: SubTypes.bar.role,
        testId: 'bar-role'
    }
];
export const CHARTS_STATISTICS = [
    {
        title: "charts.Topic.Statistics.mostInfluencedValues",
        Type: ChartType.Statistics,
        subType: SubTypes.statistics.mostInfluencedValues,
        testId: "most-influenced"
    },
    {
        title: 'charts.Topic.Statistics.stonesAVG',
        Type: ChartType.Statistics,
        subType: SubTypes.statistics.stonesAverage,
        range: {x: {min: -100, max: 100}},
        testId: 'stones-average'
    },
    {
        title: 'charts.Topic.Statistics.triadsAVG',
        Type: ChartType.Statistics,
        subType: SubTypes.statistics.trianglesAverage,
        testId: 'triangles-average',
    },
    {
        title: 'charts.Topic.Statistics.emotionByQuestion.All',
        Type: ChartType.Statistics,
        subType: SubTypes.statistics.emotionByQuestion,
        colorSchema: ColorsTypes.darkBlue,
        testId: 'MCQ-average'
    }
];

export const CHARTS_HeadCount = [
    {
        title: 'charts.Topic.Bar.HeadCount',
        Type: ChartType.GroupedBar,
        subTypes: [SubTypes.bar.department, SubTypes.bar.headcount],
        testId: 'bargrouped-headcount',
        colorSchema: {'decisions': barSchema.reduceColor.department.validating.default, 'headcount': barSchema.reduceColor.department.validating.headcount}
    }
];

export const DASHBOARD_CHARTS = [
    ...CHARTS_DASHBOARD_LIGHT,
    ...CHARTS_CARTESIAN,
    ...CHARTS_TRIANGLES,
    ...CHARTS_MULTIPLE_CHOICE,
    ...CHARTS_STATISTICS,
    ...CHARTS_HeadCount,
    {
        title: 'charts.Topic.Bar.typeVSfrequency',
        tooltip: 'dashboard.tooltip.typeVsFrequency',
        colorSchema: barSchema.frequency,
        optionalStyle: undefined,
        Type: ChartType.CrossProduct,
        subTypes: [SubTypes.bar.type, SubTypes.bar.frequency],
        testId: 'bar-type-vs-frequency'
    },
    ...CHARTS_DEMOGRAPHICS
]
