import React, {useEffect, useState} from 'react'
import {createErrorClass} from '../../core/utils/html'
import {InputWrapper} from './Shared.styles'
import ErrorOutput from './ErrorOutput'
import {I18n} from "react-i18nify";
import {validateValue} from '../../core/utils/form'
import {Panel} from "../../core/components/BootstrapWrapper";


const SingleLineInput = (props) => {
    const [value, setValue] = useState(props.defaultValue)
    const [validationErrors, setValidationErrors] = useState(null)

    const validateInput = () => {
        const [valid, errs] = validateValue(props.validationRules, value)
        if (!valid) {
            setValidationErrors(errs)
            props.onError && props.onError(props.fieldName)
            return false
        }
        return true
    }

    useEffect(() => {
        if(props.shouldValidate)
            validateInput()
    }, [props.shouldValidate])


    useEffect(() => {
        if (validationErrors)
            validateInput()
    }, [I18n._localeKey])

    return (
        <Panel default className={createErrorClass(props.fieldName, props.errors || validationErrors, !props.errors)} header={props.header}>
            <InputWrapper>
            <input
                style={{cursor: props.readOnly ? "not-allowed" : ""}}
                data-testid="input-field"
                value={value}
                readOnly={props.readOnly}
                placeholder={props.placeholder}
                onBlur={() => {
                    if(props.validationRules && !validateInput()) {
                        return
                    }
                    props.onBlur && props.onBlur(value)
                }}
                onChange={(e) => {
                    setValue(e.target.value)
                    setValidationErrors(null)
                }}
            />
                <ErrorOutput errors={props.errors || validationErrors}/>
            </InputWrapper>
        </Panel>
    )
}

export default SingleLineInput
