import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {I18n} from 'react-i18nify'

import {useWindowSize} from '../../../core/hooks/useWindowSize'
import Dimension from './Dimension'
import {BLACK, MAGENTA} from '../../../core/ui/_colors'
import {
    ArrowLabelBottom,
    ArrowLabelLeft,
    ArrowLabelRight,
    ArrowLabelTop,
    CompetingValuesContainer,
    CompetingValuesImg,
    CompetingValuesStand,
    CompetingValuesStandItem,
    CompetingValuesStandItemText,
    DiagramLabels,
    DragInfo,
    DragInfoInner,
    InsideLabel,
    InsideLabelInner,
    InsideLabelsCont,
} from './Dimension.styles'
import diagramDesktopIcon from "../../../img/Capture/diagram_desktop.png"
import diagramTabletIcon from "../../../img/Capture/diagram_tablet.png"
import diagramMobileIcon from "../../../img/Capture/diagram_mobile.png"

const icons = {
    diagram_mobile: diagramMobileIcon,
    diagram_tablet: diagramTabletIcon,
    diagram_desktop: diagramDesktopIcon
}

const DimensionsContainer = (props) => {
    const windowSize = useWindowSize()

    const [competingValues, setCompetingValues] = useState(props.competingValues)
    const [activeValue, setActiveValue] = useState('default')

    const handlePositionChange = (x, y, name) => {
        const newCompetingValues = JSON.parse(JSON.stringify(competingValues))
        for (let i = 0; i < newCompetingValues.length; i++) {
            if (newCompetingValues[i].name === name) {
                newCompetingValues[i].x = x || 0
                newCompetingValues[i].y = y || 0
            }
        }
        setCompetingValues(newCompetingValues)
        props.onPositionChange(newCompetingValues)
        setActiveValue('')
    }

    useEffect(() => {
        if (props.shouldResetPosition) {
            setCompetingValues(competingValues.map((stone) => ({...stone, x: 0, y: 0})))
        }
    }, [props.shouldResetPosition])

    const renderDiagramLabels = () => {
        let labels
        switch (activeValue) {
            case '':
                labels = ''
                break
            case 'default':
                const translationValue =
                    windowSize.screen_width === 'mobile' ? 'DefaultUp' : 'DefaultLeft'
                labels = (
                    <DragInfo>
                        <DragInfoInner>
                            {I18n.t('capture.Dimension.Graph.' + translationValue)}
                        </DragInfoInner>
                    </DragInfo>
                )
                break
            default:
                labels = (
                    <InsideLabelsCont className={'lele'}>
                        <InsideLabel>
                            <InsideLabelInner>
                                {I18n.t('capture.Dimension.Graph.Clan.' + activeValue)}
                            </InsideLabelInner>
                        </InsideLabel>
                        <InsideLabel>
                            <InsideLabelInner>
                                {I18n.t('capture.Dimension.Graph.Adhocracy.' + activeValue)}
                            </InsideLabelInner>
                        </InsideLabel>
                        <InsideLabel>
                            <InsideLabelInner>
                                {I18n.t('capture.Dimension.Graph.Hierarchy.' + activeValue)}
                            </InsideLabelInner>
                        </InsideLabel>
                        <InsideLabel>
                            <InsideLabelInner>
                                {I18n.t('capture.Dimension.Graph.Market.' + activeValue)}
                            </InsideLabelInner>
                        </InsideLabel>
                    </InsideLabelsCont>
                )
                break
        }

        return (
            <DiagramLabels>
                <ArrowLabelTop>{I18n.t('capture.Dimension.Graph.Image.top')}</ArrowLabelTop>
                <ArrowLabelBottom>
                    {' '}
                    {I18n.t('capture.Dimension.Graph.Image.bottom')}
                </ArrowLabelBottom>
                <ArrowLabelRight>{I18n.t('capture.Dimension.Graph.Image.right')}</ArrowLabelRight>
                <ArrowLabelLeft>{I18n.t('capture.Dimension.Graph.Image.left')}</ArrowLabelLeft>
                {labels}
            </DiagramLabels>
        )
    }

    const imgName = 'diagram_' + windowSize.screen_width
    return (
        <CompetingValuesContainer className={props.allDragged}>
            <CompetingValuesImg
                id="diagram"
                style={{
                    backgroundImage:
                        'url("' + icons[imgName] + '")',
                }}
            >
                {competingValues.map((item, i) => (
                    <Dimension
                        key={i}
                        keyVal={item.name}
                        bounds={item.bounds}
                        x={item.x}
                        y={item.y}
                        onPositionChange={handlePositionChange}
                        dragStartCallback={(activeValue) => setActiveValue(activeValue)}
                        shouldResetPosition={props.shouldResetPosition}
                    />
                ))}
            </CompetingValuesImg>
            <CompetingValuesStand>
                {competingValues.map((item) => (
                    <CompetingValuesStandItem key={item.name}>
                        <CompetingValuesStandItemText
                            style={{
                                color: item.x === 0 && props.hasError ? MAGENTA : BLACK,
                            }}
                        >
                            {I18n.t(item.value)}
                        </CompetingValuesStandItemText>
                    </CompetingValuesStandItem>
                ))}
            </CompetingValuesStand>
            {renderDiagramLabels()}
        </CompetingValuesContainer>
    )
}

DimensionsContainer.propTypes = {
    competingValues: PropTypes.array,
    onPositionChange: PropTypes.func,
    I18n: PropTypes.object,
    allDragged: PropTypes.bool,
}

export default DimensionsContainer
