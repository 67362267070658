import React from 'react'
import {Col} from 'react-bootstrap'
import Nodes from './Nodes'
import {useActions} from '../../core/hooks/useActions'
import {strategyMapSlice} from '../redux/slices'
import {useSelector} from 'react-redux'
import { ITEM_TYPES } from '../const'
import {useDrop} from 'react-dnd'
import {handleDrop} from '../utils'
import {fieldsToSelectors} from '../redux/selectors'

const Board = ({nodes, x, timeArea, nodeParentId, isOpen, nodesPerTimeArea, nodeBlocks}) => {
    const Actions = useActions(strategyMapSlice.actions)
    const {nodeMap} = useSelector((state) => state.strategyMap)
    const clickedNodesToChildren = useSelector(fieldsToSelectors['clickedNodesToChildren'])

    const nodesLength = nodes.length

    const [_, drop] = useDrop({
        accept: ITEM_TYPES.NODE,
        drop(props, monitor) {
            handleDrop(monitor, Actions, x, nodesLength, timeArea, nodeMap, clickedNodesToChildren)
        },
    })

    return (
        <Col sm={4} className={'col-xs-4'} style={{padding: 0}}>
            <div ref={drop} className={'node-container'}>
                <Nodes
                    x={x}
                    nodesPerTimeArea={nodesPerTimeArea}
                    isOpen={isOpen}
                    nodeParentId={nodeParentId}
                    clickedNodesToChildren={clickedNodesToChildren}
                    nodes={nodes}
                    nodeMap={nodeMap}
                    timeArea={timeArea}
                    nodeBlocks={nodeBlocks}
                    {...Actions}
                />
                <div style={{clear: 'both'}} />
            </div>
        </Col>
    )
}

export default Board
