import React from 'react'
import {
    BarContainer,
    BarLegend,
    DepartmentName,
    HorizontalBarContainer,
    InnerLabel,
    ItemLegend,
    ProgressBarCustom
} from './BarHorizontalCustom.styles'
import {I18n} from 'react-i18nify'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {truncateLabel} from '../../core/utils/string'

const BarHorizontalCustom = (props) => {
    const data = Object.entries(props.departmentStat)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({...r, [k]: v}), {});

    return (
        <>
            {Object.keys(data).map((department, index) => {
                return (
                    <HorizontalBarContainer data-testid={props.testId} key={department} zoom={props.zoom}>
                        
                        <BarContainer>
                            <ProgressBarCustom
                                now={props.departmentStat[department]}
                            />
                        </BarContainer>
                        <DepartmentName>
                            <InnerLabel>
                                <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-${index}`} style={{opacity: 1}}>
                                            <b>{department}</b>
                                        </Tooltip>
                                    }
                                >
                                    <span>{truncateLabel(department, 15)}</span>
                                </OverlayTrigger>
                            </InnerLabel>
                        </DepartmentName>
                    </HorizontalBarContainer>
                )
            })}
            {props.legend &&
                props.legend.map((item) => {
                    return (
                        <ItemLegend key={item.label}>
                            <BarLegend color={item.color} />
                            <div>{I18n.t(item.label)}</div>
                        </ItemLegend>
                    )
                })}
        </>
    )
}

export default BarHorizontalCustom
