import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import moment from 'moment'
import {I18n} from 'react-i18nify'
import {decisionsFiltered} from "../../dashboard/components/DecisionFilter/Dashboard.functions";
import { barSchema } from '../../Charts/ChartColorSchema'
import { BarChart, Bar, XAxis, YAxis, Legend, Tooltip, Symbols, Surface } from 'recharts';
import { sortByField } from '../../core/utils/array'
import _ from 'lodash'

const GraphChartWrapper = styled.div`
    width: 100%;
    margin: 20px auto;
    overflow-x: auto;
    overflow-y: hidden;
`;

const fillObj = Object.keys(barSchema.mood).reduce((a, v) => ({ ...a, [v]: 0}), {})

const OverviewGraph = () => {
    const {decisions, filters} = useDashboardSlice();
    const [chartData, setChartData] = useState([]);
    const monthMap = moment.localeData(I18n._localeKey).monthsShort();

    const renderCusomizedLegend = ({ payload }) => {
        return (
          <div className="customized-legend">
            {payload.map((entry, key) => {
              const { dataKey, color } = entry;
              const active = _.includes(false, dataKey);
              const style = {
                marginRight: 10,
                color: active ? "#AAA" : "#000"
              };
    
              return (
                <span
                  className="legend-item"
                  onClick={() => this.handleClick(dataKey)}
                  style={style}
                  key={key}
                >
                  <Surface width={10} height={10} viewBox={{x: 0, y: 0, width: 10, height: 10}}>
                    <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                    {active && (
                      <Symbols
                        cx={5}
                        cy={5}
                        type="circle"
                        size={25}
                        fill={"#FFF"}
                      />
                    )}
                  </Surface>
                  <span>{dataKey}</span>
                </span>
              );
            })}
          </div>
        );
      };

    const getData = () => {
        const dataMap = []
        const startDate = moment().subtract( 45, 'days' );
        const endDate = moment();
        for (let m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
          const dateFormat = m.get('date') + " " + monthMap[m.get('month')] + " " + m.get('year')
          dataMap[dateFormat] = {...fillObj, name: dateFormat}
        }
        
        sortByField(decisionsFiltered( decisions, filters ), 'date').forEach( decision => {
            const d = moment(decision.date + "T00:00:00");
            const dateFormat = d.get('date') + " " + monthMap[d.get('month')] + " " + d.get('year')
            if (dataMap[dateFormat] ) {
              dataMap[dateFormat][decision.mood] += 1
            }
        });

        setChartData( Object.values(dataMap) );
    }

    useEffect(() => getData(), [decisions, filters]);

    return (
        <GraphChartWrapper>
            <BarChart 
                testId={'overview-graph'}
                width={1100}
                height={400} 
                autoscale={false}
                data={ chartData } >
                <Legend
                    verticalAlign="top"
                    height={36}
                    align="right"    
                    payload={_.toPairs(barSchema.mood).map(pair => ({
                        dataKey: pair[0],
                        color: pair[1]
                    }))}
                    content={renderCusomizedLegend}
                />
                <Tooltip />
                <XAxis dataKey="name" />
                <YAxis />
                {Object.keys(barSchema.mood)
                    .map(
                        (key) => 
                            <Bar key={key} dataKey={key} stackId="a" fill={barSchema.mood[key]} />
                    )}
            </BarChart>
        </GraphChartWrapper>
    )
}

export default React.memo(OverviewGraph)
