import styled from 'styled-components'
import {Col} from 'react-bootstrap'
import {Z_INDICES} from '../../core/const'
import {BLACK, BRIGHT_GREY, DARK_GREY, MID_GREY, PALE_GREY, WHITE} from '../../core/ui/_colors'
import Avatar from "../../core/components/Avatar";

export const NODE_HEIGHT = 186

export const Container = styled(Col)`
    padding: 0px 0px 0px 0px;
    margin-bottom: 65px;
    z-index: ${Z_INDICES.Node};
    opacity: ${ p => p.dragging ? 0.3 : 1 };
`

export const Placeholder = styled(Col)`
    background-color: ${BRIGHT_GREY};
    height: ${NODE_HEIGHT + 'px'};
    border: 1px dashed ${MID_GREY};
`

export const InnerBody = styled.div.attrs((props) => ({
    'data-testid': `node-card-${props.nodeId}`,
}))`
    transition: 0.15s;
    cursor: ${(p) => (p.isDraggable ? 'move' : 'pointer')};
    background-color: ${WHITE};
    padding: 8px 12px 12px;
    word-break: break-word;
    height: ${NODE_HEIGHT + 'px'};
    width: 100%;
    overflow: hidden;
`

export const TopBar = styled.div`
    opacity: ${(props) => props.alpha};
    background-color: ${(props) => props.fill};
    height: 6px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

export const NodeHeader = styled.div`
    font-size: ${(p) => p.fontSize}px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 6px;
`

export const NodeTitle = styled.div`
    font-size: ${(p) => p.fontSize}px;
    font-weight: bold;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const NodeText = styled.div`
    max-height: ${({hasTitle}) => (hasTitle ? '59px' : '84px')};
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${({hasTitle}) => (hasTitle ? 3 : 4)};
    -webkit-box-orient: vertical;
`

export const ToggleContainer = styled.div`
    border-radius: 50%;
    width: 26px;
    height: 26px;
    background-color: ${WHITE};
    position: absolute;
    bottom: -13px;
    left: 50%;
    margin-left: -12.5px;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
        width: 14.3px;
        height: 8.5px;
        object-fit: contain;
        margin-left: -1px;
        margin-bottom: -1px;
    }
`

export const HalfCircleBottom = styled.div`
    width: 30px;
    height: 30px;
    background: ${DARK_GREY};
    border-radius: 50%;
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -14.5px;
    z-index: -1;
`

export const Badge = styled.div`
    float: right;
    font-size: 12px;
    font-weight: normal;
    color: ${WHITE};
    background-color: ${BLACK};
    border-radius: 10rem;
    margin: 1px 2px 0px 6px;
    padding: 0.25em 0.6em;
    line-height: 1;
    text-align: center;
`

export const CommentsBadge = styled(Badge)`
    width: 30px;
    &:before {
        content: '\\02D1';
        position: absolute;
        color: ${BLACK};
        font-size: 23px;
        margin-top: 2px;
    }
`

export const ChampionAvatar = styled(Avatar)`
    margin: 0 2px 0 6px;
    font-size: 10px;
    width: 25px;
    height: 25px;
    line-height: 24px;
`

export const Title = styled.div`
    padding: 2px 5px;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
`

export const SectionTitle = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: ${MID_GREY};
`

export const Section = styled.div`
    margin-top: 40px;
    width: 100%;
`

export const SideBarContainerWrapper = styled.div`
    width: 100%;
    padding: 4px;
    display: flex;
    position: relative;
    align-items: center;
    ${(p) => (p.clickable ? 'cursor: pointer;' : '')}
    transition: background .5s;
    &:hover {
        background: ${PALE_GREY};
        .show-hover {
            display: block;
        }
    }
`
