import styled from 'styled-components'
import {BRIGHT_GREY, MAGENTA, LIGHT_GREY} from '../../core/ui/_colors'
import Charts from '../../Charts/Charts'

export const InspectChartStyle = styled.div`
    padding-bottom: 30px;
    padding-top: 30px;
    clear: both;
    font-size: 110%;
    border-top: 2px solid ${BRIGHT_GREY};
    margin-left: 20px;
    margin-right: 20px;
`

export const InspectGraphChartWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    .user-select-none {
        right: 20px;
    };
    * {
        margin: auto;
        text-align: center;
    }
    display: flex;
  
    justify-content: center;
    align-items: center;
`

export const InspectGraphSelectBoxWrapper = styled.div`
    margin-left: 20px;
    width: 50%;
`

export const HelperText = styled.div`
    white-space: break-spaces;
    font-size: 14px;
`

export const ID = styled.div`
    float: left;
    padding-top: 12px;
    padding-right: 16px;
    padding-left: 16px;
`
export const InputContainer = styled.div`
    float: left;
    width: 90%;
`

export const ExtraHint = styled.div`
    float: right;
    padding-left: 20px;
    color: ${props => props.color}
`
export const TextField = styled.textarea`
    width: 100%;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    border-color: ${props => props.hasLatexCommands ? MAGENTA : LIGHT_GREY}
`

export const QuestionAnswerContainer = styled.div`
    padding: 10px;
    width: 100%;
    font-size: 12px;
    margin-top: 30px;
`
export const QuestionAnswerTitle = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
`
export const QuestionComment = styled.div`
    width: 100%;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 10px;
`

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    min-width: 900px;
`

export const ChartsModal = styled(Charts)`
    width: 400px;
`

export const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const Image = styled.div`
    width: 50%;
    margin-left: 0px;
    float: left;
    transform: ${(props) => (props.scale ? 'scale(' + props.scale + ')' : 'scale(1)')};
    div: {
        width: 420px !important;
    }
`
export const QuestionContainer = styled.div`
    border-top: 1px solid #eee;
    margin-bottom: 30px;
    clear: both;
    width: 100%;
`

export const ReportWelcomeContent = styled.div`
    width: 60%;
    min-height: 400px;
`

export const ImageWrapper = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin-left: ${(props) => props.marginLeft};
    float: left;
    bottom: 0;
    overflow: visible:
`

export const GraphChartWrapper = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: scroll;
    padding: 10px;
    background: white;
    display: flex;
    justify-content: center;
    min-height: 450px;
    min-width: 900px;
`

export const PrintContainer = styled.div`
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
    $( p => p.width && 'width: ' + p.width + 'px;'}
`

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
`

export const Content = styled.div`
    min-height: 400px;
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

export const RowItem = styled.div`
    flex: 1;
    min-width: 300px;
`

export const RowItemContent = styled.div`
    overflow-y: scroll;
`

export const H3 = styled.h3`
    font-size: 20px;
    font-weight: bold;
`