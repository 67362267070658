import React, {useState} from 'react'
import {I18n} from 'react-i18nify'
import {ButtonPrimary, ButtonSecondary} from '../../core/components/Buttons'
import Modal from '../../core/components/Modal'
import {GenerateHighResImage} from './GenerateHighResImage'
import {ChoiceBox, ChoiceRow} from '../Filter/Filters.styles'
import {REPORT_CHARTS} from '../../Charts/ChartsOrgScanReport'
import {ChartType} from '../../Charts/ChartsConfig'
import {ModalFooterContainer, ModalFooterLeft, ModalFooterRight,} from '../../core/components/Modal.styles'
import {ProgressBar} from 'react-bootstrap'
import {Div} from '../../core/ui/Div'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {
    Container,
    Content,
    Row,
    RowItem,
    RowItemContent,
    H3
} from './Reports.styles'
import {RESTRICTIONS} from "../../core/const";

const CHART_SECTIONS = [{
        title: 'analysis.dialog.sections.BarCharts',
        types: [ChartType.BarHorizontal, ChartType.BarVertical, ChartType.GroupedBar]
    },{
        title: 'analysis.dialog.sections.TriangleCharts',
        types: [ChartType.Triangle]
    },{
        title: 'analysis.dialog.sections.HeatMapCharts',
        types: [ChartType.HeatMapReactangle]
    },{
        title: 'analysis.dialog.sections.CrossProductCharts',
        types: [ChartType.CrossProduct, ChartType.HeatMapCrossProduct]
    },{
        title: 'analysis.dialog.sections.PieChart',
        types: [ChartType.CrossProduct, ChartType.PieChart]
    },{
        title: 'analysis.dialog.sections.Statistics',
        types: [ChartType.Statistics]
    }];

export const CheckboxItem = ({checked, item, handleItemSelect}) => {
    return (
        <ChoiceRow onClick={() => handleItemSelect(item, checked)}>
            <ChoiceBox className="checkbox" role="button">
                {checked && <i className="material-icons">check</i>}
            </ChoiceBox>
            <span>{I18n.t(item.title)}</span>
        </ChoiceRow>
    )
}

const GenerateHighResImageModal = () => {
    const {Actions} = useDashboardSlice()
    const [open, setOpen] = useState(false);
    const [startProcessingImage, setStartProcessingImage] = useState(false);
    const [selectedChart, setSelectedChart] = useState([]);
    const [progressBarStatus, setProgressBarStatus] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const handleItemSelect = (item, checked) => {
        if (checked)
            setSelectedChart( selectedChart.filter( it => it.testId !== item.testId ) );
        else
            setSelectedChart( [...selectedChart, item] );
    }

    const onModalClose = () => {
        setStartProcessingImage(false);
        setCurrentImageIndex(null);
        setOpen(false);
        setSelectedChart([]);
    }

    const REPORT_CHARTS_SORTED =
        REPORT_CHARTS
            .sort( (a,b) => I18n.t(a.title).localeCompare( I18n.t(b.title), I18n._localeKey) );

    const isAllSelected = (names) =>
            REPORT_CHARTS.filter( c => names.includes(c.Type)).length === selectedChart.filter( c => names.includes(c.Type)).length;

    const selectAll = (names) => {
        const data = REPORT_CHARTS.filter( c => names.includes(c.Type) );
        if( isAllSelected(names) ){
            setSelectedChart(selectedChart.filter((c) => !names.includes(c.Type)));
        } else {
            setSelectedChart([...selectedChart.filter((c) => !names.includes(c.Type)), ...data]);
        }
    }

    return (
        <>
            <Modal
                closeOnOverlayClick={false}
                isOpen={open}
                onClose={onModalClose}
                title={
                   startProcessingImage
                        ? I18n.t('analysis.highResImage.generatingImages')
                        : I18n.t('analysis.dialog.topic.SelectChartsToExport')
                }
                helpText={
                     <><p>{
                         I18n.t("analysis.download.report.keepInMind3")
                         .replace(/%department-max%/g, RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY.department -1 )
                     }<br />{
                         I18n.t("analysis.download.report.keepInMind4")
                         .replace(/role-max/g, RESTRICTIONS.OrgScan.Report.REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY.role -1 )
                     }
                     </p>
                         <p>{ startProcessingImage
                            ? I18n.t('analysis.highResImage.generatingImagesHelpText')
                            : I18n.t('analysis.dialog.topic.helpText')
                       }</p>
                    </>
                }
                footerContent={
                    <ModalFooterContainer>
                        {startProcessingImage && (
                            <Div marginBottom={'-20px'} width={'100%'}>
                                <ProgressBar
                                    now={((currentImageIndex + 1) / selectedChart.length) * 100}
                                    label={progressBarStatus}
                                />
                            </Div>
                        )}
                        <ModalFooterLeft>
                            {selectedChart.length} / {REPORT_CHARTS.length}{' '}
                            {I18n.t('analysis.dialog.topic.selected')}
                        </ModalFooterLeft>
                        <ModalFooterRight>
                            <ButtonSecondary type="button" onClick={onModalClose}>
                                {I18n.t('analysis.buttons.cancel')}
                            </ButtonSecondary>
                            {!startProcessingImage && (
                                <ButtonPrimary
                                    onClick={() => {
                                        setSelectedChart(REPORT_CHARTS)
                                        setStartProcessingImage(true)
                                    }}
                                >
                                    {I18n.t('analysis.buttons.GenerateAll')}
                                </ButtonPrimary>
                            )}
                            {!startProcessingImage && selectedChart.length > 0 && (
                                <ButtonPrimary
                                    onClick={() => {
                                        setStartProcessingImage(true)
                                    }}
                                >
                                    {I18n.t('analysis.buttons.GenerateSelected')}
                                </ButtonPrimary>
                            )}
                        </ModalFooterRight>
                    </ModalFooterContainer>
                }
            >
                <GenerateHighResImage
                    startProcessingImage={startProcessingImage}
                    cancelGeneration={() => setStartProcessingImage(false)}
                    imageGenerationsCompleted={() => setStartProcessingImage(false)}
                    setProgressBarStatus={setProgressBarStatus}
                    setCurrentImageIndex={setCurrentImageIndex}
                    currentImageIndex={currentImageIndex}
                    selectedChart={selectedChart}
                />
                {
                    ! startProcessingImage && (
                    <Container>
                        <Content>
                            <Row>
                                {
                                    CHART_SECTIONS
                                        .map( sec => <Section
                                            key={sec.title}
                                            title={ sec.title }
                                            REPORT_CHARTS_SORTED={REPORT_CHARTS_SORTED}
                                            isAllSelected={isAllSelected}
                                            selectAll={selectAll}
                                            selectedChart={selectedChart}
                                            handleItemSelect={handleItemSelect}
                                            ChartTypes={ sec.types } />)
                                }
                            </Row>
                        </Content>
                    </Container>
                )}
            </Modal>
            <ButtonSecondary
                onClick={() => {
                    Actions.setSelectedPoints({})
                    setOpen(true)
                    setStartProcessingImage(false)
                }}
            >
                {I18n.t('analysis.buttons.GenerateImages')}
            </ButtonSecondary>
        </>
    )
}

const Section = ({title, REPORT_CHARTS_SORTED, isAllSelected, selectAll, selectedChart, handleItemSelect, ChartTypes}) => {

    return <RowItem>
            <H3>
                <CheckboxItem
                    item={{title: title}}
                    checked={isAllSelected( ChartTypes )}
                    handleItemSelect={() => {
                        selectAll( ChartTypes )
                    }}
                />
            </H3>
            <RowItemContent>
                {
                    REPORT_CHARTS_SORTED
                        .filter( c => ChartTypes.includes( c.Type ) )
                        .map( (item, i) => (
                            <CheckboxItem
                                key={i}
                                item={item}
                                checked={selectedChart.some(
                                    (it) => it.testId === item.testId,
                                )}
                                handleItemSelect={handleItemSelect}
                            />
                        )
                    )
                }
            </RowItemContent>
        </RowItem>
}


export default React.memo(GenerateHighResImageModal)
