import React, {useEffect, useState} from 'react'

import {createErrorClass} from '../../../core/utils/html'
import DimensionsContainer from './DimensionsContainer'
import {DimensionImg, DimensionRow, Dimensions, DimensionTitle} from './Dimension.styles'
import { InfoLabel } from '../Shared.styles'
import ErrorOutput from '../ErrorOutput'
import leaderTypeIcon from "../../../img/Capture/ic-leader-type@unknown.png"
import orientationIcon from "../../../img/Capture/ic-orientation@unknown.png"
import effectivenessIcon from "../../../img/Capture/ic-effectiveness@unknown.png"
import valueDriversIcon from "../../../img/Capture/ic-value-drivers@unknown.png"
import {I18n} from "react-i18nify";
import {Panel} from "../../../core/components/BootstrapWrapper";

const icons = {
    'leader-type': leaderTypeIcon,
    'orientation': orientationIcon,
    'effectiveness': effectivenessIcon,
    'value-drivers': valueDriversIcon
}

const translateText = (text) => {
    try {
        return I18n.t(text)
    } catch (e){
        return text;
    }
}

const Stones = (props) => {
    const [competingValues, setCompetingValues] = useState(props.competingValues)
    const [errors, setErrors] = useState(null)

    const allDragged = () => competingValues.filter((item) => item.x === 0 && item.y === 0).length === 0
    
    const validateInput = () => {
        if (props.required) {
            if(!allDragged())
                setErrors({required: props.requiredErrorMessage})
        } else {
            setErrors(null)
        }
    }

    useEffect(() => {
        props.onPositionChange && props.onPositionChange(competingValues)
    }, [competingValues])

    useEffect(() => {
        if (props.shouldValidate) {
            validateInput()
        }
    }, [props.shouldValidate])

    useEffect(() => {
        if (errors)
            validateInput()
    }, [I18n._localeKey])


    useEffect(() => {
        if(errors) {
            props.onError && props.onError(props.fieldName)
        }
    }, [errors]);


    return (
        <>
            {props.valuesIntro && <InfoLabel>{props.valuesIntro}</InfoLabel>}
            <Dimensions className="clearfix row">
                {props.competingValues.map((dimension) => {
                    const imgSource = dimension.imageUrl || icons[dimension.name]
                    return (
                        <DimensionRow
                            lg={3}
                            xs={6}
                            md={3}
                            key={dimension.name}
                            data-testid="Stones-info"
                        >
                            <div>
                                <DimensionImg
                                    alt={dimension.name}
                                    src={imgSource}
                                />
                            </div>
                            <div>
                                <DimensionTitle>
                                    {translateText(dimension.value)}
                                </DimensionTitle>
                                <div>
                                    {translateText(dimension.description)}
                                </div>
                            </div>
                        </DimensionRow>
                    )
                })}
            </Dimensions>
            {props.graphIntro && <InfoLabel>{props.graphIntro}</InfoLabel>}
            <Panel className={props.errors ? createErrorClass('competingValues', props.errors) : createErrorClass('competingValues', errors, true)}>
                <DimensionsContainer
                    hasError={props.errors && props.errors.includes('competingValues') || errors}
                    competingValues={competingValues}
                    onPositionChange={(values) => {
                        setCompetingValues(values)
                        setErrors(null)
                    }}
                    shouldResetPosition={props.shouldResetPosition}
                    allDragged={allDragged()}
                />
                <ErrorOutput errors={props.errors || errors}/>
            </Panel>
        </>
    )
}

export default Stones
