import React from 'react'
import {I18n} from 'react-i18nify'
import { SideBarTitle } from '../../core/ui/Sidebar.styles';
import {SwitchWithLabel} from "../../core/components/Switch";
import SelectWrapper from '../../core/components/SelectWrapper';
import { FormLabel } from 'react-bootstrap';
import { LEADERSHIP_ASSESSMENT_EMAIL_TYPES } from '../../core/const';

const Notification = (props) => {
    const options = LEADERSHIP_ASSESSMENT_EMAIL_TYPES.map( (t) => ({label: I18n.t(`profile.Label.${t}`), value: t}) )

    return (
        <div>
            <SideBarTitle>{I18n.t('profile.Label.Notifications')}</SideBarTitle>
            <SwitchWithLabel label={I18n.t('profile.Label.StrategyMapNotifications')} defaultValue={props.user.allow_strategy_map_notifications} onChange={props.onNotificationsChange} />
            <br />
            <FormLabel>{I18n.t('profile.Label.LeadershipAssessmentNotifications')}</FormLabel>
            <SelectWrapper
                onChange={ (e) => 
                            props.onLeadershipAssessmentNotificationTypeChange(e.value) }
                value={ options.find( (et) => et.value === props.user.leadership_assessment_email_type) }
                options={ options }
            />
            <div style={{clear: 'both'}}/>
        </div>
    )
}

export default Notification
