import {curry, eqBy, findIndex, prop, unionWith, update} from 'ramda'

export const updateWhen = curry((x, pred, xs) => {
    const index = findIndex(pred, xs)
    return update(index, x, xs)
})

// merge list of objects, replacing those with same pk value
export const joinTables = curry((pk, xs, ys) => {
    // switch xs and ys so that the newer entries override the oldest
    return unionWith(eqBy(prop(pk)), ys, xs)
})

export const sortByField = (objects, field) => {
    return objects.sort(function (objectA, objectB) {
        return objectA[field] > objectB[field]
            ? 1
            : objectB[field] > objectA[field]
            ? -1
            : 0
    })
}