import React from 'react';
import {MID_GREY} from '../../core/ui/_colors';
import {ArchetypeCircle, ArchetypeScore, ArchetypeTitle, ArchetypeIcon} from './Dashboard.styles';
import {Pie, PieChart} from "recharts";
import { moodColorMap } from '../../Charts/ChartColor';

const ArchetypeStat = (props) => {

    if( ! props.data )
        return null;

    const MINIMUM_SIZE = 180;
    const BASE_FONT_SIZE = 16;
    const settings = {
        data: props.data,
        icon: props.icon,
        title: props.title,
        fontSize: BASE_FONT_SIZE,
        size: MINIMUM_SIZE
    };

    if( props.showWithBars )
        return <ArchetypeStatWithBars settings={settings} />;
    else
        return <ArchetypeStatNormal settings={settings} />;
}
const ArchetypeStatNormal = ({settings}) =>
    <ArchetypeCircle size={settings.size}>
        <ArchetypeTitle fontSize={settings.fontSize}>{settings.title}</ArchetypeTitle>
        <ArchetypeIcon src={settings.icon} alt="icon" />
        {settings.data && (
            <ArchetypeScore>
                <span style={{color: moodColorMap.positive}}>{settings.data.positive}</span> /
                <span style={{color: MID_GREY}}>{settings.data.neutral}</span> /
                <span style={{color: moodColorMap.negative}}>{settings.data.negative}</span>
            </ArchetypeScore>
        )}
    </ArchetypeCircle>;

const ArchetypeStatWithBars = ({settings}) => {

    const data = [{name: 'Group A', value: 70}];

    const PIE_CHART_SETTINGS = {
        THICKNESS: 20,
        OUTER_RADIUS: settings.size / 2,
        INNER_RADIUS: settings.size / 2 - 20,
        GRAD_GAP_FOR_TITLE: 90
    };
    const grad = () => ({
        positive: settings.data.positive / (settings.data.negative + settings.data.positive + settings.data.neutral ) * (360 - PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE),
        negative: settings.data.negative / (settings.data.negative + settings.data.positive + settings.data.neutral ) * (360 - PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE)
    });

    return <>
            <ArchetypeCircle size={settings.size} style={{backgroundColor: 'transparent', boxShadow: 'None'}}>
                <PieChart width={settings.size} height={settings.size} style={{position: "absolute"}}>
                  <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={270 - PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE/2} endAngle={270 - (360 - PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE/2) } innerRadius={PIE_CHART_SETTINGS.INNER_RADIUS + 3} outerRadius={PIE_CHART_SETTINGS.OUTER_RADIUS - 3} fill={"lightgray"} />
                  <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={270 - PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE/2} endAngle={270 - PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE/2 - grad().positive } innerRadius={PIE_CHART_SETTINGS.INNER_RADIUS} outerRadius={PIE_CHART_SETTINGS.OUTER_RADIUS} fill={moodColorMap.positive} />
                  <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={-90 + PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE/2} endAngle={-90 + PIE_CHART_SETTINGS.GRAD_GAP_FOR_TITLE/2 + grad().negative } innerRadius={PIE_CHART_SETTINGS.INNER_RADIUS} outerRadius={PIE_CHART_SETTINGS.OUTER_RADIUS} fill={moodColorMap.negative} />
                </PieChart>
                <ArchetypeIcon style={{marginTop: 30}} src={settings.icon} alt="icon" />
                {settings.data && (
                    <ArchetypeScore>
                        <span style={{color: moodColorMap.positive}}>{settings.data.positive}</span> /
                        <span style={{color: MID_GREY}}>{settings.data.neutral}</span> /
                        <span style={{color: moodColorMap.negative}}>{settings.data.negative}</span>
                    </ArchetypeScore>
                )}
                <ArchetypeTitle style={{marginTop: 20}} fontSize={settings.fontSize}>{settings.title}</ArchetypeTitle>
            </ArchetypeCircle>
        </>
}

export default ArchetypeStat;
