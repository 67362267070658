import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import HeadingWithActionAndDropdown from './Components/HeadingWithActionAndDropdown'
import Checkbox from '../capture/signifier/Checkbox'
import Notification from './Notification'
import {MENU_ITEM_LINK} from '../sidebars/Components/DropdownMenu'
import {ButtonPrimary, IconButton} from '../core/components/Buttons'
import {CheckboxContainer, NotificationItem, NotificationNavTools} from './SidebarNotificationContainer.styles'
import {_SideBarContent_, SidebarFooter, SidebarFooterHeight, SidebarHeader, SidebarTabs, SideBar} from '../core/ui/Sidebar.styles'
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {useStrategyMapSlice} from "../strategyMap/hooks/useStrategyMapSlice"
import {useNotificationSlice} from "../strategyMap/hooks/useNotificationSlice";


export const SidebarNotificationContainer = () => {

    const {results: notificationResult, Actions} = useNotificationSlice();
    const CoreActions = useCoreSlice().Actions;

    const [isSelecting, setIsSelecting] = useState(false);
    const [notifications, setNotifications] = useState(notificationResult || [] );
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [isAnySelected, setIsAnySelected] = useState(false);
    const {goal, nodeMap} = useStrategyMapSlice();
    const [notificationNode, setNotificationNode] = useState(null);


    useEffect(() => {
        const nodeInCorrectStrategyMap = notificationNode && notificationNode.goal === goal.id;
        const nodeMapPopulated = notificationNode && Object.keys(nodeMap).includes(notificationNode.id.toString());
        if (nodeInCorrectStrategyMap && nodeMapPopulated) {
            Actions.markNotificationAsRead({notifications: [notificationNode.notificationId]});
            setNotificationNode(null);
        }
    }, [goal, notificationNode, nodeMap]);


    useEffect(() => {
        if( ! notificationResult.length )
            Actions.fetchNotifications();
    }, []);

    useEffect(() => {
        if( notificationResult )
            setNotifications( notificationResult );
    }, [ notificationResult ])

    const handleNotificationClick = (node, notificationId) => {
        setNotificationNode( {...node, notificationId} );
        CoreActions.showStrategyMapNode( node );
    }

    const handleSelectedItemsClick = () => {
        const selected = []
        notifications.forEach((notification) => {
            if (notification.isSelected) {
                selected.push(notification.id)
            }
        })
        setIsSelecting(false)
        return selected
    }

    const markAsReadSelectedNotifications = () => {
        const selected = handleSelectedItemsClick()
        Actions.markNotificationAsRead({notifications: selected})
    }

    const deleteSelectedNotifications = () => {
        const selected = handleSelectedItemsClick()
        Actions.markNotificationAsDeleted({notifications: selected})
    }

    useEffect(() => {
        const isSelectAll = notifications.every((notification) => notification.isSelected === true)
        setIsSelectedAll(isSelectAll)
        const isAnyNotificationSelected = notifications.some(
            (notification) => notification.isSelected === true,
        )
        setIsAnySelected(isAnyNotificationSelected)
    }, [notifications])

    const selectAllNotifications = (selectAll) => {
        setIsSelectedAll(selectAll)
        notifications.forEach((notification) => (notification.isSelected = selectAll))
        setNotifications([...notifications])
    }

    const selectItem = (notification, index) => {
        const newNotification = {
            ...notification,
            isSelected: !notification.isSelected,
        }
        notifications[index] = newNotification
        setNotifications([...notifications])
    }

    const handleNotificationCancel = () => {
        setIsSelecting(false)
        selectAllNotifications(false)
    }

    const showNotification = (notification, index) => {
        const isRead = notification.is_read
        const notificationObject = (
            <Notification
                key={notification.id}
                id={notification.id}
                notification={notification}
                clickCallback={handleNotificationClick}
            />
        )
        if (isSelecting) {
            return (
                <NotificationItem key={notification.id} className={'row'} isRead={isRead}>
                    <CheckboxContainer className="col-md-1">
                        <Checkbox
                            testId={notification.id}
                            checked={notification.isSelected === true}
                            onClick={() => selectItem(notification, index)}
                        />
                    </CheckboxContainer>
                    <div className="col-md-10">{notificationObject}</div>
                </NotificationItem>
            )
        } else {
            return (
                <NotificationItem key={notification.id} className={'row'} isRead={isRead}>
                    <div className={'col-md-11'}>{notificationObject}</div>
                </NotificationItem>
            )
        }
    }

    const submenu = [
        {
            action: 'deleteNotifications',
            active: true,
            title: I18n.t('sidebar.notification.select2delete'),
            type: MENU_ITEM_LINK,
            actionHandler: () => setIsSelecting(true),
        },
    ]

    return (
            <SideBar
                id="notification-sidebar"
                className="show-sidebar"
                data-testid={'notification-sidebar'}
            >
                <SidebarTabs style={{height: 'calc(100% - '+(isSelecting ? SidebarFooterHeight : 0)+'px)'}}>
                    <SidebarHeader>
                        <HeadingWithActionAndDropdown
                            className="padding-bottom"
                            title={I18n.t('strategyMap.nodes.notifications')}
                            icon="clear"
                            clickCallback={CoreActions.closeSidebar}
                            dropdown={submenu}
                            testId={'notification-more-menu-toggle'}
                        />
                    </SidebarHeader>
                    <_SideBarContent_ >
                        {isSelecting && (
                            <NotificationNavTools>
                                <Checkbox
                                    testId={'all'}
                                    disabled={!notifications.length}
                                    checked={isSelectedAll}
                                    onClick={() => selectAllNotifications(!isSelectedAll)}
                                />
                                <div style={{marginLeft: 'auto'}}>
                                    <IconButton onClick={handleNotificationCancel} icon="clear" />
                                </div>
                            </NotificationNavTools>)}
                        {notifications.map((notification, i) => showNotification(notification, i))}
                        {notifications.length === 0 && (<p>{I18n.t('strategyMap.messages.noNotification')}</p>)}
                    </_SideBarContent_>
                    {isSelecting && (
                        <SidebarFooter items={2}>
                            <ButtonPrimary
                                disabled={!isAnySelected}
                                onClick={markAsReadSelectedNotifications}
                            >
                                {I18n.t('sidebar.notification.markAsRead')}
                            </ButtonPrimary>
                            <ButtonPrimary
                                disabled={!isAnySelected}
                                data-testid={'notifications-delete'}
                                onClick={deleteSelectedNotifications}
                            >
                                {I18n.t('sidebar.notification.delete')}
                            </ButtonPrimary>
                        </SidebarFooter>
                    )}
            </SidebarTabs>
            </SideBar>
    )
}
