import React from 'react'
import {I18n} from 'react-i18nify'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import moment from 'moment';
import { Div } from '../../core/ui/Div';

const DaysCounter = () => {
    const {client} = useCoreSlice()
    
    if (!client.end_date) return null
    
    const daysLeft = moment( client.end_date ).diff( moment(), 'days')
    
    return (
        <Div fontSize="20px" margin="20px 0">
            {I18n.t('charts.daysRemaining').replace(':days:', daysLeft)}
        </Div>
    )
}

export default DaysCounter
