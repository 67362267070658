import styled from 'styled-components'
import {DARK_GREY, GREY, WHITE} from '../core/ui/_colors'
import {Col, Row} from 'react-bootstrap'
import {isEdge, isIE} from 'react-device-detect'

export const LoginContainer = styled.div`
    height: ${isIE || isEdge ? '100%' : '100vh'};
    background-color: ${WHITE};
    display: flex;
    justify-content: center;
`

export const FormContainer = styled.div`
    width: 360px;
    padding-top: 60px;
`

export const Title = styled(Col)`
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 8px;
`

export const Info = styled(Col)`
    text-align: center;
    margin-bottom: 30px;
`

export const LogoImg = styled.img`
    width: 120px;
    margin: 15px auto;
    display: block;
`

export const LanguageContainer = styled(Row)`
    margin-top: 10px;
    position: absolute;
    right: 25px;
    .language-list {
        width: 292px;
        font-size: 18px;
        &:focus {
            border: none;
        }
    }
`

export const FormWrapper = styled(Col)`
    width: 320px;
    margin-left: 20px;
    &.password {
        padding-top: 30px;
        p {
            margin-bottom: 20px;
        }
    }
    &.initial-password {
        padding-top: 30px;
        .form-group {
            margin-top: 20px;
        }
    }
    input {
        border: 1px solid ${GREY};
        height: 40px;
    }
`

export const Links = styled.div`
    margin-top: 40px;
    display: flex;
    .material-icons {
        float: left;
    }
`

export const Link = styled.a`
    font-weight: bold;
    color: ${DARK_GREY};
`