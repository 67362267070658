import React from 'react'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {I18n} from 'react-i18nify'
import styled from 'styled-components'
import {BLACK_GREY, PALE_GREY} from '../../core/ui/_colors'
import HeadingWithAction from './HeadingWithAction'
import {useNode} from '../../strategyMap/hooks/useNode'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IconButton} from '../../core/components/Buttons'
import {showPopupMessage} from '../../core/utils/notifications'
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import { windowOpen } from '../../core/utils/navigation'

const IntegrationItem = styled.div`
    cursor: pointer;
    padding: 4px 4px 4px 8px;
    margin-bottom: 8px;
    color: ${BLACK_GREY};
    display: flex;
    &:hover {
        color: ${BLACK_GREY};
        background: ${PALE_GREY};
        .show-hover {
            display: block;
        }
    }
`

const IntegrationText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px 5px;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
`

const getConnectionDescription = (integration) =>
    integration.tool === 'slack'
        ? I18n.t('strategyMap.nodes.slackChannel')
        : integration.description

const NodeIntegrations = () => {
    const {currentOpenNodeId, Actions} = useStrategyMapSlice()
    const {isUserChampionOrSquadMember, isUserSponsor, node} = useNode()
    const integrations = (node && node.node_integrations) || []
    const isUserAbleToRemoveIntegration = isUserChampionOrSquadMember || isUserSponsor
    const {client} = useCoreSlice()

    const handleLinkDelete = (integrationID) => {
        const warning = {
            title: I18n.t('strategyMap.messages.warning'),
            message: I18n.t('strategyMap.messages.IntegrationLinkDeleteWarning'),
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.messages.delete'),
            onConfirmCallback: () =>
                Actions.removeIntegrationLink([currentOpenNodeId, integrationID]),
            onCancelCallback: () => {},
        }
        showPopupMessage(warning)
    }

    const displayWarningConnection = (integrationId) => {
        return (
            <OverlayTrigger
                key={integrationId}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-${'top'}`} style={{opacity: 1}}>
                        <b>{I18n.t('strategyMap.messages.integrationBrokenOrRemoved')}</b>
                    </Tooltip>
                }
            >
                <IconButton icon="warning" />
            </OverlayTrigger>
        )
    }

    return (
        <div>
            {integrations.length > 0 && (
                <>
                    <HeadingWithAction title={I18n.t('strategyMap.nodes.integrations')} />
                    {integrations.map((integration) => (
                        <IntegrationItem key={integration.id}>
                            {!client[integration.tool + '_access_token'] &&
                                displayWarningConnection(integration.id)}
                            <IntegrationText onClick={() => windowOpen(integration.url)}>
                                {getConnectionDescription(integration)}
                            </IntegrationText>
                            {isUserAbleToRemoveIntegration && (
                                <IconButton
                                    displayBlock={false}
                                    icon="clear"
                                    onClick={() => handleLinkDelete(integration.id)}
                                />
                            )}
                        </IntegrationItem>
                    ))}
                </>
            )}
        </div>
    )
}

export default NodeIntegrations
