import Tour from "reactour";
import React, {useEffect} from "react";
import stories from "./Stories";
import {I18n} from "react-i18nify";
import {SIDEBARS} from "../core/redux/slices";
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import {useUserSlice} from "../strategyMap/hooks/useUserSlice";
import { DASHBOARD_OPTIONS } from "../core/const";

const Tutorials = () => {

    const {helpTour, sidebarOpen, client, Actions: CoreActions} = useCoreSlice();
    const {email, full_name, Actions} = useUserSlice();
    const isDashboardFull = client.dashboard_option === DASHBOARD_OPTIONS.full;

    const openUserProfileSidebar = () => {
        if( sidebarOpen !== SIDEBARS.UserProfile )
            CoreActions.toggleSidebarVisibility(SIDEBARS.UserProfile);
    }
    const closeSidebar = () => {
        if( sidebarOpen !== SIDEBARS.Node )
            CoreActions.closeSidebar();
    }
    useEffect( () => {}, [helpTour, I18n._localKey]);

    const getStorySteps = (helpTour) => {
        if( ! helpTour )
            return [];

        const sidebarProps = {sidebar: {openUserProfileSidebar: openUserProfileSidebar, closeSidebar: closeSidebar}, client: client, CoreActions, email, full_name, isDashboardFull, Actions};
        let story = stories(sidebarProps);
        helpTour.split('.').forEach( key =>  story = story[key]);

        return story.steps.filter( steps => ! steps.hasOwnProperty('required') || steps.required );
    }

    return (
        <Tour
            badgeContent={(curr, tot) => `${curr} ${I18n.t("stories.help.of")} ${tot}`}
            steps={ getStorySteps( helpTour ) }
            isOpen={!!helpTour}
            startAt={0}
            onRequestClose={() => CoreActions.startHelpTour(false)} />)
};

export default Tutorials;