import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useSearch} from '../core/hooks/useSearch'
import {includesAny} from '../core/utils/string'
import {any, both} from 'ramda'
import {isNotNil} from 'ramda-adjunct'
import Dropzone from 'react-dropzone'
import UserTable from './UserTable'
import ActionInput from './ActionInput'
import {I18n} from 'react-i18nify'
import {FullScreenLoading} from '../core/components/Loading'
import {ButtonSecondary} from '../core/components/Buttons'
import {GREY, PALE_GREY} from '../core/ui/_colors'
import {showPopupMessage} from '../core/utils/notifications'
import {TRANSLATIONS_MAP} from '../core/utils/language'
import { SearchIcon, SearchContainer, SearchInput } from '../core/ui/modal/Modal'
import { Panel } from '../core/components/BootstrapWrapper'
import {RESTRICTIONS} from "../core/const";
import { useCoreSlice } from "../core/hooks/useCoreSlice";
import { useUserSlice } from '../strategyMap/hooks/useUserSlice'
import SelectWrapper from '../core/components/SelectWrapper'
import { useDemographicsSlice } from '../core/hooks/useDemographicsSlice'
import { Flex } from '../core/ui/Flex'

const HeaderContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`
export const FileContainer = styled.div`
    height: 140px;
    width: 100%;
    background: ${PALE_GREY};
    border: dotted 2px ${GREY};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
`
const H3 = styled.h3`
    font-size: 20px;
    font-weight: bold;
    flex: 1;
`

const SearchField = ({onChange}) => (
    <SearchContainer>
        <SearchIcon />
        <SearchInput
            onChange={onChange}
            placeholder={I18n.t('billing.Label.FindAPerson')}
            data-testid="search-input"
        />
    </SearchContainer>
)

const matchSearch = (u, s) => {
    return any(both(isNotNil, includesAny(s)), [
        u.email,
        u.first_name,
        u.first_name,
        u.last_name,
        u.last_name,
        u.initials,
    ])
}

const UserManagement = () => {
    const {client} = useCoreSlice()
    const {Actions: DemographicsActions, department} = useDemographicsSlice()
    const {users, isFetchingUsers, isSaving, Actions: UserAction} = useUserSlice()
    const [searchedUsers, handleSearchChange] = useSearch(users, matchSearch)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [showUploadForm, setShowUploadForm] = useState(false)
    const [addUser, setAddUser] = useState(false)
    const {features} = useCoreSlice();

    useEffect(() => {
        UserAction.fetchUsers();
        DemographicsActions.fetchDemographics();
    }, [])

    useEffect(() => {
        setAddUser(false);
    }, [users])

    const makeLeadershipAdminChecks = () => {
        const UserAmountWithAdminRights =
            users.filter( user => user.is_admin_for_leadership ).length
            + selectedUsers.filter( user => ! user.is_admin_for_leadership).length;

        const maxAdminsAllowed = features["leadership.full"]
            ? RESTRICTIONS.Leadership.MAX_ADMINS_ALLOWED.FULL_VERSION
            : RESTRICTIONS.Leadership.MAX_ADMINS_ALLOWED.TEAM_VERSION;

        if( (features["leadership.team"] || features["leadership.full"])
            && UserAmountWithAdminRights >= maxAdminsAllowed ) {
            showPopupMessage({
                title: I18n.t('strategyMap.messages.warning'),
                message: I18n.t('billing.Warning.MakeAdminLeaderShipLimit').replace( "%count%", maxAdminsAllowed ),
                confirmLabel: I18n.t('strategyMap.messages.ok')
            });
            return false;
        }

        return true;
    }

    const showActionPopUp = (action) => {
        const mappedUsers = selectedUsers.map((user) => user.pk);
        let message = '';
        let updateType = '';
        
        switch (action) {
            case 'deactivate':
                message = I18n.t('billing.Warning.UserDeactivate');
                updateType = 'deactivateUsers';
                break;
            case 'reActivate':
                message = I18n.t('billing.Warning.UserActivate');
                updateType = 'activateUsers';
                break;
            case 'resendInvitation':
                message = I18n.t('billing.Warning.ResendInvite');
                updateType = 'resendInvitation';
                break;
            case 'makeStrategist':
                message = I18n.t('billing.Warning.MakeStrategist');
                updateType = 'makeUserStrategist';
                break;
            case 'revokeStrategist':
                message = I18n.t('billing.Warning.RevokeStrategist');
                updateType = 'revokeUserStrategist';
                break;
            case 'makeAdmin':
                message = I18n.t('billing.Warning.MakeAdmin');
                updateType = 'makeUserAdmin';
                break;
            case 'revokeAdmin':
                message = I18n.t('billing.Warning.RevokeAdmin');
                updateType = 'revokeUserAdmin';
                break;
            case 'makeAdminLeadership':
                const checkedStatus = makeLeadershipAdminChecks();
                if (!checkedStatus) return;
                message = I18n.t('billing.Warning.MakeAdminLeaderShip');
                updateType = 'makeUserAdminLeadership';
                break;
            case 'revokeAdminLeadership':
                message = I18n.t('billing.Warning.RevokeAdmin');
                updateType = 'revokeUserAdminLeadership';
                break;
            case 'makeAnalyst':
                message = I18n.t('billing.Warning.MakeAnalyst');
                updateType = 'makeUserAnalyst';
                break;
            case 'revokeAnalyst':
                message = I18n.t('billing.Warning.RevokeAnalyst');
                updateType = 'revokeUserAnalyst';
                break;
        }

        showPopupMessage({
            title: I18n.t('strategyMap.messages.warning'),
            message: message,
            cancelLabel: I18n.t('strategyMap.messages.cancel'),
            confirmLabel: I18n.t('strategyMap.messages.yes'),
            onCancelCallback: () => {},
            onConfirmCallback: () => UserAction.updateUsers({users: mappedUsers, updateType: updateType})
        })
    }

    const handleActionClick = (item) => {
        if( item.value )
            showActionPopUp(item.value);
    }

    const deparmentOptions = department ? 
                                department
                                    .filter( d => !d.archived )
                                    .map(d => ({value: d.pk, label: d.name})) : 
                                [];

    return (
        <Panel>
            <FullScreenLoading loading={isFetchingUsers || isSaving} />
            <HeaderContainer>
                <H3>
                    {I18n.t('billing.Label.UserStatInfo')
                        .replace('{total_billable_users}', client.billable_users_count)
                        .replace('{total_users}', searchedUsers.length)}
                </H3>
                {!showUploadForm && (
                    <ButtonSecondary
                        noSideMrg
                        onClick={() => setAddUser(!addUser)}
                    >
                        {addUser
                            ? I18n.t('strategyMap.messages.cancel')
                            : `+ ${I18n.t('billing.Label.AddUser')}`}
                    </ButtonSecondary>
                )}
                <ButtonSecondary
                    style={{margin: '16px 0', marginLeft: 10}}
                    onClick={() => {
                        setShowUploadForm(!showUploadForm)
                    }}
                >
                    {showUploadForm
                        ? I18n.t('strategyMap.messages.cancel')
                        : `+ ${I18n.t('billing.Label.UploadUser')}`}
                </ButtonSecondary>
            </HeaderContainer>
            {showUploadForm && (
                <div>
                    <p>{I18n.t('billing.UploadInfo.Instructions')}</p>
                    <p>{I18n.t('billing.UploadInfo.FileLength')}</p>
                    <p>{I18n.t('billing.UploadInfo.AvailableLanguage')} {Object.values(TRANSLATIONS_MAP).join(', ')}</p>
                    <p>{I18n.t('billing.UploadInfo.Example')}:</p>
                    <code>
                        <p>johndoe@agile42.com, John, Doe, english</p>
                        <p>janedoe@agile42.com, Jane, Doe</p>
                    </code>
                    <p>
                        <Flex alignItems={'center'}>
                            {I18n.t("billing.demographics.department")}:&nbsp;
                            <SelectWrapper
                                onChange={ (t) => setSelectedDepartment(t.value) }
                                defaultValue={ deparmentOptions.length ? deparmentOptions[0] : null }
                                options={ deparmentOptions }
                                styles={{ input: () => ( { width: 150 } ) }}
                            />
                        </Flex>
                    </p>
                    <div>
                        <Dropzone
                            multiple={false}
                            onDrop={(files) => {
                                if (!files[0].name.endsWith('.csv')) {
                                    showPopupMessage({
                                        title: I18n.t('strategyMap.messages.warning'),
                                        message: I18n.t('billing.Warning.WrongFileFormat'),
                                    })
                                    return
                                }
                                const data = new FormData()
                                data.append('file', files[0])
                                data.append('department', selectedDepartment)
                                UserAction.bulkCreateUsers(data)
                                setShowUploadForm(false)
                                setSelectedDepartment(0)
                            }}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <FileContainer>
                                            {I18n.t('billing.UploadInfo.FileInstruction')}
                                        </FileContainer>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
            )}
            <SearchField onChange={handleSearchChange} />
            <ActionInput
                selectedUsersNumber={selectedUsers.length}
                totalUsersNumber={searchedUsers.length}
                onActionClick={handleActionClick}
            />
            <UserTable
                addUser={addUser}
                users={searchedUsers}
                setSelectedUsers={setSelectedUsers}
            />
        </Panel>
    )
}

export default UserManagement