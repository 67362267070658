
export const ChartType = {
    BarStacked: 'BarStacked',
    BarStackedHorizontal: 'BarStackedHorizontal',
    HeatMapReactangle: 'HeatMapReactangle',
    BarVertical: 'BarVertical',
    BarHorizontal: 'BarHorizontal',
    CrossProduct: 'CrossProduct',
    ExperimentActivity: 'ExperimentActivity',
    CircleProgress: 'CircleProgress',
    BarHorizontalCustom: 'BarHorizontalCustom',
    Triangle: 'Triangle',
    Statistics: 'Statistics',
    HeatMapCrossProduct: 'HeatMapCrossProduct',
    PieChart: 'PieChart',
    AreaChart: 'AreaChart',
    GroupedBar: 'GroupedBar'
}

export const SubTypes = {
    culture: {
        leaderType: 'leader-type',
        orientation: 'orientation',
        effectiveness: 'effectiveness',
        valueDrivers: 'value-drivers',
    },
    triangle: {
        T1: '1',
        T2: '2',
        T3: '3',
        T4: '4',
        T5: '5',
        T6: '6',
    },
    bar: {
        decisionOwner: 'decision_owner',
        type: 'type',
        period: 'period',
        communicationMethod: 'communication_method',
        mood: 'mood',
        frequency: 'frequency',
        department: 'department',
        role: 'role',
        year: 'years',
        city: 'city',
        country: 'country',
        headcount: 'headcount'
    },
    circleProgress: {
        affinity: 'department',
        capturedDecisions: 'captured_decisions',
    },
    statistics: {
        stonesAverage: "cultural_profile",
        trianglesAverage: "triangles",
        emotionByQuestion: "emotionByQuestion",
        mostInfluencedValues: "mostInfluencedValues"
    },
    special: {
        compromise: 'compromise',
    },
}

export const ChartColumns = {
    special: {
        compromise: {
            relationship: 'relationship',
            relationship_vs_resource: 'relationship_vs_resource',
            relationship_vs_time: 'relationship_vs_time',
            resources: 'resources',
            resources_vs_time: 'resources_vs_time',
            time: 'time',
            trade_of_all_three: 'trade_of_all_three',
        },
    },
}

export const TriangleConfig = {
    Corners: {
        top: 'top',
        bottom: 'bottom',
        left: 'left',
        right: 'right',
        bottomLeft: 'bottomLeft',
        bottomRight: 'bottomRight',
        center: 'center'
    },
}

export const ordering = [
    "charts.Answers.me",
    "charts.Answers.manager",
    "charts.Answers.team",
    "charts.Answers.groupofpeople",
    "charts.Answers.external",
    "charts.Answers.colleague",
    "charts.Answers.often",
    "charts.Answers.rarely",
    "charts.Answers.allthetime",
    "charts.Answers.nowandthen",
    "charts.Answers.noformalconversation",
    "charts.Answers.announcement",
    "charts.Answers.tool",
    "charts.Answers.oneonone",
    "charts.Answers.nocommunication",
    "charts.Answers.rightaway",
    "charts.Answers.hour",
    "charts.Answers.day",
    "charts.Answers.week",
    "charts.Answers.month",
    "charts.Answers.forever",
    "charts.Answers.tactical",
    "charts.Answers.strategic",
    "charts.Answers.positive",
    "charts.Answers.negative",
    "charts.Answers.neutral"
];