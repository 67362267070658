import React from "react"
import styled from "styled-components"
import 'react-dates/lib/css/_datepicker.css'
import {SingleDatePicker} from 'react-dates'
import {isInclusivelyAfterDay} from "../utils/date"
import moment from "moment"
import { MID_GREY } from "./_colors"

export const CalenderInputIcon = () => (
    <span style={{color: MID_GREY }} className="material-icons-outlined">calendar_month</span>
)

export const SingleDatePickerContainer = styled.div`
    .SingleDatePicker {
        margin-top: 10px;
        height: 42px;
        width: 100%;
        .SingleDatePickerInput {
            width: 100%;
            height: 42px;
            input {
                border: 0;
            }
            .SingleDatePickerInput_calendarIcon {
                float: right;
                width: 40px;
                height: 40px;
                padding: 0;
            }
            .DateInput {
                background-color: transparent;
                input {
                    background-color: transparent;
                    height: 41px;
                }
            }
        }
    }
`


export const DateInput = (props) => {
    return (
        <SingleDatePickerContainer>
            <SingleDatePicker
                placeholder={'dd/mm/yyyy'}
                displayFormat={'DD/MM/YYYY'}
                disabled={props.disabled}
                date={props.deadline ? moment(props.deadline) : null}
                isDayBlocked={(day) => !isInclusivelyAfterDay(day, moment())}
                onDateChange={props.onDateChange}
                focused={props.focused}
                onFocusChange={props.onFocusChange}
                numberOfMonths={1}
                firstDayOfWeek={1}
                customInputIcon={props.customInputIcon}
            />
        </SingleDatePickerContainer>
    )
}