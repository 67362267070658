import {createSlice} from 'redux-starter-kit'
import * as ErrorUtils from '../../core/utils/error'
import {joinTables} from '../../core/utils/array'

const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isSaving: false,
    errors: ErrorUtils.getApiErrors(action.payload),
})

export const dashboardSlice = createSlice({
    slice: `dashboard`,
    initialState: {
        isSaving: false,
        isFetching: false,
        decisions: [],
        decisionsSearch: [],
        searchByWords: "",
        decisionsCount: 0,
        selectedPoints: {},
        filters: {},
        filterToggle: {},
    },
    reducers: {
        logPageView: (state) => ({...state}),
        fetchDecisions: (state) => ({...state, isFetching: true}),
        fetchDecisionsSuccess: (state, action) => {
            state.isFetching = false;
            state.decisions = joinTables('pk', state.decisions, [...action.payload]);
            state.decisionsSearch = state.decisions;
        },
        setSearchWord : (state, action) => {
            state.searchByWords = action.payload;
            if( action.payload.length <= 0 )
                state.decisionsSearch = state.decisions;
            else
                state.decisionsSearch =
                    state.decisions
                        .filter( point => point.description.toLowerCase().includes( action.payload.toLowerCase() )
                            || point.title.toLowerCase().includes( action.payload.toLowerCase() )
                        )
        },
        setDecisionsCount: (state, action) => {
            state.decisionsCount = action.payload
        },
        setFilters: (state, action) => {
            state.filters = {
                ...state.filters,
                [action.payload.filterType]: action.payload.data,
            }
        },
        clearFilters: (state, action) => {
            if (action.payload && action.payload.filterType) {
                delete state.filters[action.payload.filterType]
            } else {
                state.filters = {}
            }
        },
        setFilterToggle: (state, action) => {
            state.filterToggle = action.payload
        },
        setSelectedPoints: (state, action) => {
            state.selectedPoints = action.payload
        },
        pushDecisionToList: (state, action) => ({
            ...state,
            decisionsCount: state.decisionsCount + 1,
            decisions: [...state.decisions, action.payload.data],
        }),
        requestFailure: errorHandler,
    },
})
