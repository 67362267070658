import React, {useState} from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import UserWithAvatar from './UserWithAvatar'
import UserListPopover from './UserListPopover'
import ClickOutside from '@tntd/react-click-outside'
import {I18n} from 'react-i18nify'
import _ from 'lodash'
import {useUserSlice} from '../hooks/useUserSlice'
import {showPopupMessage} from '../../core/utils/notifications'
import {userCanAddOrDeleteChampion} from "../utils";

const ChampionItem = ({champion, node, updateChampions, isAbleToEdit}) => {
    const {users, nodeMap} = useStrategyMapSlice()
    const [open, setOpen] = useState(false)
    const currentActiveUser = useUserSlice()
    const enableRemove =
        isAbleToEdit
        && userCanAddOrDeleteChampion( currentActiveUser, node, nodeMap );
    const championsData = node.champions_data || []
    const totalChampions = championsData.length
    const removeChampion = (user) => {
        const index = _.findIndex(championsData, {pk: user.pk})
        if (index > -1) {
            championsData.splice(index, 1)
        }
        updateChampions(championsData)
    }

    const swapChampion = (user) => {
        const index = _.findIndex(championsData, {pk: champion.pk})
        if (index > -1) {
            championsData.splice(index, 1)
        }
        championsData.push(user)
        updateChampions(championsData)
    }

    return (
        <UserWithAvatar
            testId={`champion-user-avatar-${champion.pk}`}
            user={champion}
            showAvatar
            enableRemove={ enableRemove }
            onClick={ () => enableRemove && setOpen( ! open ) }
            handleRemove={() => {
                if( totalChampions > 1 && champion.pk === currentActiveUser.pk ){
                    showPopupMessage({
                        title: I18n.t('strategyMap.messages.warning'),
                        message: I18n.t('strategyMap.messages.warningRemoveMyselfAsChampion'),
                        cancelLabel: I18n.t('strategyMap.messages.cancel'),
                        confirmLabel: I18n.t('strategyMap.messages.yes'),
                        onConfirmCallback: () => removeChampion(champion),
                        onCancelCallback: () => {}
                    });
                    return false;
                }
                removeChampion( champion );
            }}
        >
            <ClickOutside
                onClickOutside={() => {
                    open && setOpen(false)
                }}
            >
                {open && (
                    <UserListPopover
                        testId={`champion-user-list-popover-${champion.pk}`}
                        users={users.filter((user) =>
                            championsData.every((champion) => champion.pk !== user.pk),
                        )}
                        open={open}
                        handleItemClick={(user) => {
                            if (
                                user.pk !== currentActiveUser.pk &&
                                champion.pk === currentActiveUser.pk
                            ) {
                                showPopupMessage({
                                    title: I18n.t('strategyMap.messages.warning'),
                                    message: I18n.t(
                                        'strategyMap.messages.warningAddingSomeoneElseAsChampion',
                                    ),
                                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                                    confirmLabel: I18n.t('strategyMap.messages.yes'),
                                    onConfirmCallback: () => swapChampion(user),
                                    onCancelCallback: () => {},
                                })
                                return false
                            }
                            swapChampion(user)
                            setOpen(false)
                        }}
                    />
                )}
            </ClickOutside>
        </UserWithAvatar>
    )
}

export default ChampionItem
