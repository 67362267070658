import {useContext, useEffect} from 'react'
import SM_WebsocketContext from '../contexts/SM_WebsocketContext'
import AuthWebsocketContext from '../contexts/AuthWebsocketContext'

const contexts = {
    sm: SM_WebsocketContext,
    auth: AuthWebsocketContext,
}

export const useWebsocket = (cb, cxt, deps = []) => {
    const socket = useContext(contexts[cxt])

    useEffect(() => {
        socket.connect()
        return () => socket._socket.close()
    }, [])

    const restartSocketOnFailure = () => {
        setTimeout(function () {
            socket.connect()
            socket.setOnError(restartSocketOnFailure)
        }, 1000)
    }

    useEffect(() => {
        socket.setOnMessage(cb)
        socket.setOnError(restartSocketOnFailure)
    }, deps)
}
