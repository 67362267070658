import { I18n } from "react-i18nify";
import { PAGES } from "../const";
import { isLoggedIn } from "./auth";
import { showPopupMessage } from "./notifications";

export const isFreeUsage = () => {
    const freeSimplePages = [
        {
            name: "survey for leadership survey",
            exact: [`/${PAGES.LeaderShip.Capture}`],
            startWith: [`/${PAGES.LeadershipAssessment}/`, '/selfassessment']
        },
        {
            name: "replay to leadership survey",
            regex: "\/capture\/.*\/.*"
        }
    ];

    const pathname = window.location.pathname;
    const isFreeUsage = freeSimplePages
        .some( page =>
            page.exact && page.exact.includes(pathname)
            || page.startWith && page.startWith.some( a => pathname.startsWith(a))
            || page.regex && pathname.search( page.regex ) >= 0
        );

    return isFreeUsage && ! isLoggedIn();
}

export const windowOpen = (url) => {
    const popupWindow = window.open( url, '_blank' )
    if(!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') { 
        showPopupMessage({
            title: 'Warning',
            message: I18n.t('analysis.allowPopupsWarning'),
            confirmLabel: 'OK',
        })
    }
    return popupWindow;
}