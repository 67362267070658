import styled from 'styled-components'
import {
    GREY,
    LIGHT_GREY,
    MID_GREY,
    PALE_GREY,
    WHITE
} from '../../core/ui/_colors'

export const Title = styled.h2`
    height: 32px;
    font-size: 32px;
    font-weight: 500;
    text-align: left;
    line-height: 1em;
`

export const DashboardMessage = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 32px;
    margin-top: 64px;
    color: ${GREY};
`

export const DashboardNavigator = styled.div`
    margin: 0 0 40px 0;
`

export const DashboardNavItem = styled.div`
    padding: 20px 15px;
    height: 130px;
    text-align: center;
    border-right: 1px solid ${PALE_GREY};
    cursor: pointer;
    background-color: ${WHITE};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

    &:last-child {
        border-right: none;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    @media (min-width: 320px) {
        height: 156px;
    }
    @media (min-width: 768px) {
        height: 156px;
    }
    @media (min-width: 992px) {
        height: 132px;
    }
`
export const NavImg = styled.img`
    width: 40px;
    height: auto;
    margin-bottom: 5px;
    @media (min-width: 768px) {
        width: 60px;
    }
`

export const NavText = styled.div`
    font-size: 14px;
    font-weight: 700;
    @media (min-width: 320px) {
        font-size: 12px;
    }
    @media (min-width: 768px) {
        font-size: 18px;
    }
`
export const DashboardItemContainer = styled.div`    
    @keyframes zoom-in {
        0% {
            transform: scale(.1, .1);
        }
        100% {
            transform: scale(1, 1);
        }
    };
    width: ${props => props.zooming ? '1140px ! important' : '' };
    height: ${props => props.zooming ? '1000px' : '' };
    position: ${props => props.zooming ? 'absolute !important' : '' };
    max-width: ${props => props.zooming ? '1170px !important' : '' };
    top: 0px !important;
    animation: ${props => props.zooming ? "zoom-in 1.5s ease" : ''};
    @media (max-width: 768px) {
        width: 100%;
    }
`

export const DashboardTiles = styled.div`
    margin: 0;
    padding: 0;
`

export const DashboardGroup = styled.div`
    padding: 0;
    &.right {
        float: right;
    }
    font-size: 14px;
    text-align: left;
`

export const DashboardItem = styled.div`
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    padding: 5px 15px 15px 20px;
    height: ${ p => p.zoom ? '820px' : '486px'};
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${WHITE};
    display: flex;
    flex-direction: column;

    .panel-body {
        padding: 4px;
        flex-grow: 1;
        display: table;
        &.complete > div {
            display: table-cell;
            vertical-align: middle;
        }
        .recharts-wrapper {
            width: 100% !important;
            height: 100% !important;
            min-width: 250px;
            .recharts-surface {
                width: 100% !important;
                height: ${ props => props.zoom ? '600px' : '300px' };
            }
        }

        .chart-label {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            cursor: default;
        }
    }

    &.dashboard-item-experiment {
        height: 223px;

        .status-info {
            margin-top: 10px;
        }

        // have to overwrite for dashboard !!!
        .experiment-body {
            .recharts-wrapper {
                width: 100% !important;
                .recharts-surface {
                    width: 100% !important;
                    height: 100px;
                }
            }
        }
    }

    @media (min-width: 768px) {
        &.dashboard-item-experiment {
            .experiment-body 
                .recharts-surface {
                    height: 130px;
                }
            }
        }
    }
    @media (min-width: 1200px) {
        &.dashboard-item-experiment {
            .experiment-body {
                .recharts-surface {
                    height: 100px;
                }
            }
        }
    }
`

export const LegendSquare = styled.div`
    float: left;
    margin: 4px 0 0 0;
    width: 12px;
    height: 12px;
`
export const LegendLabel = styled.div`
    float: left;
    font-size: 13px;
    margin-left: 4px;
    margin-right: 15px;
`

export const ChartCont = styled.div`
    min-height: 250px;

    .js-plotly-plot {
        margin: 10px 0;
    }
    .svg-container {
        margin-left: auto !important;
        margin-right: auto !important;
    }
`

export const DiagramLegend = styled.div`
    width: 100%;
    border-bottom-color: ${LIGHT_GREY};
    color: ${MID_GREY};
    border-bottom-style: solid;
    border-bottom-width: 2px;
    
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: left;

    .col-xs-6 {
        padding-right: 0;
        padding-left: 0;
        &.align-right {
            text-align: right;
        }
    }
`
export const DashboardItemLabel = styled.div`
    padding: 10px 0px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    background: transparent none;
    word-break: break-word;
`
export const DashboardExperimentTitle = styled(DashboardItemLabel)`
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 30px);
    overflow: hidden;
`

export const ArchetypeCircle = styled.div`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${WHITE};
    box-shadow: 0px 3px 10px rgb(0 0 0 / 30%);
`

export const ArchetypeTitle = styled.p`
    font-size: ${(props) => props.fontSize}px;
    font-weight: bold;
    margin: 0;
`

export const ArchetypeScore = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 60%;
`

export const ArchetypeIcon = styled.img`
    height: 40%;
    margin: 5px auto;
    display: block;
`

export const Filter = styled.div`
    margin: 16px -8px;    
    width: 30%;
    float: left;
    text-align: left;
`;