import React, {useEffect, useState} from 'react'
import {I18n} from 'react-i18nify'
import HeadingWithAction from './HeadingWithAction'
import ModalAddParent from './ModalAddParent'
import LinkedToList from './LinkedToList'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {useNode} from '../../strategyMap/hooks/useNode'
import {getArchivedNodes, getNonArchivedNodes} from "../../strategyMap/utils"
import { ContextMenuEvents } from './HeadingWithActionDropdownMenu'
import {NODE_TYPES} from "../../strategyMap/const";

const NodeLinks = (props) => {
    const {Actions, nodeMap, goal} = useStrategyMapSlice();
    const {
        node,
        isNC,
        isFinishedExp,
        isEditable,
        hasChildren,
        isGoal,
        isUserChampionOrSquadMember
    } = useNode();

    const showAddRemoveParent =
        (isNC || ! isFinishedExp) && isEditable && isUserChampionOrSquadMember;

    const [showModalAddParent, setShowModalAddParent] = useState(false);
    const justTheGoal = isGoal && ! hasChildren;
    const showLinkedToSection = node && ! justTheGoal;

    const removeParent = (parentId) => {
        const parents = node.parents.filter( id => id !== parentId );
        Actions.updateNode({...node, parents, removeParent: parentId});
    }

    const removeNodeFromOtherGoal = (nodeId) => {
        const parents = nodeMap[nodeId].parents.filter( id => id !== node.id );
        Actions.updateNode({...nodeMap[nodeId], parents, removeNodeFromOtherGoal: node.id});
    }

    const addParents = (parents) => {
        const newParents = node.parents.concat(parents);
        Actions.updateNode({...node, parents: newParents, addParents: parents});
    }


    const ShowModalDialog = () => {
        if( props.sameMap )
            return <ModalAddParent
                isOpen={showModalAddParent}
                onRequestClose={() => setShowModalAddParent(false)}
                onSaveParents={addParents}
            />
    }

    useEffect( () => {
        node?.parents?.length > 0 &&
            node.parents
            .forEach( p => {
                if( ! nodeMap[p] ) {
                    Actions.fetchNode(p);
                }
            })
    }, []);


    const parents = node.type === NODE_TYPES.GOAL ? [] :
        node?.parents
        .filter( p => nodeMap[p]?.goals?.includes(goal.id) || p === goal.id);

    const children = {
        active: getNonArchivedNodes(node.children, nodeMap).filter(n => nodeMap[n]?.goal === goal.id),
        archived: getArchivedNodes(node.children, nodeMap).filter(n => nodeMap[n]?.goal === goal.id)
    }

    return (
        <div data-testid={'node-links'}>
            {showLinkedToSection && (
                <div>
                    { showModalAddParent && ShowModalDialog() }

                    <HeadingWithAction
                        title={ props.sameMap ? I18n.t('strategyMap.nodes.linkedTo') : I18n.t('strategyMap.nodes.linkedToOtherGoal') }
                        showAction={showAddRemoveParent}
                        icon={'add'}
                        testId={'linked-to'}
                        onClick={() => {
                            if (props.sameMap) {
                                setShowModalAddParent(true)
                            }
                            else {
                                props.onEvent( ContextMenuEvents.onNodeShareOtherGoal, {} )
                            }
                        }}
                    />
                    {parents.length > 0 && <p className="text-muted">{I18n.t('strategyMap.nodes.parents')}</p>}
                    <LinkedToList
                        nodes={ parents }
                        removeItem={ removeParent }
                        removable={ showAddRemoveParent && node.parents.length > 1 }
                    />
                    {children.active.length > 0 && <p className="text-muted">{I18n.t('strategyMap.nodes.children')}</p>}
                    <LinkedToList
                        nodes={ children.active }
                        removable={ true }
                        removeItem={removeNodeFromOtherGoal}
                    />
                    {
                        !! children.archived.length &&
                        <HeadingWithAction title={I18n.t('strategyMap.nodes.archivedNodes')} />
                    }
                    {
                        !! children.archived.length && (
                        <LinkedToList
                            nodes={ children.archived }
                            removeItem={ props.onNodeUnarchive }
                            removable={ false }
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default NodeLinks
