import React from 'react'
import {DARK_GREY} from '../../core/ui/_colors'

export const CurvedLine = ({child, parent, translatePosition, height, isOpacity}) => {
    const getLinkPath = (startParent, curveFactor, end) =>
        `M ${startParent.x} ${startParent.y} S ${startParent.x} ${curveFactor} ${end.x} ${end.y}`

    const STROKE_WIDTH = 3

    const curveFactor = (parent.y - child.y) / 2 + child.y
    const opacity = isOpacity ? 0.3 : 1

    const rotationPoint = {
        x: child.x + (parent.x - child.x) / 2,
        y: child.y + (parent.y - child.y) / 2,
    }

    return (
        <div
            style={{
                background: 'transparent',
                position: 'absolute',
                left: 0,
                transform: 'translate(' + translatePosition.x + 'px, ' + translatePosition.y + 'px',
                opacity: opacity,
            }}
        >
            <svg height={height} width={window.innerWidth}>
                <path
                    d={getLinkPath(child, curveFactor, rotationPoint)}
                    stroke={DARK_GREY}
                    strokeWidth={STROKE_WIDTH}
                    fill="none"
                />
                <path
                    d={getLinkPath(child, curveFactor, rotationPoint)}
                    stroke={DARK_GREY}
                    strokeWidth={STROKE_WIDTH}
                    fill="none"
                    transform={'rotate(180 ' + rotationPoint.x + ' ' + rotationPoint.y + ')'}
                />
            </svg>
        </div>
    )
}
