import React, {useEffect, useState} from 'react'
import {
    DashboardExperimentTitle,
    DashboardItem,
    DashboardItemContainer,
    DashboardTiles, DiagramLegend,
} from './Dashboard.styles'
import ExperimentChart from './ExperimentChart';
import {useCoreSlice} from "../../core/hooks/useCoreSlice";
import {useStrategyMapSlice} from "../../strategyMap/hooks/useStrategyMapSlice";
import styled from "styled-components";
import {I18n} from "react-i18nify";
import ReactCardFlip from "react-card-flip";
import {SettingIcon} from "./DashboardChart";
import SelectWrapper from "../../core/components/SelectWrapper";
import { SIDEBARS } from '../../core/redux/slices';
import { useHistory } from '../../core/hooks/useHistory';
import { BLUE } from '../../core/ui/_colors';
import { goToNode } from './DashboardStrategyMap';


const HeaderTitle = styled.h3`
    font-size: 20px;
    padding: 0 15px;
    margin-top: 10px;
    cursor: pointer;
    color: ${BLUE};
`;

const RemoveWidget = ({onClick}) =>
    <a style={{textAlign: 'right', position: 'absolute', bottom: 15, right: 15, cursor: 'pointer'}} onClick={ onClick }>{ I18n.t( 'dashboard.removeWidget' ) }</a>;

const ExperimentCardFrame = ({title, body, flip}) => {
    const {sidebarOpen} = useCoreSlice();
    const isEditMode = sidebarOpen === SIDEBARS.Dashboard;

    return (
        <DashboardItemContainer >
            <DashboardItem className="dashboard-item-experiment">
                <div style={{position: 'absolute', right: 15, top: 15}}>
                        { isEditMode && flip && <SettingIcon onClick={flip} /> }
                    </div>
                <DashboardExperimentTitle
                    >{ title }
                    </DashboardExperimentTitle>
                <DiagramLegend></DiagramLegend>
                { body }
            </DashboardItem>
        </DashboardItemContainer>
    );
}

const NoExperiments = ({flipped, setFlipped, setNewGoal, selectedGoal, options, removeWidget}) =>
    <div className="col-sm-4 col-md-4 col-lg-4">
        <ReactCardFlip
                isFlipped={flipped || Number.isInteger(flipped) }
                flipDirection="horizontal"
                flipSpeedBackToFront={2}
                flipSpeedFrontToBack={2}
                dragable={false}
            >
                <ExperimentCardFrame
                    title={'Experiment'}
                    body={ I18n.t('dashboard.noComparableExp') }
                    flip={() => setFlipped(true)} />
                <ExperimentCardFrame
                    title={'Experiment'}
                    body={<>
                        <SelectWrapper
                            searchable={ false }
                            styles={{}}
                            name="form-field-name"
                            options={ options }
                            onChange={ setNewGoal }
                            value={ selectedGoal }
                            clearable={false}
                        />
                        <RemoveWidget onClick={ removeWidget }>remove widget</RemoveWidget>
                        </>}
                    flip={() => setFlipped(false)} />
        </ReactCardFlip>
    </div>

const Experiments = ({experiments, widget, id, usedGoals}) => {
    const {goalNodes} = useStrategyMapSlice();
    const {dashboardWidgetsVisible, Actions} = useCoreSlice();
    const [flipped, setFlipped] = useState(null);

    const exp = experiments.filter( a => a.goal === widget.filter.goal );
    const options = goalNodes
        ?.filter( a => a.id && (widget.filter.goal === a.id || ! usedGoals.includes(a.id) ) )
        .map( a => ({label: "Goal #"+ a.id + " " + a.title, value: a.id}) );
    const selectedGoal = options.find( a => a.value === widget.filter.goal );

    const setNewGoal = value => {
        const widgetOther = dashboardWidgetsVisible.filter( a => a.name !== 'charts.Experiments');
        const widgetExperiments = [...dashboardWidgetsVisible.filter( a => a.name === 'charts.Experiments')];

        widgetExperiments[id] = {...widgetExperiments[id], filter : {goal: value.value}};

        Actions.updateDashboard( [...widgetOther, ...widgetExperiments] );
    }
    const removeWidget = value => {
        const widgetOther = dashboardWidgetsVisible.filter( a => a.name !== 'charts.Experiments');
        const widgetExperiments = dashboardWidgetsVisible.filter( a => a.name === 'charts.Experiments');
        delete widgetExperiments[id];

        Actions.updateDashboard( [...widgetOther, ...widgetExperiments.filter( a => a )] );
    }

    if( exp.length == 0 )
        return <NoExperiments flipped={flipped} setFlipped={setFlipped} setNewGoal={setNewGoal} selectedGoal={selectedGoal} removeWidget={removeWidget} options={options} />

    return <div className={''}>{
        exp.map( (experiment, key) =>
            <div key={key} className="col-sm-4 col-md-4 col-lg-4">
                <ReactCardFlip
                    isFlipped={ Number.isInteger(flipped) && flipped == key }
                    flipDirection="horizontal"
                    flipSpeedBackToFront={2}
                    flipSpeedFrontToBack={2}
                    dragable={true}
                >
                    <ExperimentChart experiment={experiment} key={key} flip={() => setFlipped(key)}  />
                    <ExperimentCardFrame
                        flip={ () => setFlipped(null) }
                        title={ 'Experiment' }
                        body={
                        <>
                            <SelectWrapper
                                searchable={ false }
                                name="form-field-name"
                                options={ options }
                                onChange={ setNewGoal }
                                value={ selectedGoal }
                                clearable={false}
                            />
                            <RemoveWidget onClick={ removeWidget }>remove widget</RemoveWidget>
                        </>
                        }
                    />
                </ReactCardFlip>
            </div>
            )}
    </div>;
}
const ExperimentChartSection = () => {

    const {dashboardWidgetsVisible} = useCoreSlice();
    const {goalNodes} = useStrategyMapSlice();

    const {isMainDashboardPage} = useHistory();

    if( ! isMainDashboardPage ) return null;
    const {experiments, Actions: StrategyMapActions} = useStrategyMapSlice();

    const widgets = dashboardWidgetsVisible.filter( a => a.name == 'charts.Experiments');

    useEffect( () => {
        const goalsWithExperiments = widgets.map( a => a.filter.goal ).filter( a => Number.isInteger(a));
        StrategyMapActions.fetchAllExperiments( goalsWithExperiments );
    }, [dashboardWidgetsVisible]);

    const getGoalName = (goalID) =>
        `Goal #${goalID} ${goalNodes.find( goal => goal.id === goalID )?.title}`;

    return (
        widgets
            .map( (widget,id) => <div key={id}>
                    <HeaderTitle onClick={(e) => goToNode(widget.filter.goal, widget.filter.goal)}>{ getGoalName( widget.filter.goal ) }</HeaderTitle>
                    <DashboardTiles key={widget} className="row">
                        <Experiments experiments={experiments} id={id} widget={widget} usedGoals={widgets.map( a => a.filter.goal )} />
                    </DashboardTiles>
                </div>
            )
    )
}

export default ExperimentChartSection;
