import React, {useEffect, useState} from 'react'
import settings from '../config/settings.json'
import {I18n} from 'react-i18nify'
import TextInput from '../core/ui/form/TextInput'
import {Modal} from '../core/ui/modal/Modal'
import { windowOpen } from '../core/utils/navigation'

const TrelloAuth = (props) => {
    const [trelloAccessToken, setTrelloAccessToken] = useState('')

    useEffect(() => {
        const auth_url = `https://trello.com/1/authorize?key=${settings.trelloApiKey}&name=ORGANIC+agility&expiration=never&scope=read,write&response_type=token`
        windowOpen(auth_url)
    }, [])

    return (
        <Modal 
            isOpen={props.open}
            title={I18n.t('billing.Label.TrelloIntegrationModalTitle')}
            footer={{
                confirmText:I18n.t('strategyMap.messages.Confirm'),
                onConfirm:() => {
                    if (trelloAccessToken) {
                        props.onClose(trelloAccessToken)
                    }
                },
                onCancel:() => props.onClose()
            }}
        >
            <>
                <p>{I18n.t('billing.Label.TrelloIntegrationModalDescription')}</p>
                <br />
                <TextInput
                    label={I18n.t('strategyMap.nodes.trelloAccessToken')}
                    onChange={(e) => setTrelloAccessToken(e.target.value)}
                    value={trelloAccessToken}
                    testId="trello-auth-text"
                />
            </>
        </Modal>
    )
}

export default TrelloAuth
