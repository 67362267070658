import {useState} from 'react'

export const useTypingTimeout = (timeout = 1000) => {
    const [typingTimeout, setTypingTimeout] = useState(null)

    const handleOnchangeTimeout = (callback) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout)
        }
        setTypingTimeout(
            setTimeout(() => {
                clearTimeout(typingTimeout)
                setTypingTimeout(null)
                callback()
            }, timeout),
        )
    }

    return [handleOnchangeTimeout, typingTimeout]
}
