import React from 'react'
import {DragLayer} from 'react-dnd'

import { ITEM_TYPES } from '../const'
import NodeDragPreview from './NodeDragPreview'
import {NODE_HEIGHT} from './Node.styles'

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    left: 0,
    top: 0,
    zIndex: 100000,
}

const getItemStyles = (props) => {
    const {currentOffset, item} = props
    if (!currentOffset) {
        return {
            display: 'none',
        }
    }
    const transform = `translate(${currentOffset.x}px, ${
        currentOffset.y + item.y * (NODE_HEIGHT + 30)
    }px)`
    return {
        WebkitTransform: transform,
        transform,
    }
}

const collect = (monitor) => {
    return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }
}

const CustomDragLayer = (props) => {
    const {item, itemType, isDragging} = props

    if (!isDragging) {
        return null
    }

    const renderItem = (type, item) => {
        switch (type) {
            case ITEM_TYPES.NODE:
                return <NodeDragPreview node={item} />
            default:
                return null
        }
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(props)}>{renderItem(itemType, item)}</div>
        </div>
    )
}

export default DragLayer(collect)(CustomDragLayer)
