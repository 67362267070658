import {createSlice} from 'redux-starter-kit'
import * as ErrorUtils from '../../core/utils/error'

const errorHandler = (state, action) => ({
    ...state,
    isFetching: false,
    isSaving: false,
    errors: ErrorUtils.getApiErrors(action.payload),
})
const fetchingHandler = (state) => ({...state, isFetching: true})
const savingHandler = (state) => ({...state, isSaving: true})

export const commentSlice = createSlice({
    slice: 'COMMENT',
    initialState: {
        results: [],
        isSaving: false,
        isFetching: false,
    },
    reducers: {
        fetchComments: fetchingHandler,
        fetchCommentsSuccess: (state, action) => ({
            isFetching: false,
            results: [...action.payload],
        }),
        addComment: savingHandler,
        addCommentSuccess: (state, action) => ({
            isSaving: false,
            results: [action.payload, ...state.results],
        }),
        vote: savingHandler,
        resolve: savingHandler,
        resolveSuccess: (state, action) => {
            state.isSaving = false
            state.results = state.results.filter((comment) => comment.id !== action.payload)
        },
        voteSuccess: (state, action) => {
            state.isSaving = false
            state.results.forEach((comment, index) => {
                if (comment.id === action.payload.id) {
                    state.results[index] = action.payload
                }
            })
        },
        requestFailure: errorHandler,
    },
})
