import React, {useEffect} from 'react'
import Capture from './Capture'
import CommonPage, { getPageParam } from '../core/components/CommonPage'
import {useCaptureSlice} from './hooks/useCaptureSlice'
import {FullScreenLoading} from '../core/components/Loading'

import {I18n} from 'react-i18nify'
import {DEFAULT_CAPTURE_QUESTION_KEY, LEADERSHIP_CAPTURE_SURVEY} from './const'
import FeaturePermission from '../core/components/FeaturePermission'

const CapturePage = (props) => {
    const {Actions, isFetching, captureQuestion} = useCaptureSlice();

    const isSelfAssessment = window.location.pathname === "/selfassessment";
    const questionId = getPageParam(props, 'id') || isSelfAssessment && LEADERSHIP_CAPTURE_SURVEY || DEFAULT_CAPTURE_QUESTION_KEY;
    const questionParentId = getPageParam(props, 'parentId');

    useEffect(() => {
        Actions.getCaptureQuestions( {questionID: questionId, parentID: questionParentId} );
    }, []);
    
    if( ! captureQuestion || isFetching )
        return <FullScreenLoading loading={isFetching}/>;

    const featurePermissionName = questionId === LEADERSHIP_CAPTURE_SURVEY && 
                                    !isSelfAssessment ? 
                                        ["leadership.full", "leadership.team"] : 
                                        'capture'

    return (
        <FeaturePermission name={featurePermissionName}>
            <CommonPage pageHeading={I18n.t(captureQuestion.page_title || 'capture.PageTitle')} render={() => <Capture id={questionId} />} />
        </FeaturePermission>
    );

}

export default CapturePage;