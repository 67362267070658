import {useDashboardSlice} from "../../../dashboard/hooks/useDashboardSlice";
import {FilterInput, SelectContainer, SelectContainerItem} from "../Filters.styles";
import React from "react";
import {I18n} from "react-i18nify";

const PeriodItems = ({demographicType}) => {
    const {filters, Actions} = useDashboardSlice()
    const currentData = filters[demographicType] || {}

    return (
        <div>
            <SelectContainer>
                <SelectContainerItem
                    dimmed={!currentData.from && !currentData.to}
                    onClick={() =>
                        (currentData.from || currentData.to) &&
                        Actions.clearFilters({filterType: demographicType})
                    }
                >
                    {I18n.t('dashboard.Filters.ignoreDates')}
                </SelectContainerItem>
            </SelectContainer>

            <label> {I18n.t('dashboard.Filters.from')}: </label>
            <FilterInput
                type={'date'}
                value={currentData.from}
                max={currentData.to}
                onChange={(e) =>
                    Actions.setFilters({
                        filterType: demographicType,
                        data: {...currentData, from: e.target.value},
                    })
                }
            />

            <label> {I18n.t('dashboard.Filters.to')}:</label>
            <FilterInput
                type={'date'}
                value={currentData.to}
                min={currentData.from}
                onChange={(e) =>
                    Actions.setFilters({
                        filterType: demographicType,
                        data: {...currentData, to: e.target.value},
                    })
                }
            />
        </div>
    )
}

export default PeriodItems;