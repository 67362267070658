import {SideBar, SidebarHeader} from "../core/ui/Sidebar.styles";
import HeadingWithActionAndDropdown from "./Components/HeadingWithActionAndDropdown";
import {I18n} from "react-i18nify";
import React from "react";
import {SideBarContent} from "../core/components/DemoTool";
import {WidgetLibrary} from "../dashboard/components/CustomizeChartsDashboard";


const DashboardConfig = () => {

    return <SideBar
            id="admin-sidebar"
            className="show-sidebar"
            data-testid={'admin-sidebar'}
        >
            <SidebarHeader tabs={1}>
                <HeadingWithActionAndDropdown
                    className="padding-bottom"
                    title={I18n.t('dashboard.ButtonLabels.ManageDashboard')}
                    icon="clear"
                    showAction={true}
                />
            </SidebarHeader>
            <SideBarContent style={{height: 'calc( 100% - 90px)'}}>
                <WidgetLibrary />
            </SideBarContent>
    </SideBar>
}

export default DashboardConfig;