import React, {useState} from 'react';
import styled from "styled-components";
import {useUserSlice} from "../../strategyMap/hooks/useUserSlice";

const Tabs = styled.div`
    width: 100%;
`
const TabHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    clear: both;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(223, 225, 230);
    font-size: 14px;
    cursor: pointer;
`
const TabHeaderItem = styled.div`
    padding: 10px 15px;
    
    text-align: center;
    margin-right: 0px;
    border-bottom-style: ${ props => props.selected && 'solid'};
    border-bottom-width: ${ props => props.selected && '3px'};
    font-weight: ${ props => props.selected && 'bold'};
}
`
const TabContent = styled.div`
`

const A42Tabs = ({tabs, defaultState, style}) => {

    const [key, setKey] = useState(defaultState || tabs[0].key);
    const user = useUserSlice();

    return (
        <Tabs style={style}>
            <TabHeader>
                {
                    tabs
                        .filter( tab => typeof tab.visible === 'undefined' || tab.visible )
                        .filter( tab => tab.users === undefined || tab.users.some( a => user[a] ) )
                        .map( tab => <TabHeaderItem key={tab.key}
                                                    id={tab.key}
                                                    className={tab.key}
                                                    data-testid={tab.key}
                                                    count={tabs.length} 
                                                    selected={ key===tab.key } 
                                                    onClick={() => setKey(tab.key)}>
                                                        { tab.title }
                                        </TabHeaderItem> ) }
            </TabHeader>
            <TabContent className={"tab-content-billing"}>
                {
                    tabs
                        .filter( tab => typeof tab.visible === 'undefined' || tab.visible )
                        .filter( tab => tab.users === undefined || tab.users.some( a => user[a] ) )
                        .filter( tab => tab.key === key ).map( tab => <React.Fragment key={1}>{tab.content}</React.Fragment> )
                }
            </TabContent>
        </Tabs>
    )
};

export default A42Tabs;