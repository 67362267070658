import {TwoColumns} from "../Stories";
import {I18n} from "react-i18nify";
import React from "react";
import imageToken from "../../img/fifo-token.png";
import {ButtonPrimary} from "../../core/components/Buttons";

const closeTour = (props) => props.CoreActions.startHelpTour(false );

const u2fDialog = (onClick, label) => {
    const steps = () =>
        ["additionalAuthentication", "activation", "hints"]
            .map( step => ({
                content:
                    <TwoColumns
                        title={ I18n.t("app.u2f.dialog."+step+".title") }
                        descriptionLeft={ I18n.t("app.u2f.dialog."+step+".body")
                                        .split( '\n' )
                                        .map( (para, key) => <p key={key}>{ para }</p> ) }
                        descriptionRight={ <img width={200} src={imageToken}/> }
                        closing={ <ButtonPrimary onClick={ onClick  }>{ label }</ButtonPrimary> }
                    />,
                style: {minWidth: "600px"}
            }) );

    return {steps: steps()};
}

export const u2fAdding = (props) => {

    const onClick = () => {
        closeTour(props);
        props.Actions.storeU2FCredentials( { email: props.email, name: props.full_name } );
    };

    return u2fDialog( onClick, I18n.t("app.auth.addToken") );
}
export const u2fRemoving = (props) => {

    const onClick = () => {
        closeTour(props);
        props.Actions.removeU2F();
    };

    return u2fDialog( onClick, I18n.t("app.auth.deleteToken") );
}