import React, {useEffect, useState} from 'react'

import {I18n} from 'react-i18nify'
import Comment from './Comment'
import TextAreaAutoSwitch from '../../core/components/TextAreaAutoSwitch'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {useUserSlice} from '../hooks/useUserSlice'
import {useCommentSlice} from '../hooks/useCommentSlice'
import styled from 'styled-components'
import {RippleLoading} from '../../core/components/Loading'
import {IconButton} from '../../core/components/Buttons'
import {RESTRICTIONS} from "../../core/const";

const MAX_CHARACTER_LIMIT = 700
const TEXTAREA_MIN_HEIGHT = 150

const CommentButtonGroup = styled.div`
    clear: both;
    margin-bottom: 70px;
`

const CommentsContainer = () => {
    const {Actions, isFetching, isSaving, results} = useCommentSlice()
    const {currentOpenNodeId} = useStrategyMapSlice()
    const [comments, setComments] = useState(results)
    const [value, setValue] = useState('')
    const [showButtons, setShowButtons] = useState(false)
    const [mentions, setMentions] = useState([])
    const user = useUserSlice()
    const isAbleToEdit = RESTRICTIONS.StrategyMap.RULES.CAN_EDIT_ON_STRATEGY_MAP.some( role => user[role] ) || false;

    useEffect(() => {
        setComments([])
        if (currentOpenNodeId) {
            Actions.fetchComments({nodeId: currentOpenNodeId})
        }
    }, [currentOpenNodeId])

    useEffect(() => setComments(results), [results])

    const sendComment = () => {
        const comment = {
            text: value,
            mentions,
            node: currentOpenNodeId,
        }
        Actions.addComment({comment})
        toggleShowButtons()
    }

    const vote = (commentId, data) => {
        Actions.vote({commentId: commentId, nodeId: currentOpenNodeId, data})
    }

    const toggleShowButtons = () => {
        setValue('')
        setShowButtons(!showButtons)
    }

    return (
        <div className="comments clearfix" style={{position: 'relative'}}>
            <RippleLoading css={{position: 'relative', top: 0}} loading={isFetching || isSaving} />
            {isAbleToEdit && (
                <TextAreaAutoSwitch
                    placeholder={I18n.t('strategyMap.nodes.addNewComment')}
                    onChange={setValue}
                    onClick={toggleShowButtons}
                    onMentionsChange={setMentions}
                    text={value}
                    characterLimit={MAX_CHARACTER_LIMIT}
                    shrink
                    minHeight={TEXTAREA_MIN_HEIGHT}
                    hideToolbar={!showButtons}
                />
            )}
            {showButtons && (
                <CommentButtonGroup>
                    <IconButton 
                        withBackground
                        className="pull-right" 
                        onClick={toggleShowButtons}
                        icon="close"
                    />
                    <IconButton
                        withBackground
                        className="pull-right"
                        onClick={sendComment}
                        disabled={value.length > MAX_CHARACTER_LIMIT}
                        icon="check"
                    />
                </CommentButtonGroup>
            )}
            {!isFetching &&
                comments.map((comment) => (
                    <Comment key={comment.id} comment={comment} vote={vote} />
                ))}
        </div>
    )
}

export default CommentsContainer
