import {useEffect, useState} from 'react'

export const useSearch = (items, matchSearch) => {
    const [searchedItems, setSearchedItems] = useState(items)
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        setSearchedItems(items.filter((item) => matchSearch(item, searchTerm)))
    }, [searchTerm, items])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target && e.target.value)
    }

    return [searchedItems, handleSearchChange, searchTerm]
}
