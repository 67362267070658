import React, {useEffect, useState} from 'react'
import 'rc-slider/assets/index.css'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {SyncLoader} from 'react-spinners'
import {ColorsTypes, getDotColor, getGradientSettings, moodColorMap, getBarColors, moodSymbolMap} from '../ChartColor'
import {ChartType} from '../ChartsConfig'
import {useDashboardSlice} from '../../dashboard/hooks/useDashboardSlice'
import {ChartMaskInside, ChartMaskOutside, CornerLabel, HeatMapChartWrapper, ToolItem} from './HeatMap.styles'
import {ChartCont} from '../../dashboard/components/Dashboard.styles'
import I18n from 'react-i18nify/build/lib/I18n'
import {consoleError} from "../../core/utils/error";
import toolSquareIcon from "../../img/tool_square.png"
import toolFreeIcon from "../../img/tool_free.png"
import * as calc from '../ChartCalculations'

const OverlayLabel = (props) => {
    const maxWidth = props.align === 'center' ? '100%' : '50%'
    
    return (
        <div style={{width: maxWidth, float: "left", textAlign: props.align, position: "absolute", right: props.align === "right" ? 0 : ""}}>
            <OverlayTrigger
                placement={props.tooltipPosition || 'top'}
                overlay={
                    <Tooltip
                        id={props.children}
                        style={{opacity: 1}}
                    >
                        {props.children}
                    </Tooltip>
                }
            >
                <CornerLabel className="chart-label" style={{textAlign: props.align}}>{props.children}</CornerLabel>
            </OverlayTrigger>
        </div>
    )
}

const HeatMap = (props) => {
    const html_id = 'diagramm_cartesian_' + Math.round(Math.random() * 5000) // div tag for heatmap
    const {selectedPoints, Actions} = useDashboardSlice()
    const [cartesianPoints, setCartesianPoints] = useState(null)
    const [loading, setLoading] = useState(true);
    const labels = props.testId && props.testId.toLowerCase().includes('culture-all') ? 
                        calc.getCultureDiagramsLabel(props.subTypes[1]) :
                        props.label;


    useEffect(() => {
        if (props.data && props.data.length) {
            if (props.subType) {
                setCartesianPoints({
                    shown: {
                        points: getCalculatedPoints(props.demo, props.data[0]),
                    },
                    compared: {
                        points: getCalculatedPoints(props.demo, props.data[1]),
                    },
                })
            } else {
                const prepareStructure = {}
                props.data.forEach((data, idx) => {
                    prepareStructure[idx] = {
                        points: getCalculatedPoints(props.demo, props.data[idx]),
                    }
                })
                setCartesianPoints(prepareStructure)
            }
        } else {
            setCartesianPoints({
                shown: {points: []},
                compared: {points: []},
            })
        }
    }, [props.data, props.coordinate]);

    useEffect(() => {
        try {
            if (cartesianPoints) setUp()
        } catch (e) {
            consoleError(e, "HeatMap useEffect")
        }
    }, [cartesianPoints])

    const getStyleSelectedPoints = (value, markValue) => {
        if (
            props.inspectMode &&
            props.data[0] &&
            Object.keys(selectedPoints).length &&
            selectedPoints.diagramName === props.subType
        ) {
            return props.data[0].map( point =>
                selectedPoints.decisionIDs.includes(point.decisionID) ? markValue : value
            )
        }
        return value
    }

    const getConfigForSetUp = () => {
        if (props.type === ChartType.Triangle) {
            const currentOffset = props.dimension.height/10
            return {
                axis_visible: false,
                zero_line: false,
                y_range_from: ((-props.dimension.height * Math.sqrt(3)) / 2) + currentOffset,
                y_range_to: ((props.dimension.height * Math.sqrt(3)) / 2) - (currentOffset*3),
                layout_bgcolor: 'transparent',
            }
        } else {
            return {
                axisVisible: true,
                zeroLine: true,
                y_range_from: -props.dimension.height / 2 - props.dimension.offset,
                y_range_to: props.dimension.height / 2 + props.dimension.offset,
                layout_bgcolor: '#FFFFFF',
            }
        }
    }

    // this function is needed, because just one-time called render function,
    //  but it needs because twice on heatmap need a div container for rendering
    const setUp = () => {
        if (document.getElementById(html_id)) {
            const graphDiv = document.getElementById(html_id)

            // https://plot.ly/create/
            // just for the headless browser test
            if (window.navigator.platform === undefined) {
                return
            }

            const Plotly = require('plotly.js')
            if (loading) setLoading(false)
            const customConfForType = getConfigForSetUp()

            // hide the modebar (hover bar) buttons, plotly logo. show plotly tooltips
            const defaultPlotlyConfiguration = {
                displaylogo: false,
                showTips: !props.inspectMode,
                displayModeBar: props.inspectMode,
                modeBarButtonsToRemove: [
                    'toImage',
                    'hoverCompareCartesian',
                    'hoverClosestCartesian',
                    'toggleSpikelines',
                ],
            }
            const layoutSettings = {
                paper_bgcolor: customConfForType.layout_bgcolor,
                plot_bgcolor: customConfForType.layout_bgcolor,
                width: props.coordinate.width,
                height: props.coordinate.height,
                dragmode: props.inspectMode ? 'select' : false,
                hovermode: false,

                margin: {
                    l: 0,
                    r: 0,
                    t: 0,
                    b: 0,
                    pad: 10,
                },

                xaxis: {
                    gridwidth: 2,
                    nticks: 8,
                    showticklabels: false,
                    visible: customConfForType.axis_visible,
                    zeroline: customConfForType.zero_line,
                    fixedrange: true,
                    range: [
                        -props.dimension.width / 2 - props.dimension.offset,
                        props.dimension.width / 2 + props.dimension.offset,
                    ],
                },
                yaxis: {
                    gridwidth: 2,
                    nticks: 8,
                    showticklabels: false,
                    visible: customConfForType.axis_visible,
                    zeroline: customConfForType.zero_line,
                    fixedrange: true,
                    range: [customConfForType.y_range_from, customConfForType.y_range_to],
                },
            }

            let diagramDataForDensity = {
                visible: true,
                type: 'histogram2dcontour',
                reversescale: false,
                showscale: false,
                autocolorscale: false,
                colorscale: getGradientSettings(props.color),
                opacity: 0.8, // needed, for showing grid
                autobinx: true,
                autobiny: true,
                histnorm: 'percent',
                line: {
                    width: 0,
                    color: 'rgba(160,160,160,.3)',
                    smoothing: 8,
                },
                contours: {
                    type: 'levels',
                    size: 1,
                    showlines: true,
                    operation: '<',
                    value: 10,
                },
            }

            const dotSymbols = {color: [], symbol: []};
            let diagramDataForDensityPast = {
                visible: true,
                type: 'histogram2dcontour',
                ncontours: 11,
                reversescale: false,
                showscale: false,
                autocolorscale: false,
                colorscale: getGradientSettings(ColorsTypes.black),
                opacity: 1, // needed, for showing grid
                autobinx: false,
                autobiny: false,
                nbinsx: 2, // no effect
                nbinsy: 2, // no effect
                histnorm: 'percent',
                line: {
                    width: 0.5,
                    color: getDotColor(props.color)
                }
            }

            if (props.subType) {
                const points = props.subType && {
                    x:
                        cartesianPoints.shown.points &&
                        cartesianPoints.shown.points.map((item) => {
                            dotSymbols.color.push( moodColorMap[item.mood] || getBarColors(ColorsTypes.darkGray) );
                            dotSymbols.symbol.push( moodSymbolMap[item.mood] || 'triangle' );
                            return item.x - props.dimension.width / 2 - props.dimension.offset;
                        }),
                    y:
                        cartesianPoints.shown.points &&
                        cartesianPoints.shown.points.map(
                            (item) =>
                                (item.y - props.dimension.height / 2 - props.dimension.offset) * -1
                        )
                };

                const pointsCompared = props.subType && {
                    x:
                        cartesianPoints.compared.points &&
                        cartesianPoints.compared.points.map(
                            (item) => item.x - props.dimension.width / 2 - props.dimension.offset ),
                    y:
                        cartesianPoints.compared.points &&
                        cartesianPoints.compared.points.map(
                            (item) => (item.y - props.dimension.height / 2 - props.dimension.offset) * -1,
                        )
                };
                const diagramDataForPoints = {
                    visible: true,
                    x: points && points.x,
                    y: points && points.y,
                    type: 'scatter',
                    mode: 'markers',
                    marker: {
                        color: dotSymbols.color,
                        size: getStyleSelectedPoints(props.zoom ? 8 : 6, 14),
                        symbol: dotSymbols.symbol
                    },
                }

                diagramDataForDensity = {
                    ...diagramDataForDensity,
                    x: points.x,
                    y: points.y,
                }
                diagramDataForDensityPast = {
                    ...diagramDataForDensityPast,
                    x: pointsCompared.x,
                    y: pointsCompared.y,
                }

                if (props.subType) {
                    Plotly.newPlot(
                        html_id,
                        props.isCompared
                            ? [diagramDataForDensity, diagramDataForDensityPast]
                            : [diagramDataForDensity, diagramDataForPoints],
                        layoutSettings,
                        defaultPlotlyConfiguration,
                    )
                }
            } else {
                const color = {
                    'leader-type': ColorsTypes.orange,
                    orientation: ColorsTypes.blue,
                    effectiveness: ColorsTypes.green,
                    'value-drivers': ColorsTypes.yellow,
                    na: ColorsTypes.lightGray,
                }

                layoutSettings.showlegend = true
                layoutSettings.legend = {
                    orientation: 'h',
                    yanchor: 'bottom',
                    y: 1.02,
                    xanchor: 'right',
                    x: 1,
                }
                Plotly.newPlot(
                    html_id,
                    props.data.map((data, idx) => ({
                        ...diagramDataForDensity,
                        ncontours: 3,
                        colorscale: getGradientSettings(
                            color[data.length > 0 ? data[0].name : 'na'],
                            0.1,
                            0.3,
                        ),
                        showlegend: true,
                        name: I18n.t('charts.Topic.cartesian.' + props.subTypes[idx]),
                        x:
                            cartesianPoints[idx].points &&
                            cartesianPoints[idx].points.map(
                                (item) =>
                                    item.x - props.dimension.width / 2 - props.dimension.offset,
                            ),
                        y:
                            cartesianPoints[idx].points &&
                            cartesianPoints[idx].points.map(
                                (item) =>
                                    -1 *
                                    (item.y - props.dimension.width / 2 - props.dimension.offset),
                            ),
                    })),
                    layoutSettings,
                    defaultPlotlyConfiguration,
                )
            }

            if (graphDiv && props.inspectMode) {
                try {
                    graphDiv.on('plotly_selected', (eventData) => {
                        const points = eventData ? eventData.points.map((point) => point.pointIndex) : [];
                        Actions.setSelectedPoints({
                            diagramName: props.subType,
                            diagramType: props.type,
                            decisionIDs: props.data[0].map( (point,id) => points.includes(id) ? point.decisionID : null).filter( a => a)
                        })
                    })
                } catch (e) {
                    consoleError(e)
                }
            }
        }
    }

    const getCalculatedPoints = (demo, data) => {
        if (demo === true) {
            const points = _generateRandomPointsForRectangle(10)
            return _rectangleCoordinates2CartesianCoordinates(points)
        } else {
            return _rectangleCoordinates2CartesianCoordinates(data)
        }
    }

    const _generateRandomPointsForRectangle = (countLimit) => {
        const points = []
        for (let count = 0; count < countLimit; count++) {
            const yValue = (Math.round(Math.random() * 100) / 100) * Math.random() * Math.random()
            const xValue = (Math.round(Math.random() * 100) / 100) * Math.random()
            points.push({
                y: yValue,
                x: xValue * (1 - yValue) + yValue / 2,
                value: 1,
            })
        }
        return points
    }

    const _rectangleCoordinates2CartesianCoordinates = (_data) => {
        if (_data === undefined || _data.length === 0) return false

        const triangleWidth = props.dimension.width - 3
        const triangleHeight = props.dimension.height - 4
        const rightshift = 2

        return _data.map((point) => {
            return {
                x: Math.round(triangleWidth * point.x) + props.dimension.offset + rightshift,
                y: Math.round(triangleHeight - triangleHeight * point.y) + props.dimension.offset,
                value: point.x,
                date: point.date,
                mood: point.mood,
            }
        })
    }

    const getTopRow = () => {
        if( props.type === ChartType.Triangle )
            return (
                <OverlayLabel key={props.type} align={'center'} tooltipPosition={'top'}>
                    {labels.top}
                </OverlayLabel>
            )
        else
            return (
                <>
                    <OverlayLabel align={'left'} tooltipPosition={'top'}>
                        {labels.topLeft}
                    </OverlayLabel>
                    <OverlayLabel align={'right'} tooltipPosition={'top'}>
                        {labels.topRight}
                    </OverlayLabel>
                </>
            )
    }

    const [toolbarKey, setToolbarKey] = useState(1)

    const isToolActive = (tool) => {
        const el = document.querySelectorAll('[data-val="'+tool+'"]')
        if (el.length > 0)
            return el[0].classList.contains('active')
        return false
    }

    const clickOnTool = (tool) => {
        const el = document.querySelectorAll('[data-val="'+tool+'"]')
        if (el.length > 0) {
            el[0].click()
            setToolbarKey(toolbarKey+1)
        }
    }

    return (
        <div data-testid={props.testId} className={'dashboard-chart'}>
            <div
                style={{
                    position: 'fixed',
                    marginTop: '150px',
                    marginLeft: '0px',
                    visibility: ! loading ? 'hidden' : 'visible'
                }}
            >
                <SyncLoader size={10} color={'BlueViolet'} />
            </div>
            <ChartCont style={{visibility: loading ? 'hidden' : 'visible'}}>
                <div style={{width: "100%", minHeight: "20px", position: "relative"}}>{ getTopRow() }</div>
                <HeatMapChartWrapper>
                    {props.type === ChartType.Triangle && props.inspectMode && <div style={{float:'right'}} key={toolbarKey}>
                        <ToolItem 
                            alt={'Box Select'} 
                            src={toolSquareIcon}
                            onClick={ () => clickOnTool('select')}
                            filter={isToolActive('select') ? 0 : 1}
                        />
                        <ToolItem
                            alt={'Lasso Select'}
                            src={toolFreeIcon}
                            onClick={ () => clickOnTool('lasso')}
                            filter={isToolActive('lasso') ? 0 : 1}
                        />
                    </div>}
                    <ChartMaskOutside
                        visible={props.type === ChartType.Triangle}
                        width={props.coordinate.width}
                        height={props.coordinate.height}
                        inspectMode={props.inspectMode}
                    >
                        <ChartMaskInside
                            visible={props.type === ChartType.Triangle}
                            inspectMode={props.inspectMode}
                        >
                            <div
                                id={html_id}
                                style={{
                                    textAlign: 'center',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    padding: '0',
                                    marginBottom: '20px',
                                }}
                            />
                        </ChartMaskInside>
                    </ChartMaskOutside>
                </HeatMapChartWrapper>
                <div style={{width: "100%", position: "relative", minHeight: 30}}>
                    <OverlayLabel align={'left'} tooltipPosition={'bottom'}>
                        {labels.bottomLeft}
                    </OverlayLabel>
                    <OverlayLabel align={'right'} style={{right: 0, width: "40px"}} tooltipPosition={'bottom'}>
                        {labels.bottomRight}
                    </OverlayLabel>
                </div>
            </ChartCont>
        </div>
    )
}

HeatMap.defaultProps = {
    default: {
        minDuration: 21,
    },
    dimension: {
        height: 200,
        width: 200,
        offset: 20,
    },
    label: {
        topLeft: '',
        topRight: '',
        bottomLeft: '',
        bottomRight: '',
    },
    inspectMode: false,

    coordinate: {height: 250, width: 250},
}

export default HeatMap
