import _ from 'lodash'
import {I18n} from "react-i18nify"
import i18nCountries from 'i18n-iso-countries'

export const DEFAULT_LANGUAGE = 'english'

export const TRANSLATIONS_MAP = {
    en: 'english',
    fi: 'finnish',
    es: 'spanish',
    de: 'german',
    pt: 'portuguese',
    tr: 'turkish',
}

export const LANGUAGE_CODE = _.invert(TRANSLATIONS_MAP)

export const LANGUAGE_TO_LABELS = {
    english: 'English',
    german: 'Deutsch',
    finnish: 'Suomi',
    spanish: 'Español',
    turkish: 'Türkçe',
    portuguese: 'Português',
}

export const translation = Object.keys(LANGUAGE_TO_LABELS).map((key) => {
    return {value: key, label: LANGUAGE_TO_LABELS[key]}
})

export const getFullLanguage = () => Object.keys(LANGUAGE_CODE).filter( e => LANGUAGE_CODE[e].toLowerCase() === I18n._localeKey)[0]

export const countries = () => {
    for (const langCode in TRANSLATIONS_MAP) {
        i18nCountries.registerLocale(require(`i18n-iso-countries/langs/${langCode}.json`))
    }
    const countryNames = Object.keys(i18nCountries.getAlpha2Codes())
        .map( countryCode => ({code: countryCode,name: i18nCountries.getName(countryCode, I18n._localeKey, {select: "official"})}))
        .sort( (a,b) => a.name.localeCompare(b.name))
    
    countryNames.push({code: '___', name: I18n.t('billing.demographics.others')})
    
    return countryNames;
}



import analysisEN from '../../locales/en/analysis.en.json';
import analysisDE from '../../locales/de/analysis.de.json';
import analysisES from '../../locales/es/analysis.es.json';
import analysisTR from '../../locales/tr/analysis.tr.json';
import analysisPT from '../../locales/pt/analysis.pt.json';
import analysisFI from '../../locales/fi/analysis.fi.json';

import appEN from '../../locales/en/app.en.json';
import appDE from '../../locales/de/app.de.json';
import appES from '../../locales/es/app.es.json';
import appTR from '../../locales/tr/app.tr.json';
import appPT from '../../locales/pt/app.pt.json';
import appFI from '../../locales/fi/app.fi.json';

import billingEN from '../../locales/en/billing.en.json';
import billingDE from '../../locales/de/billing.de.json';
import billingES from '../../locales/es/billing.es.json';
import billingTR from '../../locales/tr/billing.tr.json';
import billingPT from '../../locales/pt/billing.pt.json';
import billingFI from '../../locales/fi/billing.fi.json';

import captureEN from '../../locales/en/capture.en.json';
import captureDE from '../../locales/de/capture.de.json';
import captureES from '../../locales/es/capture.es.json';
import captureTR from '../../locales/tr/capture.tr.json';
import capturePT from '../../locales/pt/capture.pt.json';
import captureFI from '../../locales/fi/capture.fi.json';

import chartsEN from '../../locales/en/charts.en.json';
import chartsDE from '../../locales/de/charts.de.json';
import chartsES from '../../locales/es/charts.es.json';
import chartsTR from '../../locales/tr/charts.tr.json';
import chartsPT from '../../locales/pt/charts.pt.json';
import chartsFI from '../../locales/fi/charts.fi.json';

import dashboardEN from '../../locales/en/dashboard.en.json';
import dashboardDE from '../../locales/de/dashboard.de.json';
import dashboardES from '../../locales/es/dashboard.es.json';
import dashboardTR from '../../locales/tr/dashboard.tr.json';
import dashboardPT from '../../locales/pt/dashboard.pt.json';
import dashboardFI from '../../locales/fi/dashboard.fi.json';

import formErrorEN from '../../locales/en/formError.en.json';
import formErrorDE from '../../locales/de/formError.de.json';
import formErrorES from '../../locales/es/formError.es.json';
import formErrorTR from '../../locales/tr/formError.tr.json';
import formErrorPT from '../../locales/pt/formError.pt.json';
import formErrorFI from '../../locales/fi/formError.fi.json';

import leadershipEN from '../../locales/en/leadership.en.json';
import leadershipDE from '../../locales/de/leadership.de.json';
import leadershipES from '../../locales/es/leadership.es.json';
import leadershipTR from '../../locales/tr/leadership.tr.json';
import leadershipPT from '../../locales/pt/leadership.pt.json';
import leadershipFI from '../../locales/fi/leadership.fi.json';

import loginEN from '../../locales/en/login.en.json';
import loginDE from '../../locales/de/login.de.json';
import loginES from '../../locales/es/login.es.json';
import loginTR from '../../locales/tr/login.tr.json';
import loginPT from '../../locales/pt/login.pt.json';
import loginFI from '../../locales/fi/login.fi.json';

import notificationsEN from '../../locales/en/notifications.en.json';
import notificationsDE from '../../locales/de/notifications.de.json';
import notificationsES from '../../locales/es/notifications.es.json';
import notificationsTR from '../../locales/tr/notifications.tr.json';
import notificationsPT from '../../locales/pt/notifications.pt.json';
import notificationsFI from '../../locales/fi/notifications.fi.json';

import profileEN from '../../locales/en/profile.en.json';
import profileDE from '../../locales/de/profile.de.json';
import profileES from '../../locales/es/profile.es.json';
import profileTR from '../../locales/tr/profile.tr.json';
import profilePT from '../../locales/pt/profile.pt.json';
import profileFI from '../../locales/fi/profile.fi.json';

import setupEN from '../../locales/en/setup.en.json';
import setupDE from '../../locales/de/setup.de.json';
import setupES from '../../locales/es/setup.es.json';
import setupTR from '../../locales/tr/setup.tr.json';
import setupPT from '../../locales/pt/setup.pt.json';
import setupFI from '../../locales/fi/setup.fi.json';

import sidebarEN from "../../locales/en/sidebar.en.json";
import sidebarDE from "../../locales/de/sidebar.de.json";
import sidebarES from "../../locales/es/sidebar.es.json";
import sidebarTR from "../../locales/tr/sidebar.tr.json";
import sidebarPT from "../../locales/pt/sidebar.pt.json";
import sidebarFI from "../../locales/fi/sidebar.fi.json";

import storiesEN from '../../locales/en/stories.en.json';
import storiesDE from '../../locales/de/stories.de.json';
import storiesES from '../../locales/es/stories.es.json';
import storiesTR from '../../locales/tr/stories.tr.json';
import storiesPT from '../../locales/pt/stories.pt.json';
import storiesFI from '../../locales/fi/stories.fi.json';

import strategyMapEN from '../../locales/en/strategyMap.en.json';
import strategyMapDE from '../../locales/de/strategyMap.de.json';
import strategyMapES from '../../locales/es/strategyMap.es.json';
import strategyMapTR from '../../locales/tr/strategyMap.tr.json';
import strategyMapPT from '../../locales/pt/strategyMap.pt.json';
import strategyMapFI from '../../locales/fi/strategyMap.fi.json';

import reportEN from '../../locales/en/report.en.json';
import reportDE from '../../locales/de/report.de.json';
import reportES from '../../locales/es/report.es.json';

export const LANGUAGES = {
    "en": {
        "analysis": analysisEN,
        "app": appEN,
        "billing": billingEN,
        "capture": captureEN,
        "charts": chartsEN,
        "dashboard": dashboardEN,
        "formError": formErrorEN,
        "leadership": leadershipEN,
        "login": loginEN,
        "notifications": notificationsEN,
        "profile": profileEN,
        "setup": setupEN,
        "sidebar": sidebarEN,
        "stories": storiesEN,
        "strategyMap": strategyMapEN,
        "report": reportEN
    },
    "de": {
        "analysis": analysisDE,
        "app": appDE,
        "billing": billingDE,
        "capture": captureDE,
        "charts": chartsDE,
        "dashboard": dashboardDE,
        "formError": formErrorDE,
        "leadership": leadershipDE,
        "login": loginDE,
        "notifications": notificationsDE,
        "profile": profileDE,
        "setup": setupDE,
        "sidebar": sidebarDE,
        "stories": storiesDE,
        "strategyMap": strategyMapDE,
        "report": reportDE
    },
    "es": {
        "analysis": analysisES,
        "app": appES,
        "billing": billingES,
        "capture": captureES,
        "charts": chartsES,
        "dashboard": dashboardES,
        "formError": formErrorES,
        "leadership": leadershipES,
        "login": loginES,
        "notifications": notificationsES,
        "profile": profileES,
        "setup": setupES,
        "sidebar": sidebarES,
        "stories": storiesES,
        "strategyMap": strategyMapES,
        "report": reportES
    },
    "tr": {
        "analysis": analysisTR,
        "app": appTR,
        "billing": billingTR,
        "capture": captureTR,
        "charts": chartsTR,
        "dashboard": dashboardTR,
        "formError": formErrorTR,
        "leadership": leadershipTR,
        "login": loginTR,
        "notifications": notificationsTR,
        "profile": profileTR,
        "setup": setupTR,
        "sidebar": sidebarTR,
        "stories": storiesTR,
        "strategyMap": strategyMapTR
    },
    "pt": {
        "analysis": analysisPT,
        "app": appPT,
        "billing": billingPT,
        "capture": capturePT,
        "charts": chartsPT,
        "dashboard": dashboardPT,
        "formError": formErrorPT,
        "leadership": leadershipPT,
        "login": loginPT,
        "notifications": notificationsPT,
        "profile": profilePT,
        "setup": setupPT,
        "sidebar": sidebarPT,
        "stories": storiesPT,
        "strategyMap": strategyMapPT
    },
    "fi": {
        "analysis": analysisFI,
        "app": appFI,
        "billing": billingFI,
        "capture": captureFI,
        "charts": chartsFI,
        "dashboard": dashboardFI,
        "formError": formErrorFI,
        "leadership": leadershipFI,
        "login": loginFI,
        "notifications": notificationsFI,
        "profile": profileFI,
        "setup": setupFI,
        "sidebar": sidebarFI,
        "stories": storiesFI,
        "strategyMap": strategyMapFI
    },
}

export const getLanguageHuman = (localCode, targetCode) =>
    require("@cospired/i18n-iso-languages/langs/"+localCode+".json").languages[targetCode];