import React from 'react'
import {I18n} from 'react-i18nify'
import Raven from 'raven-js'

import HeadingWithAction from './Components/HeadingWithAction'
import Priority from './Components/Priority'
import SquadOverlayTrigger from '../strategyMap/components/SquadOverlayTrigger'
import SquadRequest from '../strategyMap/components/SquadRequest'
import { TIME_AREAS } from '../strategyMap/const'
import {NODE_TYPES} from '../strategyMap/const'
import SquadModal from '../strategyMap/components/Modal/SquadModal'
import {isCSF, isUserAbleToEditNode, isUserAChampion, isUserASponsor} from '../strategyMap/utils'
import {useStrategyMapSlice} from '../strategyMap/hooks/useStrategyMapSlice'
import Champion from '../strategyMap/components/Champion'
import {useUserSlice} from '../strategyMap/hooks/useUserSlice'
import {ButtonSecondary} from '../core/components/Buttons'
import Targets from '../strategyMap/components/Targets'

const SF = (props) => {
    const {Actions, nodeMap, showModalSquad} = useStrategyMapSlice()
    const user = useUserSlice()
    const isAbleToEdit = isUserAbleToEditNode(user, props.node, nodeMap) && props.isEditMode
    const isCurrentUserChampion = isUserAChampion(user, props.node, nodeMap)

    const convertSFinto = (targetType) => {
        switch (targetType) {
            case NODE_TYPES.PSF:
            case NODE_TYPES.CSF:
                const updatedNode = {...props.node, sf_type: targetType}
                Actions.updateNode(updatedNode)
                break

            default:
                Raven.captureException(new Error('convertSFinto'), {
                    extra: {'error message': 'unknown typ (' + targetType + ')'},
                })
        }
    }

    const isValidating = props.node.time_area === TIME_AREAS.VALIDATING

    return (
        <div className={'sf'}>
            {props.isEditMode && (
                <SquadModal
                    isOpen={showModalSquad}
                    onRequestClose={() => Actions.setShowModalSquad(false)}
                    node={props.node}
                    squadMembers={props.node.squad}
                    isUserChampion={isCurrentUserChampion}
                    isUserSponsor={isUserASponsor(user, props.node, nodeMap)}
                    totalChampions={props.node.champions_data && props.node.champions_data.length}
                />
            )}
            {props.node.type === NODE_TYPES.PSF &&
                isCurrentUserChampion &&
                isValidating &&
                isAbleToEdit && (
                    <ButtonSecondary
                        fullWidth
                        data-testid={'sf-convertToCSF-button'}
                        onClick={() => convertSFinto(NODE_TYPES.CSF)}
                    >
                        {I18n.t('strategyMap.nodes.convertToCSF')}
                    </ButtonSecondary>
                )}

            {props.node.type === NODE_TYPES.CSF &&
                isCurrentUserChampion &&
                isValidating &&
                isAbleToEdit && (
                    <ButtonSecondary
                        fullWidth
                        data-testid={'sf-convertToPSF-button'}
                        onClick={() => convertSFinto(NODE_TYPES.PSF)}
                    >
                        {I18n.t('strategyMap.nodes.convertToPSF')}
                    </ButtonSecondary>
                )}

            {props.isEditMode && (
                <>
                    <HeadingWithAction title={I18n.t('strategyMap.nodes.champion')} />

                    <Champion
                        node={props.node}
                        isEditMode={props.isEditMode}
                        isAbleToEdit={isAbleToEdit}
                    />

                    {(isCurrentUserChampion || (props.node.targets && props.node.targets.length > 0)) && <>
                        <HeadingWithAction title={I18n.t('strategyMap.nodes.targets')} />
                        <Targets
                            node={props.node}
                            isEditMode={props.isEditMode}
                            isAbleToEdit={isAbleToEdit}
                        />
                    </>}

                    <HeadingWithAction
                        title={I18n.t('strategyMap.nodes.improvementSquad')}
                        showAction={isAbleToEdit}
                        icon={'edit'}
                        iconOutlined
                        onClick={() => Actions.setShowModalSquad(true)}
                    />

                    <SquadOverlayTrigger node={props.node} />

                    <SquadRequest node={props.node} />
                </>
            )}

            {isUserASponsor(user, props.node, nodeMap, true) &&
                !props.isReadOnly &&
                isCSF(props.node) &&
                props.node.time_area === TIME_AREAS.CONFIRMED && (
                    <>
                        <HeadingWithAction title={I18n.t('strategyMap.nodes.priority')} />
                        <Priority node={props.node} />
                    </>
                )}
        </div>
    )
}

export default SF
