export const LEADERSHIP_ASSESSMENT_KEY = 'de455352-5224-4400-bbe8-2f04069b94b3'

export const PAGES = {
    Home: '',
    Dashboard: 'dashboard',
    StrategyMap: 'strategy-map',
    Billing: 'billings',
    NoPage: 'NoPage',
    OrgScan: {
        Dashboard: 'orgscan',
        Analysis: 'orgscan/analysis',
        Capture: 'orgscan/capture'
    },
    LeaderShip: {
        Dashboard: 'leadership-assessment',
        Analysis: 'leadership-assessment/analysis',
        Capture: 'leadership-assessment/capture/' + LEADERSHIP_ASSESSMENT_KEY,
        Report: 'leadership-assessment/${id}',
        SelfAssessment: 'selfassessment'
    }
}

export const SUBSCRIPTIONS = {
    LeadershipTeam: 'LeaderShip - Team',
    LeadershipFull: 'LeaderShip - Full',
}

export const ROLES = {
    a42User: "is_a42",
    admin: "is_admin",
    adminLeadership: "is_admin_for_leadership",
    analyst: "is_analyst",
    reporter: "is_reporter",
    strategist: "is_strategist",

    strategyMap: {
        sponsor: "isUserSponsor",
        champion: "isUserChampion",
        squadMember: "squadMember",
        experimentMember: "experimentMember"
    },
    dau: "is_dau"
};
export const RESTRICTIONS = {
    Analysis: {
        MAX_TEAMS_ALLOWED_FOR_COMPARISON: 3
    },
    Leadership: {
        MAX_ADMINS_ALLOWED: {
            TEAM_VERSION: 1,
            FULL_VERSION: 15
        },
        MAX_TEAMS_ALLOWED: {
            TEAM_VERSION: 1,
            FULL_VERSION: 50
        },
        MAX_TEAM_MEMBERS_ALLOWED: {
            TEAM_VERSION: 12,
            FULL_VERSION: 12
        },
        MAX_GROUPS_ONE_PERSON_CAN_JOIN: 5,
        LEADERSHIP_STYLE_COMPARISON: 10,
        RULES: {
            CAN_SEE_ALL_TEAMS: [ROLES.adminLeadership],
            CAN_ANALYSE_DATA: [ROLES.adminLeadership],
            CAN_ANALYSE_DATA_COMPARISON: [ROLES.admin]
        }
    },
    OrgScan: {
        Report: {
            REGROUP_DEMOGRAPHICS_ON_LOW_AMOUNT_OF_DECISIONS_BY: {
                department: 15,
                role: 15
            }
        },
        RULES: {
            CAN_ANALYSE_DATA: [ROLES.analyst],
            CAN_GENERATE_REPORT: [ROLES.reporter],
            CAN_GENERATE_HiResIMAGES: [ROLES.reporter]
        }
    },
    StrategyMap: {
        MAX_SPONSORS_ALLOWED: 2,
        MAX_CHAMPION_ALLOWED: 2,
        RULES: {
            // not connected yet
            CAN_ADMINISTRATE_STRATEGY_MAP: [ROLES.admin],
            CAN_INVITE_TO_SQUAD: [ROLES.strategist],
            CAN_INVITE_TO_SQUAD_FORCED: [ROLES.admin],
            CAN_EDIT_ON_STRATEGY_MAP: [ROLES.strategist]
        },
        RULES_APP: {
            // not connected yet
            CAN_REMOVE_INTEGRATION: [ROLES.strategyMap.champion, ROLES.strategyMap.squadMember, ROLES.strategyMap.sponsor],
            CAN_INVITE_TO_SQUAD: [ROLES.strategyMap.sponsor, ROLES.strategyMap.champion],
            CAN_CHANGE_EXPERIMENT_DEADLINE: [ROLES.strategyMap.champion, ROLES.strategyMap.squadMember, ROLES.strategyMap.champion],
            CAN_CONVERT_NODE: [ROLES.strategyMap.champion],
            CAN_CHANGE_PRIORITY_NODES: [ROLES.strategyMap.sponsor],
            CAN_MANAGE_SPONSORS: [ROLES.strategyMap.sponsor]
        }
    },
    Demographics: {
        MAX_ROLE: 7,
        MAX_DEPARTMENT: 12
    },
    Settings: {
        CAN_SEE_LICENSE: [ROLES.a42User, ROLES.admin, ROLES.adminLeadership],
        CAN_SEE_ROLES_OVERVIEW: [ROLES.a42User],

        CAN_MANAGE_ADMIN: [ROLES.admin],
        CAN_MANAGE_ANALYST: [ROLES.admin, ROLES.analyst],
        CAN_MANAGE_LEADERSHIP: [ROLES.admin, ROLES.adminLeadership],

        CAN_MANAGE_USERS: [ROLES.a42User, ROLES.admin, ROLES.adminLeadership],
        CAN_MANAGE_TEAMS: [ROLES.a42User, ROLES.admin, ROLES.adminLeadership],

        CAN_MANAGE_DEMOGRAPHICS: [ROLES.a42User, ROLES.admin],
        CAN_MANAGE_SYSTEM: [ROLES.a42User],
        CAN_MANAGE_INTEGRATIONS: [ROLES.admin],
        CAN_CHANGE_VERSION: [ROLES.admin]
    }
}

export const LEADERSHIP_ASSESSMENT_PRICES = {
    team: "7.42",
    full: "9.42",
    currency: "€"
}

export const LEADERSHIP_ASSESSMENT_EMAIL_TYPES = [ 'rightaway', 'weekly', 'none']

export const TOOLBAR_CONFIG = {
    // Optionally specify the groups to display (displayed in the order listed).
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
            ['link', 'image'],
            ['clean'],
        ],
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
        },
    },

    formats: [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'mention',
    ],
}

export const Z_INDICES = {
    PageReloadPrompt: 201,
    Node: 200,
}

export const HOUR_IN_MILLISECONDS = 3600000

export const formStatuses = {
    FIRST_LOAD: 'FIRST_LOAD',
    SUBMITTED: 'SUBMITTED',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    SERVER_ERROR: 'SERVER_ERROR',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
}

export const DASHBOARD_OPTIONS = {full: 'full', lite: 'lite'};