import React, {useEffect} from 'react'
import {SubTypes} from "../ChartsConfig";
import {DecisionAnswers} from "../StackedBar/agileBarConfig";
import {I18n} from "react-i18nify";
import {TRANSLATIONS_MAP} from '../../core/utils/language';
import { Div } from '../../core/ui/Div';

export const orderPie = (points, order, translateLabel) => {
    const orderedPoints = {}

    // order points
    order.forEach((label) => {
        const point = Object.keys(points).find(point => point === label)
        if(point && points[point])
            orderedPoints[translateLabel ?  I18n.t('charts.Answers.' + label) : label] = points[point]
    })

    Object.keys(points).forEach((point) => {
        const unsortedPoint = order.find(
            (label) => label === point.name,
        )
        if (unsortedPoint === undefined) {
            orderedPoints[translateLabel ?  I18n.t('charts.Answers.' + point) : point] = points[point]
        }
    })
    return orderedPoints
}

const PrintablePiecharts = (props) => {
    useEffect(() => {
        const plotly = require('plotly.js')

        let dataPoints = {}

        switch (props.subType) {
            case SubTypes.bar.period:
                dataPoints =  orderPie(props.data, DecisionAnswers.speed, true)
                break;
            case SubTypes.bar.year:
                dataPoints =  orderPie(props.data, props.demographics.years.sort( (a,b) => (a.position - b.position)).map( e => e[TRANSLATIONS_MAP[I18n._localeKey]] || e.name), false)
                break;
            default:
                dataPoints =  props.data
        }

        plotly.newPlot(
            props.testId,
            [
                {
                    values: Object.values(dataPoints),
                    labels: Object.keys(dataPoints),
                    type: 'pie',
                    sort: false,
                    marker: {
                        colors: props.colorScheme,
                    },
                },
            ],
            {
                height: 400,
                width: 500,
                hovermode: false
            },
            {
                showTips: false,
                displayModeBar: false
            },
        )
    }, [])

    return <Div height={'100%'} width={'100%'} position={'relative'} data-testid={props.testId} id={props.testId}></Div>
}

export default PrintablePiecharts
