import React, {useEffect, useMemo, useState} from 'react'
import {I18n} from 'react-i18nify'
import TextInput from '../../core/ui/form/TextInput'
import {getNodeDisplay} from '../../strategyMap/utils'
import {useStrategyMapSlice} from '../../strategyMap/hooks/useStrategyMapSlice'
import {validURL} from '../../core/utils/network'
import {useNode} from '../../strategyMap/hooks/useNode'
import SelectInput from '../../core/ui/form/SelectInput'
import {useIntegrationsSlice} from '../hooks/useIntegrationsSlice'
import {Modal} from '../../core/ui/modal/Modal'
import SelectWrapper from '../../core/components/SelectWrapper'

const CustomLink = (props) => {
    const [errors, setErrors] = useState(false)
    const [url, setUrl] = useState('')
    const {node} = useNode()
    const {Actions} = useStrategyMapSlice()

    useEffect(() => {
        if (validURL(url)) setErrors(false)
    }, [url])

    const resetStates = () => {
        setUrl('')
        setErrors(false)
    }

    const handleConfirm = () => {
        if (!validURL(url)) {
            setErrors(true)
            return false
        }
        Actions.addNodeIntegration({
            url: url,
            description: props.tool === 'trello' ? 'Trello Board' : 'Slack Channel',
            tool: props.tool,
            node: node.id,
        })
        resetStates()
        props.onClose()
    }

    return (
        <Modal 
            isOpen={ props.isOpen }
            title={props.title}
            footer={{
                confirmText: I18n.t('strategyMap.messages.ok'),
                onConfirm: handleConfirm,
                onCancel: () => {
                    resetStates()
                    props.onClose()
                }
            }}
        >
            {props.selectComponent}
            <br />
            <TextInput
                errors={errors}
                errorMessage={I18n.t('strategyMap.nodes.EnterUrl')}
                label="URL"
                placeholder={'http://example.com'}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
            />
        </Modal>
    )
}

const NewTrelloBoard = (props) => {
    const {Actions} = useStrategyMapSlice()
    const integrationsSlice = useIntegrationsSlice()
    const {node} = useNode()
    const initialBoardName = !node || getNodeDisplay(node) + ' ' + node.title
    const [boardName, setBoardName] = useState(initialBoardName)
    const [workspaceId, setWorkspaceId] = useState('')
    const [boardNameError, setBoardNameError] = useState(false)
    const [workspaceIdError, setWorkspaceIdError] = useState(false)

    useEffect(() => {
        integrationsSlice.Actions.fetchTrelloWorkspaces()
    }, [])

    const trelloWorkspacesOptions = useMemo(() => {
        return Array.isArray(integrationsSlice.trelloWorkspaces) ? 
                integrationsSlice.trelloWorkspaces.map(
                    (workspace) => ({ value: workspace.id, label: workspace.name })
                ) : 
                []
    }, [integrationsSlice.trelloWorkspaces])

    useEffect(() => {
        if (boardName) setBoardNameError(false)
        if (workspaceId) setWorkspaceIdError(false)
    }, [boardName, workspaceId])

    return (
        <Modal 
            isOpen={ props.isOpen }
            title={props.title}
            footer={{
                confirmText: I18n.t('strategyMap.messages.ok'),
                onConfirm: () => {
                    if (boardName && workspaceId) {
                        setBoardNameError(false)
                        setWorkspaceIdError(false)
                        Actions.createTrelloBoard({
                            board_name: boardName,
                            node_id: node.id,
                            workspace_id: workspaceId,
                        })
                        props.onClose()
                    } else {
                        if (!boardName) setBoardNameError(true)
                        if (!workspaceId) setWorkspaceIdError(true)
                    }
                },
                onCancel: () => {
                    setBoardNameError(false)
                    setWorkspaceIdError(false)
                    props.onClose()
                }
            }}
        >
            {props.selectComponent}
            <br/>
            {node && (
                <>
                    <TextInput
                        errors={boardNameError}
                        errorMessage={I18n.t('formError.validationBlank')}
                        label={I18n.t('strategyMap.nodes.boardName')}
                        onChange={(e) => setBoardName(e.target.value)}
                        value={boardName}
                    />
                    <SelectInput
                        errors={workspaceIdError}
                        value={
                            workspaceId === ''
                                ? 0
                                : trelloWorkspacesOptions.filter(
                                      (workspace) => workspace.value === workspaceId,
                                  )
                        }
                        label={'Workspace'}
                        options={trelloWorkspacesOptions}
                        onChange={(e) => setWorkspaceId(e.value)}
                    />
                </>
            )}
        </Modal>
    )
}

const NewSlackChannel = (props) => {
    const {Actions} = useStrategyMapSlice()
    const {node} = useNode()

    return (
        <Modal 
            isOpen={ props.isOpen }
            title={props.title}
            footer={{
                confirmText: I18n.t('strategyMap.messages.ok'),
                onConfirm: () => {
                    Actions.connectSlackChannel(node.id)
                    props.onClose()
                },
                onCancel: props.onClose
            }}
        >
            {props.selectComponent}
        </Modal>
    )
}

const ConnectToTool = (props) => {
    const [isTrello, isSlack] = [props.tool === 'Trello', props.tool === 'Slack']
    const [useCustomLink, setUseCustomLink] = useState(!!isTrello)

    const createNewLabel = isTrello ? I18n.t('strategyMap.nodes.CreateANewBoard') : I18n.t('strategyMap.nodes.CreateANewChannel')
    const options = [
        {label: createNewLabel, value: 'create_new'},
        {label: I18n.t('strategyMap.nodes.UseExistingUrl'), value: 'use_existing'}
    ]
    const SelectAction = <SelectWrapper
                            onChange={ (e) => setUseCustomLink(e.value === 'use_existing') }
                            defaultValue={ options[0] }
                            options={ options }
                        />

    return (
        <>
            {useCustomLink && 
                <CustomLink 
                    onClose={props.onClose} 
                    tool={props.tool} 
                    selectComponent={SelectAction} 
                    isOpen={isSlack || isTrello} 
                    title={props.title} 
                />}
            {!useCustomLink && isTrello && 
                <NewTrelloBoard 
                    onClose={props.onClose} 
                    selectComponent={SelectAction} 
                    isOpen={isSlack || isTrello} 
                    title={props.title} 
                />}
            {!useCustomLink && isSlack && 
                <NewSlackChannel 
                    onClose={props.onClose} 
                    selectComponent={SelectAction} 
                    isOpen={isSlack || isTrello} 
                    title={props.title} 
                />}
        </>
    )
}

export default ConnectToTool
