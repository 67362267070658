import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const _Input = styled.textarea`
    border: 0px;
    margin: 0 10px;
    width: calc(100% - 72px);
    line-height: 20px;
    float: none;
    font-weight: 400;
    font-size: 16px;
    outline: none;
    resize: none;
    box-shadow: none;
`
const _Text = styled.div`
    height: 100%;
    padding-top: 2.5px;
    color: ${(p) => (p.isEmpty ? 'lightgray' : '')};
    margin: 0 10px;
    width: calc(100% - 72px);
    line-height: 20px;
    float: none;
    font-weight: 400;
`

const ClickableInput = (props) => {
    const [text, setText] = useState(props.value || '')
    const [hasFocus, setFocus] = useState(props.isEditing)

    const onChange = (e) => {
        setText(e.target.value.replace(/(?:\r\n|\r|\n)/g, ' '))
        setFocus(true)
        fixTextareaHeight(e)
    }

    const onKeyPress = (e) => {
        if (e.key !== 'Enter') return
        // same behavior for focus out and Enter key
        onBlur()
    }

    const onBlur = () => {
        const hasChanges = (props.value || '') !== text
        if (hasChanges) {
            if (text !== '') {
                props.onChangeFinished({text: text, done: false})
            } else {
                props.onChangeFinished()
            }
        }
        setFocus(false)
    }

    const fixTextareaHeight = (e) => {
        const extraHeightToPreventScroll = 4
        e.target.style.height = 'inherit'
        e.target.style.height = `${e.target.scrollHeight + extraHeightToPreventScroll}px`
    }

    if (hasFocus) {
        return (
            <_Input
                type="text"
                autoFocus
                rows={1}
                onFocus={fixTextareaHeight}
                data-testid={'ClickableInput-input'}
                value={text}
                placeholder={props.placeholderText}
                onChange={onChange}
                onKeyPress={onKeyPress}
                onBlur={onBlur}
            />
        )
    } else {
        return (
            <_Text onClick={() => setFocus(true)} isEmpty={text.trim() === ''}>
                {text.trim() === '' ? props.placeholderText : text}
            </_Text>
        )
    }
}

ClickableInput.propTypes = {
    placeholderText: PropTypes.string, // printed only if value is empty
    value: PropTypes.string,
    onChangeFinished: PropTypes.func,
    onFocusOut: PropTypes.func,
    showAddIcon: PropTypes.bool,
    isEditing: PropTypes.bool,
    resetToPlaceholderAfterEdit: PropTypes.bool,
}

ClickableInput.defaultProps = {
    showAddIcon: false,
}

export default ClickableInput
