import React, {useEffect} from 'react'
import 'react-rangeslider/lib/index.css'
import {I18n} from 'react-i18nify'
import {Inspect} from './inspect/Inspect'
import Filters from './Filter/Filters'
import Overview from './overview/Overview'
import {useAnalysisSlice} from './hooks/useAnalysisSlice'
import {useDashboardSlice} from '../dashboard/hooks/useDashboardSlice'
import Reports from './reports/Reports'
import {useUserSlice} from '../strategyMap/hooks/useUserSlice'
import {PAGES, RESTRICTIONS} from '../core/const'
import {Div} from '../core/ui/Div'
import {useCoreSlice} from "../core/hooks/useCoreSlice";
import A42Tabs from "../core/components/Tabs";
import { Panel } from '../core/components/BootstrapWrapper'
import { Container } from '../core/ui/_global'
import {useLanguageSlice} from "../core/hooks/useLanguageSlice";

const NoData = () => {
    return <Panel>{I18n.t('analysis.messages.noDataToAnalyze')}</Panel>
}

export const selectBoxStyling = {
    control: (styles) => ({
        ...styles,
        fontWeight: 'bold',
        fontSize: '20px',
    }),
    indicatorSeparator: (styles) => ({...styles, backgroundColor: 'white'}),
}

export const Analysis = () => {

    const {decisions, Actions: DashboardActions} = useDashboardSlice();
    const user = useUserSlice();
    const {Actions: CoreActions} = useCoreSlice();
    const analysisSlice = useAnalysisSlice();
    const {current} = useLanguageSlice();

    useEffect(() => {
        if (decisions.length === 0) {
            DashboardActions.fetchDecisions()
        }
        analysisSlice.Actions.fetchClientStatistics()
    }, [])

    useEffect(() => {
        if (user.pk && ! RESTRICTIONS.OrgScan.RULES.CAN_ANALYSE_DATA.some( role => user[role] )) {
            CoreActions.gotoPage(PAGES.OrgScan.Dashboard)
        }
    }, [user.pk])

    return (
        <Container className="container" data-testid="billing-page">
            <Div padding={'0 14px'}>
                <Filters showPeriods={true} />
            </Div>
            {decisions.length === 0 ? (
                <NoData />
            ) : (
            <A42Tabs
                tabs={[
                    {
                        key: 'overview',
                        title: I18n.t('analysis.tab.overview'),
                        content: <Overview/>
                    },
                    {
                        key: 'inspectCulture',
                        title: I18n.t('analysis.tab.inspect'),
                        content: <Inspect/>
                    },
                    {
                        key: 'reports',
                        title: I18n.t('analysis.tab.diagramsReports'),
                        content: <Reports language={current} />
                    }
                ]}
            />
            )}
        </Container>
    )
}
