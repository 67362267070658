import PropTypes from 'prop-types'
import React from 'react'
import {I18n} from 'react-i18nify'
import 'react-rangeslider/lib/index.css'
import DurationFilterComponent from './DurationFilterComponent'
import CompareFilterComponent from './CompareFilterComponent'
import {DURATION_OPTIONS} from '../Dashboard'
import StoryCounter from '../StoryCounter'
import {Div} from '../../../core/ui/Div'
import {FilterRow, Title,} from './DashboardFiltersContainer.styles'
import StrategyMapSelect from './StrategyMapSelect'
import Filters from "../../../analysis/Filter/Filters";

const DashboardFiltersContainer = (props) => {
    const applyFilters = (newFilterList) => {
        if (newFilterList.experiment.length === 0 && newFilterList.duration === 0) {
            newFilterList.duration = DURATION_OPTIONS.THREE_MONTHS
        }
        props.handleFiltersChange(newFilterList)
    }

    if (JSON.stringify(props.decisions) === '[]' || props.loading) {
        return <div />
    }
    return (
        <Div className={'dashboard-filters'}>
            <FilterRow className={'filter-row clearfix'}>
                <Div paddingLeft={'0px'} className="col-6 col-md-6 col-xs-12">
                    <Title>{I18n.t('dashboard.Dashboard')}</Title>
                    <StoryCounter
                        filteredCount={props.filteredDecisionCount}
                        count={props.decisionsCount}
                    />
                </Div>
                <Div paddingRight={'0px'} className="col-6 col-md-6 col-xs-12">
                    <DurationFilterComponent
                        appliedFilters={props.appliedFilters}
                        handleFiltersChange={applyFilters}
                    />
                </Div>
                <Div paddingRight={'0px'} className="col-6 col-md-6 col-xs-12">
                    <StrategyMapSelect />
                </Div>
            </FilterRow>

            <FilterRow className={'filter-row clearfix'}>
                <div className={'filters'}>
                    <Filters />
                </div>
                <CompareFilterComponent
                    handleFiltersChange={applyFilters}
                    isCompared={props.isCompared}
                    appliedFilters={props.appliedFilters}
                    clickCompareCallback={props.clickCompareCallback}
                />
            </FilterRow>
        </Div>
    )
}

DashboardFiltersContainer.propTypes = {
    loading: PropTypes.bool,
    isCompared: PropTypes.bool,
    decisions: PropTypes.array,
    demographics: PropTypes.object,
    appliedFilters: PropTypes.object,
    handleFiltersChange: PropTypes.func,
    removeAppliedFilterCallback: PropTypes.func,
    clickCompareCallback: PropTypes.func,
    filteredDecisionCount: PropTypes.number,
    changeDurationOptionCallback: PropTypes.func,
    resetFiltersCallback: PropTypes.func,
}

DashboardFiltersContainer.defaultProps = {}

export default DashboardFiltersContainer
