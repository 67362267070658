import styled from 'styled-components'
import {Badge, Nav, Navbar, NavItem} from 'react-bootstrap'
import {DARK_GREY, LIGHT_GREY, MAGENTA, MID_GREY, WHITE} from '../../ui/_colors'
import {Link} from "react-router-dom";

export const MainNavbar = styled.div`
    background-color: ${WHITE};
    position: fixed;
    width: ${props => props.reduceWidth ? 'calc(100vw - '+props.reduceWidth+'px)' : '100vw'};
    top: 0;
    left: 0;
    z-index: 3000;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

    @media (min-width: 320px) {
        .navbar-left li {
            &.back {
                width: 35px;
                margin-right: 10px;
            }
            .back-icon {
                margin-left: -20px;
            }
        }
    }

    @media (min-width: 411px) {
        .navbar-left li {
            &.back {
                width: 45px;
                margin-right: 15px;
            }
            .back-icon {
                margin-left: -15px;
            }
        }
    }

    @media (min-width: 576px) {
        .navbar-right {
            display: inline-flex;
            li {
                width: 60px;
                height: 60px;
                a img {
                    margin: 0px;
                }
            }
        }
    }
`

export const MainNavIcon = styled.i`
    font-size: 30px;
    color: ${DARK_GREY};
`

export const BackNavIcon = styled(MainNavIcon)`
    font-size: 50px;
    display: table;
    margin: 0 auto;
`

export const Centering = styled.div`
    margin-left: auto;
    margin-right: auto;
`

export const CenterContainer = styled.div`
    padding-right: 10px;
    min-width: 300px;
    align-items: center;
    display: grid;
    * {margin-bottom: unset};
`

export const CenterTitle = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    p {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
    @media (min-width: 576px) {
        font-size: 18px;
    }
    @media (min-width: 768px) {
        font-size: 24px;
    }
`

export const NavbarBrand = styled(Navbar.Brand)`
    margin-top: -5px;
    padding-left: 15px;
    margin-left: -10px;
    @media (min-width: 576px) {
        margin-left: 0px !important;
    }
    @media (min-width: 768px) {
        margin-left: 0px !important;
    }
`

export const PageHeader = styled(Nav)`
    height: 60px;
    font-size: 18px;
    margin: 0;
    font-weight: 500;
    padding-left: 15px;
    padding-bottom: 0;
    border-bottom: none;
    word-wrap: break-word;
    max-width: calc(50vw - 75px);
    @media (min-width: 320px) {
        font-size: 14px;
    }
    @media (min-width: 411px) {
        font-size: 18px;
    }
    border-left: 1px solid ${LIGHT_GREY};
    vertical-align: middle;
    display: table-cell;
`

export const NavContainer = styled(Nav)`
    @media (min-width: 768px) {
        width: auto !important;
    }
    padding-left: 15px;
`

export const SenseMakerLogo = styled.div`
    margin-top: 0px;
    padding-right: 6px;
    padding-left: 6px;
`

export const SettingsIcon = styled.span`
    position: absolute;
    height: 20px !important;
    width: 20px;
    top: 10%;
    margin-right: -20px;
    background-color: ${WHITE};
    border: 1px solid ${WHITE};
    border-radius: 50%;
    font-size: 18px;
`

export const NotificationBadge = styled.div`
    position: absolute;
    padding-right: auto;
    padding-left: auto;
    .badge {
        background-color: ${MAGENTA};
        position: absolute;
        top: -8px;
        font-size: 10px;
        min-width: 15px;
        padding: 3px;
        height: 20px !important;
        
        width: 20px;
        top: 7px;
        border-radius: 50%;
        margin: 0!important;
    }
`

export const NavItemBox = styled(NavItem)`
    text-align: center;
    line-height: 0;
    height: inherit;
`;

export const BadgeBox = styled(Badge)`
    position: absolute;
    top: 10%;
    margin-right: -20px;
    background-color: ${MAGENTA};
`;
export const NavBoxLink = styled(Link)`
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const Symbol = styled.div`
    font-size: 16px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 10px;
`;

export const CadenceDetails = styled.div`
    float: left;
    margin-left: 50px;
    margin-top: 21px;
    color: ${MID_GREY};
    font-size: 14px;
    * {
        float: left;
    }
    p {
        padding-right: 20px;
    }
`;