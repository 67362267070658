import React, {useEffect, useState} from 'react'
import LatexTextBox from "./LatexTextBox"
import {ButtonSecondary, IconButton} from "../../core/components/Buttons"
import {I18n} from "react-i18nify"
import { ID, InputContainer } from './Reports.styles'

const LatexItemizeBox = ({defaultValue, placeholder, onChange, hint}) => {

    const [value, setValue] = useState( defaultValue || "" )

    useEffect( () => setValue( defaultValue ? defaultValue.replaceAll( "\\item ", "") : ""), [defaultValue] )

    const _onChange = (text,index) => {
        const _value = value.split("\n");
        _value[index] = text
        setValue( _value.join("\n") )
        onChange( "\\item " + _value.join("\n\\item ") )
    }

    const _onDelete = (index) => {
        const _value = value.split("\n").filter( (e,i) => i !== index)
        setValue( _value.join("\n") )
        onChange( "\\item " +  _value.join("\n\\item ") )
    }

    const _onAddNewRow = () => {
        const _value = value.split("\n")
        _value.push("")
        setValue( _value.join("\n") )
        onChange( "\\item " +  _value.join("\n\\item ") )
    }

    return (
        <div style={{display: 'flow-root'}}>
            { value
                .split("\n")
                .map( (value,key) =>
                    <div key={key} style={{clear: "both"}}>
                        <ID>{key + 1}.</ID>
                        <InputContainer>
                            <LatexTextBox
                                rows={5}
                                key={key}
                                placeholder={placeholder}
                                defaultValue={value}
                                hint={hint}
                                onChange={ value => _onChange(value,key) }
                            />
                        </InputContainer>
                        <div>
                            <IconButton
                                onClick={() => _onDelete(key)}
                                icon="clear" />
                        </div>
                    </div>)
            }
            <div style={{width: '100%', height: '50px'}}>
                <ButtonSecondary onClick={_onAddNewRow}>{I18n.t("analysis.buttons.addNewItem")}</ButtonSecondary>
            </div>
        </div>
    )
}

export default LatexItemizeBox