import styled, {css} from 'styled-components'
import {BLACK_GREY, BRIGHT_GREY, DARK_GREY, LIGHT_GREY, MAGENTA, WHITE,} from '../../../core/ui/_colors'
import {Div} from '../../../core/ui/Div'
import {Input} from '../../../core/ui/generic'

export const DemographicsDropdown = styled(Div)`
    display: none;
    width: 100%;
    ${(props) =>
        props.isOpen &&
        css`
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            background-color: ${WHITE};
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
            -webkit-transition: width 2s ease-in-out;
            transition: width 2s ease-in-out;
        `}
    &.mobile {
        width: 100vw;
        height: calc(100vh - 150px);
        background-color: ${WHITE};
        display: flex;
        flex-direction: column;
        position: relative;
        flex-wrap: nowrap;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
        -webkit-transition: width 2s ease-in-out;
        transition: width 2s ease-in-out;
    }
`

export const FiltersMobile = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
`

export const FilterMobileItem = styled.div`
    background-color: ${BRIGHT_GREY};
    flex: none;
    padding: 0px 12px;
    border-left: 1px solid ${LIGHT_GREY};
    height: 43px;
    line-height: 43px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 2px;
`

export const ArrowImgMobile = styled(Div)`
    float: right;
    width: 30px;
    height: 100%;
`

export const LabelFilterMobile = styled(Div)`
    color: ${BLACK_GREY};
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    line-height: 30px;
    &.true {
        font-weight: bold;
    }
`

export const CloseFilter = styled(Div)`
    width: 54px;
    border-left: 1px solid ${LIGHT_GREY};
    padding: 15px;
`

export const FilterItem = styled(Div)`
    position: relative;
    flex: auto;
    border-left: 1px solid ${LIGHT_GREY};
    cursor: pointer;

    .badge {
        background-color: ${MAGENTA};
        font-size: 10px;
        font-weight: normal;
        vertical-align: top;
        padding: 3px 6px;
    }
`

export const ArrowImg = styled(Div)`
    position: absolute;
    right: 0;
    top: 13px;
`

export const DemographicsDemographicDropdown = styled(Div)`
    max-height: 360px;
    position: absolute;
    background-color: ${WHITE};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.32);
    z-index: 999;
    overflow: hidden;
    @media only screen and (max-width: 992px) {
        width: 100%;
        max-height: 300px;
        top: 43px;
        left: 0;
        position: inherit;
    }
`

export const SearchContainer = styled(Div)`
    display: inline-flex;
    border-bottom: 1px solid ${LIGHT_GREY};
    height: 56px;
    padding: 16px 12px 16px 24px;
    width: 100%;
    @media only screen and (max-width: 992px) {
        float: left;
        height: 43px;
        padding: 6px 12px 6px 24px;
        img {
            margin-top: 4px;
        }
        input[placeholder] {
            font-size: 16px;
        }
    }
`

export const SearchInput = styled(Input)`
    padding-left: 5px;
    border: none;
    min-width: 240px;

    &:focus {
        border: none;
        outline: none !important;
    }
    @media only screen and (max-width: 992px) {
        float: left;
        line-height: 24px;
    }
`

export const Title = styled(Div)`
    font-size: 32px;
    font-weight: 500;
    line-height: 1em;
    @media only screen and (max-width: 414px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 992px) {
        padding: 0;
    }
`

export const Filter = styled(Div)`
    float: left;
    width: 185px;
    height: 56px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 17px 19px;
    cursor: pointer;
    background-color: ${WHITE};
    margin-right: 12px;
`

export const Label = styled(Div)`
    color: ${BLACK_GREY};
    font-size: 16px;
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
    text-align: left;
    padding: 0.2em 0 0.3em;
`

export const LabelLH = styled(Label)`
    line-height: 30px;
`

export const ExperimentDropdown = styled(Div)`
    width: 360px;
    max-height: 360px;
    position: relative;
    top: 4px;
    left: -20px;
    background-color: ${WHITE};
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.32);
    z-index: 999;
`

export const FilterRow = styled(Div)`
    width: 100%;
    @media only screen and (min-width: 992px) {
        position: relative;
        margin-bottom: 20px;
    }

    &:nth-child(2n) {
        margin-bottom: 20px !important;
    }

    &:last-child {
        margin-bottom: 5px;
    }
`

export const ReactTabs = css`
    .react-tabs {
        height: calc(100vh - 60px);
        overflow: hidden;
        .react-tabs__tab-list {
            padding: 10px 6px 6px 8px;
            background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    from(rgba(0, 0, 0, 0.1)),
                    to(transparent)
                )
                0 100% ${WHITE} no-repeat;
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent) 0 100%
                ${WHITE} no-repeat;
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), transparent) 0 100% ${WHITE}
                no-repeat;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent) 0 100% ${WHITE}
                no-repeat;
            background-size: 100% 6px;
            height: 50px;
            border: none;
            margin-bottom: 0;

            .react-tabs__tab {
                width: 50%;
                text-align: center;
                font-size: 14px;
                line-height: 17px;
                color: ${BLACK_GREY};
                padding: 7px 12px;
                .badge {
                    background-color: ${MAGENTA};
                }
            }
            .react-tabs__tab--selected {
                font-weight: 700;
                border-bottom: 3px solid ${DARK_GREY} !important;
                border-top: none;
                border-right: none;
                border-left: none;
            }
        }
        .react-tabs__tab-panel--selected {
            height: 100%;
            overflow: auto;
            .tab-header {
                font-weight: 700;
                margin-bottom: 30px;
            }
        }
        .experiment-tab {
            overflow: hidden;
            padding-bottom: 10px;
        }
    }
    @media only screen and (min-width: 414px) {
        .react-tabs {
            .react-tabs__tab-panel--selected {
                height: calc(100% - 90px);
            }
        }
    }

    @media only screen and (min-width: 576px) {
        .react-tabs {
            .react-tabs__tab-panel--selected {
                height: calc(100% - 90px);
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .react-tabs {
            .react-tabs__tab-panel--selected {
                height: 100%;
            }
        }
    }
`

export const ExperimentCompareInfo = styled.div`
    margin-top: 12px;
    text-align: right;
    font-size: 16px;
    letter-spacing: 0.6px;
    color: ${DARK_GREY};
    line-height: 1em;
`