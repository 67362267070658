import React from 'react'
import {I18n} from 'react-i18nify'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {MID_GREY} from '../../core/ui/_colors'
import Avatar from '../../core/components/Avatar'
import AcceptRejectButtons from './AcceptRejectButtons'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import {isMe, removeSelfFromSquad} from '../utils'
import {useUserSlice} from '../hooks/useUserSlice'
import {IconButton} from '../../core/components/Buttons'

const StatusBadge = styled.span`
    opacity: 0.6;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.56px;
    float: right;
    color: ${MID_GREY};
    height: 30px;
    line-height: 30px;
    text-align: center;
`

const UserNameDiv = styled.div`
    line-height: 30px;
    display: inline-block;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ItemUser = ({squadMember, isUserChampion, isUserSponsor, totalChampions, onRequestClose}) => {
    const me = useUserSlice().pk
    const {Actions} = useStrategyMapSlice()
    const isMyOwnRequest = isMe(squadMember.user.pk)
    const isPending = !squadMember.is_accepted
    const isChampion = squadMember.is_champion
    const championLabel = isChampion && I18n.t('sidebar.squad.dialog.champion')
    const myselfLabel = isMyOwnRequest && !isPending && I18n.t('sidebar.squad.dialog.myself')
    const pendingLabel = !isMyOwnRequest && isPending && I18n.t('sidebar.squad.dialog.pending')

    return (
        <div key={squadMember.id}>
            <Avatar pull="left" 
                    colorCode={squadMember.user.color_code}
                    text={squadMember.user.initials}
                    profilePicture={squadMember.user.profile_picture} 
                />
            <UserNameDiv>{squadMember.user.full_name}</UserNameDiv>

            {(isUserChampion || isUserSponsor || (isMyOwnRequest && !isPending)) && (
                <IconButton
                    testid={`remove-squad-member-button-${squadMember.id}`}
                    onClick={() => {
                        removeSelfFromSquad(
                            me,
                            squadMember,
                            isUserChampion || isUserSponsor,
                            totalChampions,
                            onRequestClose,
                            Actions,
                        )
                    }}
                    icon="clear"
                />
            )}

            {isMyOwnRequest && !isChampion && isPending && (
                <AcceptRejectButtons
                    acceptRequest={() => Actions.acceptUserToSquad(squadMember)}
                    rejectRequest={() => Actions.removeSquadMember(squadMember)}
                />
            )}

            <StatusBadge>{pendingLabel}</StatusBadge>
            <StatusBadge>
                {[championLabel, myselfLabel].filter((label) => label).join(', ')}
            </StatusBadge>
        </div>
    )
}

ItemUser.propTypes = {
    squadMember: PropTypes.object,
    isMyOwnRequest: PropTypes.bool,
    isPending: PropTypes.bool,
    isChampion: PropTypes.bool,
    totalChampions: PropTypes.number,
    onRequestClose: PropTypes.func,
}

ItemUser.defaultProps = {
    isMyOwnRequest: false,
    isPending: true,
    isChampion: false,
}

export default ItemUser
