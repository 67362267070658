import React, {useEffect, useState} from 'react'
import { I18n } from 'react-i18nify';
import {Panel} from "../../core/components/BootstrapWrapper";
import { createErrorClass } from '../../core/utils/html';
import Checkbox from './Checkbox';
import ErrorOutput from './ErrorOutput';

const Consent = (props) => {
    const [value, setValue] = useState(props.checked || false)
    const [validationErrors, setValidationErrors] = useState(null)

    const validateInput = () => {
        if (props.required && !value) {
            setValidationErrors({required: I18n.t("capture.Form.Status.Validation.SectionRequired")})
            props.onError && props.onError(props.fieldName)
        } else {
            setValidationErrors(null)
        }
    }

    useEffect(() => {
        if(props.shouldValidate)
            validateInput()
    }, [props.shouldValidate])


    useEffect(() => {
        if (validationErrors)
            validateInput()
    }, [I18n._localeKey])

    return (
        <Panel
            default
            data-testid="Single-choice"
            header={props.header && props.header}
            className={createErrorClass(props.fieldName, props.errors || validationErrors, !props.errors)}
        >
            <Checkbox
                onClick={(checked) => {
                    props.handleChange(props.fieldName, checked)
                    setValidationErrors(null)
                    setValue(checked)
                }}
                checked={props.checked}
                label={props.label}
            />
            <ErrorOutput errors={props.errors || validationErrors}/>
        </Panel>
    )
}

export default Consent
