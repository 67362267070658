import React from 'react'
import styled from 'styled-components'
import Avatar from '../../core/components/Avatar'
import {PALE_GREY} from '../../core/ui/_colors'
import {IconButton} from '../../core/components/Buttons'

export const Container = styled.div`
    width: 100%;
    padding: 4px;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 8px;
    ${(p) => (p.clickable ? 'cursor: pointer;' : '')}
    transition: background .5s;
    &:hover {
        background: ${PALE_GREY};
        .show-hover {
            display: block;
        }
    }
`

const UserOptionTitle = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px 5px;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
`

const UserWithAvatar = ({
    user,
    showAvatar,
    onClick,
    enableRemove,
    children,
    handleRemove,
    testId,
}) => {
    const title = user.full_name ? user.full_name : user.email

    return (
        <Container clickable={onClick !== undefined}>
            {showAvatar && (
                <Avatar pull="left"
                        onClick={() => onClick && onClick(user)}
                        colorCode={user.color_code}
                        text={user.initials}
                        profilePicture={user.profile_picture} 
                    />
            )}
            <UserOptionTitle
                onClick={() => onClick && onClick(user)}
                data-testid={testId || `user-avatar-${user.pk}`}
            >
                {title}
            </UserOptionTitle>
            {enableRemove && (
                <IconButton
                    displayBlock={false}
                    testid={`remove-${testId || `user-avatar-${user.pk}`}`}
                    onClick={() => {
                        handleRemove && handleRemove(user)
                    }}
                    icon="clear"
                />
            )}
            {children}
        </Container>
    )
}

export default UserWithAvatar
