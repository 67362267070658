import React, {useState} from 'react'
import styled from "styled-components";
import {BRIGHT_GREY, DARK_GREY, LIGHT_GREY, WHITE} from "../ui/_colors";

const Background = styled.div`
    height: 30px;
    width: 54px;
    padding: 1px 27px 3px 3px;
    border-radius: 22px;
    border: ${'solid 2px '+LIGHT_GREY};
    background-color: ${ props => props.on && !props.deactivate ? DARK_GREY : !props.on && props.deactivate ? WHITE : LIGHT_GREY};
    padding-left: ${ props => props.on ? '24px' : '3px' }
`
const Ball = styled.div`
    top: 0px;
    width: 24px;
    height: 24px;
    border-radius: 15px;
    box-shadow: ${ props => ! props.deactivate && '0 2px 6px 0 rgba(0, 0, 0, 0.16)'};
    background-color: ${BRIGHT_GREY};   
    border: ${ props => props.deactivate ? 'solid 2px '+LIGHT_GREY : undefined};
`
const SwitchContainer = styled.div`
    float: right;
`
const OneLine = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`
const Label = styled.div`
    width: calc( 100% - 70px);
    float: left;
    font-weight: bold;
`

const Switch = ({defaultValue, deactivate, onChange, testId}) => {

    const [on, setOn] = useState(defaultValue);

    const onClick = () => {
        onChange(!on);
        ! deactivate && setOn(!on);
    }

    return (
        <Background data-testid={testId} on={on ? 1 : 0} deactivate={deactivate} onClick={onClick}>
            <Ball deactivate={deactivate} />
        </Background>
    )
}

export default Switch;

export const SwitchWithLabel = (props) => {
    return (
        <OneLine>
            <Label>{props.label}</Label>
            <SwitchContainer>
                <Switch {...props} />
            </SwitchContainer>
        </OneLine>
    )
}