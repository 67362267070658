import React, {useState} from 'react'
import {useStrategyMapSlice} from '../hooks/useStrategyMapSlice'
import UserListPopover from './UserListPopover'
import ClickOutside from '@tntd/react-click-outside'
import {I18n} from 'react-i18nify'
import {useUserSlice} from '../hooks/useUserSlice'
import {showPopupMessage} from '../../core/utils/notifications'
import {SideBarContainerWrapper, Title} from './Node.styles'

const AddChampion = (props) => {
    const {users} = useStrategyMapSlice()
    const [open, setOpen] = useState(false)
    const currentActiveUser = useUserSlice()
    const championsData = props.node.champions_data || []
    const addChampion = (champion) => {
        const champions = props.node.champions_data || []
        champions.push(champion)
        props.updateChampions(champions)
    }

    return (
        <SideBarContainerWrapper clickable>
            <Title onClick={() => setOpen(!open)} data-testid={'add-champion-button'}>
                {'+ ' + I18n.t('strategyMap.nodes.champion')}
            </Title>
            <ClickOutside
                onClickOutside={() => {
                    open && setOpen(false)
                }}
            >
                {open && (
                    <UserListPopover
                        users={users.filter((user) =>
                            championsData.every((champion) => champion.pk !== user.pk),
                        )}
                        open={open}
                        handleItemClick={(user) => {
                            if (!championsData.length && user.pk !== currentActiveUser.pk) {
                                showPopupMessage({
                                    title: I18n.t('strategyMap.messages.warning'),
                                    message: I18n.t(
                                        'strategyMap.messages.warningAddingSomeoneElseAsChampion',
                                    ),
                                    confirmLabel: I18n.t('strategyMap.messages.yes'),
                                    cancelLabel: I18n.t('strategyMap.messages.cancel'),
                                    onConfirmCallback: () => addChampion(user),
                                    onCancelCallback: () => {},
                                })
                                return false
                            }
                            addChampion(user)
                            setOpen(false)
                        }}
                    />
                )}
            </ClickOutside>
        </SideBarContainerWrapper>
    )
}

export default AddChampion
