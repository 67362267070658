import {I18n} from "react-i18nify";
import {AvatarContainer, AvatarMain, PhotoPicker} from "./UserProfileContainer.styles";
import {ButtonSecondary} from "../../core/components/Buttons";
import { SideBarTitle } from "../../core/ui/Sidebar.styles";
import FormError from "../../core/components/FormError";
import {FormControl, FormLabel} from "react-bootstrap";
import Notification from "../Components/Notification";
import PasswordForm from "../../core/components/PasswordForm";
import {Div} from "../../core/ui/Div";
import Translation from "../../core/components/Translation";
import React from "react";
import {A42FormGroup} from "../../core/components/BootstrapWrapper";
import SecondAuthenticationComponent from "./SecondAuthenticationComponent";
import {PAGES, RESTRICTIONS} from "../../core/const";

const UserProfile = ({Actions, isSubscriptionActive, setShowInfoModal, formErrors, setBoundAllowNotifications,
                     setBoundLeadershipAssessmentNotificationType,
                     features,
                     _user,
                     _password
                     }) =>
    <Div padding="15px">
        <AvatarContainer>
            <AvatarMain
                profilePicture={_user.user.profile_picture}
                colorCode={_user.user.color_code}
                text={_user.user.initials}
            />
            <PhotoPicker onClick={() => setShowInfoModal(true)}>
                <i className="material-icons">photo_camera</i>
            </PhotoPicker>
        </AvatarContainer>
        {
            Object.values( RESTRICTIONS.Settings ).flat().some( role => _user.user[role] )
            && isSubscriptionActive && features.account && (
            <ButtonSecondary 
                fullWidth
                id={"btn-management"}
                data-testid="manage-account"
                onClick={() => {
                    Actions.closeSidebar();
                    Actions.gotoPage( PAGES.Billing );
                }}
            >
                {I18n.t('profile.Label.ManageAccount')}
            </ButtonSecondary>)
        }
        <SideBarTitle>{I18n.t('profile.Label.ProfileData')}</SideBarTitle>
        <form id={'profile-form'}>
            <FormError errors={formErrors}/>
            <A42FormGroup controlId="formUsername">
                <FormLabel>{I18n.t('profile.Label.User')}</FormLabel>
                <FormControl
                    disabled={true}
                    type="email"
                    value={_user.user.email}
                    placeholder={I18n.t('profile.PlaceHolder.User')}
                    onChange={null}
                />
                <FormControl.Feedback/>
            </A42FormGroup>
            <A42FormGroup controlId="formFirstName">
                <FormLabel>
                    {I18n.t('profile.Label.FirstName')}
                </FormLabel>
                <FormControl
                    type="text"
                    value={_user.boundFirstName}
                    placeholder={I18n.t('profile.PlaceHolder.FirstName')}
                    onChange={(e) =>
                        _user.userDispatch(['SET_FIRST_NAME', e.target.value])
                    }
                />
                <FormControl.Feedback/>
            </A42FormGroup>
            <A42FormGroup controlId="formLastName">
                <FormLabel>
                    {I18n.t('profile.Label.LastName')}
                </FormLabel>
                <FormControl
                    type="text"
                    value={_user.boundLastName}
                    placeholder={I18n.t('profile.PlaceHolder.LastName')}
                    onChange={(e) => {
                        e.persist()
                        _user.userDispatch(['SET_LAST_NAME', e.target.value])
                    }}
                />
                <FormControl.Feedback/>
            </A42FormGroup>
        </form>
        <Notification
            user={_user.user}
            onNotificationsChange={() =>
                setBoundAllowNotifications((s) => !s)
            }
            onLeadershipAssessmentNotificationTypeChange={setBoundLeadershipAssessmentNotificationType}
        />
        <SideBarTitle>
            {I18n.t('profile.Label.ChangePassword')}
        </SideBarTitle>

        <PasswordForm
            disabled={_user.user.is_multiuser}
            errors={_password.passwordFormErrors}
            passwordFormValid={_password.passwordFormValid}
            onPasswordSave={(currentPassword, newPassword) =>
                _password.setPassword({
                    current_password: currentPassword,
                    new_password: newPassword,
                })
            }
            onPasswordChange={(s) => _password.passwordDispatch(['SET', s])}
            currentPassword={_password.currentPassword}
            newPassword={_password.newPassword}
        />
        <SecondAuthenticationComponent />

        <Div marginBottom={'100px'}>
            <Translation>
                <SideBarTitle>
                    {I18n.t('profile.Label.Settings')}
                </SideBarTitle>
            </Translation>
        </Div>
    </Div>

export default UserProfile;