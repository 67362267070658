import Triad from "../../../Charts/calculationsUtils/triad";

export const MIRO_VERSIONS = {
    V1: "V1",
    V2: "V2",
    DEFAULT: "V2"
}

export const MIRO_CHECKS = {
    "MissingFrames": "MissingFrames",
    "MissingPoints": "MissingPoints",
    "MisplacedPoints": "MisplacedPoints",
    "DuplicatedPoints": "DuplicatedPoints",
    "InconsistentMOODS": "InconsistentMOODS",
    "NeutralMOODS": "NeutralMOODS",
    "Summary": "Summary"
}

export class OrgScanData{
    data;
    dataSets;
    _version;

    TYPES = {
        Triangle: {
            T1: "Influence",
            T2: "Help",
            T3: "Situation",
            T4: "Consequences",
            T5: "Fundament",
            T6: "Compromises"
        },
        Cartesian: {
            "Leadership": "Leadership Behavior",
            "Orientation": "Orientation to Work",
            "Effectiveness": "Theory of Effectiveness",
            "Value Drivers": "Value Drivers"
        },
        Question: {
            "Decision Speed": "Decision Speed",
            "Decision Maker": "Decision Maker"
        }
    }

    FRAMES = Object.keys( this.TYPES ).flatMap( type => Object.keys( this.TYPES[type] ).flatMap( diagram => this.TYPES[type][diagram] ) );
    instances = {};
    correctIDs = [];

    constructor(data) {
        this.data = data ? data : [];
        this.setVersion( MIRO_VERSIONS.DEFAULT );
    }
    setVersion = (version) => {
        this._version = version;
        return this;
    }
    getVersion = () => this._version;

    _swapKeyValue = (json ) =>
        Object.keys( json ).reduce( (acc, key) => {
            acc[json[key]] = key;
            return acc;
        }, {});

    _getSummary = (points, CHECKS) => {

        const distinctStickyNotes = [...new Set( Object.keys( points ).flatMap( type => points[type] ) ) ];

        const completed = distinctStickyNotes
            .filter( id => Object.keys( points ).every( type => points[type].includes( id ) ) );

        const issues = [...new Set( Object.keys( CHECKS )
            .filter( check => ! [MIRO_CHECKS.Summary, MIRO_CHECKS.NeutralMOODS, MIRO_CHECKS.InconsistentMOODS].includes( check ) )
            .flatMap( issue => Object.keys( CHECKS[issue] ).flatMap( diagram => CHECKS[issue][diagram] ) )
        ) ];

        Object.keys( CHECKS[MIRO_CHECKS.InconsistentMOODS] )
            .forEach( colorIssue => issues.push( colorIssue ) );

        this.correctIDs = completed.filter( p => ! issues.includes( p ) );
        return {
            all: distinctStickyNotes.length,
            complete: completed.length,
            correct: completed.filter( p => ! issues.includes( p ) ).length
        };
    }

    validate = () => {

        const CHECKS = {};
        const invData = {
            ...this._swapKeyValue( this.TYPES.Triangle ),
            ...this._swapKeyValue( this.TYPES.Cartesian ),
            ...this._swapKeyValue( this.TYPES.Question )
        };
        const FRAMES = this.data
            .filter( a => a.type === "frame" );
        this.dataSets = FRAMES
            .filter( a => this.FRAMES.includes( a.data.title ) )
            .reduce( (acc,frame) => ({...acc,
                [invData[frame.data.title]]:
                    this.data
                        .map( a => ({...a, data: {...a.data, content: a.data.content && a.data.content.replace( /(<([^>]+)>)/ig, '')}}))
                        .filter( points => points.parent && points.parent.id === frame.id ) } ), {} );
        const dataSetsReduce = Object.keys(this.dataSets).reduce( (acc,key) => ({...acc, [key]: this.dataSets[key].map( a => a.data.content)}), {});

        const missingFrames = Object.keys( this.TYPES )
            .reduce( (acc,cur) => {
                if( ! acc[cur] )
                    acc[cur] = [];
                acc[cur] = Object.values( this.TYPES[cur] ).filter( a => ! FRAMES.map( a => a.data.title ).includes( a ) );
                return acc;
            }, {});

        CHECKS[MIRO_CHECKS.MissingFrames] = Object.keys( missingFrames )
            .reduce( (acc,cur) => {
                if( missingFrames[cur].length > 0 )
                    acc[cur] = missingFrames[cur];
                return acc;
        }, {});


        CHECKS[MIRO_CHECKS.InconsistentMOODS] = new OrgScanEmotion( this.dataSets ).check();
        CHECKS[MIRO_CHECKS.NeutralMOODS] = new OrgScanEmotion( this.dataSets ).checkOtherEmotes();

        Object.keys({...this.TYPES.Triangle,...this.TYPES.Cartesian, ...this.TYPES.Question})
            .forEach( type => {

                if( Object.keys( this.TYPES.Triangle ).includes( type ) )
                    this.instances[type] = new OrgScanTriangle( this.dataSets[type] );

                if( Object.keys( this.TYPES.Cartesian ).includes( type ) )
                    this.instances[type] = new OrgScanCultural( this.dataSets[type] );

                if( Object.keys( this.TYPES.Question ).includes( type ) )
                    this.instances[type] = new OrgScanQuestions( type, this.dataSets[type], this.getVersion() );

                this.instances[type].setVersion( this.getVersion() );

                CHECKS[MIRO_CHECKS.MisplacedPoints] = {
                    ...CHECKS[MIRO_CHECKS.MisplacedPoints],
                    [type]: [...new Set(this.instances[type].checkContainer().map( a => a.data.content ) ) ]
                };
                CHECKS[MIRO_CHECKS.DuplicatedPoints] = {
                    ...CHECKS[MIRO_CHECKS.DuplicatedPoints],
                    [type]: [...new Set(this.instances[type].checkDuplicated().map( a => a.data.content ) ) ]
                };
        });

        const allFrames = {...this.TYPES.Triangle, ...this.TYPES.Cartesian, ...this.TYPES.Question};
        const missingNotesIn = {};
        Object.keys(allFrames).forEach( key => {

            const compareMatrix = Object.keys(allFrames).filter(compareKey => key !== compareKey);

            compareMatrix.forEach(compare => {
                if( dataSetsReduce[key] && dataSetsReduce[compare] )
                    dataSetsReduce[key]
                        .filter( data => ! dataSetsReduce[compare].includes( data ) )
                        .forEach( a => {
                            if( ! missingNotesIn[compare] )
                                missingNotesIn[compare] = [];
                            if( ! missingNotesIn[compare].includes(a) )
                                missingNotesIn[compare].push(a);
                        } );
            });

        });
        CHECKS[MIRO_CHECKS.MissingPoints] = missingNotesIn;

        CHECKS[MIRO_CHECKS.Summary] = this._getSummary( dataSetsReduce, CHECKS );

        return CHECKS;
    }

    getTransformedData = () => {

        const result = {
            "Mood": new OrgScanEmotion( this.dataSets ).get(),
            ["T1"]: this.instances["T1"].getTransformedCoordinates(),
            ["T2"]: this.instances["T2"].getTransformedCoordinates(),
            ["T3"]: this.instances["T3"].getTransformedCoordinates(),
            ["T4"]: this.instances["T4"].getTransformedCoordinates(),
            ["T5"]: this.instances["T5"].getTransformedCoordinates(),
            ["T6"]: this.instances["T6"].getTransformedCoordinates(),
            ["Leadership"]: this.instances[ "Leadership" ].getTransformedCoordinates(),
            ["Orientation"]: this.instances[ "Orientation" ].getTransformedCoordinates(),
            ["Effectiveness"]: this.instances[ "Effectiveness"].getTransformedCoordinates(),
            ["Value Drivers"]: this.instances[ "Value Drivers" ].getTransformedCoordinates(),
            [this.TYPES.Question["Decision Speed"]]: this.instances["Decision Speed"].getTransformedCoordinates(),
            [this.TYPES.Question["Decision Maker"]]: this.instances["Decision Maker"].getTransformedCoordinates()
        };

        return Object.keys( result )
            .reduce( (acc,type) => {
                acc[type] = Object.keys( result[type] )
                    .reduce( (acc2, id) => {
                        if( this.correctIDs.includes( id ) )
                            acc2[id] = result[type][id];
                        return acc2;
                    }, {});
                return acc;
            }, {});
    };
}

class OrgScanCoordinates {

    points;

    constructor( points ) {
        this.points = points ? points : [];
    }


    isInBetween = function(number, limits) {
        const min = 1. * Math.min(...limits);
        const max = 1. * Math.max(...limits);

        return min <= 1.*number && 1.*number <= max;
    };

    checkDuplicated = () =>
        this.points
            .filter(a => this.points.filter(b => a.data.content === b.data.content).length > 1);

    isInRectangle = (currentPoint, xLimits, yLimits) =>
        this.isInBetween(currentPoint.x, xLimits)
        && this.isInBetween(currentPoint.y, yLimits);
}

class OrgScanEmotion {

    points;
    MOODS = {
        negative: 'negative',
        positive: 'positive',
        neutral: 'neutral'
    };

    constructor( points ) {
        this.points = Object.keys( points ).flatMap( a => points[a] );
    }

    _getEmoteFromColor = (color) => {
        switch (color) {
            case 'red':
                return this.MOODS.negative;
            case 'dark_green':
                return this.MOODS.positive;
            default:
                return this.MOODS.neutral;
        }
    }

    get = () => this.points.reduce( (acc,point) => {
        acc[point.data.content] = this._getEmoteFromColor( point.style.fillColor );
        return acc;
    }, {} );

    check = () => {
        const colors = this.get();
        return this.points.filter( a => this._getEmoteFromColor( a.style.fillColor ) !== colors[a.data.content] )
            .reduce( (acc, cur) => {
                if( ! acc[cur.data.content] )
                    acc[cur.data.content] = [];
                if( ! acc[cur.data.content].includes( this._getEmoteFromColor( cur.style.fillColor ) ) )
                    acc[cur.data.content].push( this._getEmoteFromColor( cur.style.fillColor ) );
                if( ! acc[cur.data.content].includes( colors[cur.data.content] ) )
                    acc[cur.data.content].push( colors[cur.data.content] );
                return acc;
            }, {});
    }

    checkOtherEmotes = () => {
        const colors = this.get();
        return this.points
            .filter( a => this._getEmoteFromColor( a.style.fillColor ) === 'neutral' )
            .reduce( (acc, cur) => {
                acc[cur.data.content] = [this._getEmoteFromColor( cur.style.fillColor ) ];
                return acc;
            }, {});
    }
}

class OrgScanTriangle extends OrgScanCoordinates {

    points;

    constructor( points ) {
        super( points );
        this.setVersion( MIRO_VERSIONS.DEFAULT );
    }

    setVersion = (version) => {
        this.container = this.catalog[ version ? version : MIRO_VERSIONS.DEFAULT ];
        return this;
    }

    container = {};
    catalog = {
        V1: {
            triangle: {
                x: {
                    min: 311,
                    max: 780
                },
                y: {
                    min: 212,
                    max: 619
                }
            },
            notApplicable: {
                x: {
                    min: 964,
                    max: 1272
                },
                y: {
                    min: 278,
                    max: 569
                }
            }
        },
        V2: {
            triangle: {
                x: {
                    min: 300,
                    max: 721
                },
                y: {
                    min: 215,
                    max: 581
                }
            },
            notApplicable: {
                x: {
                    min: 990,
                    max: 1174
                },
                y: {
                    min: 234,
                    max: 466
                }
            }
        }
    };

    checkContainer = () => {

        const pointsOutside = {a: this.isInTriangle().out, b: this.isInRejectBox().out};

        const missingPoints = pointsOutside.a.filter(a => pointsOutside.b.includes(a));

        return this.points.filter(p => missingPoints.includes(p.id));
    }

    isInTriangle = () => {
        const result = {in: [], out: []};

        this.points.forEach(point => {

            const currentPoint = {x: Math.round(point.position.x), y: Math.round(point.position.y)};
            const m = 2 * (this.container.triangle.y.max - this.container.triangle.y.min) / (this.container.triangle.x.max - this.container.triangle.x.min);

            if (
                this.isInBetween( currentPoint.x, [this.container.triangle.x.min, this.container.triangle.x.max] )
                &&
                this.isInBetween( currentPoint.y, [this.container.triangle.y.min, this.container.triangle.y.max] )
                &&
                this.isInBetween( currentPoint.x, [
                    (currentPoint.y - (this.container.triangle.y.max + m * this.container.triangle.x.min)) / -m,
                    (currentPoint.y - (this.container.triangle.y.max - m * this.container.triangle.x.max)) / m
                ])
            )
                result.in.push(point.id);
            else
                result.out.push(point.id);
        });

        return result;
    }

    isInRejectBox = () => {
        const result = {in: [], out: []};

        this.points.forEach(point => {

            const currentPoint = {x: Math.round(point.position.x), y: Math.round(point.position.y)};

            if(
                this.isInBetween( currentPoint.x, [this.container.notApplicable.x.min, this.container.notApplicable.x.max] )
                &&
                this.isInBetween( currentPoint.y, [this.container.notApplicable.y.min, this.container.notApplicable.y.max] )
            )
                result.in.push(point.id);
            else
                result.out.push(point.id);
        });

        return result;
    }

    _transformX = (x) => 1. * (x - this.container.triangle.x.min) / (this.container.triangle.x.max - this.container.triangle.x.min);
    _transformY = (y) => 1. - (y - this.container.triangle.y.min) / (this.container.triangle.y.max - this.container.triangle.y.min);
    getTransformedCoordinates = () => {
        const result = [

            this.isInRejectBox().in
                .reduce((acc, data) => ({
                    ...acc, [this.points.find(a => a.id === data).data.content]:
                        {
                            notApplicable: true,
                            x: 0,
                            y: 0,
                            weightTop: Triad.DEFAULT_WEIGHT,
                            weightLeft: Triad.DEFAULT_WEIGHT,
                            weightRight: Triad.DEFAULT_WEIGHT,
                        }
                }), {}),

            this.isInTriangle().in
                .reduce((acc, data) => {

                    const x = this._transformX(this.points.find(a => a.id === data).position.x);
                    const y = this._transformY(this.points.find(a => a.id === data).position.y);

                    return {
                        ...acc, [this.points.find(a => a.id === data).data.content]:
                            {
                                notApplicable: false,
                                x: x,
                                y: y,
                                weightTop: Triad.DEFAULT_WEIGHT,
                                weightLeft: Triad.DEFAULT_WEIGHT,
                                weightRight: Triad.DEFAULT_WEIGHT,
                            }
                    }
                }, {})
        ];

        return result.reduce((acc, point) => ({...acc, ...point}), {});
    }
}

class OrgScanCultural extends OrgScanCoordinates {

    constructor( points ) {
        super( points );
        this.setVersion( MIRO_VERSIONS.DEFAULT );
    }

    setVersion = (version) => {
        this.container = this.catalog[ version ? version : MIRO_VERSIONS.DEFAULT ];
        return this;
    }

    container = {};
    catalog = {
        V1: {
            cartesian: {
                x: {
                    min: 446,
                    max: 923
                },
                y: {
                    min: 204,
                    max: 673
                }
            }
        },
        V2: {
            cartesian: {
                x: {
                    min: 241,
                    max: 709
                },
                y: {
                    min: 197,
                    max: 656
                }
            }
        }
    };


    checkContainer = () => this.points.filter( a => this.isCoordinateBox().out.includes( a.id ) );

    isCoordinateBox = () => {
        const result = {in: [], out: []};

        this.points.forEach(point => {

            const currentPoint = {x: Math.round(point.position.x), y: Math.round(point.position.y)};

            if(
                this.isInRectangle(
                    currentPoint,
                    [this.container.cartesian.x.min, this.container.cartesian.x.max],
                    [this.container.cartesian.y.min, this.container.cartesian.y.max]
                )
            )
                result.in.push(point.id);
            else
                result.out.push(point.id);
        });

        return result;
    }


    _transformX = (x) => 1. * (x - this.container.cartesian.x.min) / (this.container.cartesian.x.max - this.container.cartesian.x.min);
    _transformY = (y) => 1. - (y - this.container.cartesian.y.min) / (this.container.cartesian.y.max - this.container.cartesian.y.min);
    getTransformedCoordinates = () => {

           const result = [

            this.isCoordinateBox().in
                .reduce( (acc, data ) => {

                    const x = this._transformX(this.points.find(a => a.id === data).position.x);
                    const y = this._transformY(this.points.find(a => a.id === data).position.y);

                    return {
                        ...acc, [this.points.find(a => a.id === data).data.content]:
                            {
                                x: x,
                                y: y
                            }
                    }
                }, {})
        ];

        return result.reduce((acc, point) => ({...acc, ...point}), {});
    }
}

class OrgScanQuestionsCollections extends OrgScanCoordinates {

    container = {};
    _type;

    xDefaultValue = { min: 71, max: 1295 };
    xDefaultValue2 = { min: 69, max: 1280 };
    _collection = {
        V1: {
            "Decision Maker": {
                "me": {
                    x: {
                        min: 68,
                        max: 379
                    },
                    y: {
                        min: 171,
                        max: 404
                    }
                },
                "colleague": {
                    x: {
                        min: 528,
                        max: 839
                    },
                    y: {
                        min: 171,
                        max: 404
                    }
                },
                "team": {
                    x: {
                        min: 987,
                        max: 1299
                    },
                    y: {
                        min: 171,
                        max: 404
                    }
                },
                "manager": {
                    x: {
                        min: 68,
                        max: 379
                    },
                    y: {
                        min: 423,
                        max: 650
                    }
                },
                "external": {
                    x: {
                        min: 528,
                        max: 839
                    },
                    y: {
                        min: 423,
                        max: 650
                    }
                },
                "groupofpeople": {
                    x: {
                        min: 987,
                        max: 1299
                    },
                    y: {
                        min: 423,
                        max: 650
                    }
                },
                "notsure": {
                    x: {
                        min: 70,
                        max: 1299
                    },
                    y: {
                        min: 663,
                        max: 733
                    }
                }
            },
            "Decision Speed": {
                forever: {
                    x: this.xDefaultValue,
                    y: {
                        min: 174,
                        max: 242
                    }
                },
                month: {
                    x: this.xDefaultValue,
                    y: {
                        min: 252,
                        max: 324
                    }
                },
                week: {
                    x: this.xDefaultValue,
                    y: {
                        min: 331,
                        max: 404
                    }
                },
                day: {
                    x: this.xDefaultValue,
                    y: {
                        min: 414,
                        max: 487
                    }
                },
                hour: {
                    x: this.xDefaultValue,
                    y: {
                        min: 499,
                        max: 570
                    }
                },
                rightaway: {
                    x: this.xDefaultValue,
                    y: {
                        min: 578,
                        max: 651
                    }
                },
                notsure: {
                    x: this.xDefaultValue,
                    y: {
                        min: 660,
                        max: 731
                    }
                }
            }
        },
        V2: {
            "Decision Maker": {
                "me": {
                    x: {
                        min: 69,
                        max: 447
                    },
                    y: {
                        min: 161,
                        max: 357
                    }
                },
                "colleague": {
                    x: {
                        min: 472,
                        max: 876
                    },
                    y: {
                        min: 161,
                        max: 357
                    }
                },
                "team": {
                    x: {
                        min: 885,
                        max: 1280
                    },
                    y: {
                        min: 161,
                        max: 357
                    }
                },
                "manager": {
                    x: {
                        min: 69,
                        max: 447
                    },
                    y: {
                        min: 367,
                        max: 564
                    }
                },
                "external": {
                    x: {
                        min: 476,
                        max: 876
                    },
                    y: {
                        min: 367,
                        max: 564
                    }
                },
                "groupofpeople": {
                    x: {
                        min: 883,
                        max: 1280
                    },
                    y: {
                        min: 367,
                        max: 564
                    }
                },
                "notsure": {
                    x: {
                        min: 69,
                        max: 1280
                    },
                    y: {
                        min: 570,
                        max: 633
                    }
                }
            },
            "Decision Speed": {
                forever: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 174,
                        max: 221
                    }
                },
                month: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 228,
                        max: 289
                    }
                },
                week: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 297,
                        max: 357
                    }
                },
                day: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 368,
                        max: 425
                    }
                },
                hour: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 434,
                        max: 493
                    }
                },
                rightaway: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 501,
                        max: 562
                    }
                },
                notsure: {
                    x: this.xDefaultValue2,
                    y: {
                        min: 568,
                        max: 632
                    }
                }
            }
        }
    }


    constructor( type, points, version ) {
        super( points );
        this._type = type;

        this.setVersion( version );
    }

    setVersion = (version) => {
        if( this._collection[version][this._type] )
            this.container = this._collection[version][this._type];
    }
}
class OrgScanQuestions extends OrgScanQuestionsCollections {

    constructor( type, points, version ) {
        super( type, points, version || MIRO_VERSIONS.DEFAULT );
    }

    setVersion = (version) => {}

    checkContainer = () => this.points.filter( a => [this.isCoordinateBox().out].includes( a.id ) );

    isCoordinateBox = () => {
        const result = {in: [], out: []};

        this.points.forEach(point => {

            if( this.getAnswer( point.position ) )
                result.in.push(point.id);
            else
                result.out.push(point.id);
        });

        return result;
    }


    getAnswer = (currentPoint) => {

        let result;

        Object.keys(this.container)
            .forEach(key => {
                if( this.isInRectangle(
                    currentPoint,
                    [this.container[key].x.min, this.container[key].x.max],
                    [this.container[key].y.min, this.container[key].y.max]
                ))
                    result = key;
            });
        return result;
    }


    getTransformedCoordinates = () => {

        const result = this.isCoordinateBox().in
            .reduce( (acc, data ) => {
                const point = this.points.find(a => a.id === data);
                return {
                    ...acc, [point.data.content]: this.getAnswer( point.position )
                }
            }, {});

        return result;
    }
}