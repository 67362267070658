import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useUserSlice} from './strategyMap/hooks/useUserSlice'
import AuthWebsocketContext from './core/contexts/AuthWebsocketContext'
import {useCoreSlice} from "./core/hooks/useCoreSlice";
import {HOUR_IN_MILLISECONDS} from "./core/const";

export const Authenticator = (props) => {
    const {Actions} = useCoreSlice();
    const [lastChangedUserStatus, setLastChangedUserStatus] = useState(null);
    const user = useUserSlice();
    const authSocket = useContext(AuthWebsocketContext);

    const intervalRef = useRef(null);
    useEffect( () => {
        if (authSocket) {
            authSocket.setOnMessage(handleWebsocketData);
        }
        intervalRef.current = setInterval(
            () => Actions.fetchGit(),
            HOUR_IN_MILLISECONDS
        );
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if( localStorage.getItem('token') ) {
            user.Actions.getUser();
        }
    }, [user.authSuccess]);

    const handleWebsocketData = useCallback((data) => {
        setLastChangedUserStatus({is_active: data.is_active, pk: data.pk})
    })

    useEffect(() => {
        const currentUserHasBeenDeactivated =
        lastChangedUserStatus &&
        lastChangedUserStatus.pk === user.pk &&
        lastChangedUserStatus.is_active === false
        if (currentUserHasBeenDeactivated) {
            localStorage.removeItem('token')
            props.history.push('/login?userDeactivated')
        }
    }, [lastChangedUserStatus])

    return <div>{props.children}</div>
}
