import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import TrianglePanel from './TrianglePanel'
import { InfoLabel } from '../Shared.styles'
import {isSelected} from '../../../core/utils/form'

const TriangleGroup = (props) => {
    const [trianglesData, setTrianglesData] = useState([])

    const handleChange = (data) => {
        if (isSelected(trianglesData, {triangle: data.triangle})) {
            setTrianglesData([...trianglesData.filter((item) => item.triangle !== data.triangle), data])
        } else {
            setTrianglesData([...trianglesData, data])
        }
    }

    useEffect(() => {
        props.onChange && props.onChange(trianglesData)
    }, [trianglesData])

    return (
        <>
            {props.groupInfo && <InfoLabel>{props.groupInfo}</InfoLabel>}
            <div className="triangle-section">
                {props.items.length > 0 &&
                    props.items.map((data) => {
                        return (
                            <TrianglePanel
                                key={data.id}
                                data={data}
                                fullWidth={props.fullWidth}
                                onChange={handleChange}
                                shouldValidate={props.shouldValidate}
                                onError={props.onError}
                                error={props.errors && props.errors.includes(data.id)}
                                shouldResetPosition={props.shouldResetPosition}
                                onPositionReset={props.onPositionReset}
                            />
                        )
                    })}
            </div>
        </>
    )
}


TriangleGroup.propTypes = {
    onChange: PropTypes.func,
    onPositionReset: PropTypes.func,
    onError: PropTypes.func,
    shouldResetPosition: PropTypes.bool,
    fullWidth: PropTypes.bool,
    shouldValidate: PropTypes.bool,
    items: PropTypes.array,
    errors: PropTypes.array,
}

export default TriangleGroup
