import {applyMiddleware, createStore} from 'redux'
import history from '../core/utils/history'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {routerMiddleware} from 'connected-react-router'

import rootReducer from './reducers'
import sagas from './sagas'

const {composeWithDevTools} =
    process.env.NODE_ENV !== 'production'
        ? require('redux-devtools-extension')
        : {composeWithDevTools: (x) => x}

const sagaMiddleware = createSagaMiddleware()

const reduxMiddleware = [thunkMiddleware, sagaMiddleware, routerMiddleware(history)]

const store = createStore(
    rootReducer(history),
    {},
    composeWithDevTools(applyMiddleware(...reduxMiddleware)),
)

sagaMiddleware.run(sagas)

export default store
