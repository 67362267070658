import {PAGES} from "../../core/const";
import {ClickElement, TitleDescription} from "../Stories";
import {I18n} from "react-i18nify";
import React from "react";

export const setupLeadershipInstance = (props) => ({

    page: PAGES.LeaderShip.Dashboard,
    steps: [
        {
            style: {maxWidth: 970},
            content: <div style={{marginTop: 20}}>
                <TitleDescription
                    title={ I18n.t("stories.leadership.setup-instance.steps.welcome.header") }
                    description={I18n.t("stories.leadership.setup-instance.steps.welcome.content")}
                />
            </div>,
            action: () => {}
        },
        {
            style: {maxWidth: 970},
            selector: ".user",
            content: <div style={{marginTop: 20}}>
                <TitleDescription
                    description={I18n.t("stories.leadership.setup-instance.steps.click-avatar.content")}
                />
            </div>,
            action: () => {}
        },
        {
            selector: '.btn-management',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    description={I18n.t("stories.leadership.setup-instance.steps.go-to-setup-area.content")}
                />
            </div>,
            action: () => props.sidebar.openUserProfileSidebar()
        },
        {
            selector: '.usersManagement',
            content: <div>
                <TitleDescription
                    description={I18n.t("stories.leadership.setup-instance.steps.goto-user.content")}
                />
            </div>,
            action: () => {
                ClickElement.byClassName('btn-management');
            }
        },
        {
            selector: '.tab-content-billing',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    title={ I18n.t("stories.leadership.setup-instance.steps.setup-user.header") }
                    description={I18n.t("stories.leadership.setup-instance.steps.setup-user.content")}
                />
            </div>,
            action: () => {
                ClickElement.byClassName('usersManagement')
            }
        },
        {
            selector: '.Teams',
            content: <div>
                <TitleDescription
                    description={I18n.t("stories.leadership.setup-instance.steps.goto-teams.content")}
                />
            </div>,
            action: () => {}
        },
        {
            selector: '.tab-content-billing',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    title={ I18n.t("stories.leadership.setup-instance.steps.setup-teams.header") }
                    description={I18n.t("stories.leadership.setup-instance.steps.setup-teams.content")}
                />
            </div>,
            action: () => {
                ClickElement.byClassName('Teams')
            }
        },
        {
            selector: '.back',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    description={I18n.t("stories.leadership.setup-instance.steps.go-back-dashboard.content")}
                />
            </div>,
            action: () => {}
        },
        {
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    title={ I18n.t("stories.leadership.setup-instance.steps.dashboard.header") }
                    description={I18n.t("stories.leadership.setup-instance.steps.dashboard.content")}
                />
            </div>,
            action: () => {
                ClickElement.byClassName("back");
            }
        },
        {
            selector: '.Capture',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    description={I18n.t("stories.leadership.setup-instance.steps.capture-survey.content")}
                />
            </div>,
            action: () => {}
        },
        {
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    title={ I18n.t("stories.leadership.setup-instance.steps.survey.header") }
                    description={ I18n.t("stories.leadership.setup-instance.steps.survey.content") }
                />
            </div>,
            action: () => {
                ClickElement.byClassName('Capture');
            }
        },
        {
            selector: '.card-team',
            style: {maxWidth: 630},
            content: <div>
                <TitleDescription
                    description={ I18n.t("stories.leadership.setup-instance.steps.survey-team.content") }
                />
            </div>,
            action: () => {}
        }
    ]
});